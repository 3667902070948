import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ddiq-diff-tool-modal',
  templateUrl: './ddiq-diff-tool-modal.component.tpl.html',
})
export default class DiffToolModalComponent implements OnChanges {
  @Input() isLoading;
  diff;
  entityType;

  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private urlGenerator: UrlGenerator,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.entity.isProperty()) {
      // The profile is done loading.
      if (changes.isLoading && changes.isLoading.currentValue === false) {
        this.init();
      }
    }
  }

  init() {
    const name = this.entity.getDisplayName();
    const compare = this.compare;

    this.diff = {
      current: {
        name,
      },
      profiles: [],
      compare,
    };

    this.entityType = this.entity.getType();
    this.apiFetchService
      .findEntity(this.entity.getType(), 0, 20, this.entity.getDisplayName())
      .then(this.findSuccess());
  }

  findSuccess() {
    return (data) => {
      if (!data.results) {
        return;
      }

      let spliceMe;

      for (let i = 0; i < data.results.length; i++) {
        // mark the current entity for removal
        if (data.results[i].oiqEntityId === this.entity.getId()) {
          spliceMe = i;
        }
        // add a diff url
        data.results[i].diffUrl = this.urlGenerator.generateDiff(
          this.entity.getId(),
          this.entity.getType(),
          data.results[i].oiqEntityId,
          null
        );
      }

      // remove current entity from the results
      data.results.splice(spliceMe, 1);
      this.diff.profiles = data.results;
    };
  }

  compare(baseline) {
    const diffPath = this.route.snapshot.url[0].path + '/diff/' + baseline.oiqEntityId;
    this.router.navigate([diffPath], { replaceUrl: true });
  }
}
