import { Component } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ScreeningFeature from '../screening/screening-feature.service';
import UserService from '../user/user.service';
import Monitor from './monitor.service';

@Component({
  selector: 'ddiq-monitor-link',
  templateUrl: './ddiq-monitor-link.component.tpl.html',
})
export default class MonitorLinkComponent {
  monitoringEnabled: boolean;
  deltaReportUrl;
  reportUrl;
  isScreeningEnabled: boolean;

  constructor(
    private entity: Entity,
    private urlGenerator: UrlGenerator,
    private user: UserService,
    private monitor: Monitor,
    private oiqProperties: OiqProperties,
    private screeningFeature: ScreeningFeature
  ) {
    this.isScreeningEnabled = this.screeningFeature.isEnabled();
    this.monitoringEnabled = this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled;
    this.deltaReportUrl = this.urlGenerator.generateReport(
      this.entity.getId(),
      this.entity.getType(),
      this.entity.getId(),
      '?monitor=true'
    );
    this.reportUrl = this.urlGenerator.generateReport(this.entity.getId(), this.entity.getType(), this.entity.getId());
  }

  isMonitored() {
    return this.monitor.isMonitored();
  }

  isMonitorActive() {
    return this.monitor.isMonitorActive();
  }

  isDeltaReport() {
    return this.monitor.isDeltaReport();
  }

  monitorData() {
    return this.monitor.getMonitorData() ?? {};
  }

  entityName() {
    const entity = this.entity.getData();
    if (entity) {
      return entity.name;
    }
  }
}
