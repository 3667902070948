<div id="legal" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="Legal"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!previewingEntity || legalHasContent()"
    [disable]="!legalHasContent()"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <div *ngFor="let subsection of getSubSections(section.metadata.sectionId)" class="sub-section">
      <div [ngSwitch]="subsection">
        <div id="company_legal_webContent" *ngSwitchCase="'incidents'">
          <strong class="push-down">Web Content</strong>
          <ddiq-incidents-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.contentEventGroups"
            (sortDiscoveries)="sortDiscoveries('content', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-incidents-group>
        </div>
        <div id="company_legalFilings" *ngSwitchCase="'legalFilings'">
          <div *ngIf="!isDeltaReport">
            <strong class="push-down">Legal Filings</strong>
            <ddiq-legal-filings-group
              [idPrefix]="section.metadata.sectionId"
              [groups]="section.data.legalActivitiesEventGroups"
              (sortDiscoveries)="sortDiscoveries('legalActivities', $event.sortName, $event.eventGroup)"
              [entityType]="'company'"
              [expandAll]="isExpandAllGroups">
            </ddiq-legal-filings-group>
          </div>
        </div>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
