import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doingBusinessInLocationSubmission',
})
export default class DoingBusinessInLocationSubmissionPipe implements PipeTransform {
  transform(locations: any) {
    return (locations || []).filter(function (location) {
      return location && location.source === 'AdditionalLocationSubmission' && location.label === 'DOING_BUSINESS_IN';
    });
  }
}
