import { Component, Input, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import CheckContentService from '../common-services/check-content.service';
import Entity from '../common-services/entity.service';
import SectionConfigService from '../common-services/section-config.service';
import Monitor from '../monitor/monitor.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';

@Component({
  selector: 'ddiq-legal-person',
  templateUrl: 'ddiq-legal-person.component.tpl.html',
})
export default class DdiqLegalPersonComponent implements OnInit {
  @Input() section;
  @Input() isExpandAllGroups;
  entityType;
  isDeltaReport;
  previewingEntity;
  contentCounter;

  constructor(
    private incidentsUtils: IncidentsUtils,
    private structuredDataUtils: StructuredDataUtils,
    private checkContentService: CheckContentService,
    private entity: Entity,
    private monitor: Monitor,
    private sectionConfigService: SectionConfigService,
    private messageBusService: MessageBusService
  ) {}

  ngOnInit() {
    this.contentCounter = {
      _incidentsCount: this.incidentsUtils.observedCount('legal'),
      _legal: this.structuredDataUtils.observedCount('legal'),

      filtered: function () {
        return this._incidentsCount.filtered + this._legal.filtered;
      },
      unfiltered: function () {
        return this._incidentsCount.unfiltered + this._legal.unfiltered;
      },
      filteredEscalated: function () {
        return this._incidentsCount.filteredEscalated + this._legal.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._incidentsCount.unfilteredEscalated + this._legal.unfilteredEscalated;
      },
    };

    this.previewingEntity = this.entity.isInPreview();
    this.entityType = this.entity.getType();
    this.isDeltaReport = this.monitor.isDeltaReport();
  }

  // check to see if legal has any content -- used to disable expand/collapse
  legalHasContent() {
    // we can do this because we expect the legal section to display all information passed by API
    return this.checkContentService.checkForContent(this.section.data, true);
  }

  getSubSections(sectionId) {
    return this.sectionConfigService.getSubSections(this.entityType, sectionId);
  }

  sortDiscoveries(eventGroupKind, sortName, eventGroup) {
    this.messageBusService.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind,
        sortName,
        eventGroup,
      },
    });
  }
}
