import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'citizenOf',
})
export default class CitizenOfPipe implements PipeTransform {
  transform(addresses: any) {
    return (addresses || []).filter(function (address) {
      return address && address.label === 'CITIZEN_OF';
    });
  }
}
