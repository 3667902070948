import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class Diff {
  current = {
    displayName: '',
    oiqCreatedDate: '',
  };
  baseline = {
    displayName: '',
    oiqCreatedDate: '',
  };
  currentProfileUrl;
  baselineProfileUrl;
}
