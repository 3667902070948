import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import DateFormatPipe from '../ddiq-filters/date-format.pipe';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { OiqThemeModule } from '../theme/oiq-theme.module';
import CoverResolver from './cover-resolver.service';
import CoverComponent from './ddiq-cover.component';

@NgModule({
  imports: [CommonModule, OiqThemeModule, PipesModule],
  declarations: [CoverComponent],
  providers: [CoverResolver, DateFormatPipe],
  exports: [CoverComponent],
})
export class OiqCoverModule {}
