import { Component, Inject, Input, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import CheckContentService from '../common-services/check-content.service';
import Entity from '../common-services/entity.service';
import Monitor from '../monitor/monitor.service';
import SectionConfigService from '../common-services/section-config.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';

@Component({
  selector: 'ddiq-adverse-event',
  templateUrl: 'ddiq-adverse-event.component.tpl.html',
})
export default class DdiqAdverseEventComponent implements OnInit {
  @Input() section;
  @Input() isExpandAllGroups;
  entityType;
  previewingEntity;
  contentCounter;
  isDeltaReport;

  constructor(
    private incidentsUtils: IncidentsUtils,
    private structuredDataUtils: StructuredDataUtils,
    private sectionConfigService: SectionConfigService,
    private checkContentService: CheckContentService,
    private entity: Entity,
    private monitor: Monitor,
    private messageBusService: MessageBusService
  ) {}

  ngOnInit() {
    this.contentCounter = {
      _incidentsCount: this.incidentsUtils.observedCount('adverse'),
      _scorecards: this.structuredDataUtils.observedCount('adverse'),

      filtered: function () {
        return this._incidentsCount.filtered + this._scorecards.filtered;
      },
      unfiltered: function () {
        return this._incidentsCount.unfiltered + this._scorecards.unfiltered;
      },
      filteredEscalated: function () {
        return this._incidentsCount.filteredEscalated + this._scorecards.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._incidentsCount.unfilteredEscalated + this._scorecards.unfilteredEscalated;
      },
    };

    this.isDeltaReport = this.monitor.isDeltaReport();
    this.previewingEntity = this.entity.isInPreview();
    this.entityType = this.entity.getType();
  }

  // check to see if adverse has any content -- used to disable expand/collapse
  hasAdverseContent() {
    return this.checkContentService.checkForContent(this.section.data, true);
  }

  getSubSections(sectionId) {
    return this.sectionConfigService.getSubSections(this.entityType, sectionId);
  }

  sortDiscoveries(eventGroupKind, sortName, eventGroup) {
    this.messageBusService.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind,
        sortName,
        eventGroup,
      },
    });
  }
}
