import { Injectable } from '@angular/core';
import { ProfileHit, ProfileHitId } from './profile-hit-id';

interface WatchListHit extends ProfileHit {
  watchlists?: Array<{ oiqHash: string }>;
  oiqHash?: string;
}
@Injectable()
export class WatchListHitIdService extends ProfileHitId {
  doGetIdFrom(watchListHit: WatchListHit): string {
    if (watchListHit.watchlists?.length) {
      return watchListHit.watchlists[0].oiqHash;
    }

    return watchListHit.oiqHash;
  }
}
