import { Component } from '@angular/core';
import Entity from '../common-services/entity.service';
import ProfileRefreshService from '../profilerefresh/profile-refresh.service';
import { Router } from '@angular/router';
@Component({
  selector: 'ddiq-visualize-network',
  template: ` <button class="btn btn-sm btn-primary" (click)="visualize()" [disabled]="disableButton">
    <i class="fa fa-share-alt" aria-hidden="true"></i>
    Visualize this Network
  </button>`,
})
export default class VisualizeNetworkComponent {
  entityId: any;
  entityType: any;
  disableButton: any;

  constructor(private profileRefreshService: ProfileRefreshService, private entity: Entity, private router: Router) {
    this.entityId = this.entity.getId();
    this.entityType = this.entity.getType();

    const previewingEntity = this.entity.isInPreview();
    const isProfileRefreshDelta = this.profileRefreshService.isProfileRefreshDelta();

    this.disableButton = previewingEntity || isProfileRefreshDelta;
  }

  visualize() {
    this.router.navigate(['/network/' + this.entityId + '/' + this.entityType]);
  }
}
