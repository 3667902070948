<div
  [id]="profile.lookupId"
  class="oiq-regulatory-profile"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': profile.escalated, confirmed: profile.confirmed}"
  data-oiq-collapsible-ctrl
  [expandAll]="profile.isOpen">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="profile"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-regulatory-profile-summary oiq-grid-cell">
          <div class="oiq-regulatory-profile-cell" role="gridcell">
            <div
              class="oiq-regulatory-profile-classifier"
              *ngIf="isDisplayClassifications"
              [ngClass]="{disabled: profile.disabled}">
              <strong>{{ profile.seedSource.source | translatef }}</strong>
            </div>

            <ddiq-association [level]="profile.associationBucket" class="oiq-regulatory-profile-association">
            </ddiq-association>

            <span
              *ngIf="displayDate"
              class="oiq-regulatory-profile-display-date"
              [ngClass]="{disabled: profile.disabled}">
              <span class="oiq-regulatory-profile-display-date-label sr-only">Status Date</span>
              <time
                class="oiq-regulatory-profile-display-date-time"
                datetime="{{displayDate}}"
                [innerText]="displayDate | date: 'mediumDate' :'UTC'">
              </time>
            </span>

            <ddiq-adjudication-actions
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [type]="'REGULATORY_AGENCY_PROFILE'"
              [entityType]="entityType"
              [entityId]="entityId"
              [noReassess]="noReassess"
              [targetId]="profile.id"
              [model]="profile">
            </ddiq-adjudication-actions>
          </div>
        </div>

        <div class="oiq-regulatory-profile-bd oiq-cell">
          <article class="oiq-regulatory-profile-grid" [ngClass]="{disabled: profile.disabled}">
            <div class="oiq-regulatory-profile-row" *ngIf="profile.companyName">
              <div class="oiq-regulatory-profile-hd">Name:</div>
              <div
                class="oiq-regulatory-profile-name oiq-regulatory-profile-cell"
                [innerText]="profile.companyName"></div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.fullName">
              <div class="oiq-regulatory-profile-hd">Name:</div>
              <div
                class="oiq-regulatory-profile-full-name oiq-regulatory-profile-cell"
                [innerText]="profile.fullName"></div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.seedSource?.sourceUrl">
              <div class="oiq-regulatory-profile-hd">Source:</div>
              <div class="oiq-regulatory-profile-cell">
                <a
                  class="oiq-regulatory-profile-source-nav"
                  href="{{profile.seedSource.sourceUrl | sourceUrlRedirect}}"
                  [innerText]="profile.seedSource.sourceUrl | sitename"
                  target="_blank"
                  rel="noopener noreferrer"></a>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="!profile.seedSource?.sourceUrl">
              <div class="oiq-regulatory-profile-hd">Source:</div>
              <div class="oiq-regulatory-profile-cell">
                <div class="oiq-regulatory-profile-source" [innerText]="profile.seedSource?.source | translatef"></div>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.website">
              <div class="oiq-regulatory-profile-hd">Website:</div>
              <div class="oiq-regulatory-profile-cell">
                <a
                  class="oiq-regulatory-profile-website"
                  href="{{profile.website | urlNormalizer}}"
                  [innerText]="profile.website"
                  target="_blank"
                  rel="noopener noreferrer"></a>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.addresses?.length">
              <div class="oiq-regulatory-profile-hd">Addresses:</div>
              <div class="oiq-regulatory-profile-cell">
                <ul class="oiq-regulatory-profile-addresses">
                  <li
                    class="oiq-regulatory-profile-address"
                    *ngFor="let address of profile.addresses"
                    [innerText]="address | address"></li>
                </ul>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.emailAddresses?.length">
              <div class="oiq-regulatory-profile-hd">E-Mail Addresses:</div>
              <div class="oiq-regulatory-profile-cell">
                <ddiq-limited-list [list]="profile.emailAddresses" [limit]="3">
                  <ng-template #parentTemplate let-limitedList>
                    <ul class="oiq-regulatory-profile-email-addresses">
                      <li
                        class="oiq-regulatory-profile-email-address"
                        *ngFor="let emailAddress of limitedList"
                        [innerText]="emailAddress.email"></li>
                    </ul>
                  </ng-template>
                </ddiq-limited-list>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.phoneNumbers?.length">
              <div class="oiq-regulatory-profile-hd">Phone Numbers:</div>
              <div class="oiq-regulatory-profile-cell">
                <ddiq-limited-list [list]="profile.phoneNumbers" [limit]="3">
                  <ng-template #parentTemplate let-limitedList>
                    <ul class="oiq-regulatory-profile-phone-numbers">
                      <li
                        class="oiq-regulatory-profile-phone-number"
                        *ngFor="let phone of limitedList"
                        [innerText]="phone.number"></li>
                    </ul>
                  </ng-template>
                </ddiq-limited-list>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.status">
              <div class="oiq-regulatory-profile-hd">Status:</div>
              <div class="oiq-regulatory-profile-cell">
                <div class="oiq-regulatory-profile-status" [innerText]="profile.status"></div>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.statusDate">
              <div class="oiq-regulatory-profile-hd">Status date:</div>
              <div class="oiq-regulatory-profile-cell">
                <div class="oiq-regulatory-profile-status" [innerText]="profile.statusDate | dateFormat"></div>
              </div>
            </div>

            <div class="oiq-regulatory-profile-row" *ngIf="profile.statusEndDate">
              <div class="oiq-regulatory-profile-hd">Status end date:</div>
              <div
                class="oiq-regulatory-profile-status-end oiq-regulatory-profile-cell"
                [innerText]="profile.statusEndDate | dateFormat"></div>
            </div>

            <ng-container *ngIf="profile.additionalGovernmentIdentifications?.length">
              <div
                class="oiq-regulatory-profile-row-group"
                *ngFor="let identificationNumber of profile.additionalGovernmentIdentifications">
                <ng-container *ngIf="identificationNumber.identificationType && identificationNumber.number">
                  <div class="oiq-regulatory-profile-row">
                    <div
                      class="oiq-regulatory-profile-hd oiq-regulatory-profile-identification-type"
                      [innerText]="(identificationNumber.identificationType | translatef) + ':'"></div>

                    <div
                      class="oiq-regulatory-profile-cell oiq-regulatory-profile-identification-number"
                      [innerText]="identificationNumber.number"></div>
                  </div>

                  <div class="oiq-regulatory-profile-row" *ngIf="identificationNumber.issuingBody">
                    <div class="oiq-regulatory-profile-hd">Issuing Body:</div>
                    <div
                      class="oiq-regulatory-profile-cell oiq-regulatory-profile-identification-issuingbody"
                      [innerText]="identificationNumber.issuingBody"></div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </article>

          <ddiq-collapsible>
            <ddiq-code-schema
              class="oiq-regulatory-profile-grid"
              [ngClass]="{disabled: profile.disabled}"
              [field]="profile">
            </ddiq-code-schema>

            <article class="oiq-regulatory-profile-grid" [ngClass]="{disabled: profile.disabled}">
              <div class="oiq-regulatory-profile-row" *ngIf="profile.birthdate">
                <div class="oiq-regulatory-profile-hd">Birthdate:</div>
                <div
                  class="oiq-regulatory-profile-birthdate oiq-regulatory-profile-cell"
                  [innerText]="profile.birthdate | dateFormat"></div>
              </div>

              <div class="oiq-regulatory-profile-row" *ngIf="profile.oversightDetails?.length">
                <div class="oiq-regulatory-profile-hd">Oversight Details:</div>
                <div class="oiq-regulatory-profile-cell">
                  <ul class="oiq-regulatory-profile-oversight-details">
                    <li *ngFor="let detail of profile.oversightDetails; let $last = last">
                      <span
                        class="oiq-regulatory-profile-oversight-type"
                        *ngIf="detail.oversightType && !detail.url"
                        [innerText]="detail.oversightType">
                      </span>

                      <a
                        class="oiq-regulatory-profile-oversight-url"
                        *ngIf="detail.oversightType && detail.url"
                        href="{{detail.url}}"
                        [innerText]="detail.oversightType"
                        target="_blank"
                        rel="noopener noreferrer">
                      </a>

                      <span
                        class="oiq-regulatory-profile-oversight-start-end-dates"
                        *ngIf="detail.startDate || detail.endDate">
                        ({{detail.startDate | dateFormat}}{{detail.startDate && detail.endDate ? " - " :
                        ""}}{{detail.endDate | dateFormat}})
                      </span>

                      <span
                        class="oiq-regulatory-profile-oversight-description"
                        *ngIf="detail.description"
                        [innerText]="detail.description | translatef">
                      </span>

                      <span class="oiq-regulatory-profile-grid">
                        <span class="oiq-regulatory-profile-row" *ngFor="let additionalData of detail.additionalData">
                          <span
                            class="oiq-regulatory-profile-oversight-additional-data-label oiq-regulatory-profile-hd"
                            [innerText]="(additionalData.label | translatef) + ':'">
                          </span>

                          <span class="oiq-regulatory-profile-cell">
                            <ul class="oiq-regulatory-profile-oversight-additional-data">
                              <li
                                class="oiq-regulatory-profile-oversight-additional-data-value"
                                *ngFor="let value of additionalData.value"
                                [innerText]="value | newlineToBr"></li>
                            </ul>
                          </span>
                        </span>
                      </span>
                      <br *ngIf="!$last" />
                    </li>
                  </ul>
                </div>
              </div>

              <div class="oiq-regulatory-profile-row" *ngIf="profile.alternativeNames?.length">
                <div class="oiq-regulatory-profile-hd">Alternative Names:</div>
                <div class="oiq-regulatory-profile-cell">
                  <ul class="oiq-regulatory-profile-alternative-names">
                    <li
                      class="oiq-regulatory-profile-alternative-name"
                      *ngFor="let name of profile.alternativeNames"
                      [innerText]="name"></li>
                  </ul>
                </div>
              </div>

              <div class="oiq-regulatory-profile-row" *ngIf="hasRelatedCompanyData">
                <div class="oiq-regulatory-profile-hd">Related Companies:</div>
                <div class="oiq-regulatory-profile-cell">
                  <ul class="oiq-regulatory-profile-related-companies">
                    <li
                      class="oiq-regulatory-profile-related-company"
                      *ngFor="let company of profile.relatedCompanies; let $last = last">
                      <span
                        class="oiq-regulatory-profile-related-company-name"
                        *ngIf="company.name && !(company.seedSources?.length && company.seedSources[0].sourceUrl)"
                        [innerText]="company.name">
                      </span>

                      <a
                        class="oiq-regulatory-profile-related-company-source-url"
                        *ngIf="company.name && company.seedSources?.length && company.seedSources[0].sourceUrl"
                        href="{{company.seedSources[0].sourceUrl}}"
                        [innerText]="company.name"
                        target="_blank"
                        rel="noopener noreferrer">
                      </a>

                      <span class="oiq-regulatory-profile-row" *ngIf="company.title">
                        <span class="oiq-regulatory-profile-related-company-data-label oiq-regulatory-profile-hd"
                          >Title:</span
                        >
                        <span
                          class="oiq-regulatory-profile-related-company-title oiq-regulatory-profile-cell"
                          [innerText]="company.title">
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="company.relationship">
                        <span class="oiq-regulatory-profile-related-company-data-label oiq-regulatory-profile-hd"
                          >Relationship:</span
                        >
                        <span
                          class="oiq-regulatory-profile-related-company-rel oiq-regulatory-profile-cell"
                          [innerText]="company.relationship">
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="company.address">
                        <span class="oiq-regulatory-profile-related-company-data-label oiq-regulatory-profile-hd"
                          >Address:</span
                        >
                        <span
                          class="oiq-regulatory-profile-related-company-address oiq-regulatory-profile-cell"
                          [innerText]="company.address | address">
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="company.emailAddresses?.length">
                        <span class="oiq-regulatory-profile-related-company-data-label oiq-regulatory-profile-hd"
                          >E-Mail Addresses:</span
                        >
                        <span class="oiq-regulatory-profile-cell">
                          <ddiq-limited-list [list]="company.emailAddresses" [limit]="3">
                            <ng-template #parentTemplate let-limitedList>
                              <ul class="oiq-regulatory-profile-related-company-email-addresses">
                                <li
                                  class="oiq-regulatory-profile-related-company-email-address"
                                  *ngFor="let emailAddress of limitedList"
                                  [innerText]="emailAddress.email"></li>
                              </ul>
                            </ng-template>
                          </ddiq-limited-list>
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="company.phoneNumbers?.length">
                        <span class="oiq-regulatory-profile-related-company-data-label oiq-regulatory-profile-hd"
                          >Phone Numbers:</span
                        >
                        <span class="oiq-regulatory-profile-cell">
                          <ddiq-limited-list [list]="company.phoneNumbers" [limit]="3">
                            <ng-template #parentTemplate let-limitedList>
                              <ul class="oiq-regulatory-profile-related-company-phone-numbers">
                                <li
                                  class="oiq-regulatory-profile-related-company-phone-number"
                                  *ngFor="let phone of limitedList"
                                  [innerText]="phone.number"></li>
                              </ul>
                            </ng-template>
                          </ddiq-limited-list>
                        </span>
                      </span>

                      <ng-container *ngIf="company.identificationNumbers?.length">
                        <span
                          class="oiq-regulatory-profile-row"
                          *ngFor="let identificationNumber of company.identificationNumbers">
                          <span
                            class="oiq-regulatory-profile-hd oiq-regulatory-profile-related-company-data-label"
                            [innerText]="(identificationNumber.identificationType | translatef) + ':'">
                          </span>

                          <span
                            class="oiq-regulatory-profile-cell oiq-regulatory-profile-related-company-identification-number"
                            [innerText]="identificationNumber.number">
                          </span>
                        </span>
                      </ng-container>

                      <span class="oiq-regulatory-profile-row" *ngIf="company.alternativeNames?.length">
                        <span class="oiq-regulatory-profile-hd oiq-regulatory-profile-related-company-data-label"
                          >Alternative Names:</span
                        >
                        <span class="oiq-regulatory-profile-cell">
                          <ul class="oiq-regulatory-profile-related-company-alternative-names">
                            <li
                              class="oiq-regulatory-profile-related-company-alternative-name"
                              *ngFor="let name of company.alternativeNames"
                              [innerText]="name"></li>
                          </ul>
                        </span>
                      </span>

                      <ng-container *ngIf="company.additionalData?.length">
                        <span class="oiq-regulatory-profile-row" *ngFor="let additionalData of company.additionalData">
                          <span
                            class="oiq-regulatory-profile-hd oiq-regulatory-profile-related-company-data-label"
                            [innerText]="(additionalData.label | translatef) + ':'">
                          </span>

                          <span
                            class="oiq-regulatory-profile-cell oiq-regulatory-profile-related-company-additional-data"
                            [innerText]="additionalData.value | additionalDataValue">
                          </span>
                        </span>
                      </ng-container>

                      <br *ngIf="!$last" />
                    </li>
                  </ul>
                </div>
              </div>

              <div class="oiq-regulatory-profile-row" *ngIf="profile.relatedPeople?.length">
                <div class="oiq-regulatory-profile-hd">Related People:</div>
                <div class="oiq-regulatory-profile-cell">
                  <ul class="oiq-regulatory-profile-related-people">
                    <li
                      class="oiq-regulatory-profile-related-person"
                      *ngFor="let person of profile.relatedPeople; let $last = last">
                      <span
                        class="oiq-regulatory-profile-related-person-name"
                        *ngIf="person.name && !(person.seedSources?.length && person.seedSources[0].sourceUrl)"
                        [innerText]="person.name">
                      </span>

                      <a
                        class="oiq-regulatory-profile-related-person-source-url"
                        *ngIf="person.name && person.seedSources?.length && person.seedSources[0].sourceUrl"
                        href="{{person.seedSources[0].sourceUrl}}"
                        [innerText]="person.name"
                        target="_blank"
                        rel="noopener noreferrer">
                      </a>

                      <span class="oiq-regulatory-profile-row" *ngIf="person.title">
                        <span class="oiq-regulatory-profile-related-people-data-label oiq-regulatory-profile-hd"
                          >Title:</span
                        >
                        <span
                          class="oiq-regulatory-profile-related-person-title oiq-regulatory-profile-cell"
                          [innerText]="person.title">
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="person.relationship">
                        <span class="oiq-regulatory-profile-related-people-data-label oiq-regulatory-profile-hd"
                          >Relationship:</span
                        >
                        <span
                          class="oiq-regulatory-profile-related-person-rel oiq-regulatory-profile-cell"
                          [innerText]="person.relationship">
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="person.address">
                        <span class="oiq-regulatory-profile-related-people-data-label oiq-regulatory-profile-hd"
                          >Address:</span
                        >
                        <span
                          class="oiq-regulatory-profile-related-person-address oiq-regulatory-profile-cell"
                          [innerText]="person.address | address">
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="person.emailAddresses?.length">
                        <span class="oiq-regulatory-profile-related-people-data-label oiq-regulatory-profile-hd"
                          >E-Mail Addresses:</span
                        >
                        <span class="oiq-regulatory-profile-cell">
                          <ddiq-limited-list [list]="person.emailAddresses" [limit]="3">
                            <ng-template #parentTemplate let-limitedList>
                              <ul class="oiq-regulatory-profile-related-people-email-addresses">
                                <li
                                  class="oiq-regulatory-profile-related-people-email-address"
                                  *ngFor="let emailAddress of limitedList"
                                  [innerText]="emailAddress.email"></li>
                              </ul>
                            </ng-template>
                          </ddiq-limited-list>
                        </span>
                      </span>

                      <span class="oiq-regulatory-profile-row" *ngIf="person.phoneNumbers?.length">
                        <span class="oiq-regulatory-profile-related-people-data-label oiq-regulatory-profile-hd"
                          >Phone Numbers:</span
                        >
                        <span class="oiq-regulatory-profile-cell">
                          <ddiq-limited-list [list]="person.phoneNumbers" [limit]="3">
                            <ng-template #parentTemplate let-limitedList>
                              <ul class="oiq-regulatory-profile-related-people-phone-numbers">
                                <li
                                  class="oiq-regulatory-profile-related-people-phone-number"
                                  *ngFor="let phone of limitedList"
                                  [innerText]="phone.number"></li>
                              </ul>
                            </ng-template>
                          </ddiq-limited-list>
                        </span>
                      </span>

                      <ng-container *ngIf="person.identificationNumbers?.length">
                        <span
                          class="oiq-regulatory-profile-row"
                          *ngFor="let identificationNumber of person.identificationNumbers">
                          <span
                            class="oiq-regulatory-profile-hd oiq-regulatory-profile-related-people-data-label"
                            [innerText]="(identificationNumber.identificationType | translatef) + ':'">
                          </span>

                          <span
                            class="oiq-regulatory-profile-cell oiq-regulatory-profile-related-people-identification-number"
                            [innerText]="identificationNumber.number">
                          </span>
                        </span>
                      </ng-container>

                      <span class="oiq-regulatory-profile-row" *ngIf="person.alternativeNames?.length">
                        <span class="oiq-regulatory-profile-hd oiq-regulatory-profile-related-people-data-label"
                          >Alternative Names:</span
                        >
                        <span class="oiq-regulatory-profile-cell">
                          <ul class="oiq-regulatory-profile-related-person-alternative-names">
                            <li
                              class="oiq-regulatory-profile-related-people-alternative-name"
                              *ngFor="let name of person.alternativeNames"
                              [innerText]="name"></li>
                          </ul>
                        </span>
                      </span>

                      <ng-container *ngIf="person.additionalData?.length">
                        <span class="oiq-regulatory-profile-row" *ngFor="let additionalData of person.additionalData">
                          <span
                            class="oiq-regulatory-profile-hd oiq-regulatory-profile-related-people-data-label"
                            [innerText]="(additionalData.label | translatef) + ':'">
                          </span>

                          <span
                            class="oiq-regulatory-profile-cell oiq-regulatory-profile-related-people-additional-data-value"
                            [innerText]="additionalData.value">
                          </span>
                        </span>
                      </ng-container>

                      <br *ngIf="!$last" />
                    </li>
                  </ul>
                </div>
              </div>

              <ng-container *ngIf="profile.additionalData?.length">
                <div class="oiq-regulatory-profile-row" *ngFor="let additionalData of profile.additionalData">
                  <div
                    class="oiq-regulatory-profile-additional-data-label oiq-regulatory-profile-hd"
                    [innerText]="(additionalData.label | translatef) + ':'"></div>

                  <div class="oiq-regulatory-profile-cell">
                    <ul class="oiq-regulatory-profile-additional-data">
                      <li
                        class="oiq-regulatory-profile-additional-data-value"
                        *ngFor="let value of additionalData.value"
                        [innerText]="value | newlineToBr"></li>
                    </ul>
                  </div>
                </div>
              </ng-container>
            </article>
          </ddiq-collapsible>

          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'REGULATORY_AGENCY_PROFILE'"
            [entityType]="entityType"
            [entityId]="entityId"
            [noReassess]="noReassess"
            [targetId]="profile.id"
            [model]="profile"
            [label]="'Notes'"
            [active]="profile.comment">
          </ddiq-adjudication-notes>
        </div>

        <div
          class="oiq-regulatory-profile-toggle oiq-grid-cell"
          [ngClass]="{disabled: profile.disabled}"
          *ngIf="hasAdditionalData">
          <a class="oiq-regulatory-profile-show-all-data-toggle" data-oiq-collapsible-toggle>
            <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
            Show All Data
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
