import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import ProfileDelegation from './profile-delegation.service';
import { OrderByPipe } from '../../../../../shared/pipes/order-by.pipe';
import WithRolePipe from '../ddiq-filters/with-role.pipe';
import { FilterByPipe } from 'ngx-pipes';
import Monitor from '../monitor/monitor.service';

@Component({
  templateUrl: 'delegate-profile.component.tpl.html',
  selector: 'delegate-profile',
})
export default class DelegateProfileComponent implements OnInit {
  users: any[] = [];
  userSearch: string = '';
  currentOwner: string = '';
  isInFlight: boolean;
  selectedUser: any;
  isMonitored: any;
  errorMsg: any;

  @Output() onAssigned = new EventEmitter<any>();
  @Output() onCancelled = new EventEmitter<any>();
  @Output() onAssignError = new EventEmitter<any>();
  @Input() entityType: any;
  @Input() entityId: any;

  constructor(
    private monitor: Monitor,
    private profileDelegation: ProfileDelegation,
    private orderByPipe: OrderByPipe,
    private withRolePipe: WithRolePipe,
    private filterByPipe: FilterByPipe
  ) {}

  ngOnInit() {
    this.isInFlight = true;

    Promise.all([
      this.profileDelegation.getDelegates(this.entityType, this.entityId),
      this.monitor.loadMonitor(this.entityType, this.entityId),
    ])
      .then((response) => {
        const delegates = response[0],
          isProfileMonitored = response[1];

        this.currentOwner = delegates.currentOwner;
        this.selectedUser = delegates.currentOwner;
        this.users = this.getUsers(delegates.users, isProfileMonitored);
        this.isMonitored = isProfileMonitored;
      })
      .finally(() => (this.isInFlight = false));
  }

  filterUsers() {
    let filtered;

    if (this.userSearch) {
      filtered = this.filterByPipe.transform(this.users, ['fullName', 'username', 'emailAddress'], this.userSearch);
      this.selectedUser = this.getSelectedUsernameFromUsers(filtered);
    } else {
      filtered = this.users;
      this.selectedUser = this.selectedUser || this.currentOwner;
    }

    return filtered;
  }

  assign() {
    this.isInFlight = true;

    this.profileDelegation
      .assign(this.entityType, this.entityId, {
        oldOwner: this.currentOwner,
        newOwner: this.selectedUser,
      })
      .then(
        (assignedTo) => {
          this.currentOwner = assignedTo;
          this.onAssigned.emit({
            assignedTo: DelegateProfileComponent.findUserByUsername(this.currentOwner, this.users),
          });
          this.userSearch = '';
        },
        (error) => {
          let userOwner,
            errorInfo = { errorMsg: error.errorMsg, owner: undefined };

          userOwner = DelegateProfileComponent.findUserByUsername(error.owner, this.users);

          if (userOwner) {
            errorInfo.owner = userOwner;
          }

          this.errorMsg = error.errorMsg;
          this.onAssignError.emit({ errorInfo });
        }
      )
      .finally(() => (this.isInFlight = false));
  }

  cancel() {
    this.userSearch = '';
    this.onCancelled.emit();
  }

  isDisabled() {
    return !!(!this.selectedUser || this.selectedUser === this.currentOwner || this.isInFlight);
  }

  getSelectedUsernameFromUsers(filtered) {
    let selectedUsername;

    if (this.selectedUser) {
      // user has made a selection from filtered users
      selectedUsername = this.selectedUser;
    } else {
      selectedUsername = (filtered[0] && filtered[0].username) || '';
    }

    return selectedUsername;
  }

  private getUsers(users, isProfileMonitored) {
    let filtered;

    if (isProfileMonitored) {
      filtered = this.withRolePipe.transform(users, 'role_monitor');
    } else {
      filtered = users;
    }

    return this.orderByPipe.transform(filtered, 'fullName');
  }

  private static findUserByUsername(username, users) {
    let i, currentUser;

    for (i = 0; i < users.length; i++) {
      currentUser = users[i];
      if (currentUser.username === username) {
        break;
      }
    }
    return currentUser;
  }
}
