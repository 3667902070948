import { Component, Input, OnInit } from '@angular/core';
import SupportContactInfoService from './support-contact-info.service';

@Component({
  selector: 'support-contact-email',
  templateUrl: './support-contact-email.component.tpl.html',
})
export default class SupportContactEmailComponent implements OnInit {
  @Input() subject;
  @Input() text;
  @Input() emailBody;
  contactInfo;
  anchorText;

  constructor(private supportContactInfoService: SupportContactInfoService) {}

  ngOnInit(): void {
    this.contactInfo = this.supportContactInfoService.getContactInfo();
    this.anchorText = this.text || this.contactInfo.email;
  }

  getMailToParams() {
    const mailToParams = [];

    if (this.subject) {
      mailToParams.push(`Subject=${this.subject}`);
    }

    if (this.emailBody) {
      mailToParams.push(`Body=${this.emailBody}`);
    }

    let params = mailToParams.join('&');
    if (params) {
      params = `?${params}`;
    }

    return params;
  }
}
