<aside id="aside" class="side-nav prof-sb stick" #scrollFixed="scrollFixed" scroll-fixed>
  <div
    class="profile-nav"
    [ngClass]="{'profile-nav-filters-open': isShowFilters, 'profile-nav-filters-closed': !isShowFilters}">
    <div class="profile-nav-search-container">
      <i class="fa fa-search profile-nav-search-icon" aria-hidden="true"></i>
      <input
        class="profile-nav-search-field"
        type="search"
        placeholder="Find event names"
        [(ngModel)]="eventNameSearch"
        title="Start typing to find event names" />
    </div>

    <ul class="profile-menu" ddiq-scrollbar ddiq-scrollbar-auto-hide>
      <li *ngFor="let section of showSectionNav(sections); let sectionIndex = index;" class="menu-item">
        <div
          class="section-heading"
          ddiq-scroll-into-view
          [shouldScroll]="scrollFixed.inView === sectionIndex"
          scrollto
          [attr.href]="section.metadata.sectionId"
          [href]="section.metadata.sectionId"
          (click)="section.metadata.toggleSection( true, section.metadata.navLinkText )"
          offset-adjust="130"
          [ngClass]="{active: (scrollFixed.inView == sectionIndex), nocontent: !sectionHasContent(section)}">
          <a>{{section.metadata.navLinkText }}</a>
        </div>

        <div class="section-filter" *ngIf="sectionFilter(section).labels.length">
          <ul class="filter-list">
            <li
              class="filter-list-item"
              [ngClass]="{ 'filter-list-item-active': isEventActive(label, section.metadata.sectionId) }"
              *ngFor="let label of sectionFilter(section).labels | filterBy:['label']: eventNameSearch; trackBy: trackById.bind(this, section)">
              <span
                class="filter-list-nav"
                scrollto
                href="{{section.metadata.sectionId}}_{{label.labelKey | validateHtmlIds}}"
                offset-adjust="130"
                title="{{label.label}}"
                (click)="classificationClick(label, section.metadata.sectionId)">
                <span class="inline no-upper checked">{{label.label}}</span>
                <span
                  class="badge badge-info filter-list-nav-count"
                  [textContent]="getEventCountBadgeText(section.metadata.sectionId, label)"
                  [title]="getEventCountBadgeTitleText(section.metadata.sectionId, label)">
                </span>
              </span>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <div class="profile-nav-filters">
      <div class="profile-nav-filters-toggles">
        <a
          class="profile-nav-filters-toggle"
          [ngClass]="{ 'profile-nav-filters-on': isFiltered }"
          (click)="toggleFilterSection()"
          title="Filter events in this profile">
          <span class="profile-nav-filters-toggle-icon"> <i class="fa fa-filter" aria-hidden="true"></i>Filters </span>

          <i
            class="fa fa-chevron-up"
            [ngClass]="{'fa-chevron-up': !isShowFilters, 'fa-chevron-down': isShowFilters}"
            aria-hidden="true"></i>
        </a>
      </div>

      <div class="profile-nav-filters-content" [hidden]="!isShowFilters">
        <ddiq-nav-filter></ddiq-nav-filter>
      </div>
    </div>
  </div>
</aside>
