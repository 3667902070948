import { Component, Input, OnInit } from '@angular/core';
import OiqPropertiesService from '../common-services/oiq-properties.service';

@Component({
  selector: 'correlation-details',
  templateUrl: './correlation-details.component.tpl.html',
})
export default class CorrelationDetailsComponent implements OnInit {
  @Input() seedData;
  correlationDetails = {};
  showCorrelationDetails;

  constructor(private oiqProperties: OiqPropertiesService) {}

  ngOnInit() {
    this.setDisplayableCorrelationDetails();
    this.showCorrelationDetails = this.canShowCorrelationDetails();
  }

  setDisplayableCorrelationDetails() {
    if (this.seedData && this.seedData.correlationDetails) {
      const correlationDetails = this.seedData.correlationDetails;

      Object.keys(correlationDetails).forEach((propKey) => {
        const detailValue = correlationDetails[propKey];

        if (!Array.isArray(detailValue) && typeof detailValue !== 'object') {
          this.correlationDetails[propKey] = detailValue;
        }
      });
    }
  }

  canShowCorrelationDetails() {
    return this.oiqProperties.showCorrelationDetails === true && !!Object.keys(this.correlationDetails).length;
  }
}
