import { Component, Inject, Input } from '@angular/core';
import Entity from '../common-services/entity.service';
import OiqPropertiesService from '../common-services/oiq-properties.service';
import PdfFeatureService from '../pdf/pdf-feature.service';
import SeedDataService from '../themed-entity/seed-data.service';

@Component({
  selector: 'seed-data-print-view',
  templateUrl: './seed-data-print-view.component.tpl.html',
})
export default class SeedDataPrintViewComponent {
  @Input() hideComments;
  @Input() seedData;
  @Input() section;

  isPerson;
  isCompany;
  isPdfRender;
  isShowCaseComments;
  isProfileCollectionEnabled;

  constructor(
    private seedDataService: SeedDataService,
    private pdfFeature: PdfFeatureService,
    private entity: Entity,
    private oiqProperties: OiqPropertiesService
  ) {
    this.isPerson = this.entity.isPerson();
    this.isCompany = this.entity.isCompany();
    this.isPdfRender = this.pdfFeature.isPdfRender();
    this.isShowCaseComments = this.pdfFeature.showCaseComments();
    this.isProfileCollectionEnabled = this.oiqProperties.isProfileCollectionEnabled;
  }

  showComments(adjudication) {
    return this.isShowCaseComments && !this.hideComments && !!SeedDataPrintViewComponent.getComments(adjudication);
  }

  hasAdditionalInputData(seedData) {
    return this.seedDataService.hasAdditionalInputData(seedData);
  }

  static getComments(adjudication) {
    return adjudication && adjudication.current && adjudication.current.comment;
  }

  trackByIndex(index) {
    return index;
  }
}
