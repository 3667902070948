import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat',
})
export default class DateFormatPipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  /**
   * Converts input object to string formatted date based on provided Keys in input
   * @param input
   * {
   *    month: NUMBER,
   *    day: NUMBER,
   *    year: NUMBER
   * }
   * @param defaultValue
   * @returns {String}
   */
  transform(input: any, defaultValue?: any) {
    let mmt,
      isUTCMidnight,
      fixedInput = input,
      formatted = defaultValue;

    if (!input) {
      return;
    }

    if (typeof input === 'object' && input.month) {
      fixedInput = { ...input };
      fixedInput.month = fixedInput.month - 1;
    }

    mmt = moment(fixedInput);
    mmt.utc();
    isUTCMidnight = !mmt.hour() && !mmt.seconds() && !mmt.milliseconds();

    if (isUTCMidnight && input.year && input.month && input.day) {
      // time is midnight UTC and this is a complete date... leave in UTC for formatting
      // ll = Month name, day of month, year (localized with short month name)
      formatted = mmt.format('ll');
    } else if (typeof input === 'object') {
      //not at midnight UTC... convert back to local to avoid date boundary issues
      mmt.local();
      if (input.year && input.month && input.day) {
        formatted = mmt.format('MMM DD Y');
      } else if (!input.year && input.month && input.day) {
        formatted = mmt.format('MMM DD');
      } else if (input.year && input.month && !input.day) {
        formatted = mmt.format('MMM Y');
      } else if (input.year) {
        formatted = mmt.format('Y');
      } else {
        // must be a Date object... mediumDate = MMM d, y
        formatted = this.date.transform(input, 'mediumDate');
      }
    } else {
      // mediumDate = MMM d, y
      formatted = this.date.transform(input, 'mediumDate', 'UTC');
    }

    return formatted;
  }
}
