<div #entityAware="oiqEntityAware" oiq-entity-aware>
  <ng-container *ngFor="let group of groups.list">
    <article
      id="{{idPrefix}}_{{'PRODUCT_RECALL' | validateHtmlIds}}"
      *ngIf="group.hasDiscoveries"
      data-oiq-collapsible-ctrl
      [expandAll]="expandAll || group.isEventGroupOpen"
      class="panel oiq-product-recalls discovery-group">
      <header class="panel-header oiq-product-recalls-header">
        <oiq-group-dynamic-adjudication-aware
          [adjudicationType]="'PRODUCT_RECALL'"
          [section]="idPrefix"
          [classification]="'PRODUCT_RECALL'">
        </oiq-group-dynamic-adjudication-aware>

        <h3 class="panel-title oiq-product-recalls-title">{{group.title}}</h3>

        <div class="panel-header-right">
          <button class="btn btn-sm btn-default" *ngIf="group.hasMoreEntries" data-oiq-collapsible-toggle>
            <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
            View all {{(group.discoveries | moreEntries).length + 1}} events
          </button>
        </div>
      </header>

      <section class="panel-body">
        <article
          class="oiq-product-recalls-featured-incident"
          *ngFor="let featured of group.discoveries | firstUnfilteredDiscovery">
          <ddiq-product-recall
            [recall]="featured"
            [isAdjudicateable]="entityAware.isAdjudicatable"></ddiq-product-recall>
        </article>

        <article class="oiq-product-recalls-more-entries discovery-group" *ngIf="group.hasMoreEntries">
          <ddiq-collapsible>
            <ddiq-product-recall
              class="oiq-product-recalls-more-entry"
              *ngFor="let entry of group.discoveries | moreEntries; trackBy:entryTrackBy"
              [recall]="entry"
              [isAdjudicateable]="entityAware.isAdjudicatable">
            </ddiq-product-recall>
          </ddiq-collapsible>
        </article>
      </section>
    </article>
  </ng-container>
</div>

<p *ngIf="groups.list.length === 0" class="oiq-product-recalls-msg-no-results">No results discovered</p>

<p *ngIf="groups.showAllFilteredMessage" class="oiq-product-recalls-msg-all-filtered">All results filtered</p>
