import { Injectable } from '@angular/core';
import ModalTrackingService from '../modal/modal-tracking.service';

@Injectable({
  providedIn: 'root',
})
export default class DdiqProfileCollectionMissingPermissionForSubmissionService {
  constructor(private modalTrackingService: ModalTrackingService) {}

  showError() {
    this.modalTrackingService.getTriggers().buildProfilePermissionMissing.open = true;
  }

  cancel() {
    this.modalTrackingService.getTriggers().buildProfilePermissionMissing.open = false;
  }
}
