import { Component, OnInit } from '@angular/core';
import ToArrayPipe from '../ddiq-filters/to-array.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ddiq-configuration',
  templateUrl: './ddiq-configuration.component.tpl.html',
})
export default class ConfigurationComponent implements OnInit {
  oiqConfigurationInfo = {
    features: [],
    rawConfiguration: [],
  };

  constructor(private toArrayPipe: ToArrayPipe, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      const configuration = data.preLoad[0];

      (Object.keys(configuration) || []).forEach((item) => {
        this.oiqConfigurationInfo[item] = this.toArrayPipe.transform(configuration[item]);
      });
    });
  }
}
