<div [ngSwitch]="locations.count > 0">
  <div *ngSwitchCase="true">
    <ddiq-accordion id="related_PROPERTY" [label]="'Locations'" [alerts]="listAlertCount" openonprint>
      <ddiq-location-list
        [title]="'User Submitted'"
        [locations]="locations.submitted"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-location-list>
      <ddiq-location-list
        [title]="'Discovered'"
        [locations]="locations.discovered"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-location-list>
      <ddiq-location-list
        [title]="'Referenced'"
        [locations]="locations.referenced"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-location-list>
    </ddiq-accordion>
  </div>
</div>
