import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import OiqProperties from '../common-services/oiq-properties.service';
import ApiFetchService from '../common-services/api-fetch.service';
import UserService from '../user/user.service';

@Injectable({ providedIn: 'root' })
export default class ProfileLibraryResolverService implements Resolve<any> {
  constructor(
    private oiqProperties: OiqProperties,
    private apiFetchService: ApiFetchService,
    private user: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return Promise.all([
      this.oiqProperties.apiPropertiesPromise,
      this.getGroupUsers(),
      this.apiFetchService.getVisibleCrawlPlans(),
      this.getList(),
    ]);
  }

  getGroupUsers() {
    if (!this.user.isInGroup()) {
      return Promise.resolve({});
    }
    return this.apiFetchService.usersInGroup(this.user.getGroup());
  }

  getList() {
    return this.apiFetchService.getProfileCollections().then((data) => {
      return data.results;
    });
  }
}
