import { Component, DoCheck, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import utils from '../../../exiger/utils';
import NavTrackingService from '../nav/nav-tracking.service';

@Component({
  selector: 'ddiq-accordion-top',
  templateUrl: 'ddiq-accordion-top.component.tpl.html',
})
export default class AccordionTop implements OnInit, OnDestroy, DoCheck {
  @Input() open: boolean;
  @Input() disable: boolean;
  @Input() count: number;

  @Input() filteredCount: number;
  @Input() escalatedCount: number;
  @Input() filteredEscalatedCount: number;
  @Input() alerts: any;
  @Input() toggleScope: any;

  isOpen: boolean;
  accTitle: string;
  private window: Window;
  private element: Element;
  private openAttribute: string;
  private isOpenPreviousValue: boolean;
  private toggleFnAttribute: any;

  constructor(
    private windowRef: WindowRefService,
    private elementRef: ElementRef,
    private navTrackingService: NavTrackingService
  ) {
    this.window = windowRef.nativeWindow;
    this.element = elementRef.nativeElement;
  }

  ngOnInit() {
    this.accTitle = this.element.getAttribute('label');
    if (typeof this.element.getAttribute('filteredCount') === 'undefined') {
      this.filteredCount = -1;
    }
    if (typeof this.element.getAttribute('filteredEscalatedCount') === 'undefined') {
      this.filteredEscalatedCount = -1;
    }

    let toggledOn, parentObject, toggleFunctionName, defaultOpen;

    const printStyle = utils.getParameter('print', this.window.location.href);

    if (printStyle) {
      defaultOpen = true;
    }

    this.openAttribute = this.element.getAttribute('open');

    this.toggleFnAttribute = this.element.getAttribute('toggle-function');
    this.isOpen = defaultOpen || this.openAttribute === '' || this.open;

    toggleFunctionName = this.getToggleFunctionName();
    parentObject = this.getToggleFunctionScope();

    parentObject[toggleFunctionName] = (onOff) => {
      toggledOn = typeof onOff === 'undefined' ? !toggledOn : onOff;
      this.toggleElement(toggledOn);

      this.navTrackingService.setEventActive('', '');
    };
  }

  ngOnDestroy() {
    const parentObject = this.getToggleFunctionScope();
    const toggleFunctionName = this.getToggleFunctionName();
    parentObject[toggleFunctionName] = null;
  }

  ngDoCheck() {
    if (this.openAttribute !== '') {
      const currentValue = this.open;
      if (currentValue !== this.isOpenPreviousValue) {
        this.toggleElement(currentValue);
        this.isOpenPreviousValue = !!currentValue;
      }
    }
  }

  toggleElement(onOff?) {
    if (typeof onOff !== 'undefined') {
      this.isOpen = onOff;
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  private getToggleFunctionScope() {
    return typeof this.toggleScope !== 'undefined' ? this.toggleScope : this;
  }

  private getToggleFunctionName() {
    return typeof this.toggleFnAttribute === 'undefined' || this.toggleFnAttribute === null
      ? 'toggleSection'
      : this.toggleFnAttribute;
  }
}
