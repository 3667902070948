import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class SupportContactInfoService {
  contactInfo;
  constructor() {}
  getContactInfo() {
    return this.contactInfo;
  }

  setConfiguredContactInfo(contactInfo) {
    this.contactInfo = contactInfo;
  }
}
