import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import AdditionalLocationSubmissionPipe from '../ddiq-filters/additional-location-submission.pipe';
import SubmittedPeoplePipe from '../profile-input-details/submitted-people.pipe';
import PreviousAddressesPipe from '../profile-input-details/previous-addresses.pipe';

@Injectable({
  providedIn: 'root',
})
export default class SeedDataService {
  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private additionalLocationSubmissionPipe: AdditionalLocationSubmissionPipe,
    private submittedPeoplePipe: SubmittedPeoplePipe,
    private previousAddressesPipe: PreviousAddressesPipe
  ) {}

  fetchInputDataForProfile(isMonitoringEnabled = false) {
    if (isMonitoringEnabled) {
      return this.apiFetchService.getMonitorSeedData(this.entity.getType(), this.entity.getId());
    }
    return this.apiFetchService.seedData(this.entity.getType(), this.entity.getId());
  }

  getCompanySeedData(entityId) {
    return this.apiFetchService.seedData('company', entityId);
  }

  hasAdditionalInputData(seedData) {
    return !!(
      seedData &&
      (seedData.duns ||
        seedData.organizationType ||
        seedData.yearFounded ||
        seedData.sex ||
        seedData.dateOfInterest ||
        this.hasAdditionalLocations(seedData) ||
        this.hasSubmittedPeople(seedData) ||
        this.hasPreviousAddresses(seedData) ||
        this.hasCorrelationDetails(seedData) ||
        this.hasEntries(seedData, 'formerNames') ||
        this.hasEntries(seedData, 'phoneNumbers') ||
        this.hasEntries(seedData, 'emailAddresses') ||
        this.hasEntries(seedData, 'companies') ||
        this.hasEntries(seedData, 'relatedCompanies') ||
        this.hasEntries(seedData, 'requestedLanguages') ||
        this.hasEntries(seedData, 'relatedPeople') ||
        this.hasEntries(seedData, 'education') ||
        this.hasEntries(seedData, 'requestedLanguages') ||
        this.hasEntries(seedData, 'parts') ||
        this.hasEntries(seedData, 'positions'))
    );
  }

  hasAdditionalLocations(seedData) {
    return !!this.additionalLocationSubmissionPipe.transform(seedData.otherLocations).length;
  }

  hasSubmittedPeople(seedData) {
    return !!this.submittedPeoplePipe.transform(seedData.people).length;
  }

  hasPreviousAddresses(seedData) {
    return !!this.previousAddressesPipe.transform(seedData.previousAddresses).length;
  }

  hasCorrelationDetails(seedData) {
    return this.oiqProperties.showCorrelationDetails && seedData.correlationDetails;
  }

  hasEntries(seedData, field) {
    const entries = seedData && seedData[field];
    return !!(Array.isArray(entries) && entries.length);
  }
}
