import { NgModule } from '@angular/core';
import delegateProfileComponent from './delegate-profile.component';
import ProfileDelegation from './profile-delegation.service';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { OrderByPipe } from '../../../../../shared/pipes/order-by.pipe';
import WithRolePipe from '../ddiq-filters/with-role.pipe';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import { FormsModule } from '@angular/forms';
import { FilterByPipe } from 'ngx-pipes';

@NgModule({
  imports: [CommonModule, PipesModule, FormsModule],
  declarations: [delegateProfileComponent],
  providers: [OrderByPipe, WithRolePipe, FilterByPipe, TranslatefPipe, ProfileDelegation],
  exports: [delegateProfileComponent],
})
export class ProfileDelegationModule {}
