import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-alert-list',
  templateUrl: './ddiq-alert-list.component.tpl.html',
})
export default class AlertListComponent {
  @Input() source;

  constructor() {}
}
