import { Directive, Input, OnInit } from '@angular/core';
import CheckContentService from '../common-services/check-content.service';
import Entity from '../common-services/entity.service';
import ApiFetchService from '../common-services/api-fetch.service';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Directive({
  selector: '[load-source]',
  exportAs: 'loadSource',
})
export default class LoadSourceDirective implements OnInit {
  private window;
  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private checkContentService: CheckContentService,
    private errorHandler: ErrorHandler,
    private windowRef: WindowRefService
  ) {
    this.window = this.windowRef.nativeWindow;
  }
  @Input() saveIn;
  @Input() exclude;
  ngOnInit() {
    this.apiFetchService.source(this.entity.getType(), this.entity.getId()).then(
      (data) => {
        this.loadAllSources(data);
      },
      () => {
        this.fetchFail();
      }
    );
  }

  loadAllSources(data) {
    if (this.exclude) {
      this.exclude.split(',').forEach((source) => {
        delete data[source];
      });
    }

    if (this.saveIn === undefined) {
      this.errorHandler.log('sources need a defined object for storage');
      return;
    }

    // iterate over data values
    for (let source in data) {
      if (data.hasOwnProperty(source)) {
        this.saveIn[source] = data[source];

        // check to see if there is any content in data from api
        if (this.checkContentService.checkForContent(data[source], true)) {
          this.saveIn[source].hasContent = true;
          this.saveIn.sourceCount++;
        }
      }
    }
    this.saveIn.hasContent = this.saveIn.sourceCount > 0;

    if (!this.entity.isProperty()) {
      setTimeout(() => {
        this.pageComplete();
      }, 3000);
    }
  }

  fetchFail() {
    this.errorHandler.log('loadAlSources fetch failed');
  }

  pageComplete() {
    this.window.status = 'oiqLoadComplete';
  }
}
