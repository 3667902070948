import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class SelectedArticlesFeatureService {
  private enabled;

  setEnabled(enabled) {
    this.enabled = enabled;
  }

  isEnabled() {
    return !!this.enabled;
  }
}
