<span class="btn-sort-group" *ngIf="moreThanOne">
  <button
    *ngFor="let button of buttons"
    class="btn-sort-{{button.btnClass}}"
    type="button"
    title="{{button.title}}"
    [ngClass]="{'btn-sort-active': button.active, 'btn-sort': !button.active}"
    (click)="btnClicked(button)">
    <i class="fa fa-sort-{{button.fontAwesome}}"></i>{{button.text}}
  </button>
</span>
