import { Component, OnDestroy, OnInit } from '@angular/core';
import Entity from '../common-services/entity.service';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import RelationshipFilter from '../themed-entity/relationship-filter.service';
import RelationshipService from '../common-services/relationship-service.factory';
import RelatedEntityProcessor from '../themed-entity/related-entity-processor.service';
import PersonBuildService from '../themed-entity/person-build.service';
import { Router } from '@angular/router';
const entityType = 'person';

@Component({
  selector: 'ddiq-related-people',
  templateUrl: './ddiq-related-people.component.tpl.html',
})
export default class DdiqRelatedPeopleComponent implements OnInit, OnDestroy {
  personProcessor: any;
  people: any = {};
  alertsEnabled: boolean;
  isPersonSubmissionEnabled: boolean;
  isRelatedEntityAdjudicationEnabled: boolean;
  filterObserver: any;
  listAlertCount: number;

  constructor(
    private entity: Entity,
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private adjudicationFeature: AdjudicationFeatureService,
    private personBuildService: PersonBuildService,
    private relatedEntityProcessor: RelatedEntityProcessor,
    private relationshipService: RelationshipService,
    private relationshipFilter: RelationshipFilter,
    private router: Router
  ) {}

  ngOnInit() {
    this.personProcessor = this.relatedEntityProcessor.for(entityType);
    this.alertsEnabled = this.oiqProperties.subPersonAlertsEnabled;
    this.isPersonSubmissionEnabled = this.oiqProperties.personSubmissionEnabled;
    this.isRelatedEntityAdjudicationEnabled =
      this.adjudicationFeature.isRelatedEntityAdjudicationEnabled() && this.entity.isAdjudicatableByUser();

    this.filterObserver = () => this.initPersonList(false);
    this.relationshipFilter.registerAfterFilterObserver(this.filterObserver);

    this.initPersonList();
  }

  ngOnDestroy() {
    this.relationshipFilter.unRegisterAfterFilterObserver(this.filterObserver);
  }

  refresh() {
    this.initPersonList(true);
  }

  initPersonList(forceReload?) {
    if (forceReload) {
      this.relationshipService.people(this.entity.getType(), this.entity.getId(), forceReload).then(() => {
        this.setPeopleData();
      });
      return;
    }

    this.setPeopleData();
  }

  setPeopleData() {
    let people;
    if (!this.entity.isDiff()) {
      people = this.relationshipService.getPeople();
    } else {
      people = this.relationshipService.getPeopleDiff();
    }
    this.processPeople(people);
  }

  processPeople(peeps) {
    peeps = this.personProcessor.processEntities(peeps);

    this.listAlertCount = this.alertsEnabled ? this.personProcessor.countAlerts(peeps) : 0;

    this.people = this.bucketRelatedPeople(peeps);
    this.people.count =
      this.people.submitted.length +
      this.people.owner.length +
      this.people.management.length +
      this.people.employed.length +
      this.people.family.length +
      this.people.referenced.length;

    this.relationshipService.setPersonCount(this.people.count);

    return peeps;
  }

  // lets do some prebucketing to save some DOM filtering
  bucketRelatedPeople(relatedPeople) {
    const owner = 'Owner',
      keyManagement = 'KeyManagementPersonnel',
      employs = 'Employs',
      familyMember = 'FamilyMember',
      associatedWith = 'AssociatedWith';

    return this.personProcessor.getBucketedEntities(relatedPeople, [
      {
        bucket: 'submitted',
        matchEntity: (entity) => this.personProcessor.isSubmitted(entity),
        onBucketed: (entity) => {
          if (entity.relationships.length > 0) {
            let relationshipName = associatedWith;
            if (entity.relationships[0].entityType === 'Company') {
              relationshipName = employs;
            }
            const onBucketHandler = this.onBucketed(relationshipName, 'SUBMITTED');
            onBucketHandler(entity);
          }
        },
      },
      {
        bucket: 'owner',
        matchEntity: (entity) => {
          return this.personProcessor.isRelationship(entity, owner) && !this.oiqProperties.hideDiscoveredPeople;
        },
        onBucketed: this.onBucketed(owner),
      },
      {
        bucket: 'management',
        matchEntity: (entity) => {
          return this.personProcessor.isRelationship(entity, keyManagement) && !this.oiqProperties.hideDiscoveredPeople;
        },
        onBucketed: this.onBucketed(keyManagement),
      },
      {
        bucket: 'employed',
        matchEntity: (entity) => {
          return this.personProcessor.isRelationship(entity, employs) && !this.oiqProperties.hideDiscoveredPeople;
        },
        onBucketed: this.onBucketed(employs),
      },
      {
        bucket: 'family',
        matchEntity: (entity) => {
          return this.personProcessor.isRelationship(entity, familyMember) && !this.oiqProperties.hideDiscoveredPeople;
        },
        onBucketed: this.onBucketed(familyMember),
      },
      {
        bucket: 'referenced',
        matchEntity: (entity) => {
          return (
            this.personProcessor.isRelationship(entity, associatedWith) &&
            !this.oiqProperties.hideDiscoveredPeople &&
            !this.oiqProperties.relationships.disableAssociatedPeople
          );
        },
        onBucketed: this.onBucketed(associatedWith),
      },
    ]);
  }

  onBucketed(relationshipName, sourceInfoName = relationshipName) {
    return (entity) => {
      entity.relationship = this.personProcessor.getRelationship(entity.relationships, relationshipName);
      entity.escalated = entity.relationship?.escalated;
      this.personProcessor.addSourceInfo(entity, sourceInfoName);
      this.relationshipFilter.applyFilters(entity);
    };
  }

  requestReport(person) {
    const parent = this.entity.getData();

    this.relationshipService.clear();
    person.crawlStatus = 'IN_PROGRESS';

    this.apiFetchService.entity(entityType, person.oiqEntityId).then(
      (stubPerson) => {
        stubPerson.parentPersonEntityId = person.oiqEntityId;
        stubPerson.externalReferenceCode = parent.externalReferenceCode;

        if (this.entity.isPerson() && parent.location && !stubPerson.location) {
          stubPerson.location = { country: parent.location.country };
        } else if (this.entity.isCompany() && parent.mainAddress && !stubPerson.location) {
          stubPerson.location = { country: parent.mainAddress.country };
        }

        this.personBuildService.queuePersonToBuild(stubPerson);
        this.personBuildService.setParentUrl(this.router.url);
        this.router.navigate(['/build/person']);
      },
      function () {
        // TODO: OIQ-9791 MDG: Error Handling
        person.crawlStatus = '';
      }
    );
  }
}
