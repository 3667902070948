import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import ApiFetchService from '../common-services/api-fetch.service';
import UserService from '../user/user.service';
import OktaAuthService from '../common-services/okta-auth.service';
import OiqProperties from '../common-services/oiq-properties.service';
import './img/loader.gif';

@Component({
  selector: 'ddiq-admin',
  templateUrl: './ddiq-admin.component.tpl.html',
})
export default class AdminComponent implements OnInit, OnDestroy {
  passwordValidationPattern = /^(?=.{9,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/;
  showToken: any;
  refreshToken: string = '';
  refreshTokenLoading: boolean = false;
  refreshTokenError: boolean = false;
  showLogin: boolean = false;
  loggedInUser: any;
  editingUser: any = {};
  isLoaded: boolean = false;
  canRenderRefreshTokens: boolean;
  loginConfirmed: Subscription;

  constructor(
    private messageBus: MessageBusService,
    private apiFetchService: ApiFetchService,
    private user: UserService,
    private oktaAuthService: OktaAuthService,
    private oiqProperties: OiqProperties,
    private windowRefService: WindowRefService
  ) {}

  ngOnInit() {
    this.loginConfirmed = this.messageBus.on('event:auth-loginConfirmed', () => {
      this.editingUser = {};
      this.initialLoad();
    });
    this.initialLoad();
    this.handleAuthorizationCode();
  }

  ngOnDestroy(): void {
    this.loginConfirmed.unsubscribe();
  }

  refreshUserData(user) {
    return this.apiFetchService.user(user).then((data) => {
      this.loggedInUser = data;
      return this.apiFetchService.userUsage(user).then((data) => {
        this.loggedInUser.usage = data;
      });
    });
  }

  initialLoad() {
    this.refreshUserData(this.getLoggedInUserName()).then(() => {
      this.isLoaded = true;
      this.canRenderRefreshTokens =
        this.oiqProperties.isSsoEnabled &&
        this.oiqProperties.isOAuthEnabled &&
        this.loggedInUser.roles.includes('role_api_submitter');
    });
  }

  getLoggedInUserName() {
    return this.user.getUserName();
  }

  editUserPassword() {
    const user = this.getLoggedInUserName();
    this.editingUser = {
      username: user,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      type: 'reset',
      error: 'Password must be a minimum 9 characters with: 1 digit, 1 uppercase, 1 lowercase and 1 special character',
    };
  }

  cancelEdit() {
    this.editingUser = {};
  }

  changeUserPassword() {
    this.editingUser.authError = '';
    const newPassword = {
      oldPassword: this.editingUser.oldPassword,
      newPassword: this.editingUser.newPassword,
    };
    this.apiFetchService.updatePassword(this.editingUser.username, newPassword).then(
      () => {
        this.editingUser = {};

        // Since the current session is terminated after the user changes his/her own password,
        // the user will be redirected to the login page to re-login
        window.location.reload();
      },
      () => {
        this.editingUser.authError = 'Incorrect old password';
        this.editingUser.oldPassword = '';
        this.editingUser.newPassword = '';
        this.editingUser.newPasswordConfirm = '';
      }
    );
  }

  validPassword() {
    const validPassword = this.passwordValidationPattern.test(this.editingUser.newPassword),
      passwordsMatch = this.editingUser.newPassword === this.editingUser.newPasswordConfirm,
      passwordReused = this.editingUser.newPassword === this.editingUser.oldPassword;

    if (!validPassword) {
      this.editingUser.error =
        'Password must be a minimum 9 characters with: 1 digit, 1 uppercase, 1 lowercase and 1 special character';
    } else if (passwordReused) {
      this.editingUser.error = 'Cannot reuse the same password';
    } else if (passwordsMatch) {
      this.editingUser.error = '';
    } else {
      this.editingUser.error = 'New passwords do not match';
    }

    return (
      validPassword &&
      passwordsMatch &&
      !passwordReused &&
      this.editingUser.newPassword &&
      this.editingUser.newPassword.length > 0
    );
  }

  generateRefreshToken() {
    this.showToken = { open: true };
    this.refreshTokenLoading = true;

    let authCodeParams;

    this.apiFetchService
      .getAuthCodeParams()
      .then((data) => {
        authCodeParams = data;
        if (!authCodeParams.identityProvider) {
          authCodeParams.identityProvider = '';
        }
        this.windowRefService.nativeWindow.sessionStorage.setItem('stateToken', authCodeParams.stateToken);

        this.oktaAuthService.createAuthClient({
          issuer: `${authCodeParams.baseUri}/oauth2/${authCodeParams.authServer}`,
          redirectUri: `${this.windowRefService.nativeWindow.location.origin}/dashboard`,
          responseMode: 'form_post',
          tokenManager: {
            storage: 'sessionStorage',
          },
          responseType: 'code',
          pkce: false,
        });

        return this.oktaAuthService.sessionExists();
      })
      .then((exists) => {
        if (exists) {
          return this.oktaAuthService
            .getWithoutPrompt({
              scopes: ['openid', 'offline_access'],
              clientId: authCodeParams.clientId,
              prompt: 'none',
              state: authCodeParams.stateToken,
              sessionToken: 'testSessionToken',
            })
            .then((codeResponse) => {
              const tokenParams = {
                redirectUri: `${this.windowRefService.nativeWindow.location.origin}/dashboard`,
                authorizationCode: codeResponse.code,
              };
              return this.apiFetchService.exchangeCodeForToken(tokenParams);
            })
            .then((tokenResponse) => {
              this.refreshToken = tokenResponse.refreshToken;
            })
            .catch(() => {
              this.refreshTokenError = true;
            })
            .finally(() => {
              this.refreshTokenLoading = false;
            });
        } else {
          this.windowRefService.nativeWindow.location.href = `${authCodeParams.baseUri}/oauth2/${authCodeParams.authServer}/v1/authorize?idp=${authCodeParams.identityProvider}&client_id=${authCodeParams.clientId}&response_type=code&scope=openid%20offline_access&redirect_uri=${this.windowRefService.nativeWindow.location.origin}/dashboard&state=${authCodeParams.stateToken}`;
        }
      })
      .catch(() => {
        this.refreshTokenError = true;
        this.refreshTokenLoading = false;
      });
  }

  closeTokenWindow() {
    this.showToken = { open: false };
    this.refreshToken = '';
    this.refreshTokenLoading = false;
    this.refreshTokenError = false;
  }

  copyToken() {
    const text = document.getElementById('token_display') as HTMLInputElement;
    text.select();
    document.execCommand('copy');
  }

  handleAuthorizationCode() {
    const authorizationCode = 'authorization_code';
    const state = 'state';
    const stateToken = 'stateToken';

    if (this.windowRefService.nativeWindow.sessionStorage.getItem(authorizationCode)) {
      if (
        this.windowRefService.nativeWindow.sessionStorage.getItem(state) ===
        this.windowRefService.nativeWindow.sessionStorage.getItem(stateToken)
      ) {
        this.showToken = { open: true };
        this.refreshTokenLoading = true;

        const code = this.windowRefService.nativeWindow.sessionStorage.getItem(authorizationCode);
        const tokenParams = {
          redirectUri: `${this.windowRefService.nativeWindow.location.origin}/dashboard`,
          authorizationCode: code,
        };

        this.apiFetchService.exchangeCodeForToken(tokenParams).then(
          (response) => {
            this.refreshToken = response.refreshToken;
            this.refreshTokenLoading = false;
          },
          () => {
            this.refreshTokenError = true;
          }
        );
      } else {
        this.refreshTokenError = true;
      }
    }

    this.windowRefService.nativeWindow.sessionStorage.removeItem(authorizationCode);
    this.windowRefService.nativeWindow.sessionStorage.removeItem(state);
    this.windowRefService.nativeWindow.sessionStorage.removeItem(stateToken);
  }

  canAccessGroupAdmin() {
    return this.user.canAccessGroupAdmin();
  }
}
