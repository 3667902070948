<div id="noteworthy" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="Noteworthy"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!previewingEntity || hasNoteworthyContent()"
    [disable]="!hasNoteworthyContent()"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <div
      *ngFor="let subsection of getSubSections(section.metadata.sectionId) | noteworthySubSectionPipe: hasSafetyContent():oshaInRegulatorySection"
      class="sub-section">
      <div [ngSwitch]="subsection">
        <div id="noteworthy_webContent" *ngSwitchCase="'incidents'">
          <strong class="push-down">Web Content</strong>
          <ddiq-incidents-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.contentEventGroups"
            (sortDiscoveries)="sortDiscoveries('content', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="entityAware.isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-incidents-group>
        </div>
        <div id="noteworthy_osha" *ngSwitchCase="'oshaSafetyInspections'">
          <strong class="push-down">OSHA Safety Inspections</strong>
          <ddiq-osha-inspections-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.oshaSafetyInspectionsEventGroups"
            (sortDiscoveries)="sortDiscoveries('oshaSafetyInspections', $event.sortName, $event.eventGroup)"
            [entityType]="entityType"
            [expandAll]="isExpandAllGroups">
          </ddiq-osha-inspections-group>
        </div>
        <div id="noteworthy_productRecalls" *ngSwitchCase="'productRecalls'">
          <strong class="push-down">Product Recalls</strong>
          <ddiq-product-recalls-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.productRecallsEventGroups"
            [expandAll]="isExpandAllGroups">
          </ddiq-product-recalls-group>
        </div>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
