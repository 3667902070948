import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnInit } from '@angular/core';
import Theme from './theme.service';

@Directive({
  selector: '[data-theme-pg-title]',
})
export default class ThemePgTitleDirective implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document, private theme: Theme) {}

  ngOnInit() {
    const defaultTitle = 'DDIQ - Where all due diligence begins';
    this.document.title = this.theme.getPageTitle() || defaultTitle;
  }
}
