import { Injectable, Inject } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Message } from './message.interface';

@Injectable({
  providedIn: 'root',
})
export default class MessageBusService {
  private messageSubject$ = new Subject();

  constructor() {}

  send(message: Message) {
    this.messageSubject$.next(message);
  }

  on(msgType: string, listener: (...args) => void): Subscription {
    return this.messageSubject$
      .pipe(
        filter((msg: Message) => msg.type === msgType),
        map((msg: Message) => msg.data)
      )
      .subscribe(listener);
  }
}
