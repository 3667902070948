import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startFrom',
  pure: false,
})
export default class StartFromPipe implements PipeTransform {
  constructor() {}

  /**
   * Returns input string starting from `start` index
   * @param input
   * @param start
   * @returns
   */
  transform(input: any, start: any = -1) {
    if (!(input instanceof Array)) return input;
    start = +start; //parse to int
    return input.slice(Math.max(start, 0));
  }
}
