<div class="field-source-info clearfix" [ngClass]="{ 'open': isOpen }">
  <div class="field-source-info-sources-container">
    <span
      *ngIf="count"
      role="button"
      class="field-source-info-badge badge"
      (click)="toggle()"
      title="{{title}}"
      [ngClass]="{'pointer': count}">
      <span class="field-source-info-count"> {{count}} {{countLabel}} </span>

      <i
        class="fa"
        [ngClass]="{
                'fa-chevron-up': isOpen,
                'fa-chevron-down': !isOpen }"
        aria-hidden="true">
      </i>
    </span>

    <div class="field-source-info-content" *ngIf="isOpen">
      <ul class="field-source-info-items">
        <li
          class="field-source-info-item field-source-info-item-{{i}}"
          *ngFor="let source of getSources(); let i = index">
          <span class="badge">{{source | translatef}}</span>
        </li>
      </ul>
    </div>
  </div>

  <div class="field-source-info-value-container">
    <span class="field-source-info-value">
      <ng-content></ng-content>
    </span>
  </div>
</div>
