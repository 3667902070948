import { Injectable } from '@angular/core';
import IsSubmittedPipe from '../ddiq-filters/is-submitted.pipe';
import UserService from '../user/user.service';
import ApiFetchService from './api-fetch.service';

@Injectable({
  providedIn: 'root',
})
export default class RelationshipService {
  private relationships;

  constructor(
    private apiFetchService: ApiFetchService,
    private isSubmittedPipe: IsSubmittedPipe,
    private user: UserService
  ) {
    this.clear();
  }

  companies(entityType, id, forceReload?) {
    return this.getRelated(entityType, id, 'company', forceReload);
  }
  getCompanies() {
    return this.relationships.company.children;
  }
  getCompaniesDiff() {
    return this.relationships.companydiff.children;
  }
  people(entityType, id, forceReload?) {
    return this.getRelated(entityType, id, 'person', forceReload);
  }
  getPeople() {
    return this.relationships.person.children;
  }
  peopleDiff(entityType, id, diffId) {
    return this.getRelatedDiff(entityType, id, 'person', diffId);
  }
  getPeopleDiff() {
    return this.relationships.persondiff.children;
  }
  locations(entityType, id, forceReload?) {
    return this.getRelated(entityType, id, 'location', forceReload);
  }
  getLocations() {
    return this.relationships.location.children;
  }
  locationsDiff(entityType, id, diffId) {
    return this.getRelatedDiff(entityType, id, 'location', diffId);
  }
  getLocationsDiff() {
    return this.relationships.locationdiff.children;
  }
  companiesDiff(entityType, id, diffId) {
    return this.getRelatedDiff(entityType, id, 'company', diffId);
  }
  setCompanyCount(count) {
    this.relationships.company.count = count;
  }
  setPersonCount(count) {
    this.relationships.person.count = count;
  }
  setLocationCount(count) {
    this.relationships.location.count = count;
  }
  hasRelationships() {
    return !!(
      this.relationships.company.children.length ||
      this.relationships.person.children.length ||
      this.relationships.location.children.length
    );
  }
  clear() {
    this.relationships = {
      person: { children: [] },
      persondiff: { children: [] },
      company: { children: [] },
      companydiff: { children: [] },
      location: { children: [] },
      locationdiff: { children: [] },
    };
  }
  clearPeople() {
    this.relationships.person = {};
    this.relationships.persondiff = {};
  }
  clearCompanies() {
    this.relationships.company = {};
    this.relationships.companydiff = {};
  }
  hasUserSubmitted(entities) {
    return entities.some((entity) => this.isSubmittedPipe.transform(entity.base));
  }
  private getRelated(entityType, id, type, forceReload) {
    const relations = this.relationships[type];
    if (relations.id !== id || forceReload) {
      relations.id = id;
      relations.promise = this.apiFetchService.children(entityType, id, type);
      relations.promise.then((children) => {
        relations.children = children;
        relations.children.forEach((rel) => {
          rel.filtered = false;
          rel.canViewProfile = this.user.canViewProfile(rel.base.oiqOwner);
        });
      });
    }
    return relations.promise;
  }
  private getRelatedDiff(entityType, id, type, diffId) {
    let typeDiff = type + 'diff',
      relations = this.relationships[typeDiff];
    if (relations.id !== id) {
      relations.id = id;
      relations.promise = this.apiFetchService.childrenDiff(entityType, id, type, diffId).then((children) => {
        relations.children = children;
      });
    }
    return relations.promise;
  }
}
