<section class="additional-item">
  <div *ngFor="let relatedPerson of relatedPeople; let $index= index;">
    <button type="button" class="fa fa-trash trash" (click)="removeRelatedPerson($index);"></button>
    <ddiq-text
      name="known-associate-name-{{$index}}"
      label="Name"
      size="half"
      [(model)]="relatedPerson.personName"></ddiq-text>
    <ddiq-text
      name="known-associate-relationships-{{$index}}"
      label="Relationship"
      size="half"
      [(model)]="relatedPerson.relationshipType"></ddiq-text>
    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary" (click)="addRelatedPerson();">Add Known Associate</button>
</section>
