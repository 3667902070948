import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripWWW',
})
export default class StripWWWPipe implements PipeTransform {
  constructor() {}

  /**
   * Returns input url with `www.` removed
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    let stripped = '';

    if (typeof input === 'string') {
      stripped = input.replace(/www./, '');
    }

    return stripped;
  }
}
