import { Component, Inject } from '@angular/core';
import { OrderByPipe } from 'ngx-pipes';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import AlertService from '../common-services/alert.service';
import CheckContentService from '../common-services/check-content.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import SectionConfigService from '../common-services/section-config.service';
import NullsToEndPipe from '../ddiq-filters/nulls-to-end.pipe';
import Monitor from '../monitor/monitor.service';
import AlertSummarizer from '../themed-entity/alert-summarizer.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';
import { DdiqRegulatoryBaseComponent } from './ddiq-regulatory-base.component';

@Component({
  selector: 'ddiq-location-regulatory',
  templateUrl: 'ddiq-regulatory-location.component.tpl.html',
})
export default class DdiqRegulatoryLocationComponent extends DdiqRegulatoryBaseComponent {
  constructor(
    protected incidentsUtils: IncidentsUtils,
    protected structuredDataUtils: StructuredDataUtils,
    protected alertSummarizer: AlertSummarizer,
    protected alertService: AlertService,
    protected entity: Entity,
    protected checkContentService: CheckContentService,
    protected oiqProperties: OiqProperties,
    protected monitor: Monitor,
    protected sectionConfigService: SectionConfigService,
    protected messageBusService: MessageBusService,
    protected nullsToEnd: NullsToEndPipe,
    protected orderByPipe: OrderByPipe
  ) {
    super(
      incidentsUtils,
      structuredDataUtils,
      alertSummarizer,
      alertService,
      entity,
      checkContentService,
      oiqProperties,
      monitor,
      sectionConfigService,
      messageBusService,
      nullsToEnd,
      orderByPipe
    );
  }
}
