import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'oiq-filter-count',
  templateUrl: './filter-count.component.tpl.html',
})
export default class FilterCountComponent implements OnChanges {
  @Input() entities;

  escalated;
  filtered;

  ngOnChanges(changes) {
    if (this.shouldCalculate(changes)) {
      const countInfo = this.getCountInfo();
      this.escalated = this.getEscalatedMessage(countInfo);
      this.filtered = this.getFilteredMessage(countInfo);
    }
  }

  shouldCalculate(changes) {
    return changes.entities && Array.isArray(changes.entities.currentValue);
  }

  getCountInfo() {
    const info = {
      escalatedCount: 0,
      filteredCount: 0,
      filteredAndEscalated: 0,
      entityCount: this.entities.length,
    };

    for (const entity of this.entities) {
      if (entity.filtered) {
        info.filteredCount += 1;
      }
      if (entity.escalated) {
        info.escalatedCount += 1;
      }
      if (entity.filtered && entity.escalated) {
        info.filteredAndEscalated += 1;
      }
    }

    return info;
  }

  getFilteredMessage(info) {
    return this.getCountMessage(info.filteredCount, info.entityCount);
  }

  getEscalatedMessage(info) {
    return this.getCountMessage(info.filteredAndEscalated, info.escalatedCount);
  }

  getCountMessage(excludeFromViewCount, totalCount) {
    if (excludeFromViewCount > 0 && excludeFromViewCount <= totalCount) {
      const inView = totalCount - excludeFromViewCount;
      return `${inView} of ${totalCount}`;
    }
    return totalCount;
  }
}
