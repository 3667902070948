import DdiqLegalFilingComponent from './ddiq-legal-filing.component';
import DdiqLegalFilingsGroupComponent from './ddiq-legal-filings-group.component';
import { NgModule } from '@angular/core';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { CommonModule } from '@angular/common';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqAssociationModule from '../association/oiq-association.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import OiqSourcesModule from '../sources/oiq-sources.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    DdiqUIModule,
    OiqAdjudicationModule,
    OiqAssociationModule,
    DdiqDirectivesModule,
    OiqSourcesModule,
  ],
  declarations: [DdiqLegalFilingComponent, DdiqLegalFilingsGroupComponent],
  exports: [DdiqLegalFilingComponent, DdiqLegalFilingsGroupComponent],
})
export class OiqLegalFilingsModule {}
