import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileStatusError',
})
export default class ProfileStatusErrorPipe implements PipeTransform {
  constructor() {}

  transform(reason: any) {
    const unknown = '??';
    if (!reason) {
      return '';
    }

    let actions;
    if (Array.isArray(reason.adjudicationActions)) {
      actions = reason.adjudicationActions.join(', ');
    }

    return `Please ${actions || unknown} all hits that have a confidence level equal or above ${
      reason.confidenceThreshold || unknown
    }`;
  }
}
