import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlNormalizer',
})
export default class UrlNormalizerPipe implements PipeTransform {
  constructor() {}

  /**
   * prepends input with http:// when it is missing from the input
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    if (typeof input !== 'undefined' && !input.match(/^[a-zA-Z]+:\/\//)) {
      return 'http://' + input;
    }
    return input;
  }
}
