<section class="additional-item">
  <div *ngFor="let education of educationInstitutionsHolder; let $index = index">
    <button type="button" class="fa fa-trash trash" (click)="removeEducationInstitution($index)"></button>
    <ddiq-text
      name="edu-institution-{{$index}}"
      label="Educational Institution"
      size="full"
      [(model)]="education.institution"></ddiq-text>
    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary" (click)="addEducationInstitution()">Add Education</button>
</section>
