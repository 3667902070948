import OiqProperties from '../common-services/oiq-properties.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class MonitorCategories {
  constructor(private oiqProperties: OiqProperties) {}

  categories() {
    return [
      { label: 'Adverse', value: 'ADVERSE' } as any,
      { label: 'Legal', value: 'LEGAL' } as any,
      { label: 'Regulatory', value: 'REGULATORY' } as any,
      { label: 'Noteworthy', value: 'NOTEWORTHY' } as any,
    ];
  }

  isMonitorPreselected() {
    return this.oiqProperties.monitorPreselected;
  }

  hasPreselectedCategories() {
    return this.categories().some((category) => {
      return (this.oiqProperties.monitoringPreselectedCategories || []).some(function (preselected) {
        return category.value === preselected;
      });
    });
  }

  isCategoryPreselected(categoryValue) {
    return (this.oiqProperties.monitoringPreselectedCategories || []).some(function (preselected) {
      return preselected === categoryValue;
    });
  }
}
