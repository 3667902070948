<ul class="monitor-categories">
  <li *ngIf="vm.hasMasterToggle">
    <label>
      <input
        type="checkbox"
        name="monitor-toggle"
        [(ngModel)]="vm.enableCategories"
        [defaultChecked]="vm.enableCategories"
        [checked]="vm.enableCategories"
        (change)="onMonitorToggled(vm.enableCategories)" />
      Monitor this profile using the results as the baseline
    </label>
  </li>

  <li *ngIf="vm.showCrawlPlans" class="input">
    <label>Crawl plan</label>
    <select
      class="monitor-crawlplan"
      [(ngModel)]="vm.selectedCrawlPlan"
      (change)="onCategoryUpdate()"
      [disabled]="isDisabled || vm.crawlPlans.length == 1">
      <option *ngFor="let crawlPlan of vm.crawlPlans; trackBy:crawlPlanKeyTrackBy" [ngValue]="crawlPlan">
        {{crawlPlan.description}}
      </option>
    </select>
  </li>

  <li class="input">
    <label>Frequency</label>
    <select
      class="monitor-frequency"
      [(ngModel)]="vm.selectedFrequency"
      (change)="onCategoryUpdate()"
      [disabled]="isDisabled">
      <option *ngFor="let frequency of vm.frequencies; trackBy:frequencyTrackBy">{{frequency}}</option>
    </select>
  </li>

  <li class="input monitor-category-label">
    <label>Categories</label>
  </li>

  <li *ngFor="let category of vm.categories; trackBy:categoryValueTrackBy">
    <input
      type="checkbox"
      [name]="'monitor-category-' + category.value"
      [(ngModel)]="category.selected"
      [defaultChecked]="category.selected"
      [checked]="category.selected"
      (change)="onCategoryUpdate()"
      [disabled]="isDisabled" />
    <span class="monitor-category-name">{{category.label}}</span>
  </li>
</ul>
