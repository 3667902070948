import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import AddressPipe from '../ddiq-filters/address.pipe';
import ConvertHtmlSpecificCharsPipe from '../ddiq-filters/convert-html-specific-chars.pipe';
import StripHTMLPipe from '../ddiq-filters/strip-html.pipe';
import TruncatePipe from '../ddiq-filters/truncate.pipe';

@Injectable({
  providedIn: 'root',
})
export default class FieldSourceMetadata {
  metadata = {};

  constructor(
    private truncatePipe: TruncatePipe,
    private stripHtmlPipe: StripHTMLPipe,
    private addressPipe: AddressPipe,
    private convertHtmlSpecificCharsPipe: ConvertHtmlSpecificCharsPipe
  ) {}

  setMetadata(metadata) {
    if (metadata) {
      this.metadata = this.processMetadata(metadata);
    }
  }

  updateMetadata(key, recordId, sources) {
    if (key && recordId) {
      const existing = (this.metadata[key] || []).find((element) => {
        return element.value === recordId;
      });
      if (existing) {
        existing.sources = sources;
      } else {
        if (!this.metadata[key]) {
          this.metadata[key] = [];
        }
        this.metadata[key].push({
          value: recordId,
          sources: sources,
        });
      }
    }
  }

  private processMetadata(metadata) {
    const processedMetadata = cloneDeep(metadata);

    this.processAddresses(processedMetadata);
    this.processDescriptions(processedMetadata);
    //any other processors here, if needed

    return processedMetadata;
  }

  private processAddresses(metadata) {
    let i;

    if (metadata.address) {
      for (i = 0; i < metadata.address.length; i++) {
        this.swapInSingleLineAddress(metadata.address[i]);
      }
    }
  }

  private processDescriptions(metadata) {
    let i, sourcedValue;

    if (metadata.description) {
      for (i = 0; i < metadata.description.length; i++) {
        sourcedValue = metadata.description[i];
        sourcedValue.isText = true;
        sourcedValue.value = this.truncatePipe.transform(this.stripHtmlFromDescription(sourcedValue.value), 255);
      }
    }
  }

  private swapInSingleLineAddress(sourcedValue) {
    let address, singleLineAddress;

    address = sourcedValue.value;
    singleLineAddress = this.addressPipe.transform(address);

    sourcedValue.value = singleLineAddress;
    sourcedValue.originalValue = address;
  }

  private stripHtmlFromDescription(description) {
    const stripped = this.stripHtmlPipe.transform(description);
    return this.convertHtmlSpecificCharsPipe.transform(stripped);
  }
}
