import { Component, Input, OnInit } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ScreeningFeatureService from '../screening/screening-feature.service';
import MonitorSearchService from './monitor-search.service';
import MonitorTabsData from './monitor-tabs-data.service';

@Component({
  selector: 'ddiq-monitor-closed-search',
  templateUrl: './ddiq-monitor-closed-search.component.tpl.html',
})
export default class MonitorClosedSearchComponent implements OnInit {
  @Input() isQueryEmpty;
  @Input() lastQueryString;

  isPaginationResetting = false;
  pagination = {
    current: 1,
    total: 0,
    visible: 7,
    orderBy: 'date',
    descending: true,
  };

  results = {
    visible: 10,
    reports: [],
    total: 0,
    isLoading: false,
  };
  isGovIdSearchEnabled;
  isScreeningFeatureEnabled;

  search;
  lastSuccessfulQuery;
  constructor(
    private monitorSearchService: MonitorSearchService,
    private monitorTabsData: MonitorTabsData,
    private urlGenerator: UrlGenerator,
    private oiqProperties: OiqProperties,
    private screeningFeature: ScreeningFeatureService
  ) {
    this.isGovIdSearchEnabled = this.oiqProperties.isGovIdSearchEnabled;
    this.isScreeningFeatureEnabled = this.screeningFeature.isEnabled();
  }

  ngOnInit() {
    /**
     * we need to be able to reset the pagination (via clearSearch or search) without
     * firing an additional search
     */
    this.monitorSearchService.addSetPagination((currentPage, orderBy, descending) => {
      if (this.pagination.current !== currentPage) {
        this.isPaginationResetting = true;
        this.pagination.current = currentPage;
        this.pagination.orderBy = orderBy;
        this.pagination.descending = descending;
      }
    });

    this.search = this.goSearch();
    this.initMonitorClearedSearch();
  }

  initMonitorClearedSearch() {
    this.pagination.current = 1;
    this.lastSuccessfulQuery = this.monitorSearchService.previousQuery;
    this.search(this.pagination.current);
  }

  goSearch() {
    const cSearch = this.monitorSearchService.createMonitorHitClosedFunction(this.pagination, this.results);

    cSearch.registerCallback((updatedResults) => {
      if (updatedResults) {
        for (let i = 0; i < updatedResults.reports.length; i++) {
          const reportUrl = this.urlGenerator.generateReport(
            updatedResults.reports[i].oiqEntityId,
            updatedResults.reports[i].entityType,
            updatedResults.reports[i].oiqEntityId
          );
          updatedResults.reports[i].profileLink = reportUrl + '?monitor=true';
        }

        this.monitorTabsData.counts.closedCount = updatedResults.total;
      }

      this.monitorTabsData.pendingSearches--;
    });

    return (currentPage, previousPage) => {
      if (currentPage !== previousPage && !this.isPaginationResetting) {
        cSearch.search(this.lastSuccessfulQuery, (currentPage - 1) * this.results.visible);
      }

      this.isPaginationResetting = false;
    };
  }

  orderStyle(orderBy) {
    return orderBy === this.pagination.orderBy && 'sort-' + this.pagination.descending;
  }

  changeSorting(orderBy) {
    const sort = this.pagination;

    if (sort.orderBy === orderBy) {
      sort.descending = !sort.descending;
    } else {
      sort.orderBy = orderBy;
      sort.descending = orderBy === 'date';
    }

    this.search(this.pagination.current);
  }

  onSelectPage(page) {
    this.isPaginationResetting = false;

    if (this.pagination.current !== page) {
      this.search(page, this.pagination.current);
      this.pagination.current = page;
    }
  }
}
