import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ddiq-legal-filings-group',
  templateUrl: './ddiq-legal-filings-group.component.tpl.html',
})
export default class DdiqLegalFilingsGroupComponent {
  @Input() groups: any;
  @Output() sortDiscoveries: any = new EventEmitter<any>();
  @Input() idPrefix: string;
  @Input() expandAll: boolean;

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  classification(index: number, group: any) {
    return group.classification;
  }
}
