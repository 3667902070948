import { Injectable } from '@angular/core';
import SectionConfigService from '../common-services/section-config.service';
import OiqProperties from '../common-services/oiq-properties.service';
import EventGroupsService from '../event-groups/event-groups.service';
import ProfileDisplayService from '../common-services/profile-display.service';
import IncidentsService from '../themed-entity/incidents.service';
import StructuredData from '../themed-entity/structured-data.service';
import Filter from '../themed-entity/filter.service';
import ValidateHtmlIdsPipe from '../ui/validate-html-ids.pipe';
import AntiCollideClassificationPipe from '../ddiq-filters/anti-collide-classification.pipe';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  sections: Array<any> = [];

  private validateHtmlIdPipe: ValidateHtmlIdsPipe;
  private antiCollideClassificationPipe: AntiCollideClassificationPipe;
  constructor(
    private sectionConfigService: SectionConfigService,
    private oiqProperties: OiqProperties,
    private eventGroups: EventGroupsService,
    private profileDisplayService: ProfileDisplayService,
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private filter: Filter
  ) {
    this.validateHtmlIdPipe = new ValidateHtmlIdsPipe();
    this.antiCollideClassificationPipe = new AntiCollideClassificationPipe();
  }

  clear() {
    this.incidents.clear();
    this.structuredData.clear();
    this.sections.length = 0;
    this.filter.clear();
  }

  configureProfile(entityType, entityResp) {
    this.sections = this.sectionConfigService.configSections(entityType, this.oiqProperties.apiProperties, entityResp);

    this.incidents.init(entityResp, true);
    this.structuredData.init(entityResp, true);

    if (entityType !== 'property') {
      this.filter.init(this.sections);
    }

    const eventGroupBuildHandlers = this.eventGroups.getEventGroupBuildHandlers();
    this.sections.forEach((section) => {
      eventGroupBuildHandlers.forEach((addEventGroupsTo) => addEventGroupsTo(section));
    });

    this.profileDisplayService.init();
    return { isAutoExpanded: this.profileDisplayService.isExpandAll(), sections: [...this.sections] };
  }

  refreshEventGroups() {
    const eventGroupsRefreshHandlers = this.eventGroups.getEventGroupRefreshHandlers();
    this.sections.forEach((section) => {
      eventGroupsRefreshHandlers.forEach((refreshEventGroupOn) => refreshEventGroupOn(section));
    });
    return { sections: [...this.sections] };
  }

  sortEventGroupDiscoveries(sort) {
    const sorts = this.eventGroups.getEventGroupSorts();
    for (const section of this.sections) {
      if (section.metadata.sectionId === sort.sectionId) {
        sorts[sort.eventGroupKind](section, sort);
        break;
      }
    }
    return { sections: [...this.sections] };
  }

  toggleEventGroups(toggleOpen) {
    let totalDiscoveries = 0,
      canOpenPredicate: (eventGroup) => boolean = () => true,
      isExpandAllActionFailure = false;

    const limit = this.oiqProperties.expandAllLimit;

    if (toggleOpen) {
      canOpenPredicate = (eventGroup) => {
        if (!eventGroup.unFilteredCount) {
          return false;
        }
        totalDiscoveries += eventGroup.unFilteredCount;
        return totalDiscoveries <= limit;
      };
    }

    outer: for (const section of this.sections) {
      const subsections = section.metadata.subsections;

      for (const toggleHandler of this.eventGroups.getToggleHandlersForKinds(subsections)) {
        toggleHandler(section, toggleOpen, canOpenPredicate);

        if (toggleOpen && totalDiscoveries > limit) {
          isExpandAllActionFailure = true;
          break outer;
        }
      }
    }
    return { isExpandAllActionFailure, sections: [...this.sections] };
  }

  openEventGroup(lookupId: string): void {
    let isToggledOpen = false;

    for (const section of this.sections) {
      const { subsections } = section.metadata;

      if (!subsections) continue;
      if (isToggledOpen) {
        return;
      }

      // Directly call the handlers returned by getToggleHandlersForKinds and exit out when event group is opened
      isToggledOpen = this.eventGroups.getToggleHandlersForKinds(subsections).some((openToggleHandler) => {
        return openToggleHandler(section, true, this.openEventGroupPredicate(lookupId));
      });
    }
  }

  getLookupIdByEvent(event: string): string | null {
    for (const section of this.sections) {
      const sectionId = section.metadata.sectionId;

      if (this.isEventFoundInStructuredData(sectionId, event)) {
        return `${sectionId}_${this.validateHtmlIdPipe.transform(event)}`;
      }

      const antiCollidedEvent = this.antiCollideClassificationPipe.transform(event);

      if (this.isEventFoundInIncidents(sectionId, antiCollidedEvent)) {
        return `${sectionId}_${this.validateHtmlIdPipe.transform(antiCollidedEvent)}`;
      }
    }

    return null;
  }

  private isEventFoundInStructuredData(sectionId: string, event: string): boolean {
    return this.structuredData.find(sectionId, event).length > 0;
  }

  private isEventFoundInIncidents(sectionId: string, event: string): boolean {
    return this.incidents.find(sectionId, event).length > 0;
  }

  private openEventGroupPredicate(lookupId: string) {
    return ({ discoveries }) =>
      discoveries.some((discovery) => {
        return discovery.lookupId === lookupId && !discovery.filtered;
      });
  }
}
