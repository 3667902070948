// @ts-nocheck
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import ConfigService from '../../../../../shared/services/http/config.service';
import Entity from './entity.service';
import { MonitorSearchModel } from '../monitor/monitor-search.model';
import { MonitorFilterFieldsAPI } from '../monitor/monitor';
@Injectable({
  providedIn: 'root',
})
export default class ApiFetchService {
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandler,
    private configService: ConfigService,
    private entityService: Entity
  ) {
    this.settings = this.configService.settings;
  }
  private settings;
  private webContent: String = 'snippets';
  private markup: String;
  private attachAll: boolean = true;
  private premium = {
    company: {},
    person: {},
  };

  private getViaRestApi(url, key, secret, headers?: HttpHeaders, responseType?) {
    let restHeaders = headers ? headers : new HttpHeaders().set('Accept', 'application/json');
    if (key && secret) {
      restHeaders = restHeaders.append('Authorization', this.createAuthorizationHeader(key, secret));
    }

    return this.getViaApi('rest/' + url, restHeaders, undefined, responseType);
  }

  private getViaApi(url, requestHeaders, isDirect, responseType?) {
    if (!isDirect) {
      url = this.settings.project.apiUrl + url;
    }
    let requestHeaders = requestHeaders ? requestHeaders : new HttpHeaders().set('Accept', 'application/json');

    const successCallback = (response) => {
      return response;
    };

    const errorCallback = (response) => {
      this.errorHandler.error('Fetch of ' + url + ' failed');
      this.errorHandler.error(response);
      return new Promise((resolve, reject) => reject(response));
    };

    return this.http
      .get(url, { headers: requestHeaders, responseType })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  private createAuthorizationHeader(key, secret) {
    return 'Basic ' + window.btoa(key + ':' + secret);
  }

  private getConfig(filename): Promise<any> {
    const urlPrefix = 'config/';

    const configSuccess = (response) => {
      return response;
    };

    const configFail = (response) => {
      this.errorHandler.error('failed to fetch config file: ' + urlPrefix + filename);
      this.errorHandler.error(response);
    };

    return this.http
      .get(urlPrefix + filename)
      .toPromise()
      .then(configSuccess, configFail);
  }

  config(filename): Promise<any> {
    return this.getConfig(filename);
  }

  private postViaRestApi(url, params): Promise<any> {
    return this.post('rest/' + url, params);
  }

  private post(url, params) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    const successCallback = (response) => {
      return response;
    };

    const errorCallback = (response) => {
      this.errorHandler.log('Fetch of ' + url + ' failed');
      this.errorHandler.log(response);
      return new Promise((resolve, reject) => reject(response));
    };

    return this.http
      .post(this.settings.project.apiUrl + url, params, {
        headers,
      })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  /**
   * See this.getViaRestApi notes
   * @param url
   * @param params
   * @returns promise containing put response
   */
  private putViaRestApi(url, params, key, secret) {
    let restHeaders = new HttpHeaders();

    if (key && secret) {
      restHeaders = restHeaders.append('Authorization', this.createAuthorizationHeader(key, secret));
    }

    return this.put('rest/' + url, params, restHeaders);
  }

  // nothing is posted before document is ready, so no logic to check settings.project
  private put(url, params, reqHeaders) {
    const reqHeaders = reqHeaders
      ? reqHeaders
      : new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    const successCallback = (response) => {
      return response;
    };

    const errorCallback = (response) => {
      this.errorHandler.log('Fetch of ' + url + ' failed');
      this.errorHandler.log(response);
      return new Promise((resolve, reject) => reject(response));
    };

    return this.http
      .put(this.settings.project.apiUrl + url, params, {
        headers: reqHeaders,
      })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  setWebContent(webContentType): void {
    this.webContent = webContentType;
  }

  setAttachAll(shouldAttachAll): void {
    this.attachAll = shouldAttachAll;
  }

  setMarkup(markupString) {
    this.markup = markupString;
  }

  getRestResource(url, responseType?) {
    const headers = new HttpHeaders().set('Accept', '*/*');
    return this.getViaRestApi(url, undefined, undefined, headers, responseType);
  }
  getResourceFromUrl(url) {
    return this.getViaApi(url, undefined, true);
  }
  logout() {
    return this.http.get(this.settings.project.apiUrl + 'logout', { responseType: 'text' }).toPromise();
  }
  adjudicationHistory(entityType, entityId, type, id, monitored) {
    let base = '';
    if (typeof monitored === 'boolean' && monitored) {
      base = 'monitor/';
    }
    return this.getViaRestApi(base + 'adjudicate/' + entityType + '/' + entityId + '/' + type + '/' + id);
  }
  adjudicate(entityType, entityId, type, id, params, monitored) {
    let base = '';
    if (typeof monitored === 'boolean' && monitored) {
      base = 'monitor/';
    }
    return this.postViaRestApi(base + 'adjudicate/' + entityType + '/' + entityId + '/' + type + '/' + id, params);
  }
  adjudicateByClassification(entityType, entityId, type, id, params, monitored, classification?) {
    let base = '';
    if (typeof monitored === 'boolean' && monitored) {
      base = 'monitor/';
    }
    const path =
      base +
      'adjudicate/' +
      entityType +
      '/' +
      entityId +
      '/' +
      type +
      '/' +
      id +
      ((classification && '/' + classification) || '');

    return this.postViaRestApi(path, params);
  }
  adjudicateAll(entityType, entityId, params, monitored) {
    let base = '';
    if (typeof monitored === 'boolean' && monitored) {
      base = 'monitor/';
    }

    const path = base + 'adjudicate/all/' + entityType + '/' + entityId;

    return this.postViaRestApi(path, params);
  }
  audit(entityType, entityId, errorsOnly) {
    return this.getViaRestApi('oiqPrivate/' + entityType + '/' + entityId + '/audit?errorsonly=' + errorsOnly);
  }
  integrity(entityType, entityId, isMonitor) {
    if (isMonitor) {
      return this.getViaRestApi('monitor/' + entityType + '/' + entityId + '/integrityReport');
    }
    return this.getViaRestApi('profile/' + entityType + '/' + entityId + '/integrityReport');
  }
  assessment(entityType, entityId) {
    return this.getViaRestApi('assessment/' + entityType + '/' + entityId);
  }
  build(submissionType, params) {
    return this.postViaRestApi(submissionType + '/submit', params);
  }
  children(entityType, entityId, childType) {
    return this.getViaRestApi('oiqPrivate/' + entityType + '/' + entityId + '/' + childType);
  }
  childrenDiff(entityType, entityId, childType, baselineId) {
    return this.getViaRestApi('oiqPrivate/' + entityType + '/' + entityId + '/' + childType + '/diff/' + baselineId);
  }
  crawlPlans() {
    return this.getViaRestApi('admin/crawlplan');
  }
  didYouMeanSubmission(entity, params) {
    return this.postViaRestApi('oiqPrivate/' + entity + '/didYouMean', params);
  }
  nameSuggestionSubmission(entity, params) {
    return this.postViaRestApi('oiqPrivate/' + entity + '/nameSuggestions', params);
  }
  diff(type, idA, idB) {
    return this.getViaRestApi(
      type +
        '/diff/' +
        idA +
        '/' +
        idB +
        '?oiqPrivate=true' +
        '&webContent=' +
        this.webContent +
        (this.markup ? '&markup=' + this.markup : '')
    );
  }
  entity(entityType, entityId) {
    return this.getViaRestApi(
      entityType +
        '/' +
        entityId +
        '?oiqPrivate=true' +
        '&webContent=' +
        this.webContent +
        '&attachAll=' +
        this.attachAll.toString() +
        '&countForOwnershipData=true' +
        (this.markup ? '&markup=' + this.markup : '')
    );
  }
  findEntity(
    entityType,
    start,
    count,
    query,
    status?,
    owner?,
    id?,
    reqOrg?,
    correlationId?,
    after?,
    before?,
    orderBy?,
    order?,
    govId?,
    crawlPlanType?,
    profileCollectionKey?
  ) {
    let findUrl = '';

    const start = start ? start : 0;
    const count = count ? count : 0;
    const query = encodeUriFn(query);
    const status = encodeUriFn(status);
    const owner = encodeUriFn(owner);
    const id = encodeUriFn(id);
    const reqOrg = encodeUriFn(reqOrg);
    const correlationId = encodeUriFn(correlationId);
    const after = encodeUriFn(after);
    const before = encodeUriFn(before);
    const orderBy = encodeUriFn(orderBy);
    const order = encodeUriFn(order);
    const govId = encodeUriFn(govId);
    const crawlPlanType = encodeUriFn(crawlPlanType);
    const profileCollectionKey = encodeUriFn(profileCollectionKey);

    findUrl += entityType + '/search?start=' + start;
    findUrl += '&count=' + count;
    findUrl += '&name=' + query;
    findUrl += '&status=' + status;
    findUrl += '&owner=' + owner;
    findUrl += '&submissionId=' + id;
    findUrl += '&reqOrg=' + reqOrg;
    findUrl += '&correlationId=' + correlationId;
    findUrl += '&after=' + after;
    findUrl += '&before=' + before;
    findUrl += '&orderBy=' + orderBy;
    findUrl += '&order=' + order;
    findUrl += '&govId=' + govId;
    findUrl += '&crawlPlanType=' + crawlPlanType;
    findUrl += '&profileCollectionKey=' + profileCollectionKey;

    return this.getViaRestApi(findUrl);
  }
  findExistingEntities(entityType, name, submissionId, useSubmissionId, useSubmissionIdOnly) {
    let findUrl = '';
    const shouldQuerySubmissionId = submissionId && (useSubmissionId || useSubmissionIdOnly);
    const name = name ? encodeURIComponent(name) : '';
    findUrl += entityType + '/existing?';

    if (shouldQuerySubmissionId) {
      findUrl += 'submissionId=' + encodeURIComponent(submissionId);
    }

    if (!useSubmissionIdOnly) {
      // add the & when there's a submissionId
      findUrl += ((shouldQuerySubmissionId && '&') || '') + 'name=' + name;
    }

    return this.getViaRestApi(findUrl);
  }
  find(start, count, q, id, after, before) {
    let findUrl = '';

    const start = start ? start : 0;
    const count = count ? count : 0;
    const q = encodeUriFn(q);
    const id = encodeUriFn(id);
    const after = encodeUriFn(after);
    const before = encodeUriFn(before);

    findUrl += 'company/search?start=' + start;
    findUrl += '&count=' + count;
    findUrl += '&name=' + q;
    findUrl += '&submissionId=' + id;
    findUrl += '&after=' + after;
    findUrl += '&before=' + before;

    return this.getViaRestApi(findUrl);
  }
  hideProfile(hidden) {
    const url =
      'oiqPrivate/' + this.entityService.getType() + '/' + this.entityService.getId() + '/hide?hidden=' + hidden;
    return this.putViaRestApi(url);
  }
  getProfileStatus(entityType, entityId) {
    return this.getViaRestApi(`profile/${entityType}/${entityId}`).then((response) => {
      return response.status;
    });
  }
  countryData() {
    return this.getViaRestApi('i18n/country/list');
  }
  languagesData() {
    return this.getViaRestApi('oiqPrivate/configuration/languages');
  }
  lastUpdated(entityType, entityId) {
    return this.getViaRestApi('progress/' + entityType + '/' + entityId + '/lastUpdated');
  }
  inProgressProfiles = (params) => {
    const query = params ? makeProgressQuery(params) : '';
    return this.getViaRestApi('progress/profiles' + query);
  };
  inProgressChildCompanies() {
    return this.getViaRestApi('progress/entity/' + this.entityService.getId() + '/company');
  }
  inProgressChildPersons() {
    return this.getViaRestApi('progress/entity/' + this.entityService.getId() + '/person');
  }
  ping() {
    return this.getViaRestApi('oiqPrivate/ping');
  }
  properties = () => {
    return this.getViaRestApi('oiqPrivate/configuration');
  };
  requestPasswordReset(username, captcha) {
    return this.putViaRestApi('admin/user/' + username + '/requestPasswordReset?captcha=' + captcha);
  }
  getCaptchaImageDataUri() {
    return this.getViaRestApi('captcha', undefined, undefined, new HttpHeaders().set('Accept', 'text/plain'), 'text');
  }
  changePasswordWithToken(token, user) {
    return this.putViaRestApi('admin/request/changePasswordWithToken?token=' + token, user);
  }
  verifyToken(token) {
    return this.getViaRestApi('admin/request/verifyToken?token=' + token);
  }
  getVisibleCrawlPlans() {
    return this.getViaRestApi('crawlplans/listVisibleCrawlPlans');
  }
  scheduleMonitor(type, id, params) {
    return this.putViaRestApi('monitor/schedule/' + type + '/' + id, params);
  }
  unscheduleMonitor(type, id) {
    return this.putViaRestApi('monitor/unschedule/' + type + '/' + id);
  }
  getMonitor(type, id) {
    return this.getViaRestApi('monitor/' + type + '/' + id);
  }
  getMonitorDeltaReport(type, id) {
    return this.getViaRestApi(
      'monitor/entity/' + type + '/' + id + '?webContent=classification' + (this.markup ? '&markup=' + this.markup : '')
    );
  }
  getMonitorCount() {
    return this.getViaRestApi('monitor/open/count');
  }
  getMonitorCrawlPlans(baselineCrawlPlanKey) {
    return this.getViaRestApi('monitor/availableCrawlPlans?baselineCrawlPlanKey=' + baselineCrawlPlanKey);
  }
  findMonitor(resultType, start, count, query, id, owner, govId, after, before, orderBy, order) {
    let findUrl = '';

    const start = start ? start : 0;
    const count = count ? count : 0;
    const query = encodeUriFn(query);
    const id = encodeUriFn(id);
    const owner = encodeUriFn(owner);
    const after = encodeUriFn(after);
    const before = encodeUriFn(before);
    const govId = encodeUriFn(govId);
    const orderBy = encodeUriFn(orderBy);
    const order = encodeUriFn(order);

    findUrl += 'monitor/search?resultType=' + resultType;
    findUrl += '&start=' + start;
    findUrl += '&count=' + count;
    findUrl += '&name=' + query;
    findUrl += '&submissionId=' + id;
    findUrl += '&owner=' + owner;
    findUrl += '&govId=' + govId;
    findUrl += '&afterTime=' + after;
    findUrl += '&beforeTime=' + before;
    findUrl += '&orderBy=' + orderBy;
    findUrl += '&order=' + order;
    findUrl += this.markup ? '&markup=' + this.markup : '';

    return this.getViaRestApi(findUrl);
  }
  findMonitorDailyReports(start, count, after, before, orderBy, order) {
    let findUrl = '';

    const start = start ? start : 0;
    const count = count ? count : 0;
    const after = after || '';
    const before = before || '';
    const orderBy = orderBy || '';
    const order = order || '';

    findUrl += 'monitor/report/batch';
    findUrl += '?start=' + start;
    findUrl += '&count=' + count;
    findUrl += '&afterTime=' + after;
    findUrl += '&beforeTime=' + before;
    findUrl += '&orderBy=' + orderBy;
    findUrl += '&order=' + order;

    return this.getViaRestApi(findUrl);
  }
  monitorSearch(params: MonitorSearchModel) {
    return this.postViaRestApi('search/profile/monitoring', params);
  }
  getMonitorEvents(events: string) {
    return this.getViaRestApi(`search/profile/monitoring/hitCategories?${events}`);
  }
  getMonitorFilterFields(field: MonitorFilterFieldsAPI) {
    return this.getViaRestApi(`search/profile/monitoring/fieldValues?fieldName=${field}`);
  }
  seedData(type, id) {
    return this.getViaRestApi('seeddata/' + type + '/' + id);
  }
  getMonitorSeedData(type, id) {
    return this.getViaRestApi('monitor/seeddata/' + type + '/' + id);
  }
  version() {
    return this.getViaRestApi('admin/version');
  }
  configuration() {
    return this.getViaRestApi('admin/configuration');
  }
  recentlyBuilt = () => {
    return this.getViaRestApi('oiqPrivate/recentlyBuilt');
  };

  summary(entityType, entityId, isOiqPrivate) {
    return this.getViaRestApi(entityType + '/' + entityId + '/summary?oiqPrivate=' + isOiqPrivate);
  }
  source(type, id, source?) {
    let url = 'oiqPrivate/' + type + '/' + id + '/sources/';

    if (this.entityService.isDiff()) {
      return new Promise((resolve, reject) => {
        resolve({});
      });
    }

    if (source !== undefined) {
      url = url + source;
    }

    if (source === 'oiqPremiumDataSource') {
      let d = new Promise((resolve, reject) => {
        if (!this.premium[type][id]) {
          // this premium source hasn't been requested, so create
          this.premium[type][id] = {
            queue: [], // holds concurrent requests
          };

          this.getViaRestApi(url).then((data) => {
            // resolve the original request
            resolve(data);

            // store the data for other requests
            this.premium[type][id].data = data;
            const queue = this.premium[type][id].queue;

            // resolve any requests which queued while this first request was fetching
            while (queue.length) {
              queue.shift().resolve(data);
            }
          });
        } else {
          // we have already requested this data source
          if (this.premium[type][id].data) {
            // we already have this data, so return via resolved promise
            resolve(this.premium[type][id].data);
          } else {
            // queue this promise until the running request finishes
            this.premium[type][id].queue.push(d);
          }
        }
      });
      return d;
    } else {
      return this.getViaRestApi(url);
    }
  }
  currentUser() {
    return this.getViaRestApi('admin/currentUser');
  }
  user(username, secret?, login = false) {
    return this.getViaRestApi('admin/user/' + username + '?reset=' + login, username, secret);
  }
  userUsage(username) {
    return this.getViaRestApi('admin/user/' + username + '/usage');
  }
  selfUpdateUser(username, userData) {
    return this.putViaRestApi('admin/user/' + username + '/selfUpdate', userData);
  }
  // WR: the key/secret are only needed for instances where the user
  // has not successfully logged in; e.g. when a passwordReset is
  // requested/forced.  the user won't receive a valid session when
  // they login with their old credentials, and thus must send a
  // basic auth with their old credentials, along with their new password
  updatePassword(username, passwordData, key?, secret?) {
    return this.putViaRestApi('admin/user/' + username + '/password', passwordData, key, secret);
  }
  updateTermsAndConditions(username, terms) {
    return this.putViaRestApi('admin/user/' + username + '/termsAndConditions', terms);
  }
  updateStatus(type, id, statusData) {
    return this.putViaRestApi('profile/' + type + '/' + id, statusData);
  }
  scheduleProfileRefresh(entityType, entityId, scheduleRequest) {
    return this.putViaRestApi('profile/refresh/' + entityType + '/' + entityId + '/schedule', scheduleRequest);
  }
  unscheduleProfileRefresh(entityType, entityId) {
    return this.putViaRestApi('profile/refresh/' + entityType + '/' + entityId + '/unschedule');
  }
  getProfileRefreshSchedule(entityType, entityId) {
    return this.getViaRestApi('profile/refresh/' + entityType + '/' + entityId);
  }
  usersInGroup(groupName) {
    return this.getViaRestApi('admin/group/' + groupName + '/user');
  }
  visibleUsers() {
    return this.getViaRestApi('admin/user/visibleUsers');
  }
  updateOwner(type, id, ownerData) {
    return this.putViaRestApi('profile/' + type + '/' + id + '/owner', ownerData);
  }
  notifyUser(type, id, notifyData) {
    return this.postViaRestApi('profile/' + type + '/' + id + '/notify', notifyData);
  }
  requestAdjudicationSupport(type, id) {
    return this.postViaRestApi('profile/' + type + '/' + id + '/adjudicationSupport');
  }
  updateSeedData(entityType, entityId, params) {
    return this.postViaRestApi('profile/refresh/' + entityType + '/' + entityId + '/updateSeedData', params);
  }
  storeSelectedArticles(entityType, entityId, params) {
    const url = 'pdf/' + entityType + '/' + entityId + '/articles';
    return this.postViaRestApi(url, params);
  }
  getSelectedArticles(selectedArticlesId) {
    return this.getViaRestApi('lexisnexis/articles/' + selectedArticlesId);
  }
  getExclusions(entityType, entityId, monitored) {
    let base = '';
    if (typeof monitored === 'boolean' && monitored) {
      base = 'monitor/';
    } else {
      base = 'profile/refresh/';
    }
    const path = base + entityType + '/' + entityId + '/exclusions';

    return this.getViaRestApi(path);
  }
  updateExclusions(entityType, entityId, monitored, params) {
    let base = '';
    if (typeof monitored === 'boolean' && monitored) {
      base = 'monitor/';
    } else {
      base = 'profile/refresh/';
    }
    const path = base + entityType + '/' + entityId + '/exclusions';

    return this.postViaRestApi(path, params);
  }
  getAwards(companyId, filter) {
    const queryString = Object.keys(filter).map((key) => {
      return `${key}=${filter[key]}`;
    });

    return this.getViaRestApi(`awards/${companyId}?${queryString.join('&')}`);
  }

  getCorporateGraphData(entityId, recordId, graphType) {
    const url = `corporateRecord/summary/${entityId}/${recordId}?${graphType}=true`;
    return this.getViaRestApi(url);
  }

  getAdjudicationMetaData(entityId, entityType, adjudicationActions) {
    let url = `adjudication-summary/${entityType}/${entityId}`;

    if (Array.isArray(adjudicationActions) && adjudicationActions.length > 0) {
      url += '?';
    }

    adjudicationActions.forEach((action, index) => {
      if (index !== 0) {
        url += '&';
      }

      url += `action=${action}`;
    });

    return this.getViaRestApi(url);
  }

  getAuthCodeParams() {
    return this.getViaRestApi('oauthToken/authCodeParams');
  }

  exchangeCodeForToken(tokenParams) {
    return this.postViaRestApi('oauthToken/exchangeCodeForToken', tokenParams);
  }

  getProfileCollections(params?): Promise<any> {
    return this.getViaRestApi('profilecollection' + makeProfileCollectionSearchQuery(params));
  }

  getDefaultProfileCollection(): Promise<any> {
    return this.getViaRestApi('profilecollection/access/default');
  }

  updateProfileToCollectionAssignments(entityType, id, payload): Promise<any> {
    return this.postViaRestApi(entityType + '/' + id + '/profilecollections', payload);
  }

  checkProfileCollectionPermission(entityType, entityId, permissionType) {
    let url = `profilecollection/${entityType}/${entityId}?permissionType=${permissionType}`;
    return this.getViaRestApi(url);
  }

  getSAMData(code) {
    let url = `submissionId/search/CAGE/${code}`;
    return this.getViaRestApi(url);
  }
}

function makeProgressQuery(params) {
  var query = '';
  if (params && params.count && params.start) {
    query += '?';
    query += 'count=' + params.count;
    query += '&start=' + params.start;
  } else {
    query = '?count=10&start=0';
  }
  if (params && params.queuedOnly) {
    query += '&queuedOnly=' + params.queuedOnly;
  }
  if (params && params.skipRefreshed) {
    query += '&skipRefreshed=' + params.skipRefreshed;
  }
  return query;
}

function encodeUriFn(uri) {
  return uri ? encodeURIComponent(uri) : '';
}

function makeProfileCollectionSearchQuery(params?) {
  let query = '';
  if (params && Number.isInteger(params.count) && Number.isInteger(params.start)) {
    query += '?';
    query += 'count=' + params.count;
    query += '&start=' + params.start;
  } else {
    query = '?start=0';
  }
  if (params && params.orderBy) {
    query += '&orderBy=' + params.orderBy;
  }
  if (params && params.direction) {
    query += '&direction=' + params.direction;
  }
  if (params && params.needAggregates) {
    query += '&needAggregates=' + params.needAggregates;
  }
  if (params && params.entityId) {
    query += '&entityId=' + params.entityId;
  }
  if (params && params.entityType) {
    query += '&entityType=' + params.entityType;
  }
  if (params && params.currentUserPermissions) {
    query += '&currentUserPermissions=' + params.currentUserPermissions;
  }
  return query;
}
