<div class="modal-title">Permission missing</div>
<p class="center">Please reach out to support to set up your profile collection permissions!</p>
<div class="center">
  <a
    id="profile_submission_permission_missing_profile_view_button"
    class="btn btn-primary btn-lg btn-block center"
    href="#/profiles">
    View Profiles
  </a>
</div>
