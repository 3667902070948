import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripScriptAndMeta',
})
export default class StripScriptAndMetaPipe implements PipeTransform {
  constructor() {}

  /**
   * Removes Script, Title, H1, and meta HTML tags from input
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    let stripped = String(input).replace(
      /(<meta)[^>]+(>)|(<link)[^>]+(>)|(<script)[\s\S]*(<\/script>)|(<title)[\s\S]*(<\/title>)|<h1>|<\/h1>/gim,
      ''
    );
    return stripped.replace(/(<script)[^>]+(>)/gim, '');
  }
}
