import { Component, Input, OnChanges, OnInit } from '@angular/core';
import MaxValuePipe from '../ddiq-filters/max-value.pipe';
import SortableDatePipe from '../ddiq-filters/sortable-date.pipe';

@Component({
  selector: 'watch-list-display-date',
  templateUrl: './watch-list-display-date.component.tpl.html',
})
export default class WatchListDisplayDate implements OnInit, OnChanges {
  @Input() displayCreatedDate;
  @Input() watchList;

  displayDate;
  constructor(private maxValuePipe: MaxValuePipe, private sortableDatePipe: SortableDatePipe) {}

  ngOnInit() {
    this.updateDates();
  }

  ngOnChanges(changes) {
    if (changes.watchList) {
      this.updateDates();
    }
  }

  updateDates() {
    if (this.displayCreatedDate) {
      this.setCreateDate();
    } else {
      this.setOccurredDate();
    }
  }

  setCreateDate() {
    this.displayDate = this.watchList.oiqCreatedDate;
  }

  setOccurredDate() {
    this.displayDate = this.maxValuePipe.transform(this.watchList.events, 'occurred', this.sortableDatePipe);
  }
}
