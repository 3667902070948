import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
class EventGroupSort {
  sort(groups) {
    groups.sort(function (groupA, groupB) {
      var order = 0;

      if (groupA.severity > groupB.severity) {
        order = -1;
      }
      if (groupA.severity < groupB.severity) {
        order = 1;
      }

      if (!order) {
        order = (groupA.translatedClassification || '').localeCompare(groupB.translatedClassification || '');
      }

      return order;
    });
  }
}

export default EventGroupSort;
