import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
} from '@angular/core';
import utils from '../../../exiger/utils';

@Component({ selector: 'ddiq-limited-list', templateUrl: './ddiq-limited-list.component.tpl.html' })
export default class DdiqLimitedListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() list: Array<string> = [];
  @Input() limit: number;
  @Input() message: string;
  @Input() isOpen: boolean;
  @Input() class: string = '';
  @ContentChild('parentTemplate') templateRef: TemplateRef<any>;
  isOpenInProcessing: boolean;
  isPrintExpanded: boolean;
  openMessage: string;
  closeMessage: string;
  limitedList: Array<string>;

  constructor(private el: ElementRef, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.isPrintExpanded = utils.getParameter('print', window.location.hash) === 'expanded';
    this.isOpenInProcessing = !!this.isOpen || this.isPrintExpanded;
    //initialise values in case inputs passed in are undefined
    this.list = this.list ?? [];
  }

  ngAfterViewInit() {
    this.isOpenInProcessing = !!this.isOpen || this.isPrintExpanded;
    const remainingCount = this.list.length - this.limit;

    this.openMessage = '+ ' + remainingCount + ' more';
    this.closeMessage = '- show less';

    this.applyMessage();
    this.updateList();
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes) {
    if (!this.isPrintExpanded && typeof this.isOpen !== 'undefined') {
      if (changes.isOpen?.currentValue !== changes.isOpen?.previousValue) {
        this.isOpenInProcessing = !!this.isOpen;
        this.applyMessage();
        this.updateList();
      }
    }
  }

  toggle() {
    this.isOpenInProcessing = !this.isOpenInProcessing;
    this.applyMessage();
    this.updateList();
    this.scrollIntoView();
  }

  showButton() {
    return this.list.length > this.limit;
  }

  applyMessage() {
    this.message = this.isOpenInProcessing ? this.closeMessage : this.openMessage;
  }

  updateList() {
    if (this.list) {
      this.limitedList = this.isOpenInProcessing ? this.list : this.list.slice(0, this.limit);
    } else {
      this.limitedList = [];
    }
  }

  scrollIntoView() {
    const node = this.el.nativeElement;
    if (!this.isOpenInProcessing && node.getBoundingClientRect().top < 0) {
      node.scrollIntoView();
    }
  }
}
