import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'additionalLocationSubmission',
})
export default class AdditionalLocationSubmissionPipe implements PipeTransform {
  transform(additionalLocations: any) {
    return (additionalLocations || []).filter(function (location) {
      return location.source === 'AdditionalLocationSubmission' && !location.label;
    });
  }
}
