import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ddiq-website-scorecards-group',
  templateUrl: './ddiq-website-scorecards-group.component.tpl.html',
})
export default class WebsiteScorecardsGroupComponent {
  @Input() groups: any;
  @Output() sortDiscoveries: any = new EventEmitter();
  @Input() entityType: any;
  @Input() idPrefix: any;
  @Input() expandAll: any;

  constructor() {}

  id(index: number, scorecard: any) {
    return scorecard.id;
  }

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  classification(index: number, group: any) {
    return group.classification;
  }
}
