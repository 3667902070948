import { Component, Input, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ModalTrackingService from '../modal/modal-tracking.service';
@Component({
  selector: 'ddiq-adjudication-unavailable-alert',
  templateUrl: 'ddiq-adjudication-unavailable-alert.component.tpl.html',
})
export class AdjudicationUnavailableAlertComponent implements OnInit {
  supportEnabled: boolean;
  adjudicationEnabled: boolean;

  @Input() entity: Entity;

  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private modalTrackingService: ModalTrackingService
  ) {}

  ngOnInit() {
    this.supportEnabled = this.oiqProperties.adjudicationSupportEnabled;
    this.adjudicationEnabled = !!this.oiqProperties.adjudicationEnabled;
  }

  requestSupport() {
    this.apiFetchService.requestAdjudicationSupport(this.entity.getType(), this.entity.getId()).then(
      () => this.modalTrackingService.openError('adjudicationSupportSuccess'),
      () => this.modalTrackingService.openError('adjudicationSupportError')
    );
  }
}
