import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'oiq-session-termination-message',
  templateUrl: './session-termination-message.component.tpl.html',
})
export default class SessionTerminationMessageComponent {
  @Input() content;
  @Output() logoutConfirmed = new EventEmitter<any>();

  logoutConfirmedClick() {
    this.logoutConfirmed.emit();
  }
}
