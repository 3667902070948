import { Component, OnDestroy, OnInit } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import AlertService from '../common-services/alert.service';
import ApiFetchService from '../common-services/api-fetch.service';
import EntityCache from '../common-services/entity-cache.service';
import EntityPreprocessor from '../common-services/entity-preprocessor.service';
import Entity from '../common-services/entity.service';
import FieldSourceMetadata from '../common-services/field-source-metadata.service';
import I18nService from '../common-services/i18n.service';
import OiqProperties from '../common-services/oiq-properties.service';
import PollingService from '../common-services/polling.service';
import RelationshipService from '../common-services/relationship-service.factory';
import TypeLookup from '../common-services/type-lookup.service';
import OwnershipData from '../corporate-records/ownership-data.service';
import MonitorCrawlPlans from '../monitor/monitor-crawlplans.service';
import SelectedArticlesFeatureService from '../protected-articles/selected-articles-feature.service';
import SelectedArticlesService from '../protected-articles/selected-articles.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

import $ from 'jquery';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import Monitor from '../monitor/monitor.service';
import { ProfileService } from '../common/profile.service';
import { Constant } from '../common/ddiq-constants';
import { ByEventId, ByHitId, NavigationRequest, ProfileNavigationService } from './profile-navigation.service';
import { ProfileScrollService } from './profile-scroll.service';
import { Subscription } from 'rxjs';
import { ProfileStylingService } from './profile-styling.service';

@Component({
  selector: 'ddiq-profile',
  templateUrl: 'ddiq-profile.component.tpl.html',
  providers: [ProfileNavigationService, ProfileScrollService],
})
export default class ProfileComponent implements OnInit, OnDestroy {
  deregisterEventProfileFiltered;
  deregisterEventGroupSorted;
  deregisterEventGroupsToggle;
  profile;
  isExpandAllGroups;
  pollResults;
  previewingEntity;
  isLoading;
  profileBuilding;
  isExpandAll;
  pollParams;
  isProperty;
  entityType;
  entityId;
  overviewId;
  comparableId;
  isProfileNavigating: boolean;
  private deregisterNavigationRequested: Subscription;
  private deregisterEventNavigationRequested: Subscription;
  private window;
  private isMonitoredProfile;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private apiFetchService: ApiFetchService,
    public entity: Entity,
    private entityCache: EntityCache,
    private oiqProperties: OiqProperties,
    private pollingService: PollingService,
    private errorHandler: ErrorHandler,
    private fieldSourceMetadata: FieldSourceMetadata,
    private typeLookup: TypeLookup,
    private monitor: Monitor,
    private relationshipService: RelationshipService,
    private entityPreprocessor: EntityPreprocessor,
    private i18nService: I18nService,
    private ownershipData: OwnershipData,
    private selectedArticlesFeature: SelectedArticlesFeatureService,
    private selectedArticles: SelectedArticlesService,
    private modalTrackingService: ModalTrackingService,
    private profileService: ProfileService,
    private monitorCrawlPlans: MonitorCrawlPlans,
    private messageBusService: MessageBusService,
    private windowRef: WindowRefService,
    private profileNavigation: ProfileNavigationService,
    private profileScroll: ProfileScrollService,
    private profileStyling: ProfileStylingService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.isMonitoredProfile = route.snapshot.queryParams['monitor'];
  }

  ngOnInit(): void {
    this.apiFetchService.setWebContent(Constant.WEB_CONTENT_TYPE);
    this.apiFetchService.setMarkup(this.oiqProperties.markup);
    this.apiFetchService.setAttachAll(false);
    this.selectedArticlesFeature.setEnabled(this.oiqProperties.pdfSelectedArticlesEnabled);

    this.route.params.subscribe((params: Params) => {
      this.entityType = params.entityType;
      this.entityId = params.entityId;
      this.overviewId = params.overviewId;
      this.comparableId = params.comparableId;
      this.initialize();
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (this.isMonitoredProfile !== params.monitor) {
        this.isMonitoredProfile = params.monitor;
        this.initialize();
      }
    });

    this.listenForProfileFiltered();
    this.listenForEventGroupSorted();
    this.listenForEventGroupsToggled();
  }

  ngOnDestroy(): void {
    this.selectedArticles.removeAll();
    this.deregisterEventProfileFiltered.unsubscribe();
    this.deregisterEventGroupSorted.unsubscribe();
    this.deregisterEventGroupsToggle.unsubscribe();
    this.profileService.clear();
    this.relationshipService.clear();
    this.deregisterNavigationRequested?.unsubscribe();
    this.deregisterEventNavigationRequested?.unsubscribe();
  }

  listenForProfileFiltered() {
    this.deregisterEventProfileFiltered = this.messageBusService.on(
      'event:profile-filtered',
      () => (this.profile = this.profileService.refreshEventGroups())
    );
  }

  listenForEventGroupSorted() {
    this.deregisterEventGroupSorted = this.messageBusService.on('event:sort-event-group', (sort) => {
      this.profile = this.profileService.sortEventGroupDiscoveries(sort);
    });
  }

  listenForEventGroupsToggled() {
    this.deregisterEventGroupsToggle = this.messageBusService.on('event:expand-all-event-groups-toggled', (isOpen) => {
      this.profile = this.profileService.toggleEventGroups(isOpen);
      // turns off previous auto expand.
      this.isExpandAllGroups = false;
    });
  }

  setEntityConfig() {
    var sectionConfig;

    this.pollResults = {};

    // for disabling certaing controls in Preview mode
    this.previewingEntity = false;
    // for breadcrumb navigation with multiple relationships
    this.typeLookup.set(this.entity.getId(), this.entity.getType());

    // initialize fields/processes which change if diff-ing
    if (!this.entity.isDiff()) {
      // polling and polling results object for profile building
      this.pollParams.building = true;

      if (this.monitor.isDeltaReport()) {
        var monitoredCategories = this.monitor.getMonitorData().categories;
        var noteworthy = 'visible';
        if (monitoredCategories.length > 0 && $.inArray('noteworthy', monitoredCategories) > -1) {
          noteworthy = 'hidden';
        }

        sectionConfig = {
          'ui.references.display': 'hidden',
        };

        this.entity.setDynamicSectionConfig(sectionConfig);
      }
    }

    this.pollResults = this.pollingService.getPollResults();
    this.profileBuilding = false; // displays/hides the profile building div
    this.isExpandAll = false;
  }

  /**
   * Grabs the array of alerts which are, unfortunately, named specifically for each entity
   * type.  Then passes them to the AlertService to be processed in place.
   *
   * @param entityData
   * @param type
   */
  processAlerts(entityData, type) {
    var alerts, entityUrl;

    // the battle continues
    if (type === 'property') {
      type = 'location';
    }

    switch (type) {
      case 'location':
        alerts = entityData.propertyAlerts;
        entityUrl = this.buildEntityUrl(this.entity.getParentId(), 'location', entityData.oiqentityId);
        break;
      case 'person':
        alerts = entityData.personAlerts;
        entityUrl = this.buildEntityUrl(this.entity.getParentId(), 'person', entityData.oiqentityId);
        break;
      case 'company':
        alerts = entityData.companyAlerts;
        entityUrl = this.buildEntityUrl(this.entity.getParentId(), 'company', entityData.oiqentityId);
        break;
    }
    if (alerts) {
      this.alertService.buildUrls(alerts, type, entityUrl, false);
    }
  }

  buildEntityUrl(cId, entityType, eId) {
    return '#/overview/' + cId + '/' + entityType + '/' + eId;
  }

  /**
   * takes fetched data and calls browser appropriate processing functions
   *
   * @param entityData
   */
  successfulFetch(results) {
    const entityData = results[0];
    this.entityPreprocessor.process(entityData);
    this.entity.setData(entityData);
    this.entity.setIsAdjudicatableByUser(results[4]);
    this.previewingEntity = this.entity.isInPreview();

    this.processAlerts(this.entity.getData(), this.entity.getType());

    this.profile = this.profileService.configureProfile(this.entity.getType(), entityData);
    this.isExpandAllGroups = this.profile.isAutoExpanded;
    if (!this.previewingEntity) {
      this.deregisterNavigationRequested = this.profileNavigation.navigationRequested$.subscribe(
        (navigationRequest: NavigationRequest) => {
          this.ensurePromiseIsHandled(this.handleNavigationRequest(navigationRequest));
        }
      );
    }
    this.monitorCrawlPlans.loadAvailableCrawlPlans(entityData.crawlPlanType).then(() => {
      this.isLoading = false; // wait for the crawl plans to load before showing main div
    });

    // DDIQ does fieldSourceMetadata
    this.fieldSourceMetadata.setMetadata(this.entity.getData().fieldSourceMetadata);
    // references section normally results in the last server call and the loadSource directive
    // handles the page complete trigger. When the section is hidden, (monitoring or via properties)
    // we need to trigger after the entity is loaded
    if (this.monitor.isDeltaReport() || !this.oiqProperties.isDisplayReferences) {
      setTimeout(() => (this.window.status = 'oiqLoadComplete'), 3000);
    }
    // set section to null
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: null },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  failedFetch = (message) => {
    this.errorHandler.error('failed fetch (entity)' + JSON.stringify(message));

    this.isLoading = false;
  };

  /**
   * hits the api for data
   *
   * attached to the scope so that child controllers can reload the entire entity if an on demand service
   * propagates data to the main model
   *
   * @param forceReload
   */
  loadEntity(forceReload) {
    this.pollingService.init(this.entity.getType(), this.entity.getId(), this.pollParams, true);

    this.apiFetchService.setMarkup(this.oiqProperties.markup);
    this.relationshipService.clear();

    const requests = this.makeRequests(forceReload);

    Promise.all(requests)
      .then((results) => this.fetchAndApplyOwnershipData(results))
      .then((results) => {
        this.successfulFetch(results);
      })
      .catch(this.failedFetch);
  }

  fetchAndApplyOwnershipData(results) {
    const entityData = results[0];
    return this.ownershipData
      .applyOwnershipDataToCorporateRecords(this.entity.getId(), entityData.corporateRecords)
      .then(() => {
        return results;
      });
  }

  makeRequests(forceReload) {
    const requests = [],
      entityType = this.entity.getType(),
      entityId = this.entity.getId();

    if (!this.entity.isDiff()) {
      requests.push(this.entityCache.fetchEntity(entityType, entityId));
      requests.push(this.relationshipService.people(entityType, entityId));
      requests.push(this.relationshipService.companies(entityType, entityId, forceReload));
      requests.push(this.relationshipService.locations(entityType, entityId));
      requests.push(this.apiFetchService.checkProfileCollectionPermission(entityType, entityId, 'adjudication'));
    } else {
      const diffId = this.entity.getDiffId();
      requests.push(this.apiFetchService.diff(entityType, entityId, diffId));
      requests.push(this.relationshipService.peopleDiff(entityType, entityId, diffId));
      requests.push(this.relationshipService.companiesDiff(entityType, entityId, diffId));
      requests.push(this.relationshipService.locationsDiff(entityType, entityId, diffId));
    }

    requests.push(this.i18nService.fetchCountries);
    return requests;
  }

  initializeEntity() {
    this.entity.clear();
    this.monitor.init(this.isMonitoredProfile);
    const entityType = this.entityType === 'location' ? 'property' : this.entityType;

    this.entity.setId(parseInt(this.entityId, 10));
    this.entity.setType(entityType);
    this.entity.setParentId(parseInt(this.overviewId, 10));

    if (this.comparableId) {
      // set diff
      this.entity.setDiff(true);
      this.entity.setDiffId(parseInt(this.comparableId, 10));
    }
  }

  configureAndLoad() {
    this.setEntityConfig();

    this.loadEntity(false);
  }

  onProfileAssigned(assignedTo) {
    this.entity.getData().oiqOwner = assignedTo.username;
    this.modalTrackingService.getTriggers().delegate.open = false;
  }

  onProfileAssignCancelled() {
    this.modalTrackingService.getTriggers().delegate.open = false;
  }

  onProfileAssignError(error) {
    if (error.errorMsg === 'OWNER_CHANGE_CONFLICT_ERROR' && error.owner) {
      this.entity.getData().oiqOwner = error.owner.username;
    }
  }

  reloadProfile() {
    this.initialize();
  }

  private initialize() {
    this.pollParams = {};
    this.isLoading = true;
    this.initializeEntity();
    this.monitor.loadMonitor(this.entity.getType(), this.entity.getId(), () => this.configureAndLoad());
    this.isProperty = this.entity.isProperty();
  }

  private async handleNavigationRequest(navigationRequest: NavigationRequest) {
    this.isProfileNavigating = true;
    let id = navigationRequest.id;

    if (navigationRequest instanceof ByHitId) {
      this.profileService.openEventGroup(id);
    } else if (navigationRequest instanceof ByEventId) {
      id = this.profileService.getLookupIdByEvent(id);
    }

    try {
      const element = await this.profileScroll.scrollWhenStableTo(id);
      this.profileStyling.updateNavigationClasses(element);
    } catch (error) {
      console.error(error);
    } finally {
      this.isProfileNavigating = false;
    }
  }

  private ensurePromiseIsHandled(promise: Promise<any>) {
    promise.catch((error) => console.error(error));
  }
}
