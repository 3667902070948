import { Component, Input, OnInit } from '@angular/core';
import SelectedArticlesFeatureService from './selected-articles-feature.service';
import SelectedArticlesService from './selected-articles.service';

@Component({
  selector: 'protected-article-selector',
  templateUrl: 'protected-article-selector.component.tpl.html',
})
export default class ProtectedArticleSelectorComponent implements OnInit {
  @Input() articleId;

  isEnabled;

  constructor(
    private selectedArticles: SelectedArticlesService,
    private selectedArticlesFeature: SelectedArticlesFeatureService
  ) {}

  ngOnInit() {
    this.isEnabled = this.selectedArticlesFeature.isEnabled();
  }

  isSelected() {
    return this.selectedArticles.isSelected(this.articleId);
  }

  toggleArticleSelection() {
    let isSelected = this.selectedArticles.isSelected(this.articleId);

    if (isSelected) {
      this.selectedArticles.removeArticle(this.articleId);
    } else {
      this.selectedArticles.addArticle(this.articleId);
    }
  }
}
