import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullsToEnd',
})
export default class NullsToEndPipe implements PipeTransform {
  constructor() {}

  /**
   * Moves empty array entries to the end of the array if `field` does not exist
   * @param array
   * @param field
   * @returns {Array}
   */
  transform(array: any, field?: any) {
    if (!Array.isArray(array)) return;

    let present = array.filter((item) => {
      return item[field];
    });
    let empty = array.filter((item) => {
      return !item[field];
    });
    return present.concat(empty);
  }
}
