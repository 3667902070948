import { Component, Input, OnInit } from '@angular/core';
import AdjudicationType from '../adjudication/adjudication-type.service';

@Component({
  selector: 'ddiq-watch-list',
  templateUrl: './ddiq-watch-list.component.tpl.html',
})
export default class WatchList implements OnInit {
  @Input() watchList;
  @Input() isDisplayClassifications;
  @Input() displayCreatedDate;
  @Input() isAdjudicateable;
  @Input() entityType;
  @Input() entityId;
  @Input() noReassess;
  adjudicationType;

  constructor(private adjudicationTypeService: AdjudicationType) {}

  ngOnInit() {
    this.adjudicationType = this.adjudicationTypeService.getAdjudicationTypeFor('watchlists', this.watchList);
  }

  id(index: number, watchlist: any) {
    return watchlist.id;
  }
}
