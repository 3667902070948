import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class IncidentBySectionExtractionStrategy {
  extract(sections, entity, onSectionExtracted) {
    onSectionExtracted = onSectionExtracted || function noop() {};

    sections.forEach(function (section) {
      let incidents = [];

      if (entity[section]) {
        incidents = entity[section].incidents || incidents;
      }

      onSectionExtracted(section, incidents);
    });
  }
}
