import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'noteworthySubSectionPipe',
})
export default class NoteworthySubSectionPipe implements PipeTransform {
  transform(subSections, hasContent, oshaInRegulatorySection) {
    let filteredSubSection;

    filteredSubSection = (subSections || []).filter((item) => {
      if (item === 'productRecalls') {
        return hasContent;
      }
      if (item === 'oshaSafetyInspections') {
        return !oshaInRegulatorySection && hasContent;
      }
      if (item === 'incidents') {
        return true;
      }
    });

    return filteredSubSection;
  }
}
