import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import utils from '../../../exiger/utils';
import AdjudicationSummaryConfig from '../adjudication/adjudication-summary-config.service';
import EntityCache from '../common-services/entity-cache.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ExclusionsService from '../exclusions/exclusions.service';
import Monitor from '../monitor/monitor.service';
import ScreeningFeatureService from '../screening/screening-feature.service';
import AlertSummarizer from '../themed-entity/alert-summarizer.service';
import Filter from '../themed-entity/filter.service';

@Component({
  selector: 'ddiq-main-profile',
  templateUrl: 'ddiq-main-profile.component.tpl.html',
})
export default class MainProfileComponent implements OnInit, OnDestroy {
  displayAdjudicationSummary;
  printView;
  isAdjudicatedEvent;
  adjudicationSummarySpec;
  @Input() profile;
  @Input() isExpandAllGroups;
  isDeltaReport;
  isDiff;
  isScreeningEnabled;

  constructor(
    private alertSummarizer: AlertSummarizer,
    private filter: Filter,
    private entityCache: EntityCache,
    public entity: Entity,
    private exclusionsService: ExclusionsService,
    private oiqProperties: OiqProperties,
    private adjudicationSummaryConfig: AdjudicationSummaryConfig,
    private monitor: Monitor,
    private messageBusService: MessageBusService,
    private screeningFeature: ScreeningFeatureService
  ) {}

  ngOnInit(): void {
    this.displayAdjudicationSummary = this.oiqProperties.displayAdjudicationSummary && !this.entity.isProperty();
    this.isDeltaReport = this.monitor.isDeltaReport();
    this.isDiff = this.entity.isDiff();
    this.isScreeningEnabled = this.screeningFeature.isEnabled();

    const printStyle = utils.getParameter('print', window.location.hash);

    if (printStyle) {
      this.printView = printStyle;
      this.alertSummarizer.clear();
    }

    this.filter.setFilterMask(utils.getParameter('filter', window.location.hash));

    this.updateAdjudicationSummary();
    this.messageBusService.on('event:profile-filtered', () => this.updateAdjudicationSummary());
    this.messageBusService.on('event:event-adjudicated', () => {
      this.isAdjudicatedEvent = true;
      this.filter.apply(this.filter.getFilter());
    });
  }

  ngOnDestroy() {
    if (this.isAdjudicatedEvent) {
      this.entityCache.invalidate(this.entity.getId());
    }

    this.filter.clearFilter();
    this.exclusionsService.clear();
  }

  updateAdjudicationSummary() {
    if (this.displayAdjudicationSummary) {
      this.adjudicationSummarySpec = this.makeAdjudicationSummarySpec();
    }
  }

  makeAdjudicationSummarySpec() {
    return this.adjudicationSummaryConfig.getConfigFor(this.entity.getData().crawlPlanType, this.entity.getType());
  }
}
