import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import DdiqLocationListComponent from './ddiq-location-list.component';
import DdiqPersonListComponent from './ddiq-person-list.component';
import DdiqRelatedPeopleComponent from './ddiq-related-people.component';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import OiqAssociationModule from '../association/oiq-association.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqCompanyListComponent from './ddiq-company-list.component';
import DdiqRelatedCompaniesComponent from './ddiq-related-companies.component';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../../../../shared/shared.module';
import DdiqRelatedLocationsComponent from './ddiq-related-locations.component';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { NetworkModule } from '../network/oiq-network.module';
import { DdiqCorporateRecordsModule } from '../corporate-records/ddiq-corporate-records.module';

import RelatedEntityProcessor from '../themed-entity/related-entity-processor.service';
import RelationshipFilter from '../themed-entity/relationship-filter.service';
import { AlertsModule } from '../alerts/oiq-alerts.module';

@NgModule({
  imports: [
    CommonModule,
    DdiqUIModule,
    PipesModule,
    DdiqDirectivesModule,
    OiqAdjudicationModule,
    SharedModule,
    OiqAssociationModule,
    OiqSourcesModule,
    DdiqUpgradeModule,
    NgPipesModule,
    ThemedEntityModule,
    AlertsModule,
    NetworkModule,
    DdiqCorporateRecordsModule,
  ],
  providers: [RelatedEntityProcessor, RelationshipFilter],
  declarations: [
    DdiqCompanyListComponent,
    DdiqRelatedCompaniesComponent,
    DdiqPersonListComponent,
    DdiqRelatedPeopleComponent,
    DdiqLocationListComponent,
    DdiqRelatedLocationsComponent,
  ],
  exports: [DdiqRelatedCompaniesComponent, DdiqRelatedPeopleComponent, DdiqRelatedLocationsComponent],
})
export class OiqRelatedEntityModule {}
