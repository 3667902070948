import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class ProfileStatusFeatureService {
  actionNameLookup = { confirm: 'CONFIRM', star: 'ESCALATE', remove: 'REMOVE' };

  constructor(
    private oiqProperties: OiqProperties,
    private adjudicationFeature: AdjudicationFeatureService,
    private errorHandler: ErrorHandler
  ) {}

  isVisible() {
    return this.oiqProperties.profileStatusEnabled && !!(this.oiqProperties.profileStatuses || []).length;
  }

  isAdjudicationRequiredEnabled() {
    return this.getAdjudicationRequiredAdminConfigs().isEnabled;
  }

  isAdjudicationRequiredConfigValid() {
    const { adjudicationActions, confidenceThreshold, adjudicationRequiredStatuses } =
      this.getAdjudicationRequiredAdminConfigs();

    const isAdjudicationEnabled = this.adjudicationFeature.isAdjudicationEnabled();
    const isValid =
      isAdjudicationEnabled &&
      this.hasValidAdjudicationActions(adjudicationActions) &&
      this.hasValidConfidenceThreshold(confidenceThreshold) &&
      this.hasValidAdjudicationRequiredStatuses(adjudicationRequiredStatuses);

    if (!isValid) {
      this.errorHandler.error(
        `Invalid profile status adjudication required config:
          actions: ${adjudicationActions}
          threshold: ${confidenceThreshold}
          required statuses: ${adjudicationRequiredStatuses},
          isAdjudicationEnabled: ${isAdjudicationEnabled}`
      );
    }

    return isValid;
  }

  getAdjudicationRequiredAdminConfigs() {
    return {
      isEnabled: this.oiqProperties.profileStatusAdjudicationRequiredEnabled,
      adjudicationActions: this.oiqProperties.profileStatusAdjudicationActions,
      adjudicationRequiredStatuses: this.oiqProperties.profileStatusAdjudicationRequiredStatuses,
      confidenceThreshold: this.oiqProperties.profileStatusConfidenceThreshold,
    };
  }

  hasValidAdjudicationActions(adjudicationActions) {
    const supportedAdjudicationActions = ['Remove', 'Confirm', 'Star'],
      hasDuplicates = new Set(adjudicationActions).size !== adjudicationActions.length;

    if (adjudicationActions.indexOf('Confirm') > -1 && !this.adjudicationFeature.isConfirmActionEnabled()) {
      return false;
    }

    return (
      adjudicationActions.length > 0 &&
      adjudicationActions.length <= 3 &&
      !hasDuplicates &&
      adjudicationActions.every((action) => supportedAdjudicationActions.indexOf(action) !== -1)
    );
  }

  getNormalizedAdjudicationRequiredNames() {
    const { adjudicationActions } = this.getAdjudicationRequiredAdminConfigs();

    return adjudicationActions.map((action) => {
      const actionName = this.actionNameLookup[action.toLowerCase()];
      if (!actionName) {
        throw `Unknown adjudication action ${action}.  Supported actions are ${Object.keys(this.actionNameLookup).join(
          ', '
        )}`;
      }
      return actionName;
    });
  }

  hasValidAdjudicationRequiredStatuses(requiredStatuses) {
    if (this.oiqProperties.profileStatuses.length === 0 || requiredStatuses.length === 0) {
      return false;
    }

    const areAllKnownStatuses = requiredStatuses.every((requiredStatus) => {
      return this.oiqProperties.profileStatuses.indexOf(requiredStatus) >= 0;
    });

    return areAllKnownStatuses;
  }

  hasValidConfidenceThreshold(confidence) {
    return !!(confidence && confidence.trim());
  }
}
