import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-confirm-modal',
  templateUrl: './ddiq-confirm-modal.component.tpl.html',
})
export default class ConfirmModalComponent {
  @Input() toggleConfirm;

  constructor() {}
}
