import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ddiq-network',
  templateUrl: './ddiq-network.component.tpl.html',
})
export default class NetworkComponent implements OnInit {
  entity;

  showEntityError: boolean;
  entityName: string;
  networkConfig: object;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data?.subscribe((data) => {
      this.entity = data.entity;
    });

    this.showEntityError = !this.entity;
    if (!this.showEntityError) {
      this.entityName = this.entity.getDisplayName();

      this.networkConfig = {
        entityId: this.entity.getId(),
        entityType: this.entity.getType(),
      };
    }
  }
}
