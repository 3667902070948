import { Component, OnDestroy, OnInit } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import MonitorSearchService from './monitor-search.service';

@Component({
  selector: 'ddiq-monitor-report-container',
  templateUrl: './ddiq-monitor-report-container.tpl.html',
})
export default class MonitorReportContainer implements OnInit, OnDestroy {
  dateLabelPattern: string = 'YYYY-MM-DD';
  dateInputPattern: string = 'yy-mm-dd';
  dates: any = {};

  constructor(private oiqProperties: OiqProperties, public monitorSearchService: MonitorSearchService) {}

  ngOnInit(): void {
    if (this.oiqProperties.dateFormat === 'US') {
      this.dateLabelPattern = 'MM/DD/YYYY';
      this.dateInputPattern = 'mm/dd/yy';
    }

    this.setDates(this.dates);

    this.applyPreviousQuery();
  }

  ngOnDestroy(): void {
    this.monitorSearchService.clearAll();
  }

  search(dates): void {
    this.monitorSearchService.go({}, dates, true);
  }

  clearSearch(dates): void {
    this.setDates(dates);
    this.search(dates);
  }

  onAfterDateChange(afterDate): void {
    if (afterDate) {
      this.dates.options.before = {
        minDate: afterDate ? afterDate : null,
        maxDate: new Date(),
        dateFormat: this.dateInputPattern,
      };
    }
  }

  onBeforeDateChange(beforeDate): void {
    if (beforeDate) {
      this.dates.options.after = {
        maxDate: beforeDate ? beforeDate : new Date(),
        dateFormat: this.dateInputPattern,
      };
    }
  }

  private setDates(dates) {
    const maxDate = jQuery.datepicker.formatDate(this.dateInputPattern, new Date());

    dates.after = null;
    dates.before = null;

    dates.options = {
      after: {
        dateFormat: this.dateInputPattern,
        maxDate: maxDate,
      },
      before: {
        dateFormat: this.dateInputPattern,
        maxDate: maxDate,
      },
    };

    return dates;
  }

  private applyPreviousQuery(): void {
    this.monitorSearchService.parseUrl();
    const urlQuery = this.monitorSearchService.previousQuery;

    if (urlQuery?.after) {
      this.dates.after = jQuery.datepicker.formatDate(
        this.dateInputPattern,
        jQuery.datepicker.parseDate('yy-mm-dd', urlQuery.after)
      );
    } else {
      this.dates.after = null;
    }

    if (urlQuery?.before) {
      this.dates.before = jQuery.datepicker.formatDate(
        this.dateInputPattern,
        jQuery.datepicker.parseDate('yy-mm-dd', urlQuery.before)
      );
    } else {
      this.dates.before = null;
    }
  }
}
