<div class="input {{size}}" [ngClass]="{disabled : oiqTextDisabled}">
  <label [ngClass]="{required: isRequired}"
    >{{label}} <span class="format-hint" *ngIf="labelPattern">({{labelPattern}})</span></label
  >
  <input
    type="text"
    name="ddiqDate"
    type="text"
    [required]="isRequired"
    [disabled]="oiqTextDisabled"
    autocomplete="off" />
</div>
