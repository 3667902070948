import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-location-form',
  templateUrl: './ddiq-location-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class DdiqLocationFormComponent implements OnInit {
  @Input() locations;
  @Input() countries;

  districtEnabled;
  constructor(private oiqProperties: OiqProperties) {}

  ngOnInit() {
    this.districtEnabled = this.oiqProperties.districtEnabled;
  }

  addLocation() {
    this.locations.push({});
  }

  removeLocation(index) {
    this.locations.splice(index, 1);
  }
}
