import { Component } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import UserService from '../user/user.service';

@Component({
  selector: 'ddiq-home',
  templateUrl: 'ddiq-home.component.tpl.html',
})
export default class HomeComponent {
  isPersonSubmissionEnabled: boolean;
  generateOutputEnabled: boolean;
  monitoringEnabled: boolean;

  constructor(public user: UserService, private oiqProperties: OiqProperties) {
    this.isPersonSubmissionEnabled = oiqProperties.personSubmissionEnabled;
    this.generateOutputEnabled = oiqProperties.generateOutputEnabled;
    this.monitoringEnabled = oiqProperties.monitoringEnabled;
  }
}
