<div class="tabbed">
  <ul>
    <li [ngClass]="{ active: curTab === 1 }">
      <a (click)="curTab = 1">Input Data</a>
    </li>
    <li [ngClass]="{ active: curTab === 2 }">
      <a (click)="curTab = 2">Additional Input</a>
    </li>
    <li
      class="comments-tab"
      [ngClass]="{ active: curTab === 3, disabled: isCommentDisabled }"
      *ngIf="isAdjudicationEnabled && !hideComments">
      <a (click)="curTab = 3">Case Comments</a>
    </li>
    <li [ngClass]="{ active: curTab == 4 }" *ngIf="isProfileCollectionEnabled">
      <a (click)="curTab = 4">Visibility ({{seedData.collections.length}})</a>
    </li>
  </ul>
  <br class="clr" />
  <div class="tab-body-input-data" *ngIf="curTab === 1">
    <company-input-data-detail *ngIf="isCompany" [seedData]="seedData" [section]="section"></company-input-data-detail>
    <person-input-data-detail *ngIf="isPerson" [seedData]="seedData" [section]="section"></person-input-data-detail>
    <br class="clr" />
  </div>
  <div class="tab-body-input-additional-data-detail" *ngIf="curTab === 2">
    <company-additional-input-data-detail
      *ngIf="isCompany"
      [seedData]="seedData"></company-additional-input-data-detail>
    <person-additional-input-data-detail *ngIf="isPerson" [seedData]="seedData"></person-additional-input-data-detail>
    <br class="clr" />
  </div>
  <div [hidden]="curTab !== 3">
    <div class="right" *ngIf="canAdjudicate && isAdjudicatable">
      <button
        class="btn btn-primary btn-sm push-down"
        [ngClass]="{greyed : section.data.comment}"
        (click)="editComment()"
        [disabled]="section.data.comment">
        Add Case Comment
      </button>
    </div>
    <div class="clr">
      <ddiq-adjudication-notes
        class="adjudication-notes"
        [type]="entityType"
        [entityType]="entityType"
        [entityId]="entityId"
        [model]="section.data"
        [targetId]="section.data.id"
        [label]="'Case Comments'"
        [active]="section.data.comment">
      </ddiq-adjudication-notes>
    </div>
    <br class="clr" />
  </div>
  <div *ngIf="curTab === 4">
    <span class="badge" *ngFor="let collection of seedData.collections">{{collection.name}}</span>
    <br class="clr" />
  </div>
</div>
