import { Component, DoCheck, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import $ from 'jquery';
import ModalTrackingService from './modal-tracking.service';

@Component({
  selector: 'ddiq-modal',
  templateUrl: './ddiq-modal.component.tpl.html',
  exportAs: 'ddiqModal',
})
export default class DdiqModalComponent implements OnInit, DoCheck {
  previousOpen: boolean = false;
  name;
  hasCloseBtn;
  vm;
  @Input() trigger;
  @Input() modalName;
  @Input() focus;
  @Input() closeable = false;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalTrackingService: ModalTrackingService, private messageBusService: MessageBusService) {}

  ngOnInit(): void {
    this.name = this.modalName || '';
    this.hasCloseBtn = this.closeable;
  }

  ngDoCheck(): void {
    if (typeof this.trigger === 'string') {
      this.vm = this.modalTrackingService.getTriggers()[this.trigger];
    } else {
      this.vm = this.trigger;
    }

    if (this.vm && this.vm.open !== this.previousOpen) {
      if (this.vm.open) {
        if (this.focus) {
          $('#' + this.focus).trigger('focus');
        }
        //OIQ-47123: $timeout is a hack to fix broadcast events firing before
        //listeners have registered.
        setTimeout(() => {
          this.messageBusService.send({ type: 'event:' + this.name + '-modal-opened' });
        }, 1);
      }
      this.previousOpen = this.vm.open;
    }
  }

  closeClicked() {
    this.vm.open = false;
    this.closed.emit();
  }
}
