import { Component, OnDestroy, DoCheck, OnInit } from '@angular/core';
import Entity from '../common-services/entity.service';
import RelationshipService from '../common-services/relationship-service.factory';
import IncidentsService from '../themed-entity/incidents.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import StructuredData from '../themed-entity/structured-data.service';
import UserService from '../user/user.service';
import AdjudicationFeatureService from './adjudication-feature.service';
import AdjudicationType from './adjudication-type.service';
import DynamicAdjudicationService from './dynamic-adjudication.service';

@Component({
  selector: 'ddiq-dynamic-adjudication',
  templateUrl: './ddiq-dynamic-adjudication.component.tpl.html',
})
export default class OiqDynamicAdjudicationComponent implements OnInit, OnDestroy, DoCheck {
  adjudicationActions = {
    CONFIRM: true,
    REMOVE: true,
    ESCALATE: true,
    COMMENT: true,
  };
  actionsVisible: boolean = false;
  previousMode: string;
  isStarEnabled: boolean;

  constructor(
    private entity: Entity,
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private modalTrackingService: ModalTrackingService,
    private dynamicAdjudicationService: DynamicAdjudicationService,
    private adjudicationType: AdjudicationType,
    private adjudicationFeature: AdjudicationFeatureService,
    private user: UserService,
    private relationshipService: RelationshipService
  ) {}

  ngOnInit() {
    this.isStarEnabled = this.adjudicationFeature.isStarActionEnabled();
  }

  dynamicAdjudicationEnabled() {
    return (
      this.adjudicationFeature.isBulkAdjudicationEnabled() &&
      !!(this.entity.getData() || {}).singleClassificationModel &&
      this.entity.isAdjudicatableByUser() &&
      !this.user.isReadOnlyUser() &&
      !this.entity.isInPreview()
    );
  }

  isConfirmActionEnabled() {
    return this.adjudicationFeature.isConfirmActionEnabled();
  }

  adjudicateActionEnabled() {
    return this.dynamicAdjudicationService.isDynamicAdjudicationActive() && this.dynamicAdjudicationService.getMode();
  }

  cancelActionEnabled() {
    return this.dynamicAdjudicationService.isDynamicAdjudicationActive();
  }

  toggleVisibility() {
    this.actionsVisible = !this.actionsVisible;
  }

  selectAdjudicationAction(action) {
    if (!this.dynamicAdjudicationService.getMode()) {
      if (this.adjudicationActions[action]) {
        (Object.keys(this.adjudicationActions) || []).forEach((key) => {
          this.adjudicationActions[key] = key === action;
        });
      }
      this.dynamicAdjudicationService.setMode(action);
    }
  }

  adjudicateSelected() {
    if (this.adjudicateActionEnabled()) {
      let structuredData,
        allAdjudicableItems = [];

      this.incidents.get().forEach(function (incident) {
        if (incident.selected) {
          allAdjudicableItems.push({
            type: 'INCIDENT',
            data: incident,
          });
        }
      });

      structuredData = this.structuredData.get();
      Object.keys(structuredData).forEach((key) => {
        structuredData[key].forEach((data) => {
          let adjudicationType;
          if (data.selected) {
            adjudicationType = this.adjudicationType.getAdjudicationTypeFor(
              data.filterFields.kind,
              structuredData[key]
            );
            allAdjudicableItems.push({
              type: adjudicationType || key,
              data: data,
            });
          }
        });
      });

      this.relationshipService.getLocations().forEach(setSelected(allAdjudicableItems));
      this.relationshipService.getPeople().forEach(setSelected(allAdjudicableItems));
      this.relationshipService.getCompanies().forEach(setSelected(allAdjudicableItems));

      this.dynamicAdjudicationService.setAdjudications(allAdjudicableItems);
      this.modalTrackingService.getTriggers().dynamicAdjudication.open = true;
    }
  }

  cancel() {
    this.clear();
    this.dynamicAdjudicationService.clear();
  }

  clear() {
    this.adjudicationActions = {
      CONFIRM: true,
      REMOVE: true,
      ESCALATE: true,
      COMMENT: true,
    };
  }

  ngDoCheck(): void {
    const currentMode = this.dynamicAdjudicationService.getMode();
    if (this.previousMode !== currentMode && !currentMode) {
      this.clear();
    }
    this.previousMode = currentMode;
  }

  ngOnDestroy(): void {
    this.clear();
    this.dynamicAdjudicationService.clear();
  }
}

function setSelected(allAdjudicableItems) {
  return (entity) => {
    if ((entity.relationship || {}).selected) {
      allAdjudicableItems.push({
        type: 'RELATIONSHIP',
        data: entity.relationship,
      });
    }
  };
}
