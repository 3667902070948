import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  Directive,
  ElementRef,
  EnvironmentInjector,
  HostListener,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import ExternalUrlMsgService from './ddiq-external-url-msg.service';
import { ExternalUrlMsgComponent } from './ddiq-external-url-msg.component';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: 'a[href]',
})
export class ExternalUrlMsgDirective implements OnChanges {
  @Input() href: string;
  private window: Window;
  componentRef: ComponentRef<ExternalUrlMsgComponent>;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private externalUrlMsgService: ExternalUrlMsgService,
    private injector: EnvironmentInjector,
    private appRef: ApplicationRef,
    private windowRef: WindowRefService
  ) {
    this.window = windowRef.nativeWindow;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.el.nativeElement.href = this.href;
  }

  @HostListener('click', ['$event'])
  onLinkClick(event: Event): void {
    const url = this.el.nativeElement.href;
    if (!url) {
      return;
    }

    if (this.externalUrlMsgService.canDisplayWarningMsg(url)) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.componentRef = this.createExternalUrlMsgComponent();
      const onClickedSub: Subscription = this.componentRef.instance.onClicked.subscribe(this.clickActionHandler(url));

      this.appRef.attachView(this.componentRef.hostView);

      this.componentRef.onDestroy(() => {
        this.appRef.detachView(this.componentRef.hostView);
        onClickedSub.unsubscribe();
      });
    }
  }

  private clickActionHandler(url: string): (clickAction: string) => void {
    return (clickAction) => {
      if (clickAction === 'proceed') {
        this.window.open(url, '_blank', 'noreferrer');
      }

      this.componentRef.destroy();
    };
  }

  private createExternalUrlMsgComponent(): ComponentRef<ExternalUrlMsgComponent> {
    const target: HTMLElement = this.document.getElementById('external-url-msg-target');
    const host: HTMLElement = this.document.createElement('ng-container');

    target.appendChild(host);

    return createComponent<ExternalUrlMsgComponent>(ExternalUrlMsgComponent, {
      environmentInjector: this.injector,
      hostElement: host,
    });
  }
}
