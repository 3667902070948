import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency',
})
export default class CurrencyOrNullPipe implements PipeTransform {
  constructor() {}

  /**
   * Converts input number to $ format
   * CurrencyMeasure displays the type of currency as opposed to CurrencyOrNull
   * @param input
   * @param zeroIsNull
   * @returns {String}
   */
  transform(input?: any, zeroIsNull?: any) {
    if (typeof input !== 'undefined') {
      input = Number(input);
      if (input === 0 && zeroIsNull) return '--';
      else if (input < 0)
        return (
          '($' +
          input
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            .split('.')[0] +
          ')'
        );
      else
        return (
          '$' +
          input
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            .split('.')[0]
        );
    }

    return '--';
  }
}
