import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable({
  providedIn: 'root',
})
class GovernmentAwards {
  public static readonly awardTypes = {
    recent: 'recent',
    expiring: 'expiring',
    expired: 'expired',
  };

  constructor(private apiFetchService: ApiFetchService) {}

  getAwards(awardType, companyId, filter = { start: 0, count: 10 }) {
    return this.apiFetchService.getAwards(companyId, Object.assign({ awardType }, filter));
  }
}

export default GovernmentAwards;
