import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class BuildValidationService {
  isSparseCompanySubmission(address, people) {
    let i,
      addressOk = false,
      peopleOk = false;
    if (address && address.streetAddress && address.city && address.country) {
      addressOk = true;
    }
    if (people && people.length > 0) {
      for (i = 0; i < people.length; i++) {
        if (people[i].name) {
          peopleOk = true;
        }
      }
    }
    return !(addressOk || peopleOk);
  }

  isSparsePersonSubmission(address, currentPosition, previousPositions) {
    let i,
      addressOk = false,
      positionOk = false;
    if (address && address.streetAddress && address.city && address.country) {
      addressOk = true;
    }
    if (currentPosition && currentPosition.companyName) {
      positionOk = true;
    }
    if (!positionOk && previousPositions && previousPositions.length > 0) {
      for (i = 0; i < previousPositions.length; i++) {
        if (previousPositions[i].companyName) {
          positionOk = true;
        }
      }
    }
    return !(addressOk || positionOk);
  }
}
