import { Component, Input } from '@angular/core';
import { Field } from './field.model';

@Component({
  selector: 'ddiq-code-schema',
  templateUrl: 'ddiq-code-schema.component.tpl.html',
})
export class CodeSchemaComponent {
  @Input() field: Field;
  constructor() {}
}
