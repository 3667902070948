// @ts-nocheck
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ErrorHandler from '../common/error-handler.service';
import ConfigService from './config.service';
import { PromotionConfiguration } from '../../../admin/js/modules/oiq/promotion/promotion.configuration.model';

@Injectable({
  providedIn: 'root',
})
export default class ApiFetchService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler, private Config: ConfigService) {
    this.settings = this.Config.settings;
  }

  /**
   * simply prefixes the url with a rest
   */
  private getViaRestApi(url, user, pass): Promise<any> {
    return this.getViaApi('rest/' + url, user, pass);
  }

  private getViaApi(url, user, pass): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    if (user && pass) {
      headers = headers.set('Authorization', 'Basic ' + window.btoa(user + ':' + pass));
    }

    var successCallback = (response) => {
      return response;
    };

    var errorCallback = (response) => {
      this.errorHandler.error('Fetch of ' + this.settings.project.apiUrl + url + ' failed');
      this.errorHandler.error(response);
      return new Promise((resolve, reject) => reject(response));
    };

    return this.http
      .get(this.settings.project.apiUrl + url, { headers })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  private getConfig(filename): Promise<any> {
    var urlPrefix;

    urlPrefix = 'config/';

    var configSuccess = (response) => {
      return response;
    };

    var configFail = (response) => {
      this.errorHandler.error('failed to fetch config file: ' + urlPrefix + filename);
      this.errorHandler.error(response);
    };

    return this.http
      .get(urlPrefix + filename)
      .toPromise()
      .then(configSuccess, configFail);
  }

  /**
   * See getViaRestApi notes
   * @param url
   * @param params
   * @returns promise containing put response
   */
  private putViaRestApi(url, params): Promise<any> {
    return this.put('rest/' + url, params);
  }

  // nothing is posted before document is ready, so no logic to check settings.project
  private put(url, params): Promise<any> {
    var successCallback = (response) => {
      return response;
    };

    var errorCallback = (response) => {
      this.errorHandler.log('Fetch of ' + url + ' failed');
      this.errorHandler.log(response);
      return new Promise((resolve, reject) => reject(response));
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    return this.http
      .put(this.settings.project.apiUrl + url, params, {
        headers,
      })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  /**
   * See getViaRestApi notes
   * @param url
   * @param params
   * @returns promise containing post response
   */
  private postViaRestApi(url, params): Promise<any> {
    return this.post('rest/' + url, params);
  }

  // nothing is posted before document is ready, so no logic to check settings.project
  private post(url, params): Promise<any> {
    var successCallback = (response) => {
      return response;
    };

    var errorCallback = (response) => {
      this.errorHandler.log('Fetch of ' + url + ' failed');
      this.errorHandler.log(response);
      return new Promise((resolve, reject) => reject(response));
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    return this.http
      .post(this.settings.project.apiUrl + url, params, {
        headers,
      })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  /**
   * @param url
   * @param params
   * @returns promise containing delete response
   */
  private deleteViaRestApi(url, params): Promise<any> {
    return this.delete('rest/' + url, params);
  }

  private delete(url, params): Promise<any> {
    const successCallback = (response) => {
      return response;
    };

    const errorCallback = (response) => {
      this.ErrorHandler.log('Fetch of ' + url + ' failed');
      this.ErrorHandler.log(response);
      return new Promise((resolve, reject) => reject(response));
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    return this.http
      .request('delete', this.settings.project.apiUrl + url, {
        headers,
        body: params,
      })
      .toPromise()
      .then(successCallback, errorCallback);
  }

  logout(): Promise<any> {
    return this.http.get(this.settings.project.apiUrl + 'logout', { responseType: 'text' }).toPromise();
  }
  config(filename): Promise<any> {
    return this.getConfig(filename);
  }
  properties(): Promise<any> {
    return this.getViaRestApi('oiqPrivate/configuration');
  }
  currentUser(): Promise<any> {
    return this.getViaRestApi('admin/currentUser');
  }
  user(user, pass): Promise<any> {
    return this.getViaRestApi('admin/user/' + user, user, pass);
  }
  users(showPCList?): Promise<any> {
    let url = 'admin/user';
    if (showPCList) {
      url += '?showPCList=' + showPCList;
    }
    return this.getViaRestApi(url);
  }
  searchUsers(params): Promise<any> {
    return this.getViaRestApi('admin/usersearch' + makeSearchGroupsOrUsersQuery(params));
  }
  usersInGroup(groupname): Promise<any> {
    return this.getViaRestApi('admin/group/' + groupname + '/users');
  }
  userUsage(user): Promise<any> {
    return this.getViaRestApi('admin/user/' + user.username + '/usage');
  }
  explainUserConfiguration(user): Promise<any> {
    return this.getViaRestApi('admin/user/' + user.username + '/configurationExplanation');
  }
  getUserConfigurationSpecifics(user): Promise<any> {
    return this.getViaRestApi('admin/user/' + user.username + '/configurationSpecifics');
  }
  createUser(user): Promise<any> {
    return this.postViaRestApi('admin/user/', user);
  }
  enableUser(user): Promise<any> {
    return this.putViaRestApi('admin/user/' + user.username + '/enable');
  }
  disableUser(username, reassignTo): Promise<any> {
    return this.putViaRestApi('admin/user/' + username + '/disable', { reassignTo: reassignTo });
  }
  unlockUser(user): Promise<any> {
    return this.putViaRestApi('admin/user/' + user.username + '/unlock');
  }
  updateUser(user): Promise<any> {
    return this.putViaRestApi('admin/user/' + user.username, user);
  }
  addUsersToGroup(groupname, usernames): Promise<any> {
    return this.postViaRestApi('admin/group/' + groupname + '/users', usernames);
  }
  deleteUsersFromGroup(groupname, usernames): Promise<any> {
    return this.deleteViaRestApi('admin/group/' + groupname + '/users', usernames);
  }
  countProfiles(user, monitoringEnabled): Promise<any> {
    if (monitoringEnabled) {
      return this.getViaRestApi('monitor/user/' + user.username + '/countProfiles');
    } else {
      return this.getViaRestApi('admin/user/' + user.username + '/countProfiles');
    }
  }
  resetPassword(user, password): Promise<any> {
    return this.putViaRestApi('admin/user/' + user.username + '/resetPassword', password);
  }
  updatePassword(user, password): Promise<any> {
    return this.putViaRestApi('admin/user/' + user.username + '/password', password);
  }
  federatedGroups(user): Promise<any> {
    return this.getViaRestApi('admin/user/' + user.username + '/federatedGroups');
  }
  updateFederatedGroups(user): Promise<any> {
    return this.putViaRestApi('admin/user/' + user.username + '/federatedGroups', user.federatedGroups);
  }
  roles(): Promise<any> {
    return this.getViaRestApi('admin/role/');
  }
  groups(showPCList?): Promise<any> {
    let url = 'admin/group';
    if (showPCList) {
      url += '?showPCList=' + showPCList;
    }
    return this.getViaRestApi(url);
  }
  searchGroups(params): Promise<any> {
    return this.getViaRestApi('admin/groupsearch' + makeSearchGroupsOrUsersQuery(params));
  }
  createGroup(group): Promise<any> {
    return this.postViaRestApi('admin/group/', group);
  }
  updateGroup(group): Promise<any> {
    return this.putViaRestApi('admin/group/', group);
  }
  explainGroupConfiguration(group): Promise<any> {
    return this.getViaRestApi('admin/group/' + group + '/configurationExplanation');
  }
  getGroupConfigurationSpecifics(group): Promise<any> {
    return this.getViaRestApi('admin/group/' + group + '/configurationSpecifics');
  }
  propertiesVerbose(): Promise<any> {
    return this.getViaRestApi('admin/configuration/verbose/');
  }
  propertyDetails(key): Promise<any> {
    return this.getViaRestApi('admin/configuration/view/' + key);
  }
  propertyFeatureList(): Promise<any> {
    return this.getViaRestApi('admin/configuration/featureList');
  }
  featureDetails(name): Promise<any> {
    return this.getViaRestApi('admin/configuration/feature/' + name);
  }
  updateInstanceProperties(properties): Promise<any> {
    return this.putViaRestApi('admin/configuration/', properties);
  }
  updateUserProperties(properties, user): Promise<any> {
    return this.putViaRestApi('admin/configuration/user/' + user, properties);
  }
  updateGroupProperties(properties, group): Promise<any> {
    return this.putViaRestApi('admin/configuration/group/' + group, properties);
  }
  getCrawlPlanDacAgents(category, type, enrichmentLevel): Promise<any> {
    var paramCategory = '?category=' + category;
    var paramEntityType = '&entityType=' + type;
    if (enrichmentLevel) {
      var paramEnrichmentLevel = '&enrichmentLevel=' + enrichmentLevel;
      return this.getViaRestApi('admin/agents' + paramCategory + paramEntityType + paramEnrichmentLevel);
    }
    return this.getViaRestApi('admin/agents' + paramCategory + paramEntityType);
  }
  getDataSourceGroups(): Promise<any> {
    return this.getViaRestApi('admin/crawlplan/dataSourceGroup');
  }
  getCrawlPlans(type): Promise<any> {
    return this.getViaRestApi('admin/crawlplan?type=' + type);
  }
  getCrawlPlan(crawlPlanKey, type): Promise<any> {
    return this.getViaRestApi('admin/crawlplan/' + encodeURIComponent(crawlPlanKey) + '?type=' + type);
  }
  getCrawlPlanTemplate(type): Promise<any> {
    return this.getViaRestApi('admin/crawlplan/template?type=' + type);
  }
  getMonitoringRegulatorySourcesCompany(): Promise<any> {
    return this.getViaRestApi('admin/crawlplan/monitoringRegulatorySourcesCompany');
  }
  getMonitoringRegulatorySourcesPerson(): Promise<any> {
    return this.getViaRestApi('admin/crawlplan/monitoringRegulatorySourcesPerson');
  }
  saveCrawlPlan(crawlplan, type): Promise<any> {
    return this.postViaRestApi('admin/crawlplan?type=' + type, crawlplan);
  }
  importCrawlPlan(crawlplan, type): Promise<any> {
    return this.postViaRestApi('admin/crawlplan/' + crawlplan.crawlPlanKey + '/import' + '?type=' + type, crawlplan);
  }
  getCrawlPlanKeys(): Promise<any> {
    return this.getViaRestApi('admin/crawlplankeys');
  }
  getMonitoringPlanKeys(): Promise<any> {
    return this.getViaRestApi('admin/monitoringcrawlplankeys');
  }
  inProgressProfiles(params): Promise<any> {
    return this.getViaRestApi('progress/profiles' + makeProgressQuery(params));
  }
  abortInProgressProfiles(params): Promise<any> {
    return this.putViaRestApi('progress/profiles/abort', params);
  }
  resubmitProfileInProgressFailed(params): Promise<any> {
    return this.postViaRestApi('oiqPrivate/resubmit-failed', params);
  }
  getUserActions(params, selectedUserActions, selectedUser): Promise<any> {
    return this.getViaRestApi('admin/userAction' + makeUserActionQuery(params, selectedUserActions, selectedUser));
  }
  getVisibleUserActions(): Promise<any> {
    return this.getViaRestApi('admin/userAction/visible');
  }
  generateUserActionsCsvUrl(params, selectedUserActions, selectedUser): string {
    return (
      this.settings.project.apiUrl +
      'rest/admin/userActionCsv' +
      makeUserActionQuery(params, selectedUserActions, selectedUser)
    );
  }
  generateUrl(path, type?): string {
    var result = this.settings.project.apiUrl + 'rest/' + path;
    if (type) {
      result += '?type=' + type;
    }
    return result;
  }
  getQuerySets(): Promise<any> {
    return this.getViaRestApi('admin/querySet');
  }
  getQuerySet(id): Promise<any> {
    return this.getViaRestApi('admin/querySet/' + id);
  }
  saveQuerySet(querySet): Promise<any> {
    return this.postViaRestApi('admin/querySet', querySet);
  }
  hideQuerySet(querySet): Promise<any> {
    return this.postViaRestApi('admin/querySet/' + querySet.id + '/hide' + '?hide=' + !querySet.hidden);
  }
  getQueryPlans(): Promise<any> {
    return this.getViaRestApi('admin/queryPlan');
  }
  getQueryPlan(id): Promise<any> {
    return this.getViaRestApi('admin/queryPlan/' + id);
  }
  getQueryPlanTemplate(): Promise<any> {
    return this.getViaRestApi('admin/queryPlan/template');
  }
  saveQueryPlan(queryPlan): Promise<any> {
    return this.postViaRestApi('admin/queryPlan', queryPlan);
  }
  getSubCrawlQueryPlans(): Promise<any> {
    return this.getViaRestApi('admin/subCrawlQueryPlans');
  }
  importQueryPlan(queryplan): Promise<any> {
    return this.postViaRestApi('admin/queryPlan/' + queryplan.id + '/import', queryplan);
  }
  getCountries(): Promise<any> {
    return this.getViaRestApi('oiqPrivate/configuration/countries');
  }
  getRegions(): Promise<any> {
    return this.getViaRestApi('oiqPrivate/configuration/regions');
  }
  getRelationshipTypes(): Promise<any> {
    return this.getViaRestApi('oiqPrivate/configuration/relationshipTypes');
  }
  getRiskConfigurations(): Promise<any> {
    return this.getViaRestApi('admin/riskConfiguration');
  }
  getRiskConfiguration(riskConfigurationKey): Promise<any> {
    return this.getViaRestApi('admin/riskConfiguration/' + riskConfigurationKey);
  }
  getRiskConfigurationEventMap(): Promise<any> {
    return this.getViaRestApi('admin/riskConfiguration/eventMap');
  }
  getRiskConfigurationTemplate(): Promise<any> {
    return this.getViaRestApi('admin/riskConfiguration/template');
  }
  saveRiskConfiguration(riskConfiguration): Promise<any> {
    return this.postViaRestApi('admin/riskConfiguration', riskConfiguration);
  }
  importRiskConfiguration(riskConfiguration): Promise<any> {
    return this.postViaRestApi(
      'admin/riskConfiguration/' + riskConfiguration.riskConfigurationKey + '/import',
      riskConfiguration
    );
  }
  getRiskConfigurationKeys(): Promise<any> {
    return this.getViaRestApi('admin/riskConfigurationKeys');
  }
  getPromotionConfigurations(): Promise<any> {
    return this.getViaRestApi('admin/promotionConfiguration');
  }
  getPromotionConfiguration(promotionConfigurationKey: string): Promise<any> {
    return this.getViaRestApi('admin/promotionConfiguration/' + promotionConfigurationKey);
  }
  getPromotionConfigurationKeys(): Promise<any> {
    return this.getViaRestApi('admin/promotionConfigurationKeys');
  }
  savePromotionConfiguration(promotionConfiguration: PromotionConfiguration): Promise<any> {
    return this.postViaRestApi('admin/promotionConfiguration', promotionConfiguration);
  }
  getIdTypes(): Promise<any> {
    return this.getViaRestApi('idTypes');
  }
  getEvents(): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/event/');
  }
  getEvent(eventKey): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/event/' + eventKey);
  }
  saveEvent(event): Promise<any> {
    return this.postViaRestApi('admin/eventManagement/event/', event);
  }
  getEventKeys(): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/event/keys');
  }
  getEventTemplate(): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/event/template');
  }
  importEvent(event): Promise<any> {
    return this.postViaRestApi('admin/eventManagement/event/import', event);
  }
  getEventSets(): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/set/');
  }
  getEventSet(eventKey): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/set/' + eventKey);
  }
  saveEventSet(event): Promise<any> {
    return this.postViaRestApi('admin/eventManagement/set/', event);
  }
  getEventSetKeys(): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/set/keys');
  }
  getEventSetTemplate(): Promise<any> {
    return this.getViaRestApi('admin/eventManagement/set/template');
  }
  importEventSet(event): Promise<any> {
    return this.postViaRestApi('admin/eventManagement/set/import', event);
  }
  getProfileCollections(params): Promise<any> {
    return this.getViaRestApi('profilecollection' + makeProfileCollectionSearchQuery(params));
  }
  profileCollectionLookup(searchParams): Promise<any> {
    return this.postViaRestApi('entitylookup/search', searchParams);
  }
  assignProfilesToProfileCollection(params): Promise<any> {
    return this.postViaRestApi('profilecollection/' + params.profileCollectionKey + '/profiles', params.payload);
  }
  unassignProfilesFromProfileCollection(params): Promise<any> {
    return this.deleteViaRestApi('profilecollection/' + params.profileCollectionKey + '/profiles', params.payload);
  }
  createProfileCollection(createParams): Promise<any> {
    return this.postViaRestApi('profilecollection', createParams);
  }
  updateProfileCollection(updateParams): Promise<any> {
    return this.putViaRestApi('profilecollection', updateParams);
  }
  getProfileCollectionPermissions(params): Promise<any> {
    return this.getViaRestApi(
      'profilecollection/' +
        params.profileCollectionKey +
        '/permissions' +
        makeProfileCollectionGetPermissionQuery(params)
    );
  }
  createPermissions(params): Promise<any> {
    return this.postViaRestApi('profilecollection/' + params.profileCollectionKey + '/access', params.payload);
  }
  deleteProfileCollectionPermissions(params): Promise<any> {
    return this.deleteViaRestApi('profilecollection/' + params.profileCollectionKey + '/access', params.payload);
  }
  updatePermissionDefault(params): Promise<any> {
    return this.postViaRestApi('profilecollection/' + params.profileCollectionKey + '/access/default', params.payload);
  }
}

function makeProgressQuery(params) {
  var query = '';
  if (params && params.count && params.start) {
    query += '?';
    query += 'count=' + params.count;
    query += '&start=' + params.start;
  } else {
    query = '?count=10&start=0';
  }
  if (params && params.queuedOnly) {
    query += '&queuedOnly=' + params.queuedOnly;
  }
  if (params && params.skipRefreshed) {
    query += '&skipRefreshed=' + params.skipRefreshed;
  }
  return query;
}

function makeProfileCollectionSearchQuery(params) {
  let query = '';
  if (params && params.count && Number.isInteger(params.start)) {
    query += '?';
    query += 'count=' + params.count;
    query += '&start=' + params.start;
  } else {
    query = '?count=10&start=0';
  }
  if (params && params.orderBy) {
    query += '&orderBy=' + params.orderBy;
  }
  if (params && params.direction) {
    query += '&direction=' + params.direction;
  }
  if (params && params.needAggregates) {
    query += '&needAggregates=' + params.needAggregates;
  }
  if (params && params.searchTerm) {
    query += '&searchTerm=' + params.searchTerm;
  }
  return query;
}

function makeProfileCollectionGetPermissionQuery(params) {
  let query = '';
  if (params && params.orderBy) {
    query += '?orderBy=' + params.orderBy;
  }
  if (params && params.direction) {
    if (query) {
      query += '&direction=' + params.direction;
    } else {
      query += '?direction=' + params.direction;
    }
  }
  return query;
}

function makeUserActionQuery(params, selectedUserActions, selectedUser) {
  var query = '?userActions=' + selectedUserActions.join(',');
  var start = 0;
  var count = 20;

  if (params) {
    if (params.start) {
      start = params.start;
    }
    if (params.count) {
      count = params.count;
    }
  }

  if (selectedUser) {
    query += '&username=' + selectedUser;
  }
  query += '&start=' + start;
  query += '&count=' + count;

  return query;
}

function makeSearchGroupsOrUsersQuery(params) {
  let query = '?';
  let start = 0;
  let count = 10;
  let searchTerm = '';

  if (params) {
    if (params.pagingParams && Number.isInteger(params.pagingParams.start)) {
      start = params.pagingParams.start;
    }
    if (params.pagingParams && params.pagingParams.count) {
      count = params.pagingParams.count;
    }
    if (params.searchTerm) {
      searchTerm = encodeURIComponent(params.searchTerm);
    }
  }
  query += 'start=' + start;
  query += '&count=' + count;
  query += '&searchTerm=' + searchTerm;

  return query;
}
