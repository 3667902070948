<section class="top-accordion" [ngClass]="{'down' : isOpen, 'disabled' : disable}">
  <div class="section-title" (click)="toggleElement()">
    <h2>{{accTitle}}</h2>
    <div class="count inline" [ngClass]="{ 'alert-push': (alerts > 0) }" *ngIf="count > 0">
      <div [ngClass]="{'filtered-out-count': filteredCount > -1 && filteredCount != count}" class="inline">
        <span class="filter-count" *ngIf="filteredCount > -1 && filteredCount != count">{{ filteredCount }} of</span>
        {{ count }}
      </div>
      <div
        class="escalated-count inline"
        [ngClass]="{'filtered-out-count': filteredEscalatedCount > -1 && filteredEscalatedCount != escalatedCount}"
        *ngIf="escalatedCount > 0">
        &nbsp;(<span
          class="filtered-escalated-count"
          *ngIf="filteredEscalatedCount > -1 && filteredEscalatedCount != escalatedCount"
          >{{ filteredEscalatedCount }} of </span
        >{{ escalatedCount }}<span class="fa fa-lg fa-star"></span>)
      </div>
    </div>
    <div class="accordian-alert" *ngIf="alerts > 0">
      <ddiq-alert-icon></ddiq-alert-icon>
    </div>
    <button type="button" class="up-down-white no-print"></button>
    <div class="clr"></div>
  </div>
  <div class="section-content" [hidden]="!isOpen">
    <ng-content></ng-content>
  </div>
</section>
