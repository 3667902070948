import { Injectable } from '@angular/core';
import EventGroupBuilderService from './event-group-builder.service';

function isAllFilteredOut(eventGroups) {
  return (
    !!eventGroups.length && eventGroups.filter((eventGroup) => eventGroup.isAllFiltered).length === eventGroups.length
  );
}
@Injectable({
  providedIn: 'root',
})
export default class EventGroupsBuilderService {
  constructor(private eventGroupBuilderService: EventGroupBuilderService) {}

  from(eventGroupsModel) {
    return {
      build: () => {
        const copy = Object.assign({}, eventGroupsModel);

        copy.list.forEach((eventGroup, index) => {
          copy.list[index] = this.eventGroupBuilderService.newBuilder().from(eventGroup).build();
        });

        copy.showAllFilteredMessage = isAllFilteredOut(copy.list);
        return copy;
      },
    };
  }

  newEventGroupsModel(eventGroups) {
    let eventGroupsModel = {
        list: [...eventGroups],
        showAllFilteredMessage: false,
        isAllEventGroupsOpen: false,
      },
      eventGroupsSort = (...args) => {
        /* noop */
      };

    return {
      withSort(sort) {
        eventGroupsSort = sort;
        return this;
      },
      build: () => {
        eventGroupsSort(eventGroupsModel.list);
        eventGroupsModel.showAllFilteredMessage = isAllFilteredOut(eventGroupsModel.list);
        return eventGroupsModel;
      },
    };
  }
}
