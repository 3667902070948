import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class StructuredDataByClassExtractionStrategyService {
  constructor() {}

  extract(entity, dataName, onDataExtracted: Function, onDone: Function) {
    let structuredData = [];

    Object.keys(entity[dataName] || []).forEach((classification) => {
      if (Array.isArray(entity[dataName][classification])) {
        entity[dataName][classification].forEach((extracted) => {
          extracted.classificationLabelKey = classification;
          structuredData.push(extracted);
          onDataExtracted(extracted);
        });
      }
    });

    onDone(structuredData);
  }
}
