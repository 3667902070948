import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-select-dup',
  templateUrl: './ddiq-select-dup.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class SelectDupComponent implements OnInit {
  @Input() key: string;
  @Input() model: any;
  @Input() label: string;
  @Input() options: Array<any>;
  @Input() prompt: string;
  @Input() required: boolean;
  @Input() name: string;
  @Input() size: string;

  @Output() modelChange = new EventEmitter<Array<any>>();

  isRequired: boolean;

  constructor() {}

  ngOnInit() {
    this.isRequired = typeof this.required !== 'undefined';
    this.ensureModelExists();
  }

  createOrDeleteVal(i) {
    if (i === 0) {
      if (!this.key) {
        this.model.push('');
      } else {
        this.model[this.model.length] = {};
      }
    } else {
      this.removeFrom(this.model, i);
    }
  }

  onSelectChange(selections: Array<any>, index: number) {
    if (this.isPromptSelected(selections, index)) {
      this.removeFrom(selections, index);
      this.ensureModelExists();
    }
    this.modelChange.emit(selections);
  }

  private removeFrom(selections: Array<any>, index: number) {
    selections.splice(index, 1);
  }

  private isPromptSelected(value: Array<any>, index: number) {
    if (this.key) {
      return value[index][this.key] === undefined;
    }
    return value[index] === undefined;
  }

  private ensureModelExists() {
    if (typeof this.model === 'undefined') {
      this.model = [];
    }

    if (this.model.length === 0) {
      this.model[0] = {};
    }
  }
}
