import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import AdminResolverService from './admin-resolver.service';
import DdiqAdminComponent from './ddiq-admin.component';

@NgModule({
  imports: [CommonModule, FormsModule, ThemedEntityModule],
  providers: [AdminResolverService],
  declarations: [DdiqAdminComponent],
  exports: [DdiqAdminComponent],
})
export default class AdminModule {}
