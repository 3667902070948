import { Pipe, PipeTransform } from '@angular/core';
import AdditionalLocationSubmissionPipe from './additional-location-submission.pipe';
import DoingBusinessInLocationSubmissionPipe from './doing-business-in-location-submission.pipe';
import DoingBusinessInPipe from './doing-business-in.pipe';
import IncorporatedInLocationSubmissionPipe from './incorporated-in-location-submission.pipe';
import CitizenOfPipe from './citizen-of.pipe';

@Pipe({
  name: 'locationLabel',
})
export default class LocationLabelPipe implements PipeTransform {
  constructor(
    private additionalLocationSubmission: AdditionalLocationSubmissionPipe,
    private citizenOf: CitizenOfPipe,
    private doingBusinessInLocationSubmission: DoingBusinessInLocationSubmissionPipe,
    private doingBusinessIn: DoingBusinessInPipe,
    private incorporatedInSubmission: IncorporatedInLocationSubmissionPipe
  ) {}
  transform(location: any) {
    const locations = [location];

    const additionalLocation = 'Additional Location';

    if (this.additionalLocationSubmission.transform(locations).length) {
      return additionalLocation;
    }

    if (
      this.doingBusinessInLocationSubmission.transform(locations).length ||
      this.doingBusinessIn.transform(locations).length
    ) {
      return 'Doing Business In';
    }

    if (this.incorporatedInSubmission.transform(locations).length) {
      return 'Incorporated In';
    }

    if (this.citizenOf.transform(locations).length) {
      return 'Citizen Of';
    }

    return additionalLocation;
  }
}
