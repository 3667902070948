import { Injectable } from '@angular/core';
import CorporateGraph from './corporate-graph.service';

let graphService;

@Injectable()
export default class CorporateGraphFilter {
  constructor(private CorporateGraph: CorporateGraph) {
    graphService = CorporateGraph;
  }

  filterGraph(graphData, filterValue) {
    const { nodes, links, cloned } = graphData;
    filter(cloned.nodes, nodes, filterValue);
    filter(cloned.links, links, filterValue);

    return graphData;
  }
}

function filter(masterList, filteredList, filterValue) {
  masterList.forEach((item) => {
    if (!item.filtered && canFilter(item, filterValue)) {
      let indexOfItemToRemove = -1;

      for (let i = 0; i < filteredList.length; i++) {
        if (filteredList[i].id === item.id) {
          indexOfItemToRemove = i;
          break;
        }
      }

      if (indexOfItemToRemove !== -1) {
        filteredList.splice(indexOfItemToRemove, 1);
        item.filtered = true;
      }
    } else if (item.filtered && !canFilter(item, filterValue)) {
      filteredList.push(item);
      item.filtered = false;
    }
  });
}

function canFilter(item, filter) {
  let canFilter = true;

  if (graphService.isRootNode(item)) {
    canFilter = false;
  } else if (isShowAll(filter) || (graphService.isOwnerOfRootNode(item) && isSelectedOwnershipType(item, filter))) {
    canFilter = isLessThanSlider(item, filter);
  }

  return canFilter;
}

function isLessThanSlider(item, filter) {
  const percent = graphService.isLink(item) ? item.percent : nodePercent(item, filter);
  return percent < filter.sliderValue;
}

function nodePercent(node, filter) {
  if (!isShowAll(filter)) {
    for (const l of node.linksToRoot) {
      if (l.type === filter.ownershipType) {
        return l.percent;
      }
    }
  }

  return node.maxPercent;
}

function isShowAll(filter) {
  return filter.ownershipType === 'ALL' || filter.ownershipType === undefined;
}

function isSelectedOwnershipType(item, filter) {
  if (graphService.isLink(item)) {
    return item.relationshipType === filter.ownershipType;
  }

  return (item.linksToRoot || []).some((l) => l.type === filter.ownershipType);
}
