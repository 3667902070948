import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import AlertService from '../common-services/alert.service';
import OiqProperties from '../common-services/oiq-properties.service';
import SectionConfigService from '../common-services/section-config.service';

@Injectable()
export default class EntityResolverHelperService {
  constructor(
    private errorHandler: ErrorHandler,
    private sectionConfigService: SectionConfigService,
    private oiqProperties: OiqProperties,
    private alertService: AlertService
  ) {}

  isInt(id) {
    return isFinite(id) && !isNaN(parseInt(id));
  }

  preLoadEntity(entityType) {
    let promises = [];

    // insures the config options and the properties file are loaded before opening an entity profile
    promises.push(this.sectionConfigService.fetchConfig(entityType));
    promises.push(this.oiqProperties.apiPropertiesPromise);
    promises.push(this.alertService.fetchConfig());

    return Promise.all(promises);
  }

  validateDiffRoute(comparableId) {
    return new Promise((resolve, reject) => {
      if (!this.isInt(comparableId)) {
        reject('Invalid EntityId for diff comparison: ' + comparableId);
      } else {
        resolve({});
      }
    });
  }

  validateEntityRoute(overviewId, entityId, entityType) {
    let validType;

    validType =
      entityType === 'company' || entityType === 'location' || entityType === 'person' || entityType === 'property';

    return new Promise<void>((resolve, reject) => {
      if (!this.isInt(overviewId)) {
        this.errorHandler.error('Invalid Overview Id in the URL: ' + overviewId);
        reject();
      } else if (!validType) {
        this.errorHandler.error('Invalid Entity Type in the URL: ' + entityType);
        reject();
      } else if (!this.isInt(entityId)) {
        this.errorHandler.error('Invalid Entity Id in the URL: ' + entityId);
        reject();
      } else {
        resolve();
      }
    });
  }
}
