import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class PartialUtils {
  isAddressFilled(address) {
    if (!address) {
      return false;
    }

    return address.streetAddress || address.city || address.zipPostalCode || address.stateProvince || address.country;
  }

  isArrayContainsString(array, target) {
    let i = 0;

    if (!Array.isArray(array)) {
      return false;
    }

    for (i; i < array.length; i++) {
      if (array[i] === target) {
        return true;
      }
    }

    return false;
  }

  isDefined(potentialField) {
    return typeof potentialField !== 'undefined';
  }

  isNumber(possibleNumber) {
    return !isNaN(parseFloat(possibleNumber)) && isFinite(possibleNumber);
  }

  isUserSubmitted(seedSources) {
    return this.isArrayContainsString(seedSources, 'SUBMISSION');
  }

  floatValueToTop(currentValue, possibleValueArray) {
    if (currentValue) {
      const options = possibleValueArray.slice();
      const index = possibleValueArray.indexOf(currentValue);
      options.splice(index, 1);
      return options;
    } else {
      return possibleValueArray;
    }
  }

  parseDomainFromUrl(url) {
    const anchor = document.createElement('a');
    anchor.href = url;
    return anchor.hostname;
  }

  hasNonEmptyValue(list, key) {
    let i, value;
    if (list && list.length > 0) {
      for (i = 0; i < list.length; i++) {
        value = this.getValueFromPath(list[i], key);
        if (typeof value !== 'undefined' && value !== '') {
          return true;
        }
      }
    }
    return false;
  }

  getValueFromPath(object, key) {
    if (typeof key !== 'undefined' && typeof object !== 'undefined') {
      if (key.indexOf('.') === -1) {
        return object[key];
      } else {
        return this.getValueFromPath(
          object[key.substring(0, key.indexOf('.'))],
          key.substring(key.indexOf('.') + 1, key.length)
        );
      }
    }
    return undefined;
  }
}
