export default function renameObjProp(obj, oldName, newName) {
  if (oldName === newName || !obj) {
    return obj;
  }

  if (obj.hasOwnProperty(oldName)) {
    obj[newName] = obj[oldName];
    delete obj[oldName];
  }

  return obj;
}
