import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-address',
  templateUrl: 'ddiq-address.component.tpl.html',
})
export default class AddressComponent {
  @Input() section;

  constructor() {}
}
