import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDarkWeb',
})
class DarkWebPipe implements PipeTransform {
  //matches case-insensitive http and https at the start of the input string.
  startsWithProtocol: RegExp = /^https?:\/\//i;

  transform(url: string = '', classification: string): boolean {
    return classification === 'DARK_WEB_MENTION' && this.isDarkWebUrl(url);
  }

  private isDarkWebUrl(url: string): boolean {
    return !url.match(this.startsWithProtocol)?.length;
  }
}

export default DarkWebPipe;
