import { Component, OnInit, Input } from '@angular/core';
import MaxValuePipe from '../ddiq-filters/max-value.pipe';
import SortableDatePipe from '../ddiq-filters/sortable-date.pipe';

@Component({
  selector: 'ddiq-regulatory-profile',
  templateUrl: './ddiq-regulatory-profile.component.tpl.html',
})
export default class RegulatoryProfileComponent implements OnInit {
  @Input() profile;
  @Input() displayCreatedDate: boolean;
  @Input() isAdjudicateable: boolean;
  @Input() entityType: string;
  @Input() entityId: string;
  @Input() noReassess: boolean;
  @Input() isDisplayClassifications: boolean;

  displayDate: string;
  hasRelatedCompanyData: boolean = false;
  hasAdditionalData: boolean = false;

  constructor(private maxValue: MaxValuePipe, private sortableDate: SortableDatePipe) {}

  ngOnInit() {
    this.displayDate = this.displayCreatedDate
      ? this.profile.oiqCreatedDate
      : this.maxValue.transform(this.profile.events, 'occurred', this.sortableDate);

    this.setHasRelatedCompanyData(this.profile.relatedCompanies, 'name');
    this.setHasAdditionalData();
  }

  private setHasRelatedCompanyData(relatedCompanies, fieldName) {
    if (relatedCompanies && fieldName) {
      this.hasRelatedCompanyData = relatedCompanies.some((company) => {
        return company[fieldName];
      });
    }
  }

  private setHasAdditionalData() {
    const companyClassification = this.profile.companyClassification || {};
    const companyClassificationsByCodeSchema = this.profile.companyClassificationsByCodeSchema || {};

    this.hasAdditionalData =
      this.profile.birthdate ||
      companyClassification.code ||
      companyClassification.description ||
      companyClassification.codeSchema ||
      Object.keys(companyClassificationsByCodeSchema).length ||
      (this.profile.alternativeNames || []).length ||
      (this.profile.relatedCompanies || []).length ||
      (this.profile.relatedPeople || []).length ||
      (this.profile.oversightDetails || []).length ||
      (this.profile.additionalData || []).length;
  }
}
