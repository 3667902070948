import { Injectable } from '@angular/core';
import _ from 'underscore';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import ApiFetchService from './api-fetch.service';
import OiqProperties from './oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class I18nService {
  countryList = [];
  languages = [];
  // generally, we want to set this as a prereq for a controller load
  // this allows us to operate on countryList/Names synchronously
  // see BuildCompanyCtrlResolver for an example

  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private translatePipe: TranslatefPipe
  ) {}

  // note, this returns a promise
  fetchCountries() {
    return new Promise((resolve) => {
      if (!this.countryList.length) {
        this.fetch().then((countries) => {
          Array.prototype.push.apply(this.countryList, countries);
          resolve(this.countryNames());
        });
      } else {
        resolve(this.countryNames());
      }
    });
  }

  fetchLanguages() {
    return new Promise((resolve) => {
      if (!this.languages.length) {
        this.apiFetchService.languagesData().then((scripts) => {
          Array.prototype.push.apply(this.languages, this.sortAlphaAsc(scripts));
          resolve(this.languages);
        });
      } else {
        resolve(this.languages);
      }
    });
  }

  getCountryNames() {
    return this.countryNames();
  }

  // takes in either a IsoCode or Name and returns Name or null
  matchCountryName(possible) {
    let name;

    this.countryList.some(function (country) {
      if (country.name === possible || country.id === possible || country.iso3code === possible) {
        name = country.name;

        return true;
      }
    });

    return name || null;
  }

  private fetch() {
    return this.apiFetchService.countryData().then((allCountries) => {
      return new Promise((resolve) => {
        const countries = _.map(allCountries, (countryData) => {
          return {
            id: countryData.isoCode,
            iso3code: countryData.iso3Code,
            name: countryData.names.en[0],
          };
        });

        let i,
          defaultCountry = this.oiqProperties.defaultCountry,
          preferredCountries = {};

        preferredCountries[defaultCountry] = 1;

        for (i = 0; i < this.oiqProperties.preferredCountries.length; i++) {
          const preferredCountry = this.oiqProperties.preferredCountries[i];
          preferredCountries[preferredCountry] = i + 2;
        }

        countries.sort(function (l, r) {
          const lpreferredPriority = preferredCountries[l.name],
            rpreferredPriority = preferredCountries[r.name];
          if (lpreferredPriority) {
            if (rpreferredPriority) {
              return lpreferredPriority - rpreferredPriority;
            }
            return -1;
          } else if (rpreferredPriority) {
            return 1;
          } else if (l.name < r.name) {
            return -1;
          } else if (l.name > r.name) {
            return 1;
          } else {
            return 0;
          }
        });

        resolve(countries);
      });
    });
  }

  private sortAlphaAsc(scripts) {
    var mapped,
      sorted,
      first = { script: this.oiqProperties.languageSelectionDefault };

    mapped = scripts
      .filter(function (script) {
        return script !== first.script;
      })
      .map((script) => {
        return { script: script, lang: this.translatePipe.transform(script) };
      });

    sorted = mapped.sort(function (l, r) {
      return ('' + l.lang).localeCompare(r.lang);
    });
    sorted.unshift(first);

    return sorted.map(function (language) {
      return language.script;
    });
  }

  private countryNames() {
    return _.pluck(this.countryList, 'name');
  }
}
