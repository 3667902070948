<article
  class="oiq-website-scorecard"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': scorecard.escalated, confirmed: scorecard.confirmed}"
  data-oiq-collapsible-ctrl
  [expandAll]="scorecard.isOpen">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="scorecard"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-website-scorecard-summary oiq-grid-cell">
          <div
            class="oiq-website-scorecard-classifier"
            [ngClass]="{disabled: scorecard.disabled}"
            *ngIf="isDisplayClassifications">
            <strong>Cybersecurity Scores</strong>
          </div>

          <ddiq-association [level]="scorecard.associationBucket" class="oiq-website-scorecard-association">
          </ddiq-association>

          <div class="text-center">
            <span
              *ngIf="displayCreatedDate"
              class="oiq-website-scorecard-display-date"
              [ngClass]="{disabled: scorecard.disabled}">
              <span class="sr-only">Created Date</span>
              <time class="oiq-website-scorecard-display-date-time" [attr.datetime]="scorecard.oiqCreatedDate"
                >{{scorecard.oiqCreatedDate | dateFormat }}</time
              >
            </span>

            <ddiq-adjudication-actions
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [type]="'WEBSITE_SCORECARD'"
              [entityType]="entityType"
              [entityId]="entityId"
              [targetId]="scorecard.id"
              [model]="scorecard">
            </ddiq-adjudication-actions>
          </div>
        </div>

        <div class="oiq-website-scorecard-bd oiq-grid-cell">
          <article class="oiq-website-scorecard-grid" [ngClass]="{disabled: scorecard.disabled}">
            <div class="oiq-website-scorecard-row" *ngIf="scorecard.companyName" role="row">
              <div class="oiq-website-scorecard-hd" role="columnheader">Name:</div>
              <div class="oiq-website-scorecard-cell" role="gridcell" [textContent]="scorecard.companyName"></div>
            </div>

            <div class="oiq-website-scorecard-row" role="row">
              <ng-container *ngFor="let seedSource of scorecard.seedSources">
                <div class="oiq-website-scorecard-hd" role="columnheader" *ngIf="seedSource.source">Source:</div>
                <div id="source" class="oiq-website-scorecard-cell" role="gridcell">
                  <a
                    *ngIf="seedSource.sourceUrl"
                    href="{{seedSource.sourceUrl | protectedArticleHref}}"
                    target="_blank"
                    class="inline"
                    rel="noopener noreferrer"
                    [textContent]="seedSource.source | translatef">
                  </a>

                  <span *ngIf="!seedSource.sourceUrl" [textContent]="seedSource.source | translatef"> </span>
                </div>
              </ng-container>
            </div>

            <div class="oiq-website-scorecard-row" *ngIf="scorecard.domain" role="row">
              <div class="oiq-website-scorecard-hd" role="columnheader">Domain:</div>
              <div class="oiq-website-scorecard-cell" role="gridcell" [textContent]="scorecard.domain"></div>
            </div>

            <div class="oiq-website-scorecard-row" *ngIf="scorecard.overallGrade" role="row">
              <div class="oiq-website-scorecard-hd" role="columnheader">Overall Grade:</div>
              <div
                class="oiq-website-scorecard-cell"
                role="gridcell"
                [textContent]="scorecard.overallGrade + ' (' + scorecard.overallScore + ')'"></div>
            </div>

            <div class="oiq-website-scorecard-row" *ngIf="scorecard.industry" role="row">
              <div class="oiq-website-scorecard-hd" role="columnheader">Industry:</div>
              <div class="oiq-website-scorecard-cell" role="gridcell" [textContent]="scorecard.industry"></div>
            </div>
          </article>

          <ddiq-collapsible>
            <ddiq-code-schema
              class="oiq-website-scorecard-grid"
              [ngClass]="{disabled: scorecard.disabled}"
              [field]="scorecard">
            </ddiq-code-schema>

            <article class="oiq-website-scorecard-grid" [ngClass]="{disabled: scorecard.disabled}">
              <div class="oiq-website-scorecard-row" *ngIf="scorecard.factors && scorecard.factors.length" role="row">
                <div class="oiq-website-scorecard-hd" role="columnheader">Factors:</div>
                <div class="oiq-website-scorecard-cell" role="gridcell">
                  <ng-container *ngFor="let factor of scorecard.factors">
                    <div class="oiq-website-scorecard-row-group">
                      <div class="oiq-website-scorecard-row" role="row">
                        <div id="factors" class="oiq-website-scorecard-hd" role="columnheader">
                          {{factor.factorName}}:
                        </div>
                        <div class="oiq-website-scorecard-cell" role="gridcell">
                          <span class="inline"> {{factor.factorGrade + ' (' + factor.factorScore + ')'}} </span>
                          <ng-container *ngFor="let map of factor.severityToIssuesMap | keyvalue">
                            <div
                              class="oiq-website-scorecard-row"
                              *ngIf="map.value && map.value.length && map.key == 'high'"
                              role="row">
                              <div id="high-issues" class="oiq-website-scorecard-hd" role="columnheader">
                                HIGH ({{factor.severityToTotalNumOfIssuesMap.get(map.key) + ' issues'}}):
                              </div>

                              <div class="oiq-website-scorecard-cell" role="gridcell">
                                <ddiq-limited-list [list]="map.value" [limit]="2" [isOpen]="false">
                                  <ng-template #parentTemplate let-limitedList>
                                    <ng-container *ngFor="let issueSummary of limitedList">
                                      <div id="high-issue-type" class="oiq-website-scorecard-row-group">
                                        <div
                                          class="oiq-website-scorecard-cell"
                                          role="gridcell"
                                          [textContent]="issueSummary.issueType + ' (' + issueSummary.issueCount + ' issues)'"></div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ddiq-limited-list>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let map of factor.severityToIssuesMap | keyvalue">
                            <div
                              class="oiq-website-scorecard-row"
                              *ngIf="map.value && map.value.length && map.key == 'medium'"
                              role="row">
                              <div id="medium-issues" class="oiq-website-scorecard-hd" role="columnheader">
                                MEDIUM ({{factor.severityToTotalNumOfIssuesMap.get(map.key) + ' issues'}}):
                              </div>

                              <div class="oiq-website-scorecard-cell" role="gridcell">
                                <ddiq-limited-list [list]="map.value" [limit]="2" [isOpen]="false">
                                  <ng-template #parentTemplate let-limitedList>
                                    <ng-container *ngFor="let issueSummary of limitedList">
                                      <div class="oiq-website-scorecard-row-group">
                                        <div
                                          id="medium-issue-type"
                                          class="oiq-website-scorecard-cell"
                                          role="gridcell"
                                          [textContent]="issueSummary.issueType + ' (' + issueSummary.issueCount + ' issues)'"></div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ddiq-limited-list>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let map of factor.severityToIssuesMap | keyvalue">
                            <div
                              class="oiq-website-scorecard-row"
                              *ngIf="map.value && map.value.length && map.key == 'low'"
                              role="row">
                              <div id="low-issues" class="oiq-website-scorecard-hd" role="columnheader">
                                LOW ({{factor.severityToTotalNumOfIssuesMap.get(map.key) + ' issues'}}):
                              </div>

                              <div class="oiq-website-scorecard-cell" role="gridcell">
                                <ddiq-limited-list [list]="map.value" [limit]="2" [isOpen]="false">
                                  <ng-template #parentTemplate let-limitedList>
                                    <ng-container *ngFor="let issueSummary of limitedList">
                                      <div class="oiq-website-scorecard-row-group">
                                        <div
                                          id="low-issue-type"
                                          class="oiq-website-scorecard-cell"
                                          role="gridcell"
                                          [textContent]="issueSummary.issueType + ' (' + issueSummary.issueCount + ' issues)'"></div>
                                      </div>
                                    </ng-container>
                                  </ng-template>
                                </ddiq-limited-list>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </article>
          </ddiq-collapsible>

          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'WEBSITE_SCORECARD'"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [targetId]="scorecard.id"
            [model]="scorecard"
            [label]="'Notes'"
            [active]="scorecard.comment">
          </ddiq-adjudication-notes>
        </div>
        <div
          class="oiq-website-scorecard-toggle oiq-grid-cell"
          [ngClass]="{disabled: scorecard.disabled}"
          *ngIf="hasFactors()">
          <a class="oiq-website-scorecard-show-all-data-toggle" data-oiq-collapsible-toggle>
            <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
            Show All Data
          </a>
        </div>
      </div>
    </div>
  </div>
</article>
