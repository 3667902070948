import { Component, Inject, Input, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import CheckContentService from '../common-services/check-content.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import SectionConfigService from '../common-services/section-config.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';

@Component({
  selector: 'ddiq-noteworthy-event',
  templateUrl: 'ddiq-noteworthy-event.component.tpl.html',
})
export default class DdiqNoteworthyEventComponent implements OnInit {
  @Input() section;
  @Input() isExpandAllGroups;
  entityType;
  oshaInRegulatorySection;
  previewingEntity;
  contentCounter;

  constructor(
    private incidentsUtils: IncidentsUtils,
    private structuredDataUtils: StructuredDataUtils,
    private checkContentService: CheckContentService,
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private sectionConfigService: SectionConfigService,
    private messageBusService: MessageBusService
  ) {}

  ngOnInit(): void {
    this.entityType = this.entity.getType();
    this.oshaInRegulatorySection = this.oiqProperties.oshaInRegulatorySection;
    this.previewingEntity = this.entity.isInPreview();
    this.contentCounter = {
      _incidentsCount: this.incidentsUtils.observedCount('noteworthy'),
      _safety: this.structuredDataUtils.observedCount('noteworthy'),

      filtered: function () {
        return this._incidentsCount.filtered + this._safety.filtered;
      },
      unfiltered: function () {
        return this._incidentsCount.unfiltered + this._safety.unfiltered;
      },
      filteredEscalated: function () {
        return this._incidentsCount.filteredEscalated + this._safety.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._incidentsCount.unfilteredEscalated + this._safety.unfilteredEscalated;
      },
    };
  }

  // check to see if noteworthy has any content -- used to disable expand/collapse
  hasNoteworthyContent() {
    if (this.oshaInRegulatorySection && this.section.data.hasOwnProperty('oshaSafetyInspections')) {
      return (
        this.checkContentService.checkForContent(this.section.data.safety, true) ||
        this.checkContentService.checkForContent(this.section.data.content, true)
      );
    }
    return this.checkContentService.checkForContent(this.section.data, true);
  }

  hasSafetyContent() {
    return (
      this.checkContentService.checkForContent(this.section.data.safety, true) ||
      this.checkContentService.checkForContent(this.section.data.oshaSafetyInspections, true)
    );
  }

  getSubSections(sectionId) {
    return this.sectionConfigService.getSubSections(this.entityType, sectionId);
  }

  sortDiscoveries(eventGroupKind, sortName, eventGroup) {
    this.messageBusService.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind,
        sortName,
        eventGroup,
      },
    });
  }
}
