<div *ngIf="key">
  <div class="input {{size}}">
    <label [ngClass]="{required: isRequired}">{{label}}</label>
    <div class="field" *ngFor="let item of model; let $index=index; let first = first; let last = last">
      <select
        name="{{name}}-{{$index}}"
        [(ngModel)]="item[key]"
        [required]="isRequired"
        (ngModelChange)="onSelectChange(model, $index)">
        <option [ngValue]="undefined">{{prompt}}</option>
        <option *ngFor="let opt of options" [ngValue]="opt">{{opt}}</option>
      </select>
      <button type="button" [ngClass]="{add : first, del: !first}" (click)="createOrDeleteVal($index)"></button>
    </div>
  </div>
</div>
<div *ngIf="!key">
  <div class="input {{size}}">
    <label [ngClass]="{required: isRequired}">{{label}}</label>
    <div class="field" *ngFor="let item of model | keyvalue; let $index=index; let first = first; let last = last">
      <select
        name="{{name}}-{{$index}}"
        [(ngModel)]="model[item.key]"
        [required]="isRequired"
        (ngModelChange)="onSelectChange(model, $index)">
        <option *ngIf="prompt" [ngValue]="undefined">{{prompt}}</option>
        <option *ngFor="let opt of options" [ngValue]="opt">{{opt | translatef}}</option>
      </select>
      <button type="button" [ngClass]="{add : first, del: !first}" (click)="createOrDeleteVal($index)"></button>
    </div>
  </div>
</div>
