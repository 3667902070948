import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { EmbedModule } from '../embed/embed.module';
import { CorporateRecordEventGroupsService } from './corporate-record-event-groups.service';
import DiffToolModalComponent from './ddiq-diff-tool-modal.component';
import { HomeSidebarComponent } from './ddiq-home-sidebar.component';
import HomeComponent from './ddiq-home.component';
import MainNavComponent from './ddiq-main-nav.component';
import MainComponent from './ddiq-main.component';
import PaginationComponent from '../../../../../shared/components/pagination/pagination.component';
import EventGroupBuilderService from './event-group-builder.service';
import EventGroupsBuilderService from './event-groups-builder.service';
import EventSeverityService from './event-severity.service';
import HomeResolverService from './ddiq-home-resolver.service';
import InmateProfileEventGroupsService from './inmate-profile-event-groups.service';
import LegalFilingEventGroupsService from './legal-filing-event-groups.service';
import { OshaInspectionEventGroupsService } from './osha-inspection-event-groups.service';
import WebsiteScorecardEventGroupsService from './website-scorecard-event-groups.service';
import ProfessionalLicenseEventGroupsService from './professional-license-event-groups.service';
import { ProfileService } from './profile.service';
import RegulatoryProfileEventGroupsService from './regulatory-profile-event-groups.service';
import { WatchListEventGroupsService } from './watch-list-event-groups.service';
import { ProductRecallEventGroupsService } from './product-recall-event-groups.service';
import LogoutLinkComponent from './ddiq-logout-link.component';
import SupportContactInfoModule from '../support-contact-info/support-contact-info.module';
import { SystemUseNoticeModule } from '../system-use-notice/system-use-notice.module';
import { SessionTerminationMessageModule } from '../session-termination-message/session-termination-message.module';
import { UpgradeVersionModule } from '../upgrade/upgrade.module';
import LoginComponent from './ddiq-login.component';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import { OiqThemeModule } from '../theme/oiq-theme.module';
import { OiqPasswordModule } from '../password/oiq-password.module';
import ChangePasswordWithTokenComponent from './ddiq-change-password-with-token.component';
import { NgArrayPipesModule, OrderByPipe } from 'ngx-pipes';
import InProgressProfileComponent from './ddiq-in-progress-profile.component';
import OiqNavModule from '../nav/oiq-nav.module';
import { WebContentEventGroupsService } from './web-content-event-groups.service';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import NotFoundComponent from './ddiq-not-found.component';
import { RouterModule } from '@angular/router';
import ErrorComponent from './ddiq-error.component';
import SsoErrorComponent from './ddiq-sso-error.component';
import { FormsModule } from '@angular/forms';

const paginationConfig = {
  boundaryLinks: true,
  directionLinks: true,
  first: { text: '<<', css: 'first' },
  previous: { text: '<', css: 'previous' },
  next: { text: '>', css: 'next' },
  last: { text: '>>', css: 'last' },
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    SharedModule,
    ThemedEntityModule,
    SupportContactInfoModule,
    SystemUseNoticeModule,
    SessionTerminationMessageModule,
    UpgradeVersionModule,
    DdiqUpgradeModule,
    OiqThemeModule,
    OiqPasswordModule,
    OiqNavModule,
    NgArrayPipesModule,
    DdiqDirectivesModule,
    RouterModule,
    EmbedModule,
  ],
  declarations: [
    LoginComponent,
    LogoutLinkComponent,
    ChangePasswordWithTokenComponent,
    InProgressProfileComponent,
    HomeSidebarComponent,
    HomeComponent,
    MainNavComponent,
    DiffToolModalComponent,
    MainComponent,
    NotFoundComponent,
    ErrorComponent,
    SsoErrorComponent,
  ],
  exports: [
    PaginationComponent,
    LoginComponent,
    LogoutLinkComponent,
    ChangePasswordWithTokenComponent,
    InProgressProfileComponent,
    HomeSidebarComponent,
    HomeComponent,
    MainNavComponent,
    PaginationComponent,
    DiffToolModalComponent,
    MainComponent,
    NotFoundComponent,
    ErrorComponent,
    SsoErrorComponent,
  ],
  providers: [
    WebContentEventGroupsService,
    ProductRecallEventGroupsService,
    OshaInspectionEventGroupsService,
    CorporateRecordEventGroupsService,
    ProfileService,
    WatchListEventGroupsService,
    OrderByPipe,
    HomeResolverService,
    WebsiteScorecardEventGroupsService,
    EventGroupBuilderService,
    EventGroupsBuilderService,
    EventSeverityService,
    InmateProfileEventGroupsService,
    LegalFilingEventGroupsService,
    ProfessionalLicenseEventGroupsService,
    RegulatoryProfileEventGroupsService,
    { provide: 'paginationConfig', useValue: paginationConfig },
  ],
})
export class DdiqCommonModule {}
