import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import Theme from '../theme/theme.service';
import ApiFetchService from './api-fetch.service';

@Injectable({
  providedIn: 'root',
})
export default class TranslateService {
  private language = 'english';
  private dictionary = {};
  private dictionaryLoaded = false;
  private readonly dictionaryExtension;

  constructor(
    private apiFetchService: ApiFetchService,
    private messageBus: MessageBusService,
    private errorHandler: ErrorHandler,
    private theme: Theme
  ) {
    this.dictionaryExtension = this.theme.dictionary;
    this.loadDictionary();
  }

  private setDictionaryValues(dictionary, languages, productDictionary) {
    var mappedLanguages = {};
    (languages || []).forEach(function (language) {
      mappedLanguages[language.code] = {
        english: language.description,
      };
    });
    Object.assign(this.dictionary, dictionary, mappedLanguages, productDictionary, this.dictionaryExtension || {});

    this.dictionaryLoaded = true;
    this.broadcastChange();
  }

  private loadDictionary() {
    Promise.all([
      this.apiFetchService.config('dictionary.json'),
      this.apiFetchService.config('languages.json'),
      this.apiFetchService.config('dictionary-product.json'),
    ]).then(
      (result) => {
        this.setDictionaryValues(result[0], result[1], result[2]);
      },
      () => this.errorHandler.error('dictionary load failed')
    );
  }

  private broadcastChange() {
    this.messageBus.send({ type: 'translationUpdate' });
  }

  translate(input, preserveOriginal?) {
    if (typeof this.dictionary[input] === 'undefined' || typeof this.dictionary[input][this.language] === 'undefined') {
      if (input?.length < 100) {
        //Searches for pattern of STRING_
        if (input && input.match(/(?=.*[A-Z])(?=.*[_])/) && !preserveOriginal) {
          return this.codeToTitleCase(input);
        }
      }
      return input;
    }
    return this.dictionary[input][this.language];
  }

  codeToTitleCase(input) {
    return (
      input &&
      input
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
    );
  }
}
