import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ddiq-regulatory-profiles-group',
  templateUrl: './ddiq-regulatory-profiles-group.component.tpl.html',
})
export default class RegulatoryProfilesGroupComponent {
  @Input() groups: any;
  @Output() sortDiscoveries: any = new EventEmitter();
  @Input() isDisplayCreatedDate: boolean;
  @Input() idPrefix: string;
  @Input() expandAll: boolean;

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  classification(index: number, group: any) {
    return group.classification;
  }

  id(index: number, profile: any) {
    return profile.id;
  }
}
