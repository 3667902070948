import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import DdiqPersonRelatedCompaniesFormComponent from './ddiq-person-related-companies-form.component';

@NgModule({
  imports: [CommonModule, FormsModule, ThemedEntityModule, PipesModule],
  declarations: [DdiqPersonRelatedCompaniesFormComponent],
  exports: [DdiqPersonRelatedCompaniesFormComponent],
})
export class PersonProfileFormsModule {}
