import { Pipe, Inject } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import redirectQueryParamsConstants from '../../../exiger/redirect-query-params.constants';
import utils from '../../../exiger/utils';
import Entity from '../common-services/entity.service';

@Pipe({
  name: 'protectedArticleHref',
})
export default class ProtectedArticleHrefPipe {
  private window: any;

  constructor(private entity: Entity, private windowRef: WindowRefService) {
    this.window = this.windowRef.nativeWindow;
  }

  transform(url: string) {
    let href;

    url = url || '';

    if (!isRestEndPoint(url) || isZipFile(url)) {
      href = url;
    } else {
      href = makeRedirectHref(addEntityToUrl(url, this.entity.getId()), this.window, redirectQueryParamsConstants);
    }

    return href;
  }
}

function isRestEndPoint(url) {
  return url.indexOf('ddiq-services/rest') >= 0;
}

// Currently: swiftkyc zip files
function isZipFile(url) {
  return url.indexOf('swiftkycReport') >= 0;
}

function makeRedirectHref(url, _window, redirectQueryParams) {
  let anchor,
    redirectUrl = '#/protected-articles/',
    restResourceUrl,
    contextPath = _window.location.origin + _window.location.pathname;

  anchor = document.createElement('a');

  if (url.indexOf('ddiq-services/rest/') >= 0) {
    restResourceUrl = utils.subStringAfter(url, 'ddiq-services/rest/');
  } else {
    // someone is using this code in an unexpected way
    return '';
  }

  anchor.setAttribute(
    'href',
    contextPath +
      '?' +
      redirectQueryParams.redirectUrlStr +
      '=' +
      encodeURIComponent(redirectUrl) +
      '&' +
      redirectQueryParams.restResourceUrlStr +
      '=' +
      encodeURIComponent(restResourceUrl)
  );

  return anchor.getAttribute('href');
}

function addEntityToUrl(url, id) {
  if (typeof id !== 'undefined') {
    const lexusParam = '?lnid=';
    const index = url.indexOf(lexusParam);
    if (index >= 0) {
      const lexusParamValue = url.substring(index + 6);
      url = url.replace(lexusParam + lexusParamValue, '');
    }
    return url + '?entityId=' + id;
  }
  return url;
}
