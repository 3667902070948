import WebsiteScorecardComponent from './ddiq-website-scorecard.component';
import WebsiteScorecardsGroupComponent from './ddiq-website-scorecards-group.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import OiqAssociationModule from '../association/oiq-association.module';
import { CodeSchemaModule } from '../code-schema/oiq-code-schema.module';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import WebsiteScorecardEventGroupsService from '../common/website-scorecard-event-groups.service';

@NgModule({
  imports: [
    CommonModule,
    OiqAssociationModule,
    OiqAdjudicationModule,
    ProtectedArticlesModule,
    PipesModule,
    DdiqDirectivesModule,
    DdiqUIModule,
    OiqSourcesModule,
    CodeSchemaModule,
  ],
  declarations: [WebsiteScorecardComponent, WebsiteScorecardsGroupComponent],
  providers: [WebsiteScorecardEventGroupsService],
  exports: [WebsiteScorecardsGroupComponent, WebsiteScorecardComponent],
})
export class WebsiteScorecardsModule {}
