import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import moment from 'moment';
import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ProfileRefreshService from './profile-refresh.service';
import Entity from '../common-services/entity.service';
import ModalTrackingService from '../modal/modal-tracking.service';

@Component({
  selector: 'ddiq-profile-refresh-scheduler',
  templateUrl: './ddiq-profile-refresh-scheduler.component.tpl.html',
})
export default class ProfileRefreshSchedulerComponent implements OnInit {
  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private profileRefreshService: ProfileRefreshService,
    private entity: Entity,
    private modalTrackingService: ModalTrackingService
  ) {}

  today;
  datePattern;
  labelPattern;
  request;
  profileRefreshFrequencies;
  scheduleConflicted: boolean;
  errorMessage;

  ngOnInit(): void {
    if (this.oiqProperties.dateFormat === 'US') {
      this.labelPattern = 'MM/DD/YYYY';
      this.datePattern = 'mm/dd/yy';
    } else {
      this.labelPattern = 'YYYY-MM-DD';
      this.datePattern = 'yy-mm-dd';
    }
    this.today = new Date();

    this.request = {
      frequency: '',
      dates: {
        options: {
          startDate: this.today,
          after: {
            dateFormat: this.datePattern,
            minDate: this.today,
          },
        },
      },
    };

    this.profileRefreshFrequencies = this.oiqProperties.profileRefreshFrequencies;
    this.scheduleConflicted = false;
    this.errorMessage = '';

    this.apiFetchService
      .getProfileRefreshSchedule(this.entity.getType(), this.entity.getId())
      .then(
        (response) => this.profileRefreshService.updateProfileRefreshData(response),
        this.profileRefreshRequestFailed()
      );
  }

  cancel() {
    this.request['frequency'] = '';
    this.request['startDate'] = '';
    this.modalTrackingService.getTriggers().scheduleRefresh.open = false;
  }

  scheduleProfileRefresh(request) {
    let req;
    req = { frequency: request.frequency };

    if (request.startDate) {
      // later, this will be JSON stringif-ied into ISO 8601 format by Angular
      // Setting the local time. Local time is needed to convert to correct UTC date
      let dateTime = moment();
      dateTime.set({
        year: request.startDate.getFullYear(),
        month: request.startDate.getMonth(),
        date: request.startDate.getDate(),
      });
      req.from = dateTime.toDate();
    }

    this.apiFetchService
      .scheduleProfileRefresh(this.entity.getType(), this.entity.getId(), req)
      .then(this.profileRefreshScheduled(), this.profileRefreshRequestFailed());
  }

  profileRefreshScheduled() {
    return (response) => {
      this.scheduleConflicted = false;
      this.errorMessage = '';

      this.profileRefreshService.updateProfileRefreshData(response);
      this.modalTrackingService.getTriggers().scheduleRefresh.open = false;
    };
  }

  profileRefreshRequestFailed() {
    return (response) => {
      const defaultErrorMsg = 'Scheduling has failed.';

      if (response && response.status === 409) {
        this.profileRefreshService.updateProfileRefreshData(response.data);
        this.scheduleConflicted = true;
      } else if (response && response.data) {
        this.errorMessage = response.data.reason || defaultErrorMsg;
      } else {
        this.errorMessage = defaultErrorMsg;
      }
    };
  }
}
