import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToBr',
})
export default class NewLineToBrPipe implements PipeTransform {
  constructor() {}

  /**
   * Converts input newlines `\n` into <br> tags
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    if (!input || !input.length) {
      return input;
    }
    return input
      .replace(/(^\n+)|(\n+$)/gi, '')
      .replace(/(\n){3,}/gim, '<br/><br/>')
      .replace(/(\n)/gim, '<br/>');
  }
}
