<div class="themed-entity-wrapper-hack">
  <div>
    <div class="themed-entity">
      <img *ngIf="isLoading" [src]="'./img/loader.gif'" class="result-loading-img" />

      <div *ngIf="!isLoading">
        <ddiq-profile-header [profile]="profile" (reloadProfileFn)="reloadProfile()"></ddiq-profile-header>

        <ddiq-modal class="ddiq-modal" [trigger]="'pdf'" [modalName]="'pdf'" [closeable]="true" #pdfModal="ddiqModal">
          <ddiq-pdf *ngIf="pdfModal.vm.open"></ddiq-pdf>
        </ddiq-modal>

        <ddiq-modal
          class="ddiq-modal"
          *ngIf="!entity.isDiff()"
          [trigger]="'diff'"
          [closeable]="true"
          #diffTollModal="ddiqModal">
          <ddiq-diff-tool-modal *ngIf="diffTollModal.vm.open" [isLoading]="isLoading"></ddiq-diff-tool-modal>
        </ddiq-modal>

        <ddiq-modal id="scheduleRefresh" class="ddiq-modal" *ngIf="!entity.isDiff()" [trigger]="'scheduleRefresh'">
          <ddiq-profile-refresh-scheduler></ddiq-profile-refresh-scheduler>
        </ddiq-modal>

        <ddiq-modal id="editCollections" class="ddiq-modal" *ngIf="!entity.isDiff()" [trigger]="'editCollections'">
          <ddiq-profile-collection-edit
            [entityId]="entity.getId()"
            [entityType]="entity.getType()"></ddiq-profile-collection-edit>
        </ddiq-modal>

        <ddiq-modal
          id="enableMonitoring"
          class="ddiq-modal"
          *ngIf="!entity.isDiff()"
          [trigger]="'enableMonitoring'"
          #monitorProfile="ddiqModal">
          <ddiq-monitor-profile *ngIf="monitorProfile.vm.open"></ddiq-monitor-profile>
        </ddiq-modal>

        <ddiq-modal
          class="ddiq-modal"
          [trigger]="'delegate'"
          [modalName]="'delegate'"
          [closeable]="true"
          #delegateProfile="ddiqModal">
          <delegate-profile
            *ngIf="delegateProfile.vm.open"
            [entityType]="entity.getType()"
            [entityId]="entity.getId()"
            (onAssigned)="onProfileAssigned($event.assignedTo)"
            (onAssignError)="onProfileAssignError($event.errorInfo)"
            (onCancelled)="onProfileAssignCancelled()"></delegate-profile>
        </ddiq-modal>

        <ddiq-modal
          class="ddiq-modal"
          [trigger]="'notify'"
          [modalName]="'notify'"
          [closeable]="true"
          #notifyComponent="ddiqModal">
          <ddiq-notify *ngIf="notifyComponent.vm.open"></ddiq-notify>
        </ddiq-modal>

        <ddiq-modal
          class="ddiq-modal"
          [trigger]="'dynamicAdjudication'"
          [modalName]="'dynamicAdjudication'"
          [closeable]="true"
          #dynamicAdjudication="ddiqModal">
          <ddiq-dynamic-adjudication-modal *ngIf="dynamicAdjudication.vm.open"></ddiq-dynamic-adjudication-modal>
        </ddiq-modal>

        <ddiq-modal
          class="ddiq-modal"
          [trigger]="'exclusionManagement'"
          [modalName]="'exclusionManagement'"
          [closeable]="true"
          #exclusionManagement="ddiqModal">
          <ddiq-exclusion-management-modal
            *ngIf="exclusionManagement.vm.open"
            id="exclusion-management"></ddiq-exclusion-management-modal>
        </ddiq-modal>

        <ddiq-error-modal id="error-modal" class="ddiq-modal" trigger="error" closeable></ddiq-error-modal>

        <ddiq-dynamic-adjudication></ddiq-dynamic-adjudication>

        <main [ngClass]="{'opacity': isProfileNavigating }">
          <ddiq-profile-nav *ngIf="!isProperty" [sections]="profile.sections"></ddiq-profile-nav>

          <section class="ddiq-main-profile-container padding-push">
            <ddiq-main-profile [profile]="profile" [isExpandAllGroups]="isExpandAllGroups"> </ddiq-main-profile>

            <div>
              <ddiq-footer></ddiq-footer>
            </div>
          </section>
        </main>
      </div>
    </div>
  </div>
</div>
