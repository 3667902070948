import { Injectable } from '@angular/core';
import { ProfileHit, ProfileHitId } from './profile-hit-id';

export interface RegulatoryHit extends ProfileHit {
  seedSource?: { source: string };
}
@Injectable()
export class RegulatoryHitIdService extends ProfileHitId {
  doGetIdFrom(regulatoryHit: RegulatoryHit): string {
    return `${regulatoryHit.seedSource?.source}_${regulatoryHit.id}`;
  }
}
