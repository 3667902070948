import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class CookieService {
  constructor() {}

  put(cname, cvalue) {
    document.cookie = cname + '=' + cvalue + ';';
  }
  putWithPath(cname, cvalue, cpath) {
    document.cookie = cname + '=' + cvalue + ';path=' + cpath + ';';
  }
  get(cname) {
    var i,
      name = cname + '=',
      ca = document.cookie.split(';');
    for (i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return '';
  }
  remove(cname) {
    document.cookie = cname + '=;';
  }
  removeWithPath(cname, cpath) {
    document.cookie = cname + '=;path=' + cpath + ';';
  }
}
