import { Component, Input, OnInit } from '@angular/core';
import TranslateService from '../common-services/translate.service';
import Sources from './sources.service';

@Component({
  selector: 'ddiq-field-source-info',
  templateUrl: './ddiq-field-source-info.component.tpl.html',
})
export default class FieldSourceInfoComponent implements OnInit {
  @Input() field;
  @Input() fieldValue;

  count;
  title;
  isOpen;
  countLabel;

  constructor(private sources: Sources, private translateService: TranslateService) {}

  ngOnInit() {
    this.count = this.getSources().length;

    if (this.count) {
      this.title =
        'Sources: ' +
        this.getSources()
          .map((source) => {
            return this.translateService.translate(source);
          })
          .join(', ');

      this.isOpen = this.sources.shouldShow();
      this.countLabel = this.count > 1 ? 'Sources' : 'Source';
    } else {
      this.title = 'No sources found';
    }
  }

  getSources() {
    return this.sources.getSourceInfoForFieldByValue(this.field, this.fieldValue) || [];
  }

  toggle() {
    if (this.count) {
      this.isOpen = !this.isOpen;
    }
  }
}
