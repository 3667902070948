import ScreeningFeatureService from './screening-feature.service';
import ScreeningHitCategoriesService from './screening-hit-categories.service';
import { NgModule } from '@angular/core';
import ScreeningHitCategoryComponent from './screening-hit-category.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';

@NgModule({
  imports: [CommonModule, FormsModule, ThemedEntityModule],
  providers: [ScreeningFeatureService, ScreeningHitCategoriesService],
  declarations: [ScreeningHitCategoryComponent],
  exports: [ScreeningHitCategoryComponent],
})
export class ScreeningModule {}
