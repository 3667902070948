import { Component, Inject, OnInit } from '@angular/core';
import Filter from '../themed-entity/filter.service';

@Component({
  selector: 'ddiq-filtered-messages',
  templateUrl: './ddiq-filtered-messages.component.tpl.html',
})
export default class DdiqFilteredMessages implements OnInit {
  isFiltered: boolean;
  fromDate;
  toDate;
  confirmedContent;
  starredContent;
  adjudicated;
  hideConfirmed;
  hideEscalated;
  adjudicatedOnly;
  hiddenSections;
  sections;
  isRelevanceFiltered;
  level;

  constructor(private filter: Filter) {}

  ngOnInit() {
    const isFiltered = this.filter.isFiltered();

    if (isFiltered) {
      const filter = this.filter.getFilter();
      const sections = this.filter.getSections();
      const bucketsFilterState = this.filter.getBucketsFilterState(filter.associationBucket);

      this.isFiltered = isFiltered;
      this.fromDate = filter.fromDate;
      this.toDate = filter.toDate;
      this.confirmedContent = filter.confirmedOnly;
      this.starredContent = filter.escalatedOnly;
      this.adjudicated = filter.excludeAdjudicated;
      this.hideConfirmed = filter.hideConfirmed;
      this.hideEscalated = filter.hideEscalated;
      this.adjudicatedOnly = filter.adjudicatedOnly;
      this.hiddenSections = this.isHideSection(sections);
      this.sections = sections;
      this.isRelevanceFiltered = bucketsFilterState.isFiltered;

      if (bucketsFilterState.isFiltered) {
        this.level = bucketsFilterState.getBucketName();
      }
    }
  }

  isHideSection(sections) {
    return sections.some((section) => {
      if (section.isConfigured) {
        return section.metadata.filtered;
      }
    });
  }
}
