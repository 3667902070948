<div [ngSwitch]="(people || []).length > 0" #entityAware="oiqEntityAware" oiq-entity-aware>
  <div *ngSwitchCase="true">
    <table class="related-entity">
      <thead>
        <tr>
          <th colspan="3">
            {{title}}
            <related-entity-group-dynamic-adjudication-aware [entities]="people">
            </related-entity-group-dynamic-adjudication-aware>
            <div class="pull-right">
              <oiq-filter-count [entities]="people"></oiq-filter-count>
              <ddiq-sort-buttons
                class="pull-right"
                [entities]="people"
                [orderBy]="'name, source'"
                [btnTexts]="'Name, Source'"
                (onClick)="sort($event.btnName)">
              </ddiq-sort-buttons>
            </div>
          </th>
        </tr>
      </thead>
      <ng-container *ngIf="!isAllFiltered(this.people)">
        <ng-container
          *ngFor="let entity of people | filterByImpure: ['filtered']: false | orderBy: sortBtnOrderType;  trackBy: oiqId;">
          <tbody
            class="repeat"
            oiq-adjudication-aware
            #sourceInfo="oiqSourceInfoCtrl"
            oiq-source-info-ctrl
            [info]="{ discovery: entity.base }"
            [ngClass]="{'escalated': entity.relationship?.escalated, 'confirmed': entity.relationship?.confirmed}">
            <tr>
              <td *ngIf="adjudicationSupport && entity.relationship" class="adjudication-controls">
                <oiq-dynamic-adjudication-aware [model]="entity.relationship"></oiq-dynamic-adjudication-aware>
                <ddiq-adjudication-actions
                  *ngIf="adjudicationSupport && entity.relationship"
                  [type]="'RELATIONSHIP'"
                  [entityType]="entityAware.entityType"
                  [entityId]="entityAware.entityId"
                  [noReassess]="entityAware.noReassess"
                  [targetId]="entity.relationship.id"
                  (adjudicationComplete)="isAdjudicationComplete($event)"
                  [model]="entity.relationship">
                </ddiq-adjudication-actions>
              </td>
              <td>
                <span [ngClass]="{'disabled': entity.relationship?.disabled}">
                  <span *ngIf="entity.base.name">{{ entity.base.name }}</span>
                  <span *ngIf="!entity.base.name">
                    {{ entity.base.firstName }}
                    <span *ngIf="entity.base.middleName">{{ entity.base.middleName }}</span>
                    {{ entity.base.lastName }}
                  </span>
                  <span *ngIf="entity.base.currentJobTitle"> , <em>{{ entity.base.currentJobTitle }}</em> </span>
                </span>
                <span *ngIf="entity.relationships.length" [ngClass]="{'disabled': entity.relationship?.disabled}">
                  <span *ngFor="let relationship of entity.relationships">
                    <span *ngIf="relationship.additionalData.length">
                      <span *ngFor="let additionalData of relationship.additionalData">
                        <span *ngIf="additionalData.label === 'OwnershipType' && additionalData.value">
                          , <em>{{ additionalData.value | translatef }}</em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>

                <div class="child-alert inline tt tt-right" *ngIf="alertsEnabled && entity.base.alerts.length > 0">
                  <div class="tool">
                    <ddiq-alert-icon></ddiq-alert-icon>
                  </div>
                  <div class="tip src-meta">
                    <div>
                      <ul>
                        <ul>
                          <li *ngFor="let alert of entity.base.alerts">{{alert.alertType | translatef }}</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </td>
              <td class="button-col">
                <div [ngSwitch]="entity.base.crawlStatus" class="profile-btn inline vmid no-print profile-btn-right">
                  <div *ngSwitchCase="'STUB'">
                    <div *ngIf="(isRequestBtnEnabled && canUserSubmitProfile && entity.canViewProfile)">
                      <a class="btn btn-primary btn-sm requestable" (click)="request({person: entity.base})">
                        Request
                      </a>
                    </div>
                  </div>
                  <div *ngSwitchCase="'IN_PROGRESS'">
                    <a class="btn btn-primary btn-sm in-progress" [disabled]="true"> In Progress </a>
                  </div>
                  <div *ngSwitchDefault>
                    <div *ngIf="entity.canViewProfile">
                      <a class="btn btn-primary btn-sm complete" href="{{ entity.base.url }}"> View </a>
                    </div>
                  </div>
                </div>
                <div class="inline correlating-urls">
                  <div class="inline correlating-urls">
                    <oiq-source-info-badge></oiq-source-info-badge>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="sourceInfo.showSources" [ngClass]="{'disabled': entity.relationship?.disabled}">
              <td *ngIf="adjudicationSupport && entity.relationship" class="adjudication-controls"></td>
              <td class="source-urls" colspan="2">
                <ddiq-source-info></ddiq-source-info>
              </td>
            </tr>
            <tr>
              <td *ngIf="adjudicationSupport && entity.relationship" class="adjudication-controls"></td>
              <td colspan="2">
                <ddiq-adjudication-notes
                  *ngIf="entity.relationship"
                  class="adjudication-notes"
                  [type]="'RELATIONSHIP'"
                  [entityType]="entityAware.entityType"
                  [entityId]="entityAware.entityId"
                  [noReassess]="entityAware.noReassess"
                  [targetId]="entity.relationship.id"
                  [model]="entity.relationship"
                  [label]="'Notes'"
                  [active]="entity.comment">
                </ddiq-adjudication-notes>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <tbody *ngIf="isAllFiltered(people)">
        <tr>
          <td>
            <p>All results filtered</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
