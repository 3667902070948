import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import settings from '../../shared/config/settings.json';
import configServiceProvider from '../../shared/services/http/config-service.provider';
import HttpBufferService from '../../shared/services/http/http-buffer.service';
import UnauthorizedRequestInterceptor from '../../shared/services/http/unauthorized-request-interceptor';
import { SharedModule } from '../../shared/shared.module';
import OiqAdjudicationModule from './modules/oiq/adjudication/oiq-adjudication.module';
import AdminModule from './modules/oiq/admin/oiq-admin.module';
import { DataDogModule } from './modules/oiq/analytics/data-dog.module';
import { AuditModule } from './modules/oiq/audit/ddiq-audit.module';
import { DdiqBuildCompanyModule } from './modules/oiq/build-company/oiq-build-company.module';
import { DdiqBuildPersonModule } from './modules/oiq/build-person/oiq-build-person.module';
import CommonServicesModule from './modules/oiq/common-services/common-services.module';
import { DdiqCommonModule } from './modules/oiq/common/oiq-common.module';
import { CorporateGraphModule } from './modules/oiq/corporate-graph/corporate-graph.module';
import { DdiqCorporateRecordsModule } from './modules/oiq/corporate-records/ddiq-corporate-records.module';
import { DdiqGovernmentProcurementModule } from './modules/oiq/government-procurement/oiq-government-procurement.module';
import { OiqIncidentsModule } from './modules/oiq/incidents/oiq-incidents.module';
import JWTInterceptor from './modules/oiq/interceptors/jwt-interceptor';
import NotFoundInterceptor from './modules/oiq/interceptors/not-found-interceptor';
import OiqNavModule from './modules/oiq/nav/oiq-nav.module';
import { NetworkModule } from './modules/oiq/network/oiq-network.module';
import { ProfileCollectionModule } from './modules/oiq/profile-collection/profile-collection.module';
import { ProfileCollectionPermissionMissingForSubmissionModule } from './modules/oiq/profile-collection/ddiq-profile-collection-missing-permission-for-submission.module';
import ProfileInputModule from './modules/oiq/profile-input-details/profile-input.module';
import { ProfileLibraryModule } from './modules/oiq/profile-library/ddiq-profile-library.module';
import OiqRegulatoryProfilesModule from './modules/oiq/regulatory-profiles/oiq-regulatory-profiles.module';
import { OiqRiskModule } from './modules/oiq/risk/oiq-risk.module';
import { OiqPasswordModule } from './modules/oiq/password/oiq-password.module';
import { OiqUserModule } from './modules/oiq/user/oiq-user.module';
import { OiqStructuredDataModule } from './modules/oiq/structured-data/oiq-structured-data.module';
import ScreeningDashboardSearchRequestInterceptor from './modules/oiq/screening/screening-dashboard-search-request.interceptor';
import ScreeningProfileStatusInterceptor from './modules/oiq/screening/screening-profile-status.interceptor';
import SupportContactInfoModule from './modules/oiq/support-contact-info/support-contact-info.module';
import { OiqThemeModule } from './modules/oiq/theme/oiq-theme.module';
import { ThemedEntityModule } from './modules/oiq/themed-entity/oiq-themed-entity.module';
import { DdiqUIModule } from './modules/oiq/ui/oiq-ui.module';
import VersionModule from './modules/oiq/version/oiq-version.module';
import { OiqArticleCacheModule } from './modules/oiq/article-cache/oiq-article-cache.module';
import { CodeSchemaModule } from './modules/oiq/code-schema/oiq-code-schema.module';
import { DdiqMonitorModule } from './modules/oiq/monitor/oiq-monitor.module';
import { AlertsModule } from './modules/oiq/alerts/oiq-alerts.module';
import { UpgradeVersionModule } from './modules/oiq/upgrade/upgrade.module';
import UpgradeVersionInterceptor from './modules/oiq/upgrade/upgrade-version-interceptor';
import { OiqProfessionalLicensesModule } from './modules/oiq/professional-licenses/oiq-professional-licenses.module';
import { DdiqInmateProfileModule } from './modules/oiq/inmate-profiles/oiq-inmate-profiles.module';
import { OiqProductRecallsModule } from './modules/oiq/product-recalls/oiq-product-recalls.module';
import { OiqLegalFilingsModule } from './modules/oiq/legal-filings/oiq-legal-filings.module';
import { OiqRelatedEntityModule } from './modules/oiq/related-entity/oiq-related-entity.module';
import { DdiqGeneralInformationModule } from './modules/oiq/general-information/ddiq-general-information.module';
import versionAppInitializer from './modules/oiq/version/version.app-initializer';
import themeInitializer from './modules/oiq/theme/config-theme';
import { OiqWatchLists } from './modules/oiq/watch-lists/oiq-watch-lists.module';
import OiqConfigurationModule from './modules/oiq/configuration/oiq-configuration.module';
import { OiqCoverModule } from './modules/oiq/cover/oiq-cover.module';
import { ProfileFiltersModule } from './modules/oiq/profile-filters/oiq-profile-filters.module';
import { OshaInspectionsModule } from './modules/oiq/osha-inspections/oiq-osha-inspections.module';
import { WebsiteScorecardsModule } from './modules/oiq/website-scorecards/ddiq-website-scorecards.module';
import { ProfileRefreshModule } from './modules/oiq/profilerefresh/profile-refresh.module';
import { EventGroupsModule } from './modules/oiq/event-groups/oiq-event-groups.module';
import { ProfileDelegationModule } from './modules/oiq/profile-delegation/profile-delegation.module';
import { ProfileModule } from './modules/oiq/profile/profile.module';
import { SessionTerminationMessageModule } from './modules/oiq/session-termination-message/session-termination-message.module';
import configSessionTerminationMessage from './modules/oiq/session-termination-message/config-session-termination-message';
import { ScreeningModule } from './modules/oiq/screening/screening.module';
import { configSupportContactInfo } from './modules/oiq/support-contact-info/config-support-info.component';
import { PdfModule } from './modules/oiq/pdf/oiq-pdf.module';
import { ProfileStatusModule } from './modules/oiq/profile-status/profile-status.module';
import configUser from './modules/oiq/user/config-user';
import authInitializer from './exiger/auth-initializer';
import configSystemUseNotice from './modules/oiq/system-use-notice/config-system-use-notice';
import { SystemUseNoticeModule } from './modules/oiq/system-use-notice/system-use-notice.module';
import { AppRoutingModule } from './app-routing.module';
import { IntegrityModule } from './modules/oiq/integrity/oiq-integrity.module';
import configJwt from './modules/oiq/jwt/config-jwt';
import { JwtModule } from './modules/oiq/jwt/oiq-jwt.module';
import MainComponent from './modules/oiq/common/ddiq-main.component';
import ServiceUnavailableInterceptor from './modules/oiq/interceptors/service-unavailable-interceptor';
import configExternalUrlMsg from './modules/oiq/external-url-msg/config-external-url-msg';
import { CsrfSecurityInterceptor } from '../../shared/services/http/csrf-security-interceptor';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    DdiqBuildCompanyModule,
    DdiqBuildPersonModule,
    ProfileCollectionPermissionMissingForSubmissionModule,
    AuditModule,
    NetworkModule,
    ProfileCollectionModule,
    NgMultiSelectDropDownModule.forRoot(),
    DataDogModule,
    SharedModule,
    AuditModule,
    ThemedEntityModule,
    ProfileLibraryModule,
    OiqArticleCacheModule,
    OiqRiskModule,
    OiqPasswordModule,
    CorporateGraphModule,
    OiqRiskModule,
    OiqUserModule,
    OiqStructuredDataModule,
    VersionModule,
    CodeSchemaModule,
    AlertsModule,
    UpgradeVersionModule,
    OiqThemeModule,
    OiqNavModule,
    SupportContactInfoModule,
    DdiqCommonModule,
    AdminModule,
    DdiqGovernmentProcurementModule,
    DdiqMonitorModule,
    CommonServicesModule,
    OiqAdjudicationModule,
    ProfileInputModule,
    OiqIncidentsModule,
    DdiqUIModule,
    DdiqCorporateRecordsModule,
    OiqProfessionalLicensesModule,
    DdiqInmateProfileModule,
    OiqProductRecallsModule,
    OiqRegulatoryProfilesModule,
    OiqLegalFilingsModule,
    OiqRelatedEntityModule,
    DdiqGeneralInformationModule,
    OiqWatchLists,
    OiqConfigurationModule,
    ProfileFiltersModule,
    OshaInspectionsModule,
    WebsiteScorecardsModule,
    OiqWatchLists,
    OiqCoverModule,
    ProfileRefreshModule,
    EventGroupsModule,
    ProfileDelegationModule,
    ProfileModule,
    SessionTerminationMessageModule,
    ScreeningModule,
    PdfModule,
    ProfileStatusModule,
    SystemUseNoticeModule,
    AppRoutingModule,
    IntegrityModule,
    JwtModule,
  ],
  providers: [
    configServiceProvider(settings),
    HttpBufferService,
    { provide: HTTP_INTERCEPTORS, useClass: UpgradeVersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotFoundInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ScreeningProfileStatusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ScreeningDashboardSearchRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServiceUnavailableInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfSecurityInterceptor, multi: true },

    versionAppInitializer(settings),
    authInitializer(),
    themeInitializer(),
    configSessionTerminationMessage(),
    configSupportContactInfo(),
    configSystemUseNotice(),
    configUser(),
    configJwt(),
    configExternalUrlMsg(),
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private dataDog: DataDogModule,
    private oiqUser: OiqUserModule,
    private sessionTerminationMessage: SessionTerminationMessageModule
  ) {}
  ngDoBootstrap(appRef: ApplicationRef) {
    this.dataDog.configure();
    this.sessionTerminationMessage.configure();
    this.oiqUser.configure();

    appRef.bootstrap(MainComponent);
  }
}
