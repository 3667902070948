import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import ApiFetchService from './api-fetch.service';

@Injectable({
  providedIn: 'root',
})
class AlertService {
  private alerter: any = {};

  constructor(private apiFetchService: ApiFetchService, private errorHandler: ErrorHandler) {}

  private normalizeEntityType(entityType) {
    return entityType === 'property' ? 'location' : entityType;
  }

  fetchConfig() {
    return new Promise((resolve, reject) => {
      const fetchSuccess = (data) => {
        this.alerter.alerts = data;
        resolve(undefined);
      };

      const fetchFail = (response) => {
        this.errorHandler.error('failed to load alert map');
        this.errorHandler.error(response);
        reject();
      };

      if (this.alerter.alerts) {
        resolve(undefined);
      } else {
        this.apiFetchService.config('alertMap.json').then(fetchSuccess, fetchFail);
      }
    });
  }

  /**
   *
   * @param uiAlerts
   * @param type
   * @param baseUrl
   * @param interPage - boolean - does this alert link to a different page
   */
  buildUrls(uiAlerts, type, baseUrl, interPage) {
    var alertType,
      alert,
      i, // index for alerts
      url,
      suffixRegex = /((company|person|location)\/[0-9]*)/; // regex to drop everything after the slash after the id for overview

    if (interPage) {
      url = baseUrl;
    } else {
      url = '';
    }
    for (i = 0; i < uiAlerts.length; ++i) {
      alertType = uiAlerts[i].alertType;
      alert = this.alerter.alerts[type][alertType];

      if (alert) {
        if (alert.overview) {
          // overview links need to use the baseUrl instead of the url, which in non-interPage cases is empty
          var overviewUrl = baseUrl.replace(suffixRegex, '');
          uiAlerts[i].url = overviewUrl + '#' + alert.cssId;
        } else {
          uiAlerts[i].url = url + '#' + alert.cssId;
        }
      } else {
        this.errorHandler.log('Alert is not defined: ' + alertType);
      }
    }
  }

  /**
   * takes an array of already filtered alerts (via filterAlertList)
   * plus any alerts we set (via setThemedFlaggedContentAlert)
   *
   * just counts the # of alerts. This used to manage alertDetails as
   * well but that's gone away
   *
   * @param filteredAlerts
   * @returns {number} count
   */
  getThemedAlertCount(filteredAlerts) {
    return filteredAlerts.length;
  }

  /**
   * creates an array of the alerts which are pertinent to the theme.
   *
   * @param entityType (String company/person/location)
   * @param alerts (Array of all alerts retrieved)
   * @param theme (String legal/finance/other)
   */
  getThemedEntityAlerts(entityType, alerts, theme) {
    var i,
      themedAlerts = [],
      alertType;

    entityType = this.normalizeEntityType(entityType);

    for (i = 0; i < alerts.length; i++) {
      alertType = this.alerter.alerts[entityType][alerts[i].alertType];
      if (alertType && alertType.theme === theme) {
        themedAlerts.push(alerts[i]);
      }
    }

    return themedAlerts;
  }
}

export default AlertService;
