import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import { Subscription } from 'rxjs';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { OrderByPipe } from '../../../../../shared/pipes/order-by.pipe';
import TabsData from './tabs-data.service';
import SearchService from './search.service';
import { ActivatedRoute } from '@angular/router';
import OiqProperties from '../common-services/oiq-properties.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import ProgressChecker from '../common-services/progress-checker.service';
import UserService from '../user/user.service';

@Component({
  selector: 'ddiq-profile-library',
  templateUrl: './ddiq-profile-library.component.tpl.html',
})
export default class ProfileLibraryComponent implements OnInit, DoCheck, OnDestroy {
  groupUsers: any;
  crawlPlans: any;
  profileCollectionList: any;

  profileStatusesFilter: any;
  canViewStatus: boolean;
  canSearchReqOrg: boolean;
  searchCorrelationId: any;
  isGovIdSearchEnabled: boolean;
  labelPattern: string;
  datePattern: string;
  isInGroup: boolean;
  currentTab: number;
  formDisabled: boolean;
  counts: { [key: string]: any } = {
    companyCount: 0,
    personCount: 0,
    inProgressCount: 0,
    queuedCount: 0,
    activeCount: 0,
    totalCount: 0,
    companyTotalMatchRelation: '',
    personTotalMatchRelation: '',
  };
  query: any;
  dates: any;
  previousQuery: any;
  searchSettings: any;
  selectedEntity: { [key: string]: any };
  lastQueryString: string;
  isProfileCollectionFilterVisible: boolean = false;
  progressUpdated: Subscription;
  searchProfiles: Subscription;

  constructor(
    private route: ActivatedRoute,
    private messageBusService: MessageBusService,
    private orderBy: OrderByPipe,
    public TabsData: TabsData,
    public SearchService: SearchService,
    private ProgressChecker: ProgressChecker,
    private oiqProperties: OiqProperties,
    private user: UserService,
    private modalTrackingService: ModalTrackingService
  ) {}

  ngOnInit() {
    this.profileStatusesFilter = this.oiqProperties.profileStatusesFilter;
    this.canViewStatus = this.profileStatusesFilter && this.oiqProperties.profileStatusEnabled;
    this.canSearchReqOrg = this.oiqProperties.reqOrgEnabled;
    this.searchCorrelationId = this.oiqProperties.searchCorrelationId;
    this.isGovIdSearchEnabled = this.oiqProperties.isGovIdSearchEnabled;

    this.labelPattern = 'YYYY-MM-DD';
    this.datePattern = 'yy-mm-dd';
    if (this.oiqProperties.dateFormat === 'US') {
      this.labelPattern = 'MM/DD/YYYY';
      this.datePattern = 'mm/dd/yy';
    }

    this.isInGroup = this.user.isInGroup();
    this.currentTab = this.TabsData.activeTab || 0;
    this.formDisabled = this.currentTab === 2;

    this.query = this.resetQuery({});
    this.dates = this.resetDates({});
    this.previousQuery = this.SearchService.previousQuery;
    this.searchSettings = this.SearchService.searchSettings;
    this.route.data?.subscribe((data) => {
      this.groupUsers = data.preLoad[1];
      this.crawlPlans = data.preLoad[2];
      this.profileCollectionList = data.preLoad[3];
    });

    this.initSearch();
    this.ProgressChecker.repeatedProgressCheck();

    if (this.isInGroup) {
      this.groupUsers = this.orderBy.transform(this.groupUsers.users, 'fullName');
    }

    this.isProfileCollectionFilterVisible =
      this.oiqProperties.isProfileCollectionEnabled && this.profileCollectionList.length > 1;

    this.counts = this.TabsData.counts;
    this.progressUpdated = this.messageBusService.on('event:updatedProgress', () => {
      // this setTimeout is to address the ExpressionValueChangedAfterChecked issue
      // the search function changes some values inside the subscription, By putting it in the setTimouet, we manually nvoke the change detection cycle again
      setTimeout(() => {
        this.search(this.previousQuery, this.dates, false);
      }, 1);
    });

    this.searchProfiles = this.messageBusService.on('event:searchProfiles', (data) => {
      this.query.q = data.searchQuery;
      this.search(this.query, this.dates, true);
    });
  }

  ngDoCheck() {
    if (this.TabsData.pendingSearches === 0) {
      const isOnInProgressTab = this.currentTab === 2;
      const hasActiveTab = this.TabsData.activeTab !== undefined;

      const shouldAutoSelectTab = !hasActiveTab && !isOnInProgressTab;

      if (shouldAutoSelectTab) {
        this.currentTab = this.counts.companyCount >= this.counts.personCount ? 0 : 1;
        this.TabsData.activeTab = this.currentTab;
      }
    }
  }

  ngOnDestroy() {
    this.progressUpdated.unsubscribe();
    this.searchProfiles.unsubscribe();
    this.ProgressChecker.clearAll();
    this.SearchService.clearAll();
    this.TabsData.clear();
  }

  initSearch() {
    this.SearchService.parseUrl();
    const urlQuery = this.SearchService.previousQuery;

    this.query.q = urlQuery.q;
    this.query.status = urlQuery.status;
    this.query.owner = urlQuery.owner;
    this.query.id = urlQuery.id;

    if (urlQuery.after) {
      this.dates.after = jQuery.datepicker.formatDate(
        this.datePattern,
        jQuery.datepicker.parseDate('yy-mm-dd', urlQuery.after)
      );
    } else {
      this.dates.after = null;
    }

    if (urlQuery.before) {
      this.dates.before = jQuery.datepicker.formatDate(
        this.datePattern,
        jQuery.datepicker.parseDate('yy-mm-dd', urlQuery.before)
      );
    } else {
      this.dates.before = null;
    }

    this.query.cOrder = urlQuery.cOrder;
    this.query.cDesc = urlQuery.cDesc;
    this.query.pOrder = urlQuery.pOrder;
    this.query.pDesc = urlQuery.pDesc;
    this.query.reqOrg = urlQuery.reqOrg;
    this.query.correlationId = urlQuery.correlationId;
    this.query.govId = urlQuery.govId;
    this.query.crawlPlanType = urlQuery.crawlPlanType;
    this.query.profileCollectionKey = urlQuery.profileCollectionKey;
  }

  resetQuery(query) {
    query.q = null;
    query.status = null;
    query.owner = null;
    query.id = null;
    query.cOrder = 'date';
    query.cDesc = true;
    query.pOrder = 'date';
    query.pDesc = true;
    query.reqOrg = null;
    query.correlationId = null;
    query.govId = null;
    query.crawlPlanType = null;
    query.profileCollectionKey = null;

    return query;
  }

  resetDates(dates) {
    const maxDate = jQuery.datepicker.formatDate(this.datePattern, new Date());

    dates.after = null;
    dates.before = null;
    dates.options = {
      after: {
        dateFormat: this.datePattern,
        maxDate: maxDate,
      },
      before: {
        dateFormat: this.datePattern,
        maxDate: maxDate,
        minDate: null,
      },
    };

    return dates;
  }

  isQueryEmpty() {
    return (
      !this.previousQuery.q &&
      !this.previousQuery.status &&
      !this.previousQuery.owner &&
      !this.previousQuery.id &&
      !this.previousQuery.after &&
      !this.previousQuery.before &&
      !this.previousQuery.reqOrg &&
      !this.previousQuery.correlationId &&
      !this.previousQuery.govId &&
      !this.previousQuery.crawlPlanType &&
      !this.previousQuery.profileCollectionKey
    );
  }

  search(query, dates, isResetPagination) {
    this.lastQueryString = query.q;
    delete this.TabsData.activeTab;
    this.TabsData.pendingSearches = 2;
    this.SearchService.go(query, dates, isResetPagination);
  }

  emptyQuery() {
    return !(
      this.previousQuery.q ||
      this.previousQuery.status ||
      this.previousQuery.owner ||
      this.previousQuery.id ||
      this.previousQuery.after ||
      this.previousQuery.before ||
      this.previousQuery.reqOrg ||
      this.previousQuery.correlationId ||
      this.previousQuery.govId
    );
  }

  clearSearch(query, dates) {
    this.resetDates(dates);
    this.resetQuery(query);
    this.search(query, dates, true);
  }

  clearDates(query, dates) {
    this.resetDates(dates);
    this.search(query, dates, true);
  }

  changeTab(tab) {
    this.currentTab = tab;
    this.TabsData.activeTab = tab;
    this.formDisabled = this.currentTab === 2;
  }

  onAfterDateChange(afterDate) {
    if (afterDate) {
      this.dates.options.before = {
        minDate: afterDate ? new Date(afterDate) : null,
        maxDate: new Date(),
        dateFormat: this.datePattern,
      };
    }
  }

  onBeforeDateChange(beforeDate) {
    if (beforeDate) {
      this.dates.options.after = {
        maxDate: beforeDate ? new Date(beforeDate) : new Date(),
        dateFormat: this.datePattern,
      };
    }
  }

  openDelegationModal(selectedEntity) {
    this.selectedEntity = selectedEntity;
    this.modalTrackingService.getTriggers().delegate.open = true;
  }

  onProfileAssigned(assignedTo) {
    if (this.selectedEntity) {
      this.selectedEntity.oiqOwnerFullName = assignedTo.fullName;
    }

    this.modalTrackingService.getTriggers().delegate.open = false;
    this.selectedEntity = undefined;
  }

  onProfileAssignCancelled() {
    this.modalTrackingService.getTriggers().delegate.open = false;
    this.selectedEntity = undefined;
  }

  onProfileAssignError(error) {
    if (error.errorMsg === 'OWNER_CHANGE_CONFLICT_ERROR' && error.owner) {
      this.selectedEntity.oiqOwnerFullName = error.fullName;
    }
  }
}
