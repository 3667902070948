import DdiqInmateProfilesGroupComponent from './ddiq-inmate-profiles-group.component';
import DdiqInmateProfileComponent from './ddiq-inmate-profile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqAssociationModule from '../association/oiq-association.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import OiqSourcesModule from '../sources/oiq-sources.module';

@NgModule({
  imports: [
    CommonModule,
    DdiqUIModule,
    PipesModule,
    DdiqDirectivesModule,
    OiqAdjudicationModule,
    OiqAssociationModule,
    OiqSourcesModule,
  ],
  declarations: [DdiqInmateProfileComponent, DdiqInmateProfilesGroupComponent],
  exports: [DdiqInmateProfilesGroupComponent, DdiqInmateProfileComponent],
})
export class DdiqInmateProfileModule {}
