import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'millisToTime',
})
export default class MillisToTimePipe implements PipeTransform {
  constructor() {}

  // /**
  //  * Returns String in Hour:Minute:Seconds format from milliseconds
  //  * @param input
  //  * @returns {String}
  //  */
  transform(input: any) {
    if (typeof input !== 'undefined') {
      let seconds: any = parseInt(`${(input / 1000) % 60}`),
        minutes: any = parseInt(`${(input / (1000 * 60)) % 60}`),
        hours: any = parseInt(`${(input / (1000 * 60 * 60)) % 24}`);

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      return hours + ':' + minutes + ':' + seconds;
    } else {
      return input;
    }
  }
}
