<div class="modal-title">Exclusion Management</div>

<div class="modal-content center-block">
  <div *ngIf="!retrievalError">
    <p>
      The following items will be excluded from future {{ (isMonitorDeltaReport && 'monitoring') || 'refresh' }} runs
      for this profile:
    </p>
    <div class="exclusions">
      <div *ngIf="selections['RECORD_CLUSTER'].length">
        <div class="pushdown exclusion">
          <div class="exclusion-label inline"><strong>Global Compliance / Sanctions / PEP Lists</strong></div>
          <div class="exclusion-expiry-header inline"><strong>Expiry</strong></div>
        </div>
        <div
          *ngFor="let exclusion of selections['RECORD_CLUSTER'] | orderBy : ['translation']; index as $index;"
          class="exclusion">
          <input
            name="exclusion_record_cluster-{{$index}}"
            type="checkbox"
            [(ngModel)]="exclusion.selected"
            (change)="updateExclusion(exclusion)" />
          <div class="exclusion-label inline">{{ exclusion.type | translatef }}</div>
          <div class="exclusion-expiry inline" [ngClass]="{'expired': isExpired(exclusion)}">
            {{ exclusion.expiry | dateFormat }}
          </div>
        </div>
      </div>

      <div *ngIf="selections['WATCHLIST'].length">
        <div class="pushdown exclusion">
          <div class="exclusion-label inline"><strong>Global Compliance / Sanctions / PEP Lists</strong></div>
          <div class="exclusion-expiry-header inline"><strong>Expiry</strong></div>
        </div>
        <div
          *ngFor="let exclusion of selections['WATCHLIST'] | orderBy : ['translation']; index as $index;"
          class="exclusion">
          <input
            name="exclusion_watchlist-{{$index}}"
            type="checkbox"
            [(ngModel)]="exclusion.selected"
            (change)="updateExclusion(exclusion)" />
          <div class="exclusion-label inline">{{ exclusion.type | translatef }}</div>
          <div class="exclusion-expiry inline" [ngClass]="{'expired': isExpired(exclusion)}">
            {{ exclusion.expiry | dateFormat }}
          </div>
        </div>
      </div>

      <div *ngIf="selections['REGULATORY'].length">
        <div class="pushdown exclusion">
          <div class="exclusion-label inline"><strong>Regulatory Databases</strong></div>
          <div class="exclusion-expiry-header inline"><strong>Expiry</strong></div>
        </div>
        <div
          *ngFor="let exclusion of selections['REGULATORY'] | orderBy : ['translation']; index as $index;"
          class="exclusion">
          <input
            name="exclusion_regulatory-{{$index}}"
            type="checkbox"
            [(ngModel)]="exclusion.selected"
            (change)="updateExclusion(exclusion)" />
          <div class="exclusion-label inline">{{ exclusion.type | translatef }}</div>
          <div class="exclusion-expiry inline" [ngClass]="{'expired': isExpired(exclusion)}">
            {{ exclusion.expiry | dateFormat }}
          </div>
        </div>
      </div>

      <div *ngIf="selections['WEB_CONTENT'].length">
        <div class="pushdown exclusion">
          <div class="exclusion-label inline"><strong>Adverse Media</strong></div>
          <div class="exclusion-expiry-header inline"><strong>Expiry</strong></div>
        </div>
        <div
          *ngFor="let exclusion of selections['WEB_CONTENT'] | orderBy : ['translation']; index as $index;"
          class="exclusion">
          <input
            name="exclusion_web_content-{{$index}}"
            type="checkbox"
            [(ngModel)]="exclusion.selected"
            (change)="updateExclusion(exclusion)" />
          <div class="exclusion-label inline">{{ exclusion.type | translatef }}</div>
          <div class="exclusion-expiry inline" [ngClass]="{'expired': isExpired(exclusion)}">
            {{ exclusion.expiry | dateFormat }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="exclusions" *ngIf="retrievalError">
    <p>We were unable to retrieve exclusions for this profile.</p>
    <p>{{error}}</p>
  </div>

  <div class="center push-down pushdown clr">
    <button
      type="button"
      (click)="updateExclusions();"
      *ngIf="!retrievalError"
      class="btn btn-primary"
      [disabled]="updating">
      Update
    </button>
    <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
  </div>
</div>
