<div class="top-accordion down" #entityAware="oiqEntityAware" oiq-entity-aware>
  <div class="case-comments" *ngIf="showComments(section?.data?.adjudication)">
    <div class="section-title">Case Comments</div>
    <div class="section-content" oiq-adjudication-aware>
      <ddiq-adjudication-notes
        class="adjudication-notes"
        [type]="entityAware.entityType"
        [entityType]="entityAware.entityType"
        [entityId]="entityAware.entityId"
        [model]="section?.data"
        [label]="'Case Comments'"
        [active]="section?.data.comment">
      </ddiq-adjudication-notes>
    </div>
  </div>
  <div class="section-title">
    <span>Input Data</span>
  </div>
  <div class="section-content">
    <company-input-data-detail *ngIf="isCompany" [seedData]="seedData" [section]="section"></company-input-data-detail>
    <person-input-data-detail *ngIf="isPerson" [seedData]="seedData" [section]="section"></person-input-data-detail>
  </div>
  <div class="additional-input-data-section" *ngIf="hasAdditionalInputData(seedData)">
    <div class="section-title">
      <span>Additional Input Data</span>
    </div>
    <div class="section-content">
      <company-additional-input-data-detail
        *ngIf="isCompany"
        [seedData]="seedData"></company-additional-input-data-detail>
      <person-additional-input-data-detail *ngIf="isPerson" [seedData]="seedData"></person-additional-input-data-detail>
    </div>
  </div>
  <div *ngIf="isProfileCollectionEnabled" class="section-title">
    <span>Visibility ({{seedData?.collections?.length}})</span>
  </div>
  <div *ngIf="isProfileCollectionEnabled">
    <span class="badge" *ngFor="let collection of seedData?.collections; trackBy: trackByIndex"
      >{{collection.name}}</span
    >
    <br class="clr" />
  </div>
</div>
