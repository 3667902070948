import { NgModule } from '@angular/core';
import AuthService from '../../../../../shared/services/common/auth.service';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import AlertService from './alert.service';
import ApiFetchService from './api-fetch.service';
import CheckContentService from './check-content.service';
import ConfigService from '../../../../../shared/services/http/config.service';
import Diff from './diff.service';
import OiqProperties from './oiq-properties.service';
import PartialUtils from './partial-utils.factory';
import ProgressChecker from './progress-checker.service';
import RelationshipService from './relationship-service.factory';
import TypeLookup from './type-lookup.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import TranslateService from './translate.service';
import Entity from './entity.service';
import EntityCache from './entity-cache.service';
import FieldSourceMetadata from './field-source-metadata.service';
import I18nService from './i18n.service';
import PollingService from './polling.service';
import SectionConfigService from './section-config.service';
import EntityPreprocessor from './entity-preprocessor.service';
import CrawlPlanService from './crawlplan.service';
import ProfileDisplayService from './profile-display.service';
import Paginator from './paginator.service';
import OktaAuthService from './okta-auth.service';

@NgModule({
  providers: [
    AlertService,
    ApiFetchService,
    AuthService,
    CheckContentService,
    ConfigService,
    Diff,
    ErrorHandler,
    OiqProperties,
    PartialUtils,
    ProgressChecker,
    RelationshipService,
    TypeLookup,
    UrlGenerator,
    TranslateService,
    Entity,
    EntityCache,
    EntityPreprocessor,
    FieldSourceMetadata,
    I18nService,
    PollingService,
    SectionConfigService,
    CrawlPlanService,
    ProfileDisplayService,
    Paginator,
    OktaAuthService,
  ],
})
export default class CommonServicesModule {}
