<div class="alert alert-warning alert-monitor auto-expand-failure-alert" *ngIf="show()">
  <div class="alert-icon">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
  </div>

  <p class="expand-all-warning-message">
    This profile is too large to be displayed with all its content expanded by default. Please expand the sections
    manually.
  </p>
</div>

<div class="alert alert-warning alert-monitor expand-all-action-failure-alert" *ngIf="profile.isExpandAllActionFailure">
  <div class="alert-icon">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
  </div>

  <p class="expand-all-warning-message">
    This profile is too large to be displayed with all its content expanded. Please expand the sections manually.
  </p>
</div>
