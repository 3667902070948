import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import AdjudicationFeature from './adjudication-feature.service';
import AdjudicationSummaryConfig from './adjudication-summary-config.service';
import AdjudicationSummaryComponent from './adjudication-summary.component';
import AdjudicationSummaryService from './adjudication-summary.service';
import AdjudicationType from './adjudication-type.service';
import ddiqAdjudicationActionsComponent from './ddiq-adjudication-actions.component';
import ddiqAdjudicationNotesComponent from './ddiq-adjudication-notes.component';
import DynamicAdjudicationModalComponent from './ddiq-dynamic-adjudication-modal.component';
import DdiqDynamicAdjudicationComponent from './ddiq-dynamic-adjudication.component';
import DdiqGroupDynamicAdjudicationAware from './ddiq-dynamic-group-adjudication-aware.component';
import DynamicAdjudicationService from './dynamic-adjudication.service';
import OiqAdjudicationAware from './oiq-adjudication-aware.directive';
import OiqDynamicAdjudicationAware from './oiq-dynamic-adjudication-aware.component';
import relatedEntityGroupDynamicAdjudicationAware from './related-entity-group-dynamic-adjudication-aware.component';

@NgModule({
  imports: [CommonModule, PipesModule, NgPipesModule, FormsModule],
  declarations: [
    AdjudicationSummaryComponent,
    OiqDynamicAdjudicationAware,
    OiqAdjudicationAware,
    ddiqAdjudicationActionsComponent,
    ddiqAdjudicationNotesComponent,
    DynamicAdjudicationModalComponent,
    DdiqDynamicAdjudicationComponent,
    DdiqGroupDynamicAdjudicationAware,
    relatedEntityGroupDynamicAdjudicationAware,
  ],
  providers: [
    AdjudicationFeature,
    AdjudicationSummaryConfig,
    AdjudicationSummaryService,
    AdjudicationType,
    DynamicAdjudicationService,
  ],
  exports: [
    AdjudicationSummaryComponent,
    OiqDynamicAdjudicationAware,
    OiqAdjudicationAware,
    ddiqAdjudicationActionsComponent,
    ddiqAdjudicationNotesComponent,
    DynamicAdjudicationModalComponent,
    DdiqDynamicAdjudicationComponent,
    DdiqGroupDynamicAdjudicationAware,
    relatedEntityGroupDynamicAdjudicationAware,
  ],
})
export default class OiqAdjudicationModule {}
