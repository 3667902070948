//@ts-nocheck

import utils from './utils';

var global = window;

global.Exiger = global.Exiger || {};

var Exiger = global.Exiger;

Exiger.utils = utils;

Exiger.AppRedirect = function AppRedirect(location, appRedirectUrl) {
  var AppRedirectObj = {};
  AppRedirectObj.redirect = function () {
    var redirectUrl = Exiger.Utils.getParameter(appRedirectUrl.redirectUrlStr, location.search),
      restResourceUrl = Exiger.Utils.getParameter(appRedirectUrl.restResourceUrlStr, location.search);

    if (redirectUrl) {
      var url = appRedirectUrl.getRedirectUrl(location, redirectUrl, restResourceUrl);
      if (url) {
        location.assign(url);
      }
    }
  };

  return AppRedirectObj;
};

var AppRedirect = Exiger.AppRedirect;

export default AppRedirect;
