import { Directive, HostBinding } from '@angular/core';
import { EmbedService } from './embed.service';

@Directive({
  selector: '[embed]',
})
export class EmbedDirective {
  constructor(private embedService: EmbedService) {}

  @HostBinding('class.embedded-mode')
  public get isEmbedded() {
    return this.embedService.isEmbedded();
  }
}
