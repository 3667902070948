<section class="additional-item">
  <div *ngFor="let previousAddress of previousAddresses; let $index = index;">
    <button type="button" class="fa fa-trash trash" (click)="removePreviousAddress($index)"></button>
    <ddiq-text
      name="prev-streetAddress-{{$index}}"
      [label]="'Street Address' | translatef"
      size="half"
      [(model)]="previousAddress.address.streetAddress"></ddiq-text>
    <!-- SS: simplifying assumption: we only care about building names in places we care about district (e.g. the UK) -->
    <div *ngIf="districtEnabled">
      <ddiq-text
        name="prev-buildingName-{{$index}}"
        [label]="'Building Name' | translatef"
        size="quarter"
        [(model)]="previousAddress.address.buildingName"></ddiq-text>
      <ddiq-text
        name="prev-district-{{$index}}"
        [label]="'District' | translatef"
        size="quarter"
        [(model)]="previousAddress.address.district"></ddiq-text>
    </div>
    <br class="clr" />

    <ddiq-text
      name="prev-city-{{$index}}"
      [label]="'City/Town' | translatef"
      size="quarter"
      [(model)]="previousAddress.address.city"></ddiq-text>
    <ddiq-text
      name="prev-stateOrProvince-{{$index}}"
      [label]="'State/Province/Region' | translatef"
      size="quarter"
      [(model)]="previousAddress.address.stateOrProvince"></ddiq-text>
    <ddiq-text
      name="prev-zipOrPostalCode-{{$index}}"
      [label]="'ZIP/Postal Code' | translatef"
      size="quarter"
      [(model)]="previousAddress.address.zipOrPostalCode"></ddiq-text>
    <ddiq-select
      name="prev-country-{{$index}}"
      [label]="'Country' | translatef"
      size="quarter"
      [(model)]="previousAddress.address.country"
      [options]="countries"
      [prompt]="'Choose country' | translatef">
    </ddiq-select>
    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary" (click)="addPreviousAddress()">Add Previous Address</button>
</section>
