import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-actions',
  templateUrl: './ddiq-actions.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class ActionsComponent {
  @Input() prompt;
  isOpen: boolean = false;

  constructor() {}
}
