import { Directive, ElementRef, HostListener, Input, DoCheck } from '@angular/core';
import $ from 'jquery';
import { ProfileScrollService } from '../profile/profile-scroll.service';
import { ProfileStylingService } from '../profile/profile-styling.service';

@Directive({
  selector: '[scrollto]',
})
export default class ScrollToDirective implements DoCheck {
  private readonly nativeElement: any;
  private previousTargetScrollTo: number;
  @Input() href: string;
  @Input('offset-adjust') offsetAdjust: any = 0;
  @HostListener('click', ['$event']) async onClick(e) {
    e.preventDefault();

    if (!this.href || this.href === '#') {
      return;
    }

    const element: HTMLElement = await this.profileScroll.scrollTo(this.href);
    this.profileStyling.updateNavigationClasses(element);
  }

  className = 'filtered';
  attr = 'title';
  tpl = 'Filtered ';

  constructor(
    private elementRef: ElementRef,
    private profileScroll: ProfileScrollService,
    private profileStyling: ProfileStylingService
  ) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  ngDoCheck() {
    const currentTargetScrollTo = $(`[id="${this.href}"]`).length;
    if (currentTargetScrollTo !== this.previousTargetScrollTo) {
      if (currentTargetScrollTo === 0) {
        $(this.nativeElement).addClass(this.className);
        this.addRemoveFilteredTitle($(this.nativeElement), true);
      } else {
        $(this.nativeElement).removeClass(this.className);
        this.addRemoveFilteredTitle($(this.nativeElement));
      }
      this.previousTargetScrollTo = currentTargetScrollTo;
    }
  }

  addRemoveFilteredTitle(elm, isFiltered?) {
    if (isFiltered) {
      elm.parent().attr(this.attr, this.tpl + (elm.attr(this.attr) || '') + ' from visibility');
    } else {
      elm.parent().attr(this.attr, (elm.attr(this.attr) || '').replace(this.tpl, ''));
    }
  }
}
