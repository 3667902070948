import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class CompanyBuildService {
  companyToBuild;
  parentUrl;

  queueCompanyToBuild(company) {
    this.companyToBuild = company;
  }

  getCompanyToBuild() {
    let company = this.companyToBuild;
    this.companyToBuild = null;
    return company;
  }

  setParentUrl(originUrl) {
    this.parentUrl = originUrl;
  }

  getAndClearParentUrl() {
    let toReturn = this.parentUrl;
    this.parentUrl = null;
    return toReturn;
  }
}
