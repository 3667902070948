<section>
  <p>Please enter your username to reset your account, and we will send an email with further instructions.</p>
  <form name="resetForm" #resetForm="ngForm" autocomplete="off" novalidate>
    <ddiq-text name="userName" label="Username" size="full" [(model)]="userName" required> </ddiq-text>

    <img
      class="captcha-image"
      [src]="captchaSrc"
      alt="Captcha Image"
      title="Verify you're a human"
      *ngIf="!isFetchingCaptcha" />
    <div class="text-center">
      <i class="captcha-spinner fa fa-spinner fa-spin fa-lg" aria-hidden="true" *ngIf="isFetchingCaptcha"></i>
    </div>

    <ddiq-text name="captchaText" label="Enter the image text" [(model)]="captchaText" size="full" required>
    </ddiq-text>

    <p class="error-message" *ngIf="isInError">Invalid image text entered. Please try again.</p>
    <div class="block center">
      <button
        id="login-password-reset-button"
        class="btn btn-primary btn-block"
        type="submit"
        [disabled]="(!userName && !captchaText) || isRequesting"
        (click)="requestPasswordReset()">
        Reset Password
        <i class="submit-spinner fa fa-spinner fa-spin fa-lg" aria-hidden="true" *ngIf="isRequesting"></i>
      </button>
      <button id="login-password-reset-back-button" class="btn btn-default btn-block" (click)="onCancelFn($event)">
        Cancel
      </button>
    </div>
  </form>
</section>
