<div
  class="oiq-code-schema-row"
  *ngIf="field.companyClassification && field.companyClassification.codeSchema"
  role="row">
  <div
    class="oiq-code-schema-hd oiq-code-schema-classification-code-schema"
    role="columnheader"
    [textContent]="'Primary ' + field.companyClassification.codeSchema + ':'"></div>
  <div class="oiq-code-schema-cell" role="gridcell">
    <span
      class="oiq-code-schema-classification-code"
      *ngIf="field.companyClassification.code"
      [textContent]="field.companyClassification.code"></span>
    <span
      class="oiq-code-schema-classification-description"
      *ngIf="field.companyClassification.description"
      [textContent]="' - ' + field.companyClassification.description"></span>
  </div>
</div>
<div class="oiq-code-schema-row-group" *ngFor="let item of field.companyClassificationsByCodeSchema | keyvalue">
  <div class="oiq-code-schema-row" role="row">
    <div
      class="oiq-code-schema-hd oiq-code-schema-additional-classification-code-schema"
      role="columnheader"
      [textContent]="item.key + ':'"></div>
    <div class="oiq-code-schema-cell" role="gridcell">
      <ul class="oiq-code-schema-company-classifications">
        <li *ngFor="let companyClassification of item.value">
          <span
            class="oiq-code-schema-additional-classification-code"
            *ngIf="companyClassification.code"
            [textContent]="companyClassification.code"></span>
          <span
            class="oiq-code-schema-additional-classification-description"
            *ngIf="companyClassification.description"
            [textContent]="' - ' + companyClassification.description"></span>
        </li>
      </ul>
    </div>
  </div>
</div>
