import { Component, DoCheck } from '@angular/core';
import OiqCollapsibleToggle from './oiq-collapsible-toggle.directive';

@Component({
  selector: 'oiq-collapsible-toggle-icon',
  template: `<i
    class="fa"
    [ngClass]="{ 'fa-chevron-up': collapsibleIsOpen, 'fa-chevron-down': !collapsibleIsOpen }"></i>`,
})
export default class OiqToggleIconComponent implements DoCheck {
  collapsibleIsOpen: boolean;

  constructor(private oiqCollapsibleToggle: OiqCollapsibleToggle) {}

  ngDoCheck(): void {
    this.collapsibleIsOpen = this.oiqCollapsibleToggle.isCollapsibleOpen;
  }
}
