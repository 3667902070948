import { Component, OnInit, OnDestroy } from '@angular/core';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import Entity from '../common-services/entity.service';
import I18nService from '../common-services/i18n.service';
import OiqProperties from '../common-services/oiq-properties.service';
import RelationshipService from '../common-services/relationship-service.factory';
import RelatedEntityProcessor from '../themed-entity/related-entity-processor.service';
import RelationshipFilter from '../themed-entity/relationship-filter.service';

@Component({
  selector: 'ddiq-related-locations',
  templateUrl: './ddiq-related-locations.component.tpl.html',
})
export default class DdiqRelatedLocationsComponent implements OnInit, OnDestroy {
  locations: any = {};
  listAlertCount: number;
  locationProcessor: any;

  private filterObserver;
  private alertsEnabled: boolean = false;
  private isRelatedEntityAdjudicationEnabled: boolean = false;

  constructor(
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private i18nService: I18nService,
    private relatedEntityProcessor: RelatedEntityProcessor,
    private relationshipService: RelationshipService,
    private adjudicationFeature: AdjudicationFeatureService,
    private relationshipFilter: RelationshipFilter
  ) {}

  ngOnInit() {
    this.locationProcessor = this.relatedEntityProcessor.for('location');
    this.alertsEnabled = this.oiqProperties.subPropertyAlertsEnabled;
    this.isRelatedEntityAdjudicationEnabled =
      this.adjudicationFeature.isRelatedEntityAdjudicationEnabled() && this.entity.isAdjudicatableByUser();

    this.filterObserver = () => this.initLocations(false);
    this.relationshipFilter.registerAfterFilterObserver(this.filterObserver);

    this.initLocations();
  }

  ngOnDestroy() {
    this.relationshipFilter.unRegisterAfterFilterObserver(this.filterObserver);
  }

  refresh() {
    this.initLocations(true);
  }

  initLocations(forceReload?) {
    if (forceReload) {
      this.relationshipService.locations(this.entity.getType(), this.entity.getId(), forceReload).then(() => {
        this.setLocationsData();
      });
      return;
    }

    this.setLocationsData();
  }

  setLocationsData() {
    let locations;
    if (!this.entity.isDiff()) {
      locations = this.relationshipService.getLocations();
    } else {
      locations = this.relationshipService.getLocationsDiff();
    }
    return this.processLocations(locations);
  }

  processLocations(locs) {
    locs = this.locationProcessor
      .processEntities(locs)
      .map((location) => this.expandCountryNames(location))
      .map((location) => this.disableWorldPresenceProfiles(location));

    this.listAlertCount = this.alertsEnabled ? this.locationProcessor.countAlerts(locs) : 0;

    this.locations = this.bucketLocations(locs);
    this.locations.count =
      this.locations.submitted.length + this.locations.discovered.length + this.locations.referenced.length;

    this.relationshipService.setLocationCount(this.locations.count);

    return locs;
  }

  expandCountryNames(location) {
    const country = location.base.address.country;
    location.base.address.country = this.i18nService.matchCountryName(country) || country;

    return location;
  }

  disableWorldPresenceProfiles(location) {
    if (this.locationProcessor.isRelationship(location, 'WorldPresence')) {
      location.base.crawlStatus = 'UNAVAILABLE';
    }

    return location;
  }

  bucketLocations(locs) {
    const officeAtRel = 'OfficeAt',
      associatedWithRel = 'AssociatedWith';

    return this.locationProcessor.getBucketedEntities(locs, [
      {
        bucket: 'submitted',
        matchEntity: (entity) => this.locationProcessor.isSubmitted(entity),
        onBucketed: this.onBucketed(associatedWithRel, 'SUBMITTED'),
      },
      {
        bucket: 'discovered',
        matchEntity: (entity) => {
          return this.locationProcessor.isRelationship(entity, officeAtRel);
        },
        onBucketed: this.onBucketed(officeAtRel),
      },
      {
        bucket: 'referenced',
        matchEntity: (entity) => {
          return (
            this.locationProcessor.isRelationship(entity, associatedWithRel) &&
            !this.oiqProperties.relationships.disableAssociatedLocations
          );
        },
        onBucketed: this.onBucketed(associatedWithRel),
      },
    ]);
  }

  onBucketed(relationshipName, sourceInfoName = relationshipName) {
    return (entity) => {
      entity.relationship = this.locationProcessor.getRelationship(entity.relationships, relationshipName);
      entity.escalated = entity.relationship?.escalated;
      this.locationProcessor.addSourceInfo(entity, sourceInfoName);
      this.relationshipFilter.applyFilters(entity);
    };
  }
}
