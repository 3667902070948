import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class NavTrackingService {
  activeLabel;

  constructor() {}

  isEventActive(eventLabel, sectionId) {
    let active = this.activeLabel || { label: {} };
    return eventLabel.labelKey === active.label.labelKey && sectionId === active.sectionId;
  }

  setEventActive(eventLabel, sectionId) {
    this.activeLabel = {
      sectionId: sectionId,
      label: eventLabel,
    };
  }
}
