import { Component, Input, OnChanges } from '@angular/core';
import AdjudicationFeatureService from './adjudication-feature.service';
import AdjudicationSummary from './adjudication-summary.service';

@Component({
  selector: 'adjudication-summary',
  templateUrl: './adjudication-summary.tpl.html',
})
export default class AdjudicationSummaryComponent implements OnChanges {
  @Input() summarize: any;

  stats = [];
  totals: any;

  constructor(
    private adjudicationSummary: AdjudicationSummary,
    private adjudicationFeature: AdjudicationFeatureService
  ) {}

  ngOnChanges(changes) {
    const stats = [],
      queries = getQueries(changes);

    queries.forEach((query) => {
      getCriteriaFrom(query).forEach((criteria) => {
        const summarized = this.adjudicationSummary.summarize(query.section, criteria.classification, query.kind);

        stats.push(makeResults(query, criteria, summarized));
      });
    });

    this.stats = stats;
    this.totals = calculateTotals(this.stats);
  }

  isConfirmEnabled() {
    return this.adjudicationFeature.isConfirmActionEnabled();
  }
}

function getQueries(changes) {
  return changes.summarize.currentValue || [];
}

function getCriteriaFrom(query) {
  let criteria;

  if (query.criteria.length === 0) {
    criteria = [{ classification: undefined }];
  } else {
    criteria = query.criteria;
  }
  return criteria;
}

function getClassification(query, criteria) {
  return (query.dictionary || {})[criteria.classification] || criteria.classification || query.displayAs;
}

function makeResults(query, criteria, summarized) {
  const classification = getClassification(query, criteria);
  return Object.assign({ classification: classification }, summarized);
}

function calculateTotals(stats) {
  const totals = {
    totalHits: 0,
    totalAutoAdjudicatedCount: 0,
    totalManuallyAdjudicatedCount: 0,
    totalRemainingCount: 0,
    totalConfirmedCount: 0,
    totalEscalatedCount: 0,
    totalFilteredCount: 0,
  };

  stats.forEach(function (stat) {
    totals.totalHits += stat.totalHits;
    totals.totalAutoAdjudicatedCount += stat.autoAdjudicatedCount;
    totals.totalManuallyAdjudicatedCount += stat.manuallyAdjudicatedCount;
    totals.totalRemainingCount += stat.remainingCount;
    totals.totalConfirmedCount += stat.confirmedCount;
    totals.totalEscalatedCount += stat.escalatedCount;
    totals.totalFilteredCount += stat.filteredCount;
  });

  return totals;
}
