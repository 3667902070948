import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'filterRegulatorySubSection',
})
export default class FilterRegulatorySubSectionPipe implements PipeTransform {
  transform(subSections, showOshaSafety, showGovProcurement) {
    let filteredSubSection;
    filteredSubSection = (subSections || []).filter((item) => {
      if (item === 'oshaSafetyInspections') {
        return showOshaSafety;
      } else if (item === 'governmentProcurement') {
        return showGovProcurement;
      }

      return true;
    });
    return filteredSubSection;
  }
}
