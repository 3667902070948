<a
  class="selected-article-download-link"
  [ngClass]="{ 'selected-article-download-link-disabled': !hasArticlesSelected() }"
  (click)="click()"
  *ngIf="isEnabled"
  title="{{!hasArticlesSelected() ? 'No articles have been selected' : ''}}">
  <div *ngIf="!(transcludedContent.innerHTML||'').trim()">Generate article PDF</div>
  <div #transcludedContent>
    <ng-content></ng-content>
  </div>
</a>
