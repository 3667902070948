<div #entityAware="oiqEntityAware" oiq-entity-aware>
  <ng-container *ngFor="let group of groups.list; trackBy:classification">
    <article
      id="{{idPrefix}}_{{group.classification | validateHtmlIds}}"
      class="panel oiq-watch-lists oiq-watch-lists-{{group.classification}} discovery-group"
      *ngIf="group.hasDiscoveries">
      <div data-oiq-collapsible-ctrl [expandAll]="expandAll || group.isEventGroupOpen">
        <header class="panel-header oiq-watch-lists-header">
          <oiq-group-dynamic-adjudication-aware
            [adjudicationType]="adjudicationType"
            [section]="idPrefix"
            [classification]="group.classification">
          </oiq-group-dynamic-adjudication-aware>
          <risk-in-classification
            [classification]="group.classification"
            [entityId]="entityAware.entityId"
            [riskType]="'WATCHLIST'"></risk-in-classification>
          <h3 class="panel-title oiq-watch-lists-title">{{group.title}}</h3>
          <div class="panel-header-right">
            <ddiq-sort-buttons
              *ngIf="group.hasMoreEntries"
              class="pull-left btn-sort-group-margin"
              [entities]="group.discoveries"
              [orderBy]="'association, adjudication'"
              (onClick)="sort({ sortName: $event.btnName, eventGroup: group})"
              [activeBtn]="group.activeSortButtons">
            </ddiq-sort-buttons>
            <button class="btn btn-sm btn-default" *ngIf="group.hasMoreEntries" data-oiq-collapsible-toggle>
              <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
              View all {{(group.discoveries | moreEntries).length + 1 }} events
            </button>
          </div>
        </header>

        <section class="panel-body">
          <article
            class="oiq-watch-lists-featured-incident"
            *ngFor="let featured of group.discoveries | firstUnfilteredDiscovery">
            <ddiq-watch-list
              [watchList]="featured"
              [entityId]="entityAware.entityId"
              [entityType]="entityAware.entityType"
              [isAdjudicateable]="entityAware.isAdjudicatable"
              [displayCreatedDate]="isDisplayCreatedDate">
            </ddiq-watch-list>
          </article>

          <article class="oiq-watch-lists-more-entries" *ngIf="group.hasMoreEntries">
            <ddiq-collapsible>
              <ddiq-watch-list
                class="oiq-watch-lists-more-entry discovery-group"
                *ngFor="let entry of group.discoveries | moreEntries; trackBy: id"
                [watchList]="entry"
                [entityId]="entityAware.entityId"
                [entityType]="entityAware.entityType"
                [isAdjudicateable]="entityAware.isAdjudicatable"
                [displayCreatedDate]="isDisplayCreatedDate">
              </ddiq-watch-list>
            </ddiq-collapsible>
          </article>
        </section>
      </div>
    </article>
  </ng-container>
</div>

<p *ngIf="groups.list.length === 0" class="oiq-watch-lists-msg-no-results">No results discovered</p>

<p *ngIf="groups.showAllFilteredMessage" class="oiq-watch-lists-msg-all-filtered">All results filtered</p>
