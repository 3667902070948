<div *ngIf="model.adjudication && model.adjudication.history.length > 0">
  <div
    class="adjudication-entry"
    *ngFor="let adjudicationEntry of model.adjudication.history | orderBy: 'adjudicatedDate'; let $last = last"
    [ngClass]="{'last': $last}">
    <div class="adjudication-user">
      <span>
        <span class="username" [ngClass]="{auto: adjudicationEntry.auto}"> {{adjudicationEntry.userFullName}} </span>
        ({{adjudicationEntry.adjudicatedDate | date : 'MMM d, y HH:mm:ss'}})
      </span>
    </div>

    <div class="adjudication-status" [ngSwitch]="adjudicationEntry.action">
      <div *ngSwitchCase="'REMOVE'" class="clearfix">
        <b>{{adjudicationEntry.action | translatef}}:</b><br />
        <ul class="adjudication-reasons">
          <li *ngFor="let reason of getReasons(adjudicationEntry)">
            <em>{{reason | translatef}}</em>
          </li>
        </ul>
      </div>

      <div *ngSwitchCase="'CONFIRM'" class="clearfix">
        <b>{{adjudicationEntry.action | translatef}}:</b><br />
        <ul class="adjudication-reasons">
          <li *ngFor="let reason of getReasons(adjudicationEntry)">
            <em>{{reason | translatef}}</em>
          </li>
        </ul>
      </div>

      <div *ngSwitchCase="'ESCALATE'" class="clearfix">
        <b>{{adjudicationEntry.action | translatef}}:</b><br />
        <ul class="adjudication-reasons">
          <li *ngFor="let reason of getReasons(adjudicationEntry)">
            <em>{{reason | translatef}}</em>
          </li>
        </ul>
      </div>

      <div *ngSwitchCase="'ADJUDICATE'" class="clearfix">
        <b>{{adjudicationEntry.action | translatef}}:</b><br />
        <ul class="adjudication-reasons">
          <li *ngFor="let reason of getReasons(adjudicationEntry)">
            <em>{{reason | translatef}}</em>
          </li>
        </ul>
      </div>

      <div *ngSwitchCase="'AUTO_ADJUDICATE'" class="clearfix">
        <b>{{adjudicationEntry.action | translatef}}:</b><br />
        <ul class="adjudication-reasons">
          <li *ngFor="let reason of getReasons(adjudicationEntry)">
            <em>{{reason | translatef}}</em>
          </li>
        </ul>
      </div>

      <div *ngSwitchCase="'COMMENT'" class="clearfix"></div>

      <div *ngSwitchDefault>
        <b>{{adjudicationEntry.action | translatef}}</b>
      </div>

      <div class="adjudication-comments" *ngIf="adjudicationEntry.comment">
        <ul class="adjudication-comments clearfix">
          <li *ngFor="let comment of getComments(adjudicationEntry)">{{comment}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="comment-box" *ngIf="isEditingComment">
  <textarea rows="5" [(ngModel)]="tempModel"></textarea>

  <div *ngIf="isEditingComment" class="text-right">
    <button class="btn btn-primary btn-sm" (click)="save()" [disabled]="!tempModel">OK</button>
    <button class="btn btn-default btn-sm" (click)="cancel()">Cancel</button>
  </div>
</div>
