import { Injectable } from '@angular/core';
import StructuredData from './structured-data.service';

@Injectable({
  providedIn: 'root',
})
export default class StructuredDataUtils {
  constructor(private structuredData: StructuredData) {}

  // returns object which self-updates whenever Incidents.filter is called
  observedCount(sectionName) {
    const observedCount = {
      filtered: undefined,
      unfiltered: undefined,
      filteredEscalated: undefined,
      unfilteredEscalated: undefined,
    };

    this.structuredData.registerFilterObserver(() => this.count(observedCount, sectionName));

    this.count(observedCount, sectionName);

    return observedCount;
  }

  private count(observedCount, sectionName) {
    let filteredCount = 0,
      unfilteredCount = 0,
      filteredEscalatedCount = 0,
      unfilteredEscalatedCount = 0;

    this.structuredData.applyToStructuredData((structuredData) => {
      unfilteredCount++;
      if (!structuredData.filtered) {
        filteredCount++;
      }
      if (structuredData.escalated) {
        unfilteredEscalatedCount++;
        if (!structuredData.filtered) {
          filteredEscalatedCount++;
        }
      }
    }, sectionName);

    observedCount.filtered = filteredCount;
    observedCount.unfiltered = unfilteredCount;
    observedCount.filteredEscalated = filteredEscalatedCount;
    observedCount.unfilteredEscalated = unfilteredEscalatedCount;
  }
}
