import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-phone-number-form',
  templateUrl: './ddiq-phone-number-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class PhoneNumberFormComponent implements OnInit {
  @Input() phoneNumbers;

  phoneLabels: Array<string> = ['work', 'mobile', 'home', 'other'];
  constructor() {}

  ngOnInit() {
    if (!this.phoneNumbers || this.phoneNumbers.length === 0) {
      this.phoneNumbers = [{ label: 'other' }];
    }
  }

  addPhoneNumber() {
    this.phoneNumbers.push({ label: 'other' });
  }

  removePhoneNumber(index) {
    this.phoneNumbers.splice(index, 1);
  }
}
