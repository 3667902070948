import { Injectable, Injector } from '@angular/core';
import CookieService from '../user/cookie.service';
import ApiFetchService from './api-fetch.service';
import { MonitorFilterFieldsConfigs } from '../monitor/monitor';

@Injectable({
  providedIn: 'root',
})
export default class OiqProperties {
  apiPropertiesPromise;
  project: any;
  languageSelectionDefault: string;
  languageSelectionEnabled: boolean;
  brandingUrl: any;
  defaultCountry: string;
  preferredCountries: any[];
  eventsClassification: boolean;
  showTranslatedSnippets: boolean;
  mappingEnabled: boolean;
  pdfEnabled: boolean;
  pdfShowRequestedBy: boolean;
  pdfExpandExceptDedupesOption: boolean;
  pdfExcludeAutoAdjudicated: boolean;
  pdfExcludeCaseComments: boolean;
  pdfGenerationEngine: string;
  didYouMeanOnSubmit: boolean;
  nameSuggestionOnSubmit: boolean;
  dotSubmissionEnabled: boolean;
  businessCategoryEnabled: boolean;
  districtEnabled: boolean;
  extendedSubmissionFields: boolean;
  franchiseeEnabled: boolean;
  locationsOfInterest: any[];
  mapElements: any[];
  dateFormat: string;
  markup: any;
  showCorrelationDetails: boolean;
  searchCorrelationId: boolean;
  adjudicationEnabled: boolean;
  adjudicationCommentsEnabled: boolean;
  bulkAdjudicationEnabled: boolean;
  autoAdjudicationEnabled: boolean;
  adjudicateRelatedEntitiesEnabled: boolean;
  hideAdjudicationExclusion: boolean;
  hideDiscoveredPeople: boolean;
  hideRegulatoryDatabases: boolean;
  oshaInRegulatorySection: boolean;
  initialFilterThresholdLevel: any;
  profileFilters: any;
  dateFilterInitialYears: any;
  dateFilterMaximumYears: any;
  relationships: any;
  defaultPageLength: number;
  unassociatedContentEnabled: boolean;
  subCompanyAlertsEnabled: boolean;
  subPersonAlertsEnabled: boolean;
  subPropertyAlertsEnabled: boolean;
  previewEnabled: boolean;
  adjudicationConfirmedReasons: any;
  adjudicationEscalatedReasons: any;
  adjudicationRemoveReasons: any;
  generateOutputEnabled: boolean;
  isDisplayCorporate: boolean;
  isDisplayBasicInfo: boolean;
  isDisplayRegulatory: boolean;
  isDisplayAdverse: boolean;
  isDisplayLegal: boolean;
  isDisplayReferences: boolean;
  isDisplayNoteworthy: boolean;
  isDisplayExpandAll: boolean;
  expandAllLimit: number;
  monitoringEnabled: boolean;
  monitoringFrequencies: any;
  monitorPreselected: boolean;
  monitoringPreselectedCategories: string[];
  defaultMonitoringFrequency: any;
  profileStatuses: any;
  profileStatusesFilter: any;
  profileStatusEnabled: boolean;
  profileStatusAdjudicationActions: string[];
  profileStatusConfidenceThreshold: any;
  profileStatusAdjudicationRequiredStatuses: string[];
  profileStatusAdjudicationRequiredEnabled: boolean;
  profileRefreshEnabled: boolean;
  profileRefreshFrequencies: any;
  avoidLoginPage: boolean;
  profileScoreEnabled: boolean;
  riskInClassification: boolean;
  articleCacheEnabled: boolean;
  globalCompanyEnabled: boolean;
  globalPersonEnabled: boolean;
  hiddenAdjudicationActions: any;
  adjudicationConfirmActionEnabled: boolean;
  adjudicationStarActionEnabled: boolean;
  existingProfileWarningEnabled: boolean;
  existingProfileWarningUseSubmissionId: boolean;
  existingProfileWarningUseSubmissionIdOnly: boolean;
  submissionIdFieldRequired: boolean;
  sexFieldRequired: boolean;
  registrationNumberEnabled: boolean;
  phoneAndFaxEnabled: boolean;
  personSubmissionEnabled: boolean;
  idSubmissionConfigs: any;
  partsEnabled: boolean;
  countryFieldRequired: boolean;
  crawlPlans: any[];
  isProfileCollectionEnabled: boolean;
  crawlPlanPreselected: boolean;
  adjudicationSupportEnabled: boolean;
  vesselEnabled: boolean;
  lookbackEnabled: boolean;
  displayAuditErrorsEnabled: boolean;
  displayAuditLinksEnabled: boolean;
  externalReferenceCodeEnabled: boolean;
  externalReferenceCodeRequired: boolean;
  reqOrgEnabled: boolean;
  pdfSelectedArticlesEnabled: boolean;
  displayAdjudicationSummary: boolean;
  adjudicationSummaryConfig: any;
  monitoringDefaultExclusionPeriod: number;
  profileRefreshDefaultExclusionPeriod: number;
  monitoringExclusionsEnabled: boolean;
  profileRefreshExclusionsEnabled: boolean;
  isScreenIqEnabled: boolean;
  isCorporateGraphEnabled: boolean;
  corporateGraphNodeHighlight: string;
  defaultOwnershipFilterPercentage: number;
  isGovIdSearchEnabled: boolean;
  isPersonDisplayIdentificationsEnabled: boolean;
  isSsoEnabled: boolean;
  isSloEnabled: boolean;
  isOAuthEnabled: boolean;
  isElasticSearchEnabled: boolean;
  apiProperties: any;
  sanctionedCountries: string[];
  highRiskCountries: string[];
  localCountries: string[];
  isCageButtonEnabled: boolean;
  integrityReportUrlTemplate: string;
  auditReportUrlTemplate: string;
  monitoringCustomFilters: Array<MonitorFilterFieldsConfigs>;
  hiddenRoutes: string[];
  embeddedModeEnabled: boolean;
  mrzEnabled: boolean;

  constructor(private injector: Injector, private cookieService: CookieService) {
    // working around cyclical dependencies when
    // ApiFetchService, OiqProperties, and the HttpClient are required
    // as could be the case when using these services in Http Interceptors.
    this.apiPropertiesPromise = new Promise((resolve) => {
      setTimeout(() => {
        this.injector
          .get(ApiFetchService)
          .properties()
          .then((apiProps) => resolve(this.mapProperties(apiProps)));
      }, 0);
    });
  }

  mapProperties(apiProps) {
    this.project = {
      isDdiq: true,
      isInsure: false,
    };

    this.languageSelectionDefault = apiProps['platform.languageSelection.default'] || 'en-Latn';
    this.languageSelectionEnabled = apiProps['platform.languageSelection.enabled'] === 'true';
    this.brandingUrl = apiProps['ui.branding.url'];
    this.defaultCountry = apiProps['ui.defaultCountry'];
    this.preferredCountries = [];
    this.eventsClassification = apiProps['ui.features.eventsClassificationEnabled'] === 'true';
    this.showTranslatedSnippets = apiProps['ui.showTranslatedSnippets'] === 'true';
    this.mappingEnabled = apiProps['ui.features.mapping.enabled'] === 'true';
    this.pdfEnabled = apiProps['ui.features.pdfEnabled'] === 'true';
    this.pdfShowRequestedBy = apiProps['ui.features.pdfReport.showRequestedByField'] === 'true';
    this.pdfExpandExceptDedupesOption = apiProps['ui.pdfExpandExceptDedupesOption'] === 'true';
    this.pdfExcludeAutoAdjudicated = apiProps['ui.pdfExcludeAutoAdjudicated'] === 'true';
    this.pdfExcludeCaseComments = apiProps['ui.pdfExcludeCaseComments'] === 'true';
    this.pdfGenerationEngine = apiProps['ui.pdfGenerationEngine'];
    this.didYouMeanOnSubmit = apiProps['ui.features.didYouMeanOnSubmit'] === 'true';
    this.nameSuggestionOnSubmit = apiProps['ui.features.nameSuggestionOnSubmit'] === 'true';
    this.dotSubmissionEnabled = apiProps['ui.features.dot.enabled'] === 'true';
    this.businessCategoryEnabled = apiProps['ui.features.businessCategoryEnabled'] === 'true';
    this.districtEnabled = apiProps['ui.features.districtEnabled'] === 'true';
    this.extendedSubmissionFields = apiProps['ui.features.extendedSubmissionFields'] === 'true';
    this.franchiseeEnabled = apiProps['ui.features.franchiseeEnabled'] === 'true';
    this.locationsOfInterest = [];
    this.mapElements = [];
    this.dateFormat = apiProps['ui.dateFormat'] === 'US' ? 'US' : 'ISO';
    this.markup = apiProps['ui.features.markup'];
    this.showCorrelationDetails = apiProps['ui.showCorrelationDetails'] === 'true';
    this.searchCorrelationId = apiProps['ui.searchCorrelationId'] === 'true';
    this.adjudicationEnabled = apiProps['ui.adjudicationEnabled'] === 'true';
    this.adjudicationCommentsEnabled = apiProps['ui.adjudicationCommentsEnabled'] === 'true';
    this.bulkAdjudicationEnabled = apiProps['ui.bulkAdjudicationEnabled'] === 'true';
    this.autoAdjudicationEnabled = apiProps['oiq.adjudication.auto'] === 'true';
    this.adjudicateRelatedEntitiesEnabled = apiProps['ui.features.adjudicateRelatedEntitiesEnabled'] === 'true';
    this.hideAdjudicationExclusion = apiProps['ui.features.hideAdjudicationExclusion'] === 'true';
    this.hideDiscoveredPeople = apiProps['ui.features.hideDiscoveredPeople'] === 'true';
    this.hideRegulatoryDatabases = apiProps['ui.features.hideRegulatoryDatabases'] === 'true';
    this.oshaInRegulatorySection = apiProps['ui.features.oshaInRegulatorySection'] === 'true';
    this.initialFilterThresholdLevel = apiProps['ui.initialFilterThresholdLevel'];

    this.profileFilters = {
      isShowConfirmedOnlyOn: apiProps['ui.features.profileFilters.isShowConfirmedOnlyOn'] === 'true',
      isHideConfirmedOn: apiProps['ui.features.profileFilters.isHideConfirmedOn'] === 'true',
      isShowEscalatedOnlyOn: apiProps['ui.features.profileFilters.isShowEscalatedOnlyOn'] === 'true',
      isHideEscalatedOn: apiProps['ui.features.profileFilters.isHideEscalatedOn'] === 'true',
      isShowAdjudicatedOnlyOn: apiProps['ui.features.profileFilters.isShowAdjudicatedOnlyOn'] === 'true',
      isHideAdjudicatedOn: apiProps['ui.features.profileFilters.isHideAdjudicatedOn'] === 'true',
    };

    this.dateFilterInitialYears = apiProps['ui.dateFilterInitialYears'];
    this.dateFilterMaximumYears = apiProps['ui.dateFilterMaximumYears'];
    this.relationships = {
      disableAssociatedPeople: apiProps['ui.features.relationships.disableAssociatedPeople'] === 'true',
      disableAssociatedLocations: apiProps['ui.features.relationships.disableAssociatedLocations'] === 'true',
      disableAssociatedCompanies: apiProps['ui.features.relationships.disableAssociatedCompanies'] === 'true',
    };
    this.defaultPageLength = apiProps['ui.config.defaultPageLength'] || 25;
    this.unassociatedContentEnabled = apiProps['ui.enableNotAssociatedContent'] === 'true';

    this.subCompanyAlertsEnabled = apiProps['ui.features.subCompanyAlertsEnabled'] === 'true';
    this.subPersonAlertsEnabled = apiProps['ui.features.subPersonAlertsEnabled'] === 'true';
    this.subPropertyAlertsEnabled = apiProps['ui.features.subPropertyAlertsEnabled'] === 'true';
    this.previewEnabled = apiProps['platform.whiteboard.previewEnabled'] === 'true';

    if (typeof apiProps['ui.features.locationOfInterest.types'] !== 'undefined') {
      this.locationsOfInterest = apiProps['ui.features.locationOfInterest.types'].split(',');
    }

    if (apiProps['ui.adjudicationConfirmedReasons']) {
      this.adjudicationConfirmedReasons = apiProps['ui.adjudicationConfirmedReasons'].split(',');
    } else {
      this.adjudicationConfirmedReasons = [];
    }

    if (apiProps['ui.adjudicationEscalatedReasons']) {
      this.adjudicationEscalatedReasons = apiProps['ui.adjudicationEscalatedReasons'].split(',');
    } else {
      this.adjudicationEscalatedReasons = [];
    }

    if (typeof apiProps['ui.adjudicationRemoveReasons'] !== 'undefined') {
      this.adjudicationRemoveReasons = apiProps['ui.adjudicationRemoveReasons'].split(',');
    } else if (
      typeof apiProps['ui.adjudicationRemoveReasons.company'] !== 'undefined' &&
      typeof apiProps['ui.adjudicationRemoveReasons.person'] !== 'undefined'
    ) {
      this.adjudicationRemoveReasons = {
        company: apiProps['ui.adjudicationRemoveReasons.company'].split(','),
        person: apiProps['ui.adjudicationRemoveReasons.person'].split(','),
      };
    } else {
      this.adjudicationRemoveReasons = ['IGNORED'];
    }

    if (typeof apiProps['ui.features.mapElements.types'] !== 'undefined') {
      this.mapElements = apiProps['ui.features.mapElements.types'].split(',');
    }

    this.generateOutputEnabled = apiProps['ui.utilityOutputGenerator.enabled'] === 'true';
    this.isDisplayCorporate = apiProps['ui.corporate.display'] === 'true';
    this.isDisplayBasicInfo = apiProps['ui.basicInfo.display'] === 'true';
    this.isDisplayRegulatory = apiProps['ui.regulatory.display'] === 'true';
    this.isDisplayAdverse = apiProps['ui.adverse.display'] === 'true';
    this.isDisplayLegal = apiProps['ui.legal.display'] === 'true';
    this.isDisplayReferences = apiProps['ui.references.display'] === 'true';
    this.isDisplayNoteworthy = apiProps['ui.noteworthy.display'] === 'true';
    this.isDisplayExpandAll = apiProps['ui.profileExpandAll.enabled'] === 'true';
    this.expandAllLimit = parseInt(apiProps['ui.features.expandAllEventGroups.limit'], 10);
    this.monitoringEnabled = apiProps['ui.features.monitoring.enabled'] === 'true';
    if (apiProps['ui.features.monitoring.frequencies']) {
      this.monitoringFrequencies = apiProps['ui.features.monitoring.frequencies'].split(',');
    } else {
      this.monitoringFrequencies = ['DAILY'];
    }

    this.monitorPreselected = apiProps['ui.features.monitoringPreselected'] === 'true';
    this.monitoringPreselectedCategories = (apiProps['ui.features.monitoringPreselectedCategories'] || '').split(',');

    if (typeof apiProps['ui.monitoring.defaultMonitoringFrequency'] !== 'undefined') {
      this.defaultMonitoringFrequency = apiProps['ui.monitoring.defaultMonitoringFrequency'];
    } else {
      this.defaultMonitoringFrequency = 'DAILY';
    }

    if (typeof apiProps['ui.preferredCountries'] !== 'undefined') {
      this.preferredCountries = apiProps['ui.preferredCountries'].split(',');
    }

    if (apiProps['ui.profileStatuses']) {
      this.profileStatuses = apiProps['ui.profileStatuses'].split(',');
    }
    if (apiProps['ui.profileStatuses.filter']) {
      this.profileStatusesFilter = apiProps['ui.profileStatuses.filter'].split(',');
    } else if (apiProps['ui.profileStatuses']) {
      this.profileStatusesFilter = apiProps['ui.profileStatuses'].split(',');
    }
    this.profileStatusEnabled = apiProps['ui.profileStatusEnabled'] === 'true';
    this.profileStatusAdjudicationActions = (
      apiProps['ui.profileStatuses.adjudicationRequired.adjudications'] || ''
    ).split(',');
    this.profileStatusConfidenceThreshold = apiProps['ui.profileStatuses.adjudicationRequired.confidenceThreshold'];
    this.profileStatusAdjudicationRequiredStatuses = (
      apiProps['ui.profileStatuses.adjudicationRequired.statuses'] || ''
    ).split(',');
    this.profileStatusAdjudicationRequiredEnabled =
      apiProps['ui.profileStatuses.adjudicationRequired.enabled'] === 'true';

    this.profileRefreshEnabled = apiProps['ui.features.profile.refresh.enabled'] === 'true';
    if (apiProps['ui.features.profile.refresh.frequencies']) {
      this.profileRefreshFrequencies = apiProps['ui.features.profile.refresh.frequencies'].split(',');
    } else {
      this.profileRefreshFrequencies = ['ONCE'];
    }

    this.avoidLoginPage = apiProps['ui.features.avoidLoginPage'] === 'true';

    this.profileScoreEnabled = apiProps['ui.profileScoreEnabled'] === 'true';
    this.riskInClassification = apiProps['ui.riskIconInClassifications.enabled'] === 'true';

    this.articleCacheEnabled = apiProps['ui.features.articleCacheEnabled'] === 'true';

    this.globalCompanyEnabled = apiProps['ui.features.globalCompanyEnabled'] === 'true';
    this.globalPersonEnabled = apiProps['ui.features.globalPersonEnabled'] === 'true';

    if (apiProps['ui.features.hiddenAdjudicationActions']) {
      this.hiddenAdjudicationActions = apiProps['ui.features.hiddenAdjudicationActions'].split(',');
    }

    this.adjudicationConfirmActionEnabled = apiProps['ui.features.adjudicationConfirmActionEnabled'] === 'true';
    this.adjudicationStarActionEnabled = apiProps['ui.features.adjudicationStarActionEnabled'] === 'true';

    this.existingProfileWarningEnabled = apiProps['ui.existingProfileWarning.enabled'] === 'true';
    this.existingProfileWarningUseSubmissionId = apiProps['ui.existingProfileWarning.useSubmissionId'] === 'true';
    this.existingProfileWarningUseSubmissionIdOnly =
      apiProps['ui.existingProfileWarning.useSubmissionId.only'] === 'true';
    this.submissionIdFieldRequired = apiProps['ui.field.submissionId.required'] === 'true';
    this.sexFieldRequired = apiProps['ui.field.sex.required'] === 'true';
    this.registrationNumberEnabled = apiProps['ui.field.registrationNumber.enabled'] === 'true';
    this.phoneAndFaxEnabled = apiProps['ui.field.phoneAndFaxNumbers.enabled'] === 'true';
    this.personSubmissionEnabled = apiProps['ui.features.personSubmission.enabled'] === 'true';
    this.idSubmissionConfigs = parseJson(apiProps['ui.features.idSubmission']);
    this.partsEnabled = apiProps['ui.field.parts.enabled'] === 'true';
    this.countryFieldRequired = apiProps['ui.field.country.required'] === 'true';

    this.crawlPlans = [apiProps['crawlPlan.default']];
    if (apiProps['crawlPlan.available']) {
      this.crawlPlans = this.crawlPlans.concat(apiProps['crawlPlan.available'].split(','));
    }

    this.isProfileCollectionEnabled = apiProps['profile.collections.enabled'] === 'true';

    this.crawlPlanPreselected = apiProps['crawlPlan.default.preselected'] === 'true';

    this.adjudicationSupportEnabled = apiProps['oiq.adjudication.support.enabled'] === 'true';

    this.vesselEnabled = apiProps['ui.vesselEnabled'] === 'true';

    this.lookbackEnabled = apiProps['ui.lookbackEnabled'] === 'true';

    this.displayAuditErrorsEnabled = apiProps['ui.displayAuditErrorsEnabled'] === 'true';

    this.displayAuditLinksEnabled = apiProps['ui.pdf.audit.links.enabled'] === 'true';

    this.externalReferenceCodeEnabled = apiProps['ui.field.externalReferenceCode.enabled'] === 'true';
    this.externalReferenceCodeRequired = apiProps['ui.field.externalReferenceCode.required'] === 'true';
    this.reqOrgEnabled = apiProps['ui.reqOrg.enabled'] === 'true';

    this.pdfSelectedArticlesEnabled = apiProps['ui.pdfSelectedArticlesEnabled'] === 'true';

    this.displayAdjudicationSummary = apiProps['ui.adjudicationSummary.enabled'] === 'true';

    if (this.displayAdjudicationSummary) {
      try {
        this.adjudicationSummaryConfig = JSON.parse(apiProps['ui.adjudicationSummary.config']);
      } catch (e) {
        console.error('Adjudication summary configuration parse failed', e);
      }
    }

    this.monitoringDefaultExclusionPeriod = parseInt(apiProps['ui.monitoring.defaultExclusionPeriod'] || '90');
    this.profileRefreshDefaultExclusionPeriod = parseInt(apiProps['ui.profileRefresh.defaultExclusionPeriod'] || '90');
    this.monitoringExclusionsEnabled = apiProps['platform.monitoring.exclusions.enabled'] === 'true';
    this.profileRefreshExclusionsEnabled = apiProps['platform.profileRefresh.exclusions.enabled'] === 'true';

    this.isScreenIqEnabled = apiProps['platform.maas.enabled'] === 'true';

    this.isCorporateGraphEnabled = apiProps['ui.features.ownershipAndFamilyTreeGraph.enabled'] === 'true';
    this.corporateGraphNodeHighlight = apiProps['ui.features.ownershipGraph.highlightGraphNodes'];
    this.defaultOwnershipFilterPercentage = parseInt(
      apiProps['ui.features.ownershipGraph.ownershipFilterPercentage'],
      10
    );
    this.isGovIdSearchEnabled = apiProps['ui.features.profileLib.findByIdentification.enabled'] === 'true';
    this.isPersonDisplayIdentificationsEnabled = apiProps['ui.person.identifications.display'] === 'true';

    this.isSsoEnabled = apiProps['oiq.auth.saml.sso.enabled'] === 'true';
    this.isSloEnabled = apiProps['oiq.auth.saml.slo.enabled'] === 'true';
    this.isOAuthEnabled = apiProps['oiq.auth.oauth.enabled'] === 'true';
    this.isProfileCollectionEnabled = apiProps['profile.collections.enabled'] === 'true';

    this.isElasticSearchEnabled = apiProps['profile.search.monitoring.elastic.enabled'] === 'true';

    this.sanctionedCountries = (apiProps['platform.region.sanctioned'] || '').split(',');
    this.highRiskCountries = (apiProps['platform.region.highrisk'] || '').split(',');
    this.localCountries = (apiProps['platform.region.local'] || '').split(',');
    this.isCageButtonEnabled = apiProps['ui.import.cage.data.into.build.screen.enabled'] === 'true';

    this.integrityReportUrlTemplate = apiProps['ui.features.integrityReportUrlTemplate'];
    this.auditReportUrlTemplate = apiProps['ui.features.auditReportUrlTemplate'];

    this.monitoringCustomFilters = (apiProps['profile.search.monitoring.elastic.enable.custom.search'] || '').split(
      ','
    );

    this.embeddedModeEnabled = apiProps['ui.features.embeddedMode.enabled'] === 'true';

    this.mrzEnabled = apiProps['ui.features.mrzFieldEnabled'] === 'true';

    this.apiProperties = apiProps;
  }
}

function parseJson(data) {
  let parsed;
  try {
    parsed = JSON.parse(data);
  } catch (e) {
    return data;
  }
  return parsed;
}
