import * as d3 from 'd3';
import $ from 'jquery';
import { Component, OnInit, AfterContentInit, ElementRef } from '@angular/core';
import CorporateGraphRender from './corporate-graph-render.service';
import CorporateGraph from './corporate-graph.service';
import CorporateGraphFilter from './corporate-graph-filter.service';
import OiqProperties from '../common-services/oiq-properties.service';
import { ActivatedRoute } from '@angular/router';
import OwnershipGraphNodeHighlight, {
  highlightModeFromString,
} from '../../../../../shared/enums/corporate-graph/ownership-graph-node-highlight.enum';

const icons = {
    company: '\uf0f7', //fa-building-o
    person: '\uf007', //fa-user
  },
  relationships = {
    DIRECT_OWNER: 'Direct Owner',
    ULTIMATE_OWNER: 'Ultimate Owner',
    PARENT: 'Parent',
    INDIRECT_OWNER: 'Indirect Owner',
    GLOBAL_ULTIMATE: 'Global Ultimate',
    DOMESTIC_ULTIMATE: 'Domestic Ultimate',
    HEADQUARTERS: 'Headquarters',
    SUBSIDIARY: 'Subsidiary',
    BRANCH: 'Branch',
    LINKED: 'Linked',
    MANAGER: 'Manager',
    FOUNDER: 'Founder',
    PARTNER: 'Partner',
    DIRECTOR: 'Director',
    OFFICER: 'Officer',
    MEMBER_OF_THE_BOARD: 'Member Of The Board',
    LEGAL_REPRESENTATIVE: 'Legal Representative',
    REGISTERED_AGENT: 'Registered Agent',
    SUPERVISOR: 'Supervisor',
  };

@Component({
  templateUrl: './corporate-graph-vis.component.tpl.html',
  selector: 'corporate-graph-vis',
})
export default class CorporateGraphVis implements OnInit, AfterContentInit {
  isOwnershipGraph: boolean;
  graphTitle: string;
  zoomFit: () => void;
  filterCallback: (filterValue: any) => void;

  graphData: { nodes: any[]; links: any[] };
  corporateGraphNodeHighlight;
  isDynamic;
  legend = [
    {
      name: 'Sanctioned jurisdiction',
      color: '#FF5630',
    },
    {
      name: 'High-risk jurisdiction',
      color: '#FFC400',
    },
    {
      name: 'Non-local jurisdiction',
      color: '#00B8D9',
    },
    {
      name: 'Local jurisdiction',
    },
  ];

  constructor(
    private oiqProperties: OiqProperties,
    private element: ElementRef,
    private corpGraph: CorporateGraph,
    private corporateGraphFilter: CorporateGraphFilter,
    private corporateGraphRender: CorporateGraphRender,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const graphType = this.route.snapshot.paramMap.get('graphType');
    this.isOwnershipGraph = this.corpGraph.isOwnershipGraph(graphType);
    this.corporateGraphNodeHighlight = highlightModeFromString(this.oiqProperties.corporateGraphNodeHighlight);
    this.isDynamic = this.corporateGraphNodeHighlight === OwnershipGraphNodeHighlight.DYNAMIC;
    this.route.data.subscribe((data) => {
      this.graphData = data.preLoad[1];
    });
  }

  ngAfterContentInit() {
    this.graphTitle = this.graphData.nodes[0].name;
    this.graph(this.graphData);
  }

  graph(data) {
    this.zoomFit = function () {
      zoomFit(0.9, 0);
    };

    let nodes = data.nodes,
      links = data.links,
      element = this.element,
      graphElement = $(element.nativeElement).find('#corporate-record-graph'),
      width = graphElement.width(),
      height = graphElement.height(),
      linkDistance = height / 7;

    let force = d3.layout
      .force()
      .charge(-linkDistance * 35) // lower the number, more the nodes repel
      .linkDistance(linkDistance)
      .size([width, height])
      .on('tick', tick); // updates node/link location on any movement

    let drag = force.drag();

    // Add zooming ability
    let zoom = d3.behavior
      .zoom()
      .scaleExtent([1 / 16, 16])
      .on('zoom', zoomed);

    let svg = d3.select(graphElement[0]).append('svg').attr('width', width).attr('height', height);

    svg.append('rect').attr('width', width).attr('height', height).attr('class', 'zoom-container').call(zoom);

    // Group nodes and links for zooming
    let g = svg.append('g');

    // Declare links, linkPath, linkLabels, nodes, arrows
    let arrow = g.append('defs').selectAll('marker'),
      link = g.selectAll('.link'),
      linkLabel = g.selectAll('.link-label'),
      linkText = g.selectAll('.link-text'),
      node = g.selectAll('.node');

    // Start graph, stop animation
    // Run the layout a fixed number of times.
    // The ideal number of times scales with graph complexity.
    // Of course, don't run too long—you'll hang the page!
    force.nodes(nodes).links(links).start();
    for (let i = 100 * 100; i > 0; --i) {
      force.tick();
    }
    update(this); // Render graph with data, arrange node/link layout
    force.stop();

    hideBuildingOverlay();
    zoomFit(0.7, 500);

    if (this.isOwnershipGraph) {
      const cloned = this.corpGraph.shallowCopy(nodes, links);

      this.filterCallback = (filterValue) => {
        this.corporateGraphFilter.filterGraph({ nodes, links, cloned }, filterValue);
        update(this);
      };
    }

    function update(that) {
      // Setup arrow heads
      arrow = arrow.data(links, function (d) {
        return d.id;
      });

      arrow.exit().remove();

      // Enter arrow heads
      arrow
        .enter()
        .append('marker')
        .attr({
          id: function (d) {
            return d.id;
          },
          viewBox: '0 -5 10 10',
          refX: 8,
          refY: 0,
          markerWidth: 6,
          markerHeight: 6,
          orient: 'auto',
        })
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5')
        .attr('class', 'arrow-head');

      // Setup links, linkPath, linkLabels
      link = link.data(links, function (d) {
        return d.id;
      });
      linkLabel = linkLabel.data(links, function (d) {
        return d.id;
      });
      linkText = linkText.data(links, function (d) {
        return d.id;
      });

      link.exit().remove();
      linkLabel.exit().remove();
      linkText.exit().remove();

      // Enter links
      link
        .enter()
        .insert('path', '.node')
        .attr('class', function (d) {
          return 'link ' + d.relationshipType.toLowerCase();
        })
        .attr('marker-end', function (d) {
          return 'url(#' + d.id + ')';
        });

      // Enter link label: path for link text
      linkLabel
        .enter()
        .insert('path', '.node')
        .attr('class', 'link-label')
        .attr('id', function (d) {
          return 'linkLabel' + d.id;
        });

      // Enter link text
      linkText
        .enter()
        .insert('text', '.node')
        .attr('class', 'link-text')
        .attr('dy', '1.35em')
        // Add text to link
        .append('textPath')
        .attr('startOffset', '50%')
        .attr(that.corporateGraphRender.TEXT_ANCHOR, 'middle')
        .attr('xlink:href', function (d) {
          return '#linkLabel' + d.id;
        })
        .style('pointer-events', 'none')
        .text(linkLabelText);

      // Setup nodes
      node = node.data(nodes, function (d) {
        return d.id;
      });

      node.exit().remove();

      // Enter nodes - groups rectangles, texts, icons
      let nodeEnter = node.enter().append('g').attr('class', 'node').call(drag);

      // node text with rectangle
      that.corporateGraphRender.createNameTextBox(nodeEnter);

      if (that.isOwnershipGraph) {
        if (that.corporateGraphNodeHighlight === OwnershipGraphNodeHighlight.NON_US) {
          that.corporateGraphRender.createCountryTextBox(nodeEnter);
          that.corporateGraphRender.highLightCountryNodes('red');
        } else if (that.corporateGraphNodeHighlight === OwnershipGraphNodeHighlight.DYNAMIC) {
          that.corporateGraphRender.createCountryTextBox(nodeEnter);
          that.corporateGraphRender.dynamicHighlightCountryNodes();
        }
      }

      // Node container: hidden circle holding node items
      nodeEnter.each(function (d) {
        let thisGroup = d3.select(this);

        thisGroup
          .insert('circle', 'rect') // Make sure circle is under rect
          .attr('class', 'node-circle')
          .attr('cy', function (d) {
            let rect = thisGroup.node().getBBox();
            d.center = rect.y + rect.height / 2; // Center of rect y-coordinate

            return d.center;
          })
          .attr('r', function (d) {
            let rect = thisGroup.node().getBBox();
            // radius to be used by links, so they know where to start/end
            d.radius = Math.sqrt(rect.width * rect.width + rect.height * rect.height) / 2;
            d.rectHeight = rect.height; // for icon and child collapse placements

            return d.radius; // to contain rect in circle
          });

        pulseRootNode(d, thisGroup);

        d.fixed = true; // Fix nodes in place
      });

      // Add Company/Person icon
      nodeEnter
        .append('text')
        .attr('x', 0)
        .attr('y', function (d) {
          return d.center - d.rectHeight / 2 - 12;
        })
        .attr('dy', '.35em')
        .attr(that.corporateGraphRender.TEXT_ANCHOR, 'middle')
        .attr('class', 'fa node-icon')
        .text(iconFor);

      tick();
    }

    function hideBuildingOverlay() {
      $(element.nativeElement).find('.loading').remove();
    }

    function tick() {
      // Position the link/link texts
      link.attr('d', function (d) {
        return path(true, d);
      });

      linkLabel.attr('d', function (d) {
        let ltrText = d.source.x < d.target.x;
        return path(ltrText, d);
      });

      // Position the node/text group to correct link
      node.attr('transform', function (d) {
        return `translate( ${d.x} , ${d.y - d.center})`;
      });
    }

    function zoomed() {
      let translate = `translate(${d3.event.translate})scale(${d3.event.scale})`;
      g.attr('transform', translate);
    }

    //http://bl.ocks.org/TWiStErRob/b1c62730e01fe33baa2dea0d0aa29359
    function zoomFit(paddingPercent, transitionDuration) {
      let scale,
        translate,
        graphBox = g,
        bounds = graphBox.node().getBBox(),
        fullWidth = width,
        fullHeight = height,
        graphBoxWidth = bounds.width,
        graphBoxHeight = bounds.height,
        midX = bounds.x + graphBoxWidth / 2,
        midY = bounds.y + graphBoxHeight / 2;

      if (graphBoxWidth === 0 || graphBoxHeight === 0) {
        return; // nothing to fit
      }

      scale = (paddingPercent || 0.75) / Math.max(graphBoxWidth / fullWidth, graphBoxHeight / fullHeight);
      translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

      graphBox
        .transition()
        .duration(transitionDuration || 0)
        .call(zoom.translate(translate).scale(scale).event);
    }
  }
}

function iconFor(entity) {
  if (!entity.entityType) {
    return '';
  }
  return icons[entity.entityType.toLowerCase()];
}

function pulseRootNode(d, group) {
  if (d.index === 0) {
    group.attr('class', 'root-node node');
  }
}

function linkLabelText(d) {
  let labelText = '',
    relationType = d.relationshipType,
    percent = d.percentageInterest;

  if (percent !== undefined) {
    labelText += percent + '%  ';
  }

  if (relationType !== undefined) {
    labelText += relationships[relationType];
  }

  return labelText;
}

function path(isLeftToRight, d) {
  return d.relationshipType === 'ULTIMATE_OWNER' ? arcPath(isLeftToRight, d) : linePath(isLeftToRight, d);
}

// https://stackoverflow.com/questions/18316056/d3-js-force-layout-edge-label-placement-rotation
function arcPath(leftHand, d) {
  let start = leftHand ? d.source : d.target,
    end = leftHand ? d.target : d.source,
    dx = end.x - start.x,
    dy = end.y - start.y,
    dr = Math.sqrt(dx * dx + dy * dy),
    sweep = leftHand ? 0 : 1,
    // x and y distances from center to outside edge of node
    offsetEndX = dr !== 0 ? (dx * end.radius) / dr : 0,
    offsetEndY = dr !== 0 ? (dy * end.radius) / dr : 0,
    offsetStartX = dr !== 0 ? (dx * start.radius) / dr : 0,
    offsetStartY = dr !== 0 ? (dy * start.radius) / dr : 0;
  return (
    'M' +
    (start.x + offsetStartX) +
    ',' +
    (start.y + offsetStartY) +
    'A' +
    dr +
    ',' +
    dr +
    ' 0 0,' +
    sweep +
    ' ' +
    (end.x - offsetEndX) +
    ',' +
    (end.y - offsetEndY)
  );
}

function linePath(leftHand, d) {
  let start = leftHand ? d.source : d.target,
    end = leftHand ? d.target : d.source,
    // Total difference in x and y from source to target
    dx = end.x - start.x,
    dy = end.y - start.y,
    // Length of path from center of source node to center of target node
    pathLength = Math.sqrt(dx * dx + dy * dy),
    // x and y distances from center to outside edge of node
    offsetEndX = (dx * end.radius) / pathLength,
    offsetEndY = (dy * end.radius) / pathLength,
    offsetStartX = (dx * start.radius) / pathLength,
    offsetStartY = (dy * start.radius) / pathLength;

  return (
    'M' +
    (start.x + offsetStartX) +
    ',' +
    (start.y + offsetStartY) +
    'L' +
    (end.x - offsetEndX) +
    ',' +
    (end.y - offsetEndY)
  );
}
