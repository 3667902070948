<div class="alert alert-warning alert-monitor no-print" *ngIf="showProfileRefreshInfo()">
  <div [ngSwitch]="isProfileRefreshDelta()">
    <div *ngSwitchCase="false">
      <span *ngIf="isProfileRefreshActive()">
        This profile was scheduled to be refreshed
        <span class="bold">{{ getRefreshFrequency() }}</span> on
        <span class="bold">{{ getRefreshScheduledOn() | dateFormat }}</span>.

        <span *ngIf="!isProfileRefreshScheduledOnlyOnce()">
          Next run is scheduled on <span class="bold">{{ getNextScheduledRun() | dateFormat }}</span>.
        </span>
      </span>

      <div *ngIf="hasVersions()">
        <label for="profile-versions">
          <span class="bold">Completed Profile Refresh Runs: </span>
        </label>
        <select name="selectedVersion" [(ngModel)]="selectedVersion" (change)="versionSelected()">
          <option *ngFor="let version of versions()" [ngValue]="version">{{version.label}}</option>
        </select>
      </div>
    </div>

    <div *ngSwitchCase="true">
      <p>
        <a [href]="getRootEntityUrl()" class="bold">{{ getEntityName() }}</a>
        Comparison report: (
        <span class="bold"> {{ getRefreshRunCompleteDate() | dateFormat }}</span> vs
        <span class="bold"> {{ getRefreshRunStartDate() | dateFormat }} </span>)
      </p>
    </div>
  </div>
</div>
