<div class="sub-accordian non-breaking" [ngClass]="{ tree: tree, nosep: nosep }">
  <div class="bar" (click)="isOpen = !isOpen || nobtn" [ngClass]="{ open: isOpen, nobtn: nobtn }">
    {{label}}
    <span class="accordian-alert" *ngIf="alerts > 0">
      <ddiq-alert-icon></ddiq-alert-icon>
    </span>
  </div>
  <div class="transclude" [hidden]="!isOpen">
    <ng-content></ng-content>
  </div>
</div>
