import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import ProfileCollectionListComponent from './profile-collection-list.component';
import DdiqProfileCollectionEditComponent from './ddiq-profile-collection-edit.component';
import CollectionListChangeNotificationService from '../common-services/collection-list-change-notification.service';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, NgMultiSelectDropDownModule.forRoot()],
  declarations: [ProfileCollectionListComponent, DdiqProfileCollectionEditComponent],
  providers: [CollectionListChangeNotificationService],
  exports: [ProfileCollectionListComponent, DdiqProfileCollectionEditComponent],
})
export class ProfileCollectionModule {}
