import { Component } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import Monitor from './monitor.service';

@Component({
  selector: 'ddiq-monitor-profile',
  templateUrl: './ddiq-monitor-profile.component.tpl.html',
})
export default class MonitorProfileComponent {
  hasChosenCategories: boolean;
  private frequency: any;
  private categories: any;
  private crawlPlanType: any;
  private monitored: boolean;

  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private monitor: Monitor,
    private modalTrackingService: ModalTrackingService
  ) {}

  onMonitorChoice(monitoring) {
    this.hasChosenCategories = monitoring.isEnabled;
    this.frequency = monitoring.frequency;
    this.categories = monitoring.categories.map(function (category) {
      return category.value;
    });
    this.crawlPlanType = monitoring.selectedCrawlPlanKey;
  }

  isMonitored() {
    return this.monitor.isMonitorActive();
  }

  cancel() {
    this.modalTrackingService.getTriggers().enableMonitoring.open = false;
  }

  monitorProfile() {
    if (!this.isMonitored()) {
      const monitorParams = {
        frequency: this.frequency,
        categories: this.categories || [],
        crawlPlanType: this.crawlPlanType,
      };
      this.apiFetchService.scheduleMonitor(this.entity.getType(), this.entity.getId(), monitorParams).then(
        (data) => this.monitorSuccess(data),
        (data) => this.monitorFailure(data)
      );
    }
    this.modalTrackingService.getTriggers().enableMonitoring.open = false;
  }

  monitorSuccess(data) {
    this.monitor.setMonitorData(data);
    this.monitored = this.monitor.isMonitorActive();
  }

  monitorFailure(data) {
    this.monitor.setMonitorData(null);
    this.monitored = false;
    if (data.status === 500) {
      this.modalTrackingService.openError('monitorScheduleError');
    } else {
      this.modalTrackingService.openError('monitorError');
    }
  }
}
