import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class MonitorTabsData {
  counts: { hitCount: number; closedCount: number; reportCount: number };
  pendingSearches: number;
  activeTab: number;

  constructor() {
    this.counts = {
      hitCount: 0,
      closedCount: 0,
      reportCount: 0,
    };
    this.pendingSearches = 3;
  }
}
