import { Component, OnInit } from '@angular/core';
import Entity from '../common-services/entity.service';
import ApiFetchService from '../common-services/api-fetch.service';
import TypeLookup from '../common-services/type-lookup.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'ddiq-breadcrumb',
  templateUrl: 'ddiq-breadcrumb.component.tpl.html',
})
export default class BreadcrumbComponent implements OnInit {
  parentEntity: any;
  parentUrl: string;
  private routeParams: ParamMap;

  constructor(
    private route: ActivatedRoute,
    private entity: Entity,
    private apiFetchService: ApiFetchService,
    private typeLookup: TypeLookup
  ) {
    this.routeParams = route.snapshot.paramMap;
  }

  ngOnInit() {
    // can't be your own parent!
    if (this.entity.getId() === +this.routeParams.get('overviewId')) {
      return;
    }

    const parentType = this.typeLookup.get(this.routeParams.get('overviewId'));

    if (parentType === 'company') {
      this.apiFetchService.summary('company', this.routeParams.get('overviewId'), true).then((summary) => {
        this.parentEntity = summary.name;
        this.parentUrl =
          '#/overview/' + this.routeParams.get('overviewId') + '/company/' + this.routeParams.get('overviewId');
      });
    } else if (parentType === 'person') {
      this.apiFetchService.summary('person', this.routeParams.get('overviewId'), true).then((summary) => {
        this.parentEntity = summary.name;
        this.parentUrl =
          '#/overview/' + this.routeParams.get('overviewId') + '/person/' + this.routeParams.get('overviewId');
      });
    }
  }
}
