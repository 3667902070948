import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
class EventGroupFilterService {
  getFilteredInfo(incidents) {
    const filtered = [];
    let confirmedCount = 0,
      escalatedCount = 0,
      notAdjudicated = 0,
      filteredOutCount = 0,
      manuallyAdjudicatedCount = 0,
      autoAdjudicatedCount = 0;
    const notAdjudicatedAndNotFiltered = [];

    incidents = incidents || [];

    incidents.forEach(function (incident) {
      if (!incident.filtered) {
        filtered.push(incident);
      }

      if (incident.filtered) {
        filteredOutCount += 1;
      }

      if (!incident.filtered && !incident.disabled) {
        notAdjudicatedAndNotFiltered.push(incident);
      }

      if (!incident.disabled) {
        if (incident.confirmed) {
          confirmedCount += 1;
        }
        notAdjudicated += 1;
      } else {
        if (incident.isAutoAdjudicated) {
          autoAdjudicatedCount += 1;
        } else {
          manuallyAdjudicatedCount += 1;
        }
      }

      if (incident.escalated) {
        escalatedCount += 1;
      }
    });

    return {
      filtered: filtered,
      adjudicatedCount: manuallyAdjudicatedCount + autoAdjudicatedCount,
      notAdjudicatedOrFiltered: notAdjudicatedAndNotFiltered,
      remainingCount: notAdjudicated,
      totalCount: incidents.length,
      filteredOutCount: filteredOutCount,
      escalatedCount: escalatedCount,
      confirmedCount: confirmedCount,
      autoAdjudicatedCount: autoAdjudicatedCount,
      manuallyAdjudicatedCount: manuallyAdjudicatedCount,
      notAdjudicatedAndNotFilteredCount: notAdjudicatedAndNotFiltered.length,
    };
  }
}

export default EventGroupFilterService;
