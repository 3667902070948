import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-text',
  templateUrl: './ddiq-text.component.tpl.html',
  viewProviders: [
    { provide: ControlContainer, useFactory: (ngForm: NgForm) => ngForm, deps: [[new Optional(), NgForm]] },
  ],
})
export default class DdiqTextComponent implements OnInit {
  @Input() model;
  @Input() label;
  @Input() pattern;
  @Input() maxLength;
  @Input() textDisabled;
  @Input() oiqFieldRequired;
  @Input() oiqPlaceHolder;
  @Input() size;
  @Input() required;
  @Input() name;

  @Output() modelChange = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {
    if (!this.oiqPlaceHolder) {
      this.oiqPlaceHolder = '';
    }
    if (this.model) {
      this.model = this.model.replace('\n', ' ');
    }
    if (!this.pattern) {
      this.pattern = /^.*$/;
    }
  }

  onTextChange(value: string) {
    this.modelChange.emit(value);
  }

  isRequired() {
    if (typeof this.oiqFieldRequired === 'function') {
      return this.oiqFieldRequired();
    } else {
      return typeof this.required !== 'undefined' || this.oiqFieldRequired;
    }
  }
}
