export class FilterConstants {
  public static antiCollideClassifications = [
    'INTERNATIONAL_SANCTIONS',
    'GOVERNMENT_CONTRACTOR',
    'P_CONNECTED_COMPANY',
    'PEP',
    'PEP_HIO',
    'POLITICAL_RELATION',
    'SANCTIONED_COUNTRY',
    'STATE_OWNED_COMPANY',
  ];
}
