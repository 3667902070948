import { Directive, OnInit } from '@angular/core';
import Entity from '../common-services/entity.service';
import Monitor from '../monitor/monitor.service';

@Directive({
  selector: '[oiq-entity-aware]',
  exportAs: 'oiqEntityAware',
})
export default class OiqEntityAwareNgDirective implements OnInit {
  isDeltaReport: boolean;
  noReassess: boolean;
  isSingleClassificationModel: boolean;
  isAdjudicatable: boolean;
  entityId: string;
  entityType: string;
  isPreview: boolean;
  isDiff: boolean;

  constructor(private monitor: Monitor, private entity: Entity) {}

  ngOnInit() {
    this.isDeltaReport = this.monitor.isDeltaReport();
    this.noReassess = this.noReassess || this.isDeltaReport;
    this.isSingleClassificationModel = !!(this.entity.getData() || {}).singleClassificationModel;
    this.isAdjudicatable = this.isSingleClassificationModel && this.entity.isAdjudicatableByUser();

    this.entityId = this.entity.getId();
    this.entityType = this.entity.getType();
    this.isPreview = this.entity.isInPreview();
    this.isDiff = this.entity.isDiff();
  }
}
