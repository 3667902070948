<div [ngSwitch]="data?.length > 0">
  <div *ngSwitchCase="true">
    <ddiq-accordion label="Exiger Certified Supplier" openonprint>
      <article *ngFor="let record of data" [id]="data.id" class="oiq-corporate-record">
        <div class="oiq-grid">
          <div class="oiq-grid-row">
            <div class="oiq-corporate-record-bd oiq-grid-cell">
              <article class="oiq-corporate-record-grid">
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">Parent Company:</div>
                  <div class="oiq-corporate-record-cell" role="gridcell">
                    <span *ngIf="record.name" [textContent]="record.name"></span>
                  </div>
                </div>
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">Address:</div>
                  <div class="oiq-corporate-record-cell" role="gridcell">
                    <span *ngIf="record.address" [textContent]="record.address"></span>
                  </div>
                </div>
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">Website:</div>
                  <div class="oiq-corporate-record-cell" role="gridcell">
                    <span *ngIf="record.website" [textContent]="record.website"></span>
                  </div>
                </div>
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">Country of Incorporation:</div>
                  <div class="oiq-corporate-record-cell" role="gridcell">
                    <span *ngIf="record.country" [textContent]="record.country"></span>
                  </div>
                </div>
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">Public / Private:</div>
                  <div class="oiq-corporate-record-cell" role="gridcell">
                    <span *ngIf="record.public" [textContent]="record.public"></span>
                  </div>
                </div>
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">Ticker Symbol:</div>
                  <div class="oiq-corporate-record-cell" role="gridcell">
                    <span *ngIf="record.symbol" [textContent]="record.symbol"></span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </article>
    </ddiq-accordion>
  </div>
</div>
