<main id="home">
  <ddiq-home-sidebar></ddiq-home-sidebar>
  <section class="center">
    <div id="home-buttons" class="home-btn-group">
      <a
        id="home-button-build_company_profile"
        *ngIf="user.canSubmitProfile()"
        class="btn btn-primary btn-lg btn-block"
        href="#/build/company">
        Build Company Profile
      </a>
      <a
        id="home-button-build_person_profile"
        *ngIf="user.canSubmitProfile() && isPersonSubmissionEnabled"
        class="btn btn-primary btn-lg btn-block"
        href="#/build/person">
        Build Person Profile
      </a>
      <a
        id="home-button-view_profile"
        *ngIf="!user.canSubmitProfile()"
        class="btn btn-primary btn-lg btn-block"
        href="#/profiles">
        View Profiles
      </a>
      <a
        id="home-button-generate_report"
        *ngIf="generateOutputEnabled"
        class="btn btn-primary btn-lg btn-block"
        href="#/generate-output">
        Generate Output
      </a>
      <a
        id="home-button-monitoring"
        *ngIf="user.canMonitorProfiles() && monitoringEnabled"
        class="btn btn-primary btn-lg btn-block"
        href="#/monitor">
        Monitoring
      </a>
    </div>
    <ddiq-footer id="home-footer" class="footer-container"></ddiq-footer>
  </section>
  <br class="clr" />
</main>
