import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqAssociationModule from '../association/oiq-association.module';
import { CodeSchemaModule } from '../code-schema/oiq-code-schema.module';
import { CorporateGraphModule } from '../corporate-graph/corporate-graph.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqCorporateRecordComponent from './ddiq-corporate-record.component';
import DdiqCorporateRecordsGroupComponent from './ddiq-corporate-records-group.component';
import OwnershipDataExtractionService from './ownership-data-extraction.service';
import OwnershipData from './ownership-data.service';

@NgModule({
  imports: [
    CommonModule,
    DdiqUIModule,
    PipesModule,
    ProtectedArticlesModule,
    OiqAdjudicationModule,
    OiqAssociationModule,
    DdiqDirectivesModule,
    OiqSourcesModule,
    CodeSchemaModule,
    CorporateGraphModule,
  ],
  declarations: [DdiqCorporateRecordsGroupComponent, DdiqCorporateRecordComponent],
  providers: [OwnershipDataExtractionService, OwnershipData],
  exports: [DdiqCorporateRecordsGroupComponent],
})
export class DdiqCorporateRecordsModule {}
