<div class="input-data" *ngIf="seedData.sex">
  <p>Sex:</p>
  {{ seedData.sex }}
</div>
<div class="input-data" *ngIf="hasPreviousAddresses()">
  <p>Previous Addresses:</p>
  <span *ngFor="let previousAddress of (seedData.previousAddresses | previousAddresses)">
    <p>
      <span>{{ previousAddress.address | address }}</span>
    </p>
  </span>
</div>

<div *ngIf="seedData.phoneNumbers?.length || seedData.emailAddresses?.length">
  <div class="input-data" *ngIf="seedData.phoneNumbers?.length">
    <p>Phone Numbers:</p>
    <span *ngFor="let phoneNumber of seedData.phoneNumbers">
      {{ phoneNumber.number }} <span *ngIf="phoneNumber.label">({{ phoneNumber.label | translatef }})</span><br />
    </span>
  </div>
  <div class="input-data" *ngIf="seedData.emailAddresses?.length">
    <p>Email Addresses:</p>
    <span *ngFor="let email of seedData.emailAddresses">
      {{ email.email }} <span *ngIf="email.label">({{ email.label | translatef }})</span><br />
    </span>
  </div>
</div>
<div *ngIf="seedData.people?.length">
  <div class="input-data" *ngIf="seedData.people?.length">
    <p>Known Associates:</p>
    <span *ngFor="let relatedPerson of seedData.people"> {{ relatedPerson.name }}<br /> </span>
  </div>
</div>
<div class="input-data" *ngIf="seedData.companies?.length">
  <p>Related Companies:</p>
  <span *ngFor="let company of seedData.companies">
    <span *ngIf="company.relationshipType">{{ company.relationshipType }} with</span> {{ company.name }}<br />
    <span *ngIf="company.mainAddress">{{ company.mainAddress | address }}</span><br />
  </span>
</div>
<div *ngIf="seedData.positions?.length || seedData.education?.length">
  <div class="input-data" *ngIf="seedData.positions?.length">
    <p>Employment:</p>
    <span *ngFor="let position of seedData.positions">
      {{ position.title }}<span *ngIf="position.title && position.organization"> at </span>{{ position.organization
      }}<br />
    </span>
  </div>
  <div class="input-data" *ngIf="seedData.education?.length">
    <p>Educational Institutions:</p>
    <span *ngFor="let education of seedData.education"> {{ education.institution }}<br /> </span>
  </div>
</div>
<div class="input-data" *ngIf="seedData.requestedLanguages?.length">
  <p>Languages:</p>
  <span *ngFor="let language of seedData.requestedLanguages">
    <p>
      <span>{{ language | translatef }}</span>
    </p>
  </span>
</div>
<div class="input-data" *ngIf="seedData.dateOfInterest">
  <p>Date of Interest:</p>
  <span>{{ seedData.dateOfInterest | dateFormat }}</span>
</div>
<div class="input-data">
  <correlation-details [seedData]="seedData"></correlation-details>
</div>
