import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractSource',
})
export default class ExtractSourcePipe implements PipeTransform {
  transform(reference: any, ...args: any[]): any {
    const anchor = document.createElement('a');
    let source, url;
    if (reference) {
      if (reference.source) {
        source = reference.source;
      } else if ((url = reference.url || reference.sourceUrl)) {
        anchor.href = encodeURI(url);
        source = anchor.hostname;
      }
    }
    return source;
  }
}
