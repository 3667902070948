import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class HttpBufferService {
  private buffer: BufferedRequest[] = [];

  constructor(private httpClient: HttpClient) {}

  append(request): Observable<HttpEvent<any>> {
    const bufferedRequest = this.makeBufferedRequest(request);
    this.buffer.push(bufferedRequest);
    return bufferedRequest.observer;
  }

  retryAll() {
    for (const buffered of this.buffer) {
      buffered.request$.subscribe(buffered.observer);
    }
    this.buffer = [];
  }

  private makeBufferedRequest(request) {
    return { request$: this.httpClient.request(request.clone()), observer: new Subject<HttpEvent<any>>() };
  }
}

interface BufferedRequest {
  request$: Observable<HttpEvent<any>>;
  observer: Observer<HttpEvent<any>>;
}
