import { Component, OnInit, Input, Inject } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import ApiFetchService from '../common-services/api-fetch.service';
import CollectionListChangeNotificationService from '../common-services/collection-list-change-notification.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import profileCollectionsParams from './profile-collection-param-generator';

@Component({
  selector: 'ddiq-profile-collection-edit',
  templateUrl: './ddiq-profile-collection-edit.component.tpl.html',
})
export default class DdiqProfileCollectionEditComponent implements OnInit {
  profileAssign: string;
  profileSubmit: string;
  dropdownList: any[];
  selectedItems: any[];
  dropdownSettings: IDropdownSettings;
  profileCollectionKeysInProfile: string[];
  model: any[];
  errorMessage: string;
  profileCollectionKeysForCurrentUserAsSteward: string[];

  @Input() entityId: any;
  @Input() entityType: any;

  constructor(
    private apiFetchService: ApiFetchService,
    private collectionListChangeNotificationService: CollectionListChangeNotificationService,
    private modalTrackingService: ModalTrackingService
  ) {
    this.profileAssign = 'profile_assign';
    this.profileSubmit = 'profile_submit';
    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {};
    this.model = [];
    this.profileCollectionKeysInProfile = [];
    this.errorMessage = '';
    this.profileCollectionKeysForCurrentUserAsSteward = [];
  }

  ngOnInit() {
    Promise.all([
      this.getProfileCollectionsAssociatedWithProfileForCurrentUserAsUser(),
      this.getProfileCollectionsForCurrentUserAsSteward(),
    ])
      .then((results) => {
        this.profileCollectionKeysInProfile = this.getProfileCollectionKeys(results[0]);
        this.profileCollectionKeysForCurrentUserAsSteward = this.getProfileCollectionKeys(results[1]);
      })
      .then(() => this.getProfileCollectionsForCurrentUserAsUser())
      .then((data) => {
        this.dropdownList = data.results.map((item) => ({
          profile_collection_key: item.profileCollectionKey,
          profile_collection_name: item.name,
        }));
        this.dropdownList.forEach((item) => {
          item.original_profile_collection_name = item.profile_collection_name;
          if (this.profileCollectionKeysForCurrentUserAsSteward.includes(item.profile_collection_key)) {
            item.profile_collection_name = item.profile_collection_name + ' (Steward)';
          }
          if (this.profileCollectionKeysInProfile.includes(item.profile_collection_key)) {
            this.selectedItems.push(item);
            this.selectedItems = this.selectedItems.slice();
            this.onItemSelect(item);
          }
        });
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'profile_collection_key',
          textField: 'profile_collection_name',
          selectAllText: 'Select All',
          unSelectAllText: 'Clear Selections',
          itemsShowLimit: 3,
          allowSearchFilter: true,
        };
      });
  }

  getProfileCollectionsForCurrentUserAsUser() {
    return this.getProfileCollections(this.getProfileCollectionsForCurrentUserPermissionParams(this.profileSubmit));
  }

  getProfileCollectionsAssociatedWithProfileForCurrentUserAsUser() {
    return this.getProfileCollections(
      this.getProfileCollectionsAssociatedWithProfileForCurrentUserPermissionParam(this.profileSubmit)
    );
  }

  getProfileCollectionsForCurrentUserAsSteward() {
    return this.getProfileCollections(this.getProfileCollectionsForCurrentUserPermissionParams(this.profileAssign));
  }

  onItemSelect(item: any) {
    this.model.push(item.profile_collection_key);
  }

  onSelectAll(items: any) {
    this.model.length = 0;
    items.forEach((i) => this.model.push(i.profile_collection_key));
  }

  onItemDeselect(item: any) {
    const index = this.model.indexOf(item.profile_collection_key);
    if (index !== -1) {
      this.model.splice(index, 1);
    }
  }

  onDeselectAll(items: any) {
    this.model.length = 0;
  }

  cancel() {
    this.modalTrackingService.getTriggers().editCollections.open = false;
  }

  onSave() {
    this.updateProfileToCollectionAssignments().then(
      (data) => {
        this.modalTrackingService.getTriggers().editCollections.open = false;
        let profileToCollectionAssignmentResponse: any = {};
        profileToCollectionAssignmentResponse.assignedProfileCollections =
          this.getProfileCollectionsWithOrigNamesFromKeys(data.assignedProfileCollectionKeys);
        profileToCollectionAssignmentResponse.unassignedProfileCollections =
          this.getProfileCollectionsWithOrigNamesFromKeys(data.unassignedProfileCollectionKeys);
        this.collectionListChangeNotificationService.collectionList.next(profileToCollectionAssignmentResponse);
      },
      (failureResponse) => this.profileCollectionAssignmentFailed(failureResponse)
    );
  }

  getUpdateProfileToCollectionAssignmentsPayload() {
    let payload: any = {};
    payload.profileCollectionKeys = this.selectedItems.map((item) => item.profile_collection_key);
    return payload;
  }

  getProfileCollectionsWithOrigNamesFromKeys(profileCollectionKeys) {
    return this.dropdownList
      .filter((item) => profileCollectionKeys.includes(item.profile_collection_key))
      .map((item) => ({
        name: item.original_profile_collection_name,
        profileCollectionKey: item.profile_collection_key,
      }));
  }

  updateProfileToCollectionAssignments() {
    return this.apiFetchService.updateProfileToCollectionAssignments(
      this.entityType,
      this.entityId,
      this.getUpdateProfileToCollectionAssignmentsPayload()
    );
  }

  profileCollectionAssignmentFailed(failureResponse) {
    const defaultErrorMsg = 'Saving profile collection assignment has failed';
    if (failureResponse.error) {
      this.errorMessage = failureResponse.error.reason || defaultErrorMsg;
    } else {
      this.errorMessage = defaultErrorMsg;
    }
  }

  getProfileCollections(params) {
    return this.apiFetchService.getProfileCollections(params);
  }

  getProfileCollectionKeys(data) {
    return data.results.map((item) => {
      return item.profileCollectionKey;
    });
  }

  getProfileCollectionsForCurrentUserPermissionParams(permission) {
    return profileCollectionsParams().withUserPermissions(permission).build();
  }

  getProfileCollectionsAssociatedWithProfileForCurrentUserPermissionParam(permission) {
    return profileCollectionsParams()
      .withUserPermissions(permission)
      .withEntityId(this.entityId)
      .withEntityType(this.entityType)
      .build();
  }
}
