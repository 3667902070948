<div #entityAware="oiqEntityAware" oiq-entity-aware>
  <ng-container *ngFor="let group of groups.list; trackBy:classification">
    <article
      id="{{idPrefix}}_LEGAL_ACTIVITY"
      *ngIf="group.hasDiscoveries"
      data-oiq-collapsible-ctrl
      [expandAll]="expandAll || group.isEventGroupOpen"
      class="panel oiq-legal-filings discovery-group">
      <header class="panel-header oiq-legal-filings-header">
        <oiq-group-dynamic-adjudication-aware
          [adjudicationType]="'LEGAL_ACTIVITY'"
          [section]="idPrefix"
          [classification]="'LEGAL_ACTIVITY'">
        </oiq-group-dynamic-adjudication-aware>

        <h3 class="panel-title oiq-legal-filings-title">{{group.title}}</h3>

        <div class="panel-header-right">
          <ddiq-sort-buttons
            *ngIf="group.hasMoreEntries"
            class="pull-left btn-sort-group-margin"
            [entities]="group.discoveries"
            [orderBy]="'association, adjudication'"
            (onClick)="sort({ sortName: $event.btnName, eventGroup: group})"
            [activeBtn]="group.activeSortButtons">
          </ddiq-sort-buttons>

          <button class="btn btn-sm btn-default" *ngIf="group.hasMoreEntries" data-oiq-collapsible-toggle>
            <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
            View all {{(group.discoveries | moreEntries).length + 1}} events
          </button>
        </div>
      </header>

      <section class="panel-body">
        <h3 class="sr-only">Legal Filings</h3>

        <article
          class="oiq-legal-filings-featured-incident"
          *ngFor="let featured of group.discoveries | firstUnfilteredDiscovery">
          <ddiq-legal-filing [filing]="featured" [isAdjudicateable]="entityAware.isAdjudicatable"> </ddiq-legal-filing>
        </article>

        <article class="oiq-legal-filings-more-entries discovery-group" *ngIf="group.hasMoreEntries">
          <ddiq-collapsible>
            <ddiq-legal-filing
              class="oiq-legal-filings-more-entry"
              *ngFor="let entry of group.discoveries | moreEntries"
              [filing]="entry"
              [isAdjudicateable]="entityAware.isAdjudicatable">
            </ddiq-legal-filing>
          </ddiq-collapsible>
        </article>
      </section>
    </article>
  </ng-container>
</div>

<p *ngIf="groups.list.length === 0" class="oiq-legal-filings-msg-no-results">No results discovered</p>

<p *ngIf="groups.showAllFilteredMessage" class="oiq-legal-filings-msg-all-filtered">All results filtered</p>
