import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'validateHtmlIds',
})
export default class ValidateHtmlIdsPipe implements PipeTransform {
  transform(idValue: string): any {
    return (idValue || '').trim().split(' ').join('_');
  }
}
