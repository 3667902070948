import { Injectable } from '@angular/core';
import EventGroupsTemplate from '../event-groups/event-groups-template';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';
import EventGroupBuilderService from './event-group-builder.service';
import EventGroupsBuilderService from './event-groups-builder.service';

@Injectable({
  providedIn: 'root',
})
export default class LegalFilingEventGroupsService extends EventGroupsTemplate {
  constructor(
    private eventGroupBuilderService: EventGroupBuilderService,
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private structuredDataSorts: StructuredDataSorts
  ) {
    super('legalActivitiesEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
  }

  addEventGroupsToSection() {
    return (section) => {
      const legalActivities = section.data.legalActivities;

      if (!legalActivities) return;

      const eventGroups = [];

      if (legalActivities.length) {
        const eventGroup = this.eventGroupBuilderService
          .newBuilder()
          .withDiscoveries(legalActivities)
          .withTitle('Filings')
          .withSort((discoveries) => this.structuredDataSorts.sortByAdjudication(discoveries))
          .build();

        eventGroups.push(eventGroup);
      }

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService.newEventGroupsModel(eventGroups).build();
    };
  }
}
