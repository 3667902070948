<aside id="home-sidebar" class="side-nav sidebar">
  <ddiq-recently-built-profiles></ddiq-recently-built-profiles>
  <h3>Currently Building</h3>
  <div>
    <ul>
      <li
        class="recently-built"
        *ngFor="let profile of buildingProfiles() | filterBy:['status']: 'started' | orderBy:'oiqCreatedDate'">
        <a *ngIf="showPreviewLink(profile)" [href]="profile.profileLink">{{ profile.name | translatef }}</a>
        <span *ngIf="!showPreviewLink(profile)">{{ profile.name | translatef }}</span>
      </li>
      <li
        class="recently-built"
        *ngFor="let profile of buildingProfiles() | filterBy: ['status']: 'rootFinished' | orderBy:'oiqCreatedDate'">
        <a *ngIf="showPreviewLink(profile)" [href]="profile.profileLink">{{ profile.name | translatef }}</a>
        <span *ngIf="!showPreviewLink(profile)">{{ profile.name | translatef }}</span>
      </li>
      <li
        class="recently-built"
        *ngFor="let profile of buildingProfiles() | filterBy: ['status']:'queued' | orderBy:'oiqCreatedDate'">
        {{ profile.name | translatef }}
      </li>
    </ul>
    <div class="block center-block">
      <button
        type="button"
        (click)="gotoLibrary()"
        class="btn btn-default btn-block btn-sm"
        [disabled]="buildingProfiles().length == 0"
        [ngClass]="{disabled: (buildingProfiles().length == 0)}">
        View All Building Profiles
      </button>
    </div>
  </div>
</aside>
