<header class="watch-list-header">
  <h4 class="watch-list-title">
    <span class="watch-list-header-name">{{watchList.companyName || watchList.name}}</span>

    <span class="watch-list-header-source oiq-watch-list-source-disabled" *ngIf="!sources?.sourceUrl">
      - {{sources?.source | translatef}}
    </span>

    <a
      class="watch-list-header-source-url"
      *ngIf="sources?.sourceUrl"
      [href]="sources?.sourceUrl"
      target="_blank"
      rel="noopener noreferrer">
      - {{sources?.source | translatef}}</a
    >

    <a class="watch-list-header-toggle" *ngIf="moreSourcesCount > 0" data-oiq-collapsible-toggle>
      <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
      {{moreSourcesCount}} More Sources
    </a>
  </h4>
</header>
