import DdiqProfessionalLicensesGroupComponent from './ddiq-professional-licenses-group.component';
import DdiqProfessionalLicenseComponent from './ddiq-professional-license.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import OiqAssociationModule from '../association/oiq-association.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';

@NgModule({
  imports: [
    CommonModule,
    DdiqUIModule,
    PipesModule,
    DdiqDirectivesModule,
    OiqAdjudicationModule,
    OiqAssociationModule,
    OiqSourcesModule,
    ThemedEntityModule,
  ],
  declarations: [DdiqProfessionalLicenseComponent, DdiqProfessionalLicensesGroupComponent],

  exports: [DdiqProfessionalLicenseComponent, DdiqProfessionalLicensesGroupComponent],
})
export class OiqProfessionalLicensesModule {}
