<div class="integrity-report" oiq-print-rf>
  <img *ngIf="isLoading" [src]="'./img/loader.gif'" class="result-loading-img" />
  <div *ngIf="!isLoading">
    <div class="integrity-title">
      <table>
        <tbody>
          <tr>
            <th>
              <h1>Integrity Report</h1>
              :
            </th>
            <td>
              <span class="subject">{{summary.fullName}}</span><br />
              <b>Created:</b> {{summary.oiqCreatedDate | dateFormat}}<br />
              <b>By:</b> {{summary.oiqCreatedBy}}<br />
              <span *ngIf="summary.submissionId"
                ><b> {{ 'REFERENCE_ID' | translatef }}: </b> {{summary.submissionId}}<br
              /></span>
            </td>
            <td>
              <ddiq-limited-list class="collection-box" [list]="summary.collections" [limit]="3" [isOpen]="false">
                <ng-template #parentTemplate let-limitedList>
                  <span class="badge" *ngFor="let collection of limitedList;">{{collection.name}}</span>
                </ng-template>
              </ddiq-limited-list>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="alert alert-warning" *ngIf="entries.length == 0">
      <div class="alert-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
      <p>No activity information available.</p>
    </div>

    <div *ngIf="forPrint || entries.length">
      <div class="right push-down hide-for-print">
        <button class="btn btn-primary" (click)="getIntegrityPdf()">Generate Integrity PDF</button>
      </div>
    </div>

    <div class="push-down clr" [ngSwitch]="entries.length > 0">
      <div *ngSwitchCase="true">
        <ddiq-accordion-top class="integrityRecordsTable" label="Actions" [count]="entries.length" open>
          <table>
            <thead>
              <tr>
                <th class="center integrity-column-narrow">Date</th>
                <th class="center">User</th>
                <th class="center">Action</th>
                <th class="center">Event</th>
                <th class="center integrity-column-narrow">Risk Level</th>
              </tr>
            </thead>
            <tbody *ngFor="let entry of entries; index as queryIdx">
              <tr class="integrity-row-first">
                <td class="center">
                  {{entry.entryDate | dateFormat}}
                  <br />
                  {{entry.entryDate | date : 'HH:mm:ss'}}
                </td>
                <td class="accordion-wrap">{{user.getVisibleUser(entry.username).fullName}}</td>
                <td class="accordion-wrap">
                  {{entry.action | translatef}}
                  <ul class="adjudication-reasons" *ngIf="entry.reasons">
                    <li *ngFor="let reason of unwrap(entry.reasons)">{{reason | translatef}}</li>
                  </ul>
                </td>
                <td class="accordion-wrap">
                  <ul>
                    <li *ngFor="let event of unwrap(entry.event)">{{event | translatef}}</li>
                  </ul>
                </td>
                <td class="center">{{entry.riskLevel | translatef}}</td>
              </tr>
              <tr class="integrity-row">
                <td class="accordion-wrap" colspan="6" *ngIf="entry.url">
                  <b>URL: </b><a href="{{entry.url}}" target="_blank">{{entry.url}}</a>
                </td>
              </tr>
              <tr class="integrity-row">
                <td class="accordion-wrap" colspan="6" *ngIf="entry.comment"><b>Comments: </b> {{entry.comment}}</td>
              </tr>
            </tbody>
          </table>
        </ddiq-accordion-top>
      </div>
    </div>
  </div>
</div>
