import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';

@Component({
  selector: 'ddiq-nav-search',
  template: `
    <input
      type="text"
      class="search-query"
      [(ngModel)]="searchQuery"
      (keypress)="onKeyEvent($event)"
      (keydown)="onKeyEvent($event)" />
    <i class="fa fa-search" (click)="search()"></i>
  `,
})
export default class NavSearch {
  returnKey = 13;
  searchQuery = '';
  constructor(private router: Router, private messageBusService: MessageBusService) {}

  onKeyEvent(event) {
    if (event.which === this.returnKey) {
      this.search();
      event.preventDefault();
    }
  }

  search() {
    const path = this.router.url?.split('?')[0];
    if (path !== '/profiles') {
      this.router.navigate(['/profiles'], {
        replaceUrl: true,
        queryParams: {
          pDesc: '',
          cOrder: 'date',
          cDesc: '',
          pOrder: 'date',
          q: this.searchQuery,
        },
      });
    } else {
      this.messageBusService.send({
        type: 'event:searchProfiles',
        data: {
          searchQuery: this.searchQuery,
        },
      });
    }
  }
}
