<article
  class="oiq-legal-filing"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': filing.escalated, confirmed: filing.confirmed}">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="filing"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-legal-filing-summary oiq-grid-cell">
          <ddiq-association [level]="filing.associationBucket" class="oiq-legal-filing-association"> </ddiq-association>

          <div class="text-center">
            <span
              class="oiq-legal-filing-display-date"
              *ngIf="filing.legalActivityDate"
              [ngClass]="{disabled: filing.disabled}">
              <span class="sr-only">Legal Activity Date</span>

              <time
                class="oiq-legal-filing-display-date-time"
                datetime="{{filing.legalActivityDate}}"
                [textContent]="filing.legalActivityDate | dateFormat">
              </time>
            </span>

            <div class="oiq-legal-filing-docket" *ngIf="filing.docket" [ngClass]="{disabled: filing.disabled}">
              <span>
                <span class="oiq-legal-filing-docket-label"> <span>{{'Docket' | translatef}}</span>: </span>

                <span class="oiq-legal-filing-docket-id" [textContent]="filing.docket"> </span>
              </span>
            </div>

            <ddiq-adjudication-actions
              [type]="'LEGAL_ACTIVITY'"
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [entityType]="entityAware.entityType"
              [entityId]="entityAware.entityId"
              [noReassess]="entityAware.noReassess"
              [targetId]="filing.id"
              [model]="filing">
            </ddiq-adjudication-actions>
          </div>
        </div>

        <div class="oiq-legal-filing-bd oiq-legal-filing-grid-cell">
          <header class="oiq-legal-filing-header">
            <h3 class="oiq-legal-filing-title" title="{{filing.title}}" [ngClass]="{disabled: filing.disabled}">
              <a
                class="oiq-legal-filing-title-nav"
                rel="noopener noreferrer"
                href="{{filing.documentationUrl}}"
                [textContent]="filing.title">
              </a>
            </h3>

            <div class="oiq-legal-filing-byline" [ngClass]="{disabled: filing.disabled}">
              <address class="oiq-legal-filing-byline-address">
                <a
                  class="oiq-legal-filing-byline-address-nav"
                  href="{{filing.sourceUrl}}"
                  [textContent]="filing.sourceUrl | sitename"
                  rel="author">
                </a>
              </address>
            </div>
          </header>

          <section class="oiq-legal-filing-body" [ngClass]="{disabled: filing.disabled}">
            <h4 class="oiq-legal-filing-snippet-title sr-only">Filing Snippet</h4>

            <p class="oiq-legal-filing-snippet" [textContent]="filing.documentation | stripHTML">
              Snippet Currently Unavailable
            </p>
            <div class="oiq-grid" role="grid">
              <div class="oiq-grid-row" *ngIf="filing.partyName">
                <div class="oiq-grid-hd" role="columnheader">Party Name:</div>
                <div class="oiq-grid-cell" role="gridcell" [textContent]="filing.partyName"></div>
              </div>

              <div class="oiq-grid-row" *ngIf="filing.jurisdiction">
                <div class="oiq-grid-hd" role="columnheader">Jurisdiction:</div>
                <div class="oiq-grid-cell" role="gridcell" [textContent]="filing.jurisdiction | address"></div>
              </div>

              <div class="oiq-grid-row" *ngFor="let data of filing.additionalData">
                <div class="oiq-grid-hd" role="columnheader" [textContent]="(data.label | translatef) + ':'"></div>

                <div class="oiq-grid-cell" role="gridcell">
                  <ul class="oiq-legal-filing-additional-data-values">
                    <li *ngFor="let value of data.value">
                      <span>
                        <ddiq-link [data]="value"></ddiq-link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'LEGAL_ACTIVITY'"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [noReassess]="entityAware.noReassess"
            [targetId]="filing.id"
            [model]="filing"
            [label]="'Notes'"
            [active]="filing.comment">
          </ddiq-adjudication-notes>
        </div>
      </div>
    </div>
  </div>
</article>
