enum OwnershipGraphNodeHighlight {
  NONE = 'none',
  NON_US = 'non-US',
  DYNAMIC = 'dynamic',
}

export default OwnershipGraphNodeHighlight;

export function highlightModeFromString(literal): OwnershipGraphNodeHighlight | undefined {
  const found = Object.keys(OwnershipGraphNodeHighlight)[Object.values(OwnershipGraphNodeHighlight).indexOf(literal)];

  return OwnershipGraphNodeHighlight[found] || undefined;
}
