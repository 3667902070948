import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import jQuery from 'jquery';
let $ = jQuery;

@Pipe({
  name: 'currencyMeasure',
})
export default class CurrencyMeasurePipe implements PipeTransform {
  constructor(private number: DecimalPipe) {}

  /**
   * Returns price formatted string using input object
   * @param input
   * {
   *   unit: STRING
   *   value: STRING | NUMBER
   * }
   * @returns {String}
   */
  transform(input: any) {
    let result = '';
    if (typeof input !== 'undefined') {
      /*
       * Making a couple of assumptions here:
       * - UNKNOWN currency is legacy data and has been treated as dollars historically.
       * - we display the currency code unless it is UNKNOWN
       * - input.value is a string but may represent a valid number that should be formatted
       */
      if (input.unit === 'CAD' || input.unit === 'USD' || input.unit === 'UNKNOWN') {
        result += '$';
      }
      if (input.value) {
        if ($.isNumeric(input.value)) {
          result += this.number.transform(input.value, '0.0-3');
        } else {
          result += input.value;
        }
      }
      if (input.unit && input.unit !== 'UNKNOWN') {
        result += ' (';
        result += input.unit;
        result += ')';
      }
    }

    return result;
  }
}
