//@ts-nocheck

import utils from './utils';

var global = window;

global.Exiger = global.Exiger || {};

var Exiger = global.Exiger;

Exiger.utils = utils;

// by not getting the query params from the location, this can be used to generate redirect urls anywhere in the code
Exiger.AppRedirectUrl = function AppRedirectUrl() {
  var AppRedirectUrlObj = {};
  AppRedirectUrlObj.redirectUrlStr = 'redirectUrl';
  AppRedirectUrlObj.restResourceUrlStr = 'restResourceUrl';

  AppRedirectUrlObj.getRedirectUrl = function (location, redirectUrl, restResourceUrl) {
    if (isLegacyRedirect(redirectUrl, restResourceUrl)) {
      restResourceUrl = 'lexisnexis/article/' + Exiger.Utils.subStringAfter(redirectUrl, '#/protected-articles/');

      return (
        location.origin +
        location.pathname +
        '#/protected-articles/' +
        '?restResourceUrl=' +
        encodeURIComponent(restResourceUrl)
      );
    } else if (redirectUrl.indexOf('#') === 0) {
      // make sure it's a valid hash.
      let queryString = '';
      if (restResourceUrl) {
        queryString = '?restResourceUrl=' + encodeURIComponent(restResourceUrl);
      }
      return location.origin + location.pathname + redirectUrl + queryString;
    }
  };

  // legacy Urls look like this:
  function isLegacyRedirect(redirectUrl, restResourceUrl) {
    if (restResourceUrl || redirectUrl.indexOf('#/protected-articles/') === -1) {
      // easiest check
      return false;
    }
    return !!Exiger.Utils.subStringAfter(redirectUrl, '#/protected-articles/');
  }

  return AppRedirectUrlObj;
};

var AppRedirectUrl = Exiger.AppRedirectUrl;

export default AppRedirectUrl;
