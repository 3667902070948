<span>
  <button
    class="filter-btn-show btn btn-sm"
    type="button"
    (click)="onClick('show')"
    [class]="isShowEnabled ? 'btn-primary' : 'btn-default'">
    Show
  </button>
  <button
    class="filter-btn-hide btn btn-sm"
    type="button"
    (click)="onClick('hide')"
    [class]="isHideEnabled ? 'btn-primary' : 'btn-default'">
    Hide
  </button>
</span>
