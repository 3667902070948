<div class="network-vis network-vis-building">
  <div class="network-vis-building-overlay">
    <p *ngIf="canRenderGraph" class="network-vis-building-message">
      <img [src]="'./img/loader.gif'" class="network-vis-builing-icon" />

      <span class="network-vis-building-msg-text">
        Building your graph...
        <span *ngIf="nodeCount"
          >found <strong class="network-vis-build-node-count"> {{nodeCount}} </strong> relationships.
        </span>
      </span>
    </p>
    <p *ngIf="!canRenderGraph" class="network-vis-render-error-message">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <strong>Sorry</strong>, an error occurred while trying to render the visualization. Please try again later.
    </p>
  </div>

  <div class="call-out network-call-out" style="display: none">
    <p class="call-out-content network-call-out-no-profile" *ngIf="!tombstone">No profile yet for this relation.</p>

    <div class="network-call-out-tombstone" *ngIf="tombstone">
      <div class="call-out-title">{{tombstone.name}}</div>

      <div class="call-out-content">
        <div *ngIf="tombstone.birthdate" class="call-out-section network-call-out-dob-section">
          <span class="call-out-section-title"> Birth Date: </span>
          <span class="network-call-out-dob"> {{tombstone.birthdate | dateFormat}} </span>
        </div>

        <div
          *ngIf="canShowAlternativeNames && tombstone.alternativeNames?.length"
          class="call-out-section network-call-out-tombstone-other-names">
          <span class="call-out-section-title">Other Names:</span>
          <ul>
            <li *ngFor="let otherName of tombstone.alternativeNames" class="network-tombstone-other-name">
              {{otherName}}
            </li>
          </ul>
        </div>

        <div *ngIf="tombstone.location" class="call-out-section">
          <span class="call-out-section-title">Address:</span>
          <ul>
            <li>{{tombstone.location.streetAddress}}</li>
            <li>{{tombstone.location.city}}</li>
            <li>{{tombstone.location.stateProvince}}</li>
            <li>{{tombstone.location.zipPostalCode}}</li>
            <li>{{tombstone.location.country}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
