import { Component, OnInit } from '@angular/core';
import OiqSourceInfoCtrlDirective from './oiq-source-info-ctrl.directive';
import LexisNexisArticles from '../protected-articles/lexis-nexis-articles.service';

@Component({
  selector: 'ddiq-source-info',
  templateUrl: './ddiq-source-info.component.tpl.html',
})
export default class SourceInfoComponent implements OnInit {
  sources;
  articles;
  currentEntityId;

  constructor(private lexisNexisArticles: LexisNexisArticles, private oiqSourceInfoCtrl: OiqSourceInfoCtrlDirective) {}

  ngOnInit() {
    if (this.oiqSourceInfoCtrl) {
      this.sources = this.oiqSourceInfoCtrl.sources() || [];
      this.articles = this.convertArticlesToLinks(this.oiqSourceInfoCtrl.articles() || []);
      this.currentEntityId = this.oiqSourceInfoCtrl.getCurrentEntityId();
    }
  }

  shouldShowCachedArticleLink(article) {
    if (this.oiqSourceInfoCtrl) {
      return this.oiqSourceInfoCtrl.shouldShowCachedArticleLinks() && !this.lexisNexisArticles.isLexisNexisUrl(article);
    }
  }

  convertArticlesToLinks(articles) {
    let tempArticles = articles || [];
    if (tempArticles.length > 0) {
      if (tempArticles[0] instanceof Object) {
        return tempArticles.map((article) => {
          return article.sourceUrl;
        });
      } else {
        return tempArticles;
      }
    }

    return tempArticles;
  }

  trackByIndex(index) {
    return index;
  }
}
