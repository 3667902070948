import { APP_INITIALIZER } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import ConfigService from '../../../../../shared/services/http/config.service';
import ExternalUrlMsgService from './ddiq-external-url-msg.service';
import { firstValueFrom } from 'rxjs';

export default function () {
  return {
    provide: APP_INITIALIZER,
    useFactory(httpBackend: HttpBackend, externalUrlMsgService: ExternalUrlMsgService, configService: ConfigService) {
      return () => {
        return firstValueFrom(
          new HttpClient(httpBackend).get(`${configService.settings.project.apiUrl}rest/warnexternallinks`, {
            responseType: 'json',
          })
        ).then((response: { message; url }) => {
          externalUrlMsgService.setWarningMsgConfigs(response);
        });
      };
    },
    deps: [HttpBackend, ExternalUrlMsgService, ConfigService],
    multi: true,
  };
}
