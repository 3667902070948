import $ from 'jquery';
import { Directive, ElementRef, OnInit } from '@angular/core';
import UserService from '../user/user.service';
import LogoutService from '../user/logout.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Directive({
  selector: '[login-manager]',
})
export default class LoginManagerDirective implements OnInit {
  // Whitelisted paths should not be routed to login
  private readonly whitelist = ['/request/change', '/error', '/sso-error'];

  private readonly loginSelector = '#login';
  private readonly mainViewSelector = '#main';
  private elem: HTMLElement;
  private loginRequired: Subscription;
  private loginConfirmed: Subscription;
  private showPanel: Subscription;
  private sessionTimedOut: Subscription;
  private serviceUnavailable: Subscription;
  private upgradeRequired: Subscription;
  private userPasswordFailed: Subscription;

  constructor(
    private user: UserService,
    private logout: LogoutService,
    private elementRef: ElementRef,
    private messageBus: MessageBusService,
    private location: Location
  ) {
    this.elem = elementRef.nativeElement;
  }

  ngOnInit() {
    $(this.elem).find(this.loginSelector).hide();
    this.elem.classList.remove('waiting-for-angular');

    if (!this.user.isInitialized()) {
      this.logout.logout().then(() => this.showLogin());
    }

    this.loginRequired = this.messageBus.on('event:auth-loginRequired', () => this.showLogin());
    this.loginConfirmed = this.messageBus.on('event:auth-loginConfirmed', () => this.hideLogin());
    this.showPanel = this.messageBus.on('event:showPanels', () => this.showThePanels());
    this.sessionTimedOut = this.messageBus.on('event:session-timed-out', () => this.showPanels());

    this.serviceUnavailable = this.messageBus.on('event:service-unavailable', () => {
      this.user.clear();
      this.showPanels();
    });

    this.upgradeRequired = this.messageBus.on('event:upgrade-required', () => this.showPanels());
    this.userPasswordFailed = this.messageBus.on('event:update-user-password-failed', () => this.showLogin());
  }

  ngOnDestroy() {
    this.loginRequired.unsubscribe();
    this.loginConfirmed.unsubscribe();
    this.showPanel.unsubscribe();
    this.sessionTimedOut.unsubscribe();
    this.serviceUnavailable.unsubscribe();
    this.upgradeRequired.unsubscribe();
    this.userPasswordFailed.unsubscribe();
  }

  isWhitelisted(path: string) {
    return this.whitelist.some((entry) => {
      return path?.includes(entry);
    });
  }

  showPanels() {
    $(this.elem).find(this.loginSelector).slideDown();
    $(this.elem).find(this.mainViewSelector).fadeOut('fast');
  }

  showLogin() {
    if (!this.isWhitelisted(this.location.path())) {
      this.showPanels();
    }
  }

  hideLogin() {
    $(this.elem).find(this.loginSelector).slideUp();
    $(this.elem).find(this.mainViewSelector).fadeIn('slow');
  }

  showThePanels() {
    this.showPanels();
  }
}
