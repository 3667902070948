import { NgModule } from '@angular/core';
import ConfirmModalComponent from './ddiq-confirm-modal.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [ConfirmModalComponent],
  exports: [ConfirmModalComponent],
})
export class DdiqConfirmationDialogModule {}
