import UserService from './user.service';
import LogoutService from './logout.service';
import CookieService from './cookie.service';
import { Injector, NgModule } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';

@NgModule({
  providers: [UserService, CookieService, LogoutService],
})
export class OiqUserModule {
  constructor(private injector: Injector) {}

  configure() {
    const messageBusService: MessageBusService = this.injector.get(MessageBusService);
    const user: UserService = this.injector.get(UserService);

    messageBusService.on('event:auth-loginRequired', () => {
      user.clear();
    });
  }
}
