import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-person-related-companies-form',
  templateUrl: './ddiq-person-related-companies-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class DdiqPersonRelatedCompaniesFormComponent implements OnInit {
  @Input() companies: any;
  @Input() countries: Array<string>;

  @Output() companiesChange = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {
    if (this.companies.length === 0 || Object.keys(this.companies[0]).length === 0) {
      //initialise empty address parent object
      this.companies[0] = {};
    }
    this.ensureAddressModel();
  }

  remove(index) {
    this.companies.splice(index, 1);
  }

  addNew() {
    let emptyCompaniesForm = {
      address: {},
    };
    this.companies.push(emptyCompaniesForm);
  }

  private ensureAddressModel() {
    this.companies.forEach((company) => {
      if (company.address) {
        return;
      }

      company.address = {
        streetAddress: '',
        city: '',
        stateOrProvince: '',
        zipOrPostalCode: '',
        country: '',
      };
    });
  }
}
