import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'associationTooltip',
})
export default class AssociationTooltipPipe implements PipeTransform {
  constructor() {}

  /**
   * Returns a matching string describing association level based on input
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    let result = 'POSSIBLY_ASSOCIATED';
    if (typeof input !== 'undefined') {
      if (input === 'high') {
        return 'VERY_LIKELY_ASSOCIATED';
      } else if (input === 'medium') {
        return 'LIKELY_ASSOCIATED';
      } else if (input === 'unlikely') {
        return 'UNLIKELY_ASSOCIATED';
      } else if (input === 'unassociated') {
        return 'NOT_ASSOCIATED';
      }
    }
    return result;
  }
}
