import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'lexisNexisArticleId',
})
export default class LexisNexisArticleIdPipe implements PipeTransform {
  transform(url) {
    let id,
      split = (url && url.split('article/')) || [];

    if (split.length > 1) {
      id = split[split.length - 1];
    } else {
      id = '';
    }

    return id;
  }
}
