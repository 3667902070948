import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class CheckContentService {
  constructor() {}

  checkForContent(data, ignoreConfigProperties) {
    const ignore = {
      metadata: true,
      noncontent: true,
      display: true,
      $$hashKey: true,
      oiqCreatedDate: true,
      _display: true,
    };

    if (!data) {
      return false;
    }

    // i guess we could have an array of empty objects...
    // but for now we'll assume we don't
    if (Array.isArray(data)) {
      return data.length > 0;
    }

    // we've found a property
    if (typeof data !== 'object') {
      return true;
    }

    // check to see if we've already found content here
    if (data.hasContent) {
      return true;
    }

    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!ignore[key]) {
        if (this.checkForContent(data[key], ignoreConfigProperties)) {
          return true;
        }
      }
    }
    return false;
  }
}
