import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class Theme {
  private brandTheme;
  private pageConfig;
  private themePath;
  dictionary;

  setConfig(config) {
    this.brandTheme = config.theme;
    this.pageConfig = config.pageConfig;
    this.themePath = config.themePath;
    this.dictionary = config.dictionary;
  }

  isCustomerBrand() {
    return !this.brandTheme.name.match(/ddiq/i);
  }

  getCssUrl() {
    return this.themePath + '/theme.css';
  }

  getLogoUrl(size) {
    return `${this.themePath}/img/logos/logo-${size}${this.pageConfig.logoImgExt}`;
  }

  getPageTitle() {
    return this.pageConfig.pageTitle;
  }

  getBrandMsg() {
    return this.pageConfig.brandMsg;
  }

  getAltTxt() {
    return this.pageConfig.logoImgAltTxt;
  }

  getPageHrefFor(name) {
    return (this.pageConfig.pages || {})[name];
  }
}
