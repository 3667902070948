import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class AlertSummarizer {
  summaryAlerts = {
    count: undefined,
    list: undefined,
  };

  constructor() {
    this.clear();
  }

  clear() {
    this.summaryAlerts.count = {};
    this.summaryAlerts.list = {};
  }

  addAlertCount(theme, count) {
    this.summaryAlerts.count[theme] = count;
  }

  addAlertList(theme, list) {
    this.summaryAlerts.list[theme] = list;
  }
}
