const Paths = {
  NOT_FOUND: '404',
  ERROR: 'error',
  SSO_ERROR: 'sso-error',
  PROFILE: 'overview/:overviewId/:entityType/:entityId',
  PROFILE_COMPARABLE: 'overview/:overviewId/:entityType/:entityId/diff/:comparableId',
  CORPORATE_GRAPH: 'corporate-graph/:entityId/:entityType/:recordId/:graphType',
  NETWORK_GRAPH: 'network/:entityId/:entityType',
  CACHED_ARTICLE: 'cached-article',
  PROTECTED_ARTICLES: 'protected-articles',
  SELECTED_ARTICLES: 'selected-articles/:entityType/:entityId/articles/:selectedArticlesId',
  AUDIT: 'audit/:entityType/:entityId',
  INTEGRITY: 'integrity/:entityType/:entityId',
  MONITOR_REPORT: 'monitor-report',
  MONITOR: 'monitor',
  COVER: 'cover/:overviewId/:entityType/:entityId',
  COVER_COMPARABLE: 'cover/:overviewId/:entityType/:entityId/diff/:comparableId',
  COVER_ARTICLES: 'cover/:entityType/:entityId/articles/:selectedArticlesId',
  GOVERNMENT_PROCUREMENT: 'government-procurement/:entityId',
  VERSION: 'version',
  ADMIN: 'admin',
  CONFIGURATION: 'configuration',
  CHANGE_PASSWORD: 'request/:action/:token',
  BUILD_PERSON: 'build/person',
  BUILD_COMPANY: 'build/company',
  PROFILES: 'profiles',
  HOME: 'home',
};

export default Paths;
