import { Component, Input } from '@angular/core';

@Component({
  selector: 'oiq-protected-article-pdf-button',
  template: ` <div class="protected-article-pdf-btn">
    <a href="{{ pdfUrl }}" class="btn btn-primary btn-lg"> Download PDF </a>
  </div>`,
})
export default class ProtectedArticlePdfButtonComponent {
  @Input() pdfUrl;
}
