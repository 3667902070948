import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class TypeLookup {
  private cache = {};

  get(id) {
    return this.cache[id];
  }

  set(id, type) {
    this.cache[id] = type;
  }
}
