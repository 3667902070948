<div login-manager embed class="ddiq-main modern waiting-for-angular" data-theme-pg-title>
  <div id="login" class="container">
    <ddiq-login></ddiq-login>
    <ddiq-footer></ddiq-footer>
  </div>

  <div id="main">
    <ddiq-main-nav [hidden]="hideNav"></ddiq-main-nav>
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="canDisplayWarningMsg" id="external-url-msg-target"></div>
