import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import OiqProperties from '../common-services/oiq-properties.service';
import { EmbedService } from './embed.service';

export const embedGuard = (routeSnapshot: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const embedService = inject(EmbedService);
  const oiqProperties = inject(OiqProperties);

  return oiqProperties.apiPropertiesPromise.then(() => {
    if (!embedService.canActivate(routeSnapshot.routeConfig.path)) {
      return router.parseUrl('/404');
    }

    return true;
  });
};
