import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import UpgradeVersionComponent from './upgrade-version.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UpgradeVersionComponent],
  providers: [WindowRefService, { provide: 'WINDOW_TOKEN', useValue: window }],
})
export class UpgradeVersionModule {}
