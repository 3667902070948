<oiq-drop-down class="highlight capitalize">
  <oiq-drop-down-toggle>
    <span class="toggle-text">Sort By: <span class="sort-by-text">{{labelText[sortBy.field]}}</span></span>
  </oiq-drop-down-toggle>
  <oiq-drop-down-items (click)="sortByName(query)">Name</oiq-drop-down-items>
  <oiq-drop-down-items (click)="sortByOwner(query)">Owner</oiq-drop-down-items>
  <oiq-drop-down-items (click)="sortByDate(query)">Latest Match</oiq-drop-down-items>
</oiq-drop-down>
<i
  class="asc-desc-btn"
  [ngClass]="'fa fa-sort-amount-' + sortBy.order"
  [title]="sortBy.order + 'ending'"
  (click)="toggleAscDesc()"
  aria-hidden="true"></i>
