import { Injectable } from '@angular/core';
import TranslateService from '../common-services/translate.service';
import EventGroupSort from '../event-groups/event-group-sort.service';
import EventGroupsTemplate from '../event-groups/event-groups-template';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';
import EventGroupBuilderService from './event-group-builder.service';
import EventGroupsBuilderService from './event-groups-builder.service';

@Injectable({
  providedIn: 'root',
})
export default class RegulatoryProfileEventGroupsService extends EventGroupsTemplate {
  eventGroupSorts;

  constructor(
    private eventGroupBuilderService: EventGroupBuilderService,
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private structuredDataSorts: StructuredDataSorts,
    private translateService: TranslateService,
    private eventGroupSort: EventGroupSort
  ) {
    super('regulatoryProfilesEventGroups', eventGroupBuilderService, eventGroupsBuilderService);

    this.eventGroupSorts = {
      association(corporateRecords) {
        this.structuredDataSorts.sortByAssociation(corporateRecords);
      },
      adjudication(corporateRecords) {
        this.structuredDataSorts.sortByAdjudication(corporateRecords);
      },
    };
  }

  addEventGroupsToSection() {
    return (section) => {
      const data = section.data;
      const regulatoryProfilesByClassification = data.regulatoryProfiles;

      if (!regulatoryProfilesByClassification) return;

      const eventGroups = Object.keys(regulatoryProfilesByClassification).map((classification) => {
        const regulatoryProfiles = regulatoryProfilesByClassification[classification];
        const translatedClassification = this.translateService.translate(classification);

        return this.eventGroupBuilderService
          .newBuilder()
          .withDiscoveries(regulatoryProfiles)
          .withTitle(translatedClassification)
          .add('severity', regulatoryProfiles[0].severity)
          .add('classification', classification)
          .add('translatedClassification', translatedClassification)
          .build();
      });

      data[this.eventGroupsName] = this.eventGroupsBuilderService
        .newEventGroupsModel(eventGroups)
        .withSort((eventGroups) => this.eventGroupSort.sort(eventGroups))
        .build();
    };
  }
}
