import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import AdjudicationFeatureService from './adjudication-feature.service';
import RelationshipService from '../common-services/relationship-service.factory';

@Component({
  selector: 'related-entity-group-dynamic-adjudication-aware',
  template: `<oiq-group-dynamic-adjudication-aware
    *ngIf="isEnabled"
    [section]="entities"
    [adjudicationType]="'RELATIONSHIP'">
  </oiq-group-dynamic-adjudication-aware>`,
})
export default class RelatedEntityGroupDynamicAdjudicationAware implements OnInit {
  @Input() entities: any;
  @Output() entitiesValueChange = new EventEmitter<any>();
  isEnabled: boolean;

  constructor(
    private relationshipService: RelationshipService,
    private adjudicationFeature: AdjudicationFeatureService
  ) {}

  ngOnInit() {
    this.isEnabled =
      !this.relationshipService.hasUserSubmitted(this.entities) &&
      this.adjudicationFeature.isRelatedEntityAdjudicationEnabled();
  }
}
