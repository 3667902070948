import OshaInspectionComponent from './ddiq-osha-inspection.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import OiqAssociationModule from '../association/oiq-association.module';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import OshaInspectionsGroupComponent from './ddiq-osha-inspections-group.component';
import OiqSourcesModule from '../sources/oiq-sources.module';

@NgModule({
  imports: [
    CommonModule,
    OiqAssociationModule,
    OiqAdjudicationModule,
    PipesModule,
    DdiqDirectivesModule,
    DdiqUIModule,
    OiqSourcesModule,
  ],
  declarations: [OshaInspectionComponent, OshaInspectionsGroupComponent],
  exports: [OshaInspectionsGroupComponent],
})
export class OshaInspectionsModule {}
