import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import DdiqAssociationModule from '../association/oiq-association.module';
import { DdiqCommonModule } from '../common/oiq-common.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import GovernmentAwardComponent from './oiq-government-award.component';
import GovernmentAwardsComponent from './oiq-government-awards.component';
import GovernmentAwards from './oiq-government-awards.service';
import GovernmentProcurementLinkComponent from './oiq-government-procurement-link.component';
import GovernmentProcurementSeedDataComponent from './oiq-government-procurement-seed-data.component';
import GovernmentProcurement from './oiq-government-procurement.component';

@NgModule({
  imports: [CommonModule, DdiqAssociationModule, PipesModule, DdiqCommonModule],
  providers: [GovernmentAwards],
  declarations: [
    GovernmentProcurement,
    GovernmentAwardsComponent,
    GovernmentAwardComponent,
    GovernmentProcurementSeedDataComponent,
    GovernmentProcurementLinkComponent,
  ],
  exports: [GovernmentProcurementLinkComponent, GovernmentProcurement],
})
export class DdiqGovernmentProcurementModule {}
