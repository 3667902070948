import { Injectable, Injector } from '@angular/core';
import StructuredDataArrayExtractionStrategyService from './structured-data-array-extraction-strategy.service';
import StructuredDataByClassExtractionStrategyService from './structured-data-by-class-extraction-strategy.service';
import { Constant } from '../common/ddiq-constants';

@Injectable({
  providedIn: 'root',
})
export default class StructuredDataExtractionStrategyService {
  private strategies: any = {
    classification: StructuredDataByClassExtractionStrategyService,
    incident: StructuredDataArrayExtractionStrategyService,
  };

  constructor(private injector: Injector) {
    this.strategies.cluster = this.strategies.classification;
  }

  getStrategy(webContentType?) {
    return this.injector.get(this.strategies[webContentType || Constant.WEB_CONTENT_TYPE]);
  }
}
