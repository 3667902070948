import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import NotFoundComponent from './modules/oiq/common/ddiq-not-found.component';
import BuildPersonResolverService from './modules/oiq/build-person/build-person-resolver.service';
import BuildPersonComponent from './modules/oiq/build-person/ddiq-build-person.component';
import BuildCompanyComponent from './modules/oiq/build-company/ddiq-build-company.component';
import BuildCompanyResolverService from './modules/oiq/build-company/build-company-resolver.service';
import ProfileComponent from './modules/oiq/profile/ddiq-profile.component';
import ComparableProfileResolverService from './modules/oiq/profile/comparable-profile-resolver.service';
import ProfileResolverService from './modules/oiq/profile/profile-resolver.service';
import HomeComponent from './modules/oiq/common/ddiq-home.component';
import HomeResolver from './modules/oiq/common/ddiq-home-resolver.service';
import ChangePasswordWithTokenComponent from './modules/oiq/common/ddiq-change-password-with-token.component';
import ErrorComponent from './modules/oiq/common/ddiq-error.component';
import SsoErrorComponent from './modules/oiq/common/ddiq-sso-error.component';
import ProfileLibraryComponent from './modules/oiq/profile-library/ddiq-profile-library.component';
import ProfileLibraryResolverService from './modules/oiq/profile-library/profile-library-resolver.service';
import AuditComponent from './modules/oiq/audit/ddiq-audit.component';
import AuditResolver from './modules/oiq/audit/audit-resolver.service';
import IntegrityComponent from './modules/oiq/integrity/ddiq-integrity.component';
import IntegrityResolver from './modules/oiq/integrity/integrity-resolver.service';
import CoverComponent from './modules/oiq/cover/ddiq-cover.component';
import CoverResolver from './modules/oiq/cover/cover-resolver.service';
import VersionComponent from './modules/oiq/version/ddiq-version.component';
import VersionResolverService from './modules/oiq/version/version-resolver.service';
import CorporateGraphVis from './modules/oiq/corporate-graph/corporate-graph-vis.component';
import CorporateGraphResolver from './modules/oiq/corporate-graph/corporate-graph-resolver.service';
import AdminComponent from './modules/oiq/admin/ddiq-admin.component';
import AdminResolverService from './modules/oiq/admin/admin-resolver.service';
import MonitorSearchComponent from './modules/oiq/monitor/ddiq-monitor-search.component';
import MonitorResolverService from './modules/oiq/monitor/monitor-resolver.service';
import MonitorReportContainer from './modules/oiq/monitor/ddiq-monitor-report-container.component';
import ConfigurationComponent from './modules/oiq/configuration/ddiq-configuration.component';
import ConfigurationResolver from './modules/oiq/configuration/configuration-resolver.service';
import CachedArticleResolver from './modules/oiq/article-cache/cached-article-resolver.service';
import CachedArticleComponent from './modules/oiq/article-cache/cached-article.component';
import NetworkComponent from './modules/oiq/network/ddiq-network.component';
import NetworkResolverService from './modules/oiq/network/network-resolver.service';
import GovernmentProcurement from './modules/oiq/government-procurement/oiq-government-procurement.component';
import SelectedArticlesComponent from './modules/oiq/protected-articles/ddiq-selected-articles.component';
import SelectedArticlesResolver from './modules/oiq/protected-articles/selected-articles-resolver.service';
import ProtectedArticlesComponent from './modules/oiq/protected-articles/ddiq-protected-articles.component';
import { embedGuard } from './modules/oiq/embed/embed.guard';
import Paths from './modules/oiq/common/paths.constants';

const routes: Routes = [
  { path: Paths.NOT_FOUND, component: NotFoundComponent, canActivate: [embedGuard] },
  { path: Paths.ERROR, component: ErrorComponent, canActivate: [embedGuard] },
  { path: Paths.SSO_ERROR, component: SsoErrorComponent, canActivate: [embedGuard] },
  {
    path: Paths.INTEGRITY,
    component: IntegrityComponent,
    resolve: { preLoad: IntegrityResolver },
    canActivate: [embedGuard],
  },
  {
    path: Paths.COVER_COMPARABLE,
    component: CoverComponent,
    resolve: {
      preLoad: CoverResolver,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.COVER,
    component: CoverComponent,
    resolve: {
      preLoad: CoverResolver,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.COVER_ARTICLES,
    component: CoverComponent,
    resolve: {
      preLoad: CoverResolver,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.VERSION,
    component: VersionComponent,
    resolve: { preLoad: VersionResolverService },
    canActivate: [embedGuard],
  },
  {
    path: Paths.CORPORATE_GRAPH,
    component: CorporateGraphVis,
    resolve: {
      preLoad: CorporateGraphResolver,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.MONITOR_REPORT,
    component: MonitorReportContainer,
    resolve: { preLoad: MonitorResolverService },
    canActivate: [embedGuard],
  },
  {
    path: Paths.MONITOR,
    component: MonitorSearchComponent,
    resolve: { preLoad: MonitorResolverService },
    canActivate: [embedGuard],
  },
  {
    path: Paths.ADMIN,
    component: AdminComponent,
    resolve: { preLoad: AdminResolverService },
    canActivate: [embedGuard],
  },
  {
    path: Paths.CONFIGURATION,
    component: ConfigurationComponent,
    resolve: { preLoad: ConfigurationResolver },
    canActivate: [embedGuard],
  },
  {
    path: Paths.CACHED_ARTICLE,
    component: CachedArticleComponent,
    resolve: { preLoad: CachedArticleResolver },
    canActivate: [embedGuard],
  },
  { path: Paths.CHANGE_PASSWORD, component: ChangePasswordWithTokenComponent },
  {
    path: Paths.BUILD_PERSON,
    component: BuildPersonComponent,
    resolve: {
      preLoad: BuildPersonResolverService,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.BUILD_COMPANY,
    component: BuildCompanyComponent,
    resolve: {
      preLoad: BuildCompanyResolverService,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.PROFILE,
    component: ProfileComponent,
    resolve: {
      preLoad: ProfileResolverService,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.PROFILE_COMPARABLE,
    component: ProfileComponent,
    resolve: {
      preLoad: ComparableProfileResolverService,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.PROFILES,
    component: ProfileLibraryComponent,
    resolve: {
      preLoad: ProfileLibraryResolverService,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.AUDIT,
    component: AuditComponent,
    resolve: {
      preLoad: AuditResolver,
    },
    canActivate: [embedGuard],
  },
  {
    path: Paths.NETWORK_GRAPH,
    component: NetworkComponent,
    resolve: {
      entity: NetworkResolverService,
    },
    canActivate: [embedGuard],
  },
  { path: Paths.GOVERNMENT_PROCUREMENT, component: GovernmentProcurement, canActivate: [embedGuard] },
  {
    path: Paths.SELECTED_ARTICLES,
    component: SelectedArticlesComponent,
    resolve: { preLoad: SelectedArticlesResolver },
    canActivate: [embedGuard],
  },
  { path: Paths.PROTECTED_ARTICLES, component: ProtectedArticlesComponent, canActivate: [embedGuard] },

  // The order of routes is important because the Router uses a first-match wins strategy
  // The wildcard route comes last because it matches every URL and the Router selects it only if no other routes match first.
  { path: Paths.HOME, component: HomeComponent, resolve: { preLoad: HomeResolver }, canActivate: [embedGuard] },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
