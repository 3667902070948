import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'firstUnfilteredDiscovery',
})
export default class FirstUnfilteredDiscoveryPipe implements PipeTransform {
  transform(discoveries) {
    if (!discoveries) {
      return [];
    }

    for (const discovery of discoveries) {
      if (!discovery.filtered) {
        return [discovery];
      }
    }
    return [];
  }
}
