import { PipeTransform, Pipe } from '@angular/core';
import SourceUrl from './source-url.service';

@Pipe({
  name: 'sourceUrlRedirect',
})
export default class SourceUrlRedirectPipe implements PipeTransform {
  constructor(private sourceUrl: SourceUrl) {}

  transform(url: string): any {
    return this.sourceUrl.getRedirectUrl(url) || url;
  }
}
