import SystemUseNoticeService from './system-use-notice.service';
import { APP_INITIALIZER } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import ConfigService from '../../../../../shared/services/http/config.service';

export default function () {
  return {
    provide: APP_INITIALIZER,
    useFactory(httpBackend: HttpBackend, systemUseNoticeService: SystemUseNoticeService, configService: ConfigService) {
      return () => {
        return new HttpClient(httpBackend)
          .get(`${configService.settings.project.apiUrl}rest/systemusenotice`, { responseType: 'text' })
          .toPromise()
          .then((response: string) => {
            systemUseNoticeService.setNotice(response);
          });
      };
    },
    deps: [HttpBackend, SystemUseNoticeService, ConfigService],
    multi: true,
  };
}
