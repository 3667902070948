<div *ngIf="numPages > 1">
  <p class="page-totals">{{tableTotal}} {{totalLabel}}<span *ngIf="numPages > 1"> on {{numPages}} pages</span></p>
  <ul class="pager">
    <li
      *ngFor="let page of pages"
      [class.current]="page.active"
      [class.disabled]="page.disabled"
      class="{{page.cssClass || ''}}"
      (click)="selectPage(page.number)">
      <span [class.current]="page.active" *ngIf="isNumber(page.text)">{{page.text}}</span>
    </li>
    <br class="clr" />
  </ul>
</div>
