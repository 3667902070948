import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqAssociationModule from '../association/oiq-association.module';
import { CodeSchemaModule } from '../code-schema/oiq-code-schema.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqRegulatoryProfilesGroupComponent from './ddiq-regulatory-profiles-group.component';
import DdiqRegulatoryProfileComponent from './ddiq-regulatory-profile.component';
import SourceUrlRedirectPipe from './source-url-redirect.pipe';
import SourceUrl from './source-url.service';
import AdviserInfoUrl from './adviser-info-url.service';
import { OiqRiskModule } from '../risk/oiq-risk.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    DdiqUIModule,
    OiqAdjudicationModule,
    OiqAssociationModule,
    DdiqDirectivesModule,
    CodeSchemaModule,
    OiqSourcesModule,
    OiqRiskModule,
  ],
  providers: [SourceUrl, AdviserInfoUrl],
  declarations: [SourceUrlRedirectPipe, DdiqRegulatoryProfileComponent, DdiqRegulatoryProfilesGroupComponent],
  exports: [DdiqRegulatoryProfileComponent, DdiqRegulatoryProfilesGroupComponent],
})
export default class OiqRegulatoryProfilesModule {}
