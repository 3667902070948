import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-association',
  templateUrl: './ddiq-association.component.tpl.html',
})
export default class AssociationComponent {
  @Input() level;

  hasLevel() {
    return !!this.level;
  }
}
