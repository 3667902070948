function isAllFiltered(entities) {
  entities = entities || [];

  const filtered = entities.filter((entity) => {
    return !!entity.filtered;
  });

  return entities.length > 0 && entities.length === filtered.length;
}

export default isAllFiltered;
