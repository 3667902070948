import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import moment from 'moment';

@Pipe({
  name: 'sortableDate',
})
export default class SortableDatePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  /**
   * Converts a String or an Object to a readable date format
   * @param input
   * {
   *    month: NUMBER,
   *    day: NUMBER,
   *    year: NUMBER
   * }
   * @returns {String}
   */
  transform(input: any) {
    let mmt,
      utc,
      fixedInput = input,
      formatted;

    if (typeof input === 'object' && input.month) {
      fixedInput = { ...input };
      fixedInput.month = fixedInput.month - 1;
    }

    mmt = moment(fixedInput);
    utc = mmt.utc();

    if (!utc.hour() && !utc.seconds() && !utc.milliseconds()) {
      //looks like utc
      formatted = utc.format('ll');
    } else if (typeof input === 'object') {
      if (input.year && input.month && input.day) {
        formatted = mmt.format('gggg MM DD');
      } else if (!input.year && input.month && input.day) {
        formatted = mmt.format('MMM DD');
      } else if (input.year) {
        formatted = mmt.format('gggg');
      } else {
        //h:mm:ss a
        formatted = this.date.transform(input, 'mediumDate');
      }
    } else {
      // string with non-UTC date
      formatted = this.date.transform(input, 'mediumDate');
    }

    return formatted;
  }
}
