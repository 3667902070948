<div class="input">
  <label [ngClass]="{required: isRequired}">{{label}}</label>
  <ng-multiselect-dropdown
    name="profile-collection-list"
    [placeholder]="'Select Profile Collection(s)'"
    [settings]="dropdownSettings"
    [data]="dropdownList"
    [(ngModel)]="selectedItems"
    [disabled]="isDisabled"
    (onSelect)="onItemSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelect)="onItemDeselect($event)"
    (onDeSelectAll)="onDeselectAll($event)"
    (ngModelChange)="onChange($event)"
    [ngClass]="{'required-and-invalid': isInvalid}">
  </ng-multiselect-dropdown>
</div>
