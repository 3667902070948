import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ddiq-inmate-profiles-group',
  templateUrl: './ddiq-inmate-profiles-group.component.tpl.html',
})
export default class DdiqInmateProfileComponent {
  @Input() groups: any;
  @Input() idPrefix: any;
  @Output() sortDiscoveries = new EventEmitter<any>();
  @Input() expandAll: any;

  constructor() {}

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  id(index: number, profile: any) {
    return profile.id;
  }

  classification(index: number, group: any) {
    return group.title;
  }
}
