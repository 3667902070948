<article
  [id]="record.lookupId"
  class="oiq-corporate-record"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': record.escalated, confirmed: record.confirmed}"
  data-oiq-collapsible-ctrl
  [expandAll]="record.isOpen">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="record"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-corporate-record-summary oiq-grid-cell">
          <ddiq-association [level]="record.associationBucket" class="oiq-corporate-record-association">
          </ddiq-association>

          <span
            *ngIf="record.incorporationDateFuzzy"
            class="oiq-corporate-record-display-date"
            [ngClass]="{disabled: record.disabled}">
            <span *ngIf="record.incorporationDateFuzzy">
              <span class="oiq-corporate-record-display-date-label sr-only"> Incorporation Date </span>

              <time
                class="oiq-corporate-record-display-date-time"
                datetime="{{record.incorporationDateFuzzy}}"
                [textContent]="record.incorporationDateFuzzy | dateFormat">
              </time>
            </span>

            <span *ngIf="record.positions && record.positions.length && record.positions[0].startDate">
              <span class="oiq-corporate-record-display-date-label sr-only"> Position Start: </span>

              <time
                class="oiq-corporate-record-display-date-time"
                datetime="{{record.positions[0].startDate}}"
                [textContent]="record.positions[0].startDate | dateFormat">
              </time>
            </span>
          </span>

          <ddiq-adjudication-actions
            *ngIf="isAdjudicateable && !entityAware.isPreview"
            [type]="adjudicationType"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [targetId]="record.id"
            [model]="record">
          </ddiq-adjudication-actions>
        </div>

        <div class="oiq-corporate-record-bd oiq-grid-cell">
          <article class="oiq-corporate-record-grid" [ngClass]="{disabled: record.disabled}">
            <div
              class="oiq-corporate-record-row"
              *ngIf="record.name || record.firstName || record.middleName || record.lastName"
              role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Name:</div>

              <div class="oiq-corporate-record-cell" role="gridcell">
                <span *ngIf="record.name" [textContent]="record.name"></span>
                <span *ngIf="!record.name"> {{record.firstName}} {{record.middleName}} {{record.lastName}} </span>
              </div>
            </div>

            <div class="oiq-corporate-record-row" role="row">
              <div
                class="oiq-corporate-record-hd"
                role="columnheader"
                *ngIf="record.seedSource.sourceUrl || record.seedSource.source">
                Source:
              </div>

              <div class="oiq-corporate-record-cell" role="gridcell">
                <a
                  *ngIf="record.seedSource.sourceUrl"
                  href="{{record.seedSource.sourceUrl | protectedArticleHref}}"
                  target="_blank"
                  class="inline"
                  rel="noopener noreferrer"
                  [textContent]="record.seedSource.source | translatef">
                </a>

                <span *ngIf="!record.seedSource.sourceUrl" [textContent]="record.seedSource.source | translatef">
                </span>
              </div>
            </div>

            <div
              class="oiq-corporate-record-row"
              role="row"
              *ngIf="record.positions && record.positions.length && record.positions[0].title">
              <div class="oiq-corporate-record-hd" role="columnheader">Position:</div>
              <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.positions[0].title"></div>
            </div>

            <div
              class="oiq-corporate-record-row"
              role="row"
              *ngIf="record.positions && record.positions.length && record.positions[0].organization">
              <div class="oiq-corporate-record-hd" role="columnheader">Company:</div>
              <div
                class="oiq-corporate-record-cell"
                role="gridcell"
                [textContent]="record.positions[0].organization"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.address" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Address:</div>

              <div class="oiq-corporate-record-cell" role="gridcell">
                <span [textContent]="record.address | address"></span>
                <span class="oiq-corporate-record-grid">
                  <span
                    class="oiq-corporate-record-row"
                    role="row"
                    *ngIf="record.address.coordinates && record.address.coordinates.latitude">
                    <span class="oiq-corporate-record-address-latlng oiq-corporate-record-hd" role="columnheader">
                      Latitude:
                    </span>

                    <span class="oiq-corporate-record-cell" role="gridcell">
                      {{record.address.coordinates.latitude}}
                    </span>
                  </span>

                  <span
                    class="oiq-corporate-record-row"
                    role="row"
                    *ngIf="record.address.coordinates && record.address.coordinates.longitude">
                    <span class="oiq-corporate-record-address-latlng oiq-corporate-record-hd" role="columnheader">
                      Longitude:
                    </span>

                    <span class="oiq-corporate-record-cell" role="gridcell">
                      {{record.address.coordinates.longitude}}
                    </span>
                  </span>
                </span>
              </div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.location" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Address:</div>
              <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.location | address"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.positions && record.positions.length" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Status:</div>
              <div class="oiq-corporate-record-cell" role="gridcell">
                <span>{{record.positions[0].isCurrent && 'Current' || 'Inactive'}}</span>
              </div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.positions && record.positions.length" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Positions:</div>
              <div class="oiq-corporate-record-cell" role="gridcell">
                <table>
                  <thead>
                    <tr>
                      <th translate>Organization Name</th>
                      <th translate>Title</th>
                      <th translate>Start Date</th>
                      <th translate>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let position of record.positions">
                      <td class="oiq-corporate-record-positions-organization">
                        {{ position.organization }}<span *ngIf="!position.organization" translate>--</span>
                      </td>
                      <td class="oiq-corporate-record-positions-title">
                        {{ position.title }}<span *ngIf="!position.title" translate>--</span>
                      </td>
                      <td class="oiq-corporate-record-positions-startDate">
                        {{ position.startDate | dateFormat }}<span *ngIf="!position.startDate" translate>--</span>
                      </td>
                      <td class="oiq-corporate-record-positions-endDate">
                        {{ position.endDate | dateFormat }}<span *ngIf="!position.endDate" translate>--</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.education && record.education.length" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Education:</div>
              <div class="oiq-corporate-record-cell" role="gridcell">
                <table>
                  <thead>
                    <tr>
                      <th translate>Institution</th>
                      <th translate>Degree</th>
                      <th translate>Start Year</th>
                      <th translate>End Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let education of record.education">
                      <td class="oiq-corporate-record-education-institution">
                        {{ education.institution }}<span *ngIf="!education.institution" translate>--</span>
                      </td>
                      <td class="oiq-corporate-record-education-degree">
                        {{ education.degree }}<span *ngIf="!education.degree" translate>--</span>
                      </td>
                      <td class="oiq-corporate-record-education-startYear">
                        {{ education.startDate | yearFormat }}<span *ngIf="!education.startDate" translate>--</span>
                      </td>
                      <td class="oiq-corporate-record-education-endYear">
                        {{ education.endDate | yearFormat }}<span *ngIf="!education.endDate" translate>--</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.mailingAddress" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Mailing Address:</div>
              <div
                class="oiq-corporate-record-cell"
                role="gridcell"
                [textContent]="record.mailingAddress | address"></div>
            </div>

            <ng-container *ngFor="let identificationNumber of record.identificationNumbers">
              <div
                class="oiq-corporate-record-row-group"
                *ngIf="identificationNumber.identificationType && identificationNumber.number">
                <div class="oiq-corporate-record-row" role="row">
                  <div class="oiq-corporate-record-hd" role="columnheader">
                    {{identificationNumber.identificationType}}:
                  </div>
                  <div
                    class="oiq-corporate-record-cell"
                    role="gridcell"
                    [textContent]="identificationNumber.number"></div>
                </div>

                <div class="oiq-corporate-record-row" role="row" *ngIf="identificationNumber.issuingBody">
                  <div class="oiq-corporate-record-hd" role="columnheader">Issuing Body:</div>
                  <div
                    class="oiq-corporate-record-cell"
                    role="gridcell"
                    [textContent]="identificationNumber.issuingBody"></div>
                </div>
              </div>
            </ng-container>

            <div class="oiq-corporate-record-row" *ngIf="record.registrationNumber" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">
                {{record.seedSource.source === 'DNB_DIRECT' ? 'DUNS #:' : 'Registration #:'}}
              </div>
              <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.registrationNumber"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.registrationStatus" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Status:</div>
              <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.registrationStatus"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.registrationJurisdiction" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Jurisdiction:</div>
              <div
                class="oiq-corporate-record-cell"
                role="gridcell"
                [textContent]="record.registrationJurisdiction"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.incorporationDateFuzzy" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Incorporated:</div>
              <div
                class="oiq-corporate-record-cell"
                role="gridcell"
                [textContent]="record.incorporationDateFuzzy | dateFormat"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.registrationDateFuzzy" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Registered:</div>
              <div
                class="oiq-corporate-record-cell"
                role="gridcell"
                [textContent]="record.registrationDateFuzzy | dateFormat"></div>
            </div>

            <div class="oiq-corporate-record-row" *ngIf="record.dissolutionDateFuzzy" role="row">
              <div class="oiq-corporate-record-hd" role="columnheader">Dissolved:</div>
              <div
                class="oiq-corporate-record-cell"
                role="gridcell"
                [textContent]="record.dissolutionDateFuzzy | dateFormat"></div>
            </div>
          </article>

          <ddiq-collapsible>
            <ddiq-code-schema
              class="oiq-corporate-record-grid"
              [ngClass]="{disabled: record.disabled}"
              [field]="record">
            </ddiq-code-schema>

            <article class="oiq-corporate-record-grid" [ngClass]="{disabled: record.disabled}">
              <div
                class="oiq-corporate-record-row"
                *ngIf="record.directOwners && record.directOwners.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Direct Owners:</div>

                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ddiq-limited-list [list]="record.directOwners" [limit]="3" [isOpen]="record.isOpen">
                    <ng-template #parentTemplate let-limitedList>
                      <ul class="oiq-corporate-record-ownership oiq-corporate-record-owners">
                        <li class="oiq-corporate-record-direct-owner" *ngFor="let owner of limitedList">
                          <span class="no-margin">
                            <span class="inline"> {{owner.name}} </span>
                            <span class="inline" *ngIf="owner.percentageInterest">
                              ({{owner.percentageInterest}}%)
                            </span>
                            <span class="inline" *ngIf="owner.minPercentageInterest && owner.maxPercentageInterest">
                              ({{owner.minPercentageInterest}}% - {{owner.maxPercentageInterest}}%)
                            </span>
                            <span class="inline" *ngIf="owner.startDate || owner.endDate">
                              ({{owner.startDate | dateFormat}}{{owner.startDate && owner.endDate ? " - " :
                              ""}}{{owner.endDate | dateFormat}})</span
                            >
                          </span>

                          <span class="oiq-corporate-record-grid">
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.type">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Type:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.type">
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.address">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Address:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="owner.address | address">
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.naturesOfControl">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Natures Of Control:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="owner.naturesOfControl">
                              </span>
                            </span>
                            <ng-container *ngFor="let identificationNumber of owner.identificationNumbers">
                              <span
                                class="oiq-corporate-record-row-group"
                                *ngIf="identificationNumber.identificationType && identificationNumber.number">
                                <span class="oiq-corporate-record-row" role="row">
                                  <span
                                    class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                    role="columnheader">
                                    {{identificationNumber.identificationType}}:</span
                                  >
                                  <span
                                    class="oiq-corporate-record-cell"
                                    role="gridcell"
                                    [textContent]="identificationNumber.number">
                                  </span>
                                </span>
                                <span
                                  class="oiq-corporate-record-row"
                                  role="row"
                                  *ngIf="identificationNumber.issuingBody">
                                  <span
                                    class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                    role="columnheader">
                                    Issuing Body:</span
                                  >
                                  <span
                                    class="oiq-corporate-record-cell"
                                    role="gridcell"
                                    [textContent]="identificationNumber.issuingBody">
                                  </span>
                                </span>
                              </span>
                            </ng-container>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.numberOfShares">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Number of Shares:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="owner.numberOfShares">
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.monetaryValue">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Monetary Value:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="owner.monetaryValue">
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.source">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Source:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.source">
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="owner.observedDate">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Observed date:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="owner.observedDate">
                              </span>
                            </span>
                            <span
                              class="oiq-corporate-record-row"
                              role="row"
                              *ngFor="let additionalData of owner.additionalData">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader"
                                [textContent]="(additionalData.label | translatef) + ':'"></span>
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                <ul class="oiq-corporate-record-ownership">
                                  <li
                                    *ngFor="let value of additionalData.value"
                                    [textContent]="value | newlineToBr"></li>
                                </ul>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </ng-template>
                  </ddiq-limited-list>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.ultimateOwners && record.ultimateOwners.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Ultimate Owners:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ul class="oiq-corporate-record-ownership oiq-corporate-record-owners">
                    <li *ngFor="let owner of record.ultimateOwners">
                      <span class="no-margin">
                        <span class="inline"> {{owner.name}} </span>
                        <span class="inline" *ngIf="owner.percentageInterest"> ({{owner.percentageInterest}}%) </span>
                        <span class="inline" *ngIf="owner.minPercentageInterest && owner.maxPercentageInterest">
                          ({{owner.minPercentageInterest}}% - {{owner.maxPercentageInterest}}%)
                        </span>
                        <span class="inline" *ngIf="owner.startDate || owner.endDate">
                          ({{owner.startDate | dateFormat}}{{owner.startDate && owner.endDate ? " - " :
                          ""}}{{owner.endDate | dateFormat}})</span
                        >
                      </span>

                      <span class="oiq-corporate-record-grid">
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.type">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Type:</span
                          >
                          <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.type"> </span>
                        </span>
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.address">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Address:</span
                          >
                          <span
                            class="oiq-corporate-record-cell"
                            role="gridcell"
                            [textContent]="owner.address | address">
                          </span>
                        </span>
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.naturesOfControl">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Natures Of Control:</span
                          >
                          <span
                            class="oiq-corporate-record-cell"
                            role="gridcell"
                            [textContent]="owner.naturesOfControl">
                          </span>
                        </span>
                        <ng-container *ngFor="let identificationNumber of owner.identificationNumbers">
                          <span
                            class="oiq-corporate-record-row-group"
                            *ngIf="identificationNumber.identificationType && identificationNumber.number">
                            <span class="oiq-corporate-record-row" role="row">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                {{identificationNumber.identificationType}}:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="identificationNumber.number">
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="identificationNumber.issuingBody">
                              <span
                                class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                                role="columnheader">
                                Issuing Body:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="identificationNumber.issuingBody">
                              </span>
                            </span>
                          </span>
                        </ng-container>
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.numberOfShares">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Number of Shares:</span
                          >
                          <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.numberOfShares">
                          </span>
                        </span>
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.monetaryValue">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Monetary Value:</span
                          >
                          <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.monetaryValue">
                          </span>
                        </span>
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.source">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Source:</span
                          >
                          <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.source"> </span>
                        </span>
                        <span class="oiq-corporate-record-row" role="row" *ngIf="owner.observedDate">
                          <span class="oiq-corporate-record-ownership-data oiq-corporate-record-hd" role="columnheader">
                            Observed date:</span
                          >
                          <span class="oiq-corporate-record-cell" role="gridcell" [textContent]="owner.observedDate">
                          </span>
                        </span>
                        <span
                          class="oiq-corporate-record-row"
                          role="row"
                          *ngFor="let additionalData of owner.additionalData">
                          <span
                            class="oiq-corporate-record-ownership-data oiq-corporate-record-hd"
                            role="columnheader"
                            [textContent]="(additionalData.label | translatef) + ':'"></span>
                          <span class="oiq-corporate-record-cell" role="gridcell">
                            <ul class="oiq-corporate-record-ownership">
                              <li *ngFor="let value of additionalData.value" [textContent]="value | newlineToBr"></li>
                            </ul>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.companyType" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Type:</div>
                <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.companyType"></div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.companySubtype" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Sub-Type:</div>
                <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.companySubtype"></div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.additionalNames && record.additionalNames.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Other Names:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ul class="oiq-corporate-record-alternative-names">
                    <li *ngFor="let altName of record.additionalNames | arrayDedupe" [textContent]="altName"></li>
                  </ul>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.additionalAddresses && record.additionalAddresses.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Other Addresses:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ddiq-limited-list [list]="record.additionalAddresses" [limit]="3">
                    <ng-template #parentTemplate let-limitedList>
                      <ul class="oiq-corporate-record-additional-addresses">
                        <li *ngFor="let altAddress of limitedList">
                          <span [textContent]="altAddress | address"></span>
                          <span class="oiq-corporate-record-grid">
                            <span
                              class="oiq-corporate-record-row"
                              role="row"
                              *ngIf="altAddress.coordinates && altAddress.coordinates.latitude">
                              <span
                                class="oiq-corporate-record-address-latlng oiq-corporate-record-hd"
                                role="columnheader">
                                Latitude:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                {{altAddress.coordinates.latitude}}
                              </span>
                            </span>
                            <span
                              class="oiq-corporate-record-row"
                              role="row"
                              *ngIf="altAddress.coordinates && altAddress.coordinates.longitude">
                              <span
                                class="oiq-corporate-record-address-latlng oiq-corporate-record-hd"
                                role="columnheader">
                                Longitude:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                {{altAddress.coordinates.longitude}}
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </ng-template>
                  </ddiq-limited-list>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.phoneNumbers && record.phoneNumbers.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Phone Numbers:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ddiq-limited-list [list]="record.phoneNumbers" [limit]="3">
                    <ng-template #parentTemplate let-limitedList>
                      <ul class="oiq-corporate-record-phone-numbers">
                        <li *ngFor="let phone of limitedList" [textContent]="phone.number"></li>
                      </ul>
                    </ng-template>
                  </ddiq-limited-list>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.website" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Website:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <a
                    href="{{record.website | urlNormalizer}}"
                    rel="noopener noreferrer"
                    target="_blank"
                    [textContent]="record.website"></a>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.primaryContact || record.primaryContactAddress || record.primaryContactMailingAddress"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Primary Contact:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <span *ngIf="record.primaryContact" [textContent]="record.primaryContact"></span>
                  <br *ngIf="record.primaryContactAddress" />
                  <span
                    *ngIf="record.primaryContactAddress"
                    [textContent]="record.primaryContactAddress | address"></span>
                  <br *ngIf="record.primaryContactMailingAddress" />
                  <span
                    *ngIf="record.primaryContactMailingAddress"
                    [textContent]="record.primaryContactMailingAddress | address"></span>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.parentName || record.parentAddress" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Parent Company:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <span *ngIf="record.parentName" [textContent]="record.parentName"></span>
                  <span *ngIf="record.parentAddress" [textContent]="record.parentAddress | address"></span>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.employeeCount" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Employees:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <span *ngIf="record.employeeCount" [textContent]="record.employeeCount"></span>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.cyberEmployeeCount" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Cyber Employees:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <span *ngIf="record.cyberEmployeeCount" [textContent]="record.cyberEmployeeCount"></span>
                </div>
              </div>

              <div class="oiq-corporate-record-row" role="row" *ngFor="let datum of additionalData">
                <div class="oiq-corporate-record-hd" role="columnheader">{{datum.label | translatef}}:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ul class="oiq-corporate-record-additional-data">
                    <li *ngFor="let value of datum.value">
                      <span>
                        <ddiq-link [data]="value" [is-disabled]="record.disabled"></ddiq-link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.historicNames && record.historicNames.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Historic Names:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ddiq-limited-list [list]="record.historicNames" [limit]="3">
                    <ng-template #parentTemplate let-limitedList>
                      <ul class="oiq-corporate-record-historical-names">
                        <li *ngFor="let historicName of limitedList">
                          <span [textContent]="historicName.name"></span>
                          <span *ngIf="historicName.startDate || historicName.endDate">
                            ({{historicName.startDate | dateFormat}}{{historicName.startDate && historicName.endDate ? "
                            - " : ""}}{{historicName.endDate | dateFormat}})
                          </span>
                          <span class="oiq-corporate-record-grid">
                            <span class="oiq-corporate-record-row" role="row" *ngIf="historicName.nameType">
                              <span
                                class="oiq-corporate-record-historic-names-data oiq-corporate-record-hd"
                                role="columnheader">
                                Type:</span
                              >
                              <span
                                class="oiq-corporate-record-cell"
                                role="gridcell"
                                [textContent]="historicName.nameType">
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </ng-template>
                  </ddiq-limited-list>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.officers && record.officers.length" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Officers:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ddiq-limited-list [list]="record.officers" [limit]="3" [isOpen]="record.isOpen">
                    <ng-template #parentTemplate let-limitedList>
                      <ul class="oiq-corporate-record-officers">
                        <li class="oiq-corporate-record-officer" *ngFor="let officer of limitedList">
                          <span class="no-margin"> {{officer.fullName}} </span>
                          <span class="no-margin" *ngIf="officer.occupation" [textContent]="officer.occupation"></span>
                          <span class="no-margin" *ngIf="officer.startDate">
                            ({{officer.startDate | dateFormat}}<span class="inline" *ngIf="officer.endDate">
                              - {{officer.endDate | dateFormat}}</span
                            >)
                          </span>
                          <span class="oiq-corporate-record-grid">
                            <span class="oiq-corporate-record-row" role="row" *ngIf="officer.title">
                              <span
                                class="oiq-corporate-record-officer-data oiq-corporate-record-hd"
                                role="columnheader">
                                Title:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                {{officer.title}} <span *ngIf="officer.status">({{officer.status}})</span>
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="officer.address">
                              <span
                                class="oiq-corporate-record-officer-data oiq-corporate-record-hd"
                                role="columnheader">
                                Address:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                {{officer.address | address}}
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="officer.birthdate">
                              <span
                                class="oiq-corporate-record-officer-data oiq-corporate-record-hd"
                                role="columnheader">
                                Birthdate:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                {{officer.birthdate | dateFormat}}
                              </span>
                            </span>
                            <span
                              class="oiq-corporate-record-row"
                              role="row"
                              *ngFor="let additionalData of officer.additionalData">
                              <span
                                class="oiq-corporate-record-officers-additional-data-label oiq-corporate-record-hd"
                                role="columnheader"
                                [textContent]="(additionalData.label | translatef) + ':'"></span>
                              <span class="oiq-corporate-record-cell" role="gridcell">
                                <ul class="oiq-corporate-record-officers-additional-data">
                                  <li
                                    class="oiq-corporate-record-officers-additional-data-value"
                                    *ngFor="let value of additionalData.value"
                                    [textContent]="value | newlineToBr"></li>
                                </ul>
                              </span>
                            </span>
                            <span class="oiq-corporate-record-row" role="row" *ngIf="officer.nationality">
                              <span
                                class="oiq-corporate-record-officer-data oiq-corporate-record-hd"
                                role="columnheader">
                                Nationality:</span
                              >
                              <span class="oiq-corporate-record-cell" role="gridcell"> {{officer.nationality}} </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </ng-template>
                  </ddiq-limited-list>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.beneficialOwners && record.beneficialOwners.length"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Beneficial Owners:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <ul class="oiq-corporate-record-beneficial-owners">
                    <li *ngFor="let beneficialOwner of record.beneficialOwners; let $last = last">
                      <span class="no-margin"
                        >{{beneficialOwner.fullName}}
                        <span class="inline" *ngIf="beneficialOwner.title">({{beneficialOwner.title}}) </span>
                        <span class="inline" *ngIf="beneficialOwner.entityType=='Company'">(Company)</span>
                      </span>
                      <span class="no-margin" *ngIf="beneficialOwner.address"
                        >{{beneficialOwner.address | address}}</span
                      >
                      <span class="no-margin" *ngIf="beneficialOwner.birthdate"
                        >Birthdate: {{beneficialOwner.birthdate | dateFormat}}</span
                      >
                      <span class="no-margin" *ngIf="beneficialOwner.percentageInterest"
                        >Percentage interest: {{beneficialOwner.percentageInterest}}%</span
                      >
                      <br *ngIf="!$last" />
                    </li>
                  </ul>
                </div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.description" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Description:</div>
                <div class="oiq-corporate-record-cell" role="gridcell">
                  <span [textContent]="record.description"></span>
                </div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.positions && record.positions.length && record.positions[0].startDate"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Start Date:</div>
                <div
                  class="oiq-corporate-record-cell"
                  role="gridcell"
                  [textContent]="record.positions[0].startDate | dateFormat"></div>
              </div>

              <div
                class="oiq-corporate-record-row"
                *ngIf="record.positions && record.positions.length && record.positions[0].endDate"
                role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">End Date:</div>
                <div
                  class="oiq-corporate-record-cell"
                  role="gridcell"
                  [textContent]="record.positions[0].endDate | dateFormat"></div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.birthdate" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Date of birth:</div>
                <div
                  class="oiq-corporate-record-cell"
                  role="gridcell"
                  [textContent]="record.birthdate | dateFormat"></div>
              </div>

              <div class="oiq-corporate-record-row" *ngIf="record.nationality" role="row">
                <div class="oiq-corporate-record-hd" role="columnheader">Nationality:</div>
                <div class="oiq-corporate-record-cell" role="gridcell" [textContent]="record.nationality"></div>
              </div>
            </article>
          </ddiq-collapsible>
          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="adjudicationType"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [targetId]="record.id"
            [model]="record"
            [label]="'Notes'"
            [active]="record.comment">
          </ddiq-adjudication-notes>
          <corporate-graph-buttons *ngIf="isGraphEnabled()" [record]="record"> </corporate-graph-buttons>
        </div>
        <div
          class="oiq-corporate-record-toggle oiq-grid-cell"
          [ngClass]="{disabled: record.disabled}"
          *ngIf="hasAdditionalData()">
          <a class="oiq-corporate-record-show-all-data-toggle" data-oiq-collapsible-toggle>
            <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
            Show All Data
          </a>
        </div>
      </div>
    </div>
  </div>
</article>
