import { Pipe, PipeTransform } from '@angular/core';
import TranslateService from '../common-services/translate.service';

@Pipe({ name: 'translatef' })
export default class TranslatefPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(input: any, preserveOriginal?: any): any {
    return this.translateService.translate(input, preserveOriginal);
  }
}
