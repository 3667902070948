import { AlertIconComponent } from './ddiq-alert-icon.component';
import { AdjudicationUnavailableAlertComponent } from './ddiq-adjudication-unavailable-alert.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [AlertIconComponent, AdjudicationUnavailableAlertComponent],
  exports: [AlertIconComponent, AdjudicationUnavailableAlertComponent],
})
export class AlertsModule {}
