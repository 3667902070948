import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import UserService from '../user/user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class Monitor {
  private readonly MONITOR_SCHEDULED = 'SCHEDULED';
  private readonly MONITOR_INPROGRESS = 'INPROGRESS';
  private readonly MONITOR_ERROR = 'ERROR';
  private deltaReport: boolean = false;
  private monitorData: any;

  constructor(
    private user: UserService,
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties
  ) {}

  init(isDeltaReport) {
    if (this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled) {
      this.deltaReport = isDeltaReport === 'true';
    }
    this.monitorData = null;
  }

  isDeltaReport() {
    return this.deltaReport;
  }

  isMonitored() {
    return this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled && this.monitorData !== null;
  }

  isMonitorActive() {
    return !!(
      this.monitorData &&
      (this.monitorData.status === this.MONITOR_SCHEDULED ||
        this.monitorData.status === this.MONITOR_INPROGRESS ||
        this.monitorData.status === this.MONITOR_ERROR)
    );
  }

  setMonitorData(data) {
    this.monitorData = data;
  }

  getMonitorData() {
    return this.monitorData;
  }

  loadMonitor(entityType, entityId, callback = () => {}) {
    return new Promise((resolve) => {
      if (this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled) {
        this.apiFetchService
          .getMonitor(entityType, entityId)
          .then(
            (data) => {
              this.setMonitorData(data);
            },
            () => {
              this.setMonitorData(null);
            }
          )
          .finally(() => {
            resolve(this.isMonitorActive());
            callback();
          });
      } else {
        resolve(false);
        callback();
      }
    });
  }
}
