import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class PersonBuildService {
  personToBuild;
  parentUrl;

  queuePersonToBuild(person) {
    this.personToBuild = person;
  }

  getPersonToBuild() {
    let person = this.personToBuild;
    this.personToBuild = null;
    return person;
  }

  setParentUrl(originUrl) {
    this.parentUrl = originUrl;
  }

  getAndClearParentUrl() {
    let url = this.parentUrl;
    this.parentUrl = null;
    return url;
  }
}
