import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

@Component({
  selector: 'oiq-password-reset',
  templateUrl: './oiq-password-reset.component.tpl.html',
})
export default class PasswordResetComponent implements OnInit {
  @Input() userName;
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  isFetchingCaptcha: boolean;
  isRequesting: boolean;
  isInError: boolean;
  captchaText: string;
  captchaSrc: string;

  constructor(private apiFetchService: ApiFetchService) {}

  ngOnInit() {
    return this.renderNewCaptcha();
  }

  renderNewCaptcha() {
    this.isFetchingCaptcha = true;

    return this.apiFetchService
      .getCaptchaImageDataUri()
      .then(this.applyImageUri.bind(this))
      .finally(() => {
        this.isFetchingCaptcha = false;
      });
  }

  requestPasswordReset() {
    this.isRequesting = true;

    return this.apiFetchService
      .requestPasswordReset(this.userName, this.captchaText)
      .then((e) => this.onSuccessFn(e))
      .catch(this.invalidCaptcha.bind(this))
      .finally(() => {
        this.isRequesting = false;
      });
  }

  invalidCaptcha() {
    this.isInError = true;
    this.captchaText = '';
    return this.renderNewCaptcha();
  }

  applyImageUri(imageUri) {
    this.captchaSrc = imageUri;
  }

  onSuccessFn(event: any) {
    return this.onSuccess.emit(event);
  }

  onCancelFn(event: any) {
    return this.onCancel.emit(event);
  }
}
