<div [ngSwitch]="companies.count > 0">
  <div *ngSwitchCase="true">
    <ddiq-accordion id="related_COMPANY" [label]="'Organizations'" [alerts]="listAlertCount" openonprint>
      <ddiq-company-list
        [title]="'User Submitted'"
        [companies]="companies.submitted"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Acquired By'"
        [companies]="companies.acquirer"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Acquisitions'"
        [companies]="companies.acquisitions"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Parent'"
        [companies]="companies.parent"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Owner'"
        [companies]="companies.owner"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Subsidiary'"
        [companies]="companies.subsidiary"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Received Funding From'"
        [companies]="companies.fundingfrom"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Provided Funding To'"
        [companies]="companies.fundingto"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Supplier'"
        [companies]="companies.supplier"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Consumer'"
        [companies]="companies.consumer"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Business Collaboration'"
        [companies]="companies.collaboration"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Competitor'"
        [companies]="companies.competitor"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Employers'"
        [companies]="companies.employers"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
      <ddiq-company-list
        [title]="'Referenced'"
        [companies]="companies.referenced"
        (requestReport)="requestReport($event.company)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        (adjudicationComplete)="refresh()"></ddiq-company-list>
    </ddiq-accordion>
  </div>
</div>
