<header>
  <h1>{{ updateSeedData && 'Refresh Company Profile' || 'Build Company Profile'}}</h1>
</header>

<section class="build">
  <form novalidate name="buildCompany" (ngSubmit)="submit(buildCompanyModal)" #buildCompany="ngForm">
    <div [ngSwitch]="externalReferenceCodeEnabled">
      <div *ngSwitchCase="false">
        <ddiq-text
          name="companyName"
          id="company-name-input"
          size="three-quarters"
          [label]="'Company Name' | translatef"
          [(model)]="buildParams.profiles[0].companyName"
          [oiqFieldRequired]="isMinimumIdentifyingInfoMissing()"
          [textDisabled]="updateSeedData"></ddiq-text>
        <ddiq-text
          name="referenceId"
          id="company-reference-id-input"
          size="quarter"
          [label]="'REFERENCE_ID' | translatef"
          [(model)]="buildParams.submissionId"
          [oiqFieldRequired]="submissionIdFieldRequired"></ddiq-text>
      </div>
      <div *ngSwitchCase="true">
        <ddiq-text
          name="companyName"
          id="company-name-input"
          size="half"
          [label]="'Company Name' | translatef"
          [(model)]="buildParams.profiles[0].companyName"
          [oiqFieldRequired]="isMinimumIdentifyingInfoMissing()"
          [textDisabled]="updateSeedData"></ddiq-text>
        <ddiq-text
          name="referenceId"
          id="company-reference-id-input"
          size="quarter"
          [label]="'REFERENCE_ID' | translatef"
          [(model)]="buildParams.submissionId"
          [oiqFieldRequired]="submissionIdFieldRequired"></ddiq-text>
        <ddiq-text
          name="externalReferenceCode"
          id="company-external-reference-code-input"
          size="quarter"
          [label]="'EXTERNAL_REFERENCE_CODE' | translatef"
          [(model)]="buildParams.profiles[0].externalReferenceCode"
          [oiqFieldRequired]="externalReferenceCodeRequired"></ddiq-text>
      </div>
    </div>

    <ddiq-dup
      name="alternativeNames"
      id="dba-alternate-name-input"
      size="half"
      [label]="'Doing Business As / Alternative Names'"
      [(model)]="buildParams.profiles[0].dbaNamesHolder"
      [key]="'name'"></ddiq-dup>
    <br class="clr" />

    <div class="clearfix">
      <div
        id="submission-type-input"
        class="build-form-submission-type"
        [ngClass]="{half: crawlPlans.length, full: !crawlPlans.length}"
        *ngIf="globalModeEnabled">
        <span class="input">
          <label>Submission Type</label>
        </span>
        <div class="submission-type-radio-btns">
          <div class="radio" *ngFor="let companyType of companyTypes">
            <div *ngIf="!(companyType.label=='Vessel' && !vesselEnabled)">
              <label
                ><input
                  type="radio"
                  [(ngModel)]="buildParams.profiles[0].companyType"
                  [value]="companyType.value"
                  name="companyType" />{{companyType.label}}</label
              >
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="languagesEnabled"
        [ngClass]="{quarter: (crawlPlans.length > 1) && globalModeEnabled, half: (!(crawlPlans.length > 1) && globalModeEnabled) || (!globalModeEnabled && (crawlPlans.length > 1)), full: !(crawlPlans.length > 1) && !globalModeEnabled}">
        <ddiq-select-dup
          name="requestedLanguages"
          id="languages-select"
          [size]="'full'"
          [label]="'Languages'"
          [(model)]="buildParams.profiles[0].requestedLanguages"
          [options]="languages"
          required></ddiq-select-dup>
      </div>
      <div
        id="submission-crawlplan-input"
        class="build-form-crawlplan-type input"
        [ngClass]="{quarter: languagesEnabled && globalModeEnabled, half: (!globalModeEnabled && languagesEnabled) || (!languagesEnabled && globalModeEnabled), full: !globalModeEnabled && !languagesEnabled, disabled: updateSeedData}"
        *ngIf="crawlPlans.length > 1">
        <ddiq-select
          name="crawlPlanType"
          [label]="'PROFILE_TYPE' | translatef"
          [(model)]="buildParams.profiles[0].crawlPlanType"
          [options]="crawlPlans"
          value="id"
          displayValue="name"
          [prompt]="crawlPlanPreselected ? '' : 'Choose profile type' | translatef"
          [oiqFieldRequired]="true"
          [oiqTextDisabled]="updateSeedData">
        </ddiq-select>
      </div>
      <!--To keep the collection dropdown just below the profile type -->
      <div
        id="blank-space-input"
        class="input half"
        *ngIf="profileCollectionEnabled && profileCollections.length > 1 && (globalModeEnabled || languagesEnabled)">
        <label>&nbsp;</label>
      </div>
      <div
        id="submission-collection-input"
        class="build-form-collection-type input"
        *ngIf="profileCollectionEnabled && profileCollections.length > 1"
        [ngClass]="{ half: (globalModeEnabled || languagesEnabled), full: !globalModeEnabled && !languagesEnabled}">
        <profile-collection-list
          [label]="'Collection'"
          [model]="buildParams.profiles[0].profileCollectionKeys"
          (onListChange)="validateProfileCollection($event)"
          required></profile-collection-list>
      </div>
      <div
        id="submission-lookback-input"
        class="build-form-lookback sixth input"
        *ngIf="lookbackEnabled && !isValidLookback(buildParams.profiles[0].lookbackConfig.lookbackDate)">
        <span class="input">
          <label>Lookback Timeframe</label>
        </span>
        <input
          id="lookback-config-value"
          type="text"
          [(ngModel)]="buildParams.profiles[0].lookbackConfig.value"
          name="lookbackConfigValue"
          [ngClass]="{required: !!buildParams.profiles[0].lookbackConfig.timeunit}"
          [required]="!!buildParams.profiles[0].lookbackConfig.timeunit" />
        <select
          name="lookbackTimeunits"
          id="lookback-config-timeunits"
          [(ngModel)]="buildParams.profiles[0].lookbackConfig.timeunit"
          [ngClass]="{required: !!buildParams.profiles[0].lookbackConfig.value}"
          [required]="!!buildParams.profiles[0].lookbackConfig.value">
          <option *ngFor="let timeunit of lookbackTimeunits" [value]="timeunit">{{timeunit | translatef}}</option>
        </select>
      </div>
      <div
        id="submission-lookback-hide-time"
        class="build-form-lookback sixth input"
        *ngIf="lookbackEnabled && isValidLookback(buildParams.profiles[0].lookbackConfig.lookbackDate)">
        <span class="input">
          <label>Lookback Timeframe</label>
        </span>
      </div>
      <div *ngIf="lookbackEnabled">
        <ddiq-fuzzy-date
          name="lookbackDate"
          id="lookback-date"
          size="sixth"
          [label]="'Lookback Date' | translatef"
          [(model)]="buildParams.profiles[0].lookbackConfig.lookbackDate"
          [format]="dateFormat"></ddiq-fuzzy-date>
      </div>
      <div
        class="lookback-error three-quarters"
        *ngIf="lookbackEnabled && buildParams.profiles[0].lookbackConfig.lookbackDate.year && !isValidLookback(buildParams.profiles[0].lookbackConfig.lookbackDate)">
        Invalid lookback date {{buildParams.profiles[0].lookbackConfig.lookbackDate | dateFormat}}. Please select a date
        after 1970. If run as is, the date will default to a system default setting.
      </div>
      <div class="half clr" *ngIf="!buildParams.profiles[0].companyType" [ngSwitch]="registrationNumberEnabled">
        <div *ngSwitchCase="false">
          <ddiq-select
            name="countryOfIncorporation"
            id="country-of-inc-input"
            size="full"
            [label]="'Country of Incorporation/Registration' | translatef"
            [(model)]="buildParams.profiles[0].countryOfIncorporation.country"
            [options]="countries"
            [prompt]="'Choose country' | translatef">
          </ddiq-select>
        </div>
        <div *ngSwitchCase="true">
          <ddiq-text
            name="registrationNumber"
            id="corporate-reg-number-input"
            size="half"
            [label]="'Registration Number' | translatef"
            [(model)]="buildParams.profiles[0].identificationNumbers[0].number"
            [oiqFieldRequired]="isMinimumIdentifyingInfoMissing()"></ddiq-text>
          <ddiq-select
            name="countryOfIncorporation"
            id="country-of-inc-input"
            size="half"
            [label]="'Country of Incorporation/Registration' | translatef"
            [(model)]="buildParams.profiles[0].countryOfIncorporation.country"
            [options]="countries"
            [prompt]="'Choose country' | translatef"
            [oiqFieldRequired]="!!buildParams.profiles[0].identificationNumbers[0].number && !(buildParams.profiles[0].locations[0].country && buildParams.profiles[0].companyName)">
          </ddiq-select>
        </div>
      </div>
      <div class="half">
        <ddiq-select-dup
          id="country-of-do-select"
          *ngIf="!buildParams.profiles[0].companyType"
          [size]="'full'"
          [label]="'Countries of Doing Business'"
          [(model)]="buildParams.profiles[0].countriesOfBusiness"
          [key]="'country'"
          [options]="countries"
          [prompt]="'Choose country' | translatef"></ddiq-select-dup>
      </div>
      <id-submission-inputs
        *ngIf="!buildParams.profiles[0].companyType"
        (onIdModelChange)="validateIdSubmission($event)"
        [idModel]="buildParams.profiles[0].identificationNumbers"
        [idConfigs]="idSubmissionConfigs"
        (buttonClicked)="sendCageData($event)"></id-submission-inputs>

      <ddiq-modal
        id="submitting-cage-data"
        class="ddiq-modal small-modal"
        [trigger]="submittingCage"
        [closeable]="true">
        <div class="block center-block">
          <span *ngIf="submitting"><img [src]="'./img/loader.gif'" class="submitting-img" /> Importing Data....</span>
        </div>
        <div class="error-message" *ngIf="cageError">
          <span>{{cageErrorMessage}}</span>
        </div>
      </ddiq-modal>
    </div>

    <ddiq-text
      name="streetAddress"
      id="street-address-input"
      *ngIf="buildParams.profiles[0].companyType !== 'FRANCHISEE'"
      size="half"
      [label]="'Street Address' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].streetAddress"></ddiq-text>
    <ddiq-text
      name="streetAddress"
      id="street-address-franchisee-input"
      *ngIf="buildParams.profiles[0].companyType === 'FRANCHISEE'"
      size="half"
      [label]="'Street Address' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].streetAddress"
      required></ddiq-text>

    <div *ngIf="districtEnabled">
      <ddiq-text
        name="buildingName"
        id="building-name-input"
        size="quarter"
        [label]="'Building Name' | translatef"
        [(model)]="buildParams.profiles[0].locations[0].buildingName"></ddiq-text>
      <ddiq-text
        name="district"
        id="district-input"
        size="quarter"
        [label]="'District' | translatef"
        [(model)]="buildParams.profiles[0].locations[0].district"></ddiq-text>
    </div>
    <div *ngIf="!districtEnabled">
      <ddiq-text
        name="buildingName"
        id="district-building-name-input"
        size="half"
        [label]="'Building Name' | translatef"
        [(model)]="buildParams.profiles[0].locations[0].buildingName"></ddiq-text>
    </div>

    <ddiq-text
      name="city"
      id="city-town-input"
      *ngIf="buildParams.profiles[0].companyType !== 'FRANCHISEE'"
      size="quarter"
      [label]="'City/Town' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].city"></ddiq-text>
    <ddiq-text
      name="city"
      id="city-town-franchisee-input"
      *ngIf="buildParams.profiles[0].companyType === 'FRANCHISEE'"
      size="quarter"
      [label]="'City/Town' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].city"
      required></ddiq-text>
    <ddiq-text
      name="stateOrProvince"
      id="state-province-region-input"
      size="quarter"
      [label]="'State/Province/Region' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].stateOrProvince"></ddiq-text>
    <ddiq-text
      name="zipCode"
      id="zip-postal-code-input"
      size="quarter"
      [label]="'ZIP/Postal Code' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].zipOrPostalCode"></ddiq-text>
    <ddiq-select
      id="country-select"
      name="country"
      *ngIf="buildParams.profiles[0].companyType !== 'MULTINATIONAL'"
      size="quarter"
      [label]="'Country' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].country"
      [options]="countries"
      [prompt]="'Choose country' | translatef"
      [oiqFieldRequired]="isCountryRequired(buildParams.profiles[0])">
    </ddiq-select>
    <ddiq-select
      name="country"
      id="country-multinational-select"
      *ngIf="buildParams.profiles[0].companyType === 'MULTINATIONAL'"
      size="quarter"
      [label]="'Country' | translatef"
      [(model)]="buildParams.profiles[0].locations[0].country"
      [options]="countries"
      [prompt]="'Choose country' | translatef">
    </ddiq-select>

    <ddiq-text
      name="companyWebsite"
      id="company-website-input"
      size="half"
      [label]="'Company Website' | translatef"
      [(model)]="buildParams.profiles[0].website"></ddiq-text>
    <br class="clr" />
    <!-- MDG: Why is this here?  It is always hidden -->
    <ddiq-text
      *ngIf="false"
      [label]="'Other Attribute' | translatef"
      size="half"
      [(model)]="buildParams.profiles[0].additionalContentAssociationData"
      [maxLength]="30"></ddiq-text>
    <br class="clr" />

    <ddiq-accordion-top id="add-input-toggle" label="Additional Input" toggle-function="toggleSection" open>
      <ddiq-accordion
        *ngIf="phoneAndFaxEnabled || extendedSubmissionFields"
        id="add-comp-info"
        label="Additional Company Information"
        (hasData)="hasAdditionalCompanyInfo()">
        <div *ngIf="phoneAndFaxEnabled">
          <ddiq-dup
            name="phoneNumbers"
            id="add-comp-info-phone-input"
            size="half"
            [label]="'Phone Number'"
            [(model)]="buildParams.profiles[0].phoneNumbers"
            [key]="'number'"></ddiq-dup>
          <ddiq-dup
            name="faxNumbers"
            id="add-comp-info-fax-input"
            size="half"
            [label]="'Fax Number'"
            [(model)]="buildParams.profiles[0].faxNumbers"
            [key]="'number'"></ddiq-dup>
          <br class="clr" />
        </div>
        <div *ngIf="extendedSubmissionFields">
          <ddiq-text
            name="organizationType"
            id="type-of-org-input"
            size="third"
            [label]="'Type of Organization' | translatef"
            [(model)]="buildParams.profiles[0].organizationType"></ddiq-text>
          <ddiq-text
            name="yearFounded"
            id="year-founded-input"
            size="third"
            [label]="'Year Founded' | translatef"
            [(model)]="buildParams.profiles[0].dateFounded.year"></ddiq-text>
          <br class="clr" />
        </div>
      </ddiq-accordion>

      <ddiq-accordion id="related-companies" label="Related Companies" (hasData)="hasSubmittedCompanies()">
        <ddiq-person-related-companies-form [(companies)]="buildParams.profiles[0].companies" [countries]="countries">
        </ddiq-person-related-companies-form>
      </ddiq-accordion>

      <ddiq-accordion id="additional-location" label="Locations" (hasData)="hasLocations()">
        <ddiq-location-form
          [locations]="buildParams.profiles[0].locations"
          [countries]="countries"></ddiq-location-form>
      </ddiq-accordion>

      <ddiq-accordion id="additional-people" label="People" (hasData)="hasSubmittedPeople()">
        <ddiq-people-form [buildForm]="buildParams.profiles[0]" [countries]="countries"></ddiq-people-form>
      </ddiq-accordion>

      <ddiq-accordion id="additional-parts" label="Parts" (hasData)="hasParts()" *ngIf="partsEnabled">
        <section class="additional-item">
          <ddiq-dup
            id="part-input"
            [label]="'Part Name'"
            size="half"
            [(model)]="buildParams.profiles[0].partsHolder"
            [key]="'name'"></ddiq-dup>
          <br class="clr" />
        </section>
      </ddiq-accordion>
    </ddiq-accordion-top>

    <div class="block center-block">
      <button
        id="submitCompany"
        type="submit"
        class="btn btn-primary btn-block"
        [disabled]="!buildCompany.form.valid || isProfileCollectionInvalid || !idSubmissionValid"
        *ngIf="canSubmitProfile"
        [textContent]="updateSeedData ?  'Refresh Profile' : 'Build Profile' | translatef"></button>
    </div>

    <ddiq-modal
      id="build-profile-permission-missing"
      class="ddiq-modal small-modal"
      [trigger]="'buildProfilePermissionMissing'">
      <ddiq-profile-collection-missing-permission-for-submission></ddiq-profile-collection-missing-permission-for-submission>
    </ddiq-modal>

    <div *ngIf="buildCompanyModal.open">
      <ddiq-modal
        class="ddiq-modal profile-build-modal"
        [trigger]="buildCompanyModal"
        [ngClass]="{ 'small-modal' : !didYouMean.isOn}"
        [focus]="'buildProfile'">
        <div
          class="build-modal"
          [ngSwitch]="updateSeedData ? false : existingProfiles.isOn || didYouMean.isOn || nameSuggestion.isOn">
          <div *ngSwitchCase="false" style="height: 100%">
            <ddiq-confirm-modal [toggleConfirm]="confirm.toggle">
              <div class="modal-dialog-content">
                <div class="modal-body">
                  <h3>Confirm Submission</h3>
                  <strong>You Entered:</strong><br />
                  <div class="no-suggestion">
                    <div *ngIf="buildParams.profiles[0].companyName">{{ buildParams.profiles[0].companyName }}</div>
                    <div *ngIf="buildParams.profiles[0].identificationNumbers[0].number">
                      {{ buildParams.profiles[0].identificationNumbers[0].number }}
                    </div>
                    <div *ngIf="buildParams.profiles[0].companyType == 'FRANCHISEE'">
                      {{ 'FRANCHISEE' | translatef }}
                    </div>
                    <div *ngIf="buildParams.profiles[0].companyType == 'VESSEL'">{{ 'VESSEL' | translatef }}</div>
                    <div *ngIf="buildParams.profiles[0].locations[0] | address" class="pushdown">
                      <b>Main Location:</b><br />
                      {{ buildParams.profiles[0].locations[0] | address }}
                    </div>
                    <div *ngIf="hasIdNumbers(buildParams.profiles[0].identificationNumbers)" class="pushdown">
                      <b>Identification Numbers:</b>
                      <ng-container *ngFor="let id of buildParams.profiles[0].identificationNumbers | startFrom : 1">
                        <div *ngIf="id.number">{{id.identificationType + ': ' + id.number}}</div>
                      </ng-container>
                    </div>
                    <div *ngIf="hasCompanies(buildParams.profiles[0].companies)" class="pushdown">
                      <b>Related Companies:</b>
                      <div *ngFor="let company of buildParams.profiles[0].companies">
                        <div *ngIf="company.companyName">
                          <span *ngIf="company.relationshipType">{{company.relationshipType}} with</span>
                          {{company.companyName}}
                        </div>
                        <div *ngIf="company.address | address">{{company.address | address}}</div>
                      </div>
                    </div>
                    <div *ngIf="hasAdditionalLocations(buildParams.profiles[0].locations)" class="pushdown">
                      <b>Additional Locations:</b><br />
                      <div *ngFor="let location of buildParams.profiles[0].locations | startFrom:1">
                        <span>{{ location | address }}</span>
                      </div>
                    </div>
                    <div *ngIf="hasPeople(buildParams.profiles[0].people)" class="pushdown">
                      <b>People:</b><br />
                      <div *ngFor="let person of buildParams.profiles[0].people">
                        <span *ngIf="person.personName">{{ person.personName }}</span>
                        <span *ngIf="person.currentPosition.jobTitle">, {{ person.currentPosition.jobTitle }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="pushdown"
                    *ngIf="!isScreeningEnabled && !updateSeedData && monitoringEnabled && profileRefreshEnabled && (!isSparseProfile() || isSparseProfile() && hasSelectedCompletion())">
                    <div *ngIf="monitoringEnabled">
                      <ddiq-monitor-categories
                        *ngIf="buildCompanyModal.open"
                        (toggled)="onMonitoringToggled($event.toggled)"></ddiq-monitor-categories>
                    </div>
                    <div class="build-modal-profile-refresh" *ngIf="profileRefreshEnabled">
                      <ul>
                        <li>
                          <input
                            id="profile-refresh-checkbox2"
                            name="profile-refresh-toggle"
                            type="checkbox"
                            (change)="resetProfileRefresh(request.isRefreshable)"
                            [(ngModel)]="request.isRefreshable" />
                          <label for="profile-refresh-checkbox2">Schedule Profile Refresh</label>
                        </li>
                        <li>
                          <ul class="profile_refresh_flex_container">
                            <li class="profile_refresh_field">
                              <label for="request-frequency2">Refresh Frequency</label>
                              <select
                                id="request-frequency2"
                                name="requestFrequency"
                                [(ngModel)]="request.frequency"
                                [disabled]="!request.isRefreshable">
                                <option *ngFor="let option of profileRefreshFrequencies" value="{{option}}">
                                  {{option | translatef}}
                                </option>
                              </select>
                            </li>
                            <li class="profile_refresh_field">
                              <ddiq-date
                                id="schedule-start-date-input"
                                [inputId]="'schedule-start-date'"
                                label="From Date"
                                [(model)]="request.from"
                                [options]="request.dates.options.after"
                                [oiqTextDisabled]="!request.isRefreshable"
                                [labelPattern]="labelPattern"
                                [datePattern]="datePattern"></ddiq-date>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div *ngIf="isSparseProfile()">
                    <p class="sparse-profile">
                      <span>
                        <span class="inline fa fa-warning fa-2x"></span>You have not entered sufficient information to
                        associate content with a high level of confidence.
                      </span>
                    </p>
                  </div>
                  <button
                    id="buildProfile"
                    class="btn btn-primary"
                    (click)="buildProfile(buildCompanyModal, confirm);"
                    [disabled]="submitting || refreshSelectionInvalid() ">
                    {{ isSparseProfile() && 'Proceed' || updateSeedData && 'Refresh Profile' || 'Build Profile'}}
                  </button>
                  <button class="btn btn-default" (click)="cancelDialog(buildCompanyModal, confirm)">Cancel</button>
                </div>
              </div>
            </ddiq-confirm-modal>
          </div>
          <!-- validation -->
          <div *ngSwitchCase="true" style="height: 100%">
            <ddiq-confirm-modal [toggleConfirm]="didYouMean.toggle">
              <div class="modal-dialog-content">
                <div class="modal-body">
                  <div class="build-left push-down">
                    <div class="suggestion">
                      <h3>{{ nameSuggestion.inProcess && 'Name Suggestions' || 'Confirm Submission' }}</h3>
                      <strong>You Entered</strong><br />
                      <div *ngIf="buildParams.profiles[0].companyName">
                        <strong>{{ buildParams.profiles[0].companyName }}</strong>
                      </div>
                      <div *ngIf="buildParams.profiles[0].identificationNumbers[0].number">
                        {{ buildParams.profiles[0].identificationNumbers[0].number }}
                      </div>
                      <div *ngIf="buildParams.profiles[0].companyType == 'FRANCHISEE'">
                        {{ 'FRANCHISEE' | translatef }}
                      </div>
                      <div *ngIf="buildParams.profiles[0].companyType == 'VESSEL'">{{ 'VESSEL' | translatef }}</div>
                      <div *ngIf="hasIdNumbers(buildParams.profiles[0].identificationNumbers)" class="pushdown">
                        <b>Identification Numbers:</b>
                        <ng-container *ngFor="let id of buildParams.profiles[0].identificationNumbers | startFrom : 1">
                          <div *ngIf="id.number">{{id.identificationType + ': ' + id.number}}</div>
                        </ng-container>
                      </div>
                      <div *ngIf="hasDbaNames() || hasSelectedNameSuggestion()" class="pushdown">
                        <b>Doing Business As / Alternative Names:</b><br />
                        <span *ngFor="let dbaName of collectAllDbaNames(); trackBy:trackByIndex; let last = last;">
                          <span>{{ dbaName }}<span *ngIf="!last">, </span></span>
                        </span>
                      </div>
                      <div *ngIf="hasCompanies(buildParams.profiles[0].companies)" class="pushdown">
                        <b>Related Companies:</b>
                        <div *ngFor="let company of buildParams.profiles[0].companies">
                          <div *ngIf="company.companyName">
                            <span *ngIf="company.relationshipType">{{company.relationshipType}} with</span>
                            {{company.companyName}}
                          </div>
                          <div *ngIf="company.address | address">{{company.address | address}}</div>
                        </div>
                      </div>
                      <div *ngIf="buildParams.profiles[0].locations[0] | address" class="pushdown">
                        <b>Main Location:</b><br />
                        {{ buildParams.profiles[0].locations[0] | address }}
                      </div>
                      <div *ngIf="hasAdditionalLocations(buildParams.profiles[0].locations)" class="pushdown">
                        <b>Additional Locations:</b><br />
                        <div *ngFor="let location of buildParams.profiles[0].locations | startFrom:1">
                          <span>{{ location | address }}</span>
                        </div>
                      </div>
                      <div *ngIf="hasPeople(buildParams.profiles[0].people)" class="pushdown">
                        <b>People:</b><br />
                        <div *ngFor="let person of buildParams.profiles[0].people">
                          <span *ngIf="person.personName">{{ person.personName }}</span>
                          <span *ngIf="person.currentPosition.jobTitle">, {{ person.currentPosition.jobTitle }}</span>
                        </div>
                      </div>
                      <div *ngIf="hasParts()" class="pushdown">
                        <b>Parts:</b><br />
                        <span *ngFor="let part of collectParts(); trackBy:trackByIndex; let last = last;">
                          <span>{{ part }}<span *ngIf="!last">,&nbsp;</span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="build-right push-down">
                    <div class="suggestion">
                      <h3>{{ suggestion.headerMessage | translatef }}</h3>
                      <strong>{{ suggestion.message | translatef }}</strong><br />
                      <img
                        *ngIf="existingProfiles.searching || nameSuggestion.generating || validating"
                        [src]="'./img/loader.gif'"
                        class="validation-loading-img pushdown" />
                      <div *ngIf="existingProfiles.inProcess">
                        <table class="list-table">
                          <tbody>
                            <tr
                              *ngFor="let suggestion of existingProfiles.suggestions"
                              class="suggestion"
                              [ngClass]="{'suggestion-selected' : suggestion.selected}">
                              <td>
                                <div class="right">
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="viewExistingReport(suggestion.reportUrl)">
                                    View
                                  </button>
                                </div>
                                <div>
                                  <strong class="wrappable">{{ suggestion.name }}</strong><br />
                                  <strong *ngIf="existingProfiles.useSubmissionId" class="wrappable"
                                    >{{ suggestion.submissionId }}</strong
                                  >
                                  <div *ngIf="suggestion.mainAddress">{{ suggestion.mainAddress | address }}</div>
                                  <span>Built: {{suggestion.oiqCreatedDate | dateFormat}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div *ngIf="nameSuggestion.inProcess">
                        <table class="list-table">
                          <tbody>
                            <tr
                              *ngFor="let suggestion of nameSuggestion.suggestions"
                              class="suggestion"
                              [ngClass]="{'suggestion-selected' : suggestion.selected}">
                              <td>
                                <div class="right">
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="suggestion.selected = true; nameSuggestion.handled = true;"
                                    *ngIf="!suggestion.selected">
                                    Select
                                  </button>
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="suggestion.selected = false; nameSuggestion.handled = false;"
                                    *ngIf="suggestion.selected">
                                    Unselect
                                  </button>
                                </div>
                                <div>
                                  <strong class="wrappable">{{ suggestion.suggestion }}</strong>
                                </div>
                                <div>({{ suggestion.language | translatef }})</div>
                                <div *ngIf="suggestion.source"><b>Source:</b>{{ suggestion.source | translatef }}</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div *ngIf="didYouMean.inProcess">
                        <table class="list-table">
                          <tbody>
                            <tr
                              *ngFor="let completion of didYouMean.completions"
                              class="completion"
                              [ngClass]="{'completion-selected' : completion.selected}">
                              <td>
                                <div class="right">
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="completion.selected = true; didYouMean.handled = true;"
                                    *ngIf="!completion.selected">
                                    Select
                                  </button>
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="completion.selected = false; didYouMean.handled = false;"
                                    *ngIf="completion.selected">
                                    Unselect
                                  </button>
                                </div>
                                <div>
                                  <strong class="wrappable">{{ completion.suggestion }}</strong>
                                  <div *ngIf="completion.address">
                                    <b>Address:</b><br />
                                    {{ completion.address | address }}
                                  </div>
                                  <div *ngIf="hasAdditionalLocations(completion.locations)">
                                    <div *ngIf="!completion.selected">
                                      <span>+{{ completion.locations.length }} more...</span>
                                    </div>
                                    <ng-container *ngFor="let location of completion.locations">
                                      <div *ngIf="completion.selected"><br />{{ location | address }}</div>
                                    </ng-container>
                                  </div>
                                  <div *ngIf="hasIdNumbers(completion.identificationNumbers)">
                                    <b>Identification Numbers:</b><br />
                                    <div
                                      *ngFor="let id of completion.identificationNumbers | slice:0:5;index as $index">
                                      <input
                                        name="completionIdNumbers-{{$index}}"
                                        type="checkbox"
                                        [(ngModel)]="id.selected"
                                        *ngIf="completion.selected" />
                                      {{ id.identificationType }}: {{ id.number }}
                                    </div>
                                    <div *ngIf="completion.identificationNumbers.length > 5 && !completion.selected">
                                      <span>+{{ completion.identificationNumbers.length - 5 }} more...</span>
                                    </div>
                                    <ng-container
                                      *ngFor="let id of completion.identificationNumbers | startFrom: 5;index as $index">
                                      <div *ngIf="completion.identificationNumbers.length > 5 && completion.selected">
                                        <input
                                          name="completionIdNumbers-{{$index}}"
                                          type="checkbox"
                                          [(ngModel)]="id.selected" />
                                        {{ id.identificationType | translatef }}: {{ id.number }}
                                      </div>
                                    </ng-container>
                                  </div>
                                  <div *ngIf="hasPeople(completion.people)">
                                    <b>People:</b><br />
                                    <div *ngFor="let person of completion.people | slice:0:5; index as $index">
                                      <input
                                        name="completionPeople-{{$index}}"
                                        type="checkbox"
                                        [(ngModel)]="person.selected"
                                        *ngIf="completion.selected" />
                                      {{ person.name }}{{ person.currentJobTitle && ', ' || '' }}{{
                                      person.currentJobTitle }}
                                    </div>
                                    <div *ngIf="completion.people.length > 5 && !completion.selected">
                                      <span>+{{ completion.people.length - 5 }} more...</span>
                                    </div>
                                    <ng-container
                                      *ngFor="let person of completion.people | startFrom: 5;index as $index">
                                      <div *ngIf="completion.people.length > 5 && completion.selected">
                                        <input
                                          name="completionPeople-{{$index}}"
                                          type="checkbox"
                                          [(ngModel)]="person.selected" />
                                        {{ person.name }}{{ person.currentJobTitle && ', ' || '' }}{{
                                        person.currentJobTitle }}
                                      </div>
                                    </ng-container>
                                  </div>
                                  <div *ngIf="completion.seedSources.length">
                                    <b>Sources:</b><br />
                                    <div *ngFor="let source of completion.seedSources">{{ source | translatef }}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="center pushdown clr">
                    <div *ngIf="hasSelectedCompletion() && !submitting">
                      <div class="push-down" *ngIf="!buildParams.profiles[0].companyName">
                        <span
                          >You have not entered a company name. '{{firstSelectedCompletion().suggestion}}' will be
                          used.</span
                        >
                      </div>
                      <span
                        ><b>NOTE:</b> Clicking 'Build Profile' will merge the information from the selected items with
                        the currently entered information.</span
                      >
                    </div>
                    <span *ngIf="submitting"
                      ><img [src]="'./img/loader.gif'" class="submitting-img" /> Submitting Profile...</span
                    >
                  </div>
                  <div
                    class="pushdown"
                    *ngIf="!isScreeningEnabled && !updateSeedData && monitoringEnabled && profileRefreshEnabled && (!isSparseProfile() || isSparseProfile() && hasSelectedCompletion())">
                    <div *ngIf="monitoringEnabled">
                      <ddiq-monitor-categories
                        *ngIf="buildCompanyModal.open"
                        (toggled)="onMonitoringToggled($event.toggled)"></ddiq-monitor-categories>
                    </div>
                    <div class="build-modal-profile-refresh" *ngIf="profileRefreshEnabled">
                      <ul>
                        <li>
                          <input
                            id="profile-refresh-checkbox1"
                            name="profile-refresh-toggle"
                            type="checkbox"
                            (change)="resetProfileRefresh(request.isRefreshable)"
                            [(ngModel)]="request.isRefreshable" />
                          <label for="profile-refresh-checkbox1">Schedule Profile Refresh</label>
                        </li>
                        <li>
                          <ul class="profile_refresh_flex_container">
                            <li class="profile_refresh_field">
                              <label for="request-frequency1">Refresh Frequency</label>
                              <select
                                id="request-frequency1"
                                name="requestFrequency"
                                [(ngModel)]="request.frequency"
                                [disabled]="!request.isRefreshable">
                                <option *ngFor="let option of profileRefreshFrequencies" value="{{option}}">
                                  {{option| translatef}}
                                </option>
                              </select>
                            </li>
                            <li class="profile_refresh_field">
                              <ddiq-date
                                id="schedule-start-date-input"
                                [inputId]="'schedule-start-date'"
                                label="From Date"
                                [(model)]="request.from"
                                [options]="request.dates.options.after"
                                [oiqTextDisabled]="!request.isRefreshable"
                                [labelPattern]="labelPattern"
                                [datePattern]="datePattern"></ddiq-date>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div *ngIf="isSparseProfile() && !hasSelectedCompletion() && !hasSelectedNameSuggestion()">
                    <p class="sparse-profile">
                      <span>
                        <span class="inline fa fa-warning fa-2x"></span>You have not entered sufficient information to
                        associate content with a high level of confidence.
                      </span>
                    </p>
                  </div>
                  <button
                    id="suggestNames"
                    *ngIf="existingProfiles.inProcess"
                    class="btn btn-primary"
                    [disabled]="existingProfiles.searching"
                    (click)="nameSuggestion.generate(buildCompanyModal);">
                    Proceed
                  </button>
                  <button
                    id="validate"
                    *ngIf="nameSuggestion.inProcess"
                    class="btn btn-primary"
                    [disabled]="nameSuggestion.generating"
                    (click)="mergeSelectedNameSuggestions();didYouMean.validate(buildCompanyModal);">
                    {{ isSparseProfile() && !hasSelectedNameSuggestion() && 'Proceed' || 'Validate'}}
                  </button>
                  <button
                    id="buildProfile"
                    *ngIf="didYouMean.inProcess"
                    class="btn btn-primary"
                    [disabled]="(validating || submitting || refreshSelectionInvalid() || !hasSufficientSeedData())"
                    (click)="mergeSelected();buildProfile(buildCompanyModal, didYouMean);">
                    {{ isSparseProfile() && !hasSelectedCompletion() && 'Proceed' || 'Build Profile'}}
                  </button>
                  <button class="btn btn-default" (click)="cancelDialog(buildCompanyModal, didYouMean);">Cancel</button>
                </div>
              </div>
            </ddiq-confirm-modal>
          </div>
        </div>
      </ddiq-modal>
    </div>
  </form>

  <ddiq-footer></ddiq-footer>
</section>

<ddiq-error-modal id="error-modal" class="ddiq-modal" trigger="error" closeable></ddiq-error-modal>
