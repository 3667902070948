<header>
  <h1>Profile Library</h1>
</header>
<section class="review">
  <form novalidate #find="ngForm" id="find" (ngSubmit)="search( query, dates, true );">
    <ddiq-text
      name="profileName"
      id="profile-name-input"
      label="Profile Name"
      size="third"
      [(model)]="query.q"
      [textDisabled]="formDisabled"></ddiq-text>
    <ddiq-date
      id="date-from-input"
      inputId="date-from"
      label="Date From"
      size="fixed-date"
      [(model)]="dates.after"
      [options]="dates.options.after"
      [oiqTextDisabled]="formDisabled"
      [labelPattern]="labelPattern"
      [datePattern]="datePattern"
      (modelChange)="onAfterDateChange($event)"></ddiq-date>
    <ddiq-date
      id="date-to-input"
      inputId="date-to"
      label="Date To"
      size="fixed-date"
      [(model)]="dates.before"
      [options]="dates.options.before"
      [oiqTextDisabled]="formDisabled"
      [labelPattern]="labelPattern"
      [datePattern]="datePattern"
      (modelChange)="onBeforeDateChange($event)"></ddiq-date>
    <ddiq-text
      name="profileLibraryReferenceId"
      id="profile-reference-id-input"
      label="{{'REFERENCE_ID' | translatef}}"
      size="sixth"
      [(model)]="query.id"
      [textDisabled]="formDisabled"></ddiq-text>
    <ddiq-select
      id="status-input"
      name="status-input"
      *ngIf="canViewStatus"
      size="sixth"
      [label]="'Status' | translatef"
      [(model)]="query.status"
      [options]="profileStatusesFilter"
      prompt=""
      [oiqTextDisabled]="formDisabled">
    </ddiq-select>
    <ddiq-select
      id="profileType-input"
      name="profileType-input"
      size="quarter"
      [label]="'Profile_Type' | translatef"
      [(model)]="query.crawlPlanType"
      [options]="crawlPlans"
      prompt=""
      value="crawlPlanKey"
      displayValue="description"
      [oiqTextDisabled]="formDisabled">
    </ddiq-select>
    <ddiq-select
      *ngIf="isProfileCollectionFilterVisible"
      id="profileCollection-input"
      name="profileCollection-input"
      size="quarter"
      [label]="'Profile_Collection' | translatef"
      [(model)]="query.profileCollectionKey"
      [options]="profileCollectionList"
      prompt=""
      value="profileCollectionKey"
      displayValue="name"
      [oiqTextDisabled]="formDisabled">
    </ddiq-select>
    <ddiq-select
      id="owner-input"
      name="owner-input"
      *ngIf="groupUsers && groupUsers.length"
      size="quarter"
      [label]="'Owner' | translatef"
      [(model)]="query.owner"
      [options]="groupUsers"
      prompt=""
      value="username"
      displayValue="fullName"
      [oiqTextDisabled]="formDisabled">
    </ddiq-select>
    <ddiq-text
      name="requestingOrganization"
      id="reqOrg-input"
      label="Requesting organization"
      *ngIf="canSearchReqOrg"
      [(model)]="query.reqOrg"
      size="third"
      [textDisabled]="formDisabled"></ddiq-text>
    <ddiq-text
      name="correlationId"
      id="correlationId-input"
      label="{{'CORRELATION_ID' | translatef}}"
      *ngIf="searchCorrelationId"
      [(model)]="query.correlationId"
      size="third"
      [textDisabled]="formDisabled"></ddiq-text>
    <ddiq-text
      name="profileIdentification"
      id="profile-identification"
      size="sixth"
      label="Identification"
      *ngIf="isGovIdSearchEnabled"
      [(model)]="query.govId"
      [textDisabled]="formDisabled"></ddiq-text>

    <br class="clr" />
    <button
      id="find-profiles-button"
      type="submit"
      class="btn btn-primary"
      [ngClass]="{disabled : formDisabled || find.invalid}"
      [disabled]="formDisabled || find.invalid">
      Find Profiles
    </button>
    <button
      id="clear-button"
      type="button"
      class="btn btn-danger"
      [ngClass]="{disabled : formDisabled}"
      (click)="clearSearch( query, dates )"
      [disabled]="formDisabled">
      Clear
    </button>
  </form>

  <hr class="clr" />

  <section class="inline-tabs">
    <ul>
      <li [ngClass]="{ active: currentTab == 0 }">
        <a id="company-tab" (click)="changeTab(0)"
          >Company
          <span *ngIf="counts.companyCount"
            >({{counts.companyCount}}<span *ngIf="counts.companyTotalMatchRelation === 'GTE'">+</span>)</span
          ></a
        >
      </li>
      <li [ngClass]="{ active: currentTab == 1 }">
        <a id="person-tab" (click)="changeTab(1)"
          >Person
          <span *ngIf="counts.personCount"
            >({{counts.personCount}}<span *ngIf="counts.personTotalMatchRelation === 'GTE'">+</span>)</span
          ></a
        >
      </li>
      <li [ngClass]="{ active: currentTab == 2 }">
        <a id="in-progress-tab" (click)="changeTab(2)"
          >In Progress
          <span *ngIf="counts.activeCount || counts.totalCount"
            >({{counts.activeCount}} of {{counts.totalCount}})</span
          ></a
        >
      </li>
    </ul>

    <br class="clr" />

    <div id="company-tab-active" [hidden]="currentTab !== 0">
      <ddiq-company-search
        [isQueryEmpty]="isQueryEmpty()"
        [lastQueryString]="lastQueryString"
        [isInGroup]="isInGroup"
        (openDelegationModal)="openDelegationModal($event)">
      </ddiq-company-search>
    </div>

    <div id="person-tab-active" [hidden]="currentTab !== 1">
      <ddiq-person-search
        [isQueryEmpty]="isQueryEmpty()"
        [lastQueryString]="lastQueryString"
        [isInGroup]="isInGroup"
        (openDelegationModal)="openDelegationModal($event)">
      </ddiq-person-search>
    </div>

    <div id="in-progress-tab-active" [hidden]="currentTab !== 2">
      <ddiq-in-progress-search></ddiq-in-progress-search>
    </div>
  </section>

  <ddiq-modal
    *ngIf="selectedEntity"
    class="ddiq-modal"
    [trigger]="'delegate'"
    [modalName]="'delegate'"
    [closeable]="true">
    <delegate-profile
      [entityType]="selectedEntity.entityType | lowercase"
      [entityId]="selectedEntity.oiqEntityId"
      (onAssigned)="onProfileAssigned($event.assignedTo)"
      (onCancelled)="onProfileAssignCancelled()">
    </delegate-profile>
  </ddiq-modal>

  <br class="clr" />

  <ddiq-footer></ddiq-footer>
</section>
