import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ProfileStatusAdjudicationGuard {
  constructor() {}

  thresholds = ['unassociated', 'unlikely', 'low', 'medium', 'high'];

  getConfidenceThresholdRange(minimumThreshold) {
    const index = this.thresholds.indexOf(minimumThreshold);
    if (index === -1) {
      throw `Unknown confidence threshold ${minimumThreshold}.  Supported confidence thresholds are ${this.thresholds.join(
        ', '
      )}`;
    }

    return this.thresholds.slice(index);
  }

  requiresAdjudication(adjudicationReport, criteria) {
    return this.getConfidenceThresholdRange(criteria.confidenceThreshold).some((confidenceThrshold) => {
      return adjudicationReport[confidenceThrshold].remainingHits > 0;
    });
  }
}
