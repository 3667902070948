import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ddiq-sort-buttons',
  templateUrl: './ddiq-sort-buttons.component.tpl.html',
})
class SortButtonsComponent implements OnInit {
  moreThanOne: boolean;
  buttons: {
    btnName: string;
    btnClass: string;
    fontAwesome: string;
    active: boolean;
    text: string;
    title: string;
  }[];

  @Input() entities: Array<object>;
  @Input() btnTexts: string = '';
  @Input() orderBy: string;
  @Input() activeBtn: any;
  @Output() onClick = new EventEmitter();

  ngOnInit() {
    this.moreThanOne = this.entities.length > 1;

    const btnTexts = (this.btnTexts || '').split(',').map((txt) => {
      return txt.trim();
    });

    const orderBy = (this.orderBy || '').split(',').map((order) => {
      return order.trim();
    });

    this.buttons = orderBy.map((button, index, array) => {
      let active, btnClass, fontAwesome, title, text;

      switch (button) {
        //NOTE: OIQ-22411 For General Information sorts(name, address, source) : move the sort functions out of directive
        case 'name':
          active = false;
          btnClass = btnPosition(index, array);
          text = btnTexts[index] ? btnTexts[index] : 'Name';
          fontAwesome = 'alpha-asc';
          title = 'Sort in ascending order';
          break;

        case 'address':
          active = false;
          btnClass = btnPosition(index, array);
          text = btnTexts[index] ? btnTexts[index] : 'Address';
          fontAwesome = 'alpha-asc';
          title = 'Sort in ascending order';
          break;

        case 'source':
          active = true;
          btnClass = btnPosition(index, array);
          text = btnTexts[index] ? btnTexts[index] : 'Source';
          fontAwesome = 'numeric-desc';
          title = 'Sort in descending order';

          this.moreThanOne = hasSource(this.entities) && this.entities.length > 1;
          break;

        case 'association':
          active = false;
          btnClass = btnPosition(index, array);
          text = btnTexts[index] ? btnTexts[index] : 'Confidence';
          fontAwesome = 'amount-desc';
          title = 'Sort in descending order';
          break;

        case 'date':
          active = false;
          btnClass = btnPosition(index, array);
          text = btnTexts[index] ? btnTexts[index] : 'Date';
          fontAwesome = 'numeric-desc';
          title = 'Sort in descending order';
          break;

        case 'adjudication':
          active = true;
          btnClass = btnPosition(index, array);
          text = btnTexts[index] ? btnTexts[index] : 'Default Sort';
          fontAwesome = 'unsure';
          title = 'Sort by escalated and adjudicated';
          break;

        default:
      }

      //NOTE: OIQ-22411 Re-look the use of activeBtn, how it's defined, do we need it?
      if (this.activeBtn) {
        active = this.activeBtn === button;
      }

      return {
        active: active,
        btnClass: btnClass,
        text: text,
        btnName: button,
        fontAwesome: fontAwesome,
        title: title,
      };
    });
  }

  btnClicked(btn) {
    if (!btn.active) {
      this.buttons.forEach((currentBtn) => {
        currentBtn.active = false;
      });

      if (btn.sort) {
        btn.sort();
      }

      btn.active = true;
      this.onClick.emit({ btnName: btn.btnName });
    }
  }
}

function hasSource(entities) {
  return entities.some((entity) => entity.base.totalSourceCount);
}

function btnPosition(index, array) {
  if (index === 0) {
    return 'first';
  } else if (index === array.length - 1) {
    return 'last';
  } else {
    return 'middle';
  }
}

export default SortButtonsComponent;
