import { NgModule } from '@angular/core';
import OiqThemePageHrefPipe from './oiq-theme-page-href.pipe';
import Theme from './theme.service';
import ThemeAltDirective from './theme-alt.directive';
import ThemeLogoDirective from './theme-logo.directive';
import { CommonModule } from '@angular/common';
import ThemePgTitleDirective from './theme-pg-title.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ThemeAltDirective, ThemeLogoDirective, OiqThemePageHrefPipe, ThemePgTitleDirective],
  providers: [Theme],
  exports: [ThemeAltDirective, ThemeLogoDirective, OiqThemePageHrefPipe, ThemePgTitleDirective],
})
export class OiqThemeModule {}
