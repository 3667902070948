import { Component, Input } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-monitor-results',
  templateUrl: './ddiq-monitor-results.component.tpl.html',
})
export default class MonitorResultsComponent {
  @Input() result;
  private window: Window;

  public showTranslatedSnippets: boolean;
  public showArticleCacheLinks: boolean;

  constructor(private windowRef: WindowRefService, private oiqProperties: OiqProperties) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit() {
    this.showTranslatedSnippets = this.oiqProperties.showTranslatedSnippets;
    this.showArticleCacheLinks = this.oiqProperties.articleCacheEnabled;
  }

  showDeltaReport(profileLink: string): void {
    this.window.location.href = profileLink;
  }
}
