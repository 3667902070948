import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import UserService from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export default class MonitorCrawlPlans {
  private availableCrawlPlans = [];

  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private user: UserService
  ) {}

  loadAvailableCrawlPlans(baselineCrawlPlanKey, callback = () => {}) {
    this.availableCrawlPlans = [];

    if (this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled) {
      return new Promise<void>((resolve, reject) => {
        this.apiFetchService.getMonitorCrawlPlans(baselineCrawlPlanKey).then((availableCrawlPlans) => {
          this.availableCrawlPlans.push(...availableCrawlPlans);
          resolve();
          callback();
        });
      });
    }

    callback();
    return Promise.resolve();
  }

  getAvailableCrawlPlans() {
    return this.availableCrawlPlans;
  }
}
