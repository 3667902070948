import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray',
})
export default class ToArrayPipe implements PipeTransform {
  constructor() {}

  /**
   * Converts an Object into an Array of Objects
   * @param obj
   * @returns {Array}
   */
  transform(obj: any) {
    if (obj) {
      let array = [];
      Object.keys(obj).forEach(function (k) {
        array.push({
          key: k,
          value: obj[k],
        });
      });
      return array;
    }
    return [];
  }
}
