import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ddiq-corporate-records-group',
  templateUrl: './ddiq-corporate-records-group.component.tpl.html',
})
export default class CorporateRecordsGroup implements OnInit {
  @Input() groups: any;
  @Output() sortDiscoveries = new EventEmitter<any>();
  @Input() entityType: string;
  @Input() idPrefix: string;
  @Input() expandAll: boolean;

  adjudicationType: string = 'CORPORATE_RECORD';

  ngOnInit() {
    if (this.entityType === 'person') {
      this.adjudicationType = 'PERSON_CORPORATE_RECORD';
    }
  }

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  id(index: number, record: any) {
    return record.id;
  }

  classification(index: number, group: any) {
    return group.title;
  }
}
