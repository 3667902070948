import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'additionalDataValue',
})
export default class AdditionalDataValuePipe implements PipeTransform {
  constructor() {}

  /**
   * Formats dates to MMM D, YYYY format
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    let filtered;
    if (moment(input).isValid()) {
      filtered = moment(input).format('MMM D, YYYY');
    } else {
      filtered = input;
    }
    return filtered;
  }
}
