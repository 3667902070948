import { Injectable } from '@angular/core';

function getNumberOfPages(totalCount, maxItemsPerPage) {
  let numberOfPages = Math.ceil(totalCount / maxItemsPerPage);

  if (totalCount < maxItemsPerPage || numberOfPages === 1) {
    numberOfPages = 0;
  }

  return numberOfPages;
}

function getCurrentPageIndex(recordIndex, maxItemsPerPage) {
  return Math.floor(Math.max(recordIndex, 0) / maxItemsPerPage);
}

@Injectable({
  providedIn: 'root',
})
export default class Paginator {
  paginationFor(totalCount, maxItemsPerPage, recordIndex = 0) {
    return {
      numberOfPages: getNumberOfPages(totalCount, maxItemsPerPage),
      currentPageIndex: getCurrentPageIndex(recordIndex, maxItemsPerPage),
      totalCount,
    };
  }

  startingRowForPageIndex(pageIndex, maxItemsPerPage) {
    const page = pageIndex + 1;
    return page * maxItemsPerPage - maxItemsPerPage;
  }
}
