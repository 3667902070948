import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ddiq-simple-collapse',
  templateUrl: './ddiq-simple-collapse.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class DdiqNgSimpleCollapseComponent implements OnInit {
  @Input() toggleTextMore;
  @Input() toggleTextLess: any;
  @Input() simpleCollapse: any;
  @Input() rememberPosition: boolean;
  @Input() showButton: boolean;
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.toggleTextMore = this.toggleTextMore ? this.toggleTextMore : '+';
    this.toggleTextLess = this.toggleTextLess ? this.toggleTextLess : '-';
    this.rememberPosition = this.rememberPosition ? this.rememberPosition : false;
    this.simpleCollapse = {
      showAdditional: false,
      toggleText: this.toggleTextMore,
      rememberPosition: this.rememberPosition,
      scrollPosition: 0,
    };
    const printStyle = this.route.snapshot.paramMap.get('print');
    if (printStyle === 'expanded') {
      this.simpleCollapse.showAdditional = true;
    }
  }

  toggleAdditional() {
    if (this.simpleCollapse.rememberPosition) {
      if (!this.simpleCollapse.showAdditional) {
        this.simpleCollapse.scrollPosition = window.scrollY;
      } else {
        window.scroll(this.simpleCollapse.scrollPosition);
        this.simpleCollapse.scrollPosition = 0;
      }
    }
    this.simpleCollapse.showAdditional = !this.simpleCollapse.showAdditional;
    this.simpleCollapse.toggleText = this.simpleCollapse.showAdditional ? this.toggleTextLess : this.toggleTextMore;
  }
}
