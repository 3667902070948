import UserService from '../user/user.service';
import ApiFetchService from '../common-services/api-fetch.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ProfileDelegation {
  private CONFLICT = 409;

  constructor(private userService: UserService, private apiFetchService: ApiFetchService) {}

  getDelegates(entityType, entityId) {
    return Promise.all([
      this.apiFetchService.summary(entityType, entityId, false),
      this.apiFetchService.usersInGroup(this.userService.getGroup()),
    ]).then(function (responses) {
      return {
        currentOwner: responses[0].oiqOwner,
        users: responses[1].users,
      };
    });
  }

  assign(entityType, entityId, ownership) {
    return this.apiFetchService.updateOwner(entityType, entityId, ownership).then(
      function (resp) {
        return resp.owner;
      },
      (resp) => {
        let error = {
            owner: undefined,
            errorMsg: undefined,
          },
          msg;

        if (resp.status === this.CONFLICT) {
          msg = 'OWNER_CHANGE_CONFLICT_ERROR';
          error.owner = resp.error.owner;
        } else {
          msg = 'UNKNOWN_OWNER_CHANGE_ERROR';
        }

        error.errorMsg = msg;

        return new Promise((resolve, reject) => reject(error));
      }
    );
  }
}
