import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class MonitorResolverService implements Resolve<OiqProperties> {
  constructor(private oiqProperties: OiqProperties) {}

  resolve(): Promise<OiqProperties> {
    return this.oiqProperties.apiPropertiesPromise;
  }
}
