<div class="monitor-filters">
  <ddiq-accordion id="monitor-adjudication-filter" label="Adjudication Status" [open]="true">
    <div class="adjudication-filter-btn">
      Confirmed
      <ddiq-filter-buttons
        [isShowEnabled]="query.confirmed === 'show'"
        [isHideEnabled]="query.confirmed === 'hide'"
        (onClicked)="onConfirmedClick($event.show, $event.hide)"></ddiq-filter-buttons>
    </div>
    <div class="adjudication-filter-btn">
      Escalated
      <ddiq-filter-buttons
        [isShowEnabled]="query.escalated === 'show'"
        [isHideEnabled]="query.escalated === 'hide'"
        (onClicked)="onEscalatedClick($event.show, $event.hide)"></ddiq-filter-buttons>
    </div>
    <div class="adjudication-filter-btn">
      Removed
      <ddiq-filter-buttons
        [isShowEnabled]="query.adjudicated === 'show'"
        [isHideEnabled]="query.adjudicated === 'hide'"
        (onClicked)="onAdjudicatedClick($event.show, $event.hide)"></ddiq-filter-buttons>
    </div>
  </ddiq-accordion>
  <ddiq-accordion id="monitor-event-filter" label="Event">
    <ddiq-monitor-event-filter
      noResultMsg="no events discovered"
      (onClicked)="toggle($event.eventModel, 'events')"
      [events]="events"></ddiq-monitor-event-filter>
  </ddiq-accordion>
  <ddiq-accordion id="monitor-watchlist-filter" label="Watchlist Category">
    <ddiq-monitor-event-filter
      noResultMsg="no watchlists discovered"
      (onClicked)="toggle($event.eventModel, 'watchlistEvents')"
      [events]="watchlistEvents"></ddiq-monitor-event-filter>
  </ddiq-accordion>
  <ddiq-accordion *ngIf="isIdTypeFilterEnabled" id="monitor-id-type-filter" label="ID Type">
    <ddiq-monitor-event-filter
      noResultMsg="no identification type discovered"
      (onClicked)="toggle($event.eventModel, 'idType')"
      [events]="idTypes"></ddiq-monitor-event-filter>
  </ddiq-accordion>

  <ddiq-accordion *ngIf="isRegionFilterEnabled" id="monitor-region-filter" label="Region">
    <ddiq-monitor-event-filter
      noResultMsg="no regions discovered"
      (onClicked)="toggle($event.eventModel, 'regions')"
      [events]="regions"></ddiq-monitor-event-filter>
  </ddiq-accordion>

  <ddiq-accordion *ngIf="isClientAccountFilterEnabled" id="monitor-client-account-filter" label="Client Account">
    <ddiq-monitor-event-filter
      noResultMsg="no client account discovered"
      (onClicked)="toggle($event.eventModel, 'clientAccount')"
      [events]="clientAccount"></ddiq-monitor-event-filter>
  </ddiq-accordion>
</div>
