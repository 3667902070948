import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxValue',
})
export default class MaxValuePipe implements PipeTransform {
  constructor() {}

  /**
   * Returns the max value of 'field' within an array
   * Takes in a Pipe as 'Translator' to convert values to appropriate formats
   * @param array
   * @param field
   * @param translator
   * @returns
   */
  transform(array: any, field: any, translator: any = undefined) {
    let i,
      maxval = null,
      result = null;
    if (!Array.isArray(array)) return result;
    for (i = 0; i < array.length; ++i) {
      let value = array[i][field];
      if (typeof translator !== 'undefined') {
        value = translator.transform(value);
      }
      if (!maxval || maxval < value) {
        maxval = value;
        result = array[i][field];
      }
    }
    return result;
  }
}
