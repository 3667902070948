import { Injector, Injectable } from '@angular/core';
import IncidentByClassificationExtractionStrategy from './incident-by-classification-extraction-strategy.service';
import IncidentBySectionExtractionStrategy from './incident-by-section-extraction-strategy.service';
import { Constant } from '../common/ddiq-constants';

@Injectable({
  providedIn: 'root',
})
export default class IncidentExtractionStrategyFactoryService {
  private strategies = {
    classification: IncidentByClassificationExtractionStrategy,
    incident: IncidentBySectionExtractionStrategy,
    cluster: IncidentByClassificationExtractionStrategy,
  };

  constructor(private injector: Injector) {}

  getStrategy(webContentType?) {
    return this.injector.get(this.strategies[webContentType || Constant.WEB_CONTENT_TYPE]);
  }
}
