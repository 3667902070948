import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export default class TruncatePipe implements PipeTransform {
  constructor() {}

  /**
   * Returns truncated input based on the `length`
   * Will append ... at the end of the input
   * @param input
   * @param length
   * @returns {String}
   */
  transform(input: any, length: any) {
    let localLength, truncatedInput, whiteSpaceIndex, trimmedInput;

    if (!input) {
      return '';
    }

    trimmedInput = input.trim();

    localLength = length;
    whiteSpaceIndex = -1;

    while (whiteSpaceIndex === -1 && trimmedInput.length > localLength) {
      if (trimmedInput.charAt(localLength) === ' ') {
        whiteSpaceIndex = localLength;
      } else {
        localLength++;
      }
    }

    truncatedInput = trimmedInput.substring(0, localLength).trim();

    if (whiteSpaceIndex !== -1) {
      truncatedInput += '...';
    }

    return truncatedInput;
  }
}
