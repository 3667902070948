import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import AssociationBuckets from '../association/association-buckets.service';
import IncidentsService from './incidents.service';

@Injectable({
  providedIn: 'root',
})
export default class IncidentsUtils {
  private static associationBuckets: AssociationBuckets;

  constructor(
    private incidents: IncidentsService,
    private AssociationBuckets: AssociationBuckets,
    private oiqProperties: OiqProperties
  ) {
    IncidentsUtils.associationBuckets = this.AssociationBuckets.getBuckets();
  }

  // returns object which self-updates whenever Incidents.filter is called
  observedCount(sectionName) {
    const observedCount = {};

    this.incidents.registerFilterObserver(() => this.count(observedCount, sectionName));

    this.count(observedCount, sectionName);

    return observedCount;
  }

  private count(observedCount, sectionName) {
    let filteredCount = 0,
      unfilteredCount = 0,
      filteredEscalatedCount = 0,
      unfilteredEscalatedCount = 0;

    this.incidents.applyToIncidents((incident) => {
      unfilteredCount++;
      if (!incident.filtered) {
        filteredCount++;
      }

      if (incident.escalated) {
        unfilteredEscalatedCount++;
        if (!incident.filtered) {
          filteredEscalatedCount++;
        }
      }
    }, sectionName);

    observedCount.filtered = filteredCount;
    observedCount.unfiltered = unfilteredCount;
    observedCount.filteredEscalated = filteredEscalatedCount;
    observedCount.unfilteredEscalated = unfilteredEscalatedCount;
  }

  sortByAssociation(incidents) {
    (incidents || []).sort(this.createTiebreakingSorter(IncidentsUtils.associationBucketSortDescending));
  }

  sortByDate(incidents) {
    (incidents || []).sort(this.createTiebreakingSorter(IncidentsUtils.dateSortDescending));
  }

  sortByAdjudicated(incidents) {
    (incidents || []).sort(this.createTiebreakingSorter(IncidentsUtils.escalatedSort));
  }

  sortBySeverity(incidents) {
    (incidents || []).sort(this.createTiebreakingSorter(IncidentsUtils.severitySort));
  }

  sortIncidents(incidents) {
    if (!Array.isArray(incidents)) {
      return;
    }

    if (this.oiqProperties.adjudicationEnabled || this.oiqProperties.autoAdjudicationEnabled) {
      this.sortByAdjudicated(incidents);
    } else {
      this.sortByAssociation(incidents);
    }
  }

  // Array.prototype.sort is non-deterministic
  private static tiebreakSort(lhs, rhs) {
    let i = 0,
      result = 0,
      sorts = [];

    // order tiebreaking sorts
    sorts.push(IncidentsUtils.associationBucketSortDescending);
    sorts.push(IncidentsUtils.dateSortDescending);
    sorts.push(IncidentsUtils.labelSortAscending);
    sorts.push(IncidentsUtils.titleSortAscending);

    while (!result && i < sorts.length) {
      result = sorts[i](lhs, rhs);
      i++;
    }

    if (result === 0) {
      // ErrorHandler.log('sorted without finding a deterministic result [lhs, rhs]:', lhs, rhs);
    }

    return result;
  }

  private createTiebreakingSorter(sorter) {
    return (lhs, rhs) => {
      const result = sorter(lhs, rhs);

      return result ? result : IncidentsUtils.tiebreakSort(lhs, rhs);
    };
  }

  // most recent first
  private static dateSortDescending(lhs, rhs) {
    const lhsDate = lhs.lastReportedDate || '0',
      rhsDate = rhs.lastReportedDate || '0';

    return IncidentsUtils.sortAlphabetical(lhsDate, rhsDate, true);
  }

  // highest association first
  private static associationBucketSortDescending(lhs, rhs) {
    return (
      IncidentsUtils.associationBuckets[rhs.summarizedAssociationBucket] -
      IncidentsUtils.associationBuckets[lhs.summarizedAssociationBucket]
    );
  }

  // alphabetical, ascending
  private static labelSortAscending(lhs, rhs) {
    return IncidentsUtils.sortAlphabetical(lhs.summary[0].label, rhs.summary[0].label);
  }

  private static titleSortAscending(lhs, rhs) {
    return IncidentsUtils.sortAlphabetical(lhs.evidence[0].references[0].title, rhs.evidence[0].references[0].title);
  }

  private static sortAlphabetical(lhs, rhs, isReversed?) {
    if (lhs === rhs) return 0;

    if (lhs < rhs) return isReversed ? 1 : -1;

    return isReversed ? -1 : 1;
  }

  private static escalatedSort(lhs, rhs) {
    const lhsEscalated = IncidentsUtils.isAdjudicated(lhs, 'escalated'),
      rhsEscalated = IncidentsUtils.isAdjudicated(rhs, 'escalated'),
      lhsItem = -1,
      rhsItem = 1;

    if (lhsEscalated && !rhsEscalated) {
      return lhsItem;
    } else if (rhsEscalated && !lhsEscalated) {
      return rhsItem;
    } else {
      return IncidentsUtils.adjudicatedSort(lhs, rhs);
    }
  }

  private static adjudicatedSort(lhs, rhs) {
    const lhsAdjudicated = IncidentsUtils.isAdjudicated(lhs, 'adjudicated'),
      rhsAdjudicated = IncidentsUtils.isAdjudicated(rhs, 'adjudicated'),
      lhsItem = -1,
      rhsItem = 1,
      tieBreak = 0;

    if (lhsAdjudicated && !rhsAdjudicated) {
      return rhsItem;
    } else if (rhsAdjudicated && !lhsAdjudicated) {
      return lhsItem;
    } else {
      return tieBreak;
    }
  }

  private static isAdjudicated(incident, adjudicateType) {
    return incident.adjudication && incident.adjudication[adjudicateType];
  }

  private static severitySort(lhs, rhs) {
    const rankLhsLower = -1,
      rankRhsLower = 1,
      rankSame = 0,
      lhsSeverity = IncidentsUtils.getTotalIncidentSeverity(lhs),
      rhsSeverity = IncidentsUtils.getTotalIncidentSeverity(rhs);

    if (lhsSeverity > rhsSeverity) {
      return rankLhsLower;
    }

    if (lhsSeverity < rhsSeverity) {
      return rankRhsLower;
    }

    return rankSame;
  }

  private static getTotalIncidentSeverity(incident) {
    const initialValue = 0,
      defaultValue = 0;

    return (
      (incident.summary || [])
        .map(function (summary) {
          return summary.severity;
        })
        .reduce(function (previousSummary, currentSummary) {
          return previousSummary + currentSummary;
        }, initialValue) || defaultValue
    );
  }
}
