<div class="alert alert-warning alert-monitor" *ngIf="!isScreeningEnabled && (isMonitored() && monitoringEnabled)">
  <div class="alert-icon"><span class="fa fa-eye fa-2x"></span></div>
  <div [ngSwitch]="isDeltaReport()">
    <div *ngSwitchCase="true">
      <div [ngSwitch]="monitorData().crawlType">
        <div *ngSwitchCase="'BCA'">
          <p>This is the monitoring report for <a [href]="reportUrl" class="bold">{{entityName()}}</a>.</p>
          <p>
            This report is being run <span class="bold">{{monitorData().frequency}}</span>.
            <span *ngIf="monitorData().lastRunAt"
              >It was last run on <span class="bold">{{ monitorData().lastRunAt | dateFormat}}</span>, and will be run
              again on <span class="bold">{{ monitorData().nextRunAt | dateFormat}}</span>.</span
            >
            <span *ngIf="!monitorData().lastRunAt"
              >The first run will occur on <span class="bold">{{ monitorData().nextRunAt | dateFormat}}</span>.</span
            >
          </p>
        </div>
        <div *ngSwitchCase="'MAAS'">
          <p>This is the monitoring report for <a [href]="reportUrl" class="bold">{{entityName()}}</a>.</p>
          <p *ngIf="monitorData().lastRunAt">
            The latest match received for this report was received on
            <span class="bold">{{ monitorData().lastRunAt | dateFormat}}</span>.
          </p>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="false">
      <div *ngIf="isMonitorActive()">
        <div [ngSwitch]="monitorData().crawlType">
          <div *ngSwitchCase="'BCA'">
            <p>
              This entity is being monitored <span class="bold">{{monitorData().frequency}}</span>.
              <span *ngIf="monitorData().lastRunAt"
                >It was last run on <span class="bold">{{ monitorData().lastRunAt | dateFormat}}</span>, and will be run
                again on <span class="bold">{{ monitorData().nextRunAt | dateFormat}}</span>.</span
              >
              <span *ngIf="!monitorData().lastRunAt"
                >The first run will occur on <span class="bold">{{ monitorData().nextRunAt | dateFormat}}</span>.</span
              >
            </p>
            <p *ngIf="monitorData().lastRunAt">
              The monitoring report can be found here: <a [href]="deltaReportUrl" class="bold">{{entityName()}}</a>.
            </p>
          </div>
          <div *ngSwitchCase="'MAAS'">
            <p>
              This entity is being monitored<span *ngIf="monitorData().lastRunAt"
                >. The latest match received for this entity was received on
                <span class="bold">{{ monitorData().lastRunAt | dateFormat}}</span>.</span
              >
            </p>
            <p>
              The monitoring report can be found here: <a [href]="deltaReportUrl" class="bold">{{entityName()}}</a>.
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!isMonitorActive()">
        <p>
          This entity is no longer monitored.
          <span *ngIf="monitorData().lastRunAt">
            It was last run on <span class="bold">{{ monitorData().lastRunAt | dateFormat}}</span>.
          </span>
          The monitoring report can be found here:
          <a [href]="deltaReportUrl" class="bold">{{entityName()}}</a>.
        </p>
      </div>
    </div>
  </div>
</div>
