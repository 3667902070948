import { Component, Input, OnInit } from '@angular/core';
import SelectedArticlesDownloaderService from './selected-articles-downloader.service';
import SelectedArticlesFeatureService from './selected-articles-feature.service';
import SelectedArticlesService from './selected-articles.service';

@Component({
  selector: 'selected-article-download-link',
  templateUrl: 'selected-article-download-link.component.tpl.html',
})
export default class SelectedArticleDownloadLinkComponent implements OnInit {
  @Input() entityType;
  @Input() entityId;
  @Input() onDownloadRequested;

  isBusy = false;
  isEnabled;

  constructor(
    private selectedArticles: SelectedArticlesService,
    private selectedArticlesFeature: SelectedArticlesFeatureService,
    private selectedArticlesDownloader: SelectedArticlesDownloaderService
  ) {}

  ngOnInit() {
    this.isEnabled = this.selectedArticlesFeature.isEnabled();
  }

  click() {
    if (this.hasArticlesSelected() && !this.isBusy) {
      this.isBusy = true;

      this.selectedArticles
        .save(this.entityType, this.entityId)
        .then((saved) => {
          this.selectedArticlesDownloader.download(this.entityType, this.entityId, saved.selectedArticlesId);

          this.selectedArticles.removeAll();

          if (this.onDownloadRequested) {
            this.onDownloadRequested();
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    }
  }

  hasArticlesSelected() {
    return this.selectedArticles.hasArticlesSelected();
  }
}
