import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import AddressPipe from '../ddiq-filters/address.pipe';
import isAllFiltered from '../themed-entity/is-all-filtered';

@Component({
  selector: 'ddiq-location-list',
  templateUrl: './ddiq-location-list.component.tpl.html',
})
export default class LocationListComponent implements OnInit {
  @Input() locations: any;
  @Input() alertsEnabled: boolean;
  @Input() title: string;
  @Input() adjudicationSupport: boolean;
  @Output() adjudicationComplete = new EventEmitter<any>();

  sortBy = {
    source: ['-base.totalSourceCount', 'base.addressPiped'],
    address: ['base.addressPiped', '-base.totalSourceCount'],
  };
  sortBtnOrderType = this.sortBy.source;

  isAllFiltered: (entities) => boolean;

  constructor(private addressPipe: AddressPipe) {
    this.isAllFiltered = isAllFiltered;
  }

  ngOnInit() {
    this.locations.forEach((element) => {
      element.base.addressPiped = this.addressPipe.transform(element.base.address);
    });
  }

  onAdjudicationComplete($event: any) {
    this.adjudicationComplete.emit($event);
  }

  oiqId(index: number, relatedEntity: any) {
    return relatedEntity.base.oiqId;
  }

  sort(btnName) {
    if (btnName) this.sortBtnOrderType = this.sortBy[btnName];
  }
}
