import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import utils from '../../../exiger/utils';
import ApiFetchService from './api-fetch.service';
import Entity from './entity.service';

@Injectable({
  providedIn: 'root',
})
export default class SectionConfigService {
  private cfgMap = {};
  private cfgOrder = {};

  constructor(
    private apiFetchService: ApiFetchService,
    private entityService: Entity,
    private errorHandler: ErrorHandler
  ) {}

  fetchConfig(entityType) {
    let promises = [],
      promise;

    if (entityType === 'location') {
      entityType = 'property';
    }

    promises.push(this.fetchSectionsConfig(this.cfgOrder, entityType, 'Order.json'));
    promises.push(this.fetchSectionsConfig(this.cfgMap, entityType, 'Map.json'));

    promise = Promise.all([promises]);

    return promise;
  }

  // configFile is the filename
  configSections(entityType, cfgEnv, apiData) {
    const sections = [];
    this.orderSections(entityType, cfgEnv, apiData, sections);
    return sections;
  }

  getSubSections(entityType, sectionId) {
    let i,
      config = this.cfgOrder[entityType] || [],
      subsections = [];

    for (i = 0; i < config.length; i++) {
      if (config[i].sectionId === sectionId) {
        return config[i].subsections || subsections;
      }
    }

    return subsections;
  }

  /** takes the api fields and maps them to the fields/sections we use in the UI
   * uiDefault : default mapping of uiFields -> apiFields
   * cfgEnv : any environment specific mapping settings that differ from uiDefault
   * path : current location in mapping file -- used to look up cfgEnv fields
   * apiData : object returned by the api
   **/
  private mapFromApi(uiDefault, cfgEnv, path, apiData) {
    let DISPLAY = '_display',
      OIQPROPERTIES_DISPLAY = '.display',
      populatedObj = {},
      tempObj,
      dynamicSectionConfig = this.entityService.getDynamicSectionConfig() || {};

    // check to see if this section is disabled
    if (typeof uiDefault[DISPLAY] !== 'undefined') {
      const printStyle = utils.getParameter('print', window.location);
      if (printStyle && uiDefault[DISPLAY] === 'hide-for-print') {
        return null;
      }

      if (this.canOverrideSectionVisibility(uiDefault[DISPLAY])) {
        if (cfgEnv[path + OIQPROPERTIES_DISPLAY]) {
          uiDefault[DISPLAY] = cfgEnv[path + OIQPROPERTIES_DISPLAY] === 'true' ? 'visible' : 'hidden';
        }

        if (dynamicSectionConfig[path + OIQPROPERTIES_DISPLAY]) {
          uiDefault[DISPLAY] = dynamicSectionConfig[path + OIQPROPERTIES_DISPLAY];
        }
      }

      if (uiDefault[DISPLAY] === 'hidden') {
        return null;
      }
    }

    Object.keys(uiDefault).forEach((key) => {
      const value = uiDefault[key];
      if (typeof value === 'object') {
        tempObj = this.mapFromApi(value, cfgEnv, path + '.' + key, apiData);
        if (tempObj !== null) {
          populatedObj[key] = tempObj;
        }
        // WR: lastIndexOf searches backwards
      } else if (key.lastIndexOf('_') === 0) {
        // copy values directly in -- currently _display only
        populatedObj[key] = value;
      } else if (typeof apiData[value] !== 'undefined') {
        populatedObj[key] = apiData[value];
      }
    });

    return populatedObj;
  }

  private canOverrideSectionVisibility(displayValue) {
    return displayValue === 'visible';
  }

  /** loops through the ordering and inserts the data into the section if it
   * exists in the cfg file.
   * is passing arrays by reference good practice in javascript?
   * entityType = company, property, or person
   * cfgEnv = a list of environment/customer specific settings
   * apiData = results from EntityService
   * sections = array to be filled
   * leftNav = from LeftNav service
   **/
  private orderSections(entityType, cfgEnv, apiData, sections) {
    let i = 0,
      sectionId = '',
      mappedData,
      order;

    if (typeof this.cfgMap[entityType] === 'undefined' || typeof this.cfgOrder[entityType] === 'undefined') {
      this.errorHandler.error('order or map is not defined while trying to order sections');
      return;
    }

    mappedData = this.mapFromApi(this.cfgMap[entityType], cfgEnv, 'ui', apiData);
    order = this.cfgOrder[entityType];

    for (i; i < order.length; ++i) {
      sectionId = order[i].sectionId;
      order[i].entityType = entityType;
      if (typeof mappedData[sectionId] !== 'undefined') {
        sections.push({
          data: mappedData[sectionId].data,
          noncontent: mappedData[sectionId].noncontent,
          metadata: order[i],
        });
      }
    }
  }

  // returns a resolved promise if the config files have been fetched,
  // otherwise fetches and stores the file entityType+configFileSuffix+'.json'
  private fetchSectionsConfig(configObject, entityType, configFileSuffix) {
    return new Promise((resolve) => {
      let filename;

      if (typeof configObject[entityType] !== 'undefined') {
        resolve(undefined);
        return;
      }

      var fetchSuccess = function (data) {
        configObject[entityType] = data;
        resolve(undefined);
      };

      var fetchFail = (response) => {
        this.errorHandler.error('Sections configuration fetch failed');
        this.errorHandler.error(response);
      };

      filename = entityType + configFileSuffix;
      this.apiFetchService.config(filename).then(fetchSuccess, fetchFail);
    });
  }
}
