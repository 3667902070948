import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'ddiq-drawer',
  templateUrl: './ddiq-drawer.component.tpl.html',
})
export default class Drawer implements AfterViewInit {
  splitHandler: any;
  container: any;
  boxContent: any;
  xPositionStart: number;
  drawerClosedWidth: string = '0px';
  isOpen: boolean = true; // default set to open
  isClosedThresholdValue: number = 15;
  isSplitHandlerActive: boolean;

  @Input() drawerOpenWidth: string = '300px';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.container = this.elementRef.nativeElement;
    this.splitHandler = this.container.querySelector('.split-handler');
    this.boxContent = this.container.querySelector('.box-content');
    const dragEventHandler = (e) => this.dragEvent(e);

    this.splitHandler.addEventListener('mousedown', (e) => {
      this.isSplitHandlerActive = true;
      this.xPositionStart = e.pageX;
      document.addEventListener('mousemove', dragEventHandler);
    });

    this.splitHandler.addEventListener('mouseup', (e) => {
      if (!this.isHandlerDragging(e)) {
        this.toggle();
      } else {
        this.isOpen = e.pageX > this.isClosedThresholdValue;
      }
      document.removeEventListener('mousemove', dragEventHandler);
      this.isSplitHandlerActive = false;
    });

    this.setToggleWidth();
  }

  dragEvent(e): void {
    // Get offset
    const containerOffsetLeft = this.container.offsetLeft;

    // Get x-coordinate of pointer relative to container
    const pointerRelativeXpos = e.clientX - containerOffsetLeft;

    // Resize box content
    // * Set flex-grow to 0 to prevent it from growing
    this.boxContent.style.width = pointerRelativeXpos - 8 + 'px';
    this.boxContent.style.flexGrow = 0;
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.setToggleWidth();
  }

  private setToggleWidth() {
    this.boxContent.style.width = this.isOpen ? this.drawerOpenWidth : this.drawerClosedWidth;
  }

  isHandlerDragging(e): boolean {
    return this.xPositionStart !== e.pageX;
  }
}
