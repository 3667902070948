import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ExclusionsService {
  exclusions = {};
  profileHits = {
    RECORD_CLUSTER: [],
    WATCHLIST: [],
    REGULATORY: [],
    WEB_CONTENT: [],
  };

  ngOnInit() {
    this.clear();
  }

  registerHit(type, category) {
    if (this.profileHits[type].indexOf(category) < 0) {
      this.profileHits[type].push(category);
    }
  }

  setExclusions(data) {
    this.exclusions = data;
  }

  getProfileHits() {
    return this.profileHits;
  }

  getExclusions() {
    return this.exclusions;
  }

  clear() {
    this.exclusions = {};
    this.profileHits = {
      RECORD_CLUSTER: [],
      WATCHLIST: [],
      REGULATORY: [],
      WEB_CONTENT: [],
    };
  }
}
