<div id="schedule-refresh">
  <div class="modal-title">Schedule Profile Refresh</div>
  <div class="modal-content pushdown">
    <form novalidate (ngSubmit)="scheduleProfileRefresh(request);">
      <div class="center">
        <div class="input half">
          <label>Refresh Frequency</label>
          <select name="refreshFrequency" [(ngModel)]="request.frequency" size="sixth">
            <option *ngFor="let option of profileRefreshFrequencies" value="{{option}}">{{option | translatef}}</option>
          </select>
        </div>

        <div class="input half">
          <ddiq-date
            id="schedule-start-date-input"
            [inputId]="'schedule-start-date'"
            [label]="'From Date'"
            [size]="'fixed-date'"
            [(model)]="request.startDate"
            [options]="request.dates.options.after"
            [labelPattern]="labelPattern"
            [datePattern]="datePattern"></ddiq-date>
        </div>
      </div>

      <div class="message push-down pushdown clr" *ngIf="scheduleConflicted">
        <div class="warn-message">
          This profile has already been scheduled by another user or session. The profile has been updated with the
          latest profile refresh information.
        </div>
      </div>

      <div class="message push-down pushdown clr" *ngIf="errorMessage">
        <div class="warn-message">{{errorMessage}}</div>
      </div>

      <div class="center push-down pushdown clr">
        <button
          id="schedule-profile-button"
          *ngIf="!scheduleConflicted"
          type="submit"
          class="btn btn-primary"
          [disabled]="!request.frequency ">
          Schedule
        </button>
        <button id="clear-button" type="button" class="btn btn-danger" (click)="cancel()">
          {{ scheduleConflicted && 'Close' || 'Cancel' }}
        </button>
      </div>
    </form>
  </div>
</div>
