<div class="input {{size}}" [ngClass]="{disabled : textDisabled}">
  <label [ngClass]="{required: isRequired()}">{{label}}</label>
  <input
    [name]="name"
    type="text"
    [required]="isRequired()"
    [pattern]="pattern"
    [disabled]="textDisabled"
    [placeholder]="oiqPlaceHolder"
    [(ngModel)]="model"
    [maxlength]="maxLength"
    (ngModelChange)="onTextChange(model)" />
</div>
