import isAllFiltered from '../themed-entity/is-all-filtered';
import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import UserService from '../user/user.service';

@Component({
  selector: 'ddiq-company-list',
  templateUrl: './ddiq-company-list.component.tpl.html',
})
export default class DdiqCompanyListComponent implements OnInit {
  @Input() companies;
  @Input() alertsEnabled;
  @Output() requestReport: any = new EventEmitter<any>();
  @Input() title: string;
  @Output() adjudicationComplete: any = new EventEmitter<any>();
  @Input() adjudicationSupport;

  canUserSubmitProfile: boolean;
  isAllFiltered;

  sortBy = {
    source: ['-base.totalSourceCount', 'base.name'],
    name: ['base.name', '-base.totalSourceCount'],
  };
  sortBtnOrderType = this.sortBy.source;

  constructor(private user: UserService) {
    this.isAllFiltered = isAllFiltered;
  }

  ngOnInit() {
    this.canUserSubmitProfile = this.user.canSubmitProfile();
  }

  request(requestSpec) {
    this.requestReport.emit(requestSpec);
  }

  id(index: number, entity: any) {
    return entity.base.oiqId;
  }

  isAdjudicationComplete(event) {
    this.adjudicationComplete.emit(event);
  }

  sort(btnName) {
    if (btnName) this.sortBtnOrderType = this.sortBy[btnName];
  }
}
