import { OnInit, DoCheck, OnChanges, Component, Input, ElementRef } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import ExcludeAdjudicationActionsPipe from '../ddiq-filters/exclude-adjudication-actions.pipe';
import oiqAdjudicationAware from './oiq-adjudication-aware.directive';
import { take } from 'rxjs/operators';
import AdjudicationRepository from './adjudication-repository';

@Component({
  selector: 'ddiq-adjudication-notes',
  templateUrl: './ddiq-adjudication-notes.component.tpl.html',
})
export default class AdjudicationNotesComponent implements OnInit, DoCheck, OnChanges {
  @Input() targetId: string;
  @Input() model: any;
  @Input() classification: string;
  @Input() active: any;
  @Input() label: string;
  @Input() type: string;
  @Input() entityType: string;
  @Input() entityId: any;
  @Input() noReassess: boolean;

  adjudicationEnabled: boolean;
  isEditingComment: boolean;
  wasEditingComment: boolean;
  tempModel: any;

  constructor(
    private oiqProperties: OiqProperties,
    private excludeAdjudicationActions: ExcludeAdjudicationActionsPipe,
    private elementRef: ElementRef,
    private oiqAdjudicationAware: oiqAdjudicationAware,
    private adjudicationRepository: AdjudicationRepository
  ) {}

  ngOnInit() {
    this.adjudicationEnabled = this.oiqAdjudicationAware.isAdjudicationEnabled();

    this.isEditingComment = false;
    this.wasEditingComment = false;

    if (this.model && this.model.adjudication) {
      this.model.adjudication.history = this.excludeAdjudicationActions.transform(
        this.model.adjudication.history,
        this.oiqProperties.hiddenAdjudicationActions
      );
    }
  }

  ngDoCheck() {
    this.isEditingComment = this.oiqAdjudicationAware.isEditingComment();

    if (this.isEditingComment !== this.wasEditingComment) {
      this.ensureCommentBoxInView();
      this.wasEditingComment = this.isEditingComment;
    }
  }

  ngOnChanges(changes) {
    if (changes.model) {
      let comment = '';

      if (this.model.adjudication && this.model.adjudication.current) {
        comment = this.model.adjudication.current.comment || comment;
      }

      this.tempModel = comment;
    }
  }

  getReasons(adjudicationEntry) {
    if (adjudicationEntry.status) {
      return adjudicationEntry.status.split(',');
    }

    return [];
  }

  getComments(adjudicationEntry) {
    if (adjudicationEntry.comment) {
      return adjudicationEntry.comment.split('|');
    }

    return [];
  }

  cancel() {
    this.active = false;
    this.adjudicationRepository.removeAdjudication();
    this.oiqAdjudicationAware.cancelEditComment();
    if (this.model.adjudication && this.model.adjudication.current) {
      this.tempModel = this.model.adjudication.current.comment;
    }
  }

  save() {
    this.active = false;
    this.adjudicationRepository.adjudication$.pipe(take(1)).subscribe((value) => {
      if (!value) {
        this.oiqAdjudicationAware.adjudicate({
          entityType: this.entityType,
          entityId: this.entityId,
          modelType: this.type,
          source: this.model,
          targetId: this.targetId,
          reason: this.model.adjudication ? this.model.adjudication.current.status : null,
          confirmed: this.model.adjudication ? this.model.adjudication.current.confirmed : false,
          escalated: this.model.adjudication ? this.model.adjudication.current.escalated : false,
          comment: this.tempModel,
          classification: this.classification,
          reassess: !this.noReassess,
          action: 'COMMENT',
          history: this.model.adjudication
            ? this.excludeAdjudicationActions.transform(
                this.model.adjudication.history,
                this.oiqProperties.hiddenAdjudicationActions
              )
            : [],
        });
      } else {
        this.oiqAdjudicationAware
          .adjudicate({
            entityType: value.entityType,
            entityId: value.entityId,
            modelType: value.modelType,
            source: value.source,
            targetId: value.targetId,
            reason: value.reason,
            confirmed: value.confirmed,
            escalated: value.escalated,
            comment: this.tempModel,
            classification: value.classification,
            reassess: !value.noReassess,
            action: value.action,
            history: value.source.adjudication
              ? this.excludeAdjudicationActions.transform(
                  value.source.adjudication.history,
                  this.oiqProperties.hiddenAdjudicationActions
                )
              : [],
          })
          .then(() => {
            this.adjudicationRepository.removeAdjudication();
          });
      }
    });
  }

  ensureCommentBoxInView() {
    this.tempModel = '';

    if (this.isEditingComment) {
      setTimeout(() => {
        const commentBoxElem = this.elementRef.nativeElement.querySelector('.comment-box');
        commentBoxElem.scrollIntoView(false);
        commentBoxElem.querySelector('textarea').focus();
      }, 1);
    }
  }
}
