import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root',
})
export default class OktaAuthService {
  private authClient: any;

  createAuthClient(args) {
    this.authClient = new OktaAuth(args);
  }

  sessionExists() {
    return this.authClient.session.exists();
  }

  getWithoutPrompt(params) {
    return this.authClient.token.getWithoutPrompt(params);
  }
}
