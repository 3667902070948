<div>
  <div class="oiq-watch-list-summary oiq-grid-cell">
    <div class="oiq-watch-list-cell" role="gridcell">
      <div
        class="oiq-watch-list-classifier"
        [ngClass]="{disabled: watchList.disabled}"
        *ngIf="isDisplayClassifications">
        <strong>{{ watchList.category | translatef }}</strong>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="oiq-watch-list-bd oiq-grid-cell">
    <article class="oiq-watch-list-grid" [ngClass]="{disabled: watchList.disabled}">
      <div class="oiq-watch-list-row" *ngIf="source" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Sources:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <div class="oiq-watch-list-sources">
            <span class="oiq-watch-list-source oiq-watch-list-source-disabled" *ngIf="!source.sourceUrl"
              >{{source.source | translatef}}</span
            >

            <a
              class="oiq-watch-list-source"
              *ngIf="source.sourceUrl"
              [href]="source.sourceUrl"
              target="_blank"
              rel="noopener noreferrer"
              >{{source.source | translatef}}</a
            >

            <div *ngIf="isOpen">
              <ul class="oiq-watch-list-more-sources">
                <li *ngFor="let source of moreSources">
                  <span
                    class="oiq-watch-list-source oiq-watch-list-source-disabled"
                    *ngIf="!source.sourceUrl"
                    [textContent]="source.source | translatef"></span>

                  <a
                    class="oiq-watch-list-source"
                    *ngIf="source.sourceUrl"
                    [href]="source.sourceUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{source.source | translatef}}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.program" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Program:</div>
        <div class="oiq-watch-list-cell oiq-watch-list-program" [textContent]="watchList.program" role="gridcell"></div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="(watchList.companyName || watchList.name)" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Name:</div>
        <div
          class="oiq-watch-list-cell oiq-watch-list-name"
          [textContent]="(watchList.companyName || watchList.name)"
          role="gridcell"></div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.externalId" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">External ID:</div>
        <div
          class="oiq-watch-list-cell oiq-watch-list-external-id"
          [textContent]="watchList.externalId"
          role="gridcell"></div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="hasEventsWithDescription" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Events:</div>
        <div class="oiq-watch-list-cell oiq-watch-list-name" role="gridcell">
          <ddiq-limited-list [list]="watchList.events" [limit]="3">
            <ng-template #parentTemplate let-limitedList>
              <ul class="oiq-watch-list-events">
                <li class="oiq-watch-list-event" *ngFor="let event of limitedList">
                  <span
                    class="oiq-watch-list-event-occurred"
                    *ngIf="event.occurred"
                    [textContent]="(event.occurred | dateFormat) + ': '"></span>
                  <span
                    class="oiq-watch-list-event-description"
                    *ngIf="event.description"
                    [textContent]="event.description"></span>
                </li>
              </ul>
            </ng-template>
          </ddiq-limited-list>
        </div>
      </div>

      <div class="oiq-watch-list-row oiq-watch-list-images-row" *ngIf="hasImagesToRender" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Images:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ul class="oiq-watch-list-cell-images" *ngIf="imagesInDb.length">
            <li class="oiq-watch-list-image" *ngFor="let image of imagesInDb">
              <img class="oiq-watch-list-thumbnail" [src]="image.oiqImageUrl" />
            </li>
          </ul>
          <ddiq-limited-list *ngIf="imageAnchors.length" [list]="imageAnchors" [limit]="3">
            <ng-template #parentTemplate let-limitedList>
              <ul class="oiq-watch-list-cell-images">
                <li *ngFor="let image of limitedList" class="oiq-watch-list-image-anchor-item">
                  <span>
                    <i
                      *ngIf="!image.isInDb"
                      class="fa fa-exclamation-triangle oiq-watch-list-source-warning"
                      title="The URL may not be functional"></i>
                    <ddiq-link [data]="image.sourceUrl"></ddiq-link>
                  </span>
                </li>
              </ul>
            </ng-template>
          </ddiq-limited-list>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.birthdates?.length" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Birthdates:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ul class="oiq-watch-list-birthdates">
            <li class="oiq-watch-list-birthdate" *ngFor="let birthdate of watchList.birthdates">
              {{birthdate | dateFormat}}
            </li>
          </ul>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.sex" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Sex:</div>
        <div class="oiq-watch-list-cell oiq-watch-list-sex" [textContent]="watchList.sex" role="gridcell"></div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.addresses?.length" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Addresses:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ul class="oiq-watch-list-addresses">
            <li class="oiq-watch-list-address" *ngFor="let address of watchList.addresses">{{address | address}}</li>
          </ul>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.birthPlaces?.length" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Birth Places:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ul class="oiq-watch-list-birth-places">
            <li class="oiq-watch-list-birth-place" *ngFor="let birthPlace of watchList.birthPlaces | arrayDedupe">
              {{birthPlace}}
            </li>
          </ul>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.citizenships?.length" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Citizenships:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ul class="oiq-watch-list-citizenships">
            <li class="oiq-watch-list-citizenship" *ngFor="let citizenship of watchList.citizenships | arrayDedupe">
              {{citizenship}}
            </li>
          </ul>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.knownAliases?.length" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Known Aliases:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ddiq-limited-list [list]="watchList.knownAliases" [limit]="3">
            <ng-template #parentTemplate let-limitedList>
              <ul class="oiq-watch-list-known-aliases">
                <li class="oiq-watch-list-known-alias" *ngFor="let alias of limitedList; trackBy: trackByIndex">
                  {{alias}}
                </li>
              </ul>
            </ng-template>
          </ddiq-limited-list>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.positions?.length" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Positions:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <ddiq-limited-list [list]="watchList.positions" limit="3">
            <ng-template #parentTemplate let-limitedList>
              <ul class="oiq-watch-list-positions">
                <li class="oiq-watch-list-position" *ngFor="let position of limitedList">
                  <span
                    class="oiq-watch-list-position-date"
                    *ngIf="position.startDate"
                    [textContent]="position.startDate | date:'MMM yyyy'">
                  </span>
                  <span
                    class="oiq-watch-list-position-date"
                    *ngIf="position.endDate"
                    [textContent]="' - ' + (position.endDate | date:'MMM yyyy') + ':'">
                  </span>
                  <span *ngIf="position.title" [textContent]="position.title"></span>
                  <span
                    *ngIf="position.organization && position.title"
                    [textContent]="',' + position.organization"></span>
                  <span *ngIf="position.organization && !position.title" [textContent]="position.organization"></span>
                </li>
              </ul>
            </ng-template>
          </ddiq-limited-list>
        </div>
      </div>

      <div class="oiq-watch-list-row" *ngIf="watchList.comments" role="row">
        <div class="oiq-watch-list-hd" role="columnheader">Comments:</div>
        <div class="oiq-watch-list-cell" role="gridcell">
          <p class="oiq-watch-list-comments" [textContent]="watchList.comments"></p>
        </div>
      </div>
    </article>
    <div *ngIf="isOpen">
      <article class="oiq-watch-list-grid" [ngClass]="{disabled: watchList.disabled}">
        <div class="oiq-watch-list-row" *ngIf="watchList.relatedEntities?.length" role="row">
          <div class="oiq-watch-list-hd" role="columnheader">Relations:</div>
          <div class="oiq-watch-list-cell" role="gridcell">
            <ddiq-limited-list [list]="watchList.relatedEntities" [limit]="3">
              <ng-template #parentTemplate let-limitedList>
                <ul class="oiq-watch-list-related-entities">
                  <li class="oiq-watch-list-related-entity" *ngFor="let relatedEntity of limitedList">
                    <div>
                      <span
                        class="oiq-watch-list-related-entity-name"
                        *ngIf="!relatedEntity.seedSources[0]?.sourceUrl"
                        [textContent]="relatedEntity.name"></span>
                      <a
                        class="oiq-watch-list-related-entity-name"
                        *ngIf="relatedEntity.seedSources[0]?.sourceUrl"
                        [href]="relatedEntity.seedSources[0].sourceUrl"
                        [textContent]="relatedEntity.name"
                        target="_blank"
                        rel="noopener noreferrer"></a>
                      <span
                        class="oiq-watch-list-related-entity-name"
                        *ngIf="relatedEntity.relationship"
                        [textContent]="'(' + relatedEntity.relationship + ')'"></span>
                    </div>
                    <div
                      class="oiq-watch-list-related-entity-additional-data"
                      *ngFor="let additionalDatum of relatedEntity.additionalData">
                      <span
                        *ngIf="additionalDatum.label"
                        [textContent]="(additionalDatum.label | translatef) + ' : '"></span>
                      <span *ngIf="additionalDatum.value" [textContent]="additionalDatum.value"></span>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </ddiq-limited-list>
          </div>
        </div>

        <div class="oiq-watch-list-row" *ngIf="watchList.expiryDate" role="row">
          <div class="oiq-watch-list-hd" role="columnheader">Expiration Date:</div>
          <div class="oiq-watch-list-cell oiq-watch-list-expiry-date" role="gridcell">
            {{watchList.expiryDate | dateFormat}}
          </div>
        </div>

        <div class="oiq-watch-list-row" *ngIf="watchList.associatedCountryCodes?.length" role="row">
          <div class="oiq-watch-list-hd" role="columnheader">Associated Country Codes:</div>
          <div class="oiq-watch-list-cell" role="gridcell">
            <ddiq-limited-list [list]="watchList.associatedCountryCodes" [limit]="3">
              <ng-template #parentTemplate let-limitedList>
                <ul class="oiq-watch-list-associated-country-codes">
                  <li
                    class="oiq-watch-list-associated-country-code"
                    *ngFor="let countrycode of limitedList; trackBy: trackByIndex">
                    {{countrycode}}
                  </li>
                </ul>
              </ng-template>
            </ddiq-limited-list>
          </div>
        </div>

        <div class="oiq-watch-list-row" *ngIf="watchList.additionalCategories?.length" role="row">
          <div class="oiq-watch-list-hd" role="columnheader">Additional Categories:</div>
          <div class="oiq-watch-list-cell" role="gridcell">
            <ddiq-limited-list [list]="watchList.additionalCategories" [limit]="3">
              <ng-template #parentTemplate let-limitedList>
                <ul class="oiq-watch-list-additional-categories">
                  <li
                    class="oiq-watch-list-additional-category"
                    *ngFor="let additionalCategory of limitedList; trackBy: trackByIndex">
                    {{additionalCategory | translatef}}
                  </li>
                </ul>
              </ng-template>
            </ddiq-limited-list>
          </div>
        </div>

        <ng-container *ngIf="watchList.additionalData?.length">
          <div
            class="oiq-watch-list-row oiq-watch-list-additional-data"
            *ngFor="let data of watchList.additionalData"
            role="row">
            <ng-container *ngIf="(data.label | translatef)?.length">
              <div class="oiq-watch-list-hd oiq-watch-list-additional-data-label" role="columnheader">
                {{data.label | translatef}}:
              </div>
              <div class="oiq-watch-list-cell" role="gridcell">
                <ul class="oiq-watch-list-additional-data-values">
                  <li *ngFor="let value of data.value">
                    <span class="oiq-watch-list-additional-data-value">
                      <ddiq-link [data]="value | translatef: true | newlineToBr"></ddiq-link>
                    </span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div
          class="oiq-watch-list-row oiq-watch-list-governmentIds"
          *ngFor="let governmentId of watchList.governmentIds"
          role="row">
          <div
            class="oiq-watch-list-hd oiq-watch-list-governmentIds-label"
            role="columnheader"
            [textContent]="(governmentId.identificationType | translatef) + ':'"></div>
          <div
            class="oiq-watch-list-cell oiq-watch-list-governmentIds-values"
            [textContent]="governmentId.number"></div>
        </div>

        <div class="oiq-watch-list-row" *ngIf="watchList.phoneNumbers?.length" role="row">
          <div class="oiq-watch-list-hd" role="columnheader">Phone Numbers:</div>
          <div class="oiq-watch-list-cell" role="gridcell">
            <ddiq-limited-list [list]="watchList.phoneNumbers" [limit]="3">
              <ng-template #parentTemplate let-limitedList>
                <ul class="oiq-watch-list-phone-numbers">
                  <li class="oiq-watch-list-phone-number" *ngFor="let phone of limitedList; trackBy: trackByIndex">
                    {{phone.number}}
                  </li>
                </ul>
              </ng-template>
            </ddiq-limited-list>
          </div>
        </div>
      </article>
    </div>
  </div>
  <div class="oiq-watch-list-toggle oiq-grid-cell" [ngClass]="{disabled: watchList.disabled}" *ngIf="hasMoreData()">
    <a class="oiq-watch-list-show-all-toggle" (click)="toggle()">
      <i class="fa" [ngClass]="{'fa-chevron-up': isOpen, 'fa-chevron-down': !isOpen}"></i>
      Show all data
    </a>
  </div>
</div>
