import { Inject, Injectable } from '@angular/core';
import AssociationBuckets from '../association/association-buckets.service';
import ChainableFilterDateUtils from './chainable-filter-date-utils.service';

@Injectable({
  providedIn: 'root',
})
export default class ChainableStructuredDataFilter {
  buckets;

  constructor(
    private chainableFilterDateUtils: ChainableFilterDateUtils,
    private associationBuckets: AssociationBuckets
  ) {
    this.buckets = this.associationBuckets.getBuckets();
  }

  clear() {
    return function (structuredData) {
      structuredData.filtered = false;
      return false;
    };
  }

  // are all incident dates after date
  isAfterDate(date) {
    if (!date) return;

    date = this.chainableFilterDateUtils.normalizeDate(date);

    return (structuredData) => {
      let dataDate = this.chainableFilterDateUtils.normalizeDate(structuredData.filterFields.date);

      if (dataDate > date) return true;
      if (dataDate) return false;
      return false;
    };
  }

  // are all incident dates before date
  isBeforeDate(date) {
    if (!date) return;

    date = this.chainableFilterDateUtils.normalizeDate(date);

    return (structuredData) => {
      let dataDate = this.chainableFilterDateUtils.normalizeDate(structuredData.filterFields.date);

      if (dataDate < date) return true;
      if (dataDate) return false;
      return false;
    };
  }

  isBelowMinAssociationBucket(minScore) {
    if (minScore === undefined) return;
    return (structuredData) => {
      if (typeof this.buckets[structuredData.associationBucket] === 'undefined') return true;
      return this.buckets[structuredData.associationBucket] < minScore;
    };
  }

  isExcludedAdjudication(excludeAdjudicated) {
    if (!excludeAdjudicated) {
      return;
    }

    return (data) => {
      return excludeAdjudicated && data.adjudication && data.adjudication.adjudicated;
    };
  }

  isExcludeNonEscalated(escalatedOnly) {
    if (!escalatedOnly) {
      return;
    }

    return (data) => {
      return escalatedOnly && (!data.adjudication || !data.adjudication.escalated);
    };
  }

  isExcludeNonConfirmed(confirmedOnly) {
    if (!confirmedOnly) {
      return;
    }

    return (data) => {
      return confirmedOnly && (!data.adjudication || !data.adjudication.confirmed);
    };
  }

  isExcludeNonAdjudicated(nonAdjudicatedOnly) {
    if (!nonAdjudicatedOnly) {
      return;
    }

    return (data) => {
      return !(data.adjudication && data.adjudication.adjudicated);
    };
  }

  isHidingConfirmed(hideConfirmed) {
    if (!hideConfirmed) {
      return;
    }

    return (data) => {
      return !!(data.adjudication && data.adjudication.confirmed);
    };
  }

  isHidingEscalated(hideEscalated) {
    if (!hideEscalated) {
      return;
    }

    return (data) => {
      return !!(data.adjudication && data.adjudication.escalated);
    };
  }
}
