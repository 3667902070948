<div *ngIf="(idConfigs || []).length">
  <ddiq-text-submit
    *ngFor="let id of idConfigs; let $index = index"
    [name]="id.name"
    size="quarter"
    [label]="id.name"
    [(model)]="idModel[$index + 1].number"
    (modelChange)="onChange()"
    [oiqFieldRequired]="!!id.required"
    (buttonClicked)="sendCageData($event)"
    [buttonEnabled]="id.buttonEnabled"></ddiq-text-submit>
</div>
