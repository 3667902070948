import { NgModule } from '@angular/core';
import ProtectedArticleComponent from './ddiq-protected-article.component';
import ProtectedArticlesComponent from './ddiq-protected-articles.component';
import SelectedArticlesComponent from './ddiq-selected-articles.component';
import LexisNexisArticleIdPipe from './lexis-nexis-article-id.pipe';
import LexisNexisArticles from './lexis-nexis-articles.service';
import ProtectedArticleHrefPipe from './protected-article-href.pipe';
import ProtectedArticleSelectorComponent from './protected-article-selector.component';
import ProtectedArticlesService from './protected-articles.service';
import SelectedArticleDownloadLinkComponent from './selected-article-download-link.component';
import SelectedArticleComponent from './selected-article.component';
import SelectedArticlesDownloaderService from './selected-articles-downloader.service';
import SelectedArticlesFeatureService from './selected-articles-feature.service';
import SelectedArticlesService from './selected-articles.service';
import SelectedArticleTagReplacementPipe from './selected-article-tag-replacement.pipe';
import ProtectedArticlePdfButtonComponent from './oiq-protected-article-pdf-button.component';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { CommonModule } from '@angular/common';
import { PdfModule } from '../pdf/oiq-pdf.module';
import SelectedArticlesResolver from './selected-articles-resolver.service';

@NgModule({
  imports: [DdiqUIModule, CommonModule, PdfModule],
  declarations: [
    ProtectedArticleHrefPipe,
    LexisNexisArticleIdPipe,
    SelectedArticleTagReplacementPipe,
    ProtectedArticleSelectorComponent,
    ProtectedArticlesComponent,
    ProtectedArticleComponent,
    SelectedArticlesComponent,
    SelectedArticleComponent,
    ProtectedArticlePdfButtonComponent,
    SelectedArticleDownloadLinkComponent,
  ],
  providers: [
    SelectedArticlesResolver,
    ProtectedArticlesService,
    SelectedArticlesService,
    LexisNexisArticles,
    SelectedArticlesFeatureService,
    SelectedArticlesDownloaderService,
  ],
  exports: [
    ProtectedArticleHrefPipe,
    LexisNexisArticleIdPipe,
    SelectedArticleTagReplacementPipe,
    ProtectedArticleSelectorComponent,
    ProtectedArticlesComponent,
    ProtectedArticleComponent,
    SelectedArticlesComponent,
    SelectedArticleComponent,
    ProtectedArticlePdfButtonComponent,
    SelectedArticleDownloadLinkComponent,
  ],
})
export class ProtectedArticlesModule {}
