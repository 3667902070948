import { Component, Input } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-monitor-hits',
  templateUrl: 'ddiq-monitor-hits.component.tpl.html',
})
export default class MonitorHits {
  window: Window;
  isGovIdSearchEnabled: boolean;
  showTranslatedSnippets: boolean;
  showArticleCacheLinks: boolean;

  @Input() report: {
    hits: Array<{ [key: string]: any }>;
    monitorHitTotal: number;
    profileLink: string;
    entityType: string;
    oiqEntityId: string;
    seedData: { [key: string]: any };
    submissionId: string;
    oiqLastUpdatedDate: string;
    oiqOwnerFullName: string;
    monitor: { [key: string]: any };
    isAdjudicatableByUser: boolean;
  } = {
    hits: [],
    monitorHitTotal: null,
    profileLink: '',
    entityType: '',
    oiqEntityId: '',
    seedData: {},
    submissionId: '',
    oiqLastUpdatedDate: '',
    oiqOwnerFullName: '',
    monitor: {},
    isAdjudicatableByUser: false,
  };

  constructor(private windowRef: WindowRefService, private oiqProperties: OiqProperties) {
    this.window = windowRef.nativeWindow;
    this.isGovIdSearchEnabled = oiqProperties.isGovIdSearchEnabled;
    this.showTranslatedSnippets = oiqProperties.showTranslatedSnippets;
    this.showArticleCacheLinks = this.oiqProperties.articleCacheEnabled;
  }

  showDeltaReport(profileLink: string): void {
    this.window.location.assign(profileLink);
  }
}
