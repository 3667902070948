import ArrayDedupePipe from './array-dedupe.pipe';
import DdiqCollapsibleComponent from './ddiq-collapsible.component';
import OiqCollapsibleCtrlDirective from './oiq-collapsible-ctrl.directive';
import OiqCollapsibleToggle from './oiq-collapsible-toggle.directive';
import OiqToggleIconComponent from './oiq-toggle-icon.component';
import Scrollbar from './scrollbar.service';
import StripNgProps from './strip-ng-props.service';
import LoadingIndicator from './loading-indicator.component';
import { NgModule } from '@angular/core';
import DdiqLimitedListComponent from './ddiq-limited-list.component';
import { CommonModule } from '@angular/common';
import DropDownComponent from './drop-down/upgrade/oiq-drop-down.component';
import DropDownItemsComponent from './drop-down/upgrade/oiq-drop-down-items.component';
import DropDownToggleComponent from './drop-down/upgrade/oiq-drop-down-toggle.component';
import ValidateHtmlIdsPipe from './validate-html-ids.pipe';
import ExgScrollAdjustDirective from './exg-scroll-adjust.directive';
import ScrollbarDirective from './ddiq-scrollbar.directive';
import ScrollIntoViewDirective from './ddiq-scroll-into-view.directive';
import { ExternalUrlMsgModule } from '../external-url-msg/ddiq-external-url-msg.module';
import { ExternalUrlMsgDirective } from '../external-url-msg/ddiq-external-url-msg.directive';

@NgModule({
  imports: [CommonModule, ExternalUrlMsgModule],
  providers: [ArrayDedupePipe, Scrollbar, StripNgProps],
  declarations: [
    DropDownComponent,
    DropDownItemsComponent,
    DropDownToggleComponent,
    OiqCollapsibleCtrlDirective,
    DdiqCollapsibleComponent,
    OiqCollapsibleToggle,
    OiqToggleIconComponent,
    ArrayDedupePipe,
    ValidateHtmlIdsPipe,
    DdiqLimitedListComponent,
    DropDownComponent,
    DropDownItemsComponent,
    DropDownToggleComponent,
    LoadingIndicator,
    ExgScrollAdjustDirective,
    ScrollbarDirective,
    ScrollIntoViewDirective,
  ],
  exports: [
    DropDownComponent,
    DropDownItemsComponent,
    DropDownToggleComponent,
    OiqCollapsibleCtrlDirective,
    DdiqCollapsibleComponent,
    OiqCollapsibleToggle,
    OiqToggleIconComponent,
    ValidateHtmlIdsPipe,
    ArrayDedupePipe,
    ValidateHtmlIdsPipe,
    DdiqLimitedListComponent,
    DropDownComponent,
    DropDownItemsComponent,
    DropDownToggleComponent,
    LoadingIndicator,
    ExgScrollAdjustDirective,
    ExternalUrlMsgDirective,
  ],
})
export class DdiqUIModule {}
