import { Component, Input } from '@angular/core';

@Component({
  selector: 'oiq-government-awards',
  templateUrl: './oiq-government-awards.component.tpl.html',
})
export default class GovernmentAwardsComponent {
  @Input() awards: any[];
  @Input() summary: any;
}
