import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export default class ScreeningHitCategoriesService {
  static hitCategoryParam = 'category';
  categories = [{ label: 'Sanctions', value: 'SANCTIONS' }];
  selectedCategory;

  constructor(private router: Router, private route: ActivatedRoute) {
    const hitCategory = route.snapshot.queryParamMap.get('hitCategory');
    this.select(hitCategory);

    this.router.events
      .pipe(filter((e: NavigationEnd): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        const hitCategoryParam = route.snapshot.queryParamMap.get(ScreeningHitCategoriesService.hitCategoryParam);
        this.select(hitCategoryParam);
      });
  }
  getAll() {
    return this.categories;
  }
  getSelectedCategory() {
    return this.selectedCategory;
  }
  deselect() {
    this.selectedCategory = undefined;
    this.router.navigate([], {
      queryParams: { [ScreeningHitCategoriesService.hitCategoryParam]: null },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
  select(categoryValue) {
    const selected = this.categories.filter((category) => {
      return category.value === categoryValue;
    })[0];

    if (selected) {
      this.selectedCategory = selected;
      this.router.navigate([], {
        queryParams: { [ScreeningHitCategoriesService.hitCategoryParam]: this.selectedCategory.value },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    } else {
      this.deselect();
    }
  }
}
