import ProfileStatusFeature from './profile-status-feature.service';
import ProfileStatusComponent from './profile-status.component';
import ProfileStatusErrorPipe from './profile-status-error.pipe';
import ProfileStatusAdjudicationGuard from './profile-status-adjudication-guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import ProfileStatusService from './profile-status.service';

@NgModule({
  imports: [CommonModule, OiqAdjudicationModule, DdiqUIModule],

  providers: [ProfileStatusAdjudicationGuard, ProfileStatusFeature, ProfileStatusService],
  declarations: [ProfileStatusComponent, ProfileStatusErrorPipe],
  exports: [ProfileStatusComponent],
})
export class ProfileStatusModule {}
