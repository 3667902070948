import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ddiq-professional-licenses-group',
  templateUrl: './ddiq-professional-licenses-group.component.tpl.html',
})
export default class DdiqProfessionalLicensesGroupComponent {
  @Input() groups: any;
  @Output() sortDiscoveries = new EventEmitter<any>();
  @Input() idPrefix: string;

  constructor() {}

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  id(index: number, license: any) {
    return license.id;
  }

  classification(index: number, group: any) {
    return group.title;
  }
}
