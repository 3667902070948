import { HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { forkJoin } from 'rxjs';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import ConfigService from '../../../../../shared/services/http/config.service';
import UserService from './user.service';

export default function configUser() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (
      httpBackend: HttpBackend,
      configService: ConfigService,
      user: UserService,
      errorHandler: ErrorHandler
    ) => {
      let currentUser;
      let visibleUsers;
      let headers = {};
      /* @ts-ignore */
      const token = window.Exiger.Utils.getParameter('jwt', location);
      if (token) {
        headers = {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        };
      }
      return () => {
        const httpClient = new HttpClient(httpBackend);
        let currentUsersCall = httpClient.get(
          `${configService.settings.project.apiUrl}rest/admin/currentUser`,
          headers
        );
        let visibleUsersCall = httpClient.get(
          `${configService.settings.project.apiUrl}rest/admin/user/visibleUsers`,
          headers
        );
        let samlLoginUrl;

        function initializeUsers() {
          if (currentUser) {
            user.initialize(currentUser);
            user.initializeVisibleUsers(visibleUsers);
          }
        }

        return forkJoin([currentUsersCall, visibleUsersCall])
          .toPromise()
          .then(([userData, visibleUserData]) => {
            currentUser = userData;
            visibleUsers = visibleUserData['users'];
            initializeUsers();
          })
          .catch((response) => {
            if (response && response.status === 401) {
              return httpClient
                .get(`${configService.settings.project.apiUrl}rest/saml`, { responseType: 'text' })
                .toPromise()
                .then((samlResponse) => {
                  samlLoginUrl = samlResponse;
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  const currentUrl = window.location.href;
                  if (samlLoginUrl && currentUrl.indexOf('error') === -1) {
                    const PREFIX = '/dashboard/#';
                    let redirectPath = samlLoginUrl;
                    if (currentUrl.indexOf(PREFIX) !== -1) {
                      const relayState = currentUrl.substring(currentUrl.lastIndexOf(PREFIX) + PREFIX.length);
                      redirectPath = redirectPath + '?RelayState=' + encodeURIComponent(relayState);
                    }
                    return new Promise<void>(() => {
                      window.location.href = redirectPath;
                    });
                  } else {
                    initializeUsers();
                  }
                });
            } else {
              initializeUsers();
            }
          });
      };
    },
    deps: [HttpBackend, ConfigService, UserService, ErrorHandler],
    multi: true,
  };
}
