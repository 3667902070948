import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import PasswordResetComponent from './oiq-password-reset.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';

@NgModule({
  imports: [CommonModule, ThemedEntityModule, FormsModule, DdiqUpgradeModule],
  declarations: [PasswordResetComponent],
  exports: [PasswordResetComponent],
})
export class OiqPasswordModule {}
