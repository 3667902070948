import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import ExternalUrlMsgService from './ddiq-external-url-msg.service';

@Component({
  selector: 'ddiq-external-url-msg',
  template: `
    <ddiq-modal
      class="ddiq-modal ddiq-external-url-msg"
      title=""
      [trigger]="modalToggle"
      [closeable]="true"
      (closed)="close()">
      <p class="warning-msg-text">{{ msg }}</p>
      <button class="proceed-btn btn btn-primary btn-sm" (click)="proceed()">Continue</button>
    </ddiq-modal>
  `,
})
export class ExternalUrlMsgComponent implements OnInit {
  msg: string;
  modalToggle: { open: boolean } = { open: true };
  @Output() onClicked = new EventEmitter<string>();

  constructor(private externalUrlMsgService: ExternalUrlMsgService) {}

  ngOnInit() {
    this.msg = this.externalUrlMsgService.getWarningMsg();
  }

  close(): void {
    this.onClicked.emit('closed');
    this.modalToggle.open = false;
  }

  proceed(): void {
    this.onClicked.emit('proceed');
    this.modalToggle.open = false;
  }
}
