<div class="risk-summary alert alert-warning" [hidden]="!isVisible()">
  <strong>{{'TOP_CONTRIBUTING_RISKS' | translatef}}</strong>

  <div class="risk-summmaries-recalc" *ngIf="isRecalculating()">
    <img class="recalc" src="./img/loading.gif" />
  </div>

  <div class="risk-summmaries">
    <ul class="risk-summary-items">
      <li
        *ngFor="let assessments of getAssessmentGroups() | slice:0:10"
        [ngSwitch]="riskSummaryDisplayMode(assessments)">
        <div
          *ngSwitchCase="'display-single-risk'"
          class="risk-summary-item"
          [ngClass]="{'clickable': assessments[0].link}"
          scrollto
          href="{{assessments[0].link}}"
          offset-adjust="130"
          (click)="riskSummaryClick(assessments[0])"
          title="{{assessments[0].link && 'Click to navigate'}}">
          <ddiq-risk-review [risk]="getRiskValues(assessments[0].risk)"></ddiq-risk-review>
          <span
            >{{ assessments[0].translation }}
            <span *ngIf="assessments[0].promotedFromKey"> {{assessments[0].promotedFromKey | translatef }}</span>
          </span>
        </div>

        <div *ngSwitchCase="'display-grouped-risk'" class="risk-summary-group">
          <oiq-drop-down>
            <oiq-drop-down-toggle toggleTitle="Click to view alerts in this group">
              <ddiq-risk-review [risk]="getRiskValues(assessments[0].risk)"></ddiq-risk-review>
              {{assessments[0] | translateGroupedRiskTitle}}
            </oiq-drop-down-toggle>

            <oiq-drop-down-items
              *ngFor="let assessment of assessments"
              scrollto
              href="{{assessment.link}}"
              offset-adjust="130"
              (click)="riskSummaryClick(assessment)"
              title="{{assessment.link && 'Click to navigate'}}">
              <ddiq-risk-review [risk]="getRiskValues(assessment.risk)"></ddiq-risk-review>
              <span
                >{{ assessment.translation }}
                <span *ngIf="assessment.riskTypeKey"> {{riskType(assessment) | translatef}}</span>
                <span *ngIf="assessment.promotedFromKey"> {{assessment.promotedFromKey | translatef}}</span>
              </span>
            </oiq-drop-down-items>
          </oiq-drop-down>
        </div>

        <div *ngSwitchCase="'PDF'">
          <div class="risk-summary-item" *ngIf="assessments.length === 1">
            <ddiq-risk-review [risk]="getRiskValues(assessments[0].risk)"></ddiq-risk-review>
            <span
              >{{ assessments[0].translation }}
              <span *ngIf="assessments[0].promotedFromKey"> {{assessments[0].promotedFromKey | translatef}}</span></span
            >
          </div>

          <div class="risk-summary-item" *ngIf="assessments.length > 1">
            <ddiq-risk-review [risk]="getRiskValues(assessments[0].risk)"></ddiq-risk-review>
            <span>{{assessments[0] | translateGroupedRiskTitle }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="clr"></div>
</div>
