<img *ngIf="isBusy" [src]="'./img/loader.gif'" class="result-loading-img" alt="Loading awards" />

<section *ngIf="!isBusy" class="government-procurement">
  <header>
    <h1>Government Procurement</h1>
  </header>
  <oiq-government-procurement-seed-data [seedData]="seedData"></oiq-government-procurement-seed-data>
  <nav [ngClass]="{ 'nav-disabled': isNavDisabled }">
    <button
      title="Go to recent awards"
      (click)="navigateTo(recentAwardType)"
      class="recent-nav btn btn-xs"
      [ngClass]="{ 'btn-primary': selectedView === recentAwardType, 'btn-default': selectedView !== recentAwardType }">
      Recent
    </button>
    <button
      title="Go to expiring awards"
      (click)="navigateTo(expiringAwardType)"
      class="expiring-nav btn btn-xs"
      [ngClass]="{ 'btn-primary': selectedView === expiringAwardType, 'btn-default': selectedView !== expiringAwardType }">
      Expiring
    </button>
    <button
      title="Go to expired awards"
      (click)="navigateTo(expiredAwardType)"
      class="expired-nav btn btn-xs"
      [ngClass]="{ 'btn-primary': selectedView === expiredAwardType, 'btn-default': selectedView !== expiredAwardType }">
      Expired
    </button>
  </nav>
  <section class="awards">
    <article class="recent-awards" [ngClass]="{ 'awards-paging': busyPaging[recentAwardType] }">
      <h2 class="{{recentAwardType}}">Recent Awards (past year)</h2>
      <oiq-government-awards
        *ngIf="recent.awards.length"
        [awards]="recent.awards"
        [summary]="recent.summary"></oiq-government-awards>
      <exg-pagination
        [numPages]="recent.pagination.numberOfPages"
        [tableTotal]="recent.pagination.totalCount"
        [totalLabel]="'Recent Awards'"
        [currentPage]="recent.pagination.currentPage"
        (onSelectPage)="pageAwards($event.page, recentAwardType)"
        [paginationBarLength]="recent.pagination.paginationBarLength"></exg-pagination>
      <p class="no-awards-msg" *ngIf="!recent.awards.length">No Results Found</p>
    </article>
    <hr />
    <article class="expiring-awards" [ngClass]="{ 'awards-paging': busyPaging[expiringAwardType] }">
      <h2 class="{{expiringAwardType}}">Expiring Awards (within a year)</h2>
      <oiq-government-awards
        *ngIf="expiring.awards.length"
        [awards]="expiring.awards"
        [summary]="expiring.summary"></oiq-government-awards>
      <exg-pagination
        [numPages]="expiring.pagination.numberOfPages"
        [tableTotal]="expiring.pagination.totalCount"
        [totalLabel]="'Expiring Awards'"
        [currentPage]="expiring.pagination.currentPage"
        (onSelectPage)="pageAwards($event.page, expiringAwardType)"
        [paginationBarLength]="expiring.pagination.paginationBarLength"></exg-pagination>
      <p class="no-awards-msg" *ngIf="!expiring.awards.length">No Results Found</p>
    </article>
    <hr />
    <article class="expired-awards" [ngClass]="{ 'awards-paging': busyPaging[expiredAwardType] }">
      <h2 class="{{expiredAwardType}}">Expired Awards (over past 5 years)</h2>
      <oiq-government-awards
        *ngIf="expired.awards.length"
        [awards]="expired.awards"
        [summary]="expired.summary"></oiq-government-awards>
      <exg-pagination
        [numPages]="expired.pagination.numberOfPages"
        [tableTotal]="expired.pagination.totalCount"
        [totalLabel]="'Expired Awards'"
        [currentPage]="expired.pagination.currentPage"
        (onSelectPage)="pageAwards($event.page, expiredAwardType)"
        [paginationBarLength]="expired.pagination.paginationBarLength"></exg-pagination>
      <p class="no-awards-msg" *ngIf="!expired.awards.length">No Results Found</p>
    </article>
  </section>
</section>
