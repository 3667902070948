import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import Paths from '../common/paths.constants';

@Injectable({
  providedIn: 'root',
})
export class EmbedService {
  allowedRoutes = [
    Paths.NOT_FOUND,
    Paths.ERROR,
    Paths.SSO_ERROR,
    Paths.PROFILE,
    Paths.CORPORATE_GRAPH,
    Paths.NETWORK_GRAPH,
    Paths.CACHED_ARTICLE,
    Paths.PROTECTED_ARTICLES,
    Paths.SELECTED_ARTICLES,
    Paths.AUDIT,
    Paths.INTEGRITY,
    Paths.MONITOR_REPORT,
    Paths.MONITOR,
    Paths.COVER,
    Paths.COVER_ARTICLES,
    Paths.GOVERNMENT_PROCUREMENT,
  ];

  constructor(private oiqProperties: OiqProperties) {}

  isEmbedded(): boolean {
    return this.oiqProperties.embeddedModeEnabled;
  }

  canActivate(url) {
    if (!this.isEmbedded()) {
      return true;
    }
    return this.allowedRoutes.includes(url);
  }
}
