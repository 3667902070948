import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-product-recalls-group',
  templateUrl: './ddiq-product-recalls-group.component.tpl.html',
})
export default class DdiqProductRecallsGroupComponent {
  @Input() groups: any;
  @Input() idPrefix: string;
  @Input() expandAll: boolean;

  entryTrackBy(index, entry) {
    return entry.sourceUrl || entry.id;
  }
}
