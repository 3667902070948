<div id="basicInfo">
  <div *ngIf="!isDeltaReport">
    <ddiq-crawl-error></ddiq-crawl-error>

    <div class="alert alert-warning" *ngIf="showInsufficientDataWarning()">
      <div class="alert-icon">
        <span class="fa fa-exclamation-circle fa-2x"></span>
      </div>

      <div>
        <p>
          Please be advised there may be relevant results scored as 'Uncertain' due to the lack of input data provided.
          To view these results, set the confidence filter on the left hand to 'Uncertain'.
        </p>
        <p *ngIf="canSubmitProfile">
          Alternatively, click the 'Resubmit' button on the top right hand side to resubmit the profile with additional
          input data.
        </p>
      </div>
    </div>

    <ddiq-filtered-messages></ddiq-filtered-messages>
    <ddiq-risk-summary class="non-breaking"></ddiq-risk-summary>
  </div>

  <section *ngIf="!isDiff">
    <seed-data [section]="section" [hideComments]="isDeltaReport"></seed-data>
  </section>

  <ddiq-accordion-top
    *ngIf="!isDeltaReport && !section.metadata.filtered"
    label="General Information"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [disable]="isSectionDisabled"
    [open]="isSectionOpen"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()"
    [alerts]="alertCount">
    <ddiq-alert-list [source]="basicInfoAlerts"></ddiq-alert-list>

    <div *ngIf="containsVerificationInfo">
      <div *ngIf="section.data.emailAddresses?.length || section.data.phoneNumbers?.length">
        <span *ngIf="section.data.phoneNumbers?.length">
          <strong>Phone Numbers</strong>
          <p *ngFor="let phoneNumber of section.data.phoneNumbers">
            {{ phoneNumber.number }} <span *ngIf="phoneNumber.label">({{ phoneNumber.label | translatef }})</span>
          </p>
          <br />
        </span>

        <span *ngIf="section.data.emailAddresses?.length">
          <strong>Email Addresses</strong>
          <p *ngFor="let email of section.data.emailAddresses">
            {{ email.email }} <span *ngIf="email.label">({{ email.label | translatef }})</span>
          </p>
          <br />
        </span>
      </div>

      <div *ngIf="section.data.alternativeNames?.length || section.data.relatedPeople?.length">
        <span *ngIf="section.data.alternativeNames?.length">
          <strong>Also Known As</strong>
          <ddiq-field-source-info
            [field]="'name'"
            [fieldValue]="alternativeName"
            *ngFor="let alternativeName of section.data.alternativeNames; let $index = $index">
            <p>{{ alternativeName }}</p>
          </ddiq-field-source-info>
        </span>
      </div>

      <div class="pushdown" *ngIf="section.data.birthdate">
        <span class="bold">{{'Born' | translatef}}</span>: {{ section.data.birthdate | dateFormat }}
        <span *ngIf="section.data.age">(Age: {{section.data.age}})</span>
      </div>

      <div class="pushdown" *ngIf="section.data.currentCompensationAmount">
        <span>{{'Compensation' | translatef}}: </span>
        <span>{{ section.data.currentCompensationAmount | currencyMeasure }}</span>
      </div>

      <div class="pushdown" *ngIf="section.data.seedSource">
        <span>{{'Discovered through' | translatef}}</span> <span>{{ section.data.seedSource }}</span>
      </div>

      <hr class="clr" *ngIf="containsOtherPersonData || containsHistoryInfo" />
    </div>

    <div id="person_corporateRecord" [ngSwitch]="section.data.corporateRecords?.length > 0">
      <div
        id="basicInfo_PERSON_CORPORATE_RECORD"
        *ngIf="(section.data.corporateRecords | filterByImpure: ['filtered']: false)?.length"></div>
      <div *ngSwitchCase="true" class="non-breaking">
        <ddiq-accordion
          label="Corporate Records"
          openonprint
          [hasData]="isExpandAllGroups || section.data.corporateRecordsEventGroups?.isAllEventGroupsOpen">
          <ddiq-corporate-records-group
            [groups]="section.data.corporateRecordsEventGroups"
            (sortDiscoveries)="sortDiscoveries('corporateRecords', $event.sortName, $event.eventGroup)"
            [idPrefix]="section.metadata.sectionId"
            [entityType]="entityType"
            [expandAll]="isExpandAllGroups">
          </ddiq-corporate-records-group>
        </ddiq-accordion>
      </div>
    </div>

    <div *ngIf="containsOtherPersonData">
      <ddiq-field-source-info [field]="'image'" [fieldValue]="section.data.image.sourceUrl" *ngIf="section.data.image">
        <img class="person-profile-image" *ngIf="section.data.image && !isDiff" [src]="getImgUrl()" />
      </ddiq-field-source-info>

      <strong *ngIf="!section.data.image">Links</strong>
      <br />

      <span *ngIf="section.data.socialMedia?.data.linkedInProfile?.linkedInUrl">
        <b> LinkedIn:</b>
        <a [href]="section.data.socialMedia.data.linkedInProfile.linkedInUrl" target="_blank" class="value"
          >{{ section.data.socialMedia.data.linkedInProfile.linkedInUrl | websiteNameNormalizer}}
        </a>
        <br />
      </span>

      <span *ngIf="section.data.socialMedia?.data.facebookAccount?.url">
        <b>Facebook:</b>
        <a [href]="section.data.socialMedia.data.facebookAccount.url " target="_blank" class="value"
          >{{section.data.socialMedia.data.facebookAccount.url | websiteNameNormalizer }}
        </a>
        <br />
      </span>

      <span *ngIf="section.data.socialMedia?.data.twitterAccount?.twitterUrl">
        <b>Twitter:</b>
        <a [href]="section.data.socialMedia.data.twitterAccount.twitterUrl" target="_blank" class="value"
          >{{ section.data.socialMedia.data.twitterAccount.twitterUrl | websiteNameNormalizer }}
        </a>
        <br />
      </span>

      <hr class="clr" *ngIf="containsHistoryInfo" />
    </div>

    <div class="text-right">
      <ddiq-visualize-network></ddiq-visualize-network>
    </div>

    <div *ngIf="!previewingEntity">
      <ddiq-related-people></ddiq-related-people>
      <ddiq-related-locations></ddiq-related-locations>
      <ddiq-related-companies></ddiq-related-companies>
    </div>

    <div *ngIf="containsHistoryInfo" class="non-breaking">
      <ddiq-accordion label="History" open nosep openonprint>
        <div class="history-panel">
          <div class="non-breaking" [ngSwitch]="isDefined">
            <div *ngSwitchCase="true">
              <strong>
                <span>{{'Biography' | translatef}}</span>
              </strong>
              <ddiq-field-source-info [field]="'biography'" [fieldValue]="section.data.biography">
                <p>{{ section.data.biography }}</p>
              </ddiq-field-source-info>
            </div>
          </div>

          <div class="non-breaking" [ngSwitch]="section.data.industries?.length > 0">
            <div *ngSwitchCase="true">
              <strong><span>{{'Industries' | translatef}}</span></strong>
              <ul class="industry-list">
                <li *ngFor="let industry of section.data.industries">{{ industry }}</li>
              </ul>
            </div>
          </div>

          <div class="non-breaking" [ngSwitch]="section.data.education?.length > 0">
            <div *ngSwitchCase="true">
              <strong><span>{{'Education' | translatef}}</span></strong>
              <table>
                <thead>
                  <tr>
                    <th><span>{{'Degree' | translatef}}</span></th>
                    <th><span>{{'Program' | translatef}}</span></th>
                    <th><span>{{'Institution' | translatef}}</span></th>
                    <th><span>{{'Graduation Year' | translatef}}</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let education of section.data.education">
                    <td>{{ education.degree || '--' }}</td>
                    <td>{{ education.program || '--' }}</td>
                    <td>{{ education.institution || '--' }}</td>
                    <td>
                      {{ education.endDate | yearFormat }}
                      <span *ngIf="!education.endDate">--</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="non-breaking" [ngSwitch]="section.data.certifications?.length > 0">
            <div *ngSwitchCase="true">
              <strong><span>{{'Certifications' | translatef}}</span></strong>
              <table>
                <thead>
                  <tr>
                    <th><span>{{'Certification' | translatef}}</span></th>
                    <th><span>{{'Organization' | translatef}}</span></th>
                    <th><span>{{'Date' | translatef}}</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let certification of section.data.certifications">
                    <td>{{ certification.certificationName }}</td>
                    <td>{{ certification.issuingOrganization || '--' }}</td>
                    <td>{{ certification.certificationDate || '--' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            id="basicInfo_PROFESSIONAL_LICENSE"
            class="non-breaking"
            [ngSwitch]="section.data.professionalLicenses?.length > 0">
            <div *ngSwitchCase="true">
              <strong>Professional Licenses</strong>
              <ddiq-professional-licenses-group
                [groups]="section.data.professionalLicensesEventGroups"
                (sortDiscoveries)="sortDiscoveries('professionalLicenses',  $event.sortName, $event.eventGroup)"
                [idPrefix]="section.metadata.sectionId">
              </ddiq-professional-licenses-group>
            </div>
          </div>
        </div>
      </ddiq-accordion>
    </div>

    <p class="no-references-found" *ngIf="!containsVerificationInfo">No additional information discovered.</p>
  </ddiq-accordion-top>
</div>
