import { NgModule } from '@angular/core';
import DdiqFooterComponent from './ddiq-footer.component';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import SupportContactInfoModule from '../support-contact-info/support-contact-info.module';
import { OiqThemeModule } from '../theme/oiq-theme.module';
import NavTrackingService from './nav-tracking.service';
import { ExternalUrlMsgModule } from '../external-url-msg/ddiq-external-url-msg.module';

@NgModule({
  imports: [SupportContactInfoModule, OiqThemeModule, PipesModule, ExternalUrlMsgModule],
  providers: [NavTrackingService],
  declarations: [DdiqFooterComponent],
  exports: [DdiqFooterComponent],
})
export default class OiqNavModule {}
