import { Pipe, PipeTransform } from '@angular/core';

/**
 * Removes the protocol in front of the website name
 * Shortens the website name based on the max length provided and appends ellipses
 */
@Pipe({
  name: 'websiteNameNormalizer',
})
export default class WebsiteNamePipe implements PipeTransform {
  constructor() {}

  /**
   * Removes the protocol in front of the website name
   * Shortens the website name based on the max length provided and appends ellipses
   * @param input
   * @param maxLength
   * @returns {String}
   */
  transform(input: any, maxLength: any) {
    let normalizedWebsite = input;

    if (typeof normalizedWebsite !== 'undefined') {
      normalizedWebsite = normalizedWebsite.replace(/http[s]?:\/\//, '');
    }

    if (maxLength && normalizedWebsite.length > maxLength) {
      normalizedWebsite = normalizedWebsite.substr(0, maxLength) + '...';
    }

    return normalizedWebsite;
  }
}
