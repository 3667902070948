import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import CompanyAdditionalInputDataDetailComponent from './company-additional-input-data-detail.component';
import CompanyInputDataDetailComponent from './company-input-data-detail.component';
import SeedDataTabsComponent from './seed-data-tabs.component';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import SubmittedPeoplePipe from './submitted-people.pipe';
import PreviousAddressesPipe from './previous-addresses.pipe';
import CorrelationDetailsComponent from './correlation-details.component';
import PersonAdditionalInputDataDetailComponent from './person-additional-input-data-detail.component';
import PersonInputDataDetailComponent from './person-input-data-detail.component';
import { FormsModule } from '@angular/forms';
import SeedDataComponent from './seed-data.component';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import SeedDataPrintViewComponent from './seed-data-print-view.component';
import { ExternalUrlMsgModule } from '../external-url-msg/ddiq-external-url-msg.module';

@NgModule({
  imports: [CommonModule, FormsModule, OiqAdjudicationModule, PipesModule, DdiqDirectivesModule, ExternalUrlMsgModule],
  providers: [SubmittedPeoplePipe, PreviousAddressesPipe],
  declarations: [
    SeedDataComponent,
    SeedDataTabsComponent,
    CorrelationDetailsComponent,
    CompanyInputDataDetailComponent,
    PersonInputDataDetailComponent,
    PersonAdditionalInputDataDetailComponent,
    CompanyAdditionalInputDataDetailComponent,
    SeedDataPrintViewComponent,
    SubmittedPeoplePipe,
    PreviousAddressesPipe,
  ],
  exports: [
    SeedDataComponent,
    CorrelationDetailsComponent,
    SeedDataTabsComponent,
    CompanyInputDataDetailComponent,
    PersonInputDataDetailComponent,
    PersonAdditionalInputDataDetailComponent,
    CompanyAdditionalInputDataDetailComponent,
    SeedDataPrintViewComponent,
  ],
})
export default class ProfileInputModule {}
