import { Injectable, Inject } from '@angular/core';
import ApiFetchService from './api-fetch.service';
import OiqProperties from './oiq-properties.service';
import TranslateService from './translate.service';

@Injectable({
  providedIn: 'root',
})
export default class CrawlPlanService {
  private crawlPlanList = [];

  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private translateService: TranslateService
  ) {}

  fetchCrawlPlans() {
    return this.fetch().then((crawlPlans) => {
      this.crawlPlanList = [];
      Array.prototype.push.apply(this.crawlPlanList, crawlPlans);
      this.crawlPlanList.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name, undefined, { sensitivity: 'base' }));
      return this.crawlPlanList;
    });
  }

  getCrawlPlans() {
    return this.crawlPlanList;
  }

  getValidationCrawlPlan(crawlPlanName) {
    const matchingPlans = this.crawlPlanList.filter(function (plan) {
      return plan.id === crawlPlanName;
    });
    return matchingPlans.length ? matchingPlans[0].validationCrawlPlanKey : null;
  }

  private fetch() {
    return this.apiFetchService.crawlPlans().then((data) => {
      const crawlPlans = {};

      data.forEach(function (value) {
        crawlPlans[value.crawlPlanKey] = value;
      });

      let crawlPlanList = [];

      // returns a new array of objects with id and name.
      // we may need to guard agains crawlPlans being undefined
      crawlPlanList = (this.oiqProperties.crawlPlans || []).map((plan) => {
        let validationCrawlPlanKey,
          name = this.translateService.translate('crawl_' + plan);

        if (crawlPlans[plan]) {
          name = crawlPlans[plan].description;
          validationCrawlPlanKey = crawlPlans[plan].validationCrawlPlanKey;
        } else {
          validationCrawlPlanKey = 'legacyValidationPlan';
        }
        return { id: plan, name: name, validationCrawlPlanKey: validationCrawlPlanKey };
      });

      return crawlPlanList;
    });
  }
}
