import { NgModule } from '@angular/core';
import DdiqFieldSourceInfoComponent from './ddiq-field-source-info.component';
import ddiqSortButtonsComponent from './ddiq-sort-buttons.component';
import DdiqSourceInfoComponent from './ddiq-source-info.component';
import Sources from './sources.service';
import OiqSourceInfoCtrlDirective from './oiq-source-info-ctrl.directive';
import OiqSourceInfoBadgeComponent from './oiq-source-info-badge.component';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import { CommonModule } from '@angular/common';
import { OiqArticleCacheModule } from '../article-cache/oiq-article-cache.module';
import { ExternalUrlMsgModule } from '../external-url-msg/ddiq-external-url-msg.module';

@NgModule({
  imports: [CommonModule, PipesModule, ProtectedArticlesModule, OiqArticleCacheModule, ExternalUrlMsgModule],
  declarations: [
    DdiqSourceInfoComponent,
    OiqSourceInfoCtrlDirective,
    OiqSourceInfoBadgeComponent,
    ddiqSortButtonsComponent,
    DdiqFieldSourceInfoComponent,
  ],
  providers: [Sources],
  exports: [
    DdiqSourceInfoComponent,
    OiqSourceInfoCtrlDirective,
    OiqSourceInfoBadgeComponent,
    ddiqSortButtonsComponent,
    DdiqFieldSourceInfoComponent,
  ],
})
export default class OiqSourcesModule {}
