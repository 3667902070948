import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import OiqProperties from '../common-services/oiq-properties.service';
import PartialUtils from '../common-services/partial-utils.factory';

@Component({
  selector: 'ddiq-people-form',
  templateUrl: './ddiq-people-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class DdiqPeopleFormComponent implements OnInit {
  @Input() buildForm;
  @Input() countries;

  districtEnabled;
  dateFormat;
  extendedSubmissionFields;
  people: Array<any>;
  sex: string;

  constructor(private oiqProperties: OiqProperties, private partialUtils: PartialUtils) {}

  ngOnInit() {
    this.people = this.buildForm.people;
    this.districtEnabled = this.oiqProperties.districtEnabled;
    this.dateFormat = this.oiqProperties.dateFormat;
    this.extendedSubmissionFields = this.oiqProperties.extendedSubmissionFields;
    this.ensureAddressModel();
    this.ensureCurrentPosition();
    this.ensureBirthPlace();
  }

  removePerson(index) {
    this.people.splice(index, 1);
  }

  sexOptions() {
    return this.partialUtils.floatValueToTop(this.sex, ['Male', 'Female', 'Other']);
  }

  hasRelatedPeople(person) {
    var i;
    if (person.relatedPeople) {
      for (i = 0; i < person.relatedPeople.length; i++) {
        if (person.relatedPeople[i].name) {
          return true;
        }
      }
    }
  }

  addPerson() {
    this.people.push({ relatedPeople: [{}] });
    this.ensureAddressModel();
    this.ensureCurrentPosition();
    this.ensureBirthPlace();
  }

  private ensureAddressModel() {
    this.people.forEach((person) => {
      if (person.currentAddress) {
        return;
      }

      person.currentAddress = {
        streetAddress: '',
        city: '',
        stateOrProvince: '',
        zipOrPostalCode: '',
        country: '',
      };
    });
  }
  private ensureCurrentPosition() {
    this.people.forEach((person) => {
      if (person.currentPosition) {
        return;
      }
      person.currentPosition = {
        jobTitle: '',
      };
    });
  }

  private ensureBirthPlace() {
    this.people.forEach((person) => {
      if (person.birthplace) {
        return;
      }
      person.birthplace = {
        city: '',
        stateOrProvince: '',
        country: '',
      };
    });
  }
}
