<div class="input {{size}}" [ngClass]="{disabled: oiqTextDisabled}">
  <label [ngClass]="{required: isRequired}">{{label}}</label>
  <input
    [name]="name"
    type="password"
    name="password"
    [(ngModel)]="model"
    [required]="isRequired"
    [pattern]="pattern"
    [disabled]="oiqTextDisabled"
    (ngModelChange)="onInputChange(model)" />
</div>
