import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'previousAddresses',
})
export default class PreviousAddressesPipe implements PipeTransform {
  transform(previousAddresses: any) {
    return (previousAddresses || []).filter((previousAddress) => {
      return previousAddress && previousAddress.address && !previousAddress.label;
    });
  }
}
