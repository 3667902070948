import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ddiq-piq-certified-supplier',
  templateUrl: './ddiq-piq-certified-supplier.component.tpl.html',
})

/*
Data structure:
[{
id, name, address, website, country, public, symbol
}]
 */
export default class PIQCertifiedSupplier implements OnInit {
  @Input() corporateRecords: any;
  data: any;

  constructor() {
    this.data = [];
  }

  ngOnInit() {
    // filter corp record by PIQ confidence rating and ddiq association
    const usableRecords = this.corporateRecords.filter(
      (record) =>
        record.associationBucket === 'high' &&
        record.additionalData.filter(
          (addData) =>
            addData.label === 'Certified Supplier' &&
            addData.value?.find((value) => value !== undefined) === 'Exiger Proactive Intelligence Certified'
        ).length > 0
    );
    // add fields needed when confident and associated
    usableRecords.forEach((record) => {
      this.data.push({
        id: record.id,
        name: findAdditionalDataField('Parent Company Name', record),
        address: findAdditionalDataField('Parent Company Address', record),
        website: findAdditionalDataField('Parent Company Website', record),
        country: findAdditionalDataField('Parent Company Country of Incorporation', record),
        public: findAdditionalDataField('Parent Company Public/Private', record),
        symbol: findAdditionalDataField('Parent Company Ticker Symbol', record),
      });
    });
  }
}

function findAdditionalDataField(label: string, record: any) {
  return (
    record.additionalData.find((addData) => addData.label === label)?.value?.find((value) => value !== undefined) ?? ''
  );
}
