//@ts-nocheck
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import MonitorSearchService from './monitor-search.service';
import MonitorTabsData from './monitor-tabs-data.service';
import OiqProperties from '../common-services/oiq-properties.service';
import UserService from '../user/user.service';
import ApiFetchService from '../common-services/api-fetch.service';
import ScreeningHitCategoriesService from '../screening/screening-hit-categories.service';
import ProgressChecker from '../common-services/progress-checker.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ScreeningFeatureService from '../screening/screening-feature.service';
import { OrderByPipe } from '../../../../../shared/pipes/order-by.pipe';

@Component({
  selector: 'ddiq-monitor-search',
  templateUrl: './ddiq-monitor-search.component.tpl.html',
})
export default class MonitorSearchComponent implements OnInit, DoCheck, OnDestroy {
  currentTab: number;
  counts: { hitCount: number; closedCount: number };
  labelPattern: string;
  datePattern: string;
  query: any;
  dates: any;
  private previousQuery: any;
  private searchSettings: any;
  readonly isGovIdSearchEnabled: boolean;
  readonly isMonitorRevampEnabled: boolean;
  readonly isScreeningFeatureEnabled: boolean;
  groupUsers: Array<any> = [];
  searchQuery: any;
  lastQueryString: string;
  canViewConfiguration: any;

  constructor(
    private monitorSearchService: MonitorSearchService,
    private monitorTabsData: MonitorTabsData,
    private oiqProperties: OiqProperties,
    private progressChecker: ProgressChecker,
    private user: UserService,
    private apiFetchService: ApiFetchService,
    private screeningHitCategories: ScreeningHitCategoriesService,
    private messageBusService: MessageBusService,
    private screeningFeature: ScreeningFeatureService,
    private orderBy: OrderByPipe
  ) {
    this.isGovIdSearchEnabled = oiqProperties.isGovIdSearchEnabled;
    this.currentTab = monitorTabsData.activeTab || 0;
    this.counts = monitorTabsData.counts;
    this.labelPattern = 'YYYY-MM-DD';
    this.datePattern = 'yy-mm-dd';
    if (this.oiqProperties.dateFormat === 'US') {
      this.labelPattern = 'MM/DD/YYYY';
      this.datePattern = 'mm/dd/yy';
    }
    this.query = MonitorSearchComponent.resetQuery({});
    this.dates = this.resetDates({});
    this.previousQuery = monitorSearchService.previousQuery;
    this.searchSettings = monitorSearchService.searchSettings;
    this.isMonitorRevampEnabled = oiqProperties.isElasticSearchEnabled;
    this.isScreeningFeatureEnabled = screeningFeature.isEnabled();
    this.canViewConfiguration = user.canViewConfiguration();
  }

  ngOnInit() {
    if (this.user.isInGroup()) {
      this.apiFetchService.usersInGroup(this.user.getGroup()).then((response) => {
        this.groupUsers = this.orderBy.transform(response.users, 'fullName');
      });
    }
    this.initSearch();
    this.progressChecker.repeatedProgressCheck();
  }

  ngDoCheck() {
    const searchComplete = this.monitorTabsData.pendingSearches === 0;

    if (searchComplete && !this.monitorTabsData.activeTab) {
      this.currentTab = this.counts.hitCount > 0 ? 0 : 1;
      this.monitorTabsData.activeTab = this.currentTab;
    }
  }

  ngOnDestroy() {
    this.progressChecker.clearAll();
    this.monitorSearchService.clearAll();
    delete this.monitorTabsData.activeTab;
  }

  initSearch() {
    this.monitorSearchService.parseUrl();
    const urlQuery = this.monitorSearchService.previousQuery;

    this.query.q = urlQuery.q;
    this.query.id = urlQuery.id;
    this.query.owner = urlQuery.owner;
    this.query.govId = urlQuery.govId;

    if (urlQuery.after) {
      this.dates.after = jQuery.datepicker.parseDate('yy-mm-dd', urlQuery.after);
    } else {
      this.dates.after = null;
    }

    if (urlQuery.before) {
      this.dates.before = jQuery.datepicker.parseDate('yy-mm-dd', urlQuery.before);
    } else {
      this.dates.before = null;
    }

    this.query.pOrder = urlQuery.pOrder;
    this.query.pDesc = urlQuery.pDesc;
    this.query.hOrder = urlQuery.hOrder || 'date';
    this.query.hDesc = urlQuery.hDesc || true;
    if (this.query.hDesc === 'false') {
      this.query.hDesc = false;
    }

    if (this.isMonitorRevampEnabled) {
      this.searchQuery = { ...this.query, ...this.dates };
    }
  }

  resetDates(dates) {
    const maxDate = new Date();

    dates.after = null;
    dates.before = null;

    dates.options = {
      after: {
        dateFormat: this.datePattern,
        maxDate: maxDate,
      },
      before: {
        dateFormat: this.datePattern,
        maxDate: maxDate,
      },
    };

    return dates;
  }

  isQueryEmpty() {
    return (
      !this.previousQuery.q &&
      !this.previousQuery.id &&
      !this.previousQuery.owner &&
      !this.previousQuery.after &&
      !this.previousQuery.before &&
      !this.previousQuery.govId
    );
  }

  // using arrow notation because search is used in callbacks and needs to keep it's this context
  search = (query, dates, isResetPagination) => {
    if (this.isMonitorRevampEnabled) {
      this.searchQuery = { ...query, ...dates };
    }

    this.lastQueryString = query.q;
    this.monitorTabsData.pendingSearches = 3;
    this.monitorSearchService.go(query, dates, isResetPagination);
  };

  emptyQuery() {
    return !(
      this.previousQuery.q ||
      this.previousQuery.id ||
      this.previousQuery.owner ||
      this.previousQuery.after ||
      this.previousQuery.before ||
      this.previousQuery.govId
    );
  }

  async clearSearch(query, dates) {
    if (this.isMonitorRevampEnabled) {
      query.adjudicated = 'hide';
      query.confirmed = 'any';
      query.escalated = 'any';
      delete query.events;
      delete query.watchlistEvents;
      this.messageBusService.send({ type: 'clear-monitor-filters' });
    }

    await this.screeningHitCategories.deselect();

    this.resetDates(dates);
    MonitorSearchComponent.resetQuery(query);
    this.search(query, dates, true);
  }

  clearDates(query, dates) {
    this.resetDates(dates);
    this.search(query, dates, true);
  }

  changeTab(tab) {
    this.currentTab = tab;
    this.monitorTabsData.activeTab = tab;
  }

  onAfterDateChange(afterDate) {
    if (afterDate) {
      this.dates.options.before = {
        minDate: afterDate ? afterDate : null,
        maxDate: new Date(),
        dateFormat: this.datePattern,
      };
    }
  }

  onBeforeDateChange(beforeDate) {
    if (beforeDate) {
      this.dates.options.after = {
        maxDate: beforeDate ? beforeDate : new Date(),
        dateFormat: this.datePattern,
      };
    }
  }

  private static resetQuery(query) {
    query.q = null;
    query.id = null;
    query.owner = null;
    query.govId = null;
    query.hOrder = 'date';
    query.hDesc = true;
    query.cOrder = 'name';
    query.cDesc = true;
    query.rOrder = 'date';
    query.rDesc = true;
    query.category = null;

    return query;
  }
}
