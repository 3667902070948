import { Component, DoCheck, Inject } from '@angular/core';
import PollingService from '../common-services/polling.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import ScreeningFeatureService from '../screening/screening-feature.service';
import ExternalUrlMsgService from '../external-url-msg/ddiq-external-url-msg.service';

@Component({
  selector: 'ddiq-main',
  templateUrl: './ddiq-main.component.tpl.html',
})
export default class MainComponent implements DoCheck {
  hideNav: boolean;
  previousUrl: string;

  private readonly routesWithoutNav: Array<string> = [
    '/audit/',
    '/integrity/',
    '/government-procurement/',
    '/cached-article',
    '/sso-error',
    '/corporate-graph',
    '/request/',
    '/error',
    '/cover/',
  ];
  canDisplayWarningMsg: boolean;

  constructor(
    private pollingService: PollingService,
    private modalTrackingService: ModalTrackingService,
    private router: Router,
    private screeningFeature: ScreeningFeatureService,
    private externalUrlMsgService: ExternalUrlMsgService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.previousUrl = router.url;
    this.hideNav = true;
    this.canDisplayWarningMsg = externalUrlMsgService.hasAdminConfigs();

    router.events
      .pipe(filter((e: NavigationEnd): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event) => this.onRouteChange(event));
  }

  ngDoCheck() {
    const hasNoScroll = this.document.body.classList.contains('noscroll');
    const hasScreeningEnabled = this.document.body.classList.contains('screening-enabled');

    if (this.isModalOpen() && !hasNoScroll) {
      this.document.body.classList.add('noscroll');
    } else if (!this.isModalOpen() && hasNoScroll) {
      this.document.body.classList.remove('noscroll');
    }

    if (this.screeningFeature.isEnabled() && !hasScreeningEnabled) {
      this.document.body.classList.add('screening-enabled');
    } else if (!this.screeningFeature.isEnabled() && hasScreeningEnabled) {
      this.document.body.classList.remove('screening-enabled');
    }
  }

  onRouteChange(event: NavigationEnd) {
    this.hideNav = this.shouldHideMainNav(event.url);

    const oldUrlPieces = (this.previousUrl || '').split('?');
    const newUrlPieces = event.url.split('?');

    const samePage = oldUrlPieces[0] === newUrlPieces[0];

    if (!samePage) {
      //Don't stop polling when user changes the filter or clicks on section in navigation menu
      this.pollingService.stop();
    }

    this.modalTrackingService.reset();

    this.previousUrl = event.url;
  }

  shouldHideMainNav(path: string): boolean {
    return this.routesWithoutNav.some((route: string) => {
      return path?.includes(route);
    });
  }

  isModalOpen() {
    return this.modalTrackingService.modalActive();
  }
}
