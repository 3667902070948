import { OnInit, Input, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import Utils from '../../../exiger/utils';

@Directive({
  selector: '[data-oiq-collapsible-ctrl]',
  exportAs: 'oiqCollapsibleCtrl',
})
export default class OiqCollapsibleCtrlDirective implements OnInit, OnChanges {
  @Input() expandAll: boolean = false;

  private toggles: any = {};
  private isCollapsibleOpen: boolean = false;

  constructor(private windowRef: WindowRefService) {}

  ngOnInit(): void {
    let location = this.windowRef.nativeWindow.location;
    this.isCollapsibleOpen = Utils.getParameter('print', location.href) === 'expanded' || this.expandAll;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expandAll && changes.expandAll.currentValue !== changes.expandAll.previousValue) {
      this.isCollapsibleOpen = changes.expandAll.currentValue;
    }
  }

  registerToggle(name, initialState?) {
    let toggleState = this.isCollapsibleOpen;

    if (typeof initialState === 'boolean') {
      toggleState = initialState;
    }

    this.toggles[name] = toggleState;
  }

  unregisterToggle(name) {
    delete this.toggles[name];
  }

  isOpen(name?) {
    if (isToggleRegistered(this.toggles, name)) {
      return this.toggles[name];
    } else {
      return this.isCollapsibleOpen;
    }
  }

  open(name?) {
    if (isToggleRegistered(this.toggles, name)) {
      this.toggles[name] = true;
    } else {
      this.isCollapsibleOpen = true;
    }
  }

  toggle(name?) {
    if (isToggleRegistered(this.toggles, name)) {
      this.toggles[name] = !this.toggles[name];
    } else {
      this.isCollapsibleOpen = !this.isCollapsibleOpen;
    }
  }

  getRegisteredToggleNames() {
    return Object.keys(this.toggles);
  }
}

function isToggleRegistered(toggles, name) {
  return toggles.hasOwnProperty(name);
}
