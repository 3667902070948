import CommonServicesModule from '../common-services/common-services.module';
import corporateGraphButtons from './corporate-graph-buttons.component';
import corporateGraphVis from './corporate-graph-vis.component';
import corporateGraphDrawer from './corporate-graph-drawer.component';
import CorporateGraph from './corporate-graph.service';
import CorporateGraphFilter from './corporate-graph-filter.service';
import CorporateGraphRender from './corporate-graph-render.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import CorporateGraphResolver from './corporate-graph-resolver.service';

@NgModule({
  imports: [CommonModule, FormsModule, CommonServicesModule],
  declarations: [corporateGraphButtons, corporateGraphDrawer, corporateGraphVis],
  providers: [CorporateGraphFilter, CorporateGraphRender, CorporateGraph, CorporateGraphResolver],
  exports: [corporateGraphButtons],
})
export class CorporateGraphModule {}
