import { Injectable } from '@angular/core';
import EventGroupsTemplate from '../event-groups/event-groups-template';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';
import EventGroupBuilderService from './event-group-builder.service';
import EventGroupsBuilderService from './event-groups-builder.service';

@Injectable({
  providedIn: 'root',
})
export default class InmateProfileEventGroupsService extends EventGroupsTemplate {
  constructor(
    private eventGroupBuilderService: EventGroupBuilderService,
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private structuredDataSorts: StructuredDataSorts
  ) {
    super('inmateProfilesEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
  }

  addEventGroupsToSection() {
    return (section) => {
      const inmateProfiles = section.data.inmateProfiles;

      if (!inmateProfiles) return;

      const eventGroup = this.eventGroupBuilderService
        .newBuilder()
        .withDiscoveries(inmateProfiles)
        .withTitle('Profiles')
        .withSort((discoveries) => this.structuredDataSorts.sortByAdjudication(discoveries))
        .build();

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService.newEventGroupsModel([eventGroup]).build();
    };
  }
}
