import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import AlertService from '../common-services/alert.service';
import Entity from '../common-services/entity.service';
import FieldSourceMetadata from '../common-services/field-source-metadata.service';
import PartialUtils from '../common-services/partial-utils.factory';
import RelationshipService from '../common-services/relationship-service.factory';
import OwnershipDataExtractionService from '../corporate-records/ownership-data-extraction.service';
import Monitor from '../monitor/monitor.service';
import AlertSummarizer from '../themed-entity/alert-summarizer.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';
import StructuredData from '../themed-entity/structured-data.service';
import UserService from '../user/user.service';
@Component({
  selector: 'ddiq-corporate-info',
  templateUrl: './ddiq-corporate-info.component.tpl.html',
  providers: [DecimalPipe],
})
export default class CorporateInfoComponent implements OnInit {
  @Input() section;
  @Input() isExpandAllGroups;

  corporateAlerts;
  contentCounter;
  seedData;
  isAddressFilled;
  isDeltaReport;
  previewingEntity;
  entityType;
  hasWebsiteAlert;
  hasClassificationAlert;
  alertCount;
  showInsufficientDataMsg;
  isSectionOpen;

  constructor(
    private partialUtils: PartialUtils,
    private alertSummarizer: AlertSummarizer,
    public entity: Entity,
    private relationshipService: RelationshipService,
    private structuredDataUtils: StructuredDataUtils,
    public user: UserService,
    private ownershipDataExtractionService: OwnershipDataExtractionService,
    private structuredData: StructuredData,
    private monitor: Monitor,
    private fieldSourceMetaData: FieldSourceMetadata,
    private messageBus: MessageBusService,
    private readonly alertService: AlertService,
    private readonly decimalPipe: DecimalPipe
  ) {}

  ngOnInit() {
    this.isAddressFilled = this.partialUtils.isAddressFilled;
    this.isDeltaReport = this.monitor.isDeltaReport();
    this.previewingEntity = this.entity.isInPreview();
    this.entityType = this.entity.getType();
    this.seedData = this.entity.getSeedData();

    // needed due to scope being unavailable in nested collapses
    this.markOtherCorporateInfo(this.section.data.corporateRecords);

    // display the ownership data in main profile
    this.updateOwnershipData();

    // these are exposed on the scope for use in the section header
    this.corporateAlerts = this.alertService.getThemedEntityAlerts(
      this.entity.getType(),
      this.entity.getAlerts(),
      this.section.metadata.sectionId
    );
    this.hasWebsiteAlert = (this.corporateAlerts || []).some((alert) => {
      return alert.alertType === 'STATE_OWNED_COMPANY';
    });
    this.hasClassificationAlert = (this.corporateAlerts || []).some((alert) => {
      return alert.alertType === 'HIGH_RISK_INDUSTRY_CLASSIFICATION';
    });
    this.alertCount = this.countAlerts(this.section.data, this.corporateAlerts);

    // and here they're added to the AlertSummarizer for the print header
    this.alertSummarizer.addAlertCount(this.section.metadata.sectionId, this.alertCount);
    this.alertSummarizer.addAlertList(this.section.metadata.sectionId, this.corporateAlerts);

    this.structuredData.registerFilterObserver(() => this.updateOwnershipData());

    this.contentCounter = {
      _corporate: this.structuredDataUtils.observedCount('corporate'),

      filtered: function () {
        return this._corporate.filtered;
      },
      unfiltered: function () {
        return this._corporate.unfiltered;
      },
      filteredEscalated: function () {
        return this._corporate.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._corporate.unfilteredEscalated;
      },
    };

    Promise.all([
      this.relationshipService.locations(this.entity.getType(), this.entity.getId()),
      this.relationshipService.people(this.entity.getType(), this.entity.getId()),
    ]).then((result) => {
      let hasRelatedPeopleOrLocations;

      if (this.entity.getSeedData()) {
        hasRelatedPeopleOrLocations =
          result[0].some((location) => {
            return location.base.address.streetAddress && location.base.address.city && location.base.address.country;
          }) ||
          result[1].some((person) => {
            return person.base.personName;
          });

        if (typeof this.seedData === 'undefined') {
          this.seedData = this.entity.getSeedData();
        }

        this.showInsufficientDataMsg =
          !hasRelatedPeopleOrLocations &&
          !(
            this.seedData.mainAddress &&
            this.seedData.mainAddress.streetAddress &&
            this.seedData.mainAddress.city &&
            this.seedData.mainAddress.country
          );
      }

      this.isSectionOpen = this.previewingEntity || this.sectionHasContentToDisplay();
    });
  }

  sectionHasContentToDisplay() {
    return (
      this.relationshipService.hasRelationships() ||
      this.hasVerificationInfo() ||
      this.hasOtherCompanyData() ||
      this.hasSicCodes() ||
      ['directOwners', 'ultimateOwners', 'formerNames', 'corporateRecords'].some(
        (arrayName) => this.section.data[arrayName] && this.section.data[arrayName].length
      )
    );
  }

  /**
   * returns an alert count for this section.
   *
   * corporate section includes poor bbb rating, area code not existing,
   * phone number not existing
   *
   * @param data - usually section.data
   * @param alerts
   * @returns {number}
   */
  countAlerts(data, alerts) {
    let count = 0;

    if (data === undefined && alerts === undefined) {
      return count;
    }

    if (alerts !== undefined) {
      count += alerts.length;
    }

    return count;
  }

  hasOtherCorporateInfo(corporateRecord) {
    return (
      corporateRecord.companyType !== undefined ||
      corporateRecord.companySubtype !== undefined ||
      corporateRecord.companyClassification !== undefined ||
      corporateRecord.website !== undefined ||
      corporateRecord.primaryContact !== undefined ||
      corporateRecord.primaryContactAddress !== undefined ||
      corporateRecord.primaryContactMailingAddress !== undefined ||
      corporateRecord.parentName !== undefined ||
      corporateRecord.parentAddress !== undefined ||
      corporateRecord.employeeCount !== undefined ||
      corporateRecord.description !== undefined ||
      (corporateRecord.additionalNames && corporateRecord.additionalNames.length > 0) ||
      (corporateRecord.additionalAddresses && corporateRecord.additionalAddresses.length > 0) ||
      (corporateRecord.phoneNumbers && corporateRecord.phoneNumbers.length > 0) ||
      (corporateRecord.additionalData && corporateRecord.additionalData.length > 0) ||
      (corporateRecord.historicNames && corporateRecord.historicNames.length > 0) ||
      (corporateRecord.officers && corporateRecord.officers.length > 0)
    );
  }

  markOtherCorporateInfo(corporateRecords) {
    if (!corporateRecords) {
      return;
    }

    for (let i = 0; i < corporateRecords.length; i++) {
      corporateRecords[i].hasOtherCorporateInfo = this.hasOtherCorporateInfo(corporateRecords[i]);
    }
  }

  updateOwnershipData() {
    let ownershipData = this.ownershipDataExtractionService.extractOwnersFromMostReleventCorporateRecord(
      this.section.data.corporateRecords
    );
    if (ownershipData) {
      this.section.data.directOwners = ownershipData.directOwners;
      this.section.data.ultimateOwners = ownershipData.ultimateOwners;
      this.section.data.ownershipRecordId = ownershipData.ownershipRecordId;
      this.fieldSourceMetaData.updateMetadata('ownership', ownershipData.ownershipRecordId, [ownershipData.source]);
    }
  }

  hasVerificationInfo() {
    if (!this.section.data) {
      return false;
    }

    return (
      this.partialUtils.isAddressFilled(this.section.data.mainAddress) ||
      this.section.data.website ||
      this.section.data.dbaNames?.length ||
      this.section.data.phoneNumber
    );
  }

  hasOtherCompanyData() {
    if (!this.section.data) {
      return false;
    }

    return (
      this.section.data.website ||
      (this.section.data.socialMedia &&
        ((this.section.data.socialMedia.facebook && this.section.data.socialMedia.facebook.profileUrl) ||
          (this.section.data.socialMedia.linkedIn && this.section.data.socialMedia.linkedIn.profileUrl) ||
          (this.section.data.socialMedia.twitter && this.section.data.socialMedia.twitter.profileUrl))) ||
      this.section.data.socialMediaUrls?.length > 0
    );
  }

  hasSicCodes() {
    return (
      this.section.data.classifications !== undefined &&
      ((this.section.data.classifications.ussicClassifications &&
        this.section.data.classifications.ussicClassifications.length > 0) ||
        (this.section.data.classifications.naicsClassifications &&
          this.section.data.classifications.naicsClassifications.length > 0) ||
        (this.section.data.classifications.uksicClassifications &&
          this.section.data.classifications.uksicClassifications.length > 0) ||
        (this.section.data.classifications.casicClassifications &&
          this.section.data.classifications.casicClassifications.length > 0))
    );
  }

  sortDiscoveries(sortName, eventGroup) {
    this.messageBus.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind: 'corporateRecords',
        sortName,
        eventGroup,
      },
    });
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0');
  }

  formatPercentage(value: number): string {
    return this.decimalPipe.transform(value * 100, '1.2-2');
  }
}
