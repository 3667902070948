import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyFormat',
})
export default class MoneyFormatPipe implements PipeTransform {
  constructor() {}

  /**
   * returns $ format with decimals
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    if (typeof input !== 'undefined' && input !== 0) {
      let num = '' + input;
      let parts = num.split('.');
      let integerPart = parts[0];
      let hasDecimal = parts.length > 1;

      for (let i = 0; i < Math.floor((integerPart.length - (1 + i)) / 3); i++) {
        integerPart =
          integerPart.substring(0, integerPart.length - (4 * i + 3)) +
          ',' +
          integerPart.substring(integerPart.length - (4 * i + 3));
      }
      if (hasDecimal) {
        let decimalPart = (parts[1] + '00').substr(0, 2);
        return '$' + integerPart + '.' + decimalPart;
      } else {
        return '$' + integerPart;
      }
    } else {
      return '$0';
    }
  }
}
