import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet/dist/leaflet.css';
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';

@Component({
  selector: 'ddiq-simple-leaflet',
  template: '',
})
export default class SimpleLeafletComponent implements AfterViewInit {
  @Input() address;

  constructor(private errorHandler: ErrorHandler, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const elId = this.elementRef.nativeElement.id;
    if (!elId) {
      this.errorHandler.error('Missing ID for leaflet DOM element');
      return;
    }

    let map = this.displayMap();
    // to make sure the map renders, we tell the map to resize after its been initialized
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }

  displayMap() {
    // for our map, we use the leaflet library
    const latlng = new L.LatLng(this.address.coordinates.latitude, this.address.coordinates.longitude);
    let map = new L.Map(this.elementRef.nativeElement.id, {
      center: latlng,
      zoom: 16,
    });

    let gRoad = new L.gridLayer.googleMutant({ type: 'roadmap' });
    let gSatellite = new L.gridLayer.googleMutant({ type: 'satellite' });

    map.addLayer(gRoad);
    map.addControl(
      new L.Control.Layers(
        {
          Road: gRoad,
          Satellite: gSatellite,
        },
        {}
      )
    );

    let marker = L.marker(latlng).addTo(map);
    marker.bindPopup(this.address.streetAddress);

    return map;
  }
}
