import { Injectable } from '@angular/core';
import { select, selectAll } from 'd3';
import CorporateGraph from './corporate-graph.service';

@Injectable()
export default class CorporateGraphRender {
  TEXT_ANCHOR: string;

  constructor(private CorporateGraph: CorporateGraph) {
    this.CorporateGraph = CorporateGraph;
    this.TEXT_ANCHOR = 'text-anchor';
  }

  createTextBox(nodeEnter, txt, yPosition?, filter?) {
    nodeEnter
      .filter((d) => (filter ? filter(d) : !!txt(d)))
      .append('text')
      .attr('class', 'node-text')
      .attr('x', 0)
      .attr('y', function () {
        let y = 0;
        if (yPosition) {
          y = yPosition(this);
        }
        return y;
      })
      .attr('dy', '.35em')
      .attr(this.TEXT_ANCHOR, 'middle')
      .text((d) => txt(d))
      .call(wrap, 80);
  }

  createNameTextBox(nodeEnter) {
    this.createTextBox(nodeEnter, (d) => d.name);
  }

  createCountryTextBox(nodeEnter) {
    this.createTextBox(nodeEnter, countryTxtModel, stackBoxes, this.CorporateGraph.isNonUsCountry);
  }

  highLightCountryNodes(color) {
    selectAll('.node-rect')
      .filter((d) => this.CorporateGraph.isNonUsCountry(d))
      .style('stroke', color);
  }

  dynamicHighlightCountryNodes() {
    selectAll('.node-rect')[0].forEach((d) => {
      if (this.CorporateGraph.isSanctionedCountry(d.__data__)) {
        d.style.stroke = '#FF5630';
      } else if (this.CorporateGraph.isHighRiskCountry(d.__data__)) {
        d.style.stroke = '#FFC400';
      } else if (this.CorporateGraph.isNotLocalCountry(d.__data__)) {
        d.style.stroke = '#00B8D9';
      }
    });
  }
}

function countryTxtModel(d) {
  return (d.address || {}).country;
}

function stackBoxes(node) {
  let height = 0;
  select(node.parentNode)
    .selectAll('rect')
    .each(function () {
      height += Number(select(this).attr('height'));
    });
  return height + 2;
}

//https://bl.ocks.org/mbostock/7555321
function wrap(textElements, widthLimit) {
  textElements.each(function () {
    const textElem = select(this),
      words = textElem.text().split(/\s+/).reverse(),
      x = textElem.attr('x'),
      y = textElem.attr('y'),
      dy = parseFloat(textElem.attr('dy'));

    lineWrapIfExceedsLimit({ textElem, widthLimit, x, y, dy, words });

    // Box for node text
    let boxDimension = textElem.node().getBBox();
    select(this.parentNode)
      .insert('rect', '.node-text')
      .attr('class', 'node-rect')
      .attr('height', boxDimension.height + 5)
      .attr('width', boxDimension.width + 5)
      .attr('x', boxDimension.x - 2.5)
      .attr('y', boxDimension.y - 2.5);
  });
}

function lineWrapIfExceedsLimit(box) {
  const LINE_HEIGHT = 1.1; // ems
  let word,
    lineNumber = 0,
    line = [],
    tspan = box.textElem
      .text(null)
      .append('tspan')
      .attr('x', box.x)
      .attr('y', box.y)
      .attr('dy', box.dy + 'em');

  while ((word = box.words.pop())) {
    line.push(word);
    tspan.text(line.join(' '));

    if (tspan.node().getComputedTextLength() > box.widthLimit) {
      lineNumber++;
      line.pop();
      tspan.text(line.join(' '));
      line = [word];
      tspan = box.textElem
        .append('tspan')
        .attr('x', box.x)
        .attr('y', box.y)
        .attr('dy', lineNumber * LINE_HEIGHT + box.dy + 'em')
        .text(word);
    }
  }
}
