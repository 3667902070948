import { HttpInterceptor, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import ApiFetchService from '../common-services/api-fetch.service';
import ScreeningFeatureService from './screening-feature.service';

@Injectable()
export default class ScreeningProfileStatusInterceptor implements HttpInterceptor {
  constructor(private apiFetchService: ApiFetchService, private screeningFeature: ScreeningFeatureService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      switchMap(async (event) => {
        if (this.shouldApplyProfileStatusTo(event, req)) {
          return await this.updateResponseWithProfileStatus(event);
        }
        return event;
      })
    );
  }

  private shouldApplyProfileStatusTo(event: any, req: HttpRequest<any>) {
    if (!this.screeningFeature.isEnabled()) {
      return false;
    }
    return event instanceof HttpResponse && req.url.indexOf('monitor/entity') >= 0;
  }

  private updateResponseWithProfileStatus(event: any) {
    return this.apiFetchService.getProfileStatus(event.body.entityType, event.body.oiqEntityId).then((status) => {
      const response = event.clone();
      response.body.profileStatus = status;
      return response;
    });
  }
}
