import { Injectable } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import utils from '../../../exiger/utils';
import FieldSourceMetadata from '../common-services/field-source-metadata.service';

@Injectable({
  providedIn: 'root',
})
export default class Sources {
  private TRUNCATE_LENGTH: number = 150;

  constructor(private windowRefService: WindowRefService, private fieldSourceMetadata: FieldSourceMetadata) {}

  shouldShow() {
    return utils.getParameter('sources', this.windowRefService.nativeWindow.location.href);
  }

  getSourceInfoForFieldByValue(fieldName, value) {
    var sources = [];

    (this.fieldSourceMetadata.metadata[fieldName] || []).some((metadata) => {
      if (this.isMatch(metadata.value, value)) {
        sources = sources.concat(metadata.sources);
        return true;
      }
    });

    return sources;
  }

  private normalizeString(value) {
    return ('' + value).toLowerCase().replace(/\s+/g, '').substring(0, this.TRUNCATE_LENGTH);
  }

  private isMatch(input1, input2) {
    return this.normalizeString(input1) === this.normalizeString(input2);
  }
}
