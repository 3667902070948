<img *ngIf="isLoading" [src]="'./img/loader.gif'" class="result-loading-img" />

<ng-container *ngIf="!isLoading">
  <div *ngIf="!hasSearchResults" class="no-search-results-msg">Your search has no results</div>

  <div class="monitor-hits" *ngFor="let report of reports">
    <ddiq-monitor-no-hits
      *ngIf="!report.monitorHitTotal"
      class="monitor-no-hits"
      [report]="report"></ddiq-monitor-no-hits>

    <ddiq-monitor-hits
      *ngIf="report.monitorHitTotal > 0"
      class="monitor-some-hits"
      [report]="report"></ddiq-monitor-hits>
  </div>

  <exg-pagination
    [numPages]="pagination.total"
    [currentPage]="pagination.current"
    [paginationBarLength]="pagination.visible"
    [tableTotal]="totalHitCount"
    (onSelectPage)="onSelectPage($event.page)"
    [totalLabel]="'profiles'"></exg-pagination>
</ng-container>
