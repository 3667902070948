<ddiq-monitor-hits-entity-name
  [entityType]="report.entityType"
  [entityName]="report.name"
  [profileLink]="report.profileLink"></ddiq-monitor-hits-entity-name>
<div class="delimiter"></div>
<ddiq-monitor-hits-header
  [submissionId]="report.submissionId"
  [latestMatchDate]="report.oiqLastUpdatedDate"
  [lastRunDate]="report.monitor.lastRunAt"
  [ownerName]="report.oiqOwnerFullName"
  [adjudicatedHitsCount]="report.monitor.totalAdjudicatedMonitorHits"
  [isGovIdEnabled]="isGovIdSearchEnabled"
  [idNumbers]="report.seedData.identificationNumbers"></ddiq-monitor-hits-header>
<div class="delimiter"></div>
<span *ngIf="!report.monitor.totalAdjudicatedMonitorHits" class="monitor-no-hits-message">No matches found</span>
<span *ngIf="report.monitor.totalAdjudicatedMonitorHits" class="monitor-no-hits-message"
  >All monitored hits are closed</span
>
