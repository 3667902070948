import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ddiq-website-scorecard',
  templateUrl: './ddiq-website-scorecard.component.tpl.html',
})
export default class WebsiteScorecardComponent implements OnInit {
  @Input() scorecard: any;
  @Input() adjudicationType: string;
  @Input() isAdjudicateable: boolean;
  @Input() isDisplayClassifications: boolean;
  @Input() entityType;
  @Input() entityId;
  @Input() noReassess;
  @Input() displayCreatedDate;

  constructor() {}

  ngOnInit() {
    this.createSeverityToIssuesMap();
    this.createSeverityToTotalNumOfIssuesMap();
  }

  hasFactors() {
    return this.scorecard.factors && this.scorecard.factors.length;
  }

  createSeverityToIssuesMap() {
    this.scorecard.factors.forEach((factor) => {
      let severityToIssuesMap: Map<string, any[]> = new Map();
      factor.issueSummaryList.forEach((issueSummary) => {
        let summaryList = severityToIssuesMap.get(issueSummary.issueSeverity);
        if (!summaryList) {
          summaryList = [];
        }
        summaryList.push(issueSummary);
        severityToIssuesMap.set(issueSummary.issueSeverity, summaryList);
      });
      this.sortIssueSummary(severityToIssuesMap);
      factor.severityToIssuesMap = severityToIssuesMap;
    });
  }

  createSeverityToTotalNumOfIssuesMap() {
    this.scorecard.factors.forEach((factor) => {
      let severityToTotalNumOfIssuesMap: Map<string, number> = new Map();
      factor.issueSummaryList.forEach((issueSummary) => {
        let totalNumOfIssues = severityToTotalNumOfIssuesMap.get(issueSummary.issueSeverity);
        if (!totalNumOfIssues) {
          totalNumOfIssues = 0;
        }
        totalNumOfIssues = totalNumOfIssues + issueSummary.issueCount;
        severityToTotalNumOfIssuesMap.set(issueSummary.issueSeverity, totalNumOfIssues);
      });

      factor.severityToTotalNumOfIssuesMap = severityToTotalNumOfIssuesMap;
    });
  }

  // sort by issueCount in descending order and then by issueType in ascending order
  compare(a, b) {
    if (b.issueCount < a.issueCount) {
      return -1;
    } else if (b.issueCount > a.issueCount) {
      return 1;
    } else if (a.issueCount === b.issueCount) {
      if (a.issueType > b.issueType) {
        return 1;
      } else if (a.issueType < b.issueType) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  sortIssueSummary(severityToTotalNumOfIssuesMap) {
    severityToTotalNumOfIssuesMap.forEach((value, key) => {
      value.sort(this.compare);
    });
  }
}
