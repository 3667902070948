import CachedArticleHrefPipe from './cached-article-href.pipe';
import CachedArticleResolver from './cached-article-resolver.service';
import CachedArticleComponent from './cached-article.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OiqThemeModule } from '../theme/oiq-theme.module';

@NgModule({
  imports: [CommonModule, OiqThemeModule],
  declarations: [CachedArticleComponent, CachedArticleHrefPipe],
  providers: [CachedArticleResolver],
  exports: [CachedArticleComponent, CachedArticleHrefPipe],
})
export class OiqArticleCacheModule {}
