import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import ConfigurationComponent from './ddiq-configuration.component';
import ConfigurationResolverService from './configuration-resolver.service';

@NgModule({
  imports: [CommonModule, NgPipesModule, PipesModule],
  providers: [ConfigurationResolverService],
  declarations: [ConfigurationComponent],
  exports: [ConfigurationComponent],
})
export default class OiqConfigurationModule {}
