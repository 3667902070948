import { Injectable } from '@angular/core';
import { ProfileHit, ProfileHitId } from './profile-hit-id';

interface IncidentsHit extends ProfileHit {
  classification: string;
}
@Injectable()
export class IncidentsHitIdService extends ProfileHitId {
  doGetIdFrom(incidentsHit: IncidentsHit): string {
    return `${incidentsHit.classification}_${incidentsHit.id}`;
  }
}
