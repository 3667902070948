import { Component, Inject, Input, OnInit } from '@angular/core';
import Entity from '../common-services/entity.service';
import PartialUtils from '../common-services/partial-utils.factory';
import PdfFeatureService from '../pdf/pdf-feature.service';
import SeedDataService from '../themed-entity/seed-data.service';
import Monitor from '../monitor/monitor.service';

@Component({
  selector: 'seed-data',
  templateUrl: './seed-data.component.tpl.html',
})
export default class SeedDataComponent implements OnInit {
  @Input() hideComments;
  @Input() section;
  isCommentDisabled;
  isPdfRender;
  seedData;
  isMonitorDeltaReport;

  constructor(
    private readonly seedDataService: SeedDataService,
    private readonly partialUtils: PartialUtils,
    private readonly pdfFeature: PdfFeatureService,
    private readonly entity: Entity,
    private readonly monitor: Monitor
  ) {
    this.isCommentDisabled = this.entity.isInPreview();
    this.isPdfRender = this.pdfFeature.isPdfRender();
    this.isMonitorDeltaReport = this.monitor.isDeltaReport();
  }

  ngOnInit() {
    this.seedDataService.fetchInputDataForProfile(this.isMonitorDeltaReport).then((seedData) => {
      this.seedData = seedData;
    });
  }

  canShowSeedData() {
    let canShow = false;

    if (this.seedData) {
      canShow = this.partialUtils.isUserSubmitted(this.seedData?.seedSources) || this.isMonitorDeltaReport;
    }

    return canShow;
  }
}
