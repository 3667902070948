import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export default class SystemUseNoticeService {
  private notice: string;

  setNotice(notice: string) {
    this.notice = notice;
  }

  getNotice() {
    return this.notice;
  }

  hasNotice() {
    return !!(this.notice || '').trim();
  }
}
