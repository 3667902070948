import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ProfileStatusFeatureService from './profile-status-feature.service';
import ProfileStatusService from './profile-status.service';

@Component({
  selector: 'profile-status',
  templateUrl: './profile-status.component.tpl.html',
})
export default class ProfileStatusComponent implements OnInit, OnDestroy {
  constructor(
    private oiqProperties: OiqProperties,
    private profileStatus: ProfileStatusService,
    private profileStatusFeatureService: ProfileStatusFeatureService,
    public entity: Entity,
    private errorHandler: ErrorHandler,
    private messageBusService: MessageBusService
  ) {}

  isValidConfig;
  dropDownMessage;
  isBusy;
  statuses;
  availableStatuses;

  eventAdjudicated;
  hasProfileStatus: boolean;
  @Output() onError = new EventEmitter<any>();

  ngOnInit(): void {
    this.availableStatuses = this.oiqProperties.profileStatuses;
    this.isValidConfig = this.isStatusConfigValid();

    if (this.isValidConfig) {
      this.eventAdjudicated = this.messageBusService.on('event:event-adjudicated', () => this.validateStatuses());
      this.validateStatuses();
    } else {
      this.dropDownMessage = 'Profile statuses are misconfigured!';
    }

    this.hasProfileStatus = this.entity.getData().profileStatus;
  }

  ngOnDestroy() {
    if (this.eventAdjudicated) {
      this.eventAdjudicated.unsubscribe();
    }
  }

  isStatusConfigValid() {
    let isValidConfig;
    if (!this.profileStatusFeatureService.isAdjudicationRequiredEnabled()) {
      isValidConfig = true;
    } else {
      isValidConfig = this.profileStatusFeatureService.isAdjudicationRequiredConfigValid();
      if (!isValidConfig) {
        this.errorHandler.error('The profile status adjudication feature is misconfigured!');
      }
    }
    return isValidConfig;
  }

  validateStatuses() {
    return this.request(() => {
      return this.profileStatus
        .validateStatuses(this.entity.getType(), this.entity.getId(), this.availableStatuses)
        .then((statuses) => {
          this.statuses = statuses;
        });
    });
  }

  updateStatus(newStatus, isValid) {
    const existingStatus = this.entity.getData().profileStatus;

    if (newStatus === existingStatus || !isValid) {
      return;
    }
    this.preFlightStatusUpdate(newStatus, existingStatus);
  }

  preFlightStatusUpdate(newStatus, existingStatus) {
    this.validateStatuses().then(() => {
      if (!this.isStatusValid(newStatus)) {
        return this.onError.emit({ reason: 'STALE_PROFILE_STATUSES' });
      }

      this.request(() => {
        const statusUpdate = this.makeStatusUpdate(newStatus, existingStatus);
        return this.profileStatus.updateStatus(statusUpdate);
      });
    });
  }

  isStatusValid(statusName) {
    for (const status of this.statuses) {
      if (status.name === statusName && !status.isValid) {
        return false;
      }
    }
    return true;
  }

  makeStatusUpdate(newStatus, existingStatus) {
    const statusPayload = { newStatus, oldStatus: null };

    if (existingStatus) {
      statusPayload.oldStatus = existingStatus;
    }
    return statusPayload;
  }

  request(target) {
    this.isBusy = true;
    this.dropDownMessage = 'Busy...';
    return target().then(() => {
      this.dropDownMessage = '';
      this.isBusy = false;
    });
  }
}
