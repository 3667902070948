import { Component, Input, OnInit } from '@angular/core';

import Entity from '../common-services/entity.service';
import FieldSourceMetadata from '../common-services/field-source-metadata.service';

@Component({
  selector: 'ddiq-source-metadata',
  templateUrl: 'ddiq-source-metadata.component.tpl.html',
})
export default class SourceMetadataComponent implements OnInit {
  @Input() sourceMetaData;
  metadata;

  constructor(public fieldSourceMetadata: FieldSourceMetadata, private entity: Entity) {}

  ngOnInit(): void {
    // no field source data on diffs
    if (this.entity.isDiff()) {
      return;
    }
    this.metadata = this.fieldSourceMetadata.metadata[this.sourceMetaData];
  }
}
