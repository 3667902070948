<div id="address-title" *ngIf="!section.metadata.filtered">
  <ddiq-crawl-error></ddiq-crawl-error>

  <ddiq-field-source-info field="address" [fieldValue]="section.data.address | address">
    <strong>{{section.data.address | address}}</strong>
  </ddiq-field-source-info>
  <hr />
  <span *ngIf="section.data.crawlPlanTypeDescription"
    >{{ 'PROFILE_TYPE' | translatef }} : {{section.data.crawlPlanTypeDescription | translatef}}</span
  >
</div>
