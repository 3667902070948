import * as d3 from 'd3';
import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
import NetworkService from './network.service';
import { Router } from '@angular/router';

const icons = {
  company: '\uf1ad', //fa-building-o
  person: '\uf007', //fa-user
  property: '\uf0ac', //fa-globe
  alert: '\uf06a', //fa-exclamation-circle
};

function alertFor(entity) {
  let icon = '';
  const node = d3.select(this);

  if (!entity.hasAlerts) {
    node.remove();
  } else {
    node.attr('class', 'network-node-has-alert');
    icon = icons.alert;
  }
  return icon;
}

function iconFor(entity) {
  return icons[entity.entityType.toLowerCase()];
}

function labelForEntity(d) {
  return d.name;
}

@Component({
  selector: 'ddiq-network-vis',
  templateUrl: './ddiq-network-vis.component.tpl.html',
})
export default class NetworkVisComponent implements OnInit, OnChanges {
  @Input() config;

  icons = {
    company: '\uf1ad', //fa-building-o
    person: '\uf007', //fa-user
    property: '\uf0ac', //fa-globe
    alert: '\uf06a', //fa-exclamation-circle
  };
  graphContainerElement: any;
  layout: any;
  canvasGroup: any;
  width: number;
  height: number;
  zoomBehaviour: any;

  nodeCount: string;
  links: any;
  link: any;
  nodes: any;
  node: any;
  nodeBox: any;
  text: any;

  tombstone: any;
  canShowAlternativeNames: any;
  canRenderGraph: boolean = true;

  constructor(private router: Router, private networkService: NetworkService, private elementRef: ElementRef) {}

  ngOnInit() {
    this.graphContainerElement = this.elementRef.nativeElement.querySelector('.network-vis');

    this.width = parseFloat(getComputedStyle(this.graphContainerElement, null).width.replace('px', ''));
    this.height = 600;
    this.zoomBehaviour = d3.behavior
      .zoom()
      .size([this.width, this.height])
      .on('zoom', () => this.onZoom());

    this.setUp();
  }

  setUp() {
    this.layout = d3.layout
      .force()
      .size([this.width, this.height])
      .on('tick', () => this.tick());

    this.canvasGroup = d3
      .select(this.graphContainerElement)
      .append('svg')
      .attr('id', 'network-vis-svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .call(this.zoomBehaviour)
      .append('g');

    this.canvasGroup
      .append('rect')
      .attr('class', 'network-pan-overlay')
      .attr('width', this.width)
      .attr('height', this.height);

    this.links = this.canvasGroup.selectAll('.link');
    this.nodes = this.canvasGroup.selectAll('.network-node');

    this.layout.linkDistance(this.height / 6);
    this.layout.gravity(0.9);
    this.layout.charge(-2500);
  }

  ngOnChanges(changes) {
    let prevChange = changes.config.previousValue ? changes.config.previousValue.entityId : undefined;

    if (changes.config && changes.config.currentValue.entityId !== prevChange) {
      const entityId = this.config.entityId;
      const entityType = this.config.entityType;

      this.networkService
        .graph(entityId, entityType)
        .then((graph) => {
          this.nodeCount = String(graph.nodes.length - 1);
          this.centreRootNode(graph);

          this.layout.nodes(graph.nodes).links(graph.links);

          this.link = this.links
            .data(graph.links)
            .enter()
            .insert('line', '.network-node')
            .attr('class', 'link')
            .style('fill', 'none')
            .style('stroke', '#ccc')
            .style('stroke-width', '1.5px');

          this.node = this.nodes
            .data(graph.nodes)
            .enter()
            .append('g')
            .on('click', this.onNodeClicked(graph))
            .on('mouseover', (entity) => this.showCallOut(entity))
            .on('mouseout', () => this.hideCallOut())
            .attr('class', (entity, index) => this.classesForEntity(entity, index))
            .call(this.dragBehaviour());

          this.nodeBox = this.node.append('rect').attr('class', 'network-node-box').attr('rx', 3).attr('ry', 3);

          this.text = this.node.append('text').attr('text-anchor', 'middle').attr('class', 'node-text-box');

          this.text.append('tspan').attr('class', 'network-node-icon').text(iconFor);

          this.text.append('tspan').attr('class', 'network-node-label').attr('dx', 5).text(labelForEntity);

          this.text.append('tspan').attr('class', 'network-node-alert').attr('dx', 5).text(alertFor);

          this.makeNodeBox(this.nodeBox);

          this.layout.on('end', () => {
            this.autoZoomToFit(this.canvasGroup, this.zoomBehaviour, () => {
              this.hideBuildingOverlay();
              this.layout.on('end', null);
            });
          });

          this.layout.start();
        })
        .catch((error) => {
          console.log(error);

          if (error.status === 0 && error.statusText === 'Unknown Error') {
            this.canRenderGraph = false;
          }
        });
    }
  }

  onZoom() {
    this.layout.stop();
    this.canvasGroup.attr('transform', 'translate(' + d3.event.translate + ')' + ' scale(' + d3.event.scale + ')');
  }

  centreRootNode(graph) {
    const root = graph.nodes[0];

    root.x = this.width / 2;
    root.y = this.height / 2;
    root.fixed = true;
  }

  onNodeClicked(graph) {
    return (entity) => {
      if (!d3.event.defaultPrevented) {
        this.onEntitySelected(graph.nodes[0], entity);
      }
    };
  }

  onEntitySelected(parent, entity) {
    if (entity.hasProfile) {
      this.router.navigate([this.urlFor(parent, entity)]);
    }
  }

  urlFor(parent, entity) {
    const parentId = (parent || {}).entityId || entity.entityId,
      entityType = entity.entityType === 'Property' ? 'location' : entity.entityType;

    return '/overview/:parentId/:entityType/:entityId'
      .replace(/:parentId/, parentId)
      .replace(/:entityId/, entity.entityId)
      .replace(/:entityType/, entityType.toLowerCase());
  }

  showCallOut(entity) {
    const leftPadding = 40,
      topPadding = 20,
      mouse = d3.mouse(this.graphContainerElement.querySelector('svg'));

    const networkCallout = this.graphContainerElement.querySelector('.network-call-out');

    networkCallout.style.display = '';
    networkCallout.style.left = `${mouse[0] + leftPadding}px`;
    networkCallout.style.top = `${mouse[1] + topPadding}px`;

    this.tombstone = entity.tombstone;
    this.canShowAlternativeNames = entity.entityType.toLowerCase() === 'person';
  }

  hideCallOut() {
    this.graphContainerElement.querySelector('.network-call-out').style.display = 'none';
  }

  classesForEntity(entity, index) {
    const clazz = [
      'network-node',
      'network-entity-' + entity.entityId,
      'network-node-' + entity.entityType.toLowerCase(),
    ];

    if (index === 0) {
      clazz.push('network-node-root');
    }

    if (entity.hasProfile) {
      clazz.push('network-node-has-profile');
    }

    return clazz.join(' ');
  }

  dragBehaviour() {
    const drag = d3.behavior.drag();

    drag.on('dragstart', () => {
      this.layout.stop();
      d3.event.sourceEvent.stopPropagation();
    });

    drag.on('drag', (d) => {
      d.px += d3.event.dx;
      d.py += d3.event.dy;
      d.x += d3.event.dx;
      d.y += d3.event.dy;
      this.tick();
    });

    drag.on('dragend', (d) => {
      d.fixed = true;
      this.tick();
      this.layout.resume();
    });

    return drag;
  }

  tick() {
    this.link
      .transition()
      .ease('linear')
      .attr('x1', function (d) {
        return d.source.x;
      })
      .attr('y1', function (d) {
        return d.source.y;
      })
      .attr('x2', function (d) {
        return d.target.x;
      })
      .attr('y2', function (d) {
        return d.target.y;
      });

    this.node
      .transition()
      .ease('linear')
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });
  }

  makeNodeBox(rect) {
    const offset = 4;
    const padding = 15;

    rect
      .attr('width', function calcWidthFromTextNode() {
        return d3.select(this.nextSibling).node().getBBox().width + padding;
      })
      .attr('height', function calcHeightFromTextNode() {
        return d3.select(this.nextSibling).node().getBBox().height;
      })
      .attr('x', function calcHorizontalPos() {
        return -(d3.select(this).node().getBBox().width / 2);
      })
      .attr('y', function calVerticalPos() {
        return -(d3.select(this).node().getBBox().height / 2 + offset);
      });
  }

  autoZoomToFit(rootElement, zoomBehaviour, done) {
    const bounds = rootElement.node().getBBox();

    const parent = rootElement.node().parentNode.getBoundingClientRect();
    const parentWidth = parent.width;
    const parentHeight = parent.height;

    const midX = bounds.x + bounds.width / 2;
    const midY = bounds.y + bounds.height / 2;
    const scale = 0.9 / Math.max(bounds.width / parentWidth, bounds.height / parentHeight);

    const translate = [parentWidth / 2 - scale * midX, parentHeight / 2 - scale * midY];

    if (bounds.width === 0 || bounds.height === 0) {
      return;
    }

    zoomBehaviour.scaleExtent([scale, 8]);

    rootElement
      .transition()
      .ease('linear')
      .duration(1000)
      .call(zoomBehaviour.translate(translate).scale(scale).event)
      .each('end', done);
  }

  hideBuildingOverlay() {
    this.graphContainerElement.classList.remove('network-vis-building');
  }
}
