<div
  id="main-profile"
  class="main-profile"
  exg-scroll-adjust
  [sections]="profile.sections"
  [scrollOffset]="130"
  [selector]="'.discovery-group'">
  <ddiq-accordion-top label="Adjudication Summary" *ngIf="displayAdjudicationSummary" open>
    <adjudication-summary
      *ngIf="adjudicationSummarySpec && adjudicationSummarySpec.length"
      [summarize]="adjudicationSummarySpec">
    </adjudication-summary>

    <div class="alert alert-warning clearfix" *ngIf="!(adjudicationSummarySpec && adjudicationSummarySpec.length)">
      <div class="alert-icon">
        <span class="fa fa-exclamation-circle fa-2x"></span>
      </div>

      <p>The Adjudication Summary is misconfigured. Please contact your administrator.</p>
    </div>
  </ddiq-accordion-top>

  <div *ngIf="!isScreeningEnabled">
    <ddiq-profile-refresh-version-info></ddiq-profile-refresh-version-info>
  </div>

  <ddiq-expand-all-warning [profile]="profile"></ddiq-expand-all-warning>

  <ddiq-monitor-link></ddiq-monitor-link>

  <ddiq-adjudication-unavailable-alert [entity]="entity"></ddiq-adjudication-unavailable-alert>

  <!-- sections are guaranteed not to load until EntityCtrl has finished fetching the entity -->
  <ddiq-diff-header></ddiq-diff-header>

  <div *ngFor="let section of profile.sections; let $index = index">
    <div
      [ngClass]="{'page-break-before': ((section.metadata.breakBefore && !(isDeltaReport && $index == 2)) || (entity.isDiff() && $index > 1))} ">
      <div [ngSwitch]="section.metadata.sectionId">
        <ng-container *ngSwitchCase="'breadcrumb'">
          <ddiq-breadcrumb></ddiq-breadcrumb>
        </ng-container>
        <ng-container *ngSwitchCase="'address'">
          <ddiq-address [section]="section"></ddiq-address>
        </ng-container>
        <ng-container *ngSwitchCase="'map'">
          <ddiq-map [section]="section"></ddiq-map>
        </ng-container>
        <ng-container *ngSwitchCase="'basicInfo'">
          <ddiq-person-basic-info [section]="section" [isExpandAllGroups]="isExpandAllGroups"></ddiq-person-basic-info>
        </ng-container>
        <ng-container *ngSwitchCase="'corporate'">
          <ddiq-corporate-info [section]="section" [isExpandAllGroups]="isExpandAllGroups"></ddiq-corporate-info>
        </ng-container>
        <ng-container *ngSwitchCase="'regulatory'">
          <ng-container *ngIf="section.metadata.entityType==='person'">
            <ddiq-person-regulatory
              [section]="section"
              [isExpandAllGroups]="isExpandAllGroups"></ddiq-person-regulatory>
          </ng-container>
          <ng-container *ngIf="section.metadata.entityType==='company'">
            <ddiq-company-regulatory
              [section]="section"
              [isExpandAllGroups]="isExpandAllGroups"></ddiq-company-regulatory>
          </ng-container>
          <ng-container *ngIf="section.metadata.entityType==='property'">
            <ddiq-location-regulatory
              [section]="section"
              [isExpandAllGroups]="isExpandAllGroups"></ddiq-location-regulatory>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'adverse'">
          <ddiq-adverse-event [section]="section" [isExpandAllGroups]="isExpandAllGroups"></ddiq-adverse-event>
        </ng-container>
        <ng-container *ngSwitchCase="'legal'">
          <ng-container *ngIf="section.metadata.entityType==='person'">
            <ddiq-legal-person [section]="section" [isExpandAllGroups]="isExpandAllGroups"></ddiq-legal-person>
          </ng-container>
          <ng-container *ngIf="section.metadata.entityType==='company'">
            <ddiq-legal-company [section]="section" [isExpandAllGroups]="isExpandAllGroups"></ddiq-legal-company>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'noteworthy'">
          <ddiq-noteworthy-event [section]="section" [isExpandAllGroups]="isExpandAllGroups"></ddiq-noteworthy-event>
        </ng-container>
        <ng-container *ngSwitchCase="'references'">
          <ng-container *ngIf="section.metadata.entityType==='person'">
            <ddiq-person-references
              [section]="section"
              [isExpandAllGroups]="isExpandAllGroups"></ddiq-person-references>
          </ng-container>
          <ng-container *ngIf="section.metadata.entityType==='company'">
            <ddiq-company-references
              [section]="section"
              [isExpandAllGroups]="isExpandAllGroups"></ddiq-company-references>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
