import { Component, Inject, Input, OnInit } from '@angular/core';
import { OrderByPipe } from 'ngx-pipes';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import AlertService from '../common-services/alert.service';
import CheckContentService from '../common-services/check-content.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import SectionConfigService from '../common-services/section-config.service';
import NullsToEndPipe from '../ddiq-filters/nulls-to-end.pipe';
import Monitor from '../monitor/monitor.service';
import AlertSummarizer from '../themed-entity/alert-summarizer.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';

@Component({
  selector: 'ddiq-regulatory-base',
  template: ``,
})
export abstract class DdiqRegulatoryBaseComponent implements OnInit {
  entityId;
  entityType;
  hideRegulatoryDatabases;
  oshaInRegulatorySection;
  showGovProcurement;
  previewingEntity;
  isDeltaReport;
  showOshaSafety;
  contentCounter;
  watchLists;
  regulatoryAlerts;
  alertCount;
  @Input() section;
  @Input() isExpandAllGroups;
  hasFoundGovernmentAwards;

  constructor(
    protected incidentsUtils: IncidentsUtils,
    protected structuredDataUtils: StructuredDataUtils,
    protected alertSummarizer: AlertSummarizer,
    protected alertService: AlertService,
    protected entity: Entity,
    protected checkContentService: CheckContentService,
    protected oiqProperties: OiqProperties,
    protected monitor: Monitor,
    protected sectionConfigService: SectionConfigService,
    protected messageBusService: MessageBusService,
    protected nullsToEnd: NullsToEndPipe,
    protected orderByPipe: OrderByPipe
  ) {}

  ngOnInit(): void {
    this.entityId = this.entity.getId();
    this.entityType = this.entity.getType();
    this.hideRegulatoryDatabases = this.oiqProperties.hideRegulatoryDatabases;
    this.oshaInRegulatorySection = this.oiqProperties.oshaInRegulatorySection;
    this.showGovProcurement = this.entity.wasSearchedForGovernmentAwards();
    this.previewingEntity = this.entity.isInPreview();
    this.isDeltaReport = this.monitor.isDeltaReport();

    this.showOshaSafety = this.oshaInRegulatorySection && this.hasSafetyContent();

    this.contentCounter = {
      _incidentsCount: this.incidentsUtils.observedCount('regulatory'),
      _regulatory: this.structuredDataUtils.observedCount('regulatory'),

      filtered: function () {
        return this._incidentsCount.filtered + this._regulatory.filtered;
      },
      unfiltered: function () {
        return this._incidentsCount.unfiltered + this._regulatory.unfiltered;
      },
      filteredEscalated: function () {
        return this._incidentsCount.filteredEscalated + this._regulatory.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._incidentsCount.unfilteredEscalated + this._regulatory.unfilteredEscalated;
      },
    };

    this.watchLists = this.section.data.watchLists;

    if (this.isDeltaReport) {
      const watchListArr = Object.keys(this.section.data.watchLists).map((key) => {
        return this.section.data.watchLists[key];
      });
      this.watchLists = this.nullsToEnd.transform(
        this.orderByPipe.transform(watchListArr, '-oiqCreatedDate'),
        'oiqCreatedDate'
      );
    }

    // these are exposed on the scope for use in the section header
    this.regulatoryAlerts = this.alertService.getThemedEntityAlerts(
      this.entity.getType(),
      this.entity.getAlerts(),
      this.section.metadata.sectionId
    );
    this.alertCount = countAlerts(this.regulatoryAlerts);

    // and here they're added to the AlertSummarizer for the print header
    this.alertSummarizer.addAlertCount(this.section.metadata.sectionId, this.alertCount);
    this.alertSummarizer.addAlertList(this.section.metadata.sectionId, this.regulatoryAlerts);

    this.hasFoundGovernmentAwards = this.entity.hasFoundGovernmentAwards();
  }

  hasSafetyContent() {
    return (
      this.checkContentService.checkForContent(this.section.data.safety, true) ||
      this.checkContentService.checkForContent(this.section.data.oshaSafetyInspections, true)
    );
  }

  regulatoryHasContent() {
    if (this.hasFoundGovernmentAwards) {
      return true;
    }

    if (!this.oshaInRegulatorySection && this.section.data.hasOwnProperty('oshaSafetyInspections')) {
      return (
        this.checkContentService.checkForContent(this.section.data.watchLists, true) ||
        this.checkContentService.checkForContent(this.section.data.content, true) ||
        this.checkContentService.checkForContent(this.section.data.regulatoryProfiles, true)
      );
    }

    return this.checkContentService.checkForContent(this.section.data, true);
  }

  getSubSections(sectionId) {
    return this.sectionConfigService.getSubSections(this.entityType, sectionId);
  }

  sortDiscoveries(eventGroupKind, sortName, eventGroup) {
    this.messageBusService.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind,
        sortName,
        eventGroup,
      },
    });
  }
}

/**
 * returns an alert count for this section.
 *
 * regulatory section includes watchlist count
 *
 * @param alerts - usually $scope.section.data
 * @returns {number}
 */
function countAlerts(alerts) {
  let count = 0;

  if (alerts !== undefined) {
    count += alerts.length;
  }

  return count;
}
