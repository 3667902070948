import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';

var $ = jQuery;

@Component({
  selector: 'ddiq-date',
  templateUrl: './ddiq-date.component.tpl.html',
})
export default class DdiqDateComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() model;
  @Input() label;
  @Input() labelPattern;
  @Input() datePattern;
  @Input() options;
  @Input() oiqTextDisabled;
  @Input() inputId;
  @Output() modelChange = new EventEmitter<any>();
  @Input() size;
  @Input() required;

  previousValue;
  isRequired;

  private widget!: any;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.isRequired = typeof this.required !== 'undefined';
  }

  ngAfterViewInit(): void {
    this.loadDatePicker();
  }

  dateChange(date) {
    this.modelChange.emit(date);
  }

  loadDatePicker() {
    this.widget = $('input', this.elementRef.nativeElement)
      .datepicker({
        dateFormat: this.datePattern,
        firstDay: 1,
        minDate: this.options.minDate,
        maxDate: this.options.maxDate,
      })
      .on('change', () => {
        const date = this.widget.datepicker('getDate');
        if (date !== this.previousValue) {
          this.previousValue = date;
          this.dateChange(date);
        }
      });
    this.widget.datepicker('setDate', this.model);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.widget && changes.model) {
      this.widget.datepicker('setDate', changes.model.currentValue);
    }
  }
}
