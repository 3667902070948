import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import IntegrityComponent from './ddiq-integrity.component';
import IntegrityResolver from './integrity-resolver.service';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import DateFormatPipe from '../ddiq-filters/date-format.pipe';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';

@NgModule({
  imports: [CommonModule, PipesModule, NgPipesModule, ThemedEntityModule, DdiqUIModule],
  declarations: [IntegrityComponent],
  providers: [DatePipe, TranslatefPipe, DateFormatPipe, IntegrityResolver],
})
export class IntegrityModule {}
