import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ddiq-audit-section-detail',
  templateUrl: './ddiq-audit-section-detail.component.tpl.html',
})
export default class AuditSectionDetailComponent {
  @Input() data;
  @Input() noWebContent;

  isForPrint: any;

  constructor(private route: ActivatedRoute) {
    this.isForPrint = route.snapshot.paramMap.get('print');
  }
}
