import { PipeTransform, Pipe } from '@angular/core';
import Theme from './theme.service';

@Pipe({
  name: 'oiqThemePageHref',
})
export default class OiqThemePageHrefPipe implements PipeTransform {
  constructor(private theme: Theme) {}

  transform(pageName, defaultHref): any {
    return this.theme.getPageHrefFor(pageName) || defaultHref;
  }
}
