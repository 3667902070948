import { Component, Input, Optional } from '@angular/core';
import ModalTrackingService from '../modal/modal-tracking.service';
import OiqCollapsibleCtrlDirective from '../ui/oiq-collapsible-ctrl.directive';
import DynamicAdjudicationService from './dynamic-adjudication.service';

@Component({
  selector: 'oiq-group-dynamic-adjudication-aware',
  templateUrl: './ddiq-dynamic-group-adjudication-aware.component.tpl.html',
})
export default class OiqGroupDynamicAdjudicationAware {
  @Input() adjudicationType: string;
  @Input() section: any;
  @Input() classification: string;

  constructor(
    private dynamicAdjudicationService: DynamicAdjudicationService,
    private modalTrackingService: ModalTrackingService,
    @Optional() private oiqCollapsibleCtrl: OiqCollapsibleCtrlDirective
  ) {}

  selectAllVisible() {
    if (this.dynamicAdjudicationService.selectItemsOfType(this.adjudicationType, this.section, this.classification)) {
      this.modalTrackingService.openError('dynamicGroupAdjudicationWarning');
    }
    if (this.oiqCollapsibleCtrl) {
      this.oiqCollapsibleCtrl.open();
    }
  }

  dynamicAdjudicationActive() {
    return this.dynamicAdjudicationService.isDynamicAdjudicationActive();
  }

  getDescription() {
    var mode = this.dynamicAdjudicationService.getMode();
    if (mode === 'ESCALATE') {
      return ', non-starred items';
    } else if (mode === 'REMOVE') {
      return ', non-adjudicated items';
    }
    return ' items';
  }
}
