<table class="oiq-adjudication-summary">
  <thead>
    <th>Category</th>
    <th>Total Hits</th>
    <th>Auto-Adjudicated</th>
    <th>Manually Adjudicated</th>
    <th *ngIf="isConfirmEnabled()">Confirmed</th>
    <th>Starred</th>
    <th>Hits Remaining</th>
    <th>Filtered</th>
  </thead>
  <tbody>
    <tr *ngFor="let stat of stats" class="oiq-adjudication-summary-row">
      <td class="oiq-adjudication-summary-classification">{{stat.classification | translatef}}</td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-total-hits-stat">{{stat.totalHits}}</td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-stat-auto-adjudicated-count">
        {{stat.autoAdjudicatedCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-stat-manually-adjudicated-count">
        {{stat.manuallyAdjudicatedCount}}
      </td>
      <td
        *ngIf="isConfirmEnabled()"
        class="oiq-adjudication-summary-stat oiq-adjudication-summary-stat-confirmed-count">
        {{stat.confirmedCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-stat-escalated-count">
        {{stat.escalatedCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-stat-remaining-count">
        {{stat.remainingCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-stat-filtered-count">{{stat.filteredCount}}</td>
    </tr>
    <tr class="oiq-adjudication-summary-totals">
      <th>Totals</th>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-total-hits">{{totals.totalHits}}</td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-auto-adjudicated-count">
        {{totals.totalAutoAdjudicatedCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-manually-adjudicated-count">
        {{totals.totalManuallyAdjudicatedCount}}
      </td>
      <td
        *ngIf="isConfirmEnabled()"
        class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-confirmed-count">
        {{totals.totalConfirmedCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-escalated-count">
        {{totals.totalEscalatedCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-remaining-count">
        {{totals.totalRemainingCount}}
      </td>
      <td class="oiq-adjudication-summary-stat oiq-adjudication-summary-totals-filtered-count">
        {{totals.totalFilteredCount}}
      </td>
    </tr>
  </tbody>
</table>
