import { Injectable } from '@angular/core';
import Filter from './filter.service';

function isExcludedAdjudication(excludeAdjudicated) {
  return function (entity) {
    return excludeAdjudicated && entity.relationship.disabled;
  };
}

function isExcludedNonEscalated(escalatedOnly) {
  return function (entity) {
    return escalatedOnly && !entity.relationship.escalated;
  };
}

function isExcludedNonConfirmed(confirmedOnly) {
  return function (entity) {
    return confirmedOnly && !entity.relationship.confirmed;
  };
}

function isExcludeNonAdjudicated(excludeNonAdjudicated) {
  return function (entity) {
    return excludeNonAdjudicated && !entity.relationship.disabled;
  };
}

function isExcludeConfirmed(excludeConfirmed) {
  return function (entity) {
    return excludeConfirmed && entity.relationship.confirmed;
  };
}

function isExcludeEscalated(excludeEscalated) {
  return function (entity) {
    return excludeEscalated && entity.relationship.escalated;
  };
}

function getFilters(Filter) {
  const criteria = Filter.getFilter();
  return [
    isExcludedAdjudication(criteria.excludeAdjudicated),
    isExcludedNonEscalated(criteria.escalatedOnly),
    isExcludedNonConfirmed(criteria.confirmedOnly),
    isExcludeNonAdjudicated(criteria.adjudicatedOnly),
    isExcludeConfirmed(criteria.hideConfirmed),
    isExcludeEscalated(criteria.hideEscalated),
  ];
}

@Injectable({
  providedIn: 'root',
})
export default class RelationshipFilter {
  constructor(private filter: Filter) {}

  applyFilters(entity) {
    entity.filtered = false;

    for (const filter of getFilters(this.filter)) {
      const isFiltered = filter(entity);
      if (isFiltered) {
        entity.filtered = true;
        break;
      }
    }
  }

  registerAfterFilterObserver(observer) {
    this.filter.registerAfterFilterObserver(observer);
  }

  unRegisterAfterFilterObserver(observer) {
    this.filter.unRegisterFilterObservers(undefined, observer);
  }
}
