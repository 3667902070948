import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ddiq-monitor-event-filter',
  templateUrl: './ddiq-monitor-event-filter.component.tpl.html',
})
export default class MonitorEventFilter implements OnInit, OnDestroy {
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  @Output() onClicked = new EventEmitter<any>();
  @Input() events: Array<{ key: string; name: string }>;
  @Input() noResultMsg: string = 'None discovered';
  clearFilters: Subscription;
  eventNameSearch: string;
  displayLimit: number = 10;
  eventModel: Array<string> = [];

  constructor(private messageBusService: MessageBusService) {}

  ngOnInit(): void {
    this.clearFilters = this.messageBusService.on('clear-monitor-filters', () => {
      this.clear();
    });
  }

  ngOnDestroy() {
    this.clearFilters.unsubscribe();
  }

  private clear() {
    this.eventNameSearch = '';

    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });

    this.eventModel.length = 0;
  }

  onClick(e): void {
    const { value: eventKey, checked: isChecked } = e.target;

    if (!isChecked && this.eventModel.includes(eventKey)) {
      this.eventModel = this.eventModel.filter((event) => event !== eventKey);
    } else {
      this.eventModel.push(eventKey);
    }

    this.onClicked.emit({ eventModel: this.eventModel });
  }
}
