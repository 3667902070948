<div class="input-data" *ngIf="seedData?.duns">
  <p>DUNS Number:</p>
  {{ seedData?.duns }}
</div>
<div class="input-data" *ngIf="hasAdditionalLocations()">
  <p>Additional Locations:</p>
  <span *ngFor="let location of seedData.otherLocations | additionalLocationSubmission">
    <p>
      <span>{{ location.address | address }}</span>
    </p>
  </span>
</div>
<div class="input-data" *ngIf="seedData?.formerNames?.length">
  <p>Former Names:</p>
  <div *ngFor="let formerName of seedData.formerNames">{{formerName}}</div>
</div>
<div class="input-data" *ngIf="seedData?.phoneNumbers?.length">
  <p>Phone Numbers:</p>
  <span *ngFor="let phoneNumber of seedData.phoneNumbers">
    {{ phoneNumber.number }} <span *ngIf="phoneNumber.label">({{ phoneNumber.label | translatef }})</span><br />
  </span>
</div>
<div class="input-data" *ngIf="seedData?.emailAddresses?.length">
  <p>Email Addresses:</p>
  <div *ngFor="let emailAddress of seedData.emailAddresses">{{emailAddress.email}}</div>
</div>
<div class="input-data" *ngIf="hasUserSubmittedPeople()">
  <p>People:</p>
  <span *ngFor="let person of seedData.people | submittedPeople">
    <div>{{ person.name }}</div>
  </span>
</div>
<div class="input-data" *ngIf="seedData?.companies?.length">
  <p>Related Companies:</p>
  <span *ngFor="let company of seedData?.companies">
    <span *ngIf="company.relationshipType">{{ company.relationshipType }} with</span> {{ company.name }}<br />
    <span *ngIf="company.mainAddress">{{ company.mainAddress | address }}</span><br />
  </span>
</div>
<div class="input-data" *ngIf="seedData?.organizationType">
  <p>Organization Type:</p>
  {{seedData.organizationType | translatef }}
</div>
<div class="input-data" *ngIf="seedData?.yearFounded">
  <p>Year Founded:</p>
  <span>{{ seedData.yearFounded }}</span>
</div>
<div class="input-data" *ngIf="seedData?.requestedLanguages?.length">
  <p>Languages:</p>
  <span *ngFor="let language of seedData?.requestedLanguages">
    <p>
      <span>{{ language | translatef }}</span>
    </p>
  </span>
</div>
<div class="input-data" *ngIf="hasParts()">
  <p>Parts:</p>
  <span *ngFor="let part of seedData?.parts; let last = last; trackBy:trackByIndex">
    <span>{{ part }}<span *ngIf="!last">,&nbsp;</span></span>
  </span>
</div>
<div class="input-data">
  <correlation-details [seedData]="seedData"></correlation-details>
</div>
