import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class AdjudicationRepository {
  private _adjudication = new BehaviorSubject<any>(undefined);

  get adjudication$() {
    return this._adjudication.asObservable();
  }
  public store(adjudication) {
    this._adjudication.next(adjudication);
  }

  public removeAdjudication() {
    this._adjudication.next(undefined);
  }
}
