import { Injectable } from '@angular/core';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import TranslateService from '../common-services/translate.service';
import ExclusionsService from '../exclusions/exclusions.service';
import IncidentExtractionStrategyFactoryService from '../incidents/incident-extraction-strategy-factory.service';
import ChainableIncidentFilter from './chainable-incident-filter.service';
import { Constant } from '../common/ddiq-constants';
import { ProfileHitIdService } from './profile-hit-id.service';

@Injectable({
  providedIn: 'root',
})
export default class IncidentsService {
  private masterIncidents = [];
  private filterObservers = [];
  byClassification = {};
  private incidents = {
    basicInfo: [],
    corporate: [],
    adverse: [],
    legal: [],
    noteworthy: [],
    other: [],
    regulatory: [],
  };

  constructor(
    private chainableIncidentFilter: ChainableIncidentFilter,
    private translateService: TranslateService,
    private incidentExtractionStrategyFactory: IncidentExtractionStrategyFactoryService,
    private exclusionsService: ExclusionsService,
    private adjudicationFeature: AdjudicationFeatureService,
    private profileHitId: ProfileHitIdService
  ) {}

  clear() {
    this.masterIncidents.length = 0;
    this.filterObservers.length = 0;
    this.byClassification = {};
    this.incidents.basicInfo.length = 0;
    this.incidents.corporate.length = 0;
    this.incidents.adverse.length = 0;
    this.incidents.legal.length = 0;
    this.incidents.noteworthy.length = 0;
    this.incidents.other.length = 0;
    this.incidents.regulatory.length = 0;
  }

  incidentsArrayApply(callback, sectionName) {
    let targetIncidents = sectionName ? this.incidents[sectionName] : this.masterIncidents;

    callback(targetIncidents);
  }

  // applies callback to each incident
  applyToIncidents(callback, sectionName = undefined) {
    let targetIncidents = sectionName ? this.incidents[sectionName] : this.masterIncidents;
    targetIncidents.forEach(callback);
  }

  // applies callback to each summary in each incident
  applyToSummaries(callback, sectionName = undefined) {
    let targetIncidents = sectionName ? this.incidents[sectionName] : this.masterIncidents;

    targetIncidents.forEach(function (incident) {
      incident.summary.forEach(callback);
    });
  }

  // callbacks receive the entire list of incidents as the callback parameter
  registerFilterObserver(callback) {
    this.filterObservers.push(callback);
  }

  filter(
    sectionName?,
    startDate?,
    endDate?,
    minAssociationBucket?,
    excludeAdjudicated?,
    escalatedOnly?,
    confirmedOnly?,
    hideConfirmed?,
    hideEscalated?,
    adjudicatedOnly?
  ) {
    let chainedFilter = this.createChainedFilter(
      this.chainableIncidentFilter.clear(),
      this.chainableIncidentFilter.isBeforeDate(startDate),
      this.chainableIncidentFilter.isAfterDate(endDate),
      this.chainableIncidentFilter.isBelowSummarizedAssociationBucket(minAssociationBucket),
      this.chainableIncidentFilter.isExcludedAdjudication(excludeAdjudicated),
      this.chainableIncidentFilter.isExcludeNonEscalated(escalatedOnly),
      this.chainableIncidentFilter.isExcludeNonConfirmed(confirmedOnly),
      this.chainableIncidentFilter.isHidingConfirmed(hideConfirmed),
      this.chainableIncidentFilter.isHidingEscalated(hideEscalated),
      this.chainableIncidentFilter.isExcludeNonAdjudicated(adjudicatedOnly)
    );

    this.applyToIncidents(chainedFilter, sectionName);

    this.notifyObservers();
  }

  notifyObservers() {
    this.filterObservers.forEach(function (observer) {
      observer();
    });
  }

  find(sectionId, classificationName) {
    let section, incidents;

    if (sectionId === 'references') {
      sectionId = 'other';
    }
    if (!classificationName) {
      incidents = this.incidents[sectionId];
    } else {
      section = this.byClassification[sectionId] || {};
      incidents = section[classificationName] || [];
    }

    return incidents;
  }

  get() {
    return this.masterIncidents;
  }

  init(entity, recordExclusions, webContentType?) {
    let extractionStrategy = this.incidentExtractionStrategyFactory.getStrategy(webContentType),
      sections = Object.keys(this.incidents);

    this.masterIncidents = [];
    this.filterObservers = [];

    extractionStrategy.extract(sections, entity, (section, incidents, classifiedIncidents) => {
      this.incidents[section] = incidents;

      Array.prototype.push.apply(this.masterIncidents, this.incidents[section]);

      this.normalizeIncidents(section, recordExclusions, webContentType || Constant.WEB_CONTENT_TYPE);

      if (classifiedIncidents) {
        this.byClassification[section] = this.byClassification[section] || {};
        this.byClassification[section] = classifiedIncidents;
      }
    });

    // Preprocessing for incidents

    // Add default classification to snippet if no classification
    this.applyToIncidents((incident) => {
      incident.evidence.forEach(this.addDefaultClassificationToSnippet);
    });

    this.normalizeSummaries();
  }

  normalizeIncidents(sectionName, recordExclusions, webContentType) {
    let normalizeIncident = (incident) => {
      // ensure at least 1 summary for each incident
      if (incident.summary.length === 0) {
        incident.summary.push({
          theme: 'other',
          label: 'OTHER_CONTENT',
          labelKey: 'OTHER_CONTENT',
          kind: 'incidents',
        });
      }

      if (recordExclusions) {
        this.exclusionsService.registerHit('WEB_CONTENT', incident.classification);
      }

      let adjudication = this.getAdjudication(webContentType, incident);

      if (adjudication) {
        if (!incident.adjudication) {
          incident.adjudication = adjudication;
        }

        if (this.adjudicationFeature.isConfirmActionEnabled()) {
          incident.confirmed = adjudication.confirmed;
        }

        incident.disabled = adjudication.adjudicated;
        incident.escalated = adjudication.escalated;
        incident.isAutoAdjudicated = adjudication.auto;
      }

      incident.filterFields = { kind: 'incidents' };
      this.profileHitId.applyIdTo(incident);
    };

    this.applyToIncidents(normalizeIncident, sectionName);
  }

  getAdjudication(webContentType, incident) {
    let adjudication;
    if (webContentType !== 'classification' && webContentType !== 'cluster') {
      incident.adjudicable = incident.summary.length === 1 && this.isIncidentLegacyAdjudicable(incident);
      adjudication = incident.adjudication;
    } else if (incident.summary && incident.summary[0] && incident.summary[0].adjudication) {
      adjudication = incident.summary[0].adjudication;
    }
    return adjudication;
  }

  // this function checks that each classification on an incident is the same in order for it to be legacy adjudicable
  isIncidentLegacyAdjudicable(incident) {
    let classificationLabel,
      legacyAdjudicable = true;
    incident.evidence.forEach(function (evidence) {
      evidence.classifications.forEach(function (classification) {
        if (!legacyAdjudicable) {
          return;
        } else if (!classificationLabel) {
          classificationLabel = classification.label;
        } else if (classificationLabel !== classification.label) {
          legacyAdjudicable = false;
        }
      });
    });
    return legacyAdjudicable;
  }

  // if a snippet has no classifications, create one
  addDefaultClassificationToSnippet(snippet) {
    if (snippet.classifications.length === 0) {
      snippet.classifications.push({
        label: 'OTHER_CONTENT',
        theme: 'other',
      });
    }
  }

  normalizeSummaries() {
    this.applyToSummaries((summary) => {
      // BLOG/NEWS don't have a theme
      summary.theme = summary.theme || 'other';
      summary.labelKey = summary.classificationLabelKey || summary.label;
      summary.label = this.translateService.translate(summary.labelKey);
      summary.kind = 'incidents';
    });
  }

  // takes a splat of callbacks as parameters
  // breaks as soon as one of the callbacks returns true
  createChainedFilter(...args) {
    let filters = [];

    // scans the incident filters and only returns the defined ones
    for (let i = 0; i < args.length; i++) {
      if (args[i]) {
        filters.push(args[i]);
      }
    }

    return function (obj) {
      // runs the incident through all the filters
      for (let i = 0; i < filters.length; i++) {
        if (filters[i](obj)) {
          obj.filtered = true;
          // stop when a single filter returns true
          return;
        }
      }
    };
  }
}
