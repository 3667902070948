import { Component, OnDestroy } from '@angular/core';
import DdiqProfileCollectionMissingPermissionForSubmissionService from './ddiq-profile-collection-missing-permission-for-submission.service';

@Component({
  selector: 'ddiq-profile-collection-missing-permission-for-submission',
  templateUrl: './ddiq-profile-collection-missing-permission-for-submission.tpl.html',
})
export default class DdiqProfileCollectionMissingPermissionForSubmissionComponent implements OnDestroy {
  constructor(
    private ddiqProfileCollectionMissingPermissionForSubmissionService: DdiqProfileCollectionMissingPermissionForSubmissionService
  ) {}

  ngOnDestroy() {
    this.ddiqProfileCollectionMissingPermissionForSubmissionService.cancel();
  }
}
