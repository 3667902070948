<div
  [id]="watchList.lookupId"
  class="oiq-watch-list"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': watchList.escalated, 'disabled': watchList.disabled, confirmed: watchList.confirmed}"
  data-oiq-collapsible-ctrl
  [expandAll]="watchList.isOpen">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="watchList"></oiq-dynamic-adjudication-aware>
    <watch-list-header
      [watchList]="watchList.watchlists && watchList.watchlists[0] || watchList"
      [moreSourcesCount]="watchList.watchlists?.length - 1 || 0">
    </watch-list-header>
    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <watch-list-detail
          class="oiq-watch-list-primary-watch-list"
          [watchList]="watchList.watchlists && watchList.watchlists[0] || watchList"
          [isOpen]="watchList.isOpen"
          [isDisplayClassifications]="isDisplayClassifications">
          <ddiq-association [level]="watchList.associationBucket" class="oiq-watch-list-association">
          </ddiq-association>

          <watch-list-display-date
            [watchList]="watchList.watchlists && watchList.watchlists[0] || watchList"
            [displayCreatedDate]="displayCreatedDate">
          </watch-list-display-date>

          <ddiq-adjudication-actions
            *ngIf="isAdjudicateable && !entityAware.isPreview"
            [type]="adjudicationType"
            [entityType]="entityType"
            [entityId]="entityId"
            [noReassess]="noReassess"
            [targetId]="watchList.id"
            [model]="watchList">
          </ddiq-adjudication-actions>
        </watch-list-detail>
      </div>
    </div>
    <div class="oiq-watch-list-other-sources" *ngIf="watchList.watchlists?.length > 1">
      <ddiq-collapsible>
        <div
          *ngFor="let otherSourceWatchList of watchList.watchlists | slice:1:watchList.watchlists.length;  trackBy: id">
          <div class="oiq-grid">
            <div class="oiq-grid-row">
              <watch-list-header
                class="oiq-watch-list-other-watch-list-header oiq-grid-cell"
                [watchList]="otherSourceWatchList"></watch-list-header>
            </div>
          </div>
          <div class="oiq-grid">
            <div class="oiq-grid-row">
              <watch-list-detail
                class="oiq-watch-list-other-watch-list"
                [watchList]="otherSourceWatchList"
                [isDisplayClassifications]="isDisplayClassifications">
                <ddiq-association [level]="otherSourceWatchList.associationBucket" class="oiq-watch-list-association">
                </ddiq-association>
                <watch-list-display-date [watchList]="otherSourceWatchList" [displayCreatedDate]="displayCreatedDate">
                </watch-list-display-date>
              </watch-list-detail>
            </div>
          </div>
        </div>
      </ddiq-collapsible>
    </div>
    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-watch-list-summary oiq-grid-cell"></div>
        <div class="oiq-watch-list-bd oiq-grid-cell">
          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="adjudicationType"
            [entityType]="entityType"
            [entityId]="entityId"
            [noReassess]="noReassess"
            [targetId]="watchList.id"
            [model]="watchList"
            [label]="'Notes'"
            [active]="watchList.comment">
          </ddiq-adjudication-notes>
        </div>
        <div class="oiq-watch-list-toggle oiq-grid-cell"></div>
      </div>
    </div>
  </div>
</div>
