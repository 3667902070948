import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'incorporatedInLocationSubmission',
})
export default class IncorporatedInLocationSubmissionPipe implements PipeTransform {
  transform(locations: any) {
    return (locations || []).filter(function (location) {
      return location && location.source === 'AdditionalLocationSubmission' && location.label === 'INCORPORATED_IN';
    });
  }
}
