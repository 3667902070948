<div>
  <div class="login-panel">
    <div class="brand">
      <img data-theme-logo="large" alt="DDIQ - Where all due diligence begins" />
      <span class="brand-sub-text">Powered by DDIQ</span>
    </div>
    <section>
      <h2 class="text-center">There has been an error in authentication</h2>
      <div class="text-center">Please contact your system administrator for more details.</div>
    </section>
  </div>
</div>
