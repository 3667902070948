import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import UserService from '../user/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export default class MonitorSearchService {
  previousQuery = {
    q: undefined,
    id: undefined,
    owner: undefined,
    govId: undefined,
    after: undefined,
    before: undefined,
    hOrder: undefined,
    hDesc: undefined,
    cOrder: undefined,
    cDesc: undefined,
    rOrder: undefined,
    rDesc: undefined,
  };
  private registeredSearches = []; // functions: searches created by entity specific search controllers
  private setPaginations = []; // functions: set pagination without firing a new search
  private readonly dateValidationPattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  private datePattern = 'yy-mm-dd';
  searchSettings: undefined;

  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private errorHandler: ErrorHandler,
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (oiqProperties.dateFormat === 'US') {
      this.datePattern = 'mm/dd/yy';
    }

    apiFetchService.setMarkup(oiqProperties.markup);
  }

  go(query, dates, isResetPagination?) {
    let INITIAL_SEARCH_PAGE = 1,
      i,
      validatedSubmission = {
        q: undefined,
        id: undefined,
        owner: undefined,
        govId: undefined,
        after: undefined,
        before: undefined,
        hOrder: undefined,
        hDesc: undefined,
        cOrder: undefined,
        cDesc: undefined,
        rOrder: undefined,
        rDesc: undefined,
      };

    // create query object for query functions
    validatedSubmission.q = query.q || null;
    validatedSubmission.id = query.id || null;
    validatedSubmission.owner = query.owner || null;
    validatedSubmission.govId = query.govId || null;
    if (dates.after) {
      let convertedDate = new Date(dates.after);

      validatedSubmission.after = jQuery.datepicker.formatDate('yy-mm-dd', convertedDate);
    } else {
      validatedSubmission.after = null;
    }
    if (dates.before) {
      let convertedDate = new Date(dates.before);

      validatedSubmission.before = jQuery.datepicker.formatDate('yy-mm-dd', convertedDate);
    } else {
      validatedSubmission.before = null;
    }
    validatedSubmission.hOrder = query.hOrder;
    validatedSubmission.hDesc = query.hDesc;
    validatedSubmission.cOrder = query.cOrder;
    validatedSubmission.cDesc = query.cDesc;
    validatedSubmission.rOrder = query.rOrder;
    validatedSubmission.rDesc = query.rDesc;

    // push params to the url
    this.router.navigate([], {
      queryParams: {
        q: validatedSubmission.q,
        id: validatedSubmission.id,
        owner: validatedSubmission.owner,
        govId: validatedSubmission.govId,
        afterTime: validatedSubmission.after,
        beforeTime: validatedSubmission.before,
        hOrder: validatedSubmission.hOrder,
        hDesc: validatedSubmission.hDesc,
        cOrder: validatedSubmission.cOrder,
        cDesc: validatedSubmission.cDesc,
        rOrder: validatedSubmission.rOrder,
        rDesc: validatedSubmission.rDesc,
      },
      queryParamsHandling: 'merge',
    });

    this.updatePreviousQuery(validatedSubmission);

    if (isResetPagination) {
      // need to reset pagination for all registered searches
      for (i = 0; i < this.setPaginations.length; i++) {
        this.setPaginations[i](INITIAL_SEARCH_PAGE, 'date', true);
      }
    }

    // call registered searches
    for (i = 0; i < this.registeredSearches.length; i++) {
      this.registeredSearches[i].search(validatedSubmission, 0);
    }
  }

  parseUrl() {
    let urlQuery = {
      q: undefined,
      id: undefined,
      owner: undefined,
      govId: undefined,
      after: undefined,
      before: undefined,
      hOrder: undefined,
      hDesc: undefined,
      cOrder: undefined,
      cDesc: undefined,
      rOrder: undefined,
      rDesc: undefined,
    };

    urlQuery.q = this.route.snapshot.queryParams.q || null;
    urlQuery.id = this.route.snapshot.queryParams.id || null;
    urlQuery.owner = this.route.snapshot.queryParams.owner || null;
    urlQuery.govId = this.route.snapshot.queryParams.govId || null;
    urlQuery.after = this.route.snapshot.queryParams.after || this.route.snapshot.queryParams.afterTime || null;
    urlQuery.before = this.route.snapshot.queryParams.before || this.route.snapshot.queryParams.beforeTime || null;
    urlQuery.hOrder = this.route.snapshot.queryParams.hOrder;
    urlQuery.hDesc = this.route.snapshot.queryParams.hDesc;
    urlQuery.cOrder = this.route.snapshot.queryParams.cOrder;
    urlQuery.cDesc = this.route.snapshot.queryParams.cDesc;
    urlQuery.rOrder = this.route.snapshot.queryParams.rOrder;
    urlQuery.rDesc = this.route.snapshot.queryParams.rDesc;

    if (urlQuery.after && !urlQuery.after.match(this.dateValidationPattern)) {
      urlQuery.after = null;
    }

    if (urlQuery.before && !urlQuery.before.match(this.dateValidationPattern)) {
      urlQuery.before = null;
    }

    this.updatePreviousQuery(urlQuery);
  }

  createSearch(prefix, searchFn, pagination, results) {
    let router = this.router,
      user = this.user,
      errorHandler = this.errorHandler,
      callbacks = [],
      hooks = {
        registerCallback: registerCallback,
        search: search,
      };

    this.registeredSearches.push(hooks);

    return hooks;

    // register function(s) to invoke after search results return
    function registerCallback(callback) {
      callbacks.push(callback);
    }

    function search(query, start) {
      results.isLoading = true;
      results.reports = [];

      const count = results.visible;

      router.navigate([], {
        queryParams: {
          [prefix + 'Order']: pagination.orderBy,
          [prefix + 'Desc']: pagination.descending || null,
          q: query.q,
          id: query.id,
          owner: query.owner,
          govId: query.govId,
          after: query.after || null,
          before: query.before || null,
        },
        queryParamsHandling: 'merge',
      });

      searchFn(start, count, query, pagination)
        .then(searchSuccess, searchFail)
        .then(function (results) {
          // after retrieving data, iterate through callbacks
          var i;

          for (i = 0; i < callbacks.length; i++) {
            callbacks[i](results);
          }
        });

      function searchSuccess(data) {
        results.reports = data.results;
        results.total = data.totalMatchCount;

        results.reports.forEach(function (report) {
          report.oiqOwnerFullName = user.getVisibleUser(report.oiqOwner)?.fullName;
        });

        pagination.total = Math.ceil(results.total / results.visible);

        results.isLoading = false;
        return results;
      }

      function searchFail(response) {
        errorHandler.error('Search Failed: ', response);
      }
    }
  }

  updatePreviousQuery(query) {
    this.previousQuery.q = query.q;
    this.previousQuery.id = query.id;
    this.previousQuery.owner = query.owner;
    this.previousQuery.govId = query.govId;
    this.previousQuery.after = query.after || null;
    this.previousQuery.before = query.before || null;
    this.previousQuery.hOrder = query.hOrder;
    this.previousQuery.hDesc = query.hDesc;
    this.previousQuery.cOrder = query.cOrder;
    this.previousQuery.cDesc = query.cDesc;
    this.previousQuery.rOrder = query.rOrder;
    this.previousQuery.rDesc = query.rDesc;
  }

  addSetPagination(setPaginationFn) {
    this.setPaginations.push(setPaginationFn);
  }

  clearAll() {
    this.registeredSearches = [];
    this.setPaginations = [];
  }

  createMonitorHitSearchFunction(pagination, results) {
    const monitorHitSearchFn = (start, count, query, pagination) => {
      return this.apiFetchService.findMonitor(
        'open',
        start,
        count,
        query.q,
        query.id,
        query.owner,
        query.govId,
        query.after,
        query.before,
        pagination.orderBy,
        pagination.descending === true ? 'desc' : 'asc'
      );
    };
    return this.createSearch('h', monitorHitSearchFn, pagination, results);
  }

  createMonitorHitClosedFunction(pagination, results) {
    const monitorHitClosedFn = (start, count, query, pagination) => {
      return this.apiFetchService.findMonitor(
        'closed',
        start,
        count,
        query.q,
        query.id,
        query.owner,
        query.govId,
        query.after,
        query.before,
        pagination.orderBy,
        pagination.descending === true ? 'desc' : 'asc'
      );
    };
    return this.createSearch('c', monitorHitClosedFn, pagination, results);
  }

  createMonitorDailyReportFunction(pagination, results) {
    const monitorDailyReportFn = (start, count, query, pagination) => {
      return this.apiFetchService.findMonitorDailyReports(
        start,
        count,
        query.after,
        query.before,
        pagination.orderBy,
        pagination.descending === true ? 'desc' : 'asc'
      );
    };
    return this.createSearch('r', monitorDailyReportFn, pagination, results);
  }

  interleaveAndSort(entity) {
    const hits = [];

    function interleave(hitType, priority) {
      return function (hit) {
        hits.push({ type: hitType, date: hit.oiqCreatedDate.substring(0, 10), priority: priority, data: hit });
      };
    }

    entity.watchLists.forEach(interleave(entity.entityType + '_watchlist', 1));
    entity.regulatory.incidents.forEach(interleave('regulatory_incident', 2));
    entity.regulatoryProfiles.forEach(interleave('regulatory_profile', 3));
    entity.adverse.incidents.forEach(interleave('adverse_incident', 5));
    if (entity.websiteScorecards) {
      entity.websiteScorecards.forEach(interleave('adverse_websitescorecard', 6));
    }
    entity.legal.incidents.forEach(interleave('legal_incident', 7));
    entity.noteworthy.incidents.forEach(interleave('noteworthy_incident', 8));

    hits.sort(function (lhs, rhs) {
      if (lhs.date === rhs.date) {
        return lhs.priority - rhs.priority;
      }
      if (lhs.date > rhs.date) {
        return -1;
      } else if (lhs.date < rhs.date) {
        return 1;
      }
      return lhs.id - rhs.id;
    });

    return hits;
  }
}
