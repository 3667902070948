<div>
  <header *ngIf="!showEntityError">
    <div class="entity-header">
      <h1>{{entityName}}</h1>
    </div>
  </header>

  <section [ngSwitch]="showEntityError" class="network-vis-section">
    <div *ngSwitchCase="false">
      <ddiq-network-vis [config]="networkConfig"></ddiq-network-vis>
    </div>

    <p *ngSwitchCase="true" class="alert alert-danger">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <strong>Sorry</strong>, something went wrong and we couldn't complete your request. Please try again later.
    </p>
  </section>
</div>
