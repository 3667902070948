import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import DdiqProfileCollectionMissingPermissionForSubmissionComponent from './ddiq-profile-collection-missing-permission-for-submission.component';
import DdiqProfileCollectionMissingPermissionForSubmissionService from './ddiq-profile-collection-missing-permission-for-submission.service';

@NgModule({
  imports: [CommonModule],
  declarations: [DdiqProfileCollectionMissingPermissionForSubmissionComponent],
  providers: [DdiqProfileCollectionMissingPermissionForSubmissionService],
  exports: [DdiqProfileCollectionMissingPermissionForSubmissionComponent],
})
export class ProfileCollectionPermissionMissingForSubmissionModule {}
