import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ModalTrackingService {
  triggers = {
    pdf: {
      open: false,
    },
    diff: {
      open: false,
    },
    delegate: {
      open: false,
    },
    notify: {
      open: false,
    },
    scheduleRefresh: {
      open: false,
    },
    enableMonitoring: {
      open: false,
    },
    dynamicAdjudication: {
      open: false,
    },
    exclusionManagement: {
      open: false,
    },
    error: {
      open: false,
      template: '',
      errorInfo: undefined,
    },
    editCollections: {
      open: false,
    },
    buildProfilePermissionMissing: {
      open: false,
    },
    submittingCage: {
      open: false,
    },
  };

  openError(template, errorInfo?) {
    this.triggers.error = {
      open: true,
      template: template,
      errorInfo: errorInfo,
    };
  }

  getTriggers() {
    return this.triggers;
  }

  modalActive() {
    return (
      this.triggers.pdf.open ||
      this.triggers.diff.open ||
      this.triggers.scheduleRefresh.open ||
      this.triggers.enableMonitoring.open ||
      this.triggers.delegate.open ||
      this.triggers.notify.open ||
      this.triggers.dynamicAdjudication.open ||
      this.triggers.exclusionManagement.open ||
      this.triggers.error.open ||
      this.triggers.editCollections.open ||
      this.triggers.buildProfilePermissionMissing.open
    );
  }

  reset() {
    this.triggers = {
      pdf: {
        open: false,
      },
      diff: {
        open: false,
      },
      delegate: {
        open: false,
      },
      notify: {
        open: false,
      },
      scheduleRefresh: {
        open: false,
      },
      enableMonitoring: {
        open: false,
      },
      dynamicAdjudication: {
        open: false,
      },
      exclusionManagement: {
        open: false,
      },
      error: {
        open: false,
        template: '',
        errorInfo: undefined,
      },
      editCollections: {
        open: false,
      },
      buildProfilePermissionMissing: {
        open: false,
      },
      submittingCage: {
        open: false,
      },
    };
  }
}
