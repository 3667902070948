import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import EntityResolverHelperService from './entity-resolver-helper.service';

@Injectable({ providedIn: 'root' })
export default class ComparableProfileResolver implements Resolve<any> {
  constructor(private entityResolverHelperService: EntityResolverHelperService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let entityId = route.params.entityId;
    let entityType = route.params.entityType;
    let overviewId = route.params.overviewId;
    let comparableId = route.params.comparableId;

    return Promise.all([
      this.entityResolverHelperService.validateEntityRoute(overviewId, entityId, entityType),
      this.entityResolverHelperService.validateDiffRoute(comparableId),
      this.entityResolverHelperService.preLoadEntity(entityType),
    ]);
  }
}
