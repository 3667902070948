import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ExternalUrlMsgService {
  private warningMsg: string;
  private safeUrl: string;

  isExternalUrl(url: string): boolean {
    return !url.includes(this.safeUrl);
  }

  canDisplayWarningMsg(url: string): boolean {
    return this.isExternalUrl(url) && this.hasAdminConfigs();
  }

  getWarningMsg(): string {
    return this.warningMsg;
  }

  setWarningMsgConfigs(msg: { message; url }): void {
    this.warningMsg = msg.message;
    this.safeUrl = msg.url;
  }

  hasAdminConfigs(): boolean {
    return !!this.safeUrl && !!this.warningMsg;
  }
}
