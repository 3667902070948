<div class="oiq-collapsible">
  <div class="oiq-collapsible-toggle-container" *ngIf="shouldRenderBtn">
    <button class="oiq-collapsible-toggle" (click)="toggle()">
      <i class="oiq-collapsible-toggle-icon fa" [ngClass]="{'fa-chevron-down': isVisible, 'fa-chevron-up': !isVisible}">
      </i
      >{{toggleText}}
    </button>
  </div>
  <div class="oiq-collapsible-body" *ngIf="isVisible">
    <ng-content></ng-content>
  </div>
</div>
