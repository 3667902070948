<img *ngIf="results.isLoading" [src]="'./img/loader.gif'" class="monitor-loading-img" />

<p *ngIf="!isQueryEmpty" class="search-results">
  Your search <span *ngIf="lastQueryString">for <span>"{{lastQueryString}}"</span></span> has {{results.total}} results
  on {{pagination.total}} pages
</p>

<table class="results-table" *ngIf="results.reports.length">
  <tr>
    <th class="monitor-narrow top" (click)="changeSorting('date')">
      <ddiq-sortable-column column="date" [sort]="pagination"><span>Run Date</span></ddiq-sortable-column>
    </th>
    <th class="center">Details</th>
  </tr>
  <tr *ngFor="let result of results.reports">
    <td class="top">{{result.startDate | dateFormat}}</td>
    <td class="monitor-daily-details">
      <table>
        <tr>
          <th>Status:</th>
          <td [ngClass]="{success : result.status == 'SUCCESS', error : result.status == 'ERROR'}">
            {{(result.status == 'SUCCESS') && 'SUCCESS' || ('ERROR (' + result.profilesInError + ')') }}
          </td>
          <th>Start Time:</th>
          <td>{{result.startDate | date:'mediumTime'}}</td>
          <th>End Time:</th>
          <td>{{result.endDate | date:'mediumTime'}}</td>
          <th>Duration:</th>
          <td>{{result.duration | millisToTime}}</td>
        </tr>
        <tr>
          <th># Queries:</th>
          <td>{{result.numQueries}}</td>
          <th># URLs Fetched:</th>
          <td>{{result.numURLsFetched}}</td>
          <th># URLs Associated:</th>
          <td>{{result.numURLsAssociated}}</td>
          <th># URLs Classified:</th>
          <td>{{result.numURLsClassified}}</td>
        </tr>
        <tr>
          <th>Profiles Run:</th>
          <td>{{result.profilesIncluded}}</td>
          <th>Regulatory:</th>
          <td>{{result.REGULATORY}}</td>
          <th>Adverse:</th>
          <td>{{result.ADVERSE}}</td>
          <th>Legal:</th>
          <td>{{result.LEGAL}}</td>
        </tr>
        <tr>
          <th>Noteworthy:</th>
          <td>{{result.NOTEWORTHY}}</td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<exg-pagination
  [numPages]="pagination.total"
  [currentPage]="pagination.current"
  [paginationBarLength]="pagination.visible"
  [tableTotal]="results.total"
  (onSelectPage)="onSelectPage($event.page)"
  [totalLabel]="'profiles'">
</exg-pagination>
