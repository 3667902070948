import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-previous-address-form',
  templateUrl: './ddiq-previous-address-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class PreviousAddressFormComponent implements OnInit {
  @Input() previousAddresses = [];
  @Input() countries: Array<string>;
  @Input() districtEnabled: boolean;

  constructor() {}
  ngOnInit() {
    if (this.previousAddresses.length === 0 || Object.keys(this.previousAddresses[0]).length === 0) {
      //initialise empty address parent object
      this.previousAddresses[0] = {
        address: {},
      };
    }
  }

  removePreviousAddress(index) {
    this.previousAddresses.splice(index, 1);
  }

  addPreviousAddress() {
    let emptyPreviousAddressForm = {
      address: {},
    };
    this.previousAddresses.push(emptyPreviousAddressForm);
  }
}
