// @ts-nocheck
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class TokenService {
  private secretToken: string;

  constructor() {}

  public saveToken(token: string) {
    this.secretToken = token;
  }

  public getToken(): string {
    return this.secretToken;
  }
}
