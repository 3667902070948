import StructuredDataExtractionStrategyService from './structured-data-extraction-strategy.service';
import StructuredDataArrayExtractionStrategyService from './structured-data-array-extraction-strategy.service';
import StructuredDataByClassExtractionStrategyService from './structured-data-by-class-extraction-strategy.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],

  providers: [
    StructuredDataArrayExtractionStrategyService,
    StructuredDataByClassExtractionStrategyService,
    StructuredDataExtractionStrategyService,
  ],
})
export class OiqStructuredDataModule {}
