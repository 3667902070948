import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-legal-filing',
  templateUrl: './ddiq-legal-filing.component.tpl.html',
})
export default class DdiqLegalFilingComponent {
  @Input() filing;
  @Input() isAdjudicateable;
}
