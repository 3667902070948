import OiqProperties from '../common-services/oiq-properties.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export default class IntegrityResolver implements Resolve<OiqProperties> {
  constructor(private oiqProperties: OiqProperties) {}

  resolve(): Promise<OiqProperties> {
    return this.oiqProperties.apiPropertiesPromise;
  }
}
