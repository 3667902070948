import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class AdjudicationFeatureService {
  constructor(private oiqProperties: OiqProperties) {}

  isConfirmActionEnabled() {
    return !!(this.isAdjudicationEnabled() && this.oiqProperties.adjudicationConfirmActionEnabled);
  }

  isStarActionEnabled() {
    return !!(this.isAdjudicationEnabled() && this.oiqProperties.adjudicationStarActionEnabled);
  }

  getAdjudicationReasons(action, entityType) {
    var reasons = this.oiqProperties.adjudicationRemoveReasons;

    if (action === 'CONFIRM') {
      reasons = this.oiqProperties.adjudicationConfirmedReasons;
    } else if (action === 'ESCALATE') {
      reasons = this.oiqProperties.adjudicationEscalatedReasons;
    }

    if (!reasons) {
      return [];
    }

    return reasons[entityType] || reasons;
  }

  getHiddenAdjudicationActions() {
    return this.oiqProperties.hiddenAdjudicationActions;
  }

  isBulkAdjudicationEnabled() {
    return this.isAdjudicationEnabled() && this.oiqProperties.bulkAdjudicationEnabled;
  }

  isRelatedEntityAdjudicationEnabled() {
    return this.isAdjudicationEnabled() && this.oiqProperties.adjudicateRelatedEntitiesEnabled;
  }

  isAdjudicationEnabled() {
    return this.oiqProperties.adjudicationEnabled;
  }
}
