import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import ApiFetchService from '../common-services/api-fetch.service';
import ProgressChecker from '../common-services/progress-checker.service';
import { isEqual } from 'lodash';

@Component({
  selector: 'ddiq-recently-built-profiles',
  template: `
    <h3>Recently Built</h3>
    <ul>
      <li class="recently-built" *ngFor="let result of recentlyBuilt.results">
        <a href="{{ result.profileLink }}">{{ result.name }}</a>
      </li>
    </ul>
  `,
})
export default class RecentlyBuiltProfiles implements OnInit, DoCheck, OnDestroy {
  recentlyBuilt;
  previouslyBuiltEntitiesResult;

  constructor(
    private progressChecker: ProgressChecker,
    private apiFetchService: ApiFetchService,
    private urlGenerator: UrlGenerator
  ) {}

  ngOnInit() {
    this.recentlyBuilt = this.progressChecker.addProgressChecker(
      'recentlyBuilt',
      this.apiFetchService.recentlyBuilt,
      true
    );
    this.previouslyBuiltEntitiesResult = { ...this.recentlyBuilt };
  }

  ngDoCheck() {
    const newResults = this.recentlyBuilt;
    const oldResults = this.previouslyBuiltEntitiesResult;

    const resultsChanged = !isEqual(newResults.results, oldResults.results);

    if (resultsChanged) {
      for (let i = 0; i < this.recentlyBuilt.results.length; i++) {
        const built = this.recentlyBuilt.results[i];
        built.profileLink = this.urlGenerator.generateReport(built.oiqEntityId, built.type, built.oiqEntityId);
      }
    }

    this.previouslyBuiltEntitiesResult = { ...newResults };
  }

  ngOnDestroy() {
    this.progressChecker.clearAll();
  }
}
