import { Injectable } from '@angular/core';
import ApiFetchService from '../../oiq/common-services/api-fetch.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import SelectedArticlesService from './selected-articles.service';

@Injectable({
  providedIn: 'root',
})
export default class SelectedArticlesResolver implements Resolve<any> {
  constructor(private apiFetchService: ApiFetchService, private selectedArticlesService: SelectedArticlesService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return Promise.all([this.preLoadEntityName(route.params), this.preLoadSelectedArticles(route.params)]);
  }

  preLoadSelectedArticles(routeParams) {
    const id = routeParams.selectedArticlesId;
    return this.selectedArticlesService.getSaved(id);
  }

  preLoadEntityName(routeParams) {
    return this.apiFetchService.summary(routeParams.entityType, routeParams.entityId, true).then(function (summary) {
      return summary?.name;
    });
  }
}
