import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import MonitorCategories from './monitor-categories.service';
import MonitorCrawlPlans from './monitor-crawlplans.service';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-monitor-categories',
  templateUrl: './ddiq-monitor-categories.component.tpl.html',
})
export default class MonitorCategoriesComponent implements OnInit {
  @Output() toggled = new EventEmitter<any>();
  @Input() noToggle: boolean | undefined;

  private element: HTMLElement;
  isDisabled: boolean;
  hasMasterToggle: boolean;
  vm: {
    selectedCrawlPlan: any;
    enableCategories: boolean;
    selectedFrequency: any;
    frequencies: any;
    crawlPlans: any[];
    hasMasterToggle: boolean;
    showCrawlPlans: boolean;
    categories: any;
  };

  constructor(
    private monitorCategories: MonitorCategories,
    private monitorCrawlPlans: MonitorCrawlPlans,
    private oiqProperties: OiqProperties,
    private elementRef: ElementRef
  ) {
    this.element = elementRef.nativeElement;
  }

  @HostListener('click', ['$event']) onClick(e) {
    e.stopPropagation();
  }

  ngOnInit() {
    this.hasMasterToggle = typeof this.noToggle === 'undefined';

    this.initializeModel();

    if (this.monitorCategories.isMonitorPreselected() || this.monitorCategories.hasPreselectedCategories()) {
      this.onCategoryUpdate();
    }

    this.isDisabled = this.hasMasterToggle && !this.monitorCategories.isMonitorPreselected();
  }

  initializeModel() {
    this.vm = {
      hasMasterToggle: this.hasMasterToggle,
      enableCategories: this.monitorCategories.isMonitorPreselected(),
      frequencies: this.oiqProperties.monitoringFrequencies,
      selectedFrequency: this.oiqProperties.defaultMonitoringFrequency,
      crawlPlans: this.monitorCrawlPlans.getAvailableCrawlPlans(),
      selectedCrawlPlan: this.monitorCrawlPlans.getAvailableCrawlPlans()[0],
      showCrawlPlans: undefined,
      categories: undefined,
    };

    this.vm.showCrawlPlans = this.vm.crawlPlans.length > 1;

    this.vm.categories = this.monitorCategories.categories().map((category) => {
      if (
        (this.hasMasterToggle &&
          this.monitorCategories.isMonitorPreselected() &&
          this.monitorCategories.hasPreselectedCategories()) ||
        !this.hasMasterToggle
      ) {
        category.selected = this.monitorCategories.isCategoryPreselected(category.value);
      } else if (
        this.hasMasterToggle &&
        this.monitorCategories.isMonitorPreselected() &&
        !this.monitorCategories.hasPreselectedCategories()
      ) {
        category.selected = true;
      }

      return category;
    });
  }

  onCategoryUpdate() {
    const frequency = this.vm.selectedFrequency;

    const crawlPlanKey = this.vm.selectedCrawlPlan ? this.vm.selectedCrawlPlan.crawlPlanKey : null;

    const categories = this.vm.categories
      .filter(function (category) {
        return category.selected === true;
      })
      .map(function (category) {
        return {
          label: category.label,
          value: category.value,
        };
      });

    const hasCategories = !!categories.length;

    this.vm.enableCategories = hasCategories;
    this.isDisabled = this.hasMasterToggle && !categories.length;

    this.toggled.emit({
      toggled: {
        isEnabled: !!hasCategories,
        categories: categories,
        frequency: frequency,
        selectedCrawlPlanKey: crawlPlanKey,
      },
    });
  }

  onMonitorToggled(isOn) {
    this.vm.selectedCrawlPlan = this.vm.crawlPlans ? this.vm.crawlPlans[0] : null;

    this.isDisabled = !isOn;

    this.vm.categories.forEach((category) => {
      if (isOn && this.monitorCategories.hasPreselectedCategories()) {
        category.selected = this.monitorCategories.isCategoryPreselected(category.value);
      } else {
        category.selected = isOn;
      }
    });

    this.onCategoryUpdate();
  }

  crawlPlanKeyTrackBy(index: number, crawlPlan: any) {
    return crawlPlan.crawlPlanKey;
  }

  frequencyTrackBy(index: number, frequency: any) {
    return frequency;
  }

  categoryValueTrackBy(index: number, category: any) {
    return category.value;
  }
}
