import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class AdjudicationType {
  getAdjudicationTypeFor(kind, adjudicables) {
    var adjudicationType;

    if (kind === 'watchlists') {
      if (this.isWatchlistClustered(adjudicables)) {
        adjudicationType = 'RECORD_CLUSTER';
      } else {
        adjudicationType = 'WATCH_LIST_ENTRY';
      }
    }

    return adjudicationType;
  }

  private isWatchlistClustered(adjudicables) {
    let watchlist;
    if (Array.isArray(adjudicables)) {
      watchlist = adjudicables[0];
    } else {
      watchlist = adjudicables;
    }
    return watchlist && watchlist.hasOwnProperty('watchlists');
  }
}
