import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import DdiqExclusionManagementModal from './ddiq-exclusion-management-modal.component';
import ExclusionsService from './exclusions.service';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, PipesModule, NgPipesModule, FormsModule],
  providers: [ExclusionsService],
  declarations: [DdiqExclusionManagementModal],
  exports: [DdiqExclusionManagementModal],
})
export class ExclusionsModule {}
