<section class="fs center">
  <div id="four-oh-four">
    <h1><span>{{'Not Found' | translatef}}</span></h1>
    <div class="row-fluid">
      <p>Sorry, something went wrong.</p>
      <hr />
    </div>
  </div>
  <ddiq-footer></ddiq-footer>
</section>
