import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { EventGroupsModule } from '../event-groups/oiq-event-groups.module';
import { OiqIncidentsModule } from '../incidents/oiq-incidents.module';
import { OshaInspectionsModule } from '../osha-inspections/oiq-osha-inspections.module';
import { OiqProductRecallsModule } from '../product-recalls/oiq-product-recalls.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqDiffHeaderComponent from './ddiq-diff-header.component';
import DdiqExpandAllWarningComponent from './ddiq-expand-all-warning.component';
import DdiqNoteworthyEventComponent from './ddiq-noteworthy-event.component';
import DdiqProfileHeaderComponent from './ddiq-profile-header.component';
import NoteworthySubSectionPipe from './noteworthy-sub-section.pipe';
import DdiqAdverseEventComponent from './ddiq-adverse-event.component';
import DdiqRegulatoryCompanyComponent from './ddiq-regulatory-company.component';
import DdiqRegulatoryLocationComponent from './ddiq-regulatory-location.component';
import DdiqRegulatoryPersonComponent from './ddiq-regulatory-person.component';
import DdiqLegalPersonComponent from './ddiq-legal-person.component';
import { OiqWatchLists } from '../watch-lists/oiq-watch-lists.module';
import { WebsiteScorecardsModule } from '../website-scorecards/ddiq-website-scorecards.module';
import { DdiqGovernmentProcurementModule } from '../government-procurement/oiq-government-procurement.module';
import OiqRegulatoryProfilesModule from '../regulatory-profiles/oiq-regulatory-profiles.module';
import { NgPipesModule, OrderByPipe } from 'ngx-pipes';
import FilterRegulatorySubSectionPipe from './filter-regulatory-sub-section.pipe';
import { OiqLegalFilingsModule } from '../legal-filings/oiq-legal-filings.module';
import { DdiqInmateProfileModule } from '../inmate-profiles/oiq-inmate-profiles.module';
import SourceMetadataComponent from './ddiq-source-metadata.component';
import DdiqLegalCompanyComponent from './ddiq-legal-company.component';
import DdiqCompanyReferencesComponent from './ddiq-company-references.component';
import DdiqPersonReferencesComponent from './ddiq-person-references.component';
import { PdfModule } from '../pdf/oiq-pdf.module';
import { ProfileStatusModule } from '../profile-status/profile-status.module';
import { DdiqMonitorModule } from '../monitor/oiq-monitor.module';
import MainProfileComponent from './ddiq-main-profile.component';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import { ProfileRefreshModule } from '../profilerefresh/profile-refresh.module';
import { AlertsModule } from '../alerts/oiq-alerts.module';
import { DdiqGeneralInformationModule } from '../general-information/ddiq-general-information.module';
import ProfileComponent from './ddiq-profile.component';
import { ProfileDelegationModule } from '../profile-delegation/profile-delegation.module';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import { ProfileCollectionModule } from '../profile-collection/profile-collection.module';
import { ExclusionsModule } from '../exclusions/exclusions.module';
import OiqNavModule from '../nav/oiq-nav.module';
import { FormsModule } from '@angular/forms';
import { DdiqCommonModule } from '../common/oiq-common.module';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import ComparableProfileResolverService from './comparable-profile-resolver.service';
import ProfileResolverService from './profile-resolver.service';
import EntityResolverHelperService from './entity-resolver-helper.service';
import { ProfileStylingService } from './profile-styling.service';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DdiqUIModule,
    EventGroupsModule,
    PipesModule,
    ThemedEntityModule,
    DdiqDirectivesModule,
    OiqIncidentsModule,
    OiqProductRecallsModule,
    OshaInspectionsModule,
    OiqWatchLists,
    WebsiteScorecardsModule,
    DdiqGovernmentProcurementModule,
    OiqRegulatoryProfilesModule,
    NgPipesModule,
    OiqLegalFilingsModule,
    DdiqInmateProfileModule,
    PdfModule,
    ProfileStatusModule,
    DdiqMonitorModule,
    OiqAdjudicationModule,
    ProfileRefreshModule,
    AlertsModule,
    DdiqGeneralInformationModule,
    ProfileDelegationModule,
    DdiqUpgradeModule,
    ProfileCollectionModule,
    ExclusionsModule,
    OiqNavModule,
    DdiqCommonModule,
    ProtectedArticlesModule,
  ],
  declarations: [
    DdiqProfileHeaderComponent,
    DdiqExpandAllWarningComponent,
    DdiqDiffHeaderComponent,
    DdiqNoteworthyEventComponent,
    NoteworthySubSectionPipe,
    DdiqAdverseEventComponent,
    DdiqRegulatoryCompanyComponent,
    DdiqRegulatoryLocationComponent,
    DdiqRegulatoryPersonComponent,
    FilterRegulatorySubSectionPipe,
    DdiqLegalPersonComponent,
    SourceMetadataComponent,
    DdiqLegalCompanyComponent,
    DdiqCompanyReferencesComponent,
    DdiqPersonReferencesComponent,
    MainProfileComponent,
    ProfileComponent,
  ],
  providers: [
    OrderByPipe,
    EntityResolverHelperService,
    ComparableProfileResolverService,
    ProfileResolverService,
    ProfileStylingService,
  ],
  exports: [],
})
export class ProfileModule {}
