import { NgModule } from '@angular/core';
import DdiqModalComponent from './ddiq-modal.component';
import ModalTrackingService from './modal-tracking.service';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  providers: [ModalTrackingService],
  declarations: [DdiqModalComponent],
  exports: [DdiqModalComponent],
})
export class DdiqModalModule {}
