import { Component, OnInit, ElementRef } from '@angular/core';
import GovernmentAwards from './oiq-government-awards.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import SeedDataService from '../themed-entity/seed-data.service';
import Paginator from '../common-services/paginator.service';

const awardsPerPage = 5,
  paginationBarLength = 7,
  defaultPageNumber = 0;

@Component({
  selector: 'oiq-government-procurement',
  templateUrl: './oiq-government-procurement.component.tpl.html',
})
export default class GovernmentProcurement implements OnInit {
  companyId: any;

  selectedView: string;
  seedData: any;
  recent: any;
  expiring: any;
  expired: any;
  recentAwardType: string;
  expiringAwardType: string;
  expiredAwardType: string;
  isNavDisabled: boolean;
  busyPaging: any;
  isBusy: boolean;
  private routeParams: ParamMap;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private SeedDataService: SeedDataService,
    private governmentAwards: GovernmentAwards,
    private Paginator: Paginator,
    private elementRef: ElementRef
  ) {
    this.routeParams = this.route.snapshot.paramMap;
    this.recentAwardType = GovernmentAwards.awardTypes.recent;
    this.expiringAwardType = GovernmentAwards.awardTypes.expiring;
    this.expiredAwardType = GovernmentAwards.awardTypes.expired;
    this.selectedView = this.recentAwardType;
    this.busyPaging = {
      [this.recentAwardType]: false,
      [this.expiringAwardType]: false,
      [this.expiredAwardType]: false,
    };
  }

  ngOnInit() {
    this.companyId = this.routeParams.get('entityId');
    this.isBusy = true;
    this.getGovernmentProcurement()
      .then(([seedData, recent, expiring, expired]) => {
        this.seedData = seedData;
        this.updateAwards(recent, this.recentAwardType);
        this.updateAwards(expiring, this.expiringAwardType);
        this.updateAwards(expired, this.expiredAwardType);
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  getGovernmentProcurement() {
    const recentFilter = this.makeFilter(this.initialPageFor(this.recentAwardType));
    const expiringFilter = this.makeFilter(this.initialPageFor(this.expiringAwardType));
    const expiredFilter = this.makeFilter(this.initialPageFor(this.expiredAwardType));

    return Promise.all([
      this.SeedDataService.getCompanySeedData(this.companyId),
      this.governmentAwards.getAwards(this.recentAwardType, this.companyId, recentFilter),
      this.governmentAwards.getAwards(this.expiringAwardType, this.companyId, expiringFilter),
      this.governmentAwards.getAwards(this.expiredAwardType, this.companyId, expiredFilter),
    ]);
  }

  initialPageFor(awardType) {
    return parseInt(this.route.snapshot.queryParams[awardType] || defaultPageNumber, 10);
  }

  makeFilter(rowStart) {
    return { start: rowStart, count: awardsPerPage };
  }

  updateAwards(awardsResponse, awardType) {
    this[awardType] = this.makeAwardsFromResponse(awardsResponse);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [awardType]: awardsResponse.resultsStart },
      queryParamsHandling: 'merge',
    });
  }

  makeAwardsFromResponse(awardsResponse) {
    const { totalMatchCount, totalAmount, resultsStart, results } = awardsResponse;
    return {
      awards: results || [],
      summary: { totalCount: totalMatchCount, totalAmount },
      pagination: this.makePagination(totalMatchCount, resultsStart),
    };
  }

  makePagination(totalResults, resultsStart) {
    const pagination = this.Paginator.paginationFor(totalResults, awardsPerPage, resultsStart);
    const currentPage = this.pageNumberForDisplay(pagination.currentPageIndex);
    return Object.assign({ paginationBarLength, currentPage }, pagination);
  }

  pageAwards(page, awardType) {
    const fromRow = this.getFromRow(page);
    this.beforePaging(awardType);
    this.governmentAwards
      .getAwards(awardType, this.companyId, this.makeFilter(fromRow))
      .then((awards) => {
        this.updateAwards(awards, awardType);
      })
      .finally(() => {
        this.afterPaging(awardType);
      });
  }

  getFromRow(page) {
    const pageIndex = this.pageNumberFromButtonText(page);
    return this.Paginator.startingRowForPageIndex(pageIndex, awardsPerPage);
  }

  navigateTo(sectionName) {
    this.selectedView = sectionName;
    if (this.selectedView) {
      this.elementRef.nativeElement.querySelector(`.${this.selectedView}`).scrollIntoView({ behavior: 'smooth' });
    }
  }

  beforePaging(awardType) {
    this.busyPaging[awardType] = true;
    this.isNavDisabled = true;
    this.clearNavigation();
  }

  afterPaging(awardType) {
    this.busyPaging[awardType] = false;
    this.isNavDisabled = false;
    this.navigateTo(awardType);
  }

  clearNavigation() {
    this.navigateTo('');
  }

  pageNumberFromButtonText(pageNumberText) {
    return parseInt(pageNumberText, 10) - 1;
  }

  pageNumberForDisplay(resultsPage) {
    return resultsPage + 1;
  }
}
