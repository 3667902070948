import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import RiskAssessmentService from './risk-assessment.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { Subscription } from 'rxjs';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'risk-in-classification',
  template: '<ddiq-risk-review class="risk-icon" *ngIf="isEnabled && riskValue" [risk]="riskValue"></ddiq-risk-review>',
})
export default class RiskInClassificationComponent implements OnInit, OnDestroy {
  @Input() riskType;
  @Input() entityId;
  @Input() classification;

  isEnabled;
  riskValue;
  assessmentsUpdated: Subscription;

  constructor(
    private riskAssessmentService: RiskAssessmentService,
    private messageBusService: MessageBusService,
    private oiqProperties: OiqProperties
  ) {
    this.isEnabled = this.oiqProperties.profileScoreEnabled && this.oiqProperties.riskInClassification;
  }

  ngOnInit() {
    if (this.isEnabled) {
      this.riskValue = this.getRiskValue();
      this.assessmentsUpdated = this.messageBusService.on('assessments-updated', () => {
        this.riskValue = this.getRiskValue();
      });
    }
  }

  getRiskValue() {
    const assessment = this.riskAssessmentService.getAssessment(this.classification, this.riskType, this.entityId);
    return assessment && this.riskAssessmentService.getRiskValues(assessment.risk);
  }

  ngOnDestroy() {
    if (this.isEnabled) {
      this.assessmentsUpdated.unsubscribe();
    }
  }
}
