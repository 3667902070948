import { Injectable } from '@angular/core';
import Filter from '../themed-entity/filter.service';
import AdjudicationFeatureService from './adjudication-feature.service';

@Injectable({
  providedIn: 'root',
})
export default class AdjudicationSummary {
  constructor(private filter: Filter, private adjudicationFeature: AdjudicationFeatureService) {}

  summarize(section, classification, kind) {
    const summarized: any = {},
      eventCounts = this.filter.getEventCounts(section, classification, kind);

    summarized.autoAdjudicatedCount = eventCounts.autoAdjudicatedCount;
    summarized.manuallyAdjudicatedCount = eventCounts.manuallyAdjudicatedCount;
    summarized.confirmedCount = eventCounts.confirmedCount;
    summarized.escalatedCount = eventCounts.escalatedCount;
    summarized.remainingCount = eventCounts.remainingCount;
    summarized.filteredCount = eventCounts.filteredOutCount;

    this.decrementConfirmedCount(summarized, eventCounts);

    summarized.remainingCount -= eventCounts.escalatedCount;
    summarized.remainingCount = Math.max(0, summarized.remainingCount);

    summarized.totalHits = eventCounts.totalCount;

    if (this.filter.isSectionFiltered(section)) {
      summarized.filteredCount = summarized.totalHits;
    }

    return summarized;
  }

  private decrementConfirmedCount(summarized, eventCounts) {
    if (this.canDecrementConfirmedCount()) {
      summarized.remainingCount -= eventCounts.confirmedCount;
    }
  }

  private canDecrementConfirmedCount() {
    return this.adjudicationFeature.isConfirmActionEnabled();
  }
}
