<div id="references" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="References"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!entityAware.isPreview || referencesHasContent()"
    [disable]="!referencesHasContent()"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <div id="person_references_dataSources">
      <strong class="push-down" translate>Data Sources</strong>

      <div load-source [saveIn]="section.data" #loadSource="loadSource" [exclude]="'tlo'">
        <div [ngSwitch]="loadSource.saveIn.sourceCount > 0">
          <div *ngSwitchCase="false">
            <p *ngIf="!entityAware.isDiff">No Data Sources</p>
            <p *ngIf="entityAware.isDiff">Data Sources are not included in the comparison</p>
            <hr />
          </div>
          <div *ngSwitchDefault>
            <div [ngSwitch]="loadSource.saveIn.oiqPremiumDataSource.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Premium Data Source" openonprint>
                  <div class="data-source">
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].biography">
                      <strong><span translate>Biography</span>:</strong>
                      {{ loadSource.saveIn.oiqPremiumDataSource[0].biography }}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].latestSalary">
                      <strong><span translate>Last Salary</span>:</strong>
                      {{ loadSource.saveIn.oiqPremiumDataSource[0].latestSalary }}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].latestTotalSalary">
                      <strong><span translate>Last Total Salary</span>:</strong>
                      {{ loadSource.saveIn.oiqPremiumDataSource[0].latestTotalSalary }}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].latestSalaryYear">
                      <strong><span translate>Last Salary Year</span>:</strong>
                      {{ loadSource.saveIn.oiqPremiumDataSource[0].latestSalaryYear }}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].age">
                      <strong><span translate>Age</span>:</strong>
                      {{ loadSource.saveIn.oiqPremiumDataSource[0].age }}
                    </p>

                    <strong class="pushdown" translate>Experience</strong>
                    <table>
                      <thead>
                        <tr>
                          <th translate>Position</th>
                          <th translate>Company</th>
                          <th translate>Dates</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].currentPosition">
                          <td>
                            {{ loadSource.saveIn.oiqPremiumDataSource[0].currentPosition.title }}<span
                              *ngIf="!loadSource.saveIn.oiqPremiumDataSource[0].currentPosition"
                              >-</span
                            >
                          </td>
                          <td>
                            {{ loadSource.saveIn.oiqPremiumDataSource[0].currentPosition.organization }}<span
                              *ngIf="!loadSource.saveIn.oiqPremiumDataSource[0].currentPosition"
                              >-</span
                            >
                          </td>
                          <td>
                            {{ loadSource.saveIn.oiqPremiumDataSource[0].currentPosition.startDate | dateFormat }}<span
                              *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].currentPosition.startDate"
                              >-</span
                            >{{ loadSource.saveIn.oiqPremiumDataSource[0].currentPosition.endDate | dateFormat }}<span
                              *ngIf="!loadSource.saveIn.oiqPremiumDataSource[0].currentPosition.endDate"
                              translate
                              >Present</span
                            >
                          </td>
                        </tr>
                        <tr *ngIf="!loadSource.saveIn.oiqPremiumDataSource[0].currentPosition">
                          <td colspan="3" translate>Present Experience Not Found</td>
                        </tr>
                        <ng-container
                          *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].previousPositions[0] && loadSource.saveIn.oiqPremiumDataSource[0].previousPositions[0].length">
                          <tr *ngFor="let position of loadSource.saveIn.oiqPremiumDataSource[0].previousPositions">
                            <td>{{ position.title }}</td>
                            <td>{{ position.organization }}</td>
                            <td>
                              {{ position.startDate | dateFormat }}<span *ngIf="!position.startDate" translate
                                >Unknown</span
                              ><span *ngIf="position.endDate">-{{ position.endDate | dateFormat }}</span>
                            </td>
                          </tr>
                        </ng-container>

                        <tr *ngIf="!loadSource.saveIn.oiqPremiumDataSource[0].previousPositions[0]">
                          <td colspan="3" translate>Previous Experience Not Found</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ddiq-accordion>
              </div>
            </div>

            <div [ngSwitch]="loadSource.saveIn.zoomInfo.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="ZoomInfo" openonprint>
                  <div class="data-source">
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.zoomInfo.profileUrl" target="_blank"
                        >{{ loadSource.saveIn.zoomInfo.profileUrl }}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.biography">
                      <strong><span translate>Biography</span>:</strong> {{ loadSource.saveIn.zoomInfo.biography }}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.email">
                      <strong><span translate>Email</span>:</strong> {{ loadSource.saveIn.zoomInfo.email }}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.companyPhone">
                      <strong><span translate>Company Phone</span>:</strong> {{ loadSource.saveIn.zoomInfo.companyPhone
                      }}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.directPhone">
                      <strong><span translate>Direct Phone</span>:</strong> {{ loadSource.saveIn.zoomInfo.directPhone }}
                    </p>
                    <div *ngIf="loadSource.saveIn.zoomInfo.industries.length">
                      <strong><span translate>Industries</span>:</strong>
                      <ul>
                        <li *ngFor="let industry of loadSource.saveIn.zoomInfo.industries">{{ industry }}</li>
                      </ul>
                    </div>
                    <strong class="pushdown" translate>Experience</strong>
                    <table>
                      <thead>
                        <tr>
                          <th translate>Position</th>
                          <th translate>Company</th>
                          <th translate>Dates</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="loadSource.saveIn.zoomInfo.currentPosition">
                          <td>
                            {{ loadSource.saveIn.zoomInfo.currentPosition.title }}<span
                              *ngIf="!loadSource.saveIn.zoomInfo.currentPosition"
                              >-</span
                            >
                          </td>
                          <td>
                            {{ loadSource.saveIn.zoomInfo.currentPosition.organization }}<span
                              *ngIf="!loadSource.saveIn.zoomInfo.currentPosition"
                              >-</span
                            >
                          </td>
                          <td>
                            {{ loadSource.saveIn.zoomInfo.currentPosition.startDate | dateFormat }}<span
                              *ngIf="loadSource.saveIn.zoomInfo.currentPosition.startDate"
                              >-</span
                            >{{ loadSource.saveIn.zoomInfo.currentPosition.endDate | dateFormat }}<span
                              *ngIf="!loadSource.saveIn.zoomInfo.currentPosition.endDate"
                              ><span translate>Present</span></span
                            >
                          </td>
                        </tr>
                        <tr *ngIf="!loadSource.saveIn.zoomInfo.currentPosition">
                          <td colspan="3" translate>Present Experience Not Found</td>
                        </tr>
                        <ng-container
                          *ngIf="loadSource.saveIn.zoomInfo.previousPositions && loadSource.saveIn.zoomInfo.previousPositions.length">
                          <tr *ngFor="let position of loadSource.saveIn.zoomInfo.previousPositions">
                            <td>{{ position.title }}</td>
                            <td>{{ position.organization }}</td>
                            <td>
                              {{ position.startDate | dateFormat }}<span *ngIf="!position.startDate"
                                ><span translate>Unknown</span></span
                              ><span *ngIf="position.endDate">-{{ position.endDate | dateFormat }}</span>
                            </td>
                          </tr>
                        </ng-container>

                        <tr
                          *ngIf="!(loadSource.saveIn.zoomInfo.previousPositions && loadSource.saveIn.zoomInfo.previousPositions.length)">
                          <td colspan="3" translate>Previous Experience Not Found</td>
                        </tr>
                      </tbody>
                    </table>
                    <div *ngIf="loadSource.saveIn.zoomInfo.education.length">
                      <strong class="pushdown" translate>Education</strong>
                      <table>
                        <thead>
                          <tr>
                            <th translate>Institution</th>
                            <th translate>Degree</th>
                            <th translate>Dates</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let education of loadSource.saveIn.zoomInfo.education">
                            <td>
                              {{ education.institution }}<span *ngIf="!education.institution" translate>Unknown</span>
                            </td>
                            <td>{{ education.degree }}<span *ngIf="!education.degree" translate>Unknown</span></td>
                            <td>
                              {{ education.endDate | dateFormat }}<span *ngIf="!education.endDate" translate
                                >Unknown</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      *ngIf="loadSource.saveIn.zoomInfo.certifications && loadSource.saveIn.zoomInfo.certifications.length">
                      <strong class="pushdown" translate>Certifications</strong>
                      <table>
                        <thead>
                          <tr>
                            <th translate>Organization</th>
                            <th translate>Name of Certification</th>
                            <th translate>Received</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let certificate of loadSource.saveIn.zoomInfo.certifications">
                            <td>
                              {{ certificate.issuingOrganization }}<span *ngIf="!certificate.issuingOrganization"
                                ><span translate>Unknown</span></span
                              >
                            </td>
                            <td>
                              {{ certificate.certificationName }}<span *ngIf="!certificate.certificationName" translate
                                >Unknown</span
                              >
                            </td>
                            <td>
                              {{ certificate.dateReceived | dateFormat }}<span
                                *ngIf="!certificate.dateReceived"
                                translate
                                >Unknown</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ddiq-accordion>
              </div>
            </div>

            <div [ngSwitch]="loadSource.saveIn.linkedIn.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="LinkedIn" openonprint>
                  <div class="data-source">
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.linkedIn.profileUrl" target="_blank"
                        >{{loadSource.saveIn.linkedIn.profileUrl}}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.linkedIn.summary">
                      <strong><span translate>Summary</span>:</strong> {{ loadSource.saveIn.linkedIn.summary }}
                    </p>
                    <p *ngIf="loadSource.saveIn.linkedIn.connections">
                      <strong><span translate>Network</span>:</strong> {{ loadSource.saveIn.linkedIn.connections }}
                    </p>
                    <div *ngIf="loadSource.saveIn.linkedIn.links && loadSource.saveIn.linkedIn.links.length">
                      <strong><span translate>Links</span>:</strong>
                      <ul>
                        <li *ngFor="let link of loadSource.saveIn.linkedIn.links">
                          <a [href]="link.url" target="_blank">{{ link.name }}</a>
                        </li>
                      </ul>
                    </div>
                    <div
                      *ngIf="loadSource.saveIn.linkedIn.associations && loadSource.saveIn.linkedIn.associations.length">
                      <strong><span translate>Associations</span>:</strong>
                      <ul>
                        <li *ngFor="let association of loadSource.saveIn.linkedIn.associations">{{ association }}</li>
                      </ul>
                    </div>
                    <div
                      *ngIf="loadSource.saveIn.linkedIn.linkedInGroups && loadSource.saveIn.linkedIn.linkedInGroups.length">
                      <strong><span translate>Groups</span>:</strong>
                      <ul>
                        <li *ngFor="let group of loadSource.saveIn.linkedIn.linkedInGroups">{{ group }}</li>
                      </ul>
                    </div>
                    <div *ngIf="loadSource.saveIn.linkedIn.positions && loadSource.saveIn.linkedIn.positions.length">
                      <strong class="pushdown" translate>Experience</strong>
                      <table>
                        <thead>
                          <tr>
                            <th translate>Position</th>
                            <th translate>Company</th>
                            <th translate>Dates</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let position of loadSource.saveIn.linkedIn.positions">
                            <td>{{ position.title }}</td>
                            <td>{{ position.organization }}</td>
                            <td>
                              {{ position.startDate | dateFormat }}<span *ngIf="!position.startDate" translate
                                >Unknown</span
                              ><span *ngIf="position.endDate">-{{ position.endDate | dateFormat }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div *ngIf="loadSource.saveIn.linkedIn.education && loadSource.saveIn.linkedIn.education.length">
                      <strong class="pushdown" translate>Education</strong>
                      <table>
                        <thead>
                          <tr>
                            <th translate>Institution</th>
                            <th translate>Degree</th>
                            <th translate>Dates</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let education of loadSource.saveIn.linkedIn.education">
                            <td>
                              {{ education.institution }}<span *ngIf="!education.institution" translate>Unknown</span>
                            </td>
                            <td>{{ education.degree }}<span *ngIf="!education.degree" translate>Unknown</span></td>
                            <td>
                              {{ education.endDate | dateFormat }}<span *ngIf="!education.endDate" translate
                                >Unknown</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ddiq-accordion>
              </div>
            </div>

            <div [ngSwitch]="loadSource.saveIn.customProfiles.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Other Data Sources" openonprint>
                  <div class="data-source" *ngFor="let customProfile of loadSource.saveIn.customProfiles">
                    <strong
                      ><a [href]="customProfile.profileUrl" target="_blank">{{customProfile.customType}}</a></strong
                    >
                    <p *ngIf="customProfile.firstName || customProfile.middleName || customProfile.lastName">
                      <strong translate>Name:</strong> {{ customProfile.firstName }} {{ customProfile.middleName }} {{
                      customProfile.lastName }}
                    </p>
                    <p *ngIf="customProfile.location">
                      <strong translate>Location:</strong> {{ customProfile.location }}
                    </p>
                    <p *ngIf="customProfile.summary"><strong translate>Summary:</strong> {{ customProfile.summary }}</p>
                    <p *ngIf="customProfile.officePhone">
                      <strong translate>Office Phone:</strong> {{ customProfile.officePhone }}
                    </p>
                    <p *ngIf="customProfile.homePhone">
                      <strong translate>Home Phone:</strong> {{ customProfile.homePhone }}
                    </p>
                    <p *ngIf="customProfile.cellPhone">
                      <strong translate>Cell Phone:</strong> {{ customProfile.cellPhone }}
                    </p>
                    <p *ngIf="customProfile.companyPhone">
                      <strong translate>Company Phone:</strong> {{ customProfile.companyPhone }}
                    </p>
                    <p *ngIf="customProfile.email"><strong translate>Email:</strong> {{ customProfile.email }}</p>
                    <p *ngIf="customProfile.postalAddress">
                      <strong translate>Address:</strong> {{ customProfile.postalAddress | address }}
                    </p>
                    <p *ngIf="customProfile.supervisor">
                      <strong translate>Supervisor:</strong> {{ customProfile.supervisor }}
                    </p>
                    <div *ngIf="customProfile.links && customProfile.links.length">
                      <strong><span translate>Links</span>:</strong>
                      <ul>
                        <li *ngFor="let link of customProfile.links">
                          <a [href]="link.url" target="_blank">{{link.name}}</a>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="customProfile.industries && customProfile.industries.length">
                      <strong><span translate>Industries</span>:</strong>
                      <ul>
                        <li *ngFor="let industry of customProfile.industries">{{ industry }}</li>
                      </ul>
                    </div>
                    <div *ngIf="customProfile.specialties && customProfile.specialties.length">
                      <strong><span translate>Specialties</span>:</strong>
                      <ul>
                        <li *ngFor="let specialty of customProfile.specialties">{{ specialty }}</li>
                      </ul>
                    </div>
                    <div *ngIf="customProfile.associations && customProfile.associations.length">
                      <strong><span translate>Associations</span>:</strong>
                      <ul>
                        <li *ngFor="let association of customProfile.associations">{{ association }}</li>
                      </ul>
                    </div>
                    <div *ngIf="customProfile.interests && customProfile.interests.length">
                      <strong><span translate>Interests</span>:</strong>
                      <ul>
                        <li *ngFor="let interest of customProfile.interests">{{ interest }}</li>
                      </ul>
                    </div>
                    <div *ngIf="customProfile.subordinates && customProfile.subordinates.length">
                      <strong><span translate>Subordinates</span>:</strong>
                      <ul>
                        <li *ngFor="let subordinate of customProfile.subordinates">{{ subordinate }}</li>
                      </ul>
                    </div>
                    <div *ngIf="customProfile.education && customProfile.education.length">
                      <strong class="pushdown" translate>Education</strong>
                      <table>
                        <thead>
                          <tr>
                            <th translate>Institution</th>
                            <th translate>Degree</th>
                            <th translate>Dates</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let education of customProfile.education">
                            <td>
                              {{ education.institution }}<span *ngIf="!education.institution" translate>Unknown</span>
                            </td>
                            <td>{{ education.degree }}<span *ngIf="!education.degree" translate>Unknown</span></td>
                            <td>
                              {{ education.endDate | dateFormat }}<span *ngIf="!education.endDate" translate
                                >Unknown</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div *ngIf="customProfile.positions && customProfile.positions.length">
                      <strong class="pushdown" translate>Positions</strong>
                      <table>
                        <thead>
                          <tr>
                            <th translate>Title</th>
                            <th translate>Organization</th>
                            <th translate>Start Date</th>
                            <th translate>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let position of customProfile.positions">
                            <td>{{ position.title }}<span *ngIf="!position.title" translate>Unknown</span></td>
                            <td>
                              {{ position.organization }}<span *ngIf="!position.organization" translate>Unknown</span>
                            </td>
                            <td>
                              {{ position.startDate | dateFormat }}<span *ngIf="!position.startDate" translate
                                >Unknown</span
                              >
                            </td>
                            <td>
                              {{ position.endDate | dateFormat }}<span *ngIf="position.endDate" translate>Unknown</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="person_references_webContent">
      <strong class="push-down">Web Content</strong>
      <ddiq-incidents-group
        [idPrefix]="section.metadata.sectionId"
        [groups]="section.data.contentEventGroups"
        (sortDiscoveries)="sortDiscoveries($event.sortName, $event.eventGroup)"
        [isDisplayCreatedDate]="entityAware.isDeltaReport"
        [expandAll]="isExpandAllGroups">
      </ddiq-incidents-group>
    </div>
  </ddiq-accordion-top>
</div>
<div *ngIf="section.metadata.filtered" ddiq-pdf-load-complete></div>
