<div class="container">
  <div class="login-panel" [ngSwitch]="request.action">
    <div class="brand">
      <img data-theme-logo="large" alt="DDIQ - Where all due diligence begins" />
      <span class="brand-sub-text">Powered by DDIQ</span>
    </div>

    <div *ngSwitchCase="'invalid'">
      <h2>Invalid token</h2>
      <div class="error-message" *ngIf="request.error">
        <span>{{request.error.reason}}</span>
      </div>
      <div class="block center">
        <button class="btn btn-default btn-block" (click)="returnToLogin()">Back</button>
      </div>
    </div>

    <div *ngSwitchCase="'change'">
      <h2>Change Password</h2>
      <section>
        <form method="post" novalidate autocomplete="off">
          <div class="input">Select your new password and enter it below.</div>
          <ddiq-password
            name="newPassword"
            label="New Password"
            size="full"
            [(model)]="user.newPassword"
            [pattern]="passwordValidationPattern"
            required></ddiq-password>
          <ddiq-password
            name="newPasswordConfirm"
            label="Confirm Password"
            size="full"
            [(model)]="user.newPasswordConfirm"
            [pattern]="passwordValidationPattern"
            required></ddiq-password>
          <div class="error-message" *ngIf="request.error || user.error">
            <span *ngIf="request.error">{{request.error.reason}}</span>
            <span *ngIf="user.error">{{user.error}}</span>
          </div>
          <div class="block center">
            <button
              class="btn btn-primary btn-block"
              type="submit"
              (click)="changePasswordWithToken(request.token, user)"
              [disabled]="!validPassword()">
              Change Password
            </button>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>
