import { Injectable } from '@angular/core';
import { ProfileHit, ProfileHitKind, ProfileHitId } from './profile-hit-id';
import { WatchListHitIdService } from './watch-list-hit-id.service';
import { RegulatoryHitIdService } from './regulatory-hit-id.service';
import { CorporateRecordHitIdService } from './corporate-record-hit-id.service';
import { IncidentsHitIdService } from './incidents-hit-id.service';

@Injectable()
export class ProfileHitIdService extends ProfileHitId {
  private readonly profileHitIds: { [Kind in ProfileHitKind]: ProfileHitId };

  constructor(
    private watchListHitId: WatchListHitIdService,
    private regulatoryHitId: RegulatoryHitIdService,
    private corporateRecordHitId: CorporateRecordHitIdService,
    private incidentsHitId: IncidentsHitIdService
  ) {
    super();
    this.profileHitIds = {
      watchlists: watchListHitId,
      regulatoryProfiles: regulatoryHitId,
      corporateRecords: corporateRecordHitId,
      incidents: incidentsHitId,
    };
  }

  doGetIdFrom(hit: ProfileHit): string {
    const profileHitId = this.profileHitIds[hit?.filterFields?.kind];
    if (!profileHitId) {
      throw new Error(`Unknown profile hit kind: ${hit?.filterFields?.kind}`);
    }
    return profileHitId.doGetIdFrom(hit);
  }
}
