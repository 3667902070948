<button
  class="corporate-record-graph-button ownership-btn"
  *ngIf="hasGraphData('ownershipData')"
  (click)="goToGraph('ownershipData')">
  <i class="fa fa-share-alt" aria-hidden="true"></i>
  Ownership Data
</button>
<button
  class="corporate-record-graph-button family-tree-btn"
  *ngIf="hasGraphData('familyTree')"
  (click)="goToGraph('familyTree')">
  <i class="fa fa-share-alt" aria-hidden="true"></i>
  Family Tree
</button>
