<div id="edit-collections">
  <div class="modal-title">Edit Collections</div>

  <div class="pc-error-message" *ngIf="errorMessage">{{errorMessage}}</div>

  <div class="modal-content pushdown">
    <form novalidate name="pcList" #pcList="ngForm" (ngSubmit)="onSave()">
      <div class="input">
        <ng-multiselect-dropdown
          ngDefaultControl
          [placeholder]="'Select Profile Collection(s)'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelect)="onItemDeselect($event)"
          (onDeSelectAll)="onDeselectAll($event)"
          [ngModelOptions]="{standalone: true}">
        </ng-multiselect-dropdown>
      </div>

      <div class="center push-down pushdown clr">
        <button id="edit-collection-button" type="submit" class="btn btn-primary" [disabled]="!selectedItems.length">
          Save
        </button>
        <button id="cancelBtn" type="button" class="btn btn-danger" (click)="cancel();">Cancel</button>
      </div>
    </form>
  </div>
</div>
