import { ViewportScroller } from '@angular/common';
import { ApplicationRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { first, map } from 'rxjs';

@Injectable()
export class ProfileScrollService {
  private renderer: Renderer2;
  constructor(
    private viewPortScroll: ViewportScroller,
    private appRef: ApplicationRef,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    viewPortScroll.setOffset([0, 130]);
  }

  scrollTo(id: string): Promise<HTMLElement | null> {
    return Promise.resolve(this.scroll(id));
  }

  scrollWhenStableTo(id: string): Promise<HTMLElement | null> {
    return new Promise((resolve, reject) => {
      this.appRef.isStable
        .pipe(
          first((isStable: boolean) => isStable),
          map(() => {
            return this.scroll(id);
          })
        )
        .subscribe({
          next: (element) => {
            resolve(element);
          },
          error: (error) => {
            console.error(error);
            reject(error);
          },
        });
    });
  }

  private scroll(id: string) {
    const elem: HTMLElement | null = document.getElementById(id);
    if (elem && !this.isElementHidden(elem)) {
      this.viewPortScroll.scrollToAnchor(id);
      return elem;
    }
    return null;
  }

  private isElementHidden(element: HTMLElement) {
    while (element) {
      if (element.hidden) {
        return true;
      }
      element = this.renderer.parentNode(element);
    }
    return false;
  }
}
