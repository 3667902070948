import AssociationBuckets from '../association/association-buckets.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import SectionConfigService from '../common-services/section-config.service';
import EventGroupFilterService from '../event-groups/event-group-filter-service.service';
import EventGroupSort from '../event-groups/event-group-sort.service';
import IncidentsService from './incidents.service';
import StructuredData from './structured-data.service';
import { Injectable } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export default class Filter {
  sections = [];
  filteredSections = [];
  beforeObservers = [];
  afterObservers = [];
  minDate = '1990-01-01';
  maxDate = new Date().getFullYear() + '-12-31';
  filter;
  filterMask;
  private static associationBuckets;
  initialBucket;

  sectionNames = ['basicInfo', 'corporate', 'regulatory', 'adverse', 'legal', 'noteworthy', 'other'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private sectionConfigService: SectionConfigService,
    private eventGroupSort: EventGroupSort,
    private eventGroupFilterService: EventGroupFilterService,
    private AssociationBuckets: AssociationBuckets,
    private messageBusService: MessageBusService
  ) {
    Filter.associationBuckets = AssociationBuckets.getBuckets();
    this.initialBucket = AssociationBuckets.getInitialBucket();
  }

  clear() {
    this.sections.length = 0;
    this.filteredSections.length = 0;
    this.beforeObservers.length = 0;
    this.afterObservers.length = 0;
    this.filter = null;
    this.filterMask = null;
  }

  // extracts available events from incidents
  init(configuredSections, skipFilterApply?) {
    const sectionMap = {};
    let i, filterFromMask, filterParam;

    filterParam = this.route.snapshot.queryParams.filter;

    if (filterParam) {
      this.setFilterMask(filterParam);
    } else {
      this.setFilter(this.getDefaultFilter());
      this.updateFilterMask();
    }

    for (i = 0; i < this.sectionNames.length; i++) {
      sectionMap[this.sectionNames[i]] = this.extractSectionLabels(this.sectionNames[i]);
    }

    this.sections.length = 0;
    Array.prototype.push.apply(this.sections, this.convertToNestedArray(sectionMap, configuredSections || []));

    filterFromMask = this.deconstructFilterMask();

    if (skipFilterApply) {
      this.setFilter(filterFromMask);
    } else {
      this.apply(filterFromMask);
    }
  }

  // apply the filter result
  apply(filter) {
    let i;

    this.beforeObservers.forEach(function (observer) {
      observer();
    });

    if (filter) {
      for (i = 0; i < this.sectionNames.length; i++) {
        this.incidents.filter(
          this.sectionNames[i],
          filter.fromDate,
          filter.toDate,
          filter.associationBucket,
          filter.excludeAdjudicated,
          filter.escalatedOnly,
          filter.confirmedOnly,
          filter.hideConfirmed,
          filter.hideEscalated,
          filter.adjudicatedOnly
        );
        this.structuredData.filter(
          this.sectionNames[i],
          filter.fromDate,
          filter.toDate,
          filter.associationBucket,
          filter.excludeAdjudicated,
          filter.escalatedOnly,
          filter.confirmedOnly,
          filter.hideConfirmed,
          filter.hideEscalated,
          filter.adjudicatedOnly
        );
      }
    } else {
      this.incidents.filter();
      this.structuredData.filter();
    }

    this.setFilter(filter);

    this.afterObservers.forEach((observer) => {
      observer();
    });
  }

  // returns whether or not there is a filter applied
  isFiltered() {
    const filter = this.getFilter();

    if (filter) {
      if (filter.excludeAdjudicated) return true;
      if (filter.escalatedOnly) return true;
      if (filter.confirmedOnly) return true;
      if (filter.hideConfirmed) return true;
      if (filter.hideEscalated) return true;
      if (filter.adjudicatedOnly) return true;
      if (filter.fromDate && filter.fromDate !== this.minDate) return true;
      if (filter.toDate && filter.toDate !== this.maxDate) return true;
      if (typeof filter.associationBucket !== 'undefined' && filter.associationBucket !== this.initialBucket)
        return true;
    }

    return !!this.filteredSections.length;
  }

  clearFilter() {
    this.filterMask = null;
    this.filter = null;
    this.filteredSections = [];
  }

  // sets a new filter
  setFilter(filter) {
    this.filter = filter;
    if (this.filter) {
      this.updateFilterMask();
    }
  }

  // sets the filter mask from the url
  setFilterMask(mask) {
    this.filterMask = mask;
  }

  // gets the filter mask for url usage
  getFilterMask() {
    return this.filterMask;
  }

  updateSectionMask(name) {
    const index = this.filteredSections.indexOf(name);

    if (index >= 0) {
      this.filteredSections.splice(index, 1);
    } else {
      this.filteredSections.push(name);
    }
  }

  isSectionFiltered(name) {
    return this.filteredSections.indexOf(name) >= 0;
  }

  // returns the currently applied filter
  getFilter() {
    return this.filter;
  }

  updateExclusions(dispositionsSpec = Filter.makeDispositionsSpec()) {
    const filter = this.applyDispositionsToFilter(this.getFilter(), dispositionsSpec);
    if (filter) {
      this.apply(filter);
    }
  }

  getSections() {
    return this.sections;
  }

  getEventCounts(sectionId, classificationName, kind) {
    let events;

    if (kind === 'incidents') {
      events = this.incidents.find(sectionId, classificationName);
    } else {
      events = this.structuredData.find(sectionId, classificationName);
    }

    return this.eventGroupFilterService.getFilteredInfo(events);
  }

  getBucketsFilterState(currentBucket) {
    return {
      isFiltered: this.initialBucket !== currentBucket,
      getBucketName: function () {
        const bucketName = Object.keys(Filter.associationBuckets).filter(function (bucket) {
          return Filter.associationBuckets[bucket] === currentBucket;
        });

        if (bucketName.length === 1) {
          return bucketName.toString();
        }
      },
    };
  }

  // Filter Observers
  registerBeforeFilterObserver(observer) {
    this.beforeObservers.push(observer);
  }

  registerAfterFilterObserver(observer) {
    this.afterObservers.push(observer);
  }

  unRegisterFilterObservers(beforeObserver, afterObserver) {
    const indexOfBefore = this.beforeObservers.indexOf(beforeObserver),
      indexOfAfter = this.afterObservers.indexOf(afterObserver);

    if (indexOfBefore >= 0) {
      this.beforeObservers.splice(indexOfBefore, 1);
    }

    if (indexOfAfter >= 0) {
      this.afterObservers.splice(indexOfAfter, 1);
    }
  }

  private getLowestBucket() {
    const lowestBucket = Object.keys(Filter.associationBuckets).reduce(function (l, r) {
      return Filter.associationBuckets[l] < Filter.associationBuckets[r] ? l : r;
    });

    return Filter.associationBuckets[lowestBucket];
  }

  private orderLabelsByKind(sectionName, labels) {
    let subsections,
      sectionLabels = [];

    if (sectionName === 'other') {
      sectionName = 'references';
    }

    subsections = this.sectionConfigService.getSubSections(this.entity.getType(), sectionName);

    if (subsections.length > 0) {
      subsections.forEach((kind) => {
        const labelsOfKind = labels.filter((label) => {
          return label.kind === kind;
        });

        if (
          kind === 'incidents' ||
          kind === 'watchlists' ||
          kind === 'regulatoryProfiles' ||
          kind === 'websiteScorecards'
        ) {
          this.eventGroupSort.sort(labelsOfKind);
        }

        sectionLabels = sectionLabels.concat(labelsOfKind);
      });
    } else {
      labels.sort(function (label1, label2) {
        return label1.label > label2.label ? 1 : -1;
      });
      sectionLabels = sectionLabels.concat(labels);
    }

    return sectionLabels;
  }

  private makeFilter() {
    const today = new Date();

    return {
      fromDate:
        this.oiqProperties.dateFilterInitialYears >= 0
          ? Filter.calculateDateFilterField(today, this.oiqProperties.dateFilterInitialYears)
          : null,
      toDate: this.oiqProperties.dateFilterInitialYears >= 0 ? Filter.calculateDateFilterField(today, 0) : null,
      associationBucket: this.initialBucket,
      excludeAdjudicated: false,
      escalatedOnly: false,
      confirmedOnly: false,
      hideConfirmed: false,
      hideEscalated: false,
      adjudicatedOnly: false,
    };
  }

  private getDefaultFilter() {
    const profileFilters = this.oiqProperties.profileFilters;
    const defaultDispositions = {
      confirmedOnly: profileFilters.isShowConfirmedOnlyOn,
      hideConfirmed: profileFilters.isHideConfirmedOn,
      escalatedOnly: profileFilters.isShowEscalatedOnlyOn,
      hideEscalated: profileFilters.isHideEscalatedOn,
      excludeAdjudicated: profileFilters.isHideAdjudicatedOn,
      adjudicatedOnly: profileFilters.isShowAdjudicatedOnlyOn,
    };

    return this.applyDispositionsToFilter(this.makeFilter(), defaultDispositions);
  }

  private deconstructFilterMask() {
    let deconstructed;

    if (this.filterMask) {
      const filterParts = this.filterMask.split('.');
      if (filterParts.length >= 9) {
        deconstructed = {
          fromDate: filterParts[0],
          toDate: filterParts[1],
          associationBucket: parseInt(filterParts[2]),
          excludeAdjudicated: filterParts[3] === '0' ? false : true,
          escalatedOnly: filterParts[4] === '0' ? false : true,
          confirmedOnly: filterParts[5] === '0' ? false : true,
          hideConfirmed: filterParts[6] === '0' ? false : true,
          hideEscalated: filterParts[7] === '0' ? false : true,
          adjudicatedOnly: filterParts[8] === '0' ? false : true,
        };
        this.filteredSections = filterParts.slice(9);
      }

      return deconstructed;
    }
    return this.makeFilter();
  }

  private static calculateDateFilterField(initialDate, yearsToSubtract) {
    const year = initialDate.getFullYear() - yearsToSubtract,
      month = '' + (initialDate.getMonth() + 1),
      day = '' + initialDate.getDate();

    return [year, Filter.format(month), Filter.format(day)].join('-'); // yyyy-mm-dd
  }

  private static format(date) {
    if (date.length < 2) {
      date = '0' + date;
    }
    return date;
  }

  private applyDispositionsToFilter(filter, spec = Filter.makeDispositionsSpec()) {
    const lowestBucket = this.getLowestBucket();

    if (filter) {
      filter.escalatedOnly = spec.escalatedOnly;
      filter.confirmedOnly = spec.confirmedOnly;
      filter.adjudicatedOnly = spec.adjudicatedOnly;
      if (spec.confirmedOnly) {
        filter.escalatedOnly = false;
        filter.excludeAdjudicated = false;
        filter.adjudicatedOnly = false;
        filter.hideConfirmed = false;
        filter.hideEscalated = false;
        filter.associationBucket = lowestBucket;
      } else if (spec.escalatedOnly) {
        filter.confirmedOnly = false;
        filter.excludeAdjudicated = false;
        filter.adjudicatedOnly = false;
        filter.hideEscalated = false;
        filter.hideConfirmed = false;
        filter.associationBucket = lowestBucket;
      } else if (spec.adjudicatedOnly) {
        filter.confirmedOnly = false;
        filter.escalatedOnly = false;
        filter.excludeAdjudicated = false;
        filter.hideEscalated = false;
        filter.hideConfirmed = false;
        filter.associationBucket = lowestBucket;
      } else {
        filter.excludeAdjudicated = spec.excludeAdjudicated;
        filter.hideConfirmed = spec.hideConfirmed;
        filter.hideEscalated = spec.hideEscalated;
      }
    }

    return filter;
  }

  private makeSectionFilterMask() {
    return this.filteredSections.join('.');
  }

  private updateFilterMask() {
    let mask = '',
      sectionFilterMask,
      shouldUpdateSearch;
    const filter = this.getFilter();

    mask += (filter.fromDate || '') + '.';
    mask += (filter.toDate || '') + '.';
    mask += filter.associationBucket + '.';
    mask += (filter.excludeAdjudicated ? '1' : '0') + '.';
    mask += (filter.escalatedOnly ? '1' : '0') + '.';
    mask += (filter.confirmedOnly ? '1' : '0') + '.';
    mask += (filter.hideConfirmed ? '1' : '0') + '.';
    mask += (filter.hideEscalated ? '1' : '0') + '.';
    mask += filter.adjudicatedOnly ? '1' : '0';

    sectionFilterMask = this.makeSectionFilterMask();

    if (sectionFilterMask) {
      mask += '.' + sectionFilterMask;
    }

    this.filterMask = mask;

    // MDG: I have turned reloadOnSearch on for entity/diff viewing.
    // I have to turn it off temporarily in order to update the filter as it is part of the URL

    shouldUpdateSearch = this.isFiltered();

    if (shouldUpdateSearch) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { filter: this.filterMask },
        replaceUrl: true,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { filter: null },
        replaceUrl: true,
        queryParamsHandling: 'merge',
      });
    }

    this.messageBusService.send({ type: 'event:profile-filtered', data: shouldUpdateSearch });
  }

  // extract and group labels
  private extractSectionLabels(sectionName) {
    const labelKeys = {},
      labels = [];

    this.incidents.applyToSummaries(extractLabel, sectionName);
    this.structuredData.applyLabels(extractLabel, sectionName);

    return labels;

    function extractLabel(summary) {
      let label;
      const labelName = summary.label,
        labelKey = summary.labelKey;

      if (!labelKeys[labelKey]) {
        label = {
          kind: summary.kind,
          labelKey: labelKey,
          label: labelName,
          severity: summary.severity,
          translatedClassification: labelName,
        };
        labels.push(label);
        labelKeys[labelKey] = label;
      }
    }
  }

  private convertToNestedArray(sections, configuredSections) {
    const sectionsWithLabels = [];

    Object.keys(sections).forEach((sectionName) => {
      let data, labels, configuration;

      labels = sections[sectionName].map(function (label) {
        return {
          kind: label.kind,
          labelKey: label.labelKey,
          label: label.label,
          severity: label.severity,
          translatedClassification: label.translatedClassification,
        };
      });

      configuration = configuredSections.filter(function (configured) {
        if (sectionName === 'other') {
          sectionName = 'references';
        }
        return configured.metadata.sectionId === sectionName;
      })[0];

      data = {
        name: sectionName,
        labels: this.orderLabelsByKind(sectionName, labels),
      };

      data.isConfigured = !!configuration;

      if (data.isConfigured) {
        data = Object.assign(data, configuration);
      }

      sectionsWithLabels.push(data);
    });
    return sectionsWithLabels;
  }

  private static makeDispositionsSpec() {
    return {
      escalatedOnly: undefined,
      confirmedOnly: undefined,
      adjudicatedOnly: undefined,
      excludeAdjudicated: undefined,
      hideConfirmed: undefined,
      hideEscalated: undefined,
    };
  }
}
