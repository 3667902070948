import { Injectable } from '@angular/core';
import RelationshipService from '../common-services/relationship-service.factory';
import IncidentsService from '../themed-entity/incidents.service';
import StructuredData from '../themed-entity/structured-data.service';

@Injectable({
  providedIn: 'root',
})
export default class DynamicAdjudicationService {
  private toAdjudicate = [];
  private adjudicationMode;
  private selectedCount = 0;

  constructor(
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private relationshipService: RelationshipService
  ) {}

  getMode() {
    return this.adjudicationMode;
  }

  incrementCount() {
    return this.selectedCount++;
  }

  setMode(mode) {
    this.adjudicationMode = mode;
  }

  isDynamicAdjudicationActive() {
    return !!this.adjudicationMode;
  }

  decrementCount() {
    this.selectedCount--;
  }

  getCount() {
    return this.selectedCount;
  }

  clear() {
    let structuredData;

    this.adjudicationMode = null;
    this.selectedCount = 0;
    this.toAdjudicate.splice(0);

    this.incidents.get().forEach(function (incident) {
      incident.selected = false;
    });

    structuredData = this.structuredData.get();
    Object.keys(structuredData).forEach(function (key) {
      structuredData[key]?.forEach(function (data) {
        data.selected = false;
      });
    });

    this.relationshipService.getLocations().forEach(clearSelected);
    this.relationshipService.getPeople().forEach(clearSelected);
    this.relationshipService.getCompanies().forEach(clearSelected);
  }

  setAdjudications(adjudications) {
    this.toAdjudicate.splice(0);
    Array.prototype.push.apply(this.toAdjudicate, adjudications);
  }

  selectItemsOfType(adjudicationType, section, classification) {
    let items,
      mode = this.getMode(),
      filteredItemsPresent;

    if ('INCIDENT' === adjudicationType) {
      items = this.incidents.find(section, classification);
    } else if ('RELATIONSHIP' === adjudicationType) {
      items = section;
    } else {
      items = this.structuredData.find(section, classification);
    }
    items.forEach((discovery) => {
      if (!discovery.filtered) {
        let data = (!classification && discovery.relationship) || discovery;
        if (
          (mode === 'CONFIRM' && !data.confirmed) ||
          (mode === 'ESCALATE' && !data.escalated) ||
          (mode === 'REMOVE' && !data.disabled) ||
          mode === 'COMMENT'
        ) {
          data.selected = true;
          this.incrementCount();
        }
      } else {
        filteredItemsPresent = true;
      }
    });
    return filteredItemsPresent;
  }

  getAdjudications() {
    return this.toAdjudicate;
  }
}

function clearSelected(entity) {
  if ((entity.relationship || {}).selected) {
    entity.relationship.selected = false;
  }
}
