import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import utils from '../../../exiger/utils';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import OiqProperties from '../common-services/oiq-properties.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import { ActivatedRoute, Params } from '@angular/router';
import ToArrayPipe from '../ddiq-filters/to-array.pipe';
import AddressPipe from '../ddiq-filters/address.pipe';

@Component({
  selector: 'ddiq-audit',
  templateUrl: './ddiq-audit.component.tpl.html',
})
export default class AuditComponent implements OnInit {
  auditQueryToggles: any[];
  isLoading: boolean;
  noAuditInfo: boolean;
  forPrint: boolean;
  enableLinks: boolean;
  sort: object;
  decodeURI: object;
  audit:
    | {
        [k: string]: any;
      }
    | undefined;
  summary:
    | {
        [k: string]: any;
      }
    | undefined;
  warningMessageTxt: string;
  pdfUrl: string;
  filter: string;
  notSearchedOrder: string;
  errorOrder: string;
  searchedOrder: string;
  private window: any;

  constructor(
    private windowRef: WindowRefService,
    private translatef: TranslatefPipe,
    private oiqProperties: OiqProperties,
    private urlGenerator: UrlGenerator,
    private route: ActivatedRoute,
    private toArray: ToArrayPipe,
    private address: AddressPipe
  ) {
    this.window = windowRef.nativeWindow;
    this.auditQueryToggles = [];
    this.isLoading = true;
    this.noAuditInfo = false;
    this.sort = {
      error: { orderBy: 'sourceName' },
      searched: { orderBy: 'sourceName' },
      notSearched: { orderBy: 'sourceName' },
    };

    if (this.isPrint()) {
      this.forPrint = true;
    }

    this.decodeURI = decodeURI;
    this.enableLinks = this.oiqProperties.displayAuditLinksEnabled || !this.forPrint;
  }

  ngOnInit() {
    this.clearFilter();

    this.route.data.subscribe((data) => {
      const auditData = data.preLoad[1],
        summaryData = data.preLoad[2];

      this.loadAuditAndSummaryData(auditData, summaryData);
    });
  }

  private isPrint() {
    return utils.getParameter('print', this.window.location.hash);
  }

  loadAuditAndSummaryData(crawlAudit, entity) {
    this.route.params.subscribe((params: Params) => {
      const entityType = params.entityType;
      const entityId = params.entityId;

      this.isLoading = false;
      this.noAuditInfo = !crawlAudit || !crawlAudit.sectionDetails.GENERAL;

      if (this.noAuditInfo) {
        this.warningMessageTxt =
          'No audit information available' +
          (entity.isImportedWithoutACrawlPlan ? ' because this profile was imported without a crawl plan.' : '.');
      } else {
        this.translateSourceName(crawlAudit.sources.searched);
        this.translateSourceName(crawlAudit.sources.notSearched);

        this.audit = {
          queries: crawlAudit.queries,
          querieserror: crawlAudit.querieserror,
          sources: convertSourcesByWorkerCategories(crawlAudit.sources), // deduplicate sources by sourceId
          warnings: this.convertWarnings(crawlAudit.errors),
          totalWebSourcesAnalyzed: crawlAudit.totalWebSourcesAnalyzed,
          crawlStart: crawlAudit.crawlStart,
          crawlEnd: crawlAudit.crawlEnd,
          submitted: crawlAudit.submitted,
          stamped: this.convertStamped(crawlAudit.stampedProperties),
        };

        this.audit.sectionDetails = {
          GENERAL: this.convertSection(crawlAudit.sectionDetails.GENERAL),
          REGULATORY: this.convertSection(crawlAudit.sectionDetails.REGULATORY),
          ADVERSE: this.convertSection(crawlAudit.sectionDetails.ADVERSE),
          LEGAL: this.convertSection(crawlAudit.sectionDetails.LEGAL),
          NOTEWORTHY: this.convertSection(crawlAudit.sectionDetails.NOTEWORTHY),
          OTHER: this.convertSection(crawlAudit.sectionDetails.OTHER),
        };

        this.audit.sectionVisibility = {
          GENERAL:
            entityType === 'company' ? this.oiqProperties.isDisplayCorporate : this.oiqProperties.isDisplayBasicInfo,
          REGULATORY: this.oiqProperties.isDisplayRegulatory,
          ADVERSE: this.oiqProperties.isDisplayAdverse,
          LEGAL: this.oiqProperties.isDisplayLegal,
          NOTEWORTHY: this.oiqProperties.isDisplayNoteworthy,
          OTHER: this.oiqProperties.isDisplayReferences,
        };

        this.audit.sectionTotal = this.getSectionTotal();
        this.pdfUrl = this.urlGenerator.generateAuditPdf(entityType, entityId);
        this.summary = entity;
        this.summary.fullName = this.getName(entity, entityType);
      }

      setTimeout(() => {
        this.window.status = 'oiqLoadComplete';
      }, 3000);
    });
  }

  translateSourceName(sources) {
    (sources || []).forEach((source) => {
      source.sourceName = this.translatef.transform(source.sourceName);
    });
  }

  convertWarnings(records) {
    const array = [];
    const uriIndex = {};
    const sourceIndex = {};

    (records || []).forEach((record) => {
      const warning = {
        dateTime: record.errorTime,
        errorCode: record.errorCode,
        errorMessage: record.errorMessage,
        sourceId: record.sourceId,
        sourceName: this.translatef.transform(record.sourceId),
        uri: record.provokingUri,
      };

      array.push(warning);

      if (warning.uri) {
        uriIndex[warning.uri] = warning;
      }

      if (!sourceIndex[warning.sourceId]) {
        sourceIndex[warning.sourceId] = [];
      }

      sourceIndex[warning.sourceId].push(warning);
    });

    return {
      array: array,
      bySource: sourceIndex,
      byUri: uriIndex,
    };
  }

  convertStamped(data) {
    if (!data) {
      return {};
    }

    const stamped: { [excludedEvents: string]: object } = {};
    if (data['events.exclusions']) {
      stamped.excludedEvents = this.parseExcludedEvents(data['events.exclusions']);
    }

    return stamped;
  }

  parseExcludedEvents(data) {
    const split = data.split(',');
    const details = {};
    let count = 0;

    for (let i = 0; i < split.length; i++) {
      const code = split[i].trim();
      const eventKey = this.translatef.transform(code, true); // true preserves original, to  detect lack  of translation

      // Skip if there is no translation. Otherwise, add to appropriate box, skipping duplicates
      if (code !== eventKey) {
        if (!details[eventKey]) {
          details[eventKey] = [code];
          count++;
        } else if (details[eventKey].indexOf(code) === -1) {
          details[eventKey].push(code);
        }
      }
    }

    return {
      count: count,
      details: details,
    };
  }

  convertSection(data) {
    if (!data) {
      return {};
    }

    return {
      structuredCounts: this.toArray.transform(data.structuredCounts),
      webContentCounts: this.toArray.transform(data.webContentCounts).sort(function (l, r) {
        if (r.value === l.value) {
          return l.key - r.key;
        }
        return r.value - l.value;
      }),
      totalHits: data.totalHits,
      incidentHits: data.incidentHits,
      open: this.isPrint(),
    };
  }

  getSectionTotal() {
    let sectionTotal = 0;

    Object.keys(this.audit.sectionVisibility).forEach((key) => {
      if (this.audit.sectionVisibility[key]) {
        sectionTotal += this.audit.sectionDetails[key].totalHits;
      }
    });

    return sectionTotal;
  }

  getName(summary, entityType) {
    switch (entityType) {
      case 'property':
        return this.address.transform(summary.address);
      case 'person':
        return summary.name;
      case 'company':
        return summary.name;
    }
  }

  clearFilter() {
    this.filter = '';
  }

  getAuditPdf() {
    this.window.location.href = this.pdfUrl;
  }

  toggleUrls(rowIdx) {
    const idx = this.auditQueryToggles.indexOf(rowIdx);

    if (-1 === idx) {
      this.auditQueryToggles.push(rowIdx);
    } else {
      this.auditQueryToggles.splice(idx, 1);
    }
  }

  showHideAccordionRow(query, queryIdx, result) {
    return query.isAuditable && this.isAuditQueryOpened(queryIdx) && this.wasQuerySearched(result);
  }

  isAuditQueryOpened(rowIdx) {
    return this.auditQueryToggles.indexOf(rowIdx) >= 0 || this.isPrint();
  }

  wasQuerySearched(query) {
    return query.maxResults > 0;
  }

  queryHasError(query) {
    return query.results.some(function (result) {
      return result.errorMessage;
    });
  }

  changeSorting(section, orderBy) {
    const sort = this.sort[section];

    if (sort.orderBy === orderBy) {
      sort.descending = !sort.descending;
    } else {
      sort.orderBy = orderBy;
      sort.descending = false;
    }
  }

  getOrder(section) {
    const sort = this.sort[section];

    if (sort.descending) {
      return '-' + sort.orderBy;
    }
    return sort.orderBy;
  }

  workerCategories(source) {
    let result = '';

    for (let i = 0; i < source.workerCategories.length; i++) {
      if (result.length) {
        result += ', ';
      }
      result += this.translatef.transform(source.workerCategories[i]);
    }

    return result;
  }

  warningMessage(record) {
    if (Array.isArray(record)) {
      let errorMessages = new Set();

      record.forEach((singleRecord) => {
        errorMessages.add(craftWarningMessage(singleRecord));
      });

      return Array.from(errorMessages).join('; ');
    } else {
      return craftWarningMessage(record);
    }
  }

  trackByIndex(index) {
    return index;
  }
}

/**
 This method is to deduplicate the sources by sourceId,
 for any source if it has more than one entry with same sourceId.
 It will deduplicate that source and make it to one source entry during this process will give preference to
 record that has value 'IDENTIFICATION' in workerCategories and other sources will accumulate counts (matchedCount, rejectedCount).
 Related Jira ID: OIQ-34090
 **/
function convertSourcesByWorkerCategories(sources) {
  Object.keys(sources || {}).forEach(function (bucketKey) {
    const bucketArray = [];
    const bucketIndex = {};
    const statuses = {
      DISABLED: 0,
      NOT_CRAWLED: 1,
      NO_RESULTS: 2,
      NO_MATCHES: 3,
      MATCHED: 4,
    };

    (sources[bucketKey] || []).forEach(function (record) {
      const existing = bucketIndex[record.sourceId];

      // If source already exist, check for IDENTIFICATION workerCategory or accumulate counts (exiting + current record)
      // If source not exist, push this source to main bucketArray
      if (existing) {
        // If already found same source with IDENTIFICATION workerCategory, no need to process this record to accumulate counts
        if (
          existing.workerCategories &&
          existing.workerCategories.length > 0 &&
          existing.workerCategories.indexOf('IDENTIFICATION') > -1
        ) {
          return;
        }

        // If same source and has IDENTIFICATION workerCategory. Take counts from it
        if (
          record.workerCategories &&
          record.workerCategories.length > 0 &&
          record.workerCategories.indexOf('IDENTIFICATION') > -1
        ) {
          existing.matchedCount = record.matchedCount;
          existing.rejectedCount = record.rejectedCount;
          existing.workerCategories = record.workerCategories;

          if ((statuses[existing.status] || -1) < (statuses[record.status] || -1)) {
            existing.status = record.status;
          }
        } else {
          // If same source and doesn't have IDENTIFICATION workerCategory. accumulate counts (exiting + current record)
          existing.matchedCount += record.matchedCount;
          existing.rejectedCount += record.rejectedCount;
          existing.workerCategories = existing.workerCategories
            .concat(record.workerCategories)
            .filter(function (value, index, array) {
              return array.indexOf(value) === index;
            });

          if ((statuses[existing.status] || -1) < (statuses[record.status] || -1)) {
            existing.status = record.status;
          }
        }
      } else {
        // No existing record , add it to bucketArray
        bucketArray.push(record);
        bucketIndex[record.sourceId] = record;
      }
    });

    sources[bucketKey] = bucketArray;
  });

  return sources;
}

function craftWarningMessage(record) {
  if (record.errorMessage) {
    return record.errorMessage;
  } else if (record.uri) {
    return 'We were unable to access this URI: ' + record.uri;
  } else {
    return 'We encountered a problem crawling this source';
  }
}
