import { Component, OnInit } from '@angular/core';
import RiskAssessmentService from './risk-assessment.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import NavTrackingService from '../nav/nav-tracking.service';
import PdfFeatureService from '../pdf/pdf-feature.service';
import OiqProperties from '../common-services/oiq-properties.service';
import TranslateService from '../common-services/translate.service';
import Filter from '../themed-entity/filter.service';
import './img/loading.gif';

@Component({
  selector: 'ddiq-risk-summary',
  templateUrl: './ddiq-risk-summary.component.tpl.html',
})
export default class DdiqRiskSummaryComponent implements OnInit {
  isPdf: Boolean;
  linkIds: any;

  constructor(
    private riskAssessmentService: RiskAssessmentService,
    private messageBusService: MessageBusService,
    private oiqProperties: OiqProperties,
    private navTrackingService: NavTrackingService,
    private translateService: TranslateService,
    private filter: Filter,
    private pdfFeature: PdfFeatureService
  ) {}

  ngOnInit() {
    this.isPdf = this.pdfFeature.isPdfRender();
    this.linkIds = this.getLinkIds();
  }

  getLinkIds() {
    const result = {};
    const sections = this.filter.getSections();
    const typeMappings = {
      watchlists: 'WATCHLIST',
      regulatoryProfiles: 'REGULATORY',
      incidents: 'WEB_CONTENT',
    };

    for (let i = 0; i < sections.length; i++) {
      const labels = sections[i].labels;

      for (let j = 0; j < labels.length; j++) {
        result[getLinkId(labels[j].labelKey, typeMappings[labels[j].kind])] = sections[i].name;
      }
    }

    return result;
  }

  getAssessmentGroups() {
    const groupedAssessments = this.riskAssessmentService.getGroupedAssessments();

    (groupedAssessments || []).forEach((group) => {
      group.forEach((assessment) => {
        this.decorateAssessment(assessment);
        addTranslationLookUpKeys(assessment);
      });
    });
    return groupedAssessments;
  }

  decorateAssessment(assessment) {
    const key = assessment.name;
    const type = assessment.riskType;

    if (key) {
      if (type === 'WEB_CONTENT' && this.linkIds[getLinkId('UNS_' + key, type)]) {
        assessment.key = 'UNS_' + key;
        assessment.translation = this.translateService.translate(assessment.key);
      } else if (this.linkIds[getLinkId(key, type)]) {
        assessment.key = key;
        assessment.translation = this.translateService.translate(assessment.key);
      } else {
        assessment.translation = this.translateService.translate(key);
      }

      assessment.link = getLink(assessment, this.linkIds);
    }
  }

  riskType(assessment) {
    if ((assessment.key || '').indexOf('UNS_') === 0) {
      return '';
    }

    return assessment.riskTypeKey;
  }

  riskSummaryDisplayMode(assessments) {
    let displayMode = '';

    if (this.isPdf) {
      displayMode = 'PDF';
    } else if (assessments.length === 1) {
      displayMode = 'display-single-risk';
    } else if (assessments.length > 1) {
      displayMode = 'display-grouped-risk';
    }

    return displayMode;
  }

  getRiskValues(profileRisk) {
    return this.riskAssessmentService.getRiskValues(profileRisk);
  }

  isRecalculating() {
    return this.riskAssessmentService.isRecalculating();
  }

  isVisible() {
    const assessments = this.riskAssessmentService.getAssessments();
    return this.oiqProperties.profileScoreEnabled && assessments && assessments.length;
  }

  riskSummaryClick(assessment) {
    if (assessment.link) {
      let key = assessment.key;
      const navTo = assessment.link;
      const label = { labelKey: key };

      if (assessment.promotedFrom) {
        key = '';
        label.labelKey = navTo;
      }

      this.navTrackingService.setEventActive(label, this.linkIds[getLinkId(key, assessment.riskType)]);

      //navigate
      this.messageBusService.send({
        type: 'event:navigateTo',
        data: {
          to: navTo,
        },
      });
    }
  }
}

function getLinkId(key, type) {
  if (type) {
    return key + '|' + type;
  }
  return key;
}

function getLink(assessment, linkIds) {
  if (assessment.promotedFrom) {
    return 'related_' + assessment.promotedFrom;
  } else if (assessment.key) {
    return linkIds[getLinkId(assessment.key, assessment.riskType)] + '_' + assessment.key;
  } else if (assessment.name === 'STATE_OWNED_COMPANY' && assessment.riskType === 'OTHER') {
    return 'company_WEBSITE';
  } else if (assessment.name === 'HIGH_RISK_INDUSTRY_CLASSIFICATION' && assessment.riskType === 'OTHER') {
    return 'company_SIC_CODES';
  } else if (assessment.name === 'INCORPORATION_DATE_ALERT') {
    return 'company_corporateRecord';
  } else if (assessment.name === 'FOREIGN_ENTITY' && assessment.riskType === 'OTHER') {
    return 'related_PROPERTY';
  } else if (assessment.name.indexOf('FAR_EXCLUSION') !== -1 && assessment.riskType === 'REGULATORY') {
    return 'regulatory_SAM_GOV_EXCLUSIONS';
  }
  return null;
}

function addTranslationLookUpKeys(assessment) {
  if (assessment.promotedFrom) {
    assessment.promotedFromKey = assessment.promotedFrom + '_PROMOTED_FROM';
  }

  if (assessment.riskType) {
    assessment.riskTypeKey = assessment.riskType + '_RISK_TYPE';
  }
}
