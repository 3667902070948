import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import EntityResolverHelperService from './entity-resolver-helper.service';
import RiskAssessmentService from '../risk/risk-assessment.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable({ providedIn: 'root' })
export default class ProfileResolverService implements Resolve<any> {
  constructor(
    private entityResolverHelperService: EntityResolverHelperService,
    private riskAssessmentService: RiskAssessmentService,
    private oiqProperties: OiqProperties,
    private router: Router,
    private apiFetchService: ApiFetchService
  ) {}

  async resolve(route) {
    let entityId = route.params.entityId;
    let entityType = route.params.entityType;
    let overviewId = route.params.overviewId;

    await this.oiqProperties.apiPropertiesPromise;

    if (this.oiqProperties.isScreenIqEnabled && !route.queryParams['monitor']) {
      const monitor = await this.apiFetchService.getMonitor(entityType, entityId);
      if (monitor && Object.keys(monitor).length) {
        await this.router.navigate(['overview', overviewId, entityType, entityId], {
          queryParams: { monitor: true },
          replaceUrl: true,
        });
      }
    }

    if (entityType === 'location') {
      entityType = 'property';
    }

    return Promise.all([
      this.entityResolverHelperService.validateEntityRoute(overviewId, entityId, entityType),
      this.entityResolverHelperService.preLoadEntity(entityType),
      this.riskAssessmentService.updateAssessments(entityType, entityId),
    ]);
  }
}
