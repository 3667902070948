import { Pipe } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe {
  transform(values: Array<any>, propertyName: string): any {
    if (!Array.isArray(values) || values.length < 1) {
      return values;
    }
    return [...values].sort((firstEl, secondEl) => firstEl[propertyName].localeCompare(secondEl[propertyName]));
  }
}
