import { Component, Input, OnInit } from '@angular/core';
import LexisNexisArticles from '../protected-articles/lexis-nexis-articles.service';

@Component({
  selector: 'ddiq-incident',
  templateUrl: './ddiq-incident.component.tpl.html',
})
export default class IncidentComponent implements OnInit {
  @Input() incident: any;
  @Input() classification: string;

  @Input() displayFirstSeenDate: boolean;
  @Input() displayCreatedDate: boolean;
  @Input() isAdjudicateable: boolean;
  @Input() entityType: string;
  @Input() entityId: string;
  @Input() noReassess: boolean;
  @Input() isDisplayClassifications: boolean;
  @Input() showArticleCacheLinks: boolean;
  @Input() showOtherMentionsOpen: boolean;
  @Input() showReferencesOpen: boolean;
  @Input() showTranslatedSnippets: boolean;

  displayDate: any;
  otherMentions: any;
  hasOtherMentions: boolean;
  hasSecondaryClassifications: boolean;

  constructor(private lexisNexisArticles: LexisNexisArticles) {}

  ngOnInit() {
    this.displayDate = this.displayCreatedDate ? this.incident.oiqCreatedDate : this.incident.lastReportedDate;
    const evidence = this.incident.evidence ? this.incident.evidence.slice(1) : [];
    this.otherMentions = evidence.map((currentEvidence) => currentEvidence.references).flat();
    this.hasOtherMentions = !!this.otherMentions.length;
    this.hasSecondaryClassifications = (this.incident.secondaryClassifications || []).length;
  }

  isLexisNexisUrl(url) {
    return this.lexisNexisArticles.isLexisNexisUrl(url);
  }
}
