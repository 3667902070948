import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import utils from '../../../exiger/utils';

import { Component, OnInit } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import ApiFetchService from '../common-services/api-fetch.service';
import CrawlPlanService from '../common-services/crawlplan.service';
import I18nService from '../common-services/i18n.service';
import OiqProperties from '../common-services/oiq-properties.service';
import PartialUtils from '../common-services/partial-utils.factory';
import ArrayExcludePipe from '../ddiq-filters/array-exclude.pipe';
import MonitorCrawlPlans from '../monitor/monitor-crawlplans.service';
import DdiqProfileCollectionMissingPermissionForSubmissionService from '../profile-collection/ddiq-profile-collection-missing-permission-for-submission.service';
import UserService from '../user/user.service';
import StripNgProps from '../ui/strip-ng-props.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import CompanyBuildService from '../themed-entity/company-build.service';
import BuildValidationService from '../themed-entity/build-validation.service';
import ScreeningFeatureService from '../screening/screening-feature.service';
import { Router } from '@angular/router';

const SUBMISSION = 'SUBMISSION';

@Component({
  selector: 'ddiq-build-company',
  templateUrl: './ddiq-build-company.component.tpl.html',
})
export default class BuildCompanyComponent implements OnInit {
  dateFormat;
  districtEnabled;
  globalModeEnabled;
  extendedSubmissionFields;
  monitoringEnabled;
  crawlPlans;
  countries;
  languages;
  languagesEnabled;
  isMonitored;
  profileRefreshFrequencies;
  profileRefreshEnabled;
  defaultCountry;
  submissionIdFieldRequired: boolean;
  countryFieldRequired: boolean;
  vesselEnabled: boolean;
  lookbackEnabled: boolean;
  showCorrelationDetails;
  lookbackTimeunits = ['', 'DAYS', 'WEEKS', 'MONTHS'];
  registrationNumberEnabled: boolean;
  externalReferenceCodeEnabled: boolean;
  externalReferenceCodeRequired: boolean;
  phoneAndFaxEnabled: boolean;
  idSubmissionConfigs;
  crawlPlanPreselected;
  partsEnabled;
  profileCollectionEnabled: boolean;
  isProfileCollectionInvalid = false;
  profileCollections = [];
  idSubmissionValid;
  DEFAULT_NEXT_URL = '/profiles';

  companyTypes = [
    {
      label: 'Standard',
      value: null,
    },
    {
      label: 'Franchisee',
      value: 'FRANCHISEE',
    },
    {
      label: 'Multinational',
      value: 'MULTINATIONAL',
    },
    {
      label: 'Vessel',
      value: 'VESSEL',
    },
  ];

  didYouMean;
  nameSuggestion;
  existingProfiles;
  suggestion;
  confirm;

  labelPattern;
  datePattern;
  today;

  validating;
  submitting;
  buildParams;
  request;
  buildCompanyModal;
  refreshProfileId;
  updateSeedData;
  isLoading;
  nextUrl;
  canSubmitProfile;
  isScreeningEnabled;
  submittingCage;
  showCageImport;
  cageError;
  cageErrorMessage;

  constructor(
    private oiqProperties: OiqProperties,
    private apiFetchService: ApiFetchService,
    private companyBuildService: CompanyBuildService,
    private i18nService: I18nService,
    private crawlPlanService: CrawlPlanService,
    private PU: PartialUtils,
    private buildValidationService: BuildValidationService,
    private user: UserService,
    private modalTrackingService: ModalTrackingService,
    private ddiqProfileCollectionMissingPermissionForSubmissionService: DdiqProfileCollectionMissingPermissionForSubmissionService,
    private urlGenerator: UrlGenerator,
    private monitorCrawlPlans: MonitorCrawlPlans,
    private stripNgProps: StripNgProps,
    private arrayExcludePipe: ArrayExcludePipe,
    private screeningFeature: ScreeningFeatureService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initData();
    this.resetProfileRefresh(false);

    this.initialLoad();

    this.apiFetchService.getProfileCollections({ currentUserPermissions: 'profile_submit' }).then((data) => {
      this.profileCollections = data.results;
      if (this.profileCollectionEnabled && this.profileCollections.length === 0) {
        this.ddiqProfileCollectionMissingPermissionForSubmissionService.showError();
      }
    });
  }

  initData() {
    this.dateFormat = this.oiqProperties.dateFormat;
    this.districtEnabled = this.oiqProperties.districtEnabled;
    this.globalModeEnabled = this.oiqProperties.globalCompanyEnabled;
    this.extendedSubmissionFields = this.oiqProperties.extendedSubmissionFields;
    this.monitoringEnabled = this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled;
    this.crawlPlans = this.crawlPlanService.getCrawlPlans();
    this.countries = this.i18nService.getCountryNames();
    this.languages = this.i18nService.languages;
    this.languagesEnabled = this.oiqProperties.languageSelectionEnabled;
    this.isMonitored = false;
    this.profileRefreshFrequencies = this.arrayExcludePipe.transform(
      this.oiqProperties.profileRefreshFrequencies,
      'ONCE'
    );
    this.profileRefreshEnabled = this.oiqProperties.profileRefreshEnabled && this.profileRefreshFrequencies.length;
    this.defaultCountry = this.oiqProperties.defaultCountry;
    this.submissionIdFieldRequired =
      this.oiqProperties.submissionIdFieldRequired || this.oiqProperties.existingProfileWarningUseSubmissionIdOnly;
    this.countryFieldRequired = this.oiqProperties.countryFieldRequired;
    this.vesselEnabled = this.oiqProperties.vesselEnabled;
    this.lookbackEnabled = this.oiqProperties.lookbackEnabled;
    this.showCorrelationDetails = this.oiqProperties.showCorrelationDetails;
    this.registrationNumberEnabled = this.oiqProperties.registrationNumberEnabled;
    this.externalReferenceCodeEnabled = this.oiqProperties.externalReferenceCodeEnabled;
    this.externalReferenceCodeRequired = this.oiqProperties.externalReferenceCodeRequired;
    this.phoneAndFaxEnabled = this.oiqProperties.phoneAndFaxEnabled;
    this.idSubmissionConfigs = this.oiqProperties.idSubmissionConfigs;
    for (let idSubmission of this.idSubmissionConfigs) {
      idSubmission.buttonEnabled = false;
      if (idSubmission.name === 'CAGE' && this.oiqProperties.isCageButtonEnabled) {
        idSubmission.buttonEnabled = true;
      }
    }
    this.crawlPlanPreselected = this.oiqProperties.crawlPlanPreselected;
    this.partsEnabled = this.oiqProperties.partsEnabled;
    this.profileCollectionEnabled = this.oiqProperties.isProfileCollectionEnabled;
    this.canSubmitProfile = this.user.canSubmitProfile();
    this.isScreeningEnabled = this.screeningFeature.isEnabled();

    this.idSubmissionValid = !(this.idSubmissionConfigs || []).some((config) => config.required);

    this.nextUrl = this.companyBuildService.getAndClearParentUrl() || this.DEFAULT_NEXT_URL;

    if (this.oiqProperties.dateFormat === 'US') {
      this.labelPattern = 'MM/DD/YYYY';
      this.datePattern = 'mm/dd/yy';
    } else {
      this.labelPattern = 'YYYY-MM-DD';
      this.datePattern = 'yy-mm-dd';
    }

    this.today = new Date();

    this.didYouMean = {
      isOn: this.oiqProperties.didYouMeanOnSubmit,
      toggle: false,
    };

    this.nameSuggestion = {
      isOn: this.oiqProperties.nameSuggestionOnSubmit,
    };

    this.existingProfiles = {
      isOn: this.oiqProperties.existingProfileWarningEnabled,
      useSubmissionId: this.oiqProperties.existingProfileWarningUseSubmissionId,
      useSubmissionIdOnly: this.oiqProperties.existingProfileWarningUseSubmissionIdOnly,
    };

    this.suggestion = {};

    this.confirm = {
      toggle: false,
    };

    this.didYouMean.validate = (modal) => {
      let validationCrawlPlan = this.crawlPlanService.getValidationCrawlPlan(
        this.buildParams.profiles[0].crawlPlanType
      );
      this.nameSuggestion.inProcess = false;

      if (!this.didYouMean.isOn || !validationCrawlPlan) {
        this.buildProfile(modal);
      } else {
        this.buildParams.profiles[0].validationCrawlPlanType = validationCrawlPlan;
        this.validating = true;
        this.suggestion.headerMessage = '';

        this.didYouMean.handled = false;
        this.didYouMean.warn = false;
        this.didYouMean.completions = [];
        this.didYouMean.snapshots = [];
        this.suggestion.message = 'Validating...';
        this.didYouMean.inProcess = true;

        // set via confirmationDialog directive;
        this.didYouMean.toggle = true;

        var consolidatedBuildParams = this.consolidateFormInput(this.buildParams);

        var didYouMeanSuccessCallback = (data) => {
          this.didYouMeanSuccess(data);
        };

        var didYouMeanErrorCallback = (data) => {
          this.didYouMeanError(modal, data);
        };

        if (this.buildParams.submissionId) {
          this.buildParams.profiles[0].submissionId = this.buildParams.submissionId;
        }

        this.apiFetchService
          .didYouMeanSubmission('company', utils.compact(consolidatedBuildParams.profiles[0]))
          .then(didYouMeanSuccessCallback, didYouMeanErrorCallback);
      }
    };

    this.nameSuggestion.generate = (modal) => {
      this.existingProfiles.inProcess = false;
      this.nameSuggestion.suggestions = [];
      this.nameSuggestion.handled = false;
      if (!this.nameSuggestion.isOn || !this.buildParams.profiles[0].companyName) {
        this.didYouMean.validate(modal);
      } else {
        let companyNames, countries;

        this.suggestion.message = 'Generating name suggestions...';
        this.suggestion.headerMessage = '';
        this.nameSuggestion.inProcess = true;
        this.nameSuggestion.generating = true;

        companyNames = (this.buildParams.profiles[0].dbaNamesHolder || [])
          .filter((dbaNameHolder) => {
            return dbaNameHolder.name;
          })
          .map(function (dbaNameHolder) {
            return dbaNameHolder.name;
          })
          .concat(this.buildParams.profiles[0].formerNames || []);

        companyNames.push(this.buildParams.profiles[0].companyName);

        countries = this.collectCountries([], this.buildParams.profiles[0].locations || []);
        countries = this.collectCountries(countries, this.buildParams.profiles[0].countriesOfBusiness || []);
        if (
          this.buildParams.profiles[0].countryOfIncorporation &&
          this.buildParams.profiles[0].countryOfIncorporation.country &&
          countries.indexOf(this.buildParams.profiles[0].countryOfIncorporation.country) === -1
        ) {
          countries.push(this.buildParams.profiles[0].countryOfIncorporation.country);
        }

        if (this.buildParams.profiles[0].companyType === 'MULTINATIONAL') {
          countries = ['*'];
        }

        var nameSuggestionSuccessCallback = (data) => {
          this.nameSuggestionSuccess(modal, data);
        };

        var nameSuggestionErrorCallback = () => {
          this.nameSuggestionError(modal);
        };
        this.apiFetchService
          .nameSuggestionSubmission('company', {
            names: companyNames,
            countries: countries,
          })
          .then(nameSuggestionSuccessCallback, nameSuggestionErrorCallback);
      }
    };

    this.existingProfiles.search = (modal) => {
      if (!this.existingProfiles.isOn || !this.buildParams.profiles[0].companyName) {
        this.nameSuggestion.generate(modal);
      } else {
        this.existingProfiles.suggestions = [];
        this.existingProfiles.headerMessage = '';
        this.existingProfiles.handled = false;
        this.suggestion.message = 'Checking existing profiles...';
        this.existingProfiles.searching = true;
        this.existingProfiles.inProcess = true;

        var existingProfilesSuccessCallback = (data) => {
          this.existingProfileWarningSuccess(modal, data);
        };

        var existingProfilesErrorCallback = () => {
          this.existingProfileWarningError(modal);
        };

        var name = this.buildParams.profiles[0].companyName;
        var submissionId = this.buildParams.submissionId;
        var useSubmissionId = this.existingProfiles.useSubmissionId;
        var useSubmissionIdOnly = this.existingProfiles.useSubmissionIdOnly;

        this.apiFetchService
          .findExistingEntities('company', name, submissionId, useSubmissionId, useSubmissionIdOnly)
          .then(existingProfilesSuccessCallback, existingProfilesErrorCallback);
      }
    };
  }

  /**
   * did you mean success
   */
  didYouMeanSuccess(data) {
    var i, completion;

    this.didYouMean.snapshots = data.snapshots;
    if (data.completions && data.completions.length > 0) {
      if (data.status === 'TOO_MANY_RESULTS') {
        this.suggestion.message =
          'Too many listings were found. If you do not see your subject, please refine your search criteria by adding more information.';
        this.didYouMean.warn = true;
      } else {
        this.suggestion.message = 'We found some similar listings:';
      }

      // have to add the correct mappings as our PostalCodeSerializer is a goofed up
      for (i = 0; i < data.completions.length; i++) {
        completion = data.completions[i];
        if (completion.address) {
          completion.address.stateOrProvince = completion.address.stateProvince;
          completion.address.zipOrPostalCode = completion.address.zipPostalCode;
        }
      }
      this.didYouMean.completions = data.completions;
      this.didYouMean.handled = false;
    } else if (data.status === 'NO_APPLICABLE_SOURCES') {
      this.suggestion.message =
        "No suggestions were found. Please verify the data entered and click 'Build Profile' to continue.";
      this.didYouMean.warn = true;
    } else if (data.status === 'ERROR') {
      this.suggestion.headerMessage = 'Validation Unavailable';
      this.suggestion.message =
        "No suggestions were found. Please verify the data entered and click 'Build Profile' to continue.";
      this.didYouMean.warn = true;
    } else {
      this.suggestion.message =
        "No suggestions were found. Please verify the data entered and click 'Build Profile' to continue.";
    }
    this.validating = false;
  }

  didYouMeanError(modal, data) {
    if (data.status === 400) {
      this.cancelDialog(modal);
      this.submitting = false;
      this.validating = false;
      if (data.error?.message?.indexOf('mal-formed') > -1) {
        this.modalTrackingService.openError('websiteMalformedError');
      } else {
        this.modalTrackingService.openError('crawlConfigurationError');
      }
    } else {
      this.suggestion.headerMessage = 'Validation Unavailable';
      this.suggestion.message = 'We were unable to verify the data entered.  Do you wish to submit anyway, or cancel?';
      this.validating = false;
    }
  }

  nameSuggestionSuccess(modal, data) {
    this.nameSuggestion.handled = false;
    this.nameSuggestion.generating = false;
    if (data.length > 0) {
      this.suggestion.message = 'Select alternative names to add:';
      this.nameSuggestion.suggestions = data;
    } else {
      this.didYouMean.validate(modal);
    }
  }

  nameSuggestionError(modal) {
    this.nameSuggestion.handled = false;
    this.nameSuggestion.generating = false;
    this.didYouMean.validate(modal);
  }

  existingProfileWarningSuccess(modal, data) {
    var i, result;
    this.existingProfiles.handled = false;
    this.existingProfiles.searching = false;
    if (data.totalMatchCount > 0) {
      this.suggestion.message = 'We found some existing profiles:';
      for (i = 0; i < data.totalMatchCount; i++) {
        result = data.results[i];
        result.reportUrl = this.urlGenerator.generateReport(result.oiqEntityId, 'company', result.oiqEntityId);
      }
      this.existingProfiles.suggestions = data.results;
    } else {
      this.nameSuggestion.generate(modal);
    }
  }

  existingProfileWarningError(modal) {
    this.existingProfiles.handled = false;
    this.existingProfiles.generating = false;
    this.nameSuggestion.generate(modal);
  }

  collectCountries(countries, addresses) {
    var newCountries;
    newCountries = addresses
      .filter(function (address) {
        return address.country;
      })
      .map(function (address) {
        return address.country;
      })
      .filter(function (country) {
        return countries.indexOf(country) === -1;
      });
    return countries.concat(newCountries);
  }

  initRefreshRequest() {
    return {
      isRefreshable: false,
      frequency: '',
      dates: {
        options: {
          after: {
            dateFormat: this.datePattern,
            minDate: this.today,
          },
        },
      },
    };
  }

  resetProfileRefresh(enabled) {
    if (!enabled) {
      this.request = this.initRefreshRequest();
    }
  }

  /**
   * creates a new object for the html form
   * @returns {profiles: *[]}
   */
  createBuildForm() {
    var crawlPlans = this.getAvailableCrawlPlans(),
      submission = {
        profiles: [
          {
            people: [{ alternativeNamesHolder: [{}], relatedPeople: [{}] }],
            companies: [{}],
            locations: [{ country: this.defaultCountry }, {}],
            dbaNamesHolder: [{}],
            formerNames: [],
            phoneNumbers: [{ label: 'company' }],
            faxNumbers: [{ label: 'fax' }],
            identificationNumbers: [{ identificationType: SUBMISSION }],
            snapshots: [],
            validatedRecords: [],
            countriesOfBusiness: [{}],
            countryOfIncorporation: { label: 'INCORPORATED_IN' },
            monitored: false,
            crawlPlanType: this.determineSelectedCrawlPlan(crawlPlans),
            lookbackConfig: { timeunit: '', lookbackDate: {} },
            crawlScheduleConfig: { previewEnabled: true },
            relatedCompanies: [{}],
            partsHolder: [],
            requestedLanguages: this.languagesEnabled ? [this.languages[0]] : [],
            companyType: null,
            profileCollectionKeys: [],
            dateFounded: {},
          },
        ],
      };

    return submission;
  }

  getAvailableCrawlPlans() {
    return this.oiqProperties.crawlPlans || [];
  }

  determineSelectedCrawlPlan(crawlPlans) {
    if (crawlPlans.length === 0) {
      // degenerate case; there should always be a default crawl plan
      return 'ddiq';
    } else if (crawlPlans.length === 1 || this.oiqProperties.crawlPlanPreselected) {
      return crawlPlans[0];
    } else {
      return null;
    }
  }

  /**
   * clears the form and checks for running crawls
   */
  refreshBuildPage() {
    this.buildCompanyModal = { open: false };
    this.submitting = false;
    this.buildParams = this.createBuildForm();
    this.validateProfileCollection();
  }

  initialLoad() {
    var i,
      j,
      location,
      person,
      company,
      companyToBuild = this.companyBuildService.getCompanyToBuild(),
      personToBuild,
      address;

    this.refreshBuildPage();

    if (companyToBuild) {
      if (companyToBuild.requestedLanguages) {
        this.buildParams.profiles[0].requestedLanguages = companyToBuild.requestedLanguages;
      }
      if (companyToBuild.refreshProfileId) {
        this.refreshProfileId = companyToBuild.refreshProfileId;
        this.updateSeedData = true;
      }
      if (companyToBuild.crawlPlanType) {
        if (this.getAvailableCrawlPlans().indexOf(companyToBuild.crawlPlanType) >= 0) {
          this.buildParams.profiles[0].crawlPlanType = companyToBuild.crawlPlanType;
        } else {
          this.buildParams.profiles[0].crawlPlanType = this.getAvailableCrawlPlans()[0];
        }
      }
      if (companyToBuild.externalReferenceCode) {
        this.buildParams.profiles[0].externalReferenceCode = companyToBuild.externalReferenceCode;
      }

      this.buildParams.profiles[0].parentCompanyEntityId = companyToBuild.parentCompanyEntityId;
      this.buildParams.profiles[0].companyName = companyToBuild.name ? companyToBuild.name.trim() : companyToBuild.name;
      this.buildParams.profiles[0].locations = [];
      this.buildParams.profiles[0].people = [];
      this.buildParams.profiles[0].companies = [];
      this.buildParams.profiles[0].countriesOfBusiness = [];
      if (companyToBuild.submissionId) {
        this.buildParams.submissionId = companyToBuild.submissionId;
      }
      if (companyToBuild.website) {
        this.buildParams.profiles[0].website = companyToBuild.website;
      }
      if (companyToBuild.address) {
        this.buildParams.profiles[0].locations.unshift({
          streetAddress: companyToBuild.address.streetAddress,
          stateOrProvince: companyToBuild.address.stateProvince,
          zipOrPostalCode: companyToBuild.address.zipPostalCode,
          city: companyToBuild.address.city,
          country: this.i18nService.matchCountryName(companyToBuild.address.country),
          district: companyToBuild.address.district,
          buildingName: companyToBuild.address.buildingName,
        });
      }
      if (companyToBuild.otherLocations) {
        for (i = 0; i < companyToBuild.otherLocations.length; i++) {
          location = {
            streetAddress: companyToBuild.otherLocations[i].address.streetAddress,
            stateOrProvince: companyToBuild.otherLocations[i].address.stateProvince,
            zipOrPostalCode: companyToBuild.otherLocations[i].address.zipPostalCode,
            city: companyToBuild.otherLocations[i].address.city,
            country: this.i18nService.matchCountryName(companyToBuild.otherLocations[i].address.country),
            district: companyToBuild.otherLocations[i].address.district,
            buildingName: companyToBuild.otherLocations[i].address.buildingName,
          };
          if (companyToBuild.otherLocations[i].source === 'MainAddressSubmission') {
            this.buildParams.profiles[0].locations.unshift(location);
          } else if (companyToBuild.otherLocations[i].source === 'AdditionalLocationSubmission') {
            if (!companyToBuild.otherLocations[i].label) {
              this.buildParams.profiles[0].locations.push(location);
            }
            switch (companyToBuild.otherLocations[i].label) {
              case 'DOING_BUSINESS_IN':
                this.buildParams.profiles[0].countriesOfBusiness.unshift({ country: location.country });
                break;
              case 'INCORPORATED_IN':
                this.buildParams.profiles[0].countryOfIncorporation.country = location.country;
                break;
            }
          }
        }
      }
      for (i = 2; i > this.buildParams.profiles[0].locations.length; i--) {
        this.buildParams.profiles[0].locations.push({});
      }
      if (companyToBuild.companies && companyToBuild.companies.length > 0) {
        for (i = 0; i < companyToBuild.companies.length; i++) {
          company = companyToBuild.companies[i];
          company.companyName = company.name;
          if (company.mainAddress) {
            company.address = {
              streetAddress: company.mainAddress.streetAddress,
              city: company.mainAddress.city,
              stateOrProvince: company.mainAddress.stateProvince,
              zipOrPostalCode: company.mainAddress.zipPostalCode,
              country: this.i18nService.matchCountryName(company.mainAddress.country),
            };
          } else if (company.locations && company.locations.length > 0) {
            company.address = {
              streetAddress: company.locations[0].streetAddress,
              city: company.locations[0].city,
              stateOrProvince: company.locations[0].stateProvince,
              zipOrPostalCode: company.locations[0].zipPostalCode,
              country: this.i18nService.matchCountryName(company.locations[0].country),
            };
          }
          this.buildParams.profiles[0].companies.push(company);
        }
      }
      if (companyToBuild.people && companyToBuild.people.length > 0) {
        for (i = 0; i < companyToBuild.people.length; i++) {
          personToBuild = companyToBuild.people[i];
          person = {
            currentPosition: { jobTitle: personToBuild.currentJobTitle },
            alternativeNamesHolder: [{}],
            relatedPeople: [{}],
          };
          if (personToBuild.name) {
            person.personName = personToBuild.name;
          } else {
            person.personName = [personToBuild.firstName, personToBuild.middleName, personToBuild.lastName]
              .filter(Boolean)
              .join(' ');
          }
          if (this.oiqProperties.extendedSubmissionFields) {
            person.birthdate = companyToBuild.people[i].birthdate || {};
            person.age = companyToBuild.people[i].age;
            person.sex = companyToBuild.people[i].sex;
            person.nationality = companyToBuild.people[i].nationality;
            if (companyToBuild.people[i].location) {
              person.currentAddress = {
                streetAddress: companyToBuild.people[i].location.streetAddress,
                stateOrProvince: companyToBuild.people[i].location.stateProvince,
                zipOrPostalCode: companyToBuild.people[i].location.zipPostalCode,
                city: companyToBuild.people[i].location.city,
                country: this.i18nService.matchCountryName(companyToBuild.people[i].location.country),
                district: companyToBuild.people[i].location.district,
                buildingName: companyToBuild.people[i].location.buildingName,
              };
            }

            if (companyToBuild.people[i].birthplace) {
              person.birthplace = {
                city: companyToBuild.people[i].birthplace.city,
                stateOrProvince: companyToBuild.people[i].birthplace.stateProvince,
                country: companyToBuild.people[i].birthplace.country,
              };
            }
            if (companyToBuild.people[i].alternativeNames) {
              person.alternativeNamesHolder = [];
              for (j = 0; j < companyToBuild.people[i].alternativeNames.length; j++) {
                person.alternativeNamesHolder.push({
                  name: companyToBuild.people[i].alternativeNames[j],
                });
              }
            }
            if (companyToBuild.people[i].relatedPeople) {
              person.relatedPeople = [];
              for (j = 0; j < companyToBuild.people[i].relatedPeople.length; j++) {
                person.relatedPeople.push({
                  name: companyToBuild.people[i].relatedPeople[j].name,
                  relationship: companyToBuild.people[i].relatedPeople[j].relationship,
                });
              }
            }
          }
          this.buildParams.profiles[0].people.push(person);
        }
      } else {
        person = {
          relatedPeople: [{}],
        };
        this.buildParams.profiles[0].people.push(person);
      }
      if (companyToBuild.identificationNumbers) {
        const ids = companyToBuild.identificationNumbers,
          profiles = this.buildParams.profiles;

        if (ids.some((id) => id.identificationType === SUBMISSION)) {
          profiles[0].identificationNumbers = ids.sort((id) => {
            if (id.identificationType === SUBMISSION) {
              return -1;
            }
            return 0;
          });
        } else {
          profiles[0].identificationNumbers = ids;
          ids.unshift({ identificationType: SUBMISSION });
        }
      }
      if (companyToBuild.dbaNames) {
        this.buildParams.profiles[0].dbaNamesHolder = [];
        for (i = 0; i < companyToBuild.dbaNames.length; i++) {
          this.buildParams.profiles[0].dbaNamesHolder.push({ name: companyToBuild.dbaNames[i] });
        }
      }
      if (companyToBuild.phoneNumbers && companyToBuild.phoneNumbers.length) {
        this.buildParams.profiles[0].faxNumbers = [];
        this.buildParams.profiles[0].phoneNumbers = [];
        for (i = 0; i < companyToBuild.phoneNumbers.length; i++) {
          if (companyToBuild.phoneNumbers[i].label === 'FAX') {
            this.buildParams.profiles[0].faxNumbers.push({
              number: companyToBuild.phoneNumbers[i].number,
              label: companyToBuild.phoneNumbers[i].label,
            });
          } else {
            this.buildParams.profiles[0].phoneNumbers.push({
              number: companyToBuild.phoneNumbers[i].number,
              label: companyToBuild.phoneNumbers[i].label,
            });
          }
        }
        if (this.buildParams.profiles[0].faxNumbers.length === 0) {
          this.buildParams.profiles[0].faxNumbers.push({ label: 'fax' });
        }
        if (this.buildParams.profiles[0].phoneNumbers.length === 0) {
          this.buildParams.profiles[0].phoneNumbers.push({ label: 'company' });
        }
      }

      this.buildParams.profiles[0].companyType = companyToBuild.companyType || null;

      if (companyToBuild.yearFounded) {
        this.buildParams.profiles[0].dateFounded = { year: companyToBuild.yearFounded };
      }
      this.buildParams.profiles[0].organizationType = companyToBuild.organizationType;
      if (companyToBuild.parts) {
        this.buildParams.profiles[0].partsHolder = [];
        for (i = 0; i < companyToBuild.parts.length; i++) {
          this.buildParams.profiles[0].partsHolder.push({ name: companyToBuild.parts[i] });
        }
      }

      if (companyToBuild.relatedCompanies && companyToBuild.relatedCompanies.length) {
        this.buildParams.profiles[0].relatedCompanies = [];
        for (i = 0; i < companyToBuild.relatedCompanies.length; i++) {
          address = companyToBuild.relatedCompanies[i].address || {};
          this.buildParams.profiles[0].relatedCompanies.push({
            name: companyToBuild.relatedCompanies[i].name,
            relationship: companyToBuild.relatedCompanies[i].relationship,
            address: {
              streetAddress: address.streetAddress,
              city: address.city,
              stateOrProvince: address.stateProvince,
              zipOrPostalCode: address.zipPostalCode,
              country: address.country,
            },
          });
        }
      }
      if (companyToBuild.collections) {
        companyToBuild.collections.forEach((pc) =>
          this.buildParams.profiles[0].profileCollectionKeys.push(pc.profileCollectionKey)
        );
      }
    }
  }

  consolidateFormInput(buildParams) {
    var i,
      j,
      value,
      people,
      companies,
      alternativeNamesHolder,
      copiedBuildParams = JSON.parse(JSON.stringify(buildParams)),
      profile = copiedBuildParams.profiles[0];

    // convert our UI people submissions into having a currentPosition at the current company
    people = profile.people;
    for (i = 0; i < people.length; i++) {
      if (people[i].currentPosition !== undefined) {
        people[i].currentPosition.companyName = profile.companyName;
      } else {
        people[i].currentPosition = { companyName: profile.companyName };
      }

      alternativeNamesHolder = people[i].alternativeNamesHolder;
      if (alternativeNamesHolder && alternativeNamesHolder.length > 0) {
        people[i].alternativeNames = [];
        for (j = 0; j < alternativeNamesHolder.length; j++) {
          value = alternativeNamesHolder[j].name;
          if (value) {
            people[i].alternativeNames.push(value);
          }
        }
      }
    }

    companies = profile.companies;
    companies.forEach((company) => {
      var companyLocation;
      if (company.address !== undefined) {
        companyLocation = {
          streetAddress: company.address.streetAddress,
          stateOrProvince: company.address.stateOrProvince,
          zipOrPostalCode: company.address.zipOrPostalCode,
          city: company.address.city,
          country: this.i18nService.matchCountryName(company.address.country),
        };

        if (company.locations === undefined) {
          company.locations = [];
        }
        var foundLocation = false;
        if (company.locations && company.locations.length > 0) {
          foundLocation = company.locations.some(function (location) {
            return (
              location.streetAddress !== companyLocation.streetAddress &&
              location.stateOrProvince !== companyLocation.stateOrProvince &&
              location.zipOrPostalCode !== companyLocation.zipOrPostalCode &&
              location.city !== companyLocation.city &&
              location.country !== companyLocation.country
            );
          });
        }
        if (!foundLocation) {
          company.locations.push(companyLocation);
        }
        delete company.address;
      }
    });

    // convert our UI object in to the submission model by converting dbaNamesHolder into dbaNames which is just an array of strings
    profile.dbaNames = profile.dbaNamesHolder
      .filter(function (dbaNameHolder) {
        return dbaNameHolder.name;
      })
      .map(function (dbaNameHolder) {
        return dbaNameHolder.name;
      });

    profile.parts = this.collectParts();

    profile.phoneNumbers = profile.phoneNumbers
      .filter((phoneNumber) => {
        return !this.checkPhoneNumberType(phoneNumber, 'fax');
      })
      .concat(
        profile.faxNumbers.filter(function (faxNumber) {
          return faxNumber.value;
        })
      );

    profile.locations = profile.locations
      .filter((location) => {
        return (
          !this.checkAddressType(location, 'DOING_BUSINESS_IN') && !this.checkAddressType(location, 'INCORPORATED_IN')
        );
      })
      .concat(!profile.companyType && profile.countryOfIncorporation.country ? profile.countryOfIncorporation : [])
      .concat(
        profile.companyType
          ? []
          : profile.countriesOfBusiness
              .filter(function (countryOfBusiness) {
                return countryOfBusiness.country;
              })
              .map(function (countryOfBusiness) {
                return {
                  country: countryOfBusiness.country,
                  label: 'DOING_BUSINESS_IN',
                };
              })
      );

    profile.sparseSubmission = this.isSparseProfile();

    if (this.request && this.request.frequency) {
      profile.profileRefreshConfig = {
        frequency: this.request.frequency,
      };
      if (this.request.from) {
        profile.profileRefreshConfig.from = this.request.from;
      }
    }

    profile.identificationNumbers = profile.identificationNumbers.filter((id) => !!id.number);

    return copiedBuildParams;
  }

  checkPhoneNumberType(phoneNumber, label) {
    return phoneNumber && phoneNumber.label && label && phoneNumber.label.toUpperCase() === label.toUpperCase();
  }

  checkAddressType(address, label) {
    return address && address.label && label && address.label.toUpperCase() === label.toUpperCase();
  }

  buildProfile(modal, dialog?) {
    this.submitting = true;
    this.didYouMean.inProcess = false;

    var consolidatedBuildParams = this.consolidateFormInput(this.buildParams);

    var successCallback = (data) => {
      this.submitSuccess(modal, dialog, data);
    };

    var errorCallback = (data) => {
      this.submitError(modal, dialog, data);
    };

    if (this.refreshProfileId) {
      this.apiFetchService
        .updateSeedData('company', this.refreshProfileId, consolidatedBuildParams)
        .then(successCallback, errorCallback);
    } else {
      this.apiFetchService
        .build('company', utils.compact(consolidatedBuildParams))
        .then(successCallback, errorCallback);
    }
  }

  cancelDialog(modal, dialog?) {
    this.buildParams.profiles[0].monitored = false;
    delete this.buildParams.profiles[0].monitorConfig;
    this.didYouMean.handled = false;
    this.didYouMean.inProcess = false;
    this.nameSuggestion.handled = false;
    this.nameSuggestion.inProcess = false;
    this.request = this.initRefreshRequest();
    modal.open = false;
    this.clearSelectedNameSuggestions();
    this.clearCountryOfIncorporationAndDoingBusiness();
    if (dialog) {
      dialog.toggle = false;
    }
  }

  clearSelectedNameSuggestions() {
    var selectedNameSuggestions;
    selectedNameSuggestions = (this.nameSuggestion.suggestions || [])
      .filter(function (nameSuggestion) {
        return nameSuggestion.selected;
      })
      .map(function (nameSuggestion) {
        return nameSuggestion.suggestion;
      });
    this.buildParams.profiles[0].dbaNamesHolder = this.buildParams.profiles[0].dbaNamesHolder.filter(function (
      dbaNameHolder
    ) {
      return selectedNameSuggestions.indexOf(dbaNameHolder.name) === -1;
    });
    if (this.buildParams.profiles[0].dbaNamesHolder.length === 0) {
      this.buildParams.profiles[0].dbaNamesHolder.push({});
    }
  }

  clearCountryOfIncorporationAndDoingBusiness() {
    this.buildParams.profiles[0].locations = this.buildParams.profiles[0].locations.filter((location) => {
      return (
        !this.checkAddressType(location, 'DOING_BUSINESS_IN') && !this.checkAddressType(location, 'INCORPORATED_IN')
      );
    });
  }

  hasAdditionalCompanyInfo() {
    if (
      this.buildParams.profiles[0].phoneNumbers[0].number ||
      this.buildParams.profiles[0].faxNumbers[0].number ||
      this.buildParams.profiles[0].companyType === 'FRANCHISEE' ||
      this.buildParams.profiles[0].organizationType ||
      (this.buildParams.profiles[0].dateFounded && this.buildParams.profiles[0].dateFounded.year)
    ) {
      return true;
    }
  }

  hasLocations() {
    var i, location;
    if (this.buildParams.profiles[0].locations.length > 1) {
      for (i = 1; i < this.buildParams.profiles[0].locations.length; i++) {
        location = this.buildParams.profiles[0].locations[i];
        if (
          location.streetAddress ||
          location.stateOrProvince ||
          location.zipOrPostalCode ||
          location.city ||
          location.country
        ) {
          return true;
        }
      }
    }
  }

  hasSubmittedPeople() {
    return this.buildParams.profiles[0].people.some(function (person) {
      return person.personName;
    });
  }

  hasSubmittedCompanies() {
    return this.buildParams.profiles[0].companies.some(function (company) {
      return company.companyName;
    });
  }

  hasIdNumbers(ids) {
    return (ids || []).some((id) => !!id.number && id.identificationType !== 'SUBMISSION');
  }

  isSparseProfile() {
    return this.buildValidationService.isSparseCompanySubmission(
      this.buildParams.profiles[0].locations[0],
      this.buildParams.profiles[0].people
    );
  }

  isCountryRequired(profile) {
    if (typeof this.countryFieldRequired === 'boolean' && !this.countryFieldRequired) {
      return this.countryFieldRequired;
    }

    let isRequired = true,
      isRequiredForIdNumber,
      isRequiredForRegistrationNumber;

    isRequiredForRegistrationNumber = !(
      this.registrationNumberEnabled &&
      profile.identificationNumbers[0].number &&
      profile.countryOfIncorporation.country
    );

    isRequiredForIdNumber = !(
      profile.identificationNumbers.length &&
      profile.identificationNumbers.some(
        (id) => id.number && id.minSeedDataToSearch !== 'COUNTRY' && id.identificationType !== SUBMISSION
      )
    );

    if (!isRequiredForIdNumber || !isRequiredForRegistrationNumber) {
      isRequired = false;
    }

    return isRequired;
  }

  validateProfileCollection(changedModel?: any) {
    this.isProfileCollectionInvalid = false;
    if (!this.profileCollectionEnabled) {
      return;
    }

    if (changedModel !== undefined) {
      this.isProfileCollectionInvalid = !changedModel.length;
    }
  }

  validateIdSubmission(changedModel: any) {
    this.idSubmissionValid = changedModel;
  }

  submit(modal) {
    const ensureMonitorCrawlPlans: Promise<void> = new Promise((resolve) => {
      if (this.updateSeedData || !this.monitoringEnabled) {
        resolve();
        return;
      }

      this.monitorCrawlPlans.loadAvailableCrawlPlans(this.buildParams.profiles[0].crawlPlanType).then(resolve);
    });

    const openModal = () => {
      if (!modal.open) {
        modal.open = true;
        if (!this.updateSeedData) {
          if (this.existingProfiles.isOn) {
            this.existingProfiles.search(modal);
          } else if (this.nameSuggestion.isOn) {
            this.nameSuggestion.generate(modal);
          } else if (this.didYouMean.isOn) {
            this.didYouMean.validate(modal);
          } else {
            this.confirm.toggle = true;
          }
        } else {
          this.confirm.toggle = true;
        }
      }
    };

    // force a call to the server for before doing anything to ensure user is logged in
    this.apiFetchService
      .ping()
      .then(() => ensureMonitorCrawlPlans)
      .then(openModal);
  }

  hasPeople(people) {
    var i, person;

    for (i = 0; people !== undefined && i < people.length; i++) {
      person = people[i];
      if (person.personName || person.name) {
        return true;
      }
    }
    return false;
  }

  hasCompanies(companies) {
    return (companies || []).some(function (company) {
      return company.companyName;
    });
  }

  /**
   * on submission success
   */
  submitSuccess(modal, dialog, data) {
    if (data && data.submissionResult === 'FAILED' && data.results.length === 1) {
      //XXX: OIQ-24541 calling a submitError in a submitSuccess cause
      // backwards compatibility for third party sources that require 2XX http status code
      this.submitError(modal, dialog, data);
    } else {
      this.cancelDialog(modal, dialog);
      this.router.navigate([this.nextUrl]);
    }
  }

  /**
   * on submission error, don't clear the form
   * WR TODO: display some sort of error
   */
  submitError(modal, dialog, data) {
    this.isLoading = false;
    if (data.status === 400) {
      this.cancelDialog(modal, dialog);
      this.submitting = false;
      if (data.error?.message?.indexOf('mal-formed') > -1) {
        this.modalTrackingService.openError('websiteMalformedError');
      } else {
        this.modalTrackingService.openError('crawlConfigurationError');
      }
    } else if (data.status === 409 && this.updateSeedData) {
      this.cancelDialog(modal, dialog);
      this.submitting = false;
      if (data.data && data.data.status === 'COMPLETED') {
        this.modalTrackingService.openError('refreshOutOfSyncError');
      } else {
        this.modalTrackingService.openError('refreshInProgressError');
      }
    } else if (data.submissionResult === 'FAILED') {
      this.cancelDialog(modal, dialog);
      this.submitting = false;
      this.modalTrackingService.openError('submissionError', data.results[0]);
    }
  }

  hasRelatedCompanies() {
    return (this.buildParams.profiles[0].relatedCompanies || []).some(function (company) {
      return company.name;
    });
  }

  hasAdditionalLocations(locations) {
    return (locations || []).some((location, index) => {
      var isNotMainAddress = index > 0,
        locationWithoutNgProps;

      if (isNotMainAddress) {
        locationWithoutNgProps = JSON.parse(this.stripNgProps.strip(location));
        return Object.keys(locationWithoutNgProps).some(function (propName) {
          return locationWithoutNgProps[propName];
        });
      }
    });
  }

  viewExistingReport(reportUrl) {
    window.open(reportUrl, '_blank');
  }

  addPhone() {
    this.buildParams.profiles[0].phoneNumbers.push({ label: 'company' });
  }

  removePhone(phoneIndex) {
    this.buildParams.profiles[0].phoneNumbers.splice(phoneIndex, 1);
  }

  addFax() {
    this.buildParams.profiles[0].faxNumbers.push({ label: 'fax' });
  }

  removeFax(faxIndex) {
    this.buildParams.profiles[0].faxNumbers.splice(faxIndex, 1);
  }

  addDBA() {
    this.buildParams.profiles[0].dbaNamesHolder.push({});
  }

  removeDBA(alternateNameIndex) {
    this.buildParams.profiles[0].dbaNamesHolder.splice(alternateNameIndex, 1);
  }

  mergeSelected() {
    var i, completion, snapshots;
    if (this.didYouMean.handled) {
      snapshots = [];

      for (i = 0; i < this.didYouMean.completions.length; i++) {
        completion = this.didYouMean.completions[i];

        if (completion.selected) {
          if (!this.buildParams.profiles[0].companyName) {
            this.buildParams.profiles[0].companyName = completion.suggestion;
          }
          snapshots = snapshots.concat(completion.snapshots);
          this.buildParams.profiles[0].validatedRecords = this.buildParams.profiles[0].validatedRecords.concat(
            completion.oiqValidationIds
          );
          this.attachSelectedPeople(completion.people);
          this.attachSelectedIdNumbers(completion.identificationNumbers);
        }
      }

      this.buildParams.profiles[0].snapshots = snapshots;
    }
  }

  mergeSelectedNameSuggestions() {
    var i, nameSuggestion;
    if (this.nameSuggestion.handled) {
      for (i = 0; i < this.nameSuggestion.suggestions.length; i++) {
        nameSuggestion = this.nameSuggestion.suggestions[i];

        if (nameSuggestion.selected) {
          if (this.hasDbaNames()) {
            this.buildParams.profiles[0].dbaNamesHolder.push({ name: nameSuggestion.suggestion });
          } else {
            this.buildParams.profiles[0].dbaNamesHolder[0].name = nameSuggestion.suggestion;
          }
        }
      }
    }
  }

  attachSelectedIdNumbers(ids) {
    if (!ids || ids.length === 0) {
      return;
    }

    const submissionModel = this.buildParams.profiles[0].identificationNumbers;

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      if (isDuplicate(id)) {
        continue;
      }
      if (id.selected) {
        delete id.selected;
        submissionModel.push(id);
      }
    }

    function isDuplicate(didYouMean) {
      return submissionModel.some(
        (userSelected) =>
          userSelected.number === didYouMean.number && userSelected.identificationType === didYouMean.identificationType
      );
    }
  }

  attachSelectedPeople(records) {
    if (!records || records.length === 0) {
      return;
    }

    if (!this.hasPeople(this.buildParams.profiles[0].people)) {
      this.buildParams.profiles[0].people = [];
    }

    for (var p = 0; p < records.length; p++) {
      if (records[p].selected) {
        let person = {
          currentPosition: { jobTitle: records[p].currentJobTitle },
          alternativeNamesHolder: [{}],
          relatedPeople: [{}],
          personName: [records[p].firstName, records[p].lastName].filter(Boolean).join(' '),
        };

        this.buildParams.profiles[0].people.push(person);
        this.buildParams.profiles[0].validatedRecords = this.buildParams.profiles[0].validatedRecords.concat(
          records[p].oiqValidationIds
        );
      }
    }
  }

  hasSelectedCompletion() {
    return this.firstSelectedCompletion() !== null;
  }

  firstSelectedCompletion() {
    var i;
    if (this.didYouMean.completions && this.didYouMean.completions.length > 0) {
      for (i = 0; i < this.didYouMean.completions.length; i++) {
        if (this.didYouMean.completions[i].selected) {
          return this.didYouMean.completions[i];
        }
      }
    }
    return null;
  }

  hasSufficientSeedData() {
    const profile = this.buildParams.profiles[0];

    return (
      this.hasSelectedCompletion() || this.hasCompanyName(profile) || this.hasIdNumbers(profile.identificationNumbers)
    );
  }

  hasCompanyName(profile) {
    if (profile.companyName) {
      return !!profile.companyName.trim();
    }
    return false;
  }

  refreshSelectionInvalid() {
    if (this.request.isRefreshable) {
      return (
        this.request.frequency === null ||
        typeof this.request.frequency === 'undefined' ||
        this.request.frequency === ''
      );
    } else {
      return false;
    }
  }

  hasSelectedNameSuggestion() {
    return (this.nameSuggestion.suggestions || []).some(function (suggestion) {
      return suggestion.selected;
    });
  }

  hasDbaNames() {
    return (this.buildParams.profiles[0].dbaNamesHolder || []).some(function (dbaNameHolder) {
      return dbaNameHolder.name;
    });
  }

  collectAllDbaNames() {
    var dbaNames;
    dbaNames = (this.buildParams.profiles[0].dbaNamesHolder || [])
      .filter(function (dbaNameHolder) {
        return dbaNameHolder.name;
      })
      .map(function (dbaNameHolder) {
        return dbaNameHolder.name;
      });
    dbaNames = dbaNames.concat(
      (this.nameSuggestion.suggestions || [])
        .filter(function (nameSuggestion) {
          return nameSuggestion.selected;
        })
        .filter(function (nameSuggestion) {
          return dbaNames.indexOf(nameSuggestion.suggestion) === -1;
        })
        .map(function (nameSuggestion) {
          return nameSuggestion.suggestion;
        })
    );
    return dbaNames;
  }

  hasParts() {
    return (
      (this.buildParams.profiles[0].partsHolder || []).filter(function (partsHolder) {
        return partsHolder.name;
      }).length > 0
    );
  }

  collectParts() {
    var parts;
    parts = (this.buildParams.profiles[0].partsHolder || [])
      .filter(function (partsHolder) {
        return partsHolder.name;
      })
      .map(function (partsHolder) {
        return partsHolder.name;
      });

    return parts;
  }

  // Used to determine if there's a valid lookback date, which should be after 1970.
  isValidLookback(date) {
    return date && date.year && date.year.toString().length >= 4 && date.year >= 1970;
  }

  onMonitoringToggled(monitoring) {
    if (this.monitoringEnabled) {
      this.buildParams.profiles[0].monitored = monitoring.isEnabled;
      this.isMonitored = monitoring.isEnabled;

      if (monitoring.isEnabled) {
        this.buildParams.profiles[0].monitorConfig = {
          frequency: monitoring.frequency,
          categories: monitoring.categories.map(function (category) {
            return category.value;
          }),
          crawlPlanType: monitoring.selectedCrawlPlanKey,
        };
      } else {
        delete this.buildParams.profiles[0].monitorConfig;
      }
    }
  }

  isMinimumIdentifyingInfoMissing() {
    const identificationNumbers = this.buildParams?.profiles[0]?.identificationNumbers;
    let hasIdentificationNumber;

    if (identificationNumbers && identificationNumbers.length !== 0) {
      hasIdentificationNumber = identificationNumbers.some((id) => !!id.number);
    }

    return !(this.buildParams?.profiles[0]?.companyName || hasIdentificationNumber);
  }

  trackByIndex(index) {
    return index;
  }

  sendCageData(data) {
    this.cageError = false;
    this.submittingCage = { open: true };
    this.submitting = true;
    if (data) {
      this.apiFetchService
        .getSAMData(data)
        .then((results) => {
          // no results
          if (results.status === 204) {
            this.submitting = false;
            this.cageError = true;
            this.cageErrorMessage = results.reason;
          } else {
            this.buildParams.profiles[0].companyName = results.companyName;
            this.buildParams.profiles[0].website = results.companyWebsite;
            this.buildParams.profiles[0].locations[0] = results.address;
            let samUeiSubmission = this.buildParams.profiles[0].identificationNumbers.find((idNumbers) => {
              return idNumbers.identificationType === 'SAM UEI';
            });
            if (samUeiSubmission) {
              samUeiSubmission.number = results.samUei;
            }
            this.submitting = false;
            this.submittingCage = { open: false };
          }
        })
        .catch((error) => {
          this.submitting = false;
          this.cageError = true;
          this.cageErrorMessage = error.error.reason;
        });
    } else {
      // when input is left empty
      this.submitting = false;
      this.cageError = true;
      this.cageErrorMessage = 'Please enter a valid CAGE code';
    }
  }
}
