<div id="regulatory" *ngIf="!section.metadata.filtered">
  <ddiq-accordion-top
    label="Regulatory, Compliance and Government"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!previewingEntity || regulatoryHasContent()"
    [disable]="!regulatoryHasContent()"
    [alerts]="alertCount"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()">
    <ddiq-alert-list [source]="regulatoryAlerts"></ddiq-alert-list>

    <div
      *ngFor="let subsection of getSubSections(section.metadata.sectionId) | filterRegulatorySubSection: showOshaSafety :showGovProcurement"
      class="sub-section">
      <div [ngSwitch]="subsection">
        <div *ngSwitchCase="'watchlists'">
          <strong class="push-down">Global Compliance / Sanctions / PEP Lists</strong>
          <ddiq-watch-list-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.watchListsEventGroups"
            (sortDiscoveries)="sortDiscoveries('watchLists', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-watch-list-group>
        </div>
        <ng-container *ngIf="!hideRegulatoryDatabases">
          <div *ngSwitchCase="'regulatoryProfiles'">
            <strong class="push-down">Regulatory Databases</strong>
            <ddiq-regulatory-profiles-group
              [idPrefix]="section.metadata.sectionId"
              [groups]="section.data.regulatoryProfilesEventGroups"
              (sortDiscoveries)="sortDiscoveries('regulatoryProfiles', $event.sortName, $event.eventGroup)"
              [isDisplayCreatedDate]="isDeltaReport"
              [expandAll]="isExpandAllGroups">
            </ddiq-regulatory-profiles-group>
          </div>
        </ng-container>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
