<div id="pdf">
  <div class="modal-title">Generate PDF Report</div>

  <div class="modal-content center-block">
    <div class="clearfix">
      <div class="modal-left-col">
        <fieldset>
          <legend>Content</legend>
          <form>
            <div *ngFor="let contentType of contentTypes" class="radio">
              <label>
                <input
                  id="{{contentType.content}}"
                  type="radio"
                  [(ngModel)]="content.value"
                  [value]="contentType.content"
                  name="expanded" />
                {{contentType.description}}
              </label>
            </div>
          </form>
        </fieldset>
      </div>

      <div class="modal-right-col">
        <form>
          <fieldset>
            <legend>Sources</legend>
            <div class="checkbox">
              <label>
                <input id="expandSources" type="checkbox" name="expandSources" [(ngModel)]="expandSources" />
                Expand all sources
              </label>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
    <br class="clr" />

    <div class="clearfix" *ngIf="hasRelatedProfiles()">
      <div>
        <legend>Related Entity Profiles - Select to add to PDF</legend>
        <span>Content collapsed by default</span>
        <br class="clr" />
        <button class="btn btn-primary btn-sm" (click)="selectAllRelatedProfiles()" *ngIf="!allRelatedProfilesSelected">
          Select All
        </button>
        <button
          class="btn btn-primary btn-sm"
          (click)="unselectAllRelatedProfiles()"
          *ngIf="allRelatedProfilesSelected">
          Unselect All
        </button>
      </div>
      <br class="clr" />

      <div class="child-left push-down">
        <legend>Related Companies - Select to add to PDF</legend>

        <div class="pdf-selection">
          <table class="list-table">
            <tr *ngFor="let company of relatedCompanies; index as $index">
              <td>
                <div class="right">
                  <button
                    class="btn btn-primary btn-sm btn-block"
                    (click)="addRelatedProfile(company)"
                    *ngIf="!company.selected"
                    [disabled]="hasSelectProfilesExceededLimit()">
                    Select
                  </button>

                  <button
                    class="btn btn-primary btn-sm btn-block"
                    (click)="removeRelatedProfile(company)"
                    *ngIf="company.selected">
                    Unselect
                  </button>
                </div>

                <div>{{ company.base.name }}<br /></div>

                <div class="checkbox" *ngIf="company.selected">
                  <label>
                    <input
                      type="checkbox"
                      name="selectedCompany-{{$index}}"
                      [(ngModel)]="company.expanded"
                      (change)="updateChildrenStatus()" />
                    Show all content expanded
                  </label>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="child-right push-down">
        <legend>Related People - Select to add to PDF</legend>

        <div class="pdf-selection">
          <table class="list-table">
            <tr *ngFor="let person of relatedPeople; index as $index">
              <td>
                <div class="right">
                  <button
                    class="btn btn-primary btn-sm btn-block"
                    (click)="addRelatedProfile(person)"
                    *ngIf="!person.selected"
                    [disabled]="hasSelectProfilesExceededLimit()">
                    Select
                  </button>

                  <button
                    class="btn btn-primary btn-sm btn-block"
                    (click)="removeRelatedProfile(person)"
                    *ngIf="person.selected">
                    Unselect
                  </button>
                </div>

                <div>{{ person.base.name }}<br /></div>

                <div class="checkbox" *ngIf="person.selected">
                  <label>
                    <input
                      type="checkbox"
                      name="personSelected-{{$index}}"
                      [(ngModel)]="person.expanded"
                      (change)="updateChildrenStatus()" />
                    Show all content expanded
                  </label>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="pdf-warn">
      <span *ngIf="isFiltered"
        >The current profile is filtered. <br /><br />
        If you do not want the filter applied in the PDF, select 'Cancel' and clear the filters.
      </span>

      <span *ngIf="isAnyExpandedChildProfile">
        Because expanded content has been selected for some related entity profiles, the PDF may reach its size limit.
        <br />
        If you see an error message in the next page, please return to the profile and select less related entity
        profiles with expanded content to be included in the PDF.
      </span>
    </div>
  </div>

  <div class="center push-down clr">
    <button type="button" (click)="generatePdf()" class="btn btn-primary">Generate PDF</button>
    <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
  </div>
</div>
