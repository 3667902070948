import EventGroupsTemplate from '../event-groups/event-groups-template';
import EventGroupsBuilderService from './event-groups-builder.service';
import EventGroupBuilderService from './event-group-builder.service';
import OiqProperties from '../common-services/oiq-properties.service';
import { Injectable } from '@angular/core';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';

@Injectable({
  providedIn: 'root',
})
export class OshaInspectionEventGroupsService extends EventGroupsTemplate {
  private readonly regulatory = 'regulatory';

  constructor(
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private eventGroupBuilderService: EventGroupBuilderService,
    private oiqProperties: OiqProperties,
    private structuredDataSorts: StructuredDataSorts
  ) {
    super('oshaSafetyInspectionsEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
  }

  addEventGroupsToSection() {
    return (section) => {
      const oshaSafetyInspections = section.data.oshaSafetyInspections;
      const sectionId = section.metadata.sectionId;

      if (!this.canAttachEventGroups(oshaSafetyInspections, sectionId, this.oiqProperties.oshaInRegulatorySection))
        return;

      const eventGroups = [];

      if (oshaSafetyInspections.length) {
        const eventGroup = this.eventGroupBuilderService
          .newBuilder()
          .withDiscoveries(oshaSafetyInspections)
          .withTitle('Inspections')
          .withSort((discoveries) => this.structuredDataSorts.sortByAdjudication(discoveries))
          .build();

        eventGroups.push(eventGroup);
      }

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService.newEventGroupsModel(eventGroups).build();
    };
  }

  private canAttachEventGroups(oshaSafetyInspections, sectionId, canOnlyAddToRegulatory) {
    return (
      oshaSafetyInspections &&
      ((canOnlyAddToRegulatory && sectionId === this.regulatory) ||
        (!canOnlyAddToRegulatory && sectionId !== this.regulatory))
    );
  }
}
