import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import DdiqProfileRefreshSchedulerComponent from './ddiq-profile-refresh-scheduler.component';
import DdiqProfileRefreshVersionInfoComponent from './ddiq-profile-refresh-version-info.component';
import ProfileRefreshService from './profile-refresh.service';

@NgModule({
  imports: [CommonModule, FormsModule, PipesModule, ThemedEntityModule],
  declarations: [DdiqProfileRefreshSchedulerComponent, DdiqProfileRefreshVersionInfoComponent],
  providers: [ProfileRefreshService],
  exports: [DdiqProfileRefreshSchedulerComponent, DdiqProfileRefreshVersionInfoComponent],
})
export class ProfileRefreshModule {}
