import { Injectable } from '@angular/core';
import CrawlPlanService from '../common-services/crawlplan.service';
import I18nService from '../common-services/i18n.service';
import OiqProperties from '../common-services/oiq-properties.service';
import { Resolve } from '@angular/router';

@Injectable()
export default class BuildCompanyResolverService implements Resolve<any> {
  constructor(
    private oiqProperties: OiqProperties,
    private i18nService: I18nService,
    private crawlPlanService: CrawlPlanService
  ) {}

  resolve() {
    return this.oiqProperties.apiPropertiesPromise
      .then(() => this.i18nService.fetchCountries())
      .then(() => this.i18nService.fetchLanguages())
      .then(() => this.crawlPlanService.fetchCrawlPlans());
  }
}
