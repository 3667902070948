<div #entityAware="oiqEntityAware" oiq-entity-aware>
  <ng-container *ngFor="let group of groups.list; trackBy:classification">
    <article
      id="{{idPrefix}}_{{group.antiCollideClassification | validateHtmlIds}}"
      *ngIf="group.hasDiscoveries"
      class="panel oiq-incidents oiq-incidents-{{group.classification}} discovery-group">
      <div data-oiq-collapsible-ctrl [expandAll]="expandAll || group.isEventGroupOpen">
        <header class="panel-header oiq-incidents-header">
          <oiq-group-dynamic-adjudication-aware
            [adjudicationType]="'INCIDENT'"
            [section]="idPrefix"
            [classification]="group.antiCollideClassification">
          </oiq-group-dynamic-adjudication-aware>
          <risk-in-classification
            [classification]="group.classification"
            [entityId]="entityAware.entityId"
            [riskType]="'WEB_CONTENT'">
          </risk-in-classification>
          <h3 class="panel-title oiq-incidents-title">{{group.title}}</h3>

          <div class="panel-header-right">
            <ddiq-sort-buttons
              *ngIf="group.hasMoreEntries"
              class="pull-left btn-sort-group-margin"
              [entities]="group.discoveries"
              [orderBy]="'association, date, adjudication'"
              (onClick)="sort({ sortName: $event.btnName, eventGroup: group })"
              [activeBtn]="group.activeSortButtons">
            </ddiq-sort-buttons>

            <button class="btn btn-sm btn-default" *ngIf="group.hasMoreEntries" data-oiq-collapsible-toggle>
              <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
              View all {{(group.discoveries | moreEntries).length + 1 }} events
            </button>
          </div>
        </header>

        <section class="panel-body">
          <article
            class="oiq-incidents-featured-incident"
            *ngFor="let featured of group.discoveries | firstUnfilteredDiscovery">
            <ddiq-incident
              class="featured-incident"
              [incident]="featured"
              [classification]="group.classification"
              [displayCreatedDate]="isDisplayCreatedDate"
              [displayFirstSeenDate]="true"
              [isAdjudicateable]="entityAware.isAdjudicatable"
              [showArticleCacheLinks]="showArticleCacheLinks"
              [showOtherMentionsOpen]="showOtherMentionsOpen"
              [showReferencesOpen]="showReferencesOpen"
              [showTranslatedSnippets]="showTranslatedSnippets"
              [entityId]="entityAware.entityId"
              [entityType]="entityAware.entityType"
              [noReassess]="entityAware.noReassess">
            </ddiq-incident>
          </article>

          <article class="oiq-incidents-more-entries" *ngIf="group.hasMoreEntries">
            <ddiq-collapsible>
              <ddiq-incident
                class="oiq-incidents-more-entry discovery-group"
                *ngFor="let entry of group.discoveries | moreEntries; trackBy:id"
                [incident]="entry"
                [classification]="group.classification"
                [displayCreatedDate]="isDisplayCreatedDate"
                [displayFirstSeenDate]="true"
                [isAdjudicateable]="entityAware.isAdjudicatable"
                [showArticleCacheLinks]="showArticleCacheLinks"
                [showOtherMentionsOpen]="showOtherMentionsOpen"
                [showReferencesOpen]="showReferencesOpen"
                [showTranslatedSnippets]="showTranslatedSnippets"
                [entityId]="entityAware.entityId"
                [entityType]="entityAware.entityType"
                [noReassess]="entityAware.noReassess">
              </ddiq-incident>
            </ddiq-collapsible>
          </article>
        </section>
      </div>
    </article>
  </ng-container>
</div>

<p *ngIf="groups.list?.length === 0" class="oiq-incidents-msg-no-results">No results discovered</p>

<p *ngIf="groups.showAllFilteredMessage" class="oiq-incidents-msg-all-filtered">All results filtered</p>
