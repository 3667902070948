import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable({
  providedIn: 'root',
})
export default class SelectedArticlesService {
  selected = {};

  constructor(private apiFetchService: ApiFetchService) {}

  isSelected(articleId) {
    return !!this.selected[articleId];
  }

  addArticle(articleId) {
    this.selected[articleId] = articleId;
  }

  removeArticle(articleId) {
    delete this.selected[articleId];
  }

  getSelected() {
    return Object.keys(this.selected);
  }

  save(entityType, entityId) {
    return this.apiFetchService.storeSelectedArticles(entityType, entityId, this.getSelected());
  }

  hasArticlesSelected() {
    return Object.keys(this.selected).length > 0;
  }

  getSaved(selectedArticlesId) {
    return this.apiFetchService.getSelectedArticles(selectedArticlesId);
  }

  removeAll() {
    this.selected = {};
  }
}
