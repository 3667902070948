import { Component, OnInit } from '@angular/core';
import OiqSourceInfoCtrlDirective from './oiq-source-info-ctrl.directive';

@Component({
  selector: 'oiq-source-info-badge',
  templateUrl: './oiq-source-info-badge.component.tpl.html',
})
export default class OiqSourceInfoBadgeComponent implements OnInit {
  constructor(private oiqSourceInfoCtrl: OiqSourceInfoCtrlDirective) {}

  isOpen: boolean;
  count: number;
  ngOnInit() {
    this.isOpen = this.oiqSourceInfoCtrl.isOpen();
    this.count = this.oiqSourceInfoCtrl.total();
  }

  onClick() {
    this.oiqSourceInfoCtrl.toggle();
    this.isOpen = this.oiqSourceInfoCtrl.isOpen();
  }
}
