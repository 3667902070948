import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({ providedIn: 'root' })
class ExpandAllEventGroupsFeatureService {
  constructor(private oiqProperties: OiqProperties) {}

  isEnabled() {
    const expandAllLimit = this.oiqProperties.expandAllLimit;

    if (typeof expandAllLimit !== 'number' || expandAllLimit < 0) {
      console.error(
        `Expand all event groups feature is misconfigured. Expected a number of 0 or more and found ${expandAllLimit}.`
      );
      return false;
    }

    return !!expandAllLimit;
  }
}

export default ExpandAllEventGroupsFeatureService;
