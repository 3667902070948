import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import AssociationBucketsService from './association-buckets.service';
import DdiqAssociationConfidenceComponent from './ddiq-association-confidence.component';
import DdiqAssociationComponent from './ddiq-association.component';

@NgModule({
  imports: [CommonModule, PipesModule],
  providers: [AssociationBucketsService],
  declarations: [DdiqAssociationConfidenceComponent, DdiqAssociationComponent],
  exports: [DdiqAssociationConfidenceComponent, DdiqAssociationComponent],
})
export default class OiqAssociationModule {}
