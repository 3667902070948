<div>
  <div id="login-ctrl" class="login-panel" [ngSwitch]="panel">
    <div id="login-img" class="brand">
      <img data-theme-logo="large" data-theme-alt [hidden]="panel == 3" />
      <span class="brand-sub-text">Powered by DDIQ</span>
    </div>
    <div id="login-main" *ngSwitchCase="1">
      <div *ngIf="showSystemUseNotice">
        <h2>System Use Notice</h2>
        <section>
          <ddiq-system-use-notice
            [content]="systemUseNoticeContent"
            (noticeAccepted)="acceptSystemUseNotice()"></ddiq-system-use-notice>
        </section>
      </div>
      <div *ngIf="!showSystemUseNotice">
        <h2>Log In</h2>

        <section>
          <form method="post" autocomplete="off">
            <ddiq-text label="User Name" size="full" [(model)]="login.user" name="username" required></ddiq-text>
            <ddiq-password name="password" label="Password" size="full" [(model)]="login.pass" required></ddiq-password>
            <p>By logging in, I agree to the <span>terms and conditions</span> of this application.</p>
            <div id="login-main-login_fail" class="error-message" *ngIf="login.fail">
              <div [ngSwitch]="login.error?.reason">
                <span *ngSwitchCase="'UNAUTHORIZED'">Invalid User Name/Password combination. Please try again.</span>
                <span *ngSwitchCase="'TERMS_NOT_ACCEPTED'">Terms and Agreement must be accepted to continue.</span>
                <span *ngSwitchCase="'TOO_MANY_PASSWORD_ATTEMPTS'"
                  >Too many password attempts. Please retry login.</span
                >
                <span *ngSwitchDefault
                  >{{login.error?.message}} Please email <ddiq-support-contact-info></ddiq-support-contact-info> to
                  unlock your account.</span
                >
              </div>
            </div>
            <div id="login-main-button" class="block center">
              <button
                class="btn btn-primary btn-block"
                type="submit"
                (click)="checkCredentials()"
                [disabled]="login.isWaiting">
                Log in <i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true" *ngIf="login.isWaiting"></i>
              </button>
            </div>
            <a id="login-main-forgot_password" class="center" (click)="changePanel(4)">Forgot password</a>
          </form>
        </section>
      </div>
    </div>

    <div id="login-change-password" *ngSwitchCase="2">
      <h2>Change Password</h2>

      <section>
        <form method="post" novalidate autocomplete="off">
          <ddiq-password
            name="oldPassword"
            label="Old Password"
            size="full"
            [(model)]="user.oldPassword"
            (passwordChanged)="validPassword()"
            required></ddiq-password>
          <ddiq-password
            name="newPassword"
            label="New Password"
            size="full"
            [(model)]="user.newPassword"
            (passwordChanged)="validPassword()"
            [pattern]="passwordValidationPattern"
            required></ddiq-password>
          <ddiq-password
            name="newPasswordConfirm"
            label="Confirm Password"
            size="full"
            [(model)]="user.newPasswordConfirm"
            (passwordChanged)="validPassword()"
            [pattern]="passwordValidationPattern"
            required></ddiq-password>
          <div
            id="login-change-password-error"
            class="error-message"
            *ngIf="(user.authError && !user.oldPassword) || user.error">
            <div id="login-change-password-auth_error" [hidden]="!user.authError">
              <span>{{user.authError}}</span>
            </div>
            <div id="login-change-password-user_error" [hidden]="!user.error">
              <span>{{user.error}}</span>
            </div>
          </div>
          <div id="login-change-password-button" class="block center">
            <button
              class="btn btn-primary btn-block"
              type="submit"
              (click)="submitPasswordChange()"
              [disabled]="user.error || user.$invalid">
              Change Password
            </button>
          </div>
        </form>
      </section>
    </div>

    <div id="login-terms" *ngSwitchCase="3">
      <iframe
        class="scroll-box"
        [src]="'TERMS_AND_CONDITIONS' | oiqThemePageHref: 'termsConditionsDDIQ.html' | sanitizeUrl"
        frameborder="0"></iframe>
      <hr />
      <div class="center">
        <button
          id="login-terms-button-agree"
          type="button"
          id="agreeTerms"
          class="btn btn-primary"
          (click)="submitTermsAndConditionsAgreement()">
          I Agree
        </button>
        <button
          id="login-terms-button-not_agree"
          type="button"
          class="btn btn-default"
          (click)="cancelTermsAndConditionsAgreement()">
          I Do Not Agree
        </button>
      </div>
    </div>

    <div id="login-password-reset" *ngSwitchCase="4">
      <h2>Password reset</h2>
      <oiq-password-reset
        [userName]="login.user"
        (onSuccess)="emailSent()"
        (onCancel)="changePanel(1)"></oiq-password-reset>
    </div>

    <div id="login-email-sent" *ngSwitchCase="5">
      <h2>Email sent</h2>

      <section>
        <p>We have sent an email to the email address associated with the account.</p>
        <p>
          The email will give you further instructions about resetting your password. The email link must be clicked
          within a limited time.
        </p>
        <p>
          If you do not receive an email within a few minutes, please try again, or email
          <ddiq-support-contact-info></ddiq-support-contact-info> for further assistance.
        </p>
        <div id="login-email-sent-back-button" class="block center">
          <button class="btn btn-default btn-block" (click)="changePanel(1)">Back</button>
        </div>
      </section>
    </div>

    <div id="login-logged_out" *ngSwitchCase="6">
      <section>
        <h2 class="text-center">You have been logged out.</h2>
      </section>
    </div>

    <div id="login-services-unavailable" *ngSwitchCase="7">
      <h2 class="text-center">Services Temporarily Unavailable</h2>
      <section>
        <p class="text-center">
          <br />Please try again later.
          <br />
          <br />For more information on our products and services, or to connect with our Enterprise Support team,
          please contact us at:
          <br />
          <br /><support-contact-phone-number></support-contact-phone-number>
          <br /><ddiq-support-contact-info></ddiq-support-contact-info>
        </p>
      </section>
    </div>

    <div id="login-upgrade-required" *ngSwitchCase="8">
      <upgrade-version></upgrade-version>
    </div>

    <div id="session-timed-out" *ngSwitchCase="9">
      <section>
        <oiq-session-termination-message
          [content]="sessionTerminationMessage"
          (logoutConfirmed)="handleLogout()"></oiq-session-termination-message>
      </section>
    </div>
  </div>
</div>
