<div id="dynamic-adjudication" *ngIf="dynamicAdjudicationEnabled()">
  <div class="dynamic-adjudication-flyout-control" (click)="toggleVisibility()">
    <i class="fa" [ngClass]="{'fa-angle-double-right': actionsVisible, 'fa-angle-double-left': !actionsVisible}"></i>
  </div>
  <div class="dynamic-adjudication-controls" *ngIf="actionsVisible">
    <div
      *ngIf="isConfirmActionEnabled()"
      class="dynamic-adjudicate clr"
      title="Select Multiple Items to Confirm"
      (click)="selectAdjudicationAction('CONFIRM')"
      [ngClass]="{'disabled' : !adjudicationActions.CONFIRM}">
      <i class="fa fa-2x fa-check-square-o"></i>
    </div>
    <div
      *ngIf="isStarEnabled"
      class="dynamic-adjudicate clr"
      title="Select Multiple Items to Star"
      (click)="selectAdjudicationAction('ESCALATE')"
      [ngClass]="{'disabled' : !adjudicationActions.ESCALATE}">
      <i class="fa fa-2x fa-star"></i>
    </div>
    <div
      class="dynamic-adjudicate clr"
      title="Select Multiple Items to Remove"
      (click)="selectAdjudicationAction('REMOVE')"
      [ngClass]="{'disabled' : !adjudicationActions.REMOVE}">
      <i class="fa fa-2x fa-times"></i>
    </div>
    <div
      class="dynamic-adjudicate clr"
      title="Select Multiple Items to Comment"
      (click)="selectAdjudicationAction('COMMENT')"
      [ngClass]="{'disabled' : !adjudicationActions.COMMENT}">
      <i class="fa fa-2x fa-commenting-o"></i>
    </div>
    <div
      class="dynamic-adjudicate clr"
      title="Adjudicate Selected"
      (click)="adjudicateSelected()"
      [ngClass]="{'disabled' : !adjudicateActionEnabled()}">
      <i class="fa fa-2x fa-check-circle-o"></i>
    </div>
    <div
      class="dynamic-adjudicate clr"
      title="Cancel"
      (click)="cancel()"
      [ngClass]="{'disabled' : !cancelActionEnabled()}">
      <i class="fa fa-2x fa-ban"></i>
    </div>
  </div>
</div>
