import EventGroupSort from './event-group-sort.service';
import EventGroupFilterService from './event-group-filter-service.service';
import EventGroupsService from './event-groups.service';
import ExpandAllEventGroupsToggleComponent from './ddiq-expand-all-event-groups-toggle.component';
import ExpandAllEventGroupsFeatureService from './expand-all-event-groups-feature.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [ExpandAllEventGroupsToggleComponent],
  exports: [ExpandAllEventGroupsToggleComponent],
  providers: [EventGroupFilterService, EventGroupsService, EventGroupSort, ExpandAllEventGroupsFeatureService],
})
export class EventGroupsModule {}
