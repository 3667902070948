import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import AuditResolver from './audit-resolver.service';
import CrawlErrorComponent from './ddiq-crawl-error.component';
import AuditSectionDetailComponent from './ddiq-audit-section-detail.component';
import AuditComponent from './ddiq-audit.component';
import { NgPipesModule } from 'ngx-pipes';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import { FormsModule } from '@angular/forms';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import SupportContactInfoModule from '../support-contact-info/support-contact-info.module';

@NgModule({
  imports: [
    CommonModule,
    NgPipesModule,
    FormsModule,
    ThemedEntityModule,
    ProtectedArticlesModule,
    PipesModule,
    SupportContactInfoModule,
  ],
  declarations: [AuditComponent, AuditSectionDetailComponent, CrawlErrorComponent],
  providers: [DatePipe, TranslatefPipe, WindowRefService, AuditResolver],
  exports: [CrawlErrorComponent],
})
export class AuditModule {}
