<div *ngIf="canShowSeedData()" oiq-adjudication-aware>
  <seed-data-tabs
    *ngIf="!isPdfRender"
    class="non-print-view"
    [seedData]="seedData"
    [hideComments]="hideComments"
    [section]="section"></seed-data-tabs>

  <seed-data-print-view
    *ngIf="isPdfRender"
    class="print-view-only"
    [seedData]="seedData"
    [section]="section"
    [hideComments]="hideComments">
  </seed-data-print-view>
</div>
