import { Component, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ExcludeAdjudicationActionsPipe from '../ddiq-filters/exclude-adjudication-actions.pipe';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import Monitor from '../monitor/monitor.service';
import RiskAssessmentService from '../risk/risk-assessment.service';
import IncidentsService from '../themed-entity/incidents.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import StructuredData from '../themed-entity/structured-data.service';
import DynamicAdjudicationService from './dynamic-adjudication.service';
import './img/loader.gif';

const CONFLICT_STATUS = 409;
const BAD_REQUEST_STATUS = 400;

@Component({
  selector: 'ddiq-dynamic-adjudication-modal',
  templateUrl: './ddiq-dynamic-adjudication-modal.component.tpl.html',
})
export default class DynamicAdjudicationModalComponent implements OnInit {
  adjudicating: boolean;
  targetCounts: any[];
  targetAdjudicables: any[];
  adjudicationMode: any;
  adjudicationModel: any;
  adjudicableMap: any;

  constructor(
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private apiFetchService: ApiFetchService,
    private modalTrackingService: ModalTrackingService,
    private dynamicAdjudicationService: DynamicAdjudicationService,
    private riskAssessmentService: RiskAssessmentService,
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private monitor: Monitor,
    private translatePipe: TranslatefPipe,
    private excludeAdjudicationActions: ExcludeAdjudicationActionsPipe,
    private messageBusService: MessageBusService
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    const perClassificationCount = {};
    const adjudicationMode = this.dynamicAdjudicationService.getMode();

    this.adjudicating = false;
    this.targetCounts = [];
    this.targetAdjudicables = this.dynamicAdjudicationService.getAdjudications();

    this.adjudicationMode = adjudicationMode;
    this.adjudicationModel = {
      reason: null,
      comment: null,
      escalated: adjudicationMode === 'ESCALATE',
      confirmed: adjudicationMode === 'CONFIRM',
    };

    for (let i = 0; i < this.targetAdjudicables.length; i++) {
      trackClassifications(perClassificationCount, this.targetAdjudicables[i].type, this.targetAdjudicables[i].data);
    }

    (Object.keys(perClassificationCount) || []).forEach((key) => {
      this.targetCounts.push({
        name: this.translatePipe.transform(key),
        severity: perClassificationCount[key].severity || 0,
        count: perClassificationCount[key].count,
      });
    });
  }

  getAdjudicationReasons() {
    if (this.oiqProperties.adjudicationRemoveReasons[this.entity.getType()]) {
      return this.oiqProperties.adjudicationRemoveReasons[this.entity.getType()];
    }
    return this.oiqProperties.adjudicationRemoveReasons;
  }

  adjudicate() {
    const toAdjudicate = [];

    this.adjudicableMap = {};
    this.adjudicating = true;

    for (let i = 0; i < this.targetAdjudicables.length; i++) {
      let current = this.targetAdjudicables[i].data;
      let type = this.targetAdjudicables[i].type;

      if (type === 'PRODUCT_RECALL') {
        type = current.isUscpsc ? 'USCPSC_PRODUCT_RECALL' : 'CONSUMER_PRODUCT_RECALL';
      }

      toAdjudicate.push({
        type: type,
        status: this.adjudicationModel.reason,
        comment: this.adjudicationModel.comment,
        escalated: this.adjudicationModel.escalated,
        confirmed: this.adjudicationModel.confirmed,
        id: current.id,
        lastId: current.adjudication ? current.adjudication.current.id : null,
        action: this.adjudicationMode,
        classification: current.classification,
      });

      this.adjudicableMap[type + '_' + current.id] = current;
    }

    this.apiFetchService
      .adjudicateAll(this.entity.getType(), this.entity.getId(), toAdjudicate, this.monitor.isDeltaReport())
      .then(this.submitSuccess(), this.submitFailure());
  }

  clear(clearMode) {
    this.adjudicating = false;

    this.adjudicationModel = {
      reason: null,
      comment: null,
      escalated: false,
      confirmed: false,
    };

    if (clearMode) {
      this.dynamicAdjudicationService.clear();
    }

    this.modalTrackingService.getTriggers().dynamicAdjudication.open = false;
  }

  cancel() {
    this.clear(false);
  }

  notifyObservers() {
    this.incidents.notifyObservers();
    this.structuredData.notifyObservers();
  }

  determineSelectionOnCollision(source) {
    const adjudicationMode = this.dynamicAdjudicationService.getMode();

    if (adjudicationMode === 'REMOVE' && source.disabled) {
      return false;
    } else if (adjudicationMode === 'ESCALATE' && source.escalated) {
      return false;
    } else if (adjudicationMode === 'CONFIRM' && source.confirmed) {
      return false;
    }
    return true;
  }

  submitFailure() {
    return (response) => {
      this.adjudicating = false;

      let errorPage;

      if (response.status === CONFLICT_STATUS) {
        var adjudications = response.data.adjudicated;

        errorPage = 'dynamicAdjudicationError';

        for (let i = 0; i < adjudications.length; i++) {
          let adjudicated = adjudications[i];

          if (adjudicated.data.adjudication) {
            let source = this.adjudicableMap[adjudicated.type + '_' + adjudicated.id];
            source.adjudication = Object.assign(source.adjudication || {}, adjudicated.data.adjudication);
            source.escalated = !!source.adjudication.escalated;
            source.confirmed = !!source.adjudication.confirmed;
            source.disabled = !!source.adjudication.adjudicated;
            source.selected = this.determineSelectionOnCollision(source);
            source.adjudication.history = this.excludeAdjudicationActions.transform(
              adjudicated.data.adjudication.history,
              this.oiqProperties.hiddenAdjudicationActions
            );
          }
        }
      } else if (response.status === BAD_REQUEST_STATUS) {
        errorPage = 'dynamicAdjudicationResponseError';
      }

      this.notifyObservers();

      if (response.data && response.data.reassess) {
        this.riskAssessmentService.updateAssessments(this.entity.getType(), this.entity.getId());
      }

      this.clear(false);
      this.modalTrackingService.openError(errorPage);
    };
  }

  submitSuccess() {
    return (response) => {
      const adjudications = response.adjudicated;

      for (let i = 0; i < adjudications.length; i++) {
        let adjudicated = adjudications[i];
        let source = this.adjudicableMap[adjudicated.type + '_' + adjudicated.id];

        source.adjudication = Object.assign(source.adjudication || {}, adjudicated.data.adjudication);
        source.escalated = !!source.adjudication.escalated;
        source.confirmed = !!source.adjudication.confirmed;
        source.disabled = !!source.adjudication.adjudicated;
        source.selected = false;
        source.adjudication.history = this.excludeAdjudicationActions.transform(
          adjudicated.data.adjudication.history,
          this.oiqProperties.hiddenAdjudicationActions
        );
      }

      this.notifyObservers();

      if (response.reassess) {
        this.riskAssessmentService.updateAssessments(this.entity.getType(), this.entity.getId());
      }

      this.messageBusService.send({ type: 'event:event-adjudicated' });

      this.clear(true);
    };
  }
}

function trackClassifications(perClassificationCount, adjudicationType, data) {
  let type;

  if ('INCIDENT' === adjudicationType) {
    type = data.classificationLabelKey;
  } else if ('WATCH_LIST_ENTRY' === adjudicationType || 'RECORD_CLUSTER' === adjudicationType) {
    type = data.category;
  } else if ('REGULATORY_AGENCY_PROFILE' === adjudicationType) {
    type = data.seedSource.source;
  } else {
    type = adjudicationType;
  }

  if (perClassificationCount[type]) {
    perClassificationCount[type].count++;
  } else {
    perClassificationCount[type] = { count: 1, severity: data.severity };
  }
}
