<div class="box-content" [ngClass]="isOpen ? 'open' : 'close'">
  <div class="box-content-sticky">
    <ng-content></ng-content>
  </div>
</div>

<button class="split-handler" [ngClass]="{active: isSplitHandlerActive}"></button>

<div class="drawer-tab" (click)="toggle()">
  <div class="drawer-tab-inner">
    <span class="icon"><i [ngClass]="'fa fa-sm fa-chevron-' + (isOpen ? 'left': 'right')"></i></span>
  </div>
</div>
