import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import NetworkComponent from './ddiq-network.component';
import NetworkVisComponent from './ddiq-network-vis.component';
import VisualizeNetworkComponent from './ddiq-visualize-network.component';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import DateFormatPipe from '../ddiq-filters/date-format.pipe';
import NetworkResolverService from './network-resolver.service';

@NgModule({
  imports: [CommonModule, ThemedEntityModule, PipesModule],
  declarations: [NetworkComponent, VisualizeNetworkComponent, NetworkVisComponent],
  providers: [DateFormatPipe, NetworkResolverService],
  exports: [NetworkComponent, VisualizeNetworkComponent],
})
export class NetworkModule {}
