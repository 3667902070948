import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class StructuredDataArrayExtractionStrategyService {
  constructor() {}

  extract(entity, dataName, onDataExtracted: Function, onDone: Function) {
    let structuredData = [];

    (entity[dataName] || []).forEach((extracted) => {
      structuredData.push(extracted);
      onDataExtracted(extracted);
    });

    onDone(structuredData);
  }
}
