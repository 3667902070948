import { Component } from '@angular/core';

@Component({
  selector: 'oiq-drop-down-items',
  template: ` <div aria-role="listitem">
    <ng-content></ng-content>
  </div>`,
})
export default class DropDownItemsComponent {
  constructor() {}
}
