<article>
  <div class="panel">
    <div class="panel-header">
      <h3 class="panel-title">
        <span class="sources-label">Sources</span>
        <span>
          <span class="award-source badge badge-alert" *ngFor="let seedSource of award.seedSources">
            <a href="{{seedSource.sourceUrl}}" target="_blank" rel="noopener noreferrer"
              >{{seedSource.source | translatef}}</a
            >
          </span>
        </span>
      </h3>
      <ddiq-association class="tool-tip-left" [level]="award.associationBucket"></ddiq-association>
    </div>
    <div class="panel-body">
      <div class="award-items">
        <dl>
          <dt>Award ID</dt>
          <dd class="award-id">{{award.awardId}}</dd>
        </dl>
        <dl>
          <dt>Parent Award ID</dt>
          <dd class="award-parent-id">{{award.parentAwardId}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>Recipient DUNS</dt>
          <dd class="award-recipient-duns">{{award.recipientDuns}}</dd>
        </dl>
        <dl>
          <dt>Parent DUNS</dt>
          <dd class="award-parent-duns">{{award.parentDuns}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>Recipient</dt>
          <dd class="award-recipient-name">{{award.recipientName}}</dd>
        </dl>
        <dl>
          <dt>Sub Awards</dt>
          <dd class="award-sub-award-count">{{award.subAwardCount}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>Category</dt>
          <dd class="award-category">{{award.category}}</dd>
        </dl>
        <dl>
          <dt>Awarding Agency</dt>
          <dd class="award-awarding-agency">{{award.awardingAgencyName}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>Current Award Amount</dt>
          <dd class="award-current-amount">{{award.currentAwardAmount | currency}}</dd>
        </dl>
        <dl>
          <dt>Awarding Sub Agency</dt>
          <dd class="award-awarding-sub-agency">{{award.awardingSubAgencyName}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>Start Date</dt>
          <dd class="award-start-date">{{award.startDate | date: 'mediumDate'}}</dd>
        </dl>
        <dl>
          <dt>Foreign Funding Description</dt>
          <dd class="award-foreign-funding-description">{{award.foreignFundingDescription}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>End Date</dt>
          <dd class="award-end-date">{{award.endDate | date: 'mediumDate'}}</dd>
        </dl>
        <dl>
          <dt>Domestic Or Foreign Entity</dt>
          <dd class="award-domestic-or-foreign-entity">{{award.domesticOrForeignEntity}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>Award Type</dt>
          <dd class="award-type">{{award.awardType}}</dd>
        </dl>
        <dl>
          <dt>Description</dt>
          <dd class="award-description">{{award.description}}</dd>
        </dl>
      </div>
      <div class="award-items">
        <dl>
          <dt>PSC</dt>
          <ng-container *ngFor="let classification of award.classifications">
            <dd
              class="award-classification-code-description"
              *ngIf="classification.codeSchema === 'PSC' && classification.code">
              {{classification.description ? classification.code + ' - ' + classification.description :
              classification.code}}
            </dd>
          </ng-container>
        </dl>
        <dl>
          <dt>NAICS</dt>
          <ng-container *ngFor="let classification of award.classifications">
            <dd
              class="award-classification-code-description"
              *ngIf="classification.codeSchema === 'NAICS' && classification.code">
              {{classification.description ? classification.code + ' - ' + classification.description :
              classification.code}}
            </dd>
          </ng-container>
        </dl>
      </div>
    </div>
  </div>
</article>
