import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ddiq-risk-review',
  templateUrl: './ddiq-risk-review.component.tpl.html',
})
export default class DdiqRiskReviewComponent implements OnInit {
  defaultRisk = {
    scoreClass: '',
    iconClass: '',
    indicator: '',
    tooltip: '',
  };

  @Input() risk: any = this.defaultRisk;

  constructor() {}

  ngOnInit() {
    this.risk = this.risk ? { ...this.defaultRisk, ...this.risk } : this.defaultRisk;
  }
}
