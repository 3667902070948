<div class="input quarter" *ngIf="isEnabled">
  <label>Hit Category</label>
  <select
    class="screening-hit-category-select"
    [(ngModel)]="selectedCategory"
    (change)="onCategoryChange()"
    name="screening-hit-category">
    <option *ngFor="let category of categories" [value]="category?.value">{{category?.label}}</option>

    <option></option>
  </select>
</div>
