<div class="input {{size}}" [ngClass]="{disabled : oiqTextDisabled}">
  <label [ngClass]="{required: isRequired()}">{{label}}</label>

  <select
    [name]="name"
    [(ngModel)]="model"
    [required]="isRequired()"
    [disabled]="oiqTextDisabled"
    (ngModelChange)="onSelectChange(model)">
    <option value="">{{prompt}}</option>
    <option *ngFor="let opt of options" [value]="getValue(opt)">{{getDisplayValue(opt)}}</option>
  </select>
</div>
