import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import { OiqArticleCacheModule } from '../article-cache/oiq-article-cache.module';
import OiqAssociationModule from '../association/oiq-association.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import { OiqRiskModule } from '../risk/oiq-risk.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqIncidentComponent from './ddiq-incident.component';
import DdiqIncidentsGroupComponent from './ddiq-incidents-group.component';
import IncidentByClassificationExtractionStrategy from './incident-by-classification-extraction-strategy.service';
import IncidentBySectionExtractionStrategy from './incident-by-section-extraction-strategy.service';
import IncidentExtractionStrategyFactory from './incident-extraction-strategy-factory.service';
import IncidentGroupDisplaySettings from './incident-group-display-settings.service';
import UncertainIncidentClassificationsPipe from './uncertain-incident-classifications.pipe';
import DarkWebPipe from './ddiq-dark-web.pipe';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    ProtectedArticlesModule,
    OiqAdjudicationModule,
    ThemedEntityModule,
    DdiqUIModule,
    OiqSourcesModule,
    DdiqDirectivesModule,
    OiqAssociationModule,
    OiqRiskModule,
    OiqArticleCacheModule,
  ],
  declarations: [UncertainIncidentClassificationsPipe, DdiqIncidentComponent, DdiqIncidentsGroupComponent, DarkWebPipe],
  providers: [
    WindowRefService,
    IncidentExtractionStrategyFactory,
    IncidentByClassificationExtractionStrategy,
    IncidentBySectionExtractionStrategy,
    IncidentGroupDisplaySettings,
  ],
  exports: [UncertainIncidentClassificationsPipe, DdiqIncidentComponent, DdiqIncidentsGroupComponent, DarkWebPipe],
})
export class OiqIncidentsModule {}
