import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedArticleTagReplacement',
})
export default class SelectedArticleTagReplacementPipe implements PipeTransform {
  transform(content) {
    let h2 = /<h2.*?>(.*)<.*\/.*h2>/,
      withSpan = '<span>$1</span>',
      findH1 = /<h1.*?>(.*)<.*\/.*h1>/,
      withH2 = '<h2>$1</h2>',
      findTitle = /<title.*>.*<\/.*title>/;

    return content.replace(findTitle, '').replace(h2, withSpan).replace(findH1, withH2);
  }
}
