<div [ngSwitch]="(locations || []).length > 0" #entityAware="oiqEntityAware" oiq-entity-aware>
  <div *ngSwitchCase="true">
    <table class="related-entity">
      <thead>
        <tr>
          <th colspan="3">
            {{title}}
            <related-entity-group-dynamic-adjudication-aware [entities]="locations">
            </related-entity-group-dynamic-adjudication-aware>
            <div class="pull-right">
              <oiq-filter-count [entities]="locations"></oiq-filter-count>
              <ddiq-sort-buttons
                class="pull-right"
                [entities]="locations"
                [orderBy]="'address, source'"
                [btnTexts]="'Address, Source'"
                (onClick)="sort($event.btnName)">
              </ddiq-sort-buttons>
            </div>
          </th>
        </tr>
      </thead>
      <ng-container *ngIf="!isAllFiltered(locations)">
        <ng-container
          *ngFor="let entity of locations | filterByImpure: ['filtered']: false | orderBy: sortBtnOrderType; trackBy: oiqId">
          <tbody
            class="repeat"
            oiq-adjudication-aware
            #sourceInfo="oiqSourceInfoCtrl"
            oiq-source-info-ctrl
            [info]="{ discovery: entity.base }"
            [ngClass]="{'escalated': entity.relationship?.escalated, 'confirmed': entity.relationship?.confirmed}">
            <tr>
              <td *ngIf="adjudicationSupport && entity.relationship" class="adjudication-controls">
                <oiq-dynamic-adjudication-aware [model]="entity.relationship"></oiq-dynamic-adjudication-aware>
                <ddiq-adjudication-actions
                  [type]="'RELATIONSHIP'"
                  [entityType]="entityAware.entityType"
                  [entityId]="entityAware.entityId"
                  [noReassess]="entityAware.noReassess"
                  [targetId]="entity.relationship.id"
                  (adjudicationComplete)="onAdjudicationComplete($event)"
                  [model]="entity.relationship">
                </ddiq-adjudication-actions>
              </td>
              <td>
                <span [ngClass]="{'disabled': entity.relationship?.disabled}">
                  {{ entity.base.address | address }}
                </span>
                <div class="child-alert inline tt tt-right" *ngIf="alertsEnabled && entity.base.alerts.length > 0">
                  <div class="tool">
                    <ddiq-alert-icon></ddiq-alert-icon>
                  </div>
                  <div class="tip src-meta">
                    <div>
                      <ul>
                        <ul>
                          <li *ngFor="let alert of entity.base.alerts">{{ alert.alertType | translatef }}</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </td>
              <td class="button-col">
                <div class="profile-btn profile-btn-right">
                  <a
                    class="btn btn-primary btn-sm vmid no-print complete"
                    *ngIf="((entity.base.crawlStatus !== 'UNAVAILABLE') && entity.canViewProfile)"
                    [href]="entity.base.url"
                    >View</a
                  >
                </div>
                <div class="inline correlating-urls">
                  <oiq-source-info-badge></oiq-source-info-badge>
                </div>
              </td>
            </tr>
            <tr *ngIf="sourceInfo.showSources" [ngClass]="{'disabled': entity.relationship?.disabled}">
              <td *ngIf="adjudicationSupport && entity.relationship" class="adjudication-controls"></td>
              <td colspan="2">
                <ddiq-source-info></ddiq-source-info>
              </td>
            </tr>
            <tr>
              <td *ngIf="adjudicationSupport && entity.relationship" class="adjudication-controls"></td>
              <td colspan="2">
                <ddiq-adjudication-notes
                  *ngIf="entity.relationship"
                  class="adjudication-notes"
                  [type]="'RELATIONSHIP'"
                  [entityType]="entityAware.entityType"
                  [entityId]="entityAware.entityId"
                  [noReassess]="entityAware.noReassess"
                  [targetId]="entity.relationship.id"
                  [model]="entity.relationship"
                  [label]="'Notes'"
                  [active]="entity.comment">
                </ddiq-adjudication-notes>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <tbody *ngIf="isAllFiltered(locations)">
        <tr>
          <td>
            <p>All results filtered</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
