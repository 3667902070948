<div id="enable-monitoring">
  <div class="modal-title">Enable Monitoring</div>
  <ddiq-monitor-categories (toggled)="onMonitorChoice($event.toggled)" [noToggle]="true"> </ddiq-monitor-categories>
  <div class="center push-down pushdown clr">
    <button
      id="enable-monitoring-button"
      (click)="monitorProfile()"
      [disabled]="!hasChosenCategories"
      class="btn btn-primary">
      Enable
    </button>
    <button id="clear-button" (click)="cancel();" class="btn btn-danger">Cancel</button>
  </div>
</div>
