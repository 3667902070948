import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import Monitor from '../monitor/monitor.service';
import ProfileRefreshService from './profile-refresh.service';
import { Router } from '@angular/router';
@Component({
  selector: 'ddiq-profile-refresh-version-info',
  templateUrl: './ddiq-profile-refresh-version-info.component.tpl.html',
})
export default class ProfileRefreshVersionInfoComponent implements OnInit {
  constructor(
    private profileRefreshService: ProfileRefreshService,
    private oiqProperties: OiqProperties,
    private entity: Entity,
    private apiFetchService: ApiFetchService,
    private monitor: Monitor,
    private router: Router
  ) {}

  selectedVersion;
  profileRefreshEnabled: boolean;
  ngOnInit() {
    this.selectedVersion = null;
    this.profileRefreshEnabled = this.oiqProperties.profileRefreshEnabled;
    this.apiFetchService
      .getProfileRefreshSchedule(this.entity.getType(), this.entity.getId())
      .then((response) => this.profileRefreshService.updateProfileRefreshData(response));
  }

  getEntityName() {
    if (this.entity.getData()) {
      return this.entity.getData().name;
    }
  }

  isProfileRefreshDelta() {
    return this.profileRefreshService.isProfileRefreshDelta();
  }

  isProfileRefreshActive() {
    return this.profileRefreshService.isProfileRefreshActive();
  }

  getRefreshRunStartDate() {
    return this.profileRefreshService.getRefreshRunStartDate();
  }

  getRefreshRunCompleteDate() {
    return this.profileRefreshService.getRefreshRunCompleteDate();
  }

  getRootEntityUrl() {
    return this.profileRefreshService.getRootEntityUrl();
  }

  getNextScheduledRun() {
    return this.profileRefreshService.getNextScheduledRun();
  }

  getRefreshScheduledOn() {
    return this.profileRefreshService.getRefreshScheduledOn();
  }

  getRefreshFrequency() {
    return this.profileRefreshService.getRefreshFrequency();
  }

  isProfileRefreshScheduledOnlyOnce() {
    return this.profileRefreshService.isProfileRefreshScheduledOnlyOnce();
  }

  hasVersions() {
    return this.profileRefreshService.hasVersions();
  }

  versions() {
    return this.profileRefreshService.getVersions();
  }

  versionSelected() {
    this.router.navigate([this.selectedVersion.value]);
  }

  showProfileRefreshInfo() {
    return (
      this.oiqProperties.profileRefreshEnabled &&
      !this.entity.isDiff() &&
      (this.isProfileRefreshDelta() || this.isProfileRefreshActive() || this.hasVersions()) &&
      !this.monitor.isDeltaReport()
    );
  }
}
