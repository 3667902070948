<header class="report-title stick" [ngClass]="{ diff: entity.isDiff() }">
  <div class="report-actions">
    <span class="date-created" *ngIf="(entity.getData().oiqCreatedDate && !entity.isInPreview())"
      >{{ entity.getData().oiqCreatedDate | dateFormat }}</span
    >
    <span class="date-created" *ngIf="(isPreviewUpdateAvailable() && !isLastPreviewUpdate())"
      >Additional information discovered</span
    >
    <span class="date-created" *ngIf="(isPreviewUpdateAvailable() && isLastPreviewUpdate())"
      >Complete profile is now available</span
    >
    <button
      type="button"
      name="btn-update-profile"
      *ngIf="isPreviewUpdateAvailable()"
      class="btn btn-primary btn-sm title-button no-print"
      (click)="updateProfile()"
      [disabled]="updateProfileBtnDisabled">
      Update Profile
    </button>
    <button
      type="button"
      name="btn-monitor"
      *ngIf="!(entity.isProperty() || entity.isDiff() || !isMonitored() || entity.isInPreview())"
      class="btn btn-sm no-print monitored">
      Monitored
    </button>
    <button
      type="button"
      name="btn-scheduled-profile"
      *ngIf="profileRefreshEnabled && isProfileRefreshActive() && !entity.isDiff()"
      class="btn btn-sm no-print monitored">
      Scheduled Refresh <i class="fa fa-spinner fa-pulse fa-lg" *ngIf="isProfileRefreshRunning()"></i>
    </button>
    <profile-status
      *ngIf="isProfileStatusEnabled()"
      (onError)="profileStatusErrorHandler($event.reason)"></profile-status>
    <ddiq-expand-all-event-groups-toggle
      class="no-print"
      (toggled)="expandAllEventGroupsToggled($event.isOpen)"
      [displayOpen]="profile.isAutoExpanded">
    </ddiq-expand-all-event-groups-toggle>
    <oiq-drop-down
      [toggleName]="'btn-actions'"
      class="action-menu btn btn-sm btn-drop-down btn-primary oiq-drop-down-items-right hide-for-print"
      *ngIf="!entity.isProperty()"
      [isDisabled]="entity.isInPreview()"
      [attr.disabled]="entity.isInPreview() == true ? true : null">
      <oiq-drop-down-toggle [toggleTitle]="'Click to choose a profile action'">Actions</oiq-drop-down-toggle>
      <oiq-drop-down-items
        class="actions action-item drop-down-items action-generate-pdf"
        *ngIf="!entity.isProperty() && !isPDFEngineBrowser()">
        <a (click)="modalTrigger('pdf').open=!modalTrigger('pdf').open">Generate PDF</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="isSelectedArticlesEnabled && !isPDFEngineBrowser()">
        <selected-article-download-link [entityType]="entity.getType()" [entityId]="entity.getId()">
          Generate Articles PDF
        </selected-article-download-link>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items action-generate-pdf"
        *ngIf="!entity.isProperty() && isPDFEngineBrowser()">
        <a (click)="print()">Generate PDF</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(entity.isProperty() || entity.isDiff() || !isInGroup || user.isReadOnlyUser())">
        <a (click)="modalTrigger('delegate').open=!modalTrigger('delegate').open">Assign To...</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="isProfileCollectionEditable && !entity.isDiff()">
        <a (click)="modalTrigger('editCollections').open=!modalTrigger('editCollections').open">Edit Collections</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(entity.isProperty() || entity.isDiff() || !isInGroup || user.isReadOnlyUser())">
        <a (click)="modalTrigger('notify').open=!modalTrigger('notify').open">Notify User</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(entity.isProperty() || monitor.isDeltaReport() || !user.canSubmitProfile() || (entity.isPerson() && !isPersonSubmissionEnabled))">
        <a (click)="resubmitReport()">Resubmit Profile</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items action-audit-report"
        *ngIf="!entity.isDiff() && user.canAccessAudit() && !monitor.isDeltaReport()">
        <a [href]="auditUrl" target="_blank">View Audit Data</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(entity.isProperty() || entity.isDiff() || monitor.isDeltaReport())">
        <a (click)="modalTrigger('diff').open=!modalTrigger('diff').open">Compare Profile</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="entity.isPerson() ? canUpdateSeedData() && isPersonSubmissionEnabled : canUpdateSeedData()">
        <a (click)="updateRefreshSeedData()">Update Seed Data</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!isScreeningFeatureEnabled() && canScheduleProfileRefresh()">
        <a (click)="modalTrigger('scheduleRefresh').open=!modalTrigger('scheduleRefresh').open"
          >Schedule Profile Refresh</a
        >
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="profileRefreshEnabled && !monitor.isDeltaReport() && isProfileRefreshScheduled() && !entity.isDiff() && !isProfileRefreshDelta() && !user.isReadOnlyUser()">
        <a (click)="unscheduleProfileRefresh()">Disable Profile Refresh</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(isMonitored() || isScreeningFeatureEnabled() || entity.isProperty() || entity.isDiff() || entity.getData().hidden || !monitoringEnabled || isProfileRefreshDelta() || user.isReadOnlyUser())">
        <a (click)="modalTrigger('enableMonitoring').open=!modalTrigger('enableMonitoring').open">Enable Monitoring</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(!isMonitored() || isScreeningFeatureEnabled() || entity.isProperty() || entity.isDiff() || entity.getData().hidden || isProfileRefreshDelta() || user.isReadOnlyUser())">
        <a (click)="disableMonitoring()">Disable Monitoring</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items"
        *ngIf="!(monitor.isDeltaReport() || entity.isProperty() || !user.canHideProfile() || isProfileRefreshDelta())">
        <div>
          <a *ngIf="entity.getData().hidden" (click)="hideProfile(false)">Unhide Profile</a>
          <a *ngIf="!entity.getData().hidden" (click)="hideProfile(true)">Hide Profile</a>
        </div>
      </oiq-drop-down-items>
      <oiq-drop-down-items
        class="actions action-item drop-down-items action-integrity-report"
        *ngIf="entity.getData().singleClassificationModel && !entity.isDiff()">
        <a [href]="integrityUrl" target="_blank" rel="noopener noreferrer">Integrity Report</a>
      </oiq-drop-down-items>
      <oiq-drop-down-items class="actions action-item drop-down-items" *ngIf="exclusionsAvailable()">
        <a (click)="modalTrigger('exclusionManagement').open=!modalTrigger('exclusionManagement').open"
          >Manage Exclusions</a
        >
      </oiq-drop-down-items>
    </oiq-drop-down>
  </div>

  <div class="entity-header" *ngIf="!entity.isDiff()">
    <div class="monitor-header" *ngIf="monitor.isDeltaReport()"><span class="fa fa-eye"></span></div>
    <div>
      <h1>
        <span class="profile-mode" *ngIf="entity.isNowBuilding()">Now building:</span>
        <span class="profile-mode" *ngIf="entity.isFailed()">Failed:</span>
        {{entity.getDisplayName()}}
        <span *ngIf="entity.getData().hidden"><i class="fa fa-user-secret" title="Hidden"></i></span>
        <span class="diff-date" *ngIf="entity.isUsingLookback()"
          >Lookback Period Start: {{entity.getLookbackPeriodStart() }}</span
        >
      </h1>
    </div>
  </div>
  <div class="entity-header" *ngIf="entity.isDiff()">
    <h1>
      {{ diff.current.displayName }}<span class="diff-label"> Comparison:</span>
      <span class="diff-date"
        >({{ diff.current.oiqCreatedDate | date:'M/d/y' }} vs {{ diff.baseline.oiqCreatedDate | date:'M/d/y' }})</span
      >
    </h1>
  </div>

  <div class="clr"></div>
</header>
