<div id="adverse" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="Adverse"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!previewingEntity || hasAdverseContent()"
    [disable]="!hasAdverseContent()"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <div *ngFor="let subsection of getSubSections(section.metadata.sectionId)" class="sub-section">
      <div [ngSwitch]="subsection">
        <div id="adverse_webContent" *ngSwitchCase="'incidents'">
          <strong class="push-down">Web Content</strong>
          <ddiq-incidents-group
            id="{{entityType}}_adverse_webContent"
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.contentEventGroups"
            (sortDiscoveries)="sortDiscoveries('content', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-incidents-group>
        </div>
        <div id="adverse_securityscorecard" *ngSwitchCase="'websiteScorecards'">
          <strong class="push-down">Cybersecurity Scores</strong>
          <ddiq-website-scorecards-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.websiteScorecardsEventGroups"
            (sortDiscoveries)="sortDiscoveries('websiteScorecards', $event.sortName, $event.eventGroup)"
            [entityType]="entityType"
            [expandAll]="isExpandAllGroups">
          </ddiq-website-scorecards-group>
        </div>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
