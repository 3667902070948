import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import utils from '../../../exiger/utils';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'ddiq-cover',
  templateUrl: './ddiq-cover.component.tpl.html',
})
export default class CoverComponent implements OnInit {
  isMonitor;
  showRequestedBy;
  pdfType;
  summary = { fullName: '', oiqCreatedDate: undefined };
  current = { fullName: '' };
  baseline = { fullName: '' };
  monitor;
  private window: any;
  private routeParams: ParamMap;

  constructor(
    private oiqProperties: OiqProperties,
    private route: ActivatedRoute,
    private windowRef: WindowRefService,
    private apiFetchService: ApiFetchService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.routeParams = route.snapshot.paramMap;
  }

  ngOnInit() {
    this.isMonitor = (utils.getParameter('monitor', this.window.location.hash) || '').toLowerCase() === 'true';
    this.showRequestedBy = this.oiqProperties.pdfShowRequestedBy;

    this.loadSummary();
  }

  loadSummary() {
    const promises = [];
    const entityId1 = this.routeParams.get('entityId');
    const entityId2 = this.routeParams.get('comparableId');
    let entityType = this.routeParams.get('entityType');

    if (entityType === 'location') {
      entityType = 'property';
    }

    if (entityId2) {
      this.pdfType = 'diff';
      promises.push(this.apiFetchService.summary(entityType, entityId1, false));
      promises.push(this.apiFetchService.summary(entityType, entityId2, false));

      Promise.all(promises).then((data) => {
        this.current = data[0];
        this.current.fullName = getName(data[0], entityType);
        this.baseline = data[1];
        this.baseline.fullName = getName(data[1], entityType);

        setTimeout(() => {
          this.window.status = 'oiqLoadComplete';
        }, 3000);
      });
    } else {
      promises.push(this.apiFetchService.summary(entityType, entityId1, false));
      if (this.isMonitor) {
        promises.push(this.apiFetchService.getMonitor(entityType, entityId1));
      }

      Promise.all(promises).then((data) => {
        this.summary = data[0];
        this.summary.fullName = getName(data[0], entityType);

        if (this.isMonitor) {
          this.pdfType = 'monitor';
          this.monitor = data[1];
        }

        setTimeout(() => {
          this.window.status = 'oiqLoadComplete';
        }, 3000);
      });
    }
  }
}

function getName(summary, entityType) {
  switch (entityType) {
    case 'property':
      return (
        summary.address.streetAddress +
        '<br/>' +
        (summary.address.streetAddressLineTwo ? summary.address.streetAddressLineTwo + '<br/>' : '') +
        summary.address.city +
        ', ' +
        summary.address.stateProvince +
        '<br/>' +
        summary.address.zipPostalCode +
        '<br/>' +
        summary.address.country +
        '<br/>'
      );
    case 'person':
      return summary.name;
    case 'company':
      return summary.name;
  }
}
