import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ErrorHandler {
  error(...args: any[]) {
    console.error(...args);
  }

  log(...args: any[]) {
    console.log(...args);
  }
}
