import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import ScreeningFeatureService from './screening-feature.service';
import ScreeningHitCategoriesService from './screening-hit-categories.service';

@Injectable()
export default class ScreeningDashboardSearchRequestInterceptor implements HttpInterceptor {
  constructor(private screeningFeature: ScreeningFeatureService, private route: ActivatedRoute) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = req.url;

    if (this.screeningFeature.isEnabled() && this.isMonitorSearchRequest(requestUrl)) {
      let category,
        hitCategoryParam = ScreeningHitCategoriesService.hitCategoryParam;

      category = this.route.snapshot.queryParamMap.get(hitCategoryParam);

      if (category) {
        const url = `${requestUrl}${this.makeParam(requestUrl, category, hitCategoryParam)}`;
        req = req.clone({ url });
      }
    }

    return next.handle(req);
  }

  private isMonitorSearchRequest(requestUrl) {
    return requestUrl.indexOf('monitor/search') >= 0;
  }

  private makeParam(url, category, paramName) {
    let built;
    if (url.indexOf('?') !== -1) {
      built = `&${paramName}=${category}`;
    } else {
      built = `?${paramName}=${category}`;
    }
    return built;
  }
}
