import $ from 'jquery';
import { Directive, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Directive({
  selector: '[scroll-fixed]',
  exportAs: 'scrollFixed',
})
export default class scrollFixed implements OnInit, OnDestroy {
  links = [];
  ticking = false;
  requestIds = [];
  private element: any;
  private readonly window: any;
  inView: number;
  eventOptions = {
    capture: true,
    passive: true,
  };

  constructor(private windowRef: WindowRefService, private elementRef: ElementRef, private ngZone: NgZone) {
    this.inView = 0;
    this.window = windowRef.nativeWindow;
    this.element = elementRef.nativeElement;
  }

  ngOnInit() {
    //https://stackoverflow.com/questions/44516017/how-to-handle-window-scroll-event-in-angular-4
    this.ngZone.runOutsideAngular(() => {
      this.window.addEventListener('scroll', this.scroll, this.eventOptions);
    });
  }

  ngOnDestroy() {
    this.requestIds.forEach((requestId) => {
      this.window.cancelAnimationFrame(requestId);
    });
    this.window.removeEventListener('scroll', this.scroll, this.eventOptions);
  }

  scroll = () => {
    let nowInView = 0,
      pageYOffset = this.getPageYOffset(this.window);

    if (!this.ticking) {
      const requestId = this.window.requestAnimationFrame(() => {
        this.links = this.element.querySelectorAll('div.section-heading');

        for (let i = 0; i < this.links.length; i++) {
          let link = this.links[i];
          try {
            const ele = $('#' + $(link).attr('href')),
              eleY = ele.length ? Math.floor(ele.offset().top - 136) : 136;

            if (pageYOffset >= eleY) {
              nowInView = i;
            } else {
              // if it's less than the nth, it's less than the nth + k
              break;
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }

        //To prevent change detection from running on scroll event firing.
        //Only those which change the active nav item
        if (nowInView !== this.inView) {
          this.ngZone.run(() => {
            this.inView = nowInView;
          });
        }
        this.ticking = false;
      });
      this.requestIds.push(requestId);
      this.ticking = true;
    }
  };

  getPageYOffset(window) {
    return window.pageYOffset;
  }
}
