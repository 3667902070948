import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ddiq-monitor-closed-summary',
  templateUrl: './ddiq-monitor-closed-summary.component.tpl.html',
})
export default class MonitorClosedSummaryComponent implements OnInit {
  @Input() data: any;
  @Input() category: string;
  hasData: boolean;
  total: number;
  adjudicated: number;
  private incomplete: boolean;

  ngOnInit() {
    const counts = MonitorClosedSummaryComponent.getCounts(this.data, this.category);

    if (counts.total > 0) {
      this.hasData = true;
      this.total = counts.total;
      this.adjudicated = counts.adjudicated;
      this.incomplete = counts.adjudicated !== counts.total;
    } else {
      this.hasData = false;
    }
  }
  private static getCounts(counts, type) {
    const result = {
      adjudicated: 0,
      total: 0,
    };

    if (counts.watchlists[type]) {
      result.adjudicated += counts.watchlists[type].adjudicated || 0;
      result.total += counts.watchlists[type].total || 0;
    }

    if (counts.incidents[type]) {
      result.adjudicated += counts.incidents[type].adjudicated || 0;
      result.total += counts.incidents[type].total || 0;
    }

    if (counts.regulatoryProfiles && counts.regulatoryProfiles[type]) {
      result.adjudicated += counts.regulatoryProfiles[type].adjudicated || 0;
      result.total += counts.regulatoryProfiles[type].total || 0;
    }

    if (counts.websiteScorecards && counts.websiteScorecards[type]) {
      result.adjudicated += counts.websiteScorecards[type].adjudicated || 0;
      result.total += counts.websiteScorecards[type].total || 0;
    }

    return result;
  }
}
