import { Injectable } from '@angular/core';
import ApiFetchService from '../../oiq/common-services/api-fetch.service';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export default class CachedArticleResolver implements Resolve<any> {
  constructor(private apiFetchService: ApiFetchService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.apiFetchService.getResourceFromUrl(route.queryParams.url).then((cachedArticle) => {
      if (!cachedArticle || cachedArticle.fragments.length === 0) {
        this.router.navigate(['/404'], { replaceUrl: true });
      }

      return cachedArticle;
    });
  }
}
