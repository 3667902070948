<div id="bulk-adjudication">
  <div class="modal-title">Bulk Adjudication</div>

  <div class="modal-content center-block" [ngSwitch]="adjudicationMode">
    <div *ngSwitchCase="'CONFIRM'">
      <p>The following items will be confirmed:</p>
      <div class="bulk-summary">
        <table>
          <tr>
            <th class="center">Type</th>
            <th class="center">Number</th>
          </tr>
          <tr *ngFor="let target of targetCounts | orderBy : ['-severity', 'name']">
            <td>{{ target.name }}</td>
            <td class="center">{{ target.count }}</td>
          </tr>
        </table>
      </div>
      <div class="center push-down pushdown clr">
        <div class="clr">
          <img *ngIf="adjudicating" src="./img/loader.gif" class="validation-loading-img push-down" />
        </div>
        <button type="button" (click)="adjudicate();" class="btn btn-primary" [disabled]="adjudicating">
          Adjudicate
        </button>
        <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
      </div>
    </div>

    <div *ngSwitchCase="'ESCALATE'">
      <p>The following items will be starred:</p>
      <div class="bulk-summary">
        <table>
          <tr>
            <th class="center">Type</th>
            <th class="center">Number</th>
          </tr>
          <tr *ngFor="let target of targetCounts | orderBy : ['-severity', 'name']">
            <td>{{ target.name }}</td>
            <td class="center">{{ target.count }}</td>
          </tr>
        </table>
      </div>
      <div class="center push-down pushdown clr">
        <div class="clr">
          <img *ngIf="adjudicating" src="./img/loader.gif" class="validation-loading-img push-down" />
        </div>
        <button type="button" (click)="adjudicate();" class="btn btn-primary" [disabled]="adjudicating">
          Adjudicate
        </button>
        <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
      </div>
    </div>

    <div *ngSwitchCase="'REMOVE'">
      <p>The following items will be adjudicated with the selected reason:</p>
      <div class="bulk-summary">
        <table>
          <tr>
            <th class="center">Type</th>
            <th class="center">Number</th>
          </tr>
          <tr *ngFor="let target of targetCounts | orderBy : ['-severity', 'name']">
            <td>{{ target.name }}</td>
            <td class="center">{{ target.count }}</td>
          </tr>
        </table>
      </div>
      <div class="center pushdown">
        <span class="input">
          <label>Reason for Removal: </label>
        </span>
        <select [(ngModel)]="adjudicationModel.reason">
          <option *ngFor="let adjudicationReason of getAdjudicationReasons()" [value]="adjudicationReason">
            {{adjudicationReason | translatef}}
          </option>
        </select>
      </div>
      <div class="center push-down pushdown clr">
        <div class="clr">
          <img *ngIf="adjudicating" src="./img/loader.gif" class="validation-loading-img push-down" />
        </div>
        <button
          type="button"
          (click)="adjudicate();"
          class="btn btn-primary"
          [disabled]="!adjudicationModel.reason || adjudicating">
          Adjudicate
        </button>
        <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
      </div>
    </div>

    <div *ngSwitchCase="'COMMENT'">
      <p>The following items will have the entered comment attached:</p>
      <div class="bulk-summary">
        <table>
          <tr>
            <th class="center">Type</th>
            <th class="center">Number</th>
          </tr>
          <tr *ngFor="let target of targetCounts | orderBy : ['-severity', 'name']">
            <td>{{ target.name }}</td>
            <td class="center">{{ target.count }}</td>
          </tr>
        </table>
      </div>
      <div class="pushdown">
        <span class="comment-label input">
          <label>Comment: </label>
        </span>
        <div class="comment-box">
          <textarea rows="5" [(ngModel)]="adjudicationModel.comment"></textarea>
        </div>
      </div>
      <div class="center push-down pushdown clr">
        <div class="clr">
          <img *ngIf="adjudicating" src="./img/loader.gif" class="validation-loading-img push-down" />
        </div>
        <button
          type="button"
          (click)="adjudicate();"
          class="btn btn-primary"
          [disabled]="!adjudicationModel.comment || adjudicating">
          Adjudicate
        </button>
        <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
      </div>
    </div>
  </div>
</div>
