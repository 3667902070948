<p *ngIf="!isQueryEmpty" class="search-results">
  Your search <span *ngIf="lastQueryString">for <span>"{{lastQueryString}}"</span></span> has {{results.total}} results
  on {{pagination.total}} pages
</p>

<table class="results-table">
  <tr>
    <th *ngIf="profileScoreEnabled" class="profile-score" (click)="changeSorting('risk')">
      <ddiq-sortable-column [sort]="pagination" column="score"></ddiq-sortable-column>
    </th>
    <th (click)="changeSorting('name')">
      <ddiq-sortable-column column="name" [sort]="pagination">Profile Name</ddiq-sortable-column>
    </th>
    <th class="search-medium" (click)="changeSorting('submission')">
      <ddiq-sortable-column column="submission" [sort]="pagination"
        >{{ 'REFERENCE_ID' | translatef }}</ddiq-sortable-column
      >
    </th>
    <th class="search-narrow" (click)="changeSorting('profileStatus')" *ngIf="canViewStatus">
      <ddiq-sortable-column column="profileStatus" [sort]="pagination">Status</ddiq-sortable-column>
    </th>
    <th class="search-narrow" (click)="changeSorting('date')">
      <ddiq-sortable-column column="date" [sort]="pagination">Date</ddiq-sortable-column>
    </th>
    <th class="search-narrow" *ngIf="isProfileCollectionEnabled"><div>Collections</div></th>
    <th class="search-narrow"><div>Profile Type</div></th>
    <th class="search-narrow" (click)="changeSorting('owner')">
      <ddiq-sortable-column column="owner" [sort]="pagination">Owner</ddiq-sortable-column>
    </th>
    <th class="search-narrow" (click)="changeSorting('reqOrg')" *ngIf="canSearchReqOrg">
      <ddiq-sortable-column column="reqOrg" [sort]="pagination">{{ 'REQ_ORG' | translatef }}</ddiq-sortable-column>
    </th>
    <th class="search-narrow" (click)="changeSorting('correlationId')" *ngIf="searchCorrelationId">
      <ddiq-sortable-column column="correlationId" [sort]="pagination"
        >{{ 'CORRELATION_ID' | translatef }}</ddiq-sortable-column
      >
    </th>
  </tr>

  <tr *ngFor="let person of results.reports">
    <td *ngIf="profileScoreEnabled">
      <ddiq-risk-review class="tool-tip-right" [risk]="person.calculatedRisk"></ddiq-risk-review>
    </td>
    <td>
      <span *ngIf="person.viewDetailsAllowed">
        <a *ngIf="person.name" href="{{ person.profileLink }}">{{ person.name }}</a>
        <a *ngIf="!person.name" href="{{ person.profileLink }}"
          >{{ person.firstName }} {{ person.middleName }} {{ person.lastName }}</a
        >
      </span>
      <span *ngIf="!person.viewDetailsAllowed">
        <span *ngIf="person.name">{{ person.name }}</span>
        <span *ngIf="!person.name">{{ person.firstName }} {{ person.middleName }} {{ person.lastName }}</span>
      </span>
      <div *ngIf="profileRefreshEnabled && person.completedVersions.length">
        <label for="profile-versions">Completed Refresh Runs:</label>
        <select id="profile-versions" onchange="javascript:location.href = this.value;">
          <option *ngFor="let v of person.completedVersions" value="{{v.reportUrl}}">{{v.label}}</option>

          <option></option>
        </select>
      </div>
      <span *ngIf="person.hidden"><i class="fa fa-user-secret fa-lg" title="Hidden"></i></span>
    </td>
    <td>{{ person.submissionId }}</td>
    <td *ngIf="canViewStatus">{{ person.profileStatus }}</td>
    <td>{{ person.oiqCreatedDate | dateFormat }}</td>
    <td *ngIf="isProfileCollectionEnabled">
      <ddiq-limited-list class="collection-box" [list]="person.collections" [limit]="3" [isOpen]="false">
        <ng-template #parentTemplate let-limitedList>
          <span class="badge" *ngFor="let collection of limitedList">{{collection.name}}</span>
        </ng-template>
      </ddiq-limited-list>
    </td>
    <td>{{ person.crawlPlanDescription }}</td>
    <td>
      <a *ngIf="isInGroup" (click)="openDelegationModalFn(person)">{{ person.oiqOwnerFullName }}</a>
      <span *ngIf="!isInGroup">{{ person.oiqOwnerFullName }}</span>
    </td>
    <td *ngIf="canSearchReqOrg">{{ person.reqOrg }}</td>
    <td *ngIf="searchCorrelationId">{{ person.correlationId }}</td>
  </tr>
</table>

<exg-pagination
  [numPages]="pagination.total"
  [currentPage]="pagination.current"
  [paginationBarLength]="pagination.visible"
  [tableTotal]="results.total"
  (onSelectPage)="onSelectPage($event.page)"
  totalLabel="profiles">
</exg-pagination>
