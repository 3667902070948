import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class AdjudicationSummaryConfig {
  constructor(private oiqProperties: OiqProperties) {}

  getConfigFor(crawlPlanType, entityType) {
    var byCrawlPlan,
      entityTypeConfig,
      config = this.oiqProperties.adjudicationSummaryConfig;

    if (config) {
      byCrawlPlan = config[crawlPlanType] || config.defaultConfig;

      if (byCrawlPlan) {
        entityTypeConfig = byCrawlPlan[entityType] || (config.defaultConfig && config.defaultConfig[entityType]);
      }
    }

    return [].concat(entityTypeConfig || []);
  }
}
