import { Injectable } from '@angular/core';
import _ from 'underscore';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import Entity from '../common-services/entity.service';
import IsSubmittedPipe from '../ddiq-filters/is-submitted.pipe';

@Injectable({
  providedIn: 'root',
})
export default class RelatedEntityProcessor {
  constructor(
    private isSubmittedPipe: IsSubmittedPipe,
    private entity: Entity,
    private urlGenerator: UrlGenerator,
    private adjudicationFeature: AdjudicationFeatureService
  ) {}

  for(baseEntityType) {
    const entityType = baseEntityType;
    const that = this;

    return {
      /* entity type INDEPENDENT functions */

      countAlerts: function (entities) {
        if (!entities) {
          return 0;
        }

        return entities.filter(function (entity) {
          // alerts should never be null
          return entity.base.alerts.length > 0;
        }).length;
      },

      getCrawlStatus: function (base) {
        if (base.percentEnriched === 20 || base.reportRequested) {
          return 'IN_PROGRESS';
        } else if (base.percentEnriched === 10) {
          return 'STUB';
        } else if (base.percentEnriched >= 100) {
          return 'COMPLETE';
        }
        return '';
      },

      getSourceUrls: function (relationships, relationshipName = 'AssociatedWith') {
        let relationship = _.findWhere(relationships || [], {
            name: relationshipName,
          }),
          additionalData = relationship ? relationship.additionalData : [],
          datum = _.findWhere(additionalData, {
            label: 'sourceUrls',
          });

        return datum ? datum.value : [];
      },

      addSourceInfo(entity, relationshipName: string = 'AssociatedWith') {
        entity.base.sourceUrls = this.getSourceUrls(entity.relationships, relationshipName) || [];

        const totalArticles: number = entity.base.sourceUrls.length;

        entity.base.sources = entity.base.seedSources.filter((source) => {
          return 'OIQ_DISCOVERY_PROCESS' === source && totalArticles ? !source : source;
        });

        // Company_Crawl handled differently and requires separate filter
        const lengthCompanySources = entity.base.seedSources.filter((source) => {
          return 'COMPANY_CRAWL_PROFILE' === source && totalArticles ? source : !source;
        }).length;

        const totalSources: number = entity.base.sources.length;
        entity.base.totalSourceCount = totalSources + totalArticles - lengthCompanySources;
      },

      isRelationship: function (entity, relationshipName) {
        return (entity.relationships || []).some(function (relationship) {
          return relationship.name === relationshipName;
        });
      },

      getRelationship: function (relationships, relationshipName) {
        relationships = relationships || [];
        for (let i = 0; i < relationships.length; i++) {
          const relationship = relationships[i];
          if (relationshipName === relationship.name) {
            return relationship;
          }
        }
        return null;
      },

      isSubmitted: function (entity) {
        return that.isSubmittedPipe.transform(entity.base);
      },

      /* entity type DEPENDENT functions */

      processEntities: function (entities) {
        return _.map(entities, this.processEntity, this);
      },

      processEntity: function (entity) {
        entity.base.url = this.getUrl(entity.base);
        entity.base.crawlStatus = this.getCrawlStatus(entity.base);
        that.augmentWithAdjudication(entity);
        return entity;
      },

      getUrl: function (base) {
        return that.urlGenerator.generateReport(that.entity.getId(), entityType, base.oiqEntityId);
      },

      getBucketedEntities: function (entities, relationshipMatchers) {
        var buckets = {};
        relationshipMatchers = relationshipMatchers || [];

        // initialize buckets
        _.forEach(relationshipMatchers, function (matcher) {
          buckets[matcher.bucket] = [];
        });

        // bucket each entity
        // priority is submitted, and then the order of the matchers
        // an entity can only appear in a single bucket
        _.forEach(
          entities,
          (entity) => {
            let matcher;

            relationshipMatchers.some(function (someMatcher) {
              if (someMatcher.matchEntity(entity)) {
                matcher = someMatcher;
              }
              return !!matcher;
            });

            if (matcher) {
              buckets[matcher.bucket].push(entity);
              if (matcher.onBucketed) {
                matcher.onBucketed(entity);
              }
            }
          },
          this
        );

        return buckets;
      },
    };
  }

  private augmentWithAdjudication(entity) {
    if (entity.relationships) {
      entity.relationships.forEach((relationship) => {
        const adjudication = relationship.adjudication;
        if (adjudication) {
          relationship.disabled = adjudication.adjudicated;
          relationship.escalated = adjudication.escalated;
          relationship.isAutoAdjudicated = adjudication.auto;

          if (this.adjudicationFeature.isConfirmActionEnabled()) {
            relationship.confirmed = adjudication.confirmed;
          }
        }
      });
    }
  }
}
