import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import utils from '../../../exiger/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ddiq-accordion',
  templateUrl: 'ddiq-accordion.component.tpl.html',
})
export default class Accordion implements OnInit, OnDestroy, OnChanges {
  @Input() disable: boolean;
  @Input() count: number;
  @Input() alerts: number;
  @Input() hasData: boolean;
  @Input() tree: any;
  @Input() nosep: any;
  @Input() openonprint: string;
  @Input() open: string;
  @Input() label: string;
  @Input() id: string;

  isOpen: boolean;
  nobtn: any;
  private window: Window;
  private deregister: Subscription;

  constructor(private messageBus: MessageBusService, private windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit() {
    this.isOpen = this.hasData;

    if (!this.isOpen) {
      const openOnPrint = typeof this.openonprint !== 'undefined';
      const printStyle = utils.getParameter('print', this.window.location.href);

      if (printStyle) {
        this.isOpen = printStyle === 'expanded' || openOnPrint;
      } else {
        this.isOpen = typeof this.open !== 'undefined';
      }
    }

    this.nobtn = typeof this.nobtn !== 'undefined';
    this.tree = typeof this.tree !== 'undefined';
    this.nosep = typeof this.nosep !== 'undefined';

    this.deregister = this.messageBus.on('event:navigateTo', (data) => {
      /* jshint unused: true */
      if (data.to === this.id && !this.isOpen) {
        this.isOpen = true;
      }
    });
  }

  ngOnDestroy() {
    this.deregister.unsubscribe();
  }

  ngOnChanges(changes) {
    if (changes.hasData) {
      this.isOpen = changes.hasData.currentValue;
    }
  }
}
