import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import utils from '../../../exiger/utils';
import moment from 'moment';
import { Component, OnInit, ViewChild } from '@angular/core';
import MonitorCrawlPlans from '../monitor/monitor-crawlplans.service';
import { PersonProfile } from './person-profile.model';
import { Completions } from './completions.model';
import ApiFetchService from '../common-services/api-fetch.service';
import { FormControl } from '@angular/forms';
import ArrayExcludePipe from '../ddiq-filters/array-exclude.pipe';
import DdiqProfileCollectionMissingPermissionForSubmissionService from '../profile-collection/ddiq-profile-collection-missing-permission-for-submission.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import UserService from '../user/user.service';
import I18nService from '../common-services/i18n.service';
import CrawlPlanService from '../common-services/crawlplan.service';
import PersonBuildService from '../themed-entity/person-build.service';
import BuildValidationService from '../themed-entity/build-validation.service';
import PartialUtils from '../common-services/partial-utils.factory';
import OiqProperties from '../common-services/oiq-properties.service';
import ScreeningFeatureService from '../screening/screening-feature.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ddiq-build-person',
  templateUrl: './ddiq-build-person.component.tpl.html',
})
export default class BuildPersonComponent implements OnInit {
  @ViewChild('buildPerson') buildPersonForm: HTMLFormElement;

  PU: any;
  dateFormat: any;
  districtEnabled;
  globalModeEnabled;
  extendedSubmissionFields;
  monitoringEnabled;
  crawlPlans = [];
  languages;
  languagesEnabled;
  isMonitored: boolean = false;
  profileRefreshFrequencies;
  profileRefreshEnabled: boolean;
  defaultCountry;
  submissionIdFieldRequired;
  sexFieldRequired: boolean;
  countryFieldRequired: boolean;
  lookbackEnabled: boolean;
  lookbackTimeunits = ['', 'DAYS', 'WEEKS', 'MONTHS'];
  externalReferenceCodeEnabled: boolean;
  externalReferenceCodeRequired;
  crawlPlanPreselected;
  DEFAULT_NEXT_URL = '/profiles';
  nextUrl;
  countries;
  personTypes = [
    {
      label: 'Standard',
      value: null,
    },
    {
      label: 'Global',
      value: 'GLOBAL',
    },
  ];
  sexOptions = ['Male', 'Female', 'Other'];
  labelPattern;
  datePattern;
  today;
  existingProfiles;
  didYouMean: {
    on: any;
    warn: boolean;
    snapshots: Array<any>;
    message: string;
    headerMessage: string;
    inProcess: boolean;
    isOn: boolean;
    toggle: boolean;
    completions: Array<Completions>;
    validate: any;
    handled: boolean;
  };
  nameSuggestion: {
    isOn: boolean;
    inProcess: boolean;
    generate: any;
    suggestions: any;
    handled: boolean;
    generating: boolean;
    headerMessage: string;
  };

  suggestion;
  confirm;

  buildParams: {
    profiles: Array<PersonProfile>;
    submissionId: number;
  };
  canSubmitProfile: boolean = false;
  updateSeedData: boolean = false;

  buildPersonModal;
  submitting;
  validating;
  profileCollections;
  profileCollectionEnabled;
  isProfileCollectionInvalid: boolean;
  isScreeningEnabled: boolean;
  isMrzEnabled: boolean;

  request: {
    from?: any;
    isRefreshable: any;
    dates: any;
    frequency: any;
  };

  refreshProfileId: any;
  isLoading;

  constructor(
    private apiFetchService: ApiFetchService,
    private ddiqProfileCollectionMissingPermissionForSubmissionService: DdiqProfileCollectionMissingPermissionForSubmissionService,
    public user: UserService,
    private urlGenerator: UrlGenerator,
    private i18nService: I18nService,
    private crawlPlanService: CrawlPlanService,
    private personBuildService: PersonBuildService,
    private buildValidationService: BuildValidationService,
    private modalTrackingService: ModalTrackingService,
    private partialUtils: PartialUtils,
    public oiqProperties: OiqProperties,
    public monitorCrawlPlans: MonitorCrawlPlans,
    private screeningFeature: ScreeningFeatureService,
    private arrayExcludePipe: ArrayExcludePipe,
    private router: Router
  ) {}

  initData(): void {
    if (!this.oiqProperties.personSubmissionEnabled) {
      this.router.navigate(['/404'], { replaceUrl: true });
    }
    this.canSubmitProfile = this.user.canSubmitProfile();

    this.PU = this.partialUtils;
    this.dateFormat = this.oiqProperties.dateFormat;
    this.districtEnabled = this.oiqProperties.districtEnabled;
    this.globalModeEnabled = this.oiqProperties.globalPersonEnabled;
    this.extendedSubmissionFields = this.oiqProperties.extendedSubmissionFields;
    this.monitoringEnabled = this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled;
    this.crawlPlans = this.crawlPlanService.getCrawlPlans();
    this.languages = this.i18nService.languages;
    this.languagesEnabled = this.oiqProperties.languageSelectionEnabled;
    this.profileRefreshFrequencies = this.arrayExcludePipe.transform(
      this.oiqProperties.profileRefreshFrequencies,
      'ONCE'
    );
    this.profileRefreshEnabled = this.oiqProperties.profileRefreshEnabled && this.profileRefreshFrequencies?.length;
    this.defaultCountry = this.oiqProperties.defaultCountry;
    this.submissionIdFieldRequired =
      this.oiqProperties.submissionIdFieldRequired || this.oiqProperties.existingProfileWarningUseSubmissionIdOnly;
    this.sexFieldRequired = this.oiqProperties.sexFieldRequired;
    this.countryFieldRequired = this.oiqProperties.countryFieldRequired;
    this.lookbackEnabled = this.oiqProperties.lookbackEnabled;
    this.externalReferenceCodeEnabled = this.oiqProperties.externalReferenceCodeEnabled;
    this.externalReferenceCodeRequired = this.oiqProperties.externalReferenceCodeRequired;
    this.crawlPlanPreselected = this.oiqProperties.crawlPlanPreselected;
    this.profileCollectionEnabled = this.oiqProperties.isProfileCollectionEnabled;
    this.isProfileCollectionInvalid = false;
    this.profileCollections = [];
    this.isScreeningEnabled = this.screeningFeature.isEnabled();
    this.isMrzEnabled = this.oiqProperties.mrzEnabled;

    this.nextUrl = this.personBuildService.getAndClearParentUrl() || this.DEFAULT_NEXT_URL;
    this.countries = this.i18nService.getCountryNames();

    this.sexOptions = ['Male', 'Female', 'Other'];

    if (this.oiqProperties.dateFormat === 'US') {
      this.labelPattern = 'MM/DD/YYYY';
      this.datePattern = 'mm/dd/yy';
    } else {
      this.labelPattern = 'YYYY-MM-DD';
      this.datePattern = 'yy-mm-dd';
    }

    this.today = new Date();

    this.existingProfiles = {
      isOn: this.oiqProperties.existingProfileWarningEnabled,
      useSubmissionId: this.oiqProperties.existingProfileWarningUseSubmissionId,
      useSubmissionIdOnly: this.oiqProperties.existingProfileWarningUseSubmissionIdOnly,
    };

    this.didYouMean = {
      ...this.didYouMean,
      isOn: this.oiqProperties.didYouMeanOnSubmit,
      toggle: false,
    };

    this.nameSuggestion = {
      ...this.nameSuggestion,
      isOn: this.oiqProperties.nameSuggestionOnSubmit,
    };

    this.suggestion = {};

    this.confirm = {
      toggle: false,
    };

    /**
     * submit button action
     */
    this.didYouMean.validate = (modal) => {
      var validationCrawlPlan = this.crawlPlanService.getValidationCrawlPlan(
        this.buildParams.profiles[0].crawlPlanType
      );
      this.nameSuggestion.inProcess = false;

      if (!this.didYouMean.isOn || !validationCrawlPlan) {
        this.buildProfile(modal);
      } else {
        this.buildParams.profiles[0].validationCrawlPlanType = validationCrawlPlan;
        this.validating = true;
        this.didYouMean.handled = false;
        this.didYouMean.warn = false;
        this.didYouMean.completions = [];
        this.didYouMean.snapshots = [];
        this.suggestion.message = 'Validating...';
        this.suggestion.headerMessage = '';
        this.didYouMean.inProcess = true;

        // set via confirmationDialog directive;
        this.didYouMean.toggle = true;

        var consolidatedBuildParams = this.consolidateFormInput(this.buildParams);

        if (consolidatedBuildParams.submissionId) {
          consolidatedBuildParams.profiles[0].submissionId = consolidatedBuildParams.submissionId;
        }

        var didYouMeanSuccessCallback = (data) => {
          this.didYouMeanSuccess(data);
        };

        var didYouMeanErrorCallback = (data) => {
          this.didYouMeanError(modal, data);
        };

        this.apiFetchService
          .didYouMeanSubmission('person', utils.compact(consolidatedBuildParams.profiles[0]))
          .then(didYouMeanSuccessCallback, didYouMeanErrorCallback);
      }
    };

    this.nameSuggestion.generate = (modal) => {
      this.existingProfiles.inProcess = false;

      this.nameSuggestion.suggestions = [];
      this.nameSuggestion.headerMessage = '';
      this.nameSuggestion.handled = false;

      if (!this.nameSuggestion.isOn) {
        this.didYouMean.validate(modal);
      } else {
        var personNames, countries;

        this.suggestion.message = 'Generating name suggestions...';
        this.nameSuggestion.generating = true;
        this.nameSuggestion.inProcess = true;

        personNames = (this.buildParams.profiles[0].alternativeNamesHolder || [])
          .filter(function (alternativeNameHolder) {
            return alternativeNameHolder.name;
          })
          .map(function (alternativeNameHolder) {
            return alternativeNameHolder.name;
          });
        personNames.push(this.buildParams.profiles[0].personName);

        countries = [];
        countries.push(this.buildParams.profiles[0].currentAddress.country);
        if (
          this.buildParams.profiles[0].birthplace &&
          this.buildParams.profiles[0].birthplace.country &&
          countries.indexOf(this.buildParams.profiles[0].birthplace.country) === -1
        ) {
          countries.push(this.buildParams.profiles[0].birthplace.country);
        }
        countries = this.collectCountries(countries, this.buildParams.profiles[0].citizenships || []);
        countries = this.collectCountries(countries, this.buildParams.profiles[0].countriesOfBusiness || []);
        countries = this.collectCountries(
          countries,
          (this.buildParams.profiles[0].previousAddresses || [])
            .filter(function (previousAddress) {
              return previousAddress.address;
            })
            .map(function (previousAddress) {
              return previousAddress.address;
            })
        );

        if (this.buildParams.profiles[0].personType === 'GLOBAL') {
          countries = ['*'];
        }

        var nameSuggestionSuccessCallback = (data) => {
          this.nameSuggestionSuccess(modal, data);
        };

        var nameSuggestionErrorCallback = () => {
          this.nameSuggestionError(modal);
        };

        this.apiFetchService
          .nameSuggestionSubmission('person', { names: personNames, countries: countries })
          .then(nameSuggestionSuccessCallback, nameSuggestionErrorCallback);
      }
    };

    this.existingProfiles.search = (modal) => {
      this.existingProfiles.suggestions = [];
      this.existingProfiles.headerMessage = '';
      this.existingProfiles.handled = false;
      this.suggestion.message = 'Checking existing profiles...';
      this.existingProfiles.searching = true;
      this.existingProfiles.inProcess = true;

      var name = this.buildParams.profiles[0].personName;
      var submissionId = this.buildParams.submissionId;
      var useSubmissionId = this.existingProfiles.useSubmissionId;
      var useSubmissionIdOnly = this.existingProfiles.useSubmissionIdOnly;

      var existingProfilesSuccessCallback = (data) => {
        this.existingProfileWarningSuccess(modal, data);
      };

      var existingProfilesErrorCallback = () => {
        this.existingProfileWarningError(modal);
      };

      this.apiFetchService
        .findExistingEntities('person', name, submissionId, useSubmissionId, useSubmissionIdOnly)
        .then(existingProfilesSuccessCallback, existingProfilesErrorCallback);
    };
  }

  ngOnInit() {
    this.initData();
    this.buildPersonModal = { open: false };
    this.resetProfileRefresh(false);
    this.initialLoad();
    this.apiFetchService.getProfileCollections({ currentUserPermissions: 'profile_submit' }).then((data) => {
      this.profileCollections = data.results;
      if (this.profileCollectionEnabled && this.profileCollections.length === 0) {
        this.ddiqProfileCollectionMissingPermissionForSubmissionService.showError();
      }
    });
  }

  private isValidYear(year) {
    return moment(year, 'YYYY', true).isValid();
  }

  private isOnlyMonthOrDayProvidedForDoB(birthDate) {
    // Get keys for the tokens with truthy values
    const dobTokensProvided = Object.keys(birthDate).filter((key) => birthDate[key]);
    const onlyTokenProvided = dobTokensProvided[0];

    return dobTokensProvided.length === 1 && (onlyTokenProvided === 'month' || onlyTokenProvided === 'day');
  }

  private isPopulatedAddress(address) {
    if (
      address &&
      (address.streetAddress || address.city || address.stateOrProvince || address.zipOrPostalCode || address.country)
    ) {
      return true;
    }

    return false;
  }

  collectCountries(countries, addresses) {
    let newCountries;
    newCountries = addresses
      .filter((address) => {
        return address.country;
      })
      .map(function (address) {
        return address.country;
      })
      .filter(function (country) {
        return countries.indexOf(country) === -1;
      });
    return countries.concat(newCountries);
  }

  initRefreshRequest() {
    return {
      isRefreshable: false,
      frequency: '',
      dates: {
        options: {
          after: {
            dateFormat: this.datePattern,
            minDate: this.today,
          },
        },
      },
    };
  }

  resetProfileRefresh(enabled) {
    if (!enabled) {
      this.request = this.initRefreshRequest();
    }
  }

  onMonitoringToggled(monitoring) {
    if (this.monitoringEnabled) {
      this.buildParams.profiles[0].monitored = monitoring.isEnabled;
      this.isMonitored = monitoring.isEnabled;

      if (monitoring.isEnabled) {
        this.buildParams.profiles[0].monitorConfig = {
          frequency: monitoring.frequency,
          categories: monitoring.categories.map(function (category) {
            return category.value;
          }),
          crawlPlanType: monitoring.selectedCrawlPlanKey,
        };
      } else {
        delete this.buildParams.profiles[0].monitorConfig;
      }
    }
  }

  /**
   * creates a new object for the html form
   * @returns {profiles : *[]}
   */
  createBuildForm() {
    var crawlPlans = this.getAvailableCrawlPlans(),
      submission = {
        profiles: [
          {
            birthdate: {},
            birthplace: {},
            currentAddress: {
              country: this.defaultCountry,
            },
            currentPosition: {
              companyAddress: {},
            },
            emailAddresses: [{ label: 'other' }],
            phoneNumbers: [{ label: 'other' }],
            educationInstitutionsHolder: [{}],
            previousPositions: [{}],
            previousAddresses: [{}],
            alternativeNamesHolder: [{}],
            relatedPeople: [{}],
            snapshots: [],
            validatedRecords: [],
            countriesOfBusiness: [{}],
            citizenships: [{}],
            monitored: false,
            crawlPlanType: this.determineSelectedCrawlPlan(crawlPlans),
            lookbackConfig: { timeunit: '', lookbackDate: {} },
            crawlScheduleConfig: { previewEnabled: true },
            relatedCompanies: [{}],
            people: [{}],
            companies: [{}],
            requestedLanguages: this.languagesEnabled ? [this.languages[0]] : [],
            personType: null,
            // begin input fields
            personName: '',
            sex: '',

            profileCollectionKeys: [],
            mrz: '',
          },
        ],
      };
    return submission;
  }

  determineSelectedCrawlPlan(crawlPlans) {
    if (crawlPlans.length === 0) {
      // degenerate case; there should always be a default crawl plan
      return 'ddiq';
    } else if (crawlPlans.length === 1 || this.oiqProperties.crawlPlanPreselected) {
      return crawlPlans[0];
    } else {
      return null;
    }
  }

  getAvailableCrawlPlans() {
    return this.oiqProperties.crawlPlans || [];
  }

  /**
   * clears the form and checks for running crawls
   */
  refreshBuildPage() {
    this.submitting = false;
    this.buildParams = Object.assign({}, this.buildParams, this.createBuildForm());
    this.validateProfileCollection();
  }

  /**
   * did you mean success
   */

  didYouMeanSuccess(data) {
    this.didYouMean.snapshots = data.snapshots;
    if (data.completions && data.completions.length > 0) {
      if (data.status === 'TOO_MANY_RESULTS') {
        this.suggestion.message =
          'Too many people were found. If you do not see your subject, please refine your search criteria by adding more information.';
        this.didYouMean.warn = true;
      } else {
        this.suggestion.message = 'We found some similar people:';
      }
      this.didYouMean.completions = data.completions;
      this.didYouMean.completions.forEach((completion) => {
        if (!completion.hasOwnProperty('birthdate')) {
          completion.birthdate = {};
        }
      });
      this.didYouMean.handled = false;
    } else if (data.status === 'NO_APPLICABLE_SOURCES') {
      this.suggestion.message =
        "No suggestions were found. Please verify the data entered and click 'Build Profile' to continue.";
      this.didYouMean.warn = true;
    } else if (data.status === 'ERROR') {
      this.suggestion.headerMessage = 'Validation Unavailable';
      this.suggestion.message =
        "No suggestions were found. Please verify the data entered and click 'Build Profile' to continue.";
      this.didYouMean.warn = true;
    } else {
      this.suggestion.message =
        "No suggestions were found. Please verify the data entered and click 'Build Profile' to continue.";
    }
    this.validating = false;
  }

  /**
   * did you mean error
   */
  didYouMeanError(modal, data) {
    if (data.status === 400) {
      this.cancelDialog(modal);
      this.submitting = false;
      this.validating = false;
      this.modalTrackingService.openError('crawlConfigurationError');
    } else {
      this.suggestion.headerMessage = 'Validation Unavailable';
      this.suggestion.message = 'We were unable to verify the data entered.  Do you wish to submit anyway, or cancel?';
      this.validating = false;
    }
  }

  nameSuggestionSuccess(modal, data) {
    this.nameSuggestion.handled = false;
    this.nameSuggestion.generating = false;
    if (data.length > 0) {
      this.suggestion.message = 'Select alternative names to add:';
      this.nameSuggestion.suggestions = data;
    } else {
      this.didYouMean.validate(modal);
    }
  }

  nameSuggestionError(modal) {
    this.nameSuggestion.handled = false;
    this.nameSuggestion.generating = false;
    this.didYouMean.validate(modal);
  }

  existingProfileWarningSuccess(modal, data) {
    let result;
    this.existingProfiles.handled = false;
    this.existingProfiles.searching = false;
    if (data.totalMatchCount > 0) {
      this.suggestion.message = 'We found some existing profiles:';
      for (let i = 0; i < data.totalMatchCount; i++) {
        result = data.results[i];
        result.reportUrl = this.urlGenerator.generateReport(result.oiqEntityId, 'person', result.oiqEntityId);
      }
      this.existingProfiles.suggestions = data.results;
    } else {
      this.nameSuggestion.generate(modal);
    }
  }

  existingProfileWarningError(modal) {
    this.existingProfiles.handled = false;
    this.existingProfiles.generating = false;
    this.nameSuggestion.generate(modal);
  }

  /**
   * on submission success
   */
  submitSuccess(modal, dialog, data) {
    if (data && data.submissionResult === 'FAILED' && data.results.length === 1) {
      //XXX: OIQ-24541 calling a submitError in a submitSuccess cause
      // backwards compatibility for third party sources that require 2XX http status code
      this.submitError(modal, dialog, data);
    } else {
      this.cancelDialog(modal, dialog);
      this.router.navigate([this.nextUrl]);
    }
  }

  /**
   * on submission error, don't clear the form
   * WR TODO: display some sort of error
   */
  submitError(modal, dialog, data) {
    this.isLoading = false;
    if (data.status === 400) {
      this.cancelDialog(modal, dialog);
      this.submitting = false;
      this.modalTrackingService.openError('crawlConfigurationError');
    } else if (data.status === 409 && this.updateSeedData) {
      this.cancelDialog(modal, dialog);
      this.submitting = false;
      if (data.data && data.data.status === 'COMPLETED') {
        this.modalTrackingService.openError('refreshOutOfSyncError');
      } else {
        this.modalTrackingService.openError('refreshInProgressError');
      }
    } else if (data.submissionResult === 'FAILED') {
      this.cancelDialog(modal, dialog);
      this.submitting = false;
      this.modalTrackingService.openError('submissionError', data.results[0]);
    }
  }

  /**
   * grab params (if any) from the url and populate the form
   */
  initialLoad() {
    var personToBuild = this.personBuildService.getPersonToBuild(),
      i,
      address;

    this.refreshBuildPage();
    if (personToBuild) {
      if (personToBuild.requestedLanguages) {
        this.buildParams.profiles[0].requestedLanguages = personToBuild.requestedLanguages;
      }
      if (personToBuild.refreshProfileId) {
        this.refreshProfileId = personToBuild.refreshProfileId;
        this.updateSeedData = true;
      }
      if (personToBuild.crawlPlanType) {
        if (this.getAvailableCrawlPlans().indexOf(personToBuild.crawlPlanType) >= 0) {
          this.buildParams.profiles[0].crawlPlanType = personToBuild.crawlPlanType;
        } else {
          this.buildParams.profiles[0].crawlPlanType = this.getAvailableCrawlPlans()[0];
        }
      }
      if (personToBuild.externalReferenceCode) {
        this.buildParams.profiles[0].externalReferenceCode = personToBuild.externalReferenceCode;
      }

      this.buildParams.profiles[0].parentPersonEntityId = personToBuild.parentPersonEntityId;
      if (personToBuild.name) {
        this.buildParams.profiles[0].personName = personToBuild.name;
      } else {
        this.buildParams.profiles[0].personName = [
          personToBuild.firstName,
          personToBuild.middleName,
          personToBuild.lastName,
        ]
          .filter(Boolean)
          .join(' ');
      }
      if (personToBuild.submissionId) {
        this.buildParams.submissionId = personToBuild.submissionId;
      }

      this.buildParams.profiles[0].personType = personToBuild.personType || null;
      this.buildParams.profiles[0].currentPosition.jobTitle = personToBuild.currentJobTitle;
      this.buildParams.profiles[0].currentPosition.companyName = personToBuild.currentCompanyName;
      if (personToBuild.location) {
        this.buildParams.profiles[0].currentAddress = {
          streetAddress: personToBuild.location.streetAddress,
          stateOrProvince: personToBuild.location.stateProvince,
          zipOrPostalCode: personToBuild.location.zipPostalCode,
          city: personToBuild.location.city,
          country: this.i18nService.matchCountryName(personToBuild.location.country),
          district: personToBuild.location.district,
          buildingName: personToBuild.location.buildingName,
        };
      }
      if (personToBuild.birthdate) {
        this.buildParams.profiles[0].birthdate = personToBuild.birthdate;
      } else if (personToBuild.age) {
        this.buildParams.profiles[0].age = personToBuild.age;
      }
      if (personToBuild.sex) {
        this.buildParams.profiles[0].sex = personToBuild.sex;
      }
      if (personToBuild.nationality) {
        this.buildParams.profiles[0].nationality = personToBuild.nationality;
      }
      if (personToBuild.birthplace) {
        this.buildParams.profiles[0].birthplace = {
          city: personToBuild.birthplace.city,
          stateOrProvince: personToBuild.birthplace.stateProvince,
          country: personToBuild.birthplace.country,
        };
      }
      if (personToBuild.positions) {
        this.buildParams.profiles[0].previousPositions = [];
        for (i = 0; i < personToBuild.positions.length; i++) {
          if (
            !(
              personToBuild.positions[i].title === personToBuild.currentJobTitle &&
              personToBuild.positions[i].organization === personToBuild.currentCompanyName
            )
          ) {
            this.buildParams.profiles[0].previousPositions.push({
              jobTitle: personToBuild.positions[i].title,
              companyName: personToBuild.positions[i].organization,
            });
          }
        }
      }
      if (personToBuild.education) {
        this.buildParams.profiles[0].educationInstitutionsHolder = [];
        for (i = 0; i < personToBuild.education.length; i++) {
          this.buildParams.profiles[0].educationInstitutionsHolder.push({
            institution: personToBuild.education[i].institution,
          });
        }
      }
      if (personToBuild.emailAddresses) {
        this.buildParams.profiles[0].emailAddresses = [];
        for (i = 0; i < personToBuild.emailAddresses.length; i++) {
          this.buildParams.profiles[0].emailAddresses.push({
            email: personToBuild.emailAddresses[i].email,
            label: personToBuild.emailAddresses[i].label,
          });
        }
      }
      if (personToBuild.phoneNumbers) {
        this.buildParams.profiles[0].phoneNumbers = [];
        for (i = 0; i < personToBuild.phoneNumbers.length; i++) {
          this.buildParams.profiles[0].phoneNumbers.push({
            number: personToBuild.phoneNumbers[i].number,
            label:
              personToBuild.phoneNumbers[i].label in { office: true, company: true }
                ? 'work'
                : personToBuild.phoneNumbers[i].label,
          });
        }
      }
      if (personToBuild.previousAddresses) {
        this.buildParams.profiles[0].previousAddresses = [];
        personToBuild.previousAddresses.forEach((previousAddress) => {
          var address = previousAddress.address,
            submission = this.buildParams.profiles[0];

          if (this.checkAddressType(previousAddress, 'DOING_BUSINESS_IN')) {
            submission.countriesOfBusiness.push({
              country: address.country,
            });
          } else if (this.checkAddressType(previousAddress, 'CITIZEN_OF')) {
            submission.citizenships.push({
              country: address.country,
            });
          } else {
            submission.previousAddresses.push({
              address: {
                streetAddress: address.streetAddress,
                city: address.city,
                stateOrProvince: address.stateProvince,
                zipOrPostalCode: address.zipPostalCode,
                country: address.country,
                district: address.district,
                buildingName: address.buildingName,
              },
            });
          }
        });

        //remove the empty objects added when the form was first created
        if (this.buildParams.profiles[0].countriesOfBusiness.length > 1) {
          this.buildParams.profiles[0].countriesOfBusiness.splice(0, 1);
        }

        if (this.buildParams.profiles[0].citizenships.length > 1) {
          this.buildParams.profiles[0].citizenships.splice(0, 1);
        }
      }

      if (personToBuild.alternativeNames) {
        this.buildParams.profiles[0].alternativeNamesHolder = [];
        for (i = 0; i < personToBuild.alternativeNames.length; i++) {
          this.buildParams.profiles[0].alternativeNamesHolder.push({
            name: personToBuild.alternativeNames[i],
          });
        }
      }
      if (personToBuild.people) {
        this.buildParams.profiles[0].people = [];
        personToBuild.people.forEach((person) => {
          if (person.name && !person.personName) {
            person.personName = person.name;
          }
          this.buildParams.profiles[0].people.push(person);
        });
      }
      if (personToBuild.relatedPeople) {
        this.buildParams.profiles[0].relatedPeople = [];
        for (i = 0; i < personToBuild.relatedPeople.length; i++) {
          this.buildParams.profiles[0].relatedPeople.push({
            name: personToBuild.relatedPeople[i].name,
            relationship: personToBuild.relatedPeople[i].relationship,
          });
        }
      }
      if (personToBuild.companies) {
        this.buildParams.profiles[0].companies = [];
        for (i = 0; i < personToBuild.companies.length; i++) {
          var company = personToBuild.companies[i];
          company.companyName = company.name;
          if (company.mainAddress) {
            company.address = {
              streetAddress: company.mainAddress.streetAddress,
              city: company.mainAddress.city,
              stateOrProvince: company.mainAddress.stateProvince,
              zipOrPostalCode: company.mainAddress.zipPostalCode,
              country: this.i18nService.matchCountryName(company.mainAddress.country),
            };
          } else if (company.locations && company.locations.length > 0) {
            company.address = {
              streetAddress: company.locations[0].streetAddress,
              city: company.locations[0].city,
              stateOrProvince: company.locations[0].stateProvince,
              zipOrPostalCode: company.locations[0].zipPostalCode,
              country: this.i18nService.matchCountryName(company.locations[0].country),
            };
          }
          this.buildParams.profiles[0].companies.push(company);
        }
      }
      if (personToBuild.relatedCompanies && personToBuild.relatedCompanies.length) {
        this.buildParams.profiles[0].relatedCompanies = [];
        for (i = 0; i < personToBuild.relatedCompanies.length; i++) {
          address = personToBuild.relatedCompanies[i].address || [];
          this.buildParams.profiles[0].relatedCompanies.push({
            name: personToBuild.relatedCompanies[i].name,
            relationship: personToBuild.relatedCompanies[i].relationship,
            address: {
              streetAddress: address.streetAddress,
              city: address.city,
              stateOrProvince: address.stateProvince,
              zipOrPostalCode: address.zipPostalCode,
              country: address.country,
            },
          });
        }
      }

      this.ensureDefaultListModel(
        { label: 'other' },
        this.buildParams.profiles[0].phoneNumbers,
        this.buildParams.profiles[0].emailAddresses
      );

      this.ensureDefaultListModel(
        {},
        this.buildParams.profiles[0].people,
        this.buildParams.profiles[0].relatedPeople,
        this.buildParams.profiles[0].educationInstitutionsHolder
      );

      if (personToBuild.collections) {
        personToBuild.collections.forEach((pc) =>
          this.buildParams.profiles[0].profileCollectionKeys.push(pc.profileCollectionKey)
        );
      }
    }
  }

  consolidateFormInput(buildParams) {
    var i,
      educationInstitutionsHolder,
      alternativeNamesHolder,
      alternativeName,
      institutionAsString,
      countriesOfBusiness,
      previousLocationsHolder,
      citizenships;

    // deep copying the build params object
    const copiedBuildParams = JSON.parse(JSON.stringify(buildParams));
    let profile = copiedBuildParams.profiles[0];

    // convert our UI object in to the submission model by converting holder to submission objects
    educationInstitutionsHolder = profile.educationInstitutionsHolder;
    if (educationInstitutionsHolder.length > 0) {
      profile.educationInstitutions = [];
      for (i = 0; i < educationInstitutionsHolder.length; i++) {
        institutionAsString = educationInstitutionsHolder[i].institution;
        if (institutionAsString) {
          profile.educationInstitutions.push(institutionAsString);
        }
      }
    }

    alternativeNamesHolder = profile.alternativeNamesHolder;
    if (alternativeNamesHolder.length > 0) {
      profile.alternativeNames = [];
      for (i = 0; i < alternativeNamesHolder.length; i++) {
        alternativeName = alternativeNamesHolder[i].name;
        if (alternativeName) {
          profile.alternativeNames.push(alternativeName);
        }
      }
    }

    var companies = profile.companies;
    companies.forEach((company) => {
      var companyLocation;
      if (company.address && Object.keys(company.address).length !== 0) {
        companyLocation = {
          streetAddress: company.address.streetAddress,
          stateOrProvince: company.address.stateOrProvince,
          zipOrPostalCode: company.address.zipOrPostalCode,
          city: company.address.city,
          country: this.i18nService.matchCountryName(company.address.country),
        };

        if (company.locations === undefined) {
          company.locations = [];
        }
        var foundLocation = false;
        if (company.locations && company.locations.length > 0) {
          foundLocation = company.locations.some(function (location) {
            return (
              location.streetAddress !== companyLocation.streetAddress &&
              location.stateOrProvince !== companyLocation.stateOrProvince &&
              location.zipOrPostalCode !== companyLocation.zipOrPostalCode &&
              location.city !== companyLocation.city &&
              location.country !== companyLocation.country
            );
          });
        }
        if (!foundLocation) {
          company.locations.push(companyLocation);
        }
        delete company.address;
      }
    });

    /**
     * OIQ-10137 -- employment history without a companyName breaks stuff
     * but since we pre-populate companyAddress.country, it always generates a currentEmployer
     * even though the user hasn't entered anything.
     *
     * So while we can disable the form for certain field combinations (position but no companyName,
     * companyAddress but no companyName; see other comment tagged OIQ-10137 below),
     * we still need to clear it here to get rid of companyAddress.country
     */
    if (!profile.currentPosition.companyName) {
      profile.currentPosition = {};
    }

    previousLocationsHolder = [];
    for (i = 0; i < profile.previousAddresses.length; i++) {
      if (
        !this.checkAddressType(profile.previousAddresses[i], 'DOING_BUSINESS_IN') &&
        !this.checkAddressType(profile.previousAddresses[i], 'CITIZEN_OF')
      ) {
        previousLocationsHolder.push(profile.previousAddresses[i]);
      }
    }

    countriesOfBusiness = profile.countriesOfBusiness;
    for (i = 0; i < countriesOfBusiness.length; i++) {
      if (countriesOfBusiness[i].country) {
        previousLocationsHolder.push({
          address: { country: countriesOfBusiness[i].country },
          label: 'DOING_BUSINESS_IN',
        });
      }
    }

    citizenships = profile.citizenships;
    for (i = 0; i < citizenships.length; i++) {
      if (citizenships[i].country) {
        previousLocationsHolder.push({
          address: { country: citizenships[i].country },
          label: 'CITIZEN_OF',
        });
      }
    }
    profile.previousAddresses = previousLocationsHolder;

    profile.sparseSubmission = this.isSparseProfile();

    if (this.isValidEnoughDate(profile.birthdate)) {
      delete profile.age;
    }

    if (this.request && this.request.frequency) {
      profile.profileRefreshConfig = {
        frequency: this.request.frequency,
      };
      if (this.request.from) {
        profile.profileRefreshConfig.from = this.request.from;
      }
    }

    return copiedBuildParams;
  }

  checkAddressType(address, label) {
    return address && address.label && label && address.label.toUpperCase() === label.toUpperCase();
  }

  /**
   * submit button action
   */
  buildProfile(modal, dialog?) {
    this.submitting = true;
    this.didYouMean.inProcess = false;

    var successCallback = (data) => {
      this.submitSuccess(modal, dialog, data);
    };

    var errorCallback = (data) => {
      this.submitError(modal, dialog, data);
    };

    var consolidatedBuildParams = this.consolidateFormInput(this.buildParams);
    if (this.refreshProfileId) {
      this.apiFetchService
        .updateSeedData('person', this.refreshProfileId, consolidatedBuildParams)
        .then(successCallback, errorCallback);
    } else {
      this.apiFetchService.build('person', utils.compact(consolidatedBuildParams)).then(successCallback, errorCallback);
    }
  }

  cancelDialog(modal, dialog?) {
    this.buildParams.profiles[0].monitored = false;
    delete this.buildParams.profiles[0].monitorConfig;
    this.didYouMean.handled = false;
    this.didYouMean.inProcess = false;
    this.nameSuggestion.inProcess = false;
    this.nameSuggestion.handled = false;
    modal.open = false;
    this.clearSelectedNameSuggestions();
    this.request = this.initRefreshRequest();
    if (dialog) {
      dialog.toggle = false;
    }
  }

  clearSelectedNameSuggestions() {
    var selectedNameSuggestions;
    selectedNameSuggestions = (this.nameSuggestion.suggestions || [])
      .filter((nameSuggestion) => {
        return nameSuggestion.selected;
      })
      .map(function (nameSuggestion) {
        return nameSuggestion.suggestion;
      });

    this.buildParams.profiles[0].alternativeNamesHolder = this.buildParams.profiles[0].alternativeNamesHolder.filter(
      function (alternativeNameHolder) {
        return selectedNameSuggestions.indexOf(alternativeNameHolder.name) === -1;
      }
    );

    if (this.buildParams.profiles[0].alternativeNamesHolder.length === 0) {
      this.buildParams.profiles[0].alternativeNamesHolder.push({});
    }
  }

  isSparseProfile() {
    return this.buildValidationService.isSparsePersonSubmission(
      this.buildParams.profiles[0].currentAddress,
      this.buildParams.profiles[0].currentPosition,
      this.buildParams.profiles[0].previousPositions
    );
  }

  validateProfileCollection(changedModel?: any) {
    this.isProfileCollectionInvalid = false;
    if (!this.profileCollectionEnabled) {
      return;
    }

    if (changedModel !== undefined) {
      this.isProfileCollectionInvalid = !changedModel.length;
    }
  }

  submit(modal) {
    const ensureMonitorCrawlPlans = new Promise((resolve) => {
      if (this.updateSeedData || !this.monitoringEnabled) {
        resolve(undefined);
        return;
      }

      this.monitorCrawlPlans.loadAvailableCrawlPlans(this.buildParams.profiles[0].crawlPlanType).then(resolve);
    });

    // force a call to the server for before doing anything to ensure user is logged in

    this.apiFetchService
      .ping()
      .then(() => ensureMonitorCrawlPlans)
      .then(() => {
        if (!modal.open) {
          modal.open = true;
          if (!this.updateSeedData) {
            if (this.existingProfiles.isOn) {
              this.existingProfiles.search(modal);
            } else if (this.nameSuggestion.isOn) {
              this.nameSuggestion.generate(modal);
            } else if (this.didYouMean.isOn) {
              this.didYouMean.validate(modal);
            } else {
              this.confirm.toggle = true;
            }
          } else {
            this.confirm.toggle = true;
          }
        }
      });
  }

  viewExistingReport(reportUrl) {
    window.open(reportUrl, '_blank');
  }

  mergeSelected() {
    var i, completion, snapshots;
    if (this.didYouMean.handled) {
      snapshots = [];

      for (i = 0; i < this.didYouMean.completions.length; i++) {
        completion = this.didYouMean.completions[i];

        if (completion.selected) {
          snapshots = snapshots.concat(completion.snapshots);
          this.buildParams.profiles[0].validatedRecords = this.buildParams.profiles[0].validatedRecords.concat(
            completion.oiqValidationIds
          );
        }
      }

      this.buildParams.profiles[0].snapshots = snapshots;
    }
  }

  mergeSelectedNameSuggestions() {
    var i, nameSuggestion;
    if (this.nameSuggestion.handled) {
      for (i = 0; i < this.nameSuggestion.suggestions.length; i++) {
        nameSuggestion = this.nameSuggestion.suggestions[i];

        if (nameSuggestion.selected) {
          if (this.hasAlternativeNames()) {
            this.buildParams.profiles[0].alternativeNamesHolder.push({ name: nameSuggestion.suggestion });
          } else {
            this.buildParams.profiles[0].alternativeNamesHolder[0].name = nameSuggestion.suggestion;
          }
        }
      }
    }
  }

  hasSelectedCompletion() {
    var i;
    if (this.didYouMean.completions && this.didYouMean.completions.length > 0) {
      for (i = 0; i < this.didYouMean.completions.length; i++) {
        if (this.didYouMean.completions[i].selected) {
          return true;
        }
      }
    }
    return false;
  }

  refreshSelectionInvalid() {
    if (this.request.isRefreshable) {
      return (
        this.request.frequency === null ||
        typeof this.request.frequency === 'undefined' ||
        this.request.frequency === ''
      );
    } else {
      return false;
    }
  }

  hasSelectedNameSuggestion() {
    return (this.nameSuggestion.suggestions || []).some(function (suggestion) {
      return suggestion.selected;
    });
  }

  addContact() {
    this.buildParams.profiles[0].emailAddresses.push({ label: 'other' });
    this.buildParams.profiles[0].phoneNumbers.push({ label: 'other' });
  }

  removeContact(idx) {
    this.buildParams.profiles[0].emailAddresses.splice(idx, 1);
    this.buildParams.profiles[0].phoneNumbers.splice(idx, 1);
  }

  addPreviousPosition() {
    this.buildParams.profiles[0].previousPositions.push({});
  }

  removePreviousPosition(previousPositionIndex) {
    this.buildParams.profiles[0].previousPositions.splice(previousPositionIndex, 1);
  }

  addAlternativeName() {
    this.buildParams.profiles[0].alternativeNamesHolder.push({});
  }

  removeAlternativeName(idx) {
    this.buildParams.profiles[0].alternativeNamesHolder.splice(idx, 1);
  }

  addPerson(buildProfile) {
    buildProfile.people.push({});
  }

  removePerson(buildProfile, idx) {
    buildProfile.people.splice(idx, 1);
  }

  hasPreviousEmployment() {
    var i, position;
    if (this.buildParams.profiles[0].previousPositions.length) {
      for (i = 0; i < this.buildParams.profiles[0].previousPositions.length; i++) {
        position = this.buildParams.profiles[0].previousPositions[i];
        if (position.jobTitle || position.companyName) {
          return true;
        }
      }
    }
  }

  hasEducation() {
    var i, education;
    if (this.buildParams.profiles[0].educationInstitutionsHolder.length) {
      for (i = 0; i < this.buildParams.profiles[0].educationInstitutionsHolder.length; i++) {
        education = this.buildParams.profiles[0].educationInstitutionsHolder[i];
        if (education.institution) {
          return true;
        }
      }
    }
  }

  hasEmail() {
    var i, email;
    if (this.buildParams.profiles[0].emailAddresses.length) {
      for (i = 0; i < this.buildParams.profiles[0].emailAddresses.length; i++) {
        email = this.buildParams.profiles[0].emailAddresses[i];
        if (email.email) {
          return true;
        }
      }
    }
  }

  hasPhoneNumbers() {
    var i, phoneNumber;
    if (this.buildParams.profiles[0].phoneNumbers.length) {
      for (i = 0; i < this.buildParams.profiles[0].phoneNumbers.length; i++) {
        phoneNumber = this.buildParams.profiles[0].phoneNumbers[i];
        if (phoneNumber.number) {
          return true;
        }
      }
    }
  }

  hasPhoneNumber(completion) {
    return (completion.phoneNumbers || []).some(function (phoneNumber) {
      return phoneNumber.number;
    });
  }

  hasKnownAssociates() {
    return this.buildParams.profiles[0].people.some(function (person) {
      return person.personName;
    });
  }

  hasPreviousAddresses() {
    var i, previousAddress;
    if (this.buildParams.profiles[0].previousAddresses.length) {
      for (i = 0; i < this.buildParams.profiles[0].previousAddresses.length; i++) {
        previousAddress = this.buildParams.profiles[0].previousAddresses[i];
        if (
          previousAddress.address &&
          (previousAddress.address.streetAddress ||
            previousAddress.address.stateOrProvince ||
            previousAddress.address.zipOrPostalCode ||
            previousAddress.address.city ||
            previousAddress.address.country)
        ) {
          return true;
        }
      }
    }
  }

  hasAlternativeNames() {
    return (this.buildParams.profiles[0].alternativeNamesHolder || []).some(function (alternativeNameHolder) {
      return alternativeNameHolder.name;
    });
  }

  hasCompanies(companies) {
    return (companies || []).some(function (company) {
      return company.companyName;
    });
  }

  hasRelatedCompanies() {
    return (this.buildParams.profiles[0].relatedCompanies || []).some(function (company) {
      return company.name;
    });
  }

  collectAllAlternativeNames() {
    var alternativeNames;

    alternativeNames = (this.buildParams.profiles[0].alternativeNamesHolder || [])
      .filter(function (alternativeNameHolder) {
        return alternativeNameHolder.name;
      })
      .map(function (alternativeNameHolder) {
        return alternativeNameHolder.name;
      });

    alternativeNames = alternativeNames.concat(
      (this.nameSuggestion.suggestions || [])
        .filter((nameSuggestion) => {
          return nameSuggestion.selected;
        })
        .filter(function (nameSuggestion) {
          return alternativeNames.indexOf(nameSuggestion.suggestion) === -1;
        })
        .map(function (nameSuggestion) {
          return nameSuggestion.suggestion;
        })
    );
    return alternativeNames;
  }

  // Used to determine if there's enough of a birthdate to calculate age. We want 4-digit years so we don't see huge ages while typing.
  isValidEnoughDate(date) {
    return date && date.year && date.year.toString().length >= 4;
  }

  isValidBirthDate(birthDate) {
    birthDate.month = birthDate.month > 12 ? '12' : birthDate.month;
    birthDate.day = birthDate.day > 31 ? '31' : birthDate.day;
    birthDate.year = birthDate.year > moment().year() ? moment().year() - 1 : birthDate.year;

    let isDoBValid = true;

    if (this.isOnlyMonthOrDayProvidedForDoB(birthDate)) {
      isDoBValid = false;
    }

    if (birthDate && birthDate.month && birthDate.day && birthDate.year) {
      if (this.isValidYear(birthDate.year)) {
        let newMoment = moment(`${birthDate.year} ${birthDate.month} ${birthDate.day}`, 'YYYY MM DD');
        isDoBValid = newMoment.isValid() && newMoment.isSameOrBefore(moment().toDate());
      } else {
        isDoBValid = false;
      }
    } else if (birthDate && birthDate.month && birthDate.day) {
      isDoBValid = moment(`${birthDate.month} ${birthDate.day}`, 'MM DD').isValid();
    } else if (birthDate && birthDate.year) {
      isDoBValid = this.isValidYear(birthDate.year);
    }

    this.setDobValidity(isDoBValid);
    return isDoBValid;
  }

  setDobValidity(isDobValid) {
    if (this.buildPersonForm) {
      const dobTokenYear = this.buildPersonForm.controls['year-birth-date'];

      const dobTokenMonth = this.buildPersonForm.controls['month-birth-date'];

      const dobTokenDay = this.buildPersonForm.controls['day-birth-date'];

      if (!isDobValid) {
        let error = { dob_invalid: true };

        dobTokenYear.setErrors(error);
        dobTokenMonth.setErrors(error);
        dobTokenDay.setErrors(error);
      } else {
        this.removeError(dobTokenYear, 'dob_invalid');
        this.removeError(dobTokenMonth, 'dob_invalid');
        this.removeError(dobTokenDay, 'dob_invalid');
      }
    }
  }

  // a helper function implementation to remove a specific error given then form control
  removeError(control: FormControl, error: string) {
    if (control) {
      const errors = control.errors;
      if (errors) {
        delete errors[error];
        if (!Object.keys(errors).length) {
          control.setErrors(null);
        }
      }
    }
  }

  // Used to determine if there's a valid lookback date, which should be after 1970.
  isValidLookback(date) {
    return date && date.year && date.year.toString().length >= 4 && date.year >= 1970;
  }

  calculateAge(date, today) {
    if (!this.isValidEnoughDate(date)) {
      return null;
    }
    today = today || new Date();
    var birthDate = new Date(+date.year, (+date.month || 1) - 1, +date.day || 1); // Implies birthday of Jan 1 if missing
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 0) {
      return null;
    }
    return age;
  }

  /**
   * WR: OIQ-10137
   *
   * Require the user to input a CompanyName if they populate either the Position
   * or any of the companyAddress fields.  See other OIQ-10137 note above for full scope of changes
   *
   * @param currentPosition
   * @returns {boolean|*}
   */
  isInvalidCurrentEmployer(position) {
    return !position.companyName && (position.jobTitle || this.isPopulatedAddress(position.companyAddress));
  }

  private ensureDefaultListModel(model, ...arrays) {
    for (const array of arrays) {
      if (array && !array.length) {
        array.push(Object.assign({}, model));
      }
    }
  }
}
