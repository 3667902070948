import { NgModule } from '@angular/core';
import DdiqProductRecallsGroupComponent from './ddiq-product-recalls-group.component';
import DdiqProductRecallComponent from './ddiq-product-recall.component';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, PipesModule, DdiqUIModule, OiqAdjudicationModule, DdiqDirectivesModule],
  declarations: [DdiqProductRecallComponent, DdiqProductRecallsGroupComponent],
  exports: [DdiqProductRecallComponent, DdiqProductRecallsGroupComponent],
})
export class OiqProductRecallsModule {}
