import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'ddiq-dup',
  templateUrl: './ddiq-dup.component.tpl.html',
})
export default class DupComponent implements OnInit, AfterViewChecked {
  @Input() model;
  @Input() label: string;
  @Input() key: string;
  @Input() size: string;
  @Input() excludesSingleWords: string;
  @Input() required;
  @Input() name: string;
  invalidInputs: boolean;
  isRequired: boolean;
  regex: RegExp;

  @ViewChildren('myInput') myInput: QueryList<ElementRef<HTMLInputElement>>;

  @Output() modelChange = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {
    if (this.excludesSingleWords) {
      this.regex = /[^A-Za-z\u00c0-\u00ff]|\s/;
    } else {
      this.regex = /.*/;
    }

    if (typeof this.model === 'undefined') {
      this.model = [];
    }

    if (this.model.length === 0) {
      this.model[0] = {};
      this.model[0][this.key] = '';
    }

    this.invalidInputs = false;

    this.isRequired = typeof this.required !== 'undefined';
  }

  hasInvalidInputs() {
    return this.invalidInputs;
  }

  ngAfterViewChecked() {
    // Check to see if any of the inputs have the invalid property
    let anyInvalid = false;
    this.myInput.forEach((childInputs) => {
      if (childInputs.nativeElement.classList.contains('ng-invalid')) {
        childInputs.nativeElement.classList.add('ng-invalid-pattern');
        anyInvalid = true;
      } else {
        childInputs.nativeElement.classList.remove('ng-invalid-pattern');
      }
      this.invalidInputs = anyInvalid;
    });
  }

  createOrDeleteVal(i) {
    if (i === 0) {
      this.model[this.model.length] = {};
      this.model[this.model.length - 1][this.key] = '';
    } else {
      this.model.splice(i, 1);
    }
  }
}
