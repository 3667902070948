import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-monitor-hits-header',
  templateUrl: './ddiq-monitor-hits-header.component.tpl.html',
})
export default class MonitorHitsHeader {
  @Input() submissionId: string;
  @Input() latestMatchDate: string;
  @Input() lastRunDate: string;
  @Input() ownerName: string;
  @Input() adjudicatedHitsCount: number;
  @Input() isGovIdEnabled: boolean = true;
  @Input() idNumbers: {
    identificationType: string;
    number: string;
  }[];

  constructor() {}
}
