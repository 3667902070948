<div
  class="dynamic-adjudication-overlay"
  (click)="toggleSelected()"
  *ngIf="dynamicAdjudicationActive()"
  [ngClass]="{ 'selected' : model.selected }">
  <div [ngSwitch]="mode()">
    <div *ngSwitchCase="'CONFIRM'">
      <i class="fa fa-4x fa-check-square-o" *ngIf="model.selected"></i>
    </div>
    <div *ngSwitchCase="'ESCALATE'">
      <i class="fa fa-4x fa-star" *ngIf="model.selected"></i>
    </div>
    <div *ngSwitchCase="'REMOVE'">
      <i class="fa fa-4x fa-times" *ngIf="model.selected"></i>
    </div>
    <div *ngSwitchCase="'COMMENT'">
      <i class="fa fa-4x fa-commenting-o" *ngIf="model.selected"></i>
    </div>
  </div>
</div>
