import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class UserService {
  private readonly internalStore: any = {};
  private readonly initializedUser: string;
  private cachedVisibleUsers: any = {};

  constructor() {
    this.initializedUser = '__initialized';
  }

  isInitialized() {
    return this.internalStore[this.initializedUser] === 'true';
  }

  passwordNeedsReset() {
    return this.internalStore.lockReason === 'EXPIRED_PASSWORD' || this.internalStore.forcePasswordReset;
  }

  isTermsConditionsAccepted() {
    return !!this.internalStore.termsConditionsAccepted;
  }

  // access checks
  canAccessAudit() {
    return this.checkPermission('audit_view');
  }

  canAccessGlobalAdmin() {
    return this.checkPermission('user_management_global');
  }

  canAccessGroupAdmin() {
    return this.checkPermission('user_management_group');
  }

  canSubmitProfile() {
    return this.checkPermission('profile_submit');
  }

  canViewProfile(owner?) {
    if (owner) {
      return (
        (owner === this.getUserName() && this.checkPermission('profile_view')) ||
        !this.isLimitedUser(this.getUserName())
      );
    }
    return this.checkPermission('profile_view');
  }

  canHideProfile() {
    return this.checkPermission('profile_hide');
  }

  canMonitorProfiles() {
    return this.checkPermission('profile_monitor');
  }

  canAdjudicateProfiles() {
    return this.checkPermission('profile_adjudicate');
  }

  canViewConfiguration() {
    return this.checkPermission('configuration_view');
  }

  getGroup() {
    return this.internalStore.oiqGroup;
  }

  isInGroup() {
    return !!this.getGroup();
  }

  isVisibleUserWithRole(userName, role) {
    let user = this.cachedVisibleUsers[userName] || {};
    return (user.roles || []).indexOf(role) > -1;
  }

  getVisibleUser(username) {
    return this.cachedVisibleUsers[username] || { username: username, fullName: username };
  }

  isLimitedUser(username) {
    var user = this.cachedVisibleUsers[username] || {};
    return (user.roles || []).indexOf('role_limited_user') > -1;
  }

  isReadOnlyUser() {
    return (
      this.isVisibleUserWithRole(this.getUserName(), 'role_read_only_global') ||
      this.isVisibleUserWithRole(this.getUserName(), 'role_read_only_group')
    );
  }

  ///

  getUserName() {
    var name = 'oiqUser';
    return this.internalStore[name];
  }

  initialize(user) {
    this.store('oiqUser', user.username);
    this.store('oiqGroup', user.group);
    this.store('termsConditionsAccepted', user.termsConditionsAccepted);
    this.store('lockReason', user.lockReason);
    this.store('forcePasswordReset', user.forcePasswordReset);

    this.setPermissionIfUserHasPermission(user, 'user_management_global');
    this.setPermissionIfUserHasPermission(user, 'user_management_group');
    this.setPermissionIfUserHasPermission(user, 'audit_view');
    this.setPermissionIfUserHasPermission(user, 'profile_submit');
    this.setPermissionIfUserHasPermission(user, 'profile_hide');
    this.setPermissionIfUserHasPermission(user, 'profile_monitor');
    this.setPermissionIfUserHasPermission(user, 'profile_adjudicate');
    this.setPermissionIfUserHasPermission(user, 'configuration_view');

    this.addRemoveProfileViewPermission(user);

    this.store(this.initializedUser, 'true');
  }

  initializeVisibleUsers(visibleUsers) {
    if (visibleUsers) {
      visibleUsers.forEach((user) => {
        this.cachedVisibleUsers[user.username] = user;
      });
    }
  }

  setPermissionIfUserHasPermission(user, userPermission) {
    var permission = this.makePermissionName(userPermission);
    if (this.userHasPermission(user, userPermission)) {
      this.store(permission, 'true');
    } else {
      this.removeFromStore(permission);
    }
  }

  checkPermission(userPermission) {
    var name = this.makePermissionName(userPermission);
    return this.internalStore[name];
  }

  clear() {
    this.cachedVisibleUsers = {};

    this.removeFromStore('oiqUser');
    this.removeFromStore('oiqGroup');
    this.removeFromStore('termsConditionsAccepted');

    this.removeFromStore(this.makePermissionName('user_management_global'));
    this.removeFromStore(this.makePermissionName('user_management_group'));
    this.removeFromStore(this.makePermissionName('audit_view'));
    this.removeFromStore(this.makePermissionName('profile_submit'));
    this.removeFromStore(this.makePermissionName('profile_view'));
    this.removeFromStore(this.makePermissionName('profile_hide'));
    this.removeFromStore(this.makePermissionName('profile_monitor'));
    this.removeFromStore(this.makePermissionName('profile_adjudicate'));
    this.removeFromStore(this.makePermissionName('configuration_view'));
    this.removeFromStore(this.initializedUser);
  }

  userHasPermission(user, permission) {
    if (user.permissions) {
      for (var i = 0; i < user.permissions.length; i++) {
        if (user.permissions[i] === permission) {
          return true;
        }
      }
    }
    return false;
  }

  makePermissionName(permission) {
    return 'permission_' + permission;
  }

  addRemoveProfileViewPermission(user) {
    var permission = this.makePermissionName('profile_view');

    if (
      this.userHasPermission(user, 'profile_view_global') ||
      this.userHasPermission(user, 'profile_view_group') ||
      this.userHasPermission(user, 'profile_view_self')
    ) {
      this.store(permission, 'true');
    } else {
      this.removeFromStore(permission);
    }
  }

  store(key, value) {
    this.internalStore[key] = value;
  }

  removeFromStore(key) {
    delete this.internalStore[key];
  }
}
