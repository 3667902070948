<div *ngIf="seedData">
  <div class="input-data">
    <div>
      <p *ngIf="seedData.name">{{ seedData.name }}</p>
      <p [hidden]="seedData.name">{{ seedData.firstName }} {{ seedData.middleName }} {{ seedData.lastName }}</p>
    </div>
    <div [hidden]="!seedData.alternativeNames?.length">
      <p>Also known as:</p>
      <span *ngFor="let alternativeName of seedData.alternativeNames; trackBy:trackByIndex">
        {{ alternativeName }}<br />
      </span>
    </div>
    <div *ngIf="seedData.location?.rawAddress">
      <span>{{seedData.location?.rawAddress}}</span>
    </div>
    <div *ngIf="showAddressDetails()">
      <span *ngIf="seedData.location?.streetAddress">{{seedData.location?.streetAddress}}<br /></span>
      <span *ngIf="seedData.location?.buildingName">{{seedData.location?.buildingName}}<br /></span>
      <span *ngIf="seedData.location?.district">{{seedData.location?.district}}</span>
      <span *ngIf="seedData.location?.city"
        ><span *ngIf="seedData.location?.district">, </span>{{seedData.location?.city}}</span
      >
      <span *ngIf="seedData.location?.stateProvince"
        ><span *ngIf="seedData.location?.city">, </span>{{ seedData.location?.stateProvince }}</span
      >
      <span *ngIf="seedData.location?.zipPostalCode || seedData.location?.country"
        >{{ seedData.location?.zipPostalCode }} {{ seedData.location?.country }}</span
      >
    </div>
    <div *ngIf="seedData.birthdate">
      <p>Birth Date:</p>
      <span *ngIf="seedData.birthdate">{{ seedData.birthdate | dateFormat }} (Age: {{seedData.age}})</span>
    </div>
  </div>

  <div class="input-data" *ngIf="hasDoingBusinessIn()">
    <p>Doing Business In:</p>
    <span *ngFor="let doingBusinessIn of (seedData.previousAddresses | doingBusinessIn)">
      <p>
        <span>{{ doingBusinessIn.address | address }}</span>
      </p>
    </span>
  </div>
  <div class="input-data" *ngIf="canShowIdentificationNumbers()">
    <p>Identification Numbers:</p>
    <span *ngFor="let identificationNumber of seedData.identificationNumbers">
      <span *ngIf="identificationNumber.number"
        ><span *ngIf="identificationNumber.identificationType"
          >{{ identificationNumber.identificationType | translatef }}: </span
        >{{ identificationNumber.number }}&nbsp;</span
      >
    </span>
  </div>
  <div class="input-data" *ngIf="hasCitizenOf()">
    <p>Citizenships:</p>
    <span *ngFor="let citizenship of (seedData.previousAddresses | citizenOf)">
      <p>
        <span>{{ citizenship.address | address }}</span>
      </p>
    </span>
  </div>
  <div class="input-data" *ngIf="seedData.personType">
    <p>Submission Type:</p>
    <span *ngIf="seedData.personType">{{ seedData.personType | translatef }}</span>
  </div>
  <div class="input-data" *ngIf="seedData.birthplace">
    <p>Birth Place:</p>
    <span *ngIf="seedData.birthplace.city">{{seedData.birthplace.city}}</span>
    <span *ngIf="seedData.birthplace.stateProvince"
      ><span *ngIf="seedData.birthplace.city">, </span>{{ seedData.birthplace.stateProvince }}</span
    >
    <span *ngIf="seedData.birthplace.country">{{ seedData.birthplace.country }}</span>
  </div>
  <div class="input-data" *ngIf="seedData.nationality">
    <p>Nationality:</p>
    {{ seedData.nationality }}
  </div>
  <div class="input-data" *ngIf="oiqProperties.mrzEnabled && seedData.mrz">
    <p>MRZ:</p>
    <pre>{{ seedData.mrz }} </pre>
    <span *ngIf="seedData.mrzValid"
      ><i class="fa fa-lg fa-check-square"></i>
      valid
    </span>
    <span *ngIf="!seedData.mrzValid"
      ><i class="fa fa-lg fa-remove"></i>
      invalid
    </span>
  </div>

  <div class="pushdown">
    <span *ngIf="section?.data.submissionId"
      >{{ 'REFERENCE_ID' | translatef }}: {{ section?.data.submissionId }}<br
    /></span>
    <span *ngIf="seedData.oiqCreatedBy"
      ><span>{{'Submitted by' | translatef}}</span><span>: {{ seedData.oiqCreatedBy }}</span><br
    /></span>
    <span *ngIf="seedData.reqOrg"
      ><span>{{'Requested by' | translatef}}</span><span>: {{ seedData.reqOrg }}</span><br
    /></span>
    <span *ngIf="seedData.correlationId"
      >{{ 'CORRELATION_ID' | translatef }}<span>: {{ seedData.correlationId }}</span><br
    /></span>
    <span *ngIf="section?.data.crawlPlanTypeDescription"
      >{{ 'PROFILE_TYPE' | translatef }} : {{section?.data.crawlPlanTypeDescription | translatef}}<br
    /></span>
    <span *ngIf="section?.data.externalReferenceCode" class="no-print"
      >{{ 'EXTERNAL_REFERENCE_CODE' | translatef }} : {{section?.data.externalReferenceCode}}</span
    >
    <span *ngIf="this.importedFrom">Imported From : {{this.importedFrom}}</span>
  </div>
</div>
<div class="input-data" [hidden]="seedData"><p>Input data unavailable.</p></div>
