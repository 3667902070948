import { EventEmitter, Component, ElementRef, OnInit, DoCheck, Input, Output } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import ExcludeAdjudicationActionsPipe from '../ddiq-filters/exclude-adjudication-actions.pipe';
import AdjudicationFeatureService from './adjudication-feature.service';
import DynamicAdjudicationService from './dynamic-adjudication.service';
import OiqAdjudicationAware from './oiq-adjudication-aware.directive';
import './img/loading.gif';
import AdjudicationRepository from './adjudication-repository';

@Component({
  selector: 'ddiq-adjudication-actions',
  templateUrl: './ddiq-adjudication-actions.component.tpl.html',
})
export default class AdjudicationActionsComponent implements OnInit, DoCheck {
  @Input() targetId: any;
  @Input() model: any;
  @Output() modelValueChange = new EventEmitter<any>();
  @Input() classification: string;
  @Input() type: string;
  @Input() entityType: any;
  @Input() entityId: any;
  @Input() noReassess: boolean;
  @Output() adjudicationComplete = new EventEmitter<any>();

  canAdjudicate: boolean;
  isAdjudicationEnabled: boolean;
  isBusy: boolean;
  isEditingComment: boolean;
  isActive: boolean;
  wasActive: boolean;
  action: string;
  adjudicationCommentsEnabled: boolean;
  adjudicationReasonsAvailable: any;
  payload: any;

  constructor(
    private dynamicAdjudicationService: DynamicAdjudicationService,
    private excludeAdjudicationActions: ExcludeAdjudicationActionsPipe,
    private adjudicationFeature: AdjudicationFeatureService,
    private elementRef: ElementRef,
    private oiqAdjudicationAware: OiqAdjudicationAware,
    private windowRefService: WindowRefService,
    private adjudicationRepository: AdjudicationRepository
  ) {
    this.isActive = false;
    this.wasActive = false;
  }

  ngOnInit() {
    this.canAdjudicate = this.oiqAdjudicationAware.canAdjudicate();
    this.isAdjudicationEnabled = this.oiqAdjudicationAware.isAdjudicationEnabled();
    this.adjudicationCommentsEnabled = this.oiqAdjudicationAware.isAdjudicationCommentsEnabled();
    this.adjudicationReasonsAvailable = {
      confirm: this.getAdjudicationReasonsForAction('CONFIRM').length !== 0,
      escalate: this.getAdjudicationReasonsForAction('ESCALATE').length !== 0,
      remove: this.getAdjudicationReasonsForAction('REMOVE').length !== 0,
    };
    this.payload = {
      entityType: this.entityType,
      entityId: this.entityId,
      modelType: this.type,
      source: this.model,
      targetId: this.targetId,
      comment: null,
      classification: this.classification,
      reassess: !this.noReassess,
      history: this.model.adjudication
        ? this.excludeAdjudicationActions.transform(
            this.model.adjudication.history,
            this.adjudicationFeature.getHiddenAdjudicationActions()
          )
        : [],
    };
  }

  ngDoCheck() {
    this.isBusy = this.oiqAdjudicationAware.isBusy();
    this.isEditingComment = this.oiqAdjudicationAware.isEditingComment();

    if (this.isActive !== this.wasActive) {
      this.onActiveStateChange();
      this.wasActive = this.isActive;
    }
  }

  editComment() {
    this.adjudicationRepository.removeAdjudication();
    this.oiqAdjudicationAware.editComment();
  }

  isBulkAdjudicating() {
    return this.dynamicAdjudicationService.isDynamicAdjudicationActive();
  }

  isConfirmActionEnabled() {
    return this.adjudicationFeature.isConfirmActionEnabled();
  }

  isStarActionEnabled() {
    return this.adjudicationFeature.isStarActionEnabled();
  }

  getAdjudicationReasons() {
    return this.getAdjudicationReasonsForAction(this.action);
  }

  getAdjudicationReasonsForAction(action) {
    return this.adjudicationFeature.getAdjudicationReasons(action, this.entityType);
  }

  cancel() {
    this.isActive = false;
  }

  save(reason) {
    this.isActive = false;

    let newpayload = {
      ...this.payload,
      reason: reason,
      action: this.action,
      confirmed: this.model.adjudication ? this.model.adjudication.current.confirmed : false,
      escalated: this.model.adjudication ? this.model.adjudication.current.escalated : false,
    };
    if (this.adjudicationCommentsEnabled) {
      this.adjudicationRepository.store(newpayload);
      this.oiqAdjudicationAware.editComment();
    } else {
      this.saveAdjudication(newpayload);
    }
  }

  handleClick() {
    if (this.model.disabled) {
      let newpayload = {
        ...this.payload,
        reason: null,
        action: 'ADD',
        confirmed: this.model.adjudication ? this.model.adjudication.current.confirmed : false,
        escalated: this.model.adjudication ? this.model.adjudication.current.escalated : false,
      };
      if (this.adjudicationCommentsEnabled) {
        this.adjudicationRepository.store(newpayload);
        this.oiqAdjudicationAware.editComment();
      } else {
        this.saveAdjudication(newpayload);
      }
    } else {
      this.action = 'REMOVE';
      this.isActive = !this.isActive;
    }
  }

  handleEscalation() {
    const escalated = !this.model.escalated;
    this.action = escalated ? 'ESCALATE' : 'DEESCALATE';

    if (!escalated || !this.adjudicationReasonsAvailable.escalate) {
      this.isActive = false;
      let newpayload = {
        ...this.payload,
        reason: this.model.adjudication ? this.model.adjudication.current.status : null,
        escalated: escalated,
        action: this.action,
        confirmed: this.model.adjudication ? this.model.adjudication.current.confirmed : false,
      };
      if (this.adjudicationCommentsEnabled) {
        this.adjudicationRepository.store(newpayload);
        this.oiqAdjudicationAware.editComment();
      } else {
        this.saveAdjudication(newpayload);
      }
    } else {
      this.isActive = !this.isActive;
    }
  }

  handleConfirmation() {
    const confirmed = !this.model.confirmed;
    this.action = confirmed ? 'CONFIRM' : 'UNCONFIRM';

    if (!confirmed || !this.adjudicationReasonsAvailable.confirm) {
      this.isActive = false;
      let newpayload = {
        ...this.payload,
        reason: this.model.adjudication ? this.model.adjudication.current.status : null,
        confirmed: confirmed,
        escalated: this.model.adjudication ? this.model.adjudication.current.escalated : false,
        action: this.action,
      };

      if (this.adjudicationCommentsEnabled) {
        this.adjudicationRepository.store(newpayload);
        this.oiqAdjudicationAware.editComment();
      } else {
        this.saveAdjudication(newpayload);
      }
    } else {
      this.isActive = !this.isActive;
    }
  }

  saveAdjudication(adjudicationPayload) {
    this.oiqAdjudicationAware
      .adjudicate({
        entityType: adjudicationPayload.entityType,
        entityId: adjudicationPayload.entityId,
        modelType: adjudicationPayload.modelType,
        source: adjudicationPayload.source,
        targetId: adjudicationPayload.targetId,
        reason: adjudicationPayload.reason,
        confirmed: adjudicationPayload.confirmed,
        escalated: adjudicationPayload.escalated,
        comment: adjudicationPayload.comment,
        classification: adjudicationPayload.classification,
        reassess: !adjudicationPayload.noReassess,
        action: adjudicationPayload.action,
        history: adjudicationPayload.history,
      })
      .then(() => this.adjudicationComplete.emit());
  }

  onActiveStateChange() {
    const reasonsElem = this.elementRef.nativeElement.querySelector('.adjudication-reason');
    const windowRef = this.windowRefService;
    let reasonsElemPos, winPos;

    if (this.isActive) {
      calculatePositions();
      if (isBelowFold()) {
        moveAboveFold();
      }
    } else {
      resetPos();
    }

    function calculatePositions() {
      const computedStyle = getComputedStyle(reasonsElem);
      winPos = windowRef.nativeWindow.innerHeight + windowRef.nativeWindow.scrollTop;
      reasonsElemPos =
        reasonsElem.outerHeight +
        reasonsElem.offsetTop +
        parseInt(computedStyle.getPropertyValue('border-top-width')) +
        parseInt(computedStyle.getPropertyValue('border-bottom-width'));
    }

    function isBelowFold() {
      return winPos < reasonsElemPos;
    }

    function resetPos() {
      reasonsElem.style.top = '';
    }

    function moveAboveFold() {
      reasonsElem.style.top = winPos - reasonsElemPos + 'px';
    }
  }
}
