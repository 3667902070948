<section class="additional-item">
  <div *ngFor="let person of people;index as $index ">
    <button type="button" class="fa fa-trash trash" (click)="removePerson($index)"></button>

    <ddiq-text
      name="ppl-fullName-{{$index}}"
      [label]="'Full Name' | translatef"
      size="full"
      [(model)]="person.personName"></ddiq-text>
    <ddiq-text
      name="ppl-jobTitle-{{$index}}"
      label="Current Job Title"
      size="full"
      [(model)]="person.currentPosition.jobTitle"></ddiq-text>

    <div *ngIf="extendedSubmissionFields">
      <ddiq-dup
        name="ppl-knownAliases-{{$index}}"
        size="half"
        [label]="'Known Aliases / Other Names'"
        [(model)]="person.alternativeNamesHolder"
        key="name"
        [excludesSingleWords]="true"></ddiq-dup>
      <br class="clr" />

      <ddiq-text
        name="ppl-streetAddress-{{$index}}"
        [label]="'Street Address' | translatef"
        size="half"
        [(model)]="person.currentAddress.streetAddress"></ddiq-text>
      <!-- SS: simplifying assumption: we only care about building names in places we care about district (e.g. the UK) -->
      <div *ngIf="districtEnabled">
        <ddiq-text
          name="ppl-buildingName-{{$index}}"
          [label]="'Building Name' | translatef"
          size="quarter"
          [(model)]="person.currentAddress.buildingName"></ddiq-text>
        <ddiq-text
          name="ppl-disctrict-{{$index}}"
          [label]="'District' | translatef"
          size="quarter"
          [(model)]="person.currentAddress.district"></ddiq-text>
      </div>
      <br class="clr" />

      <ddiq-text
        name="ppl-cityTown-{{$index}}"
        [label]="'City/Town' | translatef"
        size="quarter"
        [(model)]="person.currentAddress.city"></ddiq-text>
      <ddiq-text
        name="ppl-stateOrProvince-{{$index}}"
        [label]="'State/Province/Region' | translatef"
        size="quarter"
        [(model)]="person.currentAddress.stateOrProvince"></ddiq-text>
      <ddiq-text
        name="ppl-zipCode-{{$index}}"
        [label]="'ZIP/Postal Code' | translatef"
        size="quarter"
        [(model)]="person.currentAddress.zipOrPostalCode"></ddiq-text>
      <ddiq-select
        name="ppl-country-{{$index}}"
        [label]="'Country' | translatef"
        size="quarter"
        [(model)]="person.currentAddress.country"
        [options]="countries"
        [prompt]="'Choose country' | translatef">
      </ddiq-select>
      <br class="clr" />

      <ddiq-fuzzy-date
        name="ppl-birthDate-{{$index}}"
        [label]="'Birth Date' | translatef"
        [(model)]="person.birthdate"
        [format]="dateFormat"></ddiq-fuzzy-date>
      <ddiq-text
        name="ppl-age-{{$index}}"
        [label]="'Age' | translatef"
        size="quarter"
        [(model)]="person.age"></ddiq-text>
      <ddiq-text
        name="ppl-nationality-{{$index}}"
        [label]="'Nationality' | translatef"
        size="quarter"
        [(model)]="person.nationality"></ddiq-text>
      <div class="input quarter">
        <label>Sex</label>
        <ddiq-actions name="ppl-sex-{{$index}}" [prompt]="person.sex" class="person-gender">
          <a *ngFor="let sex of sexOptions()" (click)="person.sex = sex">{{sex}}</a>
        </ddiq-actions>
      </div>
      <br class="clr" />

      <ddiq-text
        name="ppl-cityOfBirth-{{$index}}"
        size="third"
        [label]="'City of Birth' | translatef"
        [(model)]="person.birthplace.city"></ddiq-text>
      <ddiq-text
        name="ppl-stateOrProvinceOfBirth-{{$index}}"
        size="third"
        [label]="'State/Province/Region of Birth' | translatef"
        [(model)]="person.birthplace.stateOrProvince"></ddiq-text>
      <ddiq-text
        name="ppl-countryOfBirth-{{$index}}"
        size="third"
        [label]="'Country of Birth' | translatef"
        [(model)]="person.birthplace.country"></ddiq-text>
      <br class="clr" />

      <ddiq-accordion label="Known Associates" (hasData)="hasRelatedPeople(person)">
        <ddiq-known-associate-form [relatedPeople]="person.relatedPeople"></ddiq-known-associate-form>
      </ddiq-accordion>
    </div>

    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary" (click)="addPerson()">Add Person</button>
</section>
