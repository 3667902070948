import { Injectable } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ApiFetchService from '../common-services/api-fetch.service';
import ArrayDedupePipe from '../ui/array-dedupe.pipe';

let defaultRiskScore = {
  scoreClass: 'profile-score-na',
  iconClass: 'fa-circle-o',
  tooltip: 'Score unavailable',
};

@Injectable({
  providedIn: 'root',
})
export default class RiskAssessmentService {
  riskValues = {
    NONE: {
      scoreClass: this.makeScoreClassname('safe'),
      iconClass: 'fa-circle',
      tooltip: 'No risk content found',
    },
    LOW: {
      scoreClass: this.makeScoreClassname('low'),
      iconClass: 'fa-circle',
      tooltip: 'Low risk content found',
      indicator: 'L',
    },
    MEDIUM: {
      scoreClass: this.makeScoreClassname('medium'),
      iconClass: 'fa-circle',
      tooltip: 'Medium risk content found',
      indicator: 'M',
    },
    HIGH: {
      scoreClass: this.makeScoreClassname('high'),
      iconClass: 'fa-circle',
      tooltip: 'High risk content found',
      indicator: 'H',
    },
    SEVERE: {
      scoreClass: this.makeScoreClassname('severe'),
      iconClass: 'fa-exclamation-circle',
      tooltip: 'Severe risk content found',
    },
  };
  recalculating = false;
  assessments;
  groupedAssessments;

  constructor(
    private apiFetchService: ApiFetchService,
    private messageBusService: MessageBusService,
    private arrayDedupe: ArrayDedupePipe
  ) {}

  isRecalculating() {
    return this.recalculating;
  }

  getAssessments() {
    return this.assessments;
  }

  getAssessment(classification, riskType, entityId) {
    let matchedAssessment;

    for (const assessment of this.assessments || []) {
      if (
        assessment.name === classification &&
        assessment.riskType === riskType &&
        assessment.oiqEntityId === entityId
      ) {
        matchedAssessment = assessment;
        break;
      }
    }

    return matchedAssessment;
  }

  setAssessments(data) {
    this.assessments = data;
  }

  updateAssessments(entityType?, entityId?) {
    this.recalculating = true;

    return this.apiFetchService
      .assessment(entityType, entityId)
      .then((res) => this.submitSuccess(res))
      .then(() => {
        this.messageBusService.send({ type: 'assessments-updated' });
      });
  }

  getRiskValues(profileRisk) {
    return this.riskValues[profileRisk] || defaultRiskScore;
  }

  getGroupedAssessments() {
    return this.groupedAssessments;
  }

  groupAssessments(assessments) {
    const groups = {},
      groupedAssessments = [];

    let assessmentNames = [];
    assessmentNames = (assessments || []).map((assessment) => {
      groups[assessment.name] = groups[assessment.name] || [];
      groups[assessment.name].push(assessment);
      return assessment.name;
    });

    let uniqueAssessmentsNames = this.arrayDedupe.transform(assessmentNames);

    uniqueAssessmentsNames.forEach(function (assessment) {
      groupedAssessments.push(groups[assessment]);
    });

    return groupedAssessments;
  }

  submitSuccess(response) {
    this.assessments = response.assessments;
    this.groupedAssessments = this.groupAssessments(this.assessments);
    this.recalculating = false;
  }

  makeScoreClassname(kind) {
    return 'profile-score-' + kind;
  }
}
