import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import MessageBusService from '../common/message-bus.service';
import HttpBufferService from './http-buffer.service';
import ErrorHandler from '../common/error-handler.service';

@Injectable()
export default class UnauthorizedRequestInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: ErrorHandler,
    private messageBusService: MessageBusService,
    private httpBuffer: HttpBufferService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 403) {
          return throwError(error);
        }

        if (this.isUserAccessRequest(error)) {
          if (this.isUpdateUserPasswordFailure(error)) {
            this.messageBusService.send({
              type: 'event:update-user-password-failed',
              data: error.error.reason,
            });
          }
          return throwError(error);
        }

        if (this.shouldRetry(error)) {
          this.errorHandler.log(error);
          if (this.isSessionTimedOut(error)) {
            this.messageBusService.send({ type: 'event:session-timed-out' });
          } else {
            this.messageBusService.send({ type: 'event:auth-loginRequired' });
          }
          return this.httpBuffer.append(req);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private shouldRetry(error): boolean {
    return (
      error instanceof HttpErrorResponse && error.status && error.status === 401 && !this.isUserAccessRequest(error)
    );
  }

  private isUserAccessRequest(error: HttpErrorResponse): boolean {
    return error.url?.indexOf('user/') !== -1;
  }

  private isUpdateUserPasswordFailure(error) {
    return (
      error.status === 401 &&
      error.url.indexOf('/password') !== -1 &&
      error.error.reason === 'TOO_MANY_PASSWORD_ATTEMPTS'
    );
  }

  private isSessionTimedOut(error): boolean {
    const url = error.url;

    if (!url) {
      return false;
    }

    return url.indexOf('/ping') !== -1 || url.indexOf('/progress') !== -1 || url.indexOf('/recentlyBuilt') !== -1;
  }
}
