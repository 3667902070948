import { NgModule } from '@angular/core';
import { CommonModule, LowerCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import OiqNavModule from '../nav/oiq-nav.module';
import ddiqCompanySearchComponent from './ddiq-company-search.component';
import TabsDataService from './tabs-data.service';
import ddiqProfileLibraryComponent from './ddiq-profile-library.component';
import SearchService from './search.service';
import ddiqPersonSearchComponent from './ddiq-person-search.component';
import { InProgressSearchComponent } from './ddiq-in-progress-search.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { ProfileDelegationModule } from '../profile-delegation/profile-delegation.module';
import { OrderByPipe } from '../../../../../shared/pipes/order-by.pipe';
import DateFormatPipe from '../../oiq/ddiq-filters/date-format.pipe';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { OiqRiskModule } from '../risk/oiq-risk.module';
import ProfileLibraryResolverService from './profile-library-resolver.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    ThemedEntityModule,
    ProfileDelegationModule,
    FormsModule,
    DdiqUIModule,
    OiqRiskModule,
    OiqNavModule,
  ],
  declarations: [
    ddiqProfileLibraryComponent,
    InProgressSearchComponent,
    ddiqCompanySearchComponent,
    ddiqPersonSearchComponent,
  ],
  providers: [
    LowerCasePipe,
    TranslatefPipe,
    OrderByPipe,
    DateFormatPipe,
    TabsDataService,
    SearchService,
    ProfileLibraryResolverService,
  ],
})
export class ProfileLibraryModule {}
