<nav [ngClass]="{ stick: isProfile }" class="main-nav">
  <div class="brand pull-left">
    <a class="logo" href="#/home">
      <img data-theme-logo />
      <span class="brand-sub-text">Powered by DDIQ</span>
    </a>
  </div>
  <ul>
    <li [hidden]="!canSubmitProfile">
      <a href="#/home">Build</a>
      <ul>
        <li><a href="#/build/company">Build Company</a></li>
        <li *ngIf="isPersonSubmissionEnabled"><a href="#/build/person">Build Person</a></li>
      </ul>
    </li>
    <li><a href="#/profiles">View Profiles</a></li>
    <li class="monitoring-menu" *ngIf="monitoringEnabled">
      <a href="#/monitor"
        >Monitoring
        <div class="monitor-hit-count monitor-indicator" [hidden]="!monitorHitCount">
          <span>{{monitorHitCount}}</span>
        </div></a
      >
      <ul *ngIf="shouldRenderMonitorReportsLink">
        <li><a class="monitor-reports-link" href="#/monitor-report">Reports</a></li>
      </ul>
    </li>
    <li>
      <a href="#/admin">
        {{ userName || '[no username]' }}
        <i class="fa fa-cog"></i>
      </a>
    </li>
    <li>
      <ddiq-logout-link></ddiq-logout-link>
    </li>
    <li class="search">
      <ddiq-nav-search class="pull-left icon-input"></ddiq-nav-search>
    </li>
  </ul>

  <br class="clr" />
</nav>
