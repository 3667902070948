import EventGroupsTemplate from '../event-groups/event-groups-template';
import EventGroupBuilderService from './event-group-builder.service';
import EventGroupsBuilderService from './event-groups-builder.service';
import { Injectable } from '@angular/core';
import { OrderByPipe } from 'ngx-pipes';

@Injectable({
  providedIn: 'root',
})
export class ProductRecallEventGroupsService extends EventGroupsTemplate {
  constructor(
    private eventGroupBuilderService: EventGroupBuilderService,
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private orderBy: OrderByPipe
  ) {
    super('productRecallsEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
  }

  addEventGroupsToSection() {
    return (section) => {
      const safety = section.data.safety;

      if (!this.hasProductRecalls(safety)) return;

      const eventGroups = [];
      const productRecalls = safety.data.productRecalls;

      if (productRecalls.data.length) {
        const eventGroup = this.eventGroupBuilderService
          .newBuilder()
          .withDiscoveries(this.orderBy.transform(productRecalls.data, '-recallDate'))
          .withTitle('Recalls')
          .build();

        eventGroups.push(eventGroup);
      }

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService.newEventGroupsModel(eventGroups).build();
    };
  }

  private hasProductRecalls(safety) {
    return safety && safety.data && safety.data.productRecalls && safety.data.productRecalls.data;
  }
}
