import { Component, Input, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import CheckContentService from '../common-services/check-content.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';

@Component({
  selector: 'ddiq-person-references',
  templateUrl: 'ddiq-person-references.component.tpl.html',
})
export default class DdiqPersonReferencesComponent implements OnInit {
  @Input() section;
  @Input() isExpandAllGroups;
  contentCounter;

  constructor(
    private incidentsUtils: IncidentsUtils,
    private checkContentService: CheckContentService,
    private messageBusService: MessageBusService
  ) {}

  ngOnInit() {
    this.section.data.sourceCount = 0;

    this.contentCounter = {
      _incidentsCount: this.incidentsUtils.observedCount('other'),

      filtered: function () {
        return this._incidentsCount.filtered;
      },
      unfiltered: function () {
        return this._incidentsCount.unfiltered;
      },
      filteredEscalated: function () {
        return this._incidentsCount.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._incidentsCount.unfilteredEscalated;
      },
    };
  }

  referencesHasContent() {
    return this.checkContentService.checkForContent(this.section.data, true);
  }

  sortDiscoveries(sortName, eventGroup) {
    this.messageBusService.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind: 'content',
        sortName,
        eventGroup,
      },
    });
  }
}
