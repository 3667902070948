<img *ngIf="results.isLoading" [src]="'./img/loader.gif'" class="monitor-loading-img" />

<p *ngIf="!isQueryEmpty || !results.reports.length" class="search-results">
  Your search <span *ngIf="lastQueryString">for <span>"{{lastQueryString}}"</span></span> has {{results.total}} results
  on {{pagination.total}} pages
</p>

<table class="results-table" *ngIf="results.reports.length">
  <tr>
    <th class="monitor-medium" (click)="changeSorting('name')">
      <ddiq-sortable-column column="name" [sort]="pagination">
        <span>Profile Name</span>
      </ddiq-sortable-column>
      <div>({{ 'REFERENCE_ID' | translatef }})</div>
    </th>
    <th class="center no-wrap" (click)="changeSorting('date')">
      <ddiq-sortable-column [column]="'date'" [sort]="pagination"> Latest Match </ddiq-sortable-column>
    </th>
    <th *ngIf="!isScreeningFeatureEnabled" class="center no-wrap">Last Run</th>
    <th class="monitor-narrow center">Regulatory</th>
    <th class="monitor-narrow center">Adverse</th>
    <th class="monitor-narrow center">Legal</th>
    <th class="monitor-narrow center">Noteworthy</th>
    <th class="center">Status</th>
    <th class="center no-wrap" (click)="changeSorting('owner')">
      <ddiq-sortable-column [column]="'owner'" [sort]="pagination">Owner</ddiq-sortable-column>
    </th>
    <th class="center">Frequency</th>
    <th class="search-narrow" *ngIf="isGovIdSearchEnabled"><div>Identification</div></th>
  </tr>

  <tr *ngFor="let result of results.reports">
    <td class="top">
      <a href="{{ result.profileLink }}" class="bold">{{ result.name }}</a>
      <div *ngIf="result.submissionId">
        <span>({{ result.submissionId }})</span>
      </div>
    </td>
    <td class="top center no-wrap">
      <span *ngIf="result.latestHit">{{ result.latestHit | dateFormat }}</span
      ><span *ngIf="!result.latestHit">No Matches</span>
    </td>
    <td *ngIf="!isScreeningFeatureEnabled" class="top center no-wrap">
      <span *ngIf="result.latestRun">{{ result.latestRun | dateFormat }}</span
      ><span *ngIf="!result.latestRun">Never</span>
    </td>
    <td class="center">
      <ddiq-monitor-closed-summary [data]="result.counts" [category]="'REGULATORY'"></ddiq-monitor-closed-summary>
    </td>
    <td class="center">
      <ddiq-monitor-closed-summary [data]="result.counts" [category]="'ADVERSE'"></ddiq-monitor-closed-summary>
    </td>
    <td class="center">
      <ddiq-monitor-closed-summary [data]="result.counts" [category]="'LEGAL'"></ddiq-monitor-closed-summary>
    </td>
    <td class="center">
      <ddiq-monitor-closed-summary [data]="result.counts" [category]="'NOTEWORTHY'"></ddiq-monitor-closed-summary>
    </td>
    <td class="top center">{{ result.status | translatef }}</td>
    <td class="top">{{ result.oiqOwnerFullName }}</td>
    <td class="top">{{ result.monitoringFrequency }}</td>
    <td class="profile-library-id" *ngIf="isGovIdSearchEnabled">
      <ddiq-limited-list [list]="result.identificationNumbers" [limit]="1">
        <ng-template #parentTemplate let-limitedList>
          <ul>
            <li *ngFor="let id of limitedList">{{id.identificationType}}: {{id.number}}</li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </td>
  </tr>
</table>

<exg-pagination
  [numPages]="pagination.total"
  [currentPage]="pagination.current"
  [paginationBarLength]="pagination.visible"
  [tableTotal]="results.total"
  (onSelectPage)="onSelectPage($event.page)"
  [totalLabel]="'profiles'">
</exg-pagination>
