import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class OwnershipDataExtractionService {
  extractOwners(corporateRecord) {
    var i,
      node,
      edge,
      owner,
      ownershipData = corporateRecord.ownershipData,
      result = {
        directOwners: [],
        ultimateOwners: [],
        source: (corporateRecord.seedSource || {}).source,
        ownershipRecordId: corporateRecord.id,
      };

    if (ownershipData && ownershipData.edges.length) {
      for (i = 0; i < ownershipData.edges.length; i++) {
        edge = ownershipData.edges[i];
        node = ownershipData.nodes[edge.targetNodeID];
        if (node && edge.sourceNodeID === -1) {
          owner = {
            name: node.name,
            type: node.entityType,
            address: node.address,
            numberOfShares: node.numberOfShares,
            monetaryValue: node.monetaryValue,
            source: node.source,
            observedDate: node.observedDate,
            identificationNumbers: node.identificationNumbers,
            naturesOfControl: edge.naturesOfControl,
            percentageInterest: edge.percentageInterest,
            minPercentageInterest: edge.minPercentageInterest,
            maxPercentageInterest: edge.maxPercentageInterest,
            reportedDate: edge.reportedDate,
            startDate: edge.startDate,
            endDate: edge.endDate,
            additionalData: edge.additionalData,
          };
          if (edge.relationshipType === 'DIRECT_OWNER') {
            result.directOwners.push(owner);
          } else if (edge.relationshipType === 'ULTIMATE_OWNER') {
            result.ultimateOwners.push(owner);
          }
        }
      }
    }
    result.directOwners.sort(function (l, r) {
      if (l.percentageInterest === r.percentageInterest) {
        return l.name.localeCompare(r.name);
      }
      return r.percentageInterest - l.percentageInterest;
    });
    result.ultimateOwners.sort(function (l, r) {
      if (l.percentageInterest === r.percentageInterest) {
        return l.name.localeCompare(r.name);
      }
      return r.percentageInterest - l.percentageInterest;
    });
    return result;
  }

  extractOwnersFromMostReleventCorporateRecord(corporateRecords) {
    var primaryOwnershipCorporateRecord;

    if (!corporateRecords) {
      return;
    }

    primaryOwnershipCorporateRecord = corporateRecords
      .slice(0)
      .sort(function (l, r) {
        if (r.associationScore - l.associationScore === 0) {
          if (r.incorporationDate || l.incorporationDate) {
            if (!l.incorporationDate) {
              return 1;
            } else if (!r.incorporationDate) {
              return -1;
            }
            if (r.incorporationDate === l.incorporationDate) {
              return l.oiqId - r.oiqId;
            }
            return r.incorporationDate - l.incorporationDate;
          }
        }
        return r.associationScore - l.associationScore;
      })
      .filter(function (corporateRecord) {
        return (
          (!corporateRecord.adjudication || !corporateRecord.adjudication.adjudicated) && corporateRecord.ownershipData
        );
      })[0];
    if (primaryOwnershipCorporateRecord) {
      return this.extractOwners(primaryOwnershipCorporateRecord);
    }
    return null;
  }
}
