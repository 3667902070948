import { NgModule } from '@angular/core';
import SystemUseNoticeService from './system-use-notice.service';
import SystemUseNoticeComponent from './system-use-notice.component';

@NgModule({
  declarations: [SystemUseNoticeComponent],
  providers: [SystemUseNoticeService],
  exports: [SystemUseNoticeComponent],
})
export class SystemUseNoticeModule {}
