import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class LexisNexisArticles {
  isLexisNexisUrl(url) {
    return !!(url && url.match(/lexisnexis\/article\/.+/));
  }
}
