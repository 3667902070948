import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import TabsData from './tabs-data.service';
import SearchService from './search.service';
import OiqProperties from '../common-services/oiq-properties.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ddiq-person-search',
  templateUrl: './ddiq-person-search.component.tpl.html',
})
export default class PersonSearchComponent implements OnInit {
  @Input() isQueryEmpty: boolean;
  @Input() lastQueryString: any;
  @Input() isInGroup: boolean;
  @Output() openDelegationModal = new EventEmitter<any>();

  profileRefreshEnabled: boolean;
  canViewStatus: boolean;
  profileScoreEnabled: boolean;
  canSearchReqOrg: boolean;
  searchCorrelationId: any;
  isPaginationResetting: boolean;
  pagination: { [key: string]: any };
  results: { [key: string]: any };
  search: any;
  lastSuccessfulQuery: any;
  isProfileCollectionEnabled: boolean;

  constructor(
    public TabsData: TabsData,
    private SearchService: SearchService,
    private oiqProperties: OiqProperties,
    private route: ActivatedRoute
  ) {
    this.profileRefreshEnabled = this.oiqProperties.profileRefreshEnabled;
    this.canViewStatus = this.oiqProperties.profileStatusesFilter && this.oiqProperties.profileStatusEnabled;
    this.profileScoreEnabled = this.oiqProperties.profileScoreEnabled;
    this.canSearchReqOrg = this.oiqProperties.reqOrgEnabled;
    this.searchCorrelationId = this.oiqProperties.searchCorrelationId;
    this.isProfileCollectionEnabled = this.oiqProperties.isProfileCollectionEnabled;

    this.isPaginationResetting = false;
    this.pagination = {
      current: 1,
      total: 0,
      visible: 7,
      orderBy: 'date',
      descending: true,
    };

    this.results = {
      visible: 10,
      records: [],
      total: 0,
    };
  }

  ngOnInit() {
    /**
     * we need to be able to reset the pagination (via clearSearch or search) without
     * firing an additional search
     */
    this.SearchService.addSetPagination((currentPage, orderBy, descending) => {
      // if a reset pagination has been fired by the searchService,
      // and if changes the current page, then we want to skip the next
      // search request triggered in the $watch(pagination)
      if (this.pagination.current !== currentPage) {
        this.isPaginationResetting = true;
        this.pagination.current = currentPage;
        this.pagination.orderBy = orderBy;
        this.pagination.descending = descending;
      }
    });

    this.search = this.goSearch();
    this.initPersonSearch();
  }

  initPersonSearch() {
    const initialStart = Number(this.route.snapshot.paramMap.get('personStart')) || 0;
    // start is zero indexed, but page is 1 indexed?
    this.pagination.current = 1 + Math.floor(initialStart / this.results.visible);
    this.lastSuccessfulQuery = this.SearchService.previousQuery;
    this.search(this.pagination.current);
  }

  goSearch() {
    const pSearch = this.SearchService.createPersonSearchFunction(this.pagination, this.results);

    pSearch.registerCallback((updatedResults) => {
      this.TabsData.counts.personTotalMatchRelation = updatedResults.totalMatchRelation;
      this.TabsData.counts.personCount = updatedResults.total;
      this.TabsData.pendingSearches = Math.max(this.TabsData.pendingSearches - 1, 0);
    });

    // return a function also usable by initPersonSearch, since that is the
    // only function that knows when this controller is ready to search
    return (currentPage, previousPage) => {
      if (currentPage !== previousPage && !this.isPaginationResetting) {
        pSearch.search(this.lastSuccessfulQuery, (currentPage - 1) * this.results.visible);
      }
      this.isPaginationResetting = false;
    };
  }

  orderStyle(orderBy) {
    return orderBy === this.pagination.orderBy && 'sort-' + this.pagination.descending;
  }

  changeSorting(orderBy) {
    const sort = this.pagination;

    if (sort.orderBy === orderBy) {
      sort.descending = !sort.descending;
    } else {
      sort.orderBy = orderBy;
      sort.descending = orderBy === 'date' || orderBy === 'risk';
    }

    this.search(this.pagination.current);
  }

  onSelectPage(page) {
    this.isPaginationResetting = false;

    if (this.pagination.current !== page) {
      this.search(page, this.pagination.current);
      this.pagination.current = page;
    }
  }

  openDelegationModalFn(person) {
    this.openDelegationModal.emit(person);
  }
}
