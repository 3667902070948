import { Component, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import Monitor from '../monitor/monitor.service';
import Filter from './filter.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import RelatedEntityProcessor from './related-entity-processor.service';

@Component({
  selector: 'ddiq-pdf',
  templateUrl: 'ddiq-pdf.component.tpl.html',
})
export default class DdiqPdfComponent implements OnInit {
  contentTypes = [
    {
      description: 'Show collapsed content only',
      content: 'collapsed',
    },
    {
      description: 'Show all content expanded',
      content: 'expanded',
    },
  ];
  content;
  allRelatedProfilesSelected;
  isAnyExpandedChildProfile;
  relatedCompanies: Array<any>;
  relatedPeople: Array<any>;
  selectedRelatedEntities: Array<any>;
  maxPdfLimit;
  isFiltered;
  expandSources;

  private window;

  constructor(
    private filter: Filter,
    private entity: Entity,
    private urlGenerator: UrlGenerator,
    private modalTrackingService: ModalTrackingService,
    private relatedEntityProcessor: RelatedEntityProcessor,
    private apiFetchService: ApiFetchService,
    private monitor: Monitor,
    private oiqProperties: OiqProperties,
    private messageBusService: MessageBusService,
    private windowRefService: WindowRefService
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  ngOnInit() {
    const selectedContentType = { value: this.contentTypes[0].content };
    if (this.oiqProperties.pdfExpandExceptDedupesOption) {
      this.contentTypes.splice(1, 0, {
        description: 'Show all content, but not other mentions',
        content: 'expandedExceptDedupes',
      });

      selectedContentType.value = this.contentTypes[1].content;
    }

    this.content = selectedContentType;
    this.allRelatedProfilesSelected = false;
    this.isAnyExpandedChildProfile = false;
    this.relatedCompanies = [];
    this.relatedPeople = [];
    this.selectedRelatedEntities = [];

    const companyProcessor = this.relatedEntityProcessor.for('company');
    const personProcessor = this.relatedEntityProcessor.for('person');

    this.messageBusService.on('event:pdf-modal-opened', () => {
      this.isFiltered = this.filter.isFiltered();
      this.selectedRelatedEntities.length = 0;
      this.allRelatedProfilesSelected = false;
      this.isAnyExpandedChildProfile = false;

      this.apiFetchService.children(this.entity.getType(), this.entity.getId(), 'company').then((companies) => {
        this.relatedCompanies = companyProcessor.processEntities(companies).filter((company) => {
          return company.base.percentEnriched >= 100;
        });
      });

      this.apiFetchService.children(this.entity.getType(), this.entity.getId(), 'person').then((people) => {
        this.relatedPeople = personProcessor.processEntities(people).filter((person) => {
          return person.base.percentEnriched >= 100;
        });
      });
    });
  }

  hasRelatedProfiles() {
    return this.relatedPeople.length + this.relatedCompanies.length > 0;
  }

  hasSelectProfilesExceededLimit() {
    return this.selectedRelatedEntities.length >= this.maxPdfLimit;
  }

  addRelatedProfile(relatedEntity) {
    const id = relatedEntity.base.oiqEntityId;

    if (this.selectedRelatedEntities.indexOf(id) === -1) {
      relatedEntity.selected = true;
      this.selectedRelatedEntities.push(id);
    }

    this.updateChildrenStatus();
  }

  removeRelatedProfile(relatedEntity) {
    const id = relatedEntity.base.oiqEntityId;
    const index = this.selectedRelatedEntities.indexOf(id);

    if (index !== -1) {
      relatedEntity.selected = false;
      this.selectedRelatedEntities.splice(index, 1);
    }

    relatedEntity.expanded = false;
    this.updateChildrenStatus();
  }

  selectAllRelatedProfiles() {
    this.relatedCompanies.forEach((relatedCompany) => {
      this.addRelatedProfile(relatedCompany);
    });

    this.relatedPeople.forEach((relatedPerson) => {
      this.addRelatedProfile(relatedPerson);
    });

    this.allRelatedProfilesSelected = true;
    this.updateChildrenStatus();
  }

  unselectAllRelatedProfiles() {
    this.relatedCompanies.forEach((relatedCompany) => {
      this.removeRelatedProfile(relatedCompany);
    });

    this.relatedPeople.forEach((relatedPerson) => {
      this.removeRelatedProfile(relatedPerson);
    });

    this.allRelatedProfilesSelected = false;
    this.updateChildrenStatus();
  }

  updateChildrenStatus() {
    this.isAnyExpandedChildProfile = false;
    this.allRelatedProfilesSelected = true;

    this.relatedCompanies.forEach((relatedCompany) => {
      if (relatedCompany.expanded) {
        this.isAnyExpandedChildProfile = true;
      }

      if (!relatedCompany.selected) {
        this.allRelatedProfilesSelected = false;
      }
    });

    this.relatedPeople.forEach((relatedPerson) => {
      if (relatedPerson.expanded) {
        this.isAnyExpandedChildProfile = true;
      }

      if (!relatedPerson.selected) {
        this.allRelatedProfilesSelected = false;
      }
    });
  }

  generatePdf() {
    let content = this.content.value;
    const filterMask = this.filter.getFilterMask();
    const isExcludeDedupes = this.content.value === 'expandedExceptDedupes';

    if (isExcludeDedupes) {
      content = 'expanded';
    }

    let params =
      '?content=' +
      content +
      (isExcludeDedupes ? '&exclude=dedupes' : '') +
      (this.expandSources ? '&sources=true' : '');

    if (this.monitor.isDeltaReport()) {
      params += '&monitor=true';
    }

    if (this.isFiltered) {
      params += '&filter=' + filterMask;
    }

    if (this.selectedRelatedEntities.length) {
      params +=
        '&child=' +
        this.relatedCompanies
          .concat(this.relatedPeople)
          .filter(function (relatedEntity) {
            return relatedEntity.selected;
          })
          .map(function (relatedEntity) {
            return relatedEntity.base.oiqEntityId + ':' + (relatedEntity.expanded ? 'expanded' : 'collapsed');
          })
          .join('&child=');
    }

    let pdfUrl = this.urlGenerator.generatePdf(
      this.entity.getParentId(),
      this.entity.getType(),
      this.entity.getId(),
      params
    );

    if (this.entity.isDiff()) {
      pdfUrl = this.urlGenerator.generateDiffPdf(
        this.entity.getId(),
        this.entity.getType(),
        this.entity.getDiffId(),
        params
      );
    }

    this.window.open(pdfUrl, '_blank');
    this.modalTrackingService.getTriggers().pdf.open = false;
  }

  cancel() {
    this.modalTrackingService.getTriggers().pdf.open = false;
  }
}
