import { Component } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ExcludeAdjudicationActionsPipe from '../ddiq-filters/exclude-adjudication-actions.pipe';
import Monitor from '../monitor/monitor.service';
import RiskAssessmentService from '../risk/risk-assessment.service';
import IncidentsService from '../themed-entity/incidents.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import StructuredData from '../themed-entity/structured-data.service';
import UserService from '../user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: '[oiq-adjudication-aware]',
  template: '<ng-content></ng-content>',
})
export default class OiqAdjudicationAware {
  private CONFLICT_STATUS = 409;
  private isAdjudicating: boolean;
  private editingComment: boolean;
  private isEnabled: boolean;
  private isCommentsEnabled: boolean;

  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private modalTrackingService: ModalTrackingService,
    private riskAssessmentService: RiskAssessmentService,
    private monitor: Monitor,
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private user: UserService,
    private router: Router,
    private excludeAdjudicationActions: ExcludeAdjudicationActionsPipe,
    private messageBusService: MessageBusService
  ) {
    this.isEnabled = !!oiqProperties.adjudicationEnabled;
    this.isCommentsEnabled = !!oiqProperties.adjudicationCommentsEnabled;
  }

  editComment() {
    this.editingComment = true;
  }

  cancelEditComment() {
    this.editingComment = false;
  }

  isEditingComment() {
    return !!this.editingComment;
  }

  adjudicate(data) {
    let payload;

    this.isAdjudicating = true;
    const submitSuccess = (response) => {
      data.source.adjudication = Object.assign(data.source.adjudication || {}, response.adjudication);
      data.source.confirmed = !!data.source.adjudication.confirmed;
      data.source.escalated = !!data.source.adjudication.escalated;
      data.source.disabled = !!data.source.adjudication.adjudicated;
      data.source.isAutoAdjudicated = data.source.adjudication.auto;
      data.source.adjudication.history = this.excludeAdjudicationActions.transform(
        response.adjudication.history,
        this.oiqProperties.hiddenAdjudicationActions
      );

      this.notifyObservers();

      this.isAdjudicating = false;
      this.editingComment = false;

      if (data.reassess) {
        this.riskAssessmentService.updateAssessments(data.entityType, data.entityId);
      }

      this.messageBusService.send({ type: 'event:event-adjudicated' });
    };

    const submitFailure = (response) => {
      if (response.status === this.CONFLICT_STATUS) {
        data.source.adjudication = response.data.adjudication;
        data.source.disabled = !!response.data.adjudication.adjudicated;
        data.source.confirmed = !!response.data.adjudication.confirmed;
        data.source.escalated = !!response.data.adjudication.escalated;
        data.source.adjudication.history = this.excludeAdjudicationActions.transform(
          response.data.adjudication.history,
          this.oiqProperties.hiddenAdjudicationActions
        );
        this.modalTrackingService.openError('adjudicationError');
      }

      this.isAdjudicating = false;
      this.editingComment = false;
      this.notifyObservers();
      if (data.reassess) {
        this.riskAssessmentService.updateAssessments(data.entityType, data.entityId);
      }
    };

    payload = {
      status: data.reason,
      comment: data.comment,
      confirmed: data.confirmed,
      escalated: data.escalated,
      lastId: data.source.adjudication ? data.source.adjudication.current.id : null,
      action: data.action,
    };

    const useMonitoringAdjudication = this.monitor.isDeltaReport() || this.router.url.startsWith('/monitor');

    return this.apiFetchService
      .adjudicateByClassification(
        data.entityType,
        data.entityId,
        data.modelType,
        data.targetId,
        payload,
        useMonitoringAdjudication,
        data.classification
      )
      .then(submitSuccess, submitFailure);
  }

  private notifyObservers() {
    this.incidents.notifyObservers();
    this.structuredData.notifyObservers();
  }

  isAdjudicationEnabled = function () {
    return this.isEnabled;
  };

  isAdjudicationCommentsEnabled = function () {
    return this.isCommentsEnabled;
  };

  canAdjudicate() {
    return this.user.canAdjudicateProfiles();
  }

  isBusy() {
    return !!this.isAdjudicating;
  }
}
