import { Injectable } from '@angular/core';
import StructuredDataExtractionStrategyService from '../structured-data/structured-data-extraction-strategy.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ExclusionsService from '../exclusions/exclusions.service';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import Entity from '../common-services/entity.service';
import TranslateService from '../common-services/translate.service';
import MaxValuePipe from '../ddiq-filters/max-value.pipe';
import SortableDatePipe from '../ddiq-filters/sortable-date.pipe';
import ChainableStructuredDataFilter from './chainable-structured-data-filter.service';
import Monitor from '../monitor/monitor.service';
import { ProfileHitIdService } from './profile-hit-id.service';

@Injectable({
  providedIn: 'root',
})
export default class StructuredData {
  filterObservers: Array<any> = [];
  masterStructuredData = {
    WATCH_LIST_ENTRY: [],
    REGULATORY_AGENCY_PROFILE: [],
    LEGAL_ACTIVITY: [],
    INMATE_PROFILE: [],
    CORPORATE_RECORD: [],
    PERSON_CORPORATE_RECORD: [],
    OSHA_SAFETY_INSPECTION: [],
    PRODUCT_RECALL: [],
    PROFESSIONAL_LICENSE: [],
    WEBSITE_SCORECARD: [],
  };
  structuredData = {
    basicInfo: {
      professionalLicenses: [],
      corporateRecords: [],
    },
    corporate: {
      corporateRecords: [],
    },
    regulatory: {
      watchlists: [],
      regulatoryProfiles: [],
      oshaSafetyInspections: [],
    },
    adverse: {
      websiteScorecards: [],
    },
    legal: {
      legalFilings: [],
      inmateProfiles: [],
    },
    noteworthy: {
      oshaSafetyInspections: [],
      productRecalls: [],
    },
    other: {},
  };
  byClassification: any;

  constructor(
    private monitor: Monitor,
    private chainableStructuredDataFilter: ChainableStructuredDataFilter,
    private oiqProperties: OiqProperties,
    private structuredDataExtractionStrategyService: StructuredDataExtractionStrategyService,
    private exclusionsService: ExclusionsService,
    private adjudicationFeature: AdjudicationFeatureService,
    private entity: Entity,
    private translateService: TranslateService,
    private maxValue: MaxValuePipe,
    private sortableDate: SortableDatePipe,
    private profileHitId: ProfileHitIdService
  ) {}

  // aggregates and stores structuredData
  init(entity, recordExclusions, webContentType?) {
    this.structuredData = {
      basicInfo: {
        professionalLicenses: [],
        corporateRecords: [],
      },
      corporate: {
        corporateRecords: [],
      },
      regulatory: {
        watchlists: [],
        regulatoryProfiles: [],
        oshaSafetyInspections: [],
      },
      legal: {
        legalFilings: [],
        inmateProfiles: [],
      },
      noteworthy: {
        oshaSafetyInspections: [],
        productRecalls: [],
      },
      adverse: {
        websiteScorecards: [],
      },
      other: {},
    };

    this.byClassification = {
      basicInfo: { PERSON_CORPORATE_RECORD: [], PROFESSIONAL_LICENSE: [] },
      corporate: { CORPORATE_RECORD: [] },
      regulatory: { OSHA_SAFETY_INSPECTION: [] },
      adverse: { WEBSITE_SCORECARD: [] },
      legal: { LEGAL_ACTIVITY: [], INMATE_PROFILE: [] },
      noteworthy: { OSHA_SAFETY_INSPECTION: [], PRODUCT_RECALL: [] },
      other: { BUSINESS_DIRECTORY: [], BUSINESS_LICENSE: [] },
    };

    let i,
      translationKey,
      legalFilings = entity.legalActivities || [],
      oshaSafetyInspections = entity.oshaSafetyInspections || [],
      inmateProfiles = entity.inmateProfiles || [],
      corporateRecords = entity.corporateRecords || [],
      productRecalls = entity.consumerProductRecalls || [],
      professionalLicenses = entity.professionalLicenses || [],
      extractionStrategy = this.structuredDataExtractionStrategyService.getStrategy(webContentType);

    extractionStrategy.extract(
      entity,
      'watchLists',
      (watchList) => {
        if (recordExclusions) {
          if (this.monitor.isDeltaReport()) {
            this.exclusionsService.registerHit('WATCHLIST', watchList.classificationLabelKey);
          } else {
            this.exclusionsService.registerHit('RECORD_CLUSTER', watchList.classificationLabelKey);
          }
        }

        const translationKey = watchList.classificationLabelKey || 'COMPLIANCE_SANCTIONS_PEP',
          events = StructuredData.getEventsFromWatchList(watchList);

        watchList.filterFields = {
          kind: 'watchlists',
          severity: watchList.severity || 0,
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: this.maxValue.transform(events, 'occurred', this.sortableDate),
          score: watchList.associationScore,
        };

        this.normalizeAdjudication(watchList);

        (this.byClassification.regulatory[translationKey] =
          this.byClassification.regulatory[translationKey] || []).push(watchList);

        this.profileHitId.applyIdTo(watchList);
      },
      (watchLists) => {
        Array.prototype.push.apply(this.structuredData.regulatory.watchlists, watchLists);

        this.masterStructuredData.WATCH_LIST_ENTRY = watchLists;
      }
    );

    extractionStrategy.extract(
      entity,
      'regulatoryProfiles',
      (regulatoryProfile) => {
        if (recordExclusions) {
          this.exclusionsService.registerHit('REGULATORY', regulatoryProfile.classificationLabelKey);
        }

        const translationKey = regulatoryProfile.classificationLabelKey || regulatoryProfile.seedSource.source;

        regulatoryProfile.filterFields = {
          kind: 'regulatoryProfiles',
          severity: regulatoryProfile.severity || 0,
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: regulatoryProfile.statusDate,
          score: regulatoryProfile.associationScore,
        };

        if (regulatoryProfile.additionalCompanyClassifications) {
          StructuredData.applyAdditionalCompanyClassificationsTo(regulatoryProfile);
        }
        if (regulatoryProfile.identification) {
          StructuredData.applyAdditionalGovernmentIdentificationsTo(regulatoryProfile);
        }

        this.normalizeAdjudication(regulatoryProfile);

        (this.byClassification.regulatory[translationKey] =
          this.byClassification.regulatory[translationKey] || []).push(regulatoryProfile);

        this.profileHitId.applyIdTo(regulatoryProfile);
      },
      (regulatoryProfiles) => {
        Array.prototype.push.apply(this.structuredData.regulatory.regulatoryProfiles, regulatoryProfiles);

        this.masterStructuredData.REGULATORY_AGENCY_PROFILE = regulatoryProfiles;
      }
    );

    if (legalFilings && legalFilings.length > 0) {
      translationKey = 'LEGAL_ACTIVITY';

      for (i = 0; i < legalFilings.length; i++) {
        legalFilings[i].filterFields = {
          kind: 'legalFilings',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: legalFilings[i].legalActivityDate,
          score: legalFilings[i].associationScore,
        };
        this.normalizeAdjudication(legalFilings[i]);
      }
    }

    Array.prototype.push.apply(this.structuredData.legal.legalFilings, legalFilings);
    Array.prototype.push.apply(this.byClassification.legal.LEGAL_ACTIVITY, legalFilings);
    this.masterStructuredData.LEGAL_ACTIVITY = legalFilings;

    if (inmateProfiles && inmateProfiles.length > 0) {
      translationKey = 'INMATE_PROFILE';

      for (i = 0; i < inmateProfiles.length; i++) {
        inmateProfiles[i].filterFields = {
          kind: 'inmateProfiles',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: null,
          score: inmateProfiles[i].associationScore,
        };
        this.normalizeAdjudication(inmateProfiles[i]);
      }
    }

    Array.prototype.push.apply(this.structuredData.legal.inmateProfiles, inmateProfiles);
    Array.prototype.push.apply(this.byClassification.legal.INMATE_PROFILE, inmateProfiles);
    this.masterStructuredData.INMATE_PROFILE = inmateProfiles;

    if (corporateRecords && corporateRecords.length > 0) {
      translationKey = this.entity.isCompany() ? 'CORPORATE_RECORD' : 'PERSON_CORPORATE_RECORD';

      for (i = 0; i < corporateRecords.length; i++) {
        corporateRecords[i].filterFields = {
          kind: 'corporateRecords',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: null,
          score: corporateRecords[i].associationScore,
        };
        this.normalizeAdjudication(corporateRecords[i]);

        if (corporateRecords[i].additionalCompanyClassifications) {
          StructuredData.applyAdditionalCompanyClassificationsTo(corporateRecords[i]);
        }

        this.profileHitId.applyIdTo(corporateRecords[i]);
      }
    }

    if (this.entity.isCompany()) {
      Array.prototype.push.apply(this.structuredData.corporate.corporateRecords, corporateRecords);
      Array.prototype.push.apply(this.byClassification.corporate.CORPORATE_RECORD, corporateRecords);
      this.masterStructuredData.CORPORATE_RECORD = corporateRecords;
    } else {
      Array.prototype.push.apply(this.structuredData.basicInfo.corporateRecords, corporateRecords);
      Array.prototype.push.apply(this.byClassification.basicInfo.PERSON_CORPORATE_RECORD, corporateRecords);
      this.masterStructuredData.PERSON_CORPORATE_RECORD = corporateRecords;
    }

    if (oshaSafetyInspections && oshaSafetyInspections.length > 0) {
      translationKey = 'OSHA_SAFETY_INSPECTION';

      for (i = 0; i < oshaSafetyInspections.length; i++) {
        oshaSafetyInspections[i].filterFields = {
          kind: 'oshaSafetyInspections',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: oshaSafetyInspections[i].inspectionDate,
          score: oshaSafetyInspections[i].associationScore,
        };
        this.normalizeAdjudication(oshaSafetyInspections[i]);
      }
    }

    if (this.oiqProperties.oshaInRegulatorySection) {
      Array.prototype.push.apply(this.structuredData.regulatory.oshaSafetyInspections, oshaSafetyInspections);
      Array.prototype.push.apply(this.byClassification.regulatory.OSHA_SAFETY_INSPECTION, oshaSafetyInspections);
    } else {
      Array.prototype.push.apply(this.structuredData.noteworthy.oshaSafetyInspections, oshaSafetyInspections);
      Array.prototype.push.apply(this.byClassification.noteworthy.OSHA_SAFETY_INSPECTION, oshaSafetyInspections);
    }
    this.masterStructuredData.OSHA_SAFETY_INSPECTION = oshaSafetyInspections;

    if (productRecalls && productRecalls.length > 0) {
      translationKey = 'PRODUCT_RECALL';

      for (i = 0; i < productRecalls.length; i++) {
        productRecalls[i].filterFields = {
          kind: 'productRecalls',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: productRecalls[i].recallDate,
        };
        productRecalls[i].associationBucket = 'high';
        this.normalizeAdjudication(productRecalls[i]);
      }
    }

    Array.prototype.push.apply(this.structuredData.noteworthy.productRecalls, productRecalls);
    Array.prototype.push.apply(this.byClassification.noteworthy.PRODUCT_RECALL, productRecalls);
    this.masterStructuredData.PRODUCT_RECALL = productRecalls;

    if (professionalLicenses && professionalLicenses.length > 0) {
      translationKey = 'PROFESSIONAL_LICENSE';

      for (i = 0; i < professionalLicenses.length; i++) {
        professionalLicenses[i].filterFields = {
          kind: 'professionalLicenses',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: null,
          score: professionalLicenses[i].associationScore,
        };
        this.normalizeAdjudication(professionalLicenses[i]);
      }
    }

    Array.prototype.push.apply(this.structuredData.basicInfo.professionalLicenses, professionalLicenses);
    Array.prototype.push.apply(this.byClassification.basicInfo.PROFESSIONAL_LICENSE, professionalLicenses);
    this.masterStructuredData.PROFESSIONAL_LICENSE = professionalLicenses;

    extractionStrategy.extract(
      entity,
      'websiteScorecards',
      (websiteScorecard) => {
        const translationKey = websiteScorecard.classificationLabelKey || 'WEBSITE_SCORECARD';

        websiteScorecard.filterFields = {
          kind: 'websiteScorecards',
          labelKey: translationKey,
          label: this.translateLabel(translationKey),
          date: null,
          score: websiteScorecard.associationScore,
        };
        this.normalizeAdjudication(websiteScorecard);
        this.byClassification.adverse[translationKey] = this.byClassification.adverse[translationKey] || [];
        this.byClassification.adverse[translationKey].push(websiteScorecard);
      },
      (websiteScorecards) => {
        Array.prototype.push.apply(this.structuredData.adverse.websiteScorecards, websiteScorecards);
        this.masterStructuredData.WEBSITE_SCORECARD = websiteScorecards;
      }
    );

    this.filterObservers = [];
  }

  // each structuredData passed to callback.
  // optional parameter to limit to a section.
  applyToStructuredData(callback, sectionName) {
    // applies callback to each incident
    const targetStructuredData = this.structuredData[sectionName];

    for (const structuredData in targetStructuredData) {
      if (targetStructuredData.hasOwnProperty(structuredData)) {
        StructuredData.arrayMap(targetStructuredData[structuredData], callback);
      }
    }
  }

  // each summary passed to callback
  // optional parameter to limit to a section.
  applyLabels(callback, sectionName) {
    // applies callback to each summary in each incident
    const targetStructuredData = this.structuredData[sectionName];

    for (const structuredData in targetStructuredData) {
      if (targetStructuredData.hasOwnProperty(structuredData)) {
        StructuredData.arrayMap(targetStructuredData[structuredData], dataUnwrapper);
      }
    }

    function dataUnwrapper(data) {
      callback(data.filterFields);
    }
  }

  // filter incidents
  filter(
    sectionName?,
    startDate?,
    endDate?,
    minAssociationBucket?,
    excludeAdjudicated?,
    escalatedOnly?,
    confirmedOnly?,
    hideConfirmed?,
    hideEscalated?,
    adjudicatedOnly?
  ) {
    if (this.structuredData[sectionName]) {
      const chainedFilter = this.createChainedFilter(
        this.chainableStructuredDataFilter.clear(),
        this.chainableStructuredDataFilter.isBeforeDate(startDate),
        this.chainableStructuredDataFilter.isAfterDate(endDate),
        this.chainableStructuredDataFilter.isBelowMinAssociationBucket(minAssociationBucket),
        this.chainableStructuredDataFilter.isExcludedAdjudication(excludeAdjudicated),
        this.chainableStructuredDataFilter.isExcludeNonEscalated(escalatedOnly),
        this.chainableStructuredDataFilter.isExcludeNonConfirmed(confirmedOnly),
        this.chainableStructuredDataFilter.isHidingConfirmed(hideConfirmed),
        this.chainableStructuredDataFilter.isHidingEscalated(hideEscalated),
        this.chainableStructuredDataFilter.isExcludeNonAdjudicated(adjudicatedOnly)
      );

      this.applyToStructuredData(chainedFilter, sectionName);

      this.notifyObservers();
    }
  }

  // register a post-filter callback
  registerFilterObserver(callback) {
    // callbacks receive the entire list of incidents as the callback parameter
    this.filterObservers.push(callback);
  }

  // notify post-filter callbacks
  notifyObservers() {
    StructuredData.arrayMap(this.filterObservers, function (observer) {
      observer();
    });
  }

  find(sectionId, classificationName) {
    let data = [],
      section;

    if (sectionId === 'references') {
      sectionId = 'other';
    }

    section = this.byClassification[sectionId] || {};

    if (!classificationName) {
      Object.keys(section).forEach(function (classification) {
        Array.prototype.push.apply(data, section[classification]);
      });
    } else {
      data = section[classificationName] || data;
    }
    return data;
  }

  get() {
    return this.masterStructuredData;
  }

  clear() {
    this.filterObservers.length = 0;
    this.masterStructuredData = {
      WATCH_LIST_ENTRY: [],
      REGULATORY_AGENCY_PROFILE: [],
      LEGAL_ACTIVITY: [],
      INMATE_PROFILE: [],
      CORPORATE_RECORD: [],
      PERSON_CORPORATE_RECORD: [],
      OSHA_SAFETY_INSPECTION: [],
      PRODUCT_RECALL: [],
      PROFESSIONAL_LICENSE: [],
      WEBSITE_SCORECARD: [],
    };
    this.structuredData.basicInfo.professionalLicenses.length = 0;
    this.structuredData.basicInfo.corporateRecords.length = 0;
    this.structuredData.corporate.corporateRecords.length = 0;
    this.structuredData.regulatory.watchlists.length = 0;
    this.structuredData.regulatory.regulatoryProfiles.length = 0;
    this.structuredData.regulatory.oshaSafetyInspections.length = 0;
    this.structuredData.legal.legalFilings.length = 0;
    this.structuredData.legal.inmateProfiles.length = 0;
    this.structuredData.noteworthy.oshaSafetyInspections.length = 0;
    this.structuredData.noteworthy.productRecalls.length = 0;
    this.structuredData.adverse.websiteScorecards.length = 0;
    this.structuredData.other = {};
    this.byClassification = null;
  }

  private normalizeAdjudication(data) {
    if (data.adjudication) {
      data.disabled = data.adjudication.adjudicated;
      data.escalated = data.adjudication.escalated;
      if (this.adjudicationFeature.isConfirmActionEnabled()) {
        data.confirmed = data.adjudication.confirmed;
      }
      data.isAutoAdjudicated = data.adjudication.auto;
    }
  }

  private static getEventsFromWatchList(watchList) {
    let events, primaryWatchList;

    if (watchList.watchlists) {
      events = [];

      primaryWatchList = watchList.watchlists.filter(function (watchlist) {
        return watchlist.primaryEntry;
      })[0];

      if (primaryWatchList) {
        Array.prototype.push.apply(events, primaryWatchList.events || []);
      }
    } else {
      events = watchList.events;
    }

    return events;
  }

  private static applyAdditionalCompanyClassificationsTo(regulatoryProfile) {
    const companyClassificationsByCodeSchema = regulatoryProfile.additionalCompanyClassifications.reduce(
      (classificationsByCodeSchema, classifications) => {
        const companyClassifications = classificationsByCodeSchema[classifications.codeSchema] || [];
        companyClassifications.push(classifications);

        classificationsByCodeSchema[classifications.codeSchema] = companyClassifications;
        return classificationsByCodeSchema;
      },
      {}
    );

    regulatoryProfile.companyClassificationsByCodeSchema = companyClassificationsByCodeSchema;
  }

  private static applyAdditionalGovernmentIdentificationsTo(regulatoryProfile) {
    const identification = regulatoryProfile.identification,
      ids = regulatoryProfile.additionalGovernmentIdentifications;

    if (ids.indexOf(identification) === -1) {
      ids.unshift(identification);
    }
  }

  // breaks as soon as one of the callbacks returns true
  private createChainedFilter(...args) {
    const filters = [];

    for (var i = 0; i < args.length; i++) {
      if (args[i]) {
        filters.push(args[i]);
      }
    }

    return function (obj) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i](obj)) {
          obj.filtered = true;
          // stop when a single filter returns true
          return;
        }
      }
    };
  }

  private translateLabel(label) {
    return this.translateService.translate(label);
  }

  // jeebus. until we get some modern javascript tooling, we're stuck
  // with manual implementations of collection operations
  private static arrayMap(array, callback) {
    let i;

    for (i = 0; i < array.length; i++) {
      callback(array[i]);
    }
  }
}
