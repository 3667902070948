<div class="modal" [ngSwitch]="vm.open" *ngIf="vm.open">
  <div *ngSwitchCase="true">
    <div>
      <button type="button" *ngIf="hasCloseBtn" (click)="vm.open = false"></button>
      <div id="errorModalContent">
        <ng-container [ngSwitch]="vm.template">
          <ng-container *ngSwitchCase="'submissionError'">
            <div class="modal-title">Submission Error</div>
            <div class="message" *ngFor="let result of results.result">
              <div>{{result.type}}: <span>{{result.message}}</span></div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'adjudicationError'">
            <div class="modal-title">Adjudication Error</div>

            <div class="message">
              <div>The item you were adjudicating has been updated since the profile was last loaded.</div>
              <div>The profile has been updated with the current adjudication status.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'adjudicationSupportError'">
            <div class="modal-title">Notification Error</div>

            <div class="message">
              <div>We were unable to send a message to request adjudication support.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'adjudicationSupportSuccess'">
            <div class="modal-title">Notification Sent</div>

            <div class="message">
              <div>A message to request adjudication support has been sent.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'crawlConfigurationError'">
            <div class="modal-title">Crawl Configuration Error</div>

            <div class="message">
              <div>The {{ 'PROFILE_TYPE' | translatef }} configuration that was selected cannot be found.</div>
              <div>Please select another configuration.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'websiteMalformedError'">
            <div class="modal-title">Website URL Error</div>

            <div class="message">
              <div>The {{ 'WEBSITE' | translatef }} URL that was entered is invalid.</div>
              <div>Please enter another URL.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'dynamicAdjudicationError'">
            <div class="modal-title">Bulk Adjudication Error</div>

            <div class="message">
              <div>
                One or more items that you were trying to adjudicate have been updated since the profile was last
                loaded.
              </div>
              <div>
                The profile has been updated with the current adjudication status for the items that were being
                adjudicated.
              </div>
              <div>
                Items which had been selected that no longer meet the criteria for the selected action have been
                deselected.
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'dynamicAdjudicationResponseError'">
            <div class="modal-title">Bulk Adjudication Error</div>

            <div class="message">
              <div>An issue was encountered while retrieving the latest adjudication information for this profile.</div>
              <div>Please reload the profile for the latest information.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'dynamicGroupAdjudicationWarning'">
            <div class="modal-title">Bulk Adjudication Warning</div>

            <div class="message">
              <div>
                There are one or more items that are currently excluded from view. Not all items may have been selected
                with this action.
              </div>
              <div>To see all items in this group, please review your filter settings.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'monitorError'">
            <div class="modal-title">Monitor Scheduling Conflict</div>

            <div class="message">
              <div>The profile has already been scheduled in a different user session. Please reload the profile.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'monitorScheduleError'">
            <div class="modal-title">Monitor Scheduling Error</div>

            <div class="message">
              <div>The system was unable to schedule monitoring for the selected profile due to an internal error.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'notifyError'">
            <div class="modal-title">Notify User Error</div>

            <div class="message">
              <div>We were unable to send the message to the intended recipient.</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'profileStatusStaleError'">
            <div class="modal-title">Profile Status Update Error</div>

            <div class="message">
              <p>
                We could not update the profile's status. We've discovered your profile has hits still requiring
                adjudication.
              </p>
              <p>Please reload the profile for the latest information.</p>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'profileStatusUnknownError'">
            <div class="modal-title">Profile Status Update Error</div>

            <div class="message">
              <p>An unknown error has occurred while trying to update the profile's status.</p>
              <p>Please try again later. Contact your administrator if this problem persists.</p>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'refreshInProgressError'">
            <div class="modal-title">Profile Refresh Error</div>

            <div class="message">
              <div>
                Profile refresh is currently running. Please wait until the current refresh is complete, and try the
                action again.
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'refreshOutOfSyncError'">
            <div class="modal-title">Profile Refresh Error</div>

            <div class="message">
              <div>
                A new profile refresh is available. Updating seed data is only available on the latest refresh. To
                update the seed data, please select the most recent refresh.
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'statusUpdateError'">
            <div class="modal-title">Status Update Error</div>

            <div class="message">
              <div>
                The profile for which you were trying to update the status has been updated since it was last loaded.
              </div>
              <div>The profile has been updated with the current status.</div>
            </div>
          </ng-container>
        </ng-container>

        <div class="center clr">
          <button type="button" (click)="close()" class="btn btn-primary">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
