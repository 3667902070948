import { NgModule, Injector } from '@angular/core';
import DataDogRef from './data-dog.ref';
import { DataDogService } from './data-dog.service';

@NgModule({
  providers: [DataDogService, DataDogRef],
})
export class DataDogModule {
  constructor(private injector: Injector) {}

  configure() {
    const dataDog: DataDogService = this.injector.get(DataDogService);
    dataDog.configure();
  }
}
