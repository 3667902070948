import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ddiq-filter-buttons',
  templateUrl: './ddiq-filter-buttons.component.tpl.html',
})
export default class FilterButtons {
  @Output() onClicked = new EventEmitter<any>();
  @Input() isShowEnabled: boolean;
  @Input() isHideEnabled: boolean;

  onClick(type) {
    if (type === 'show') {
      if (!this.isShowEnabled && this.isHideEnabled) {
        this.isHideEnabled = !this.isHideEnabled;
      }
      this.isShowEnabled = !this.isShowEnabled;
    }

    if (type === 'hide') {
      if (!this.isHideEnabled && this.isShowEnabled) {
        this.isShowEnabled = !this.isShowEnabled;
      }
      this.isHideEnabled = !this.isHideEnabled;
    }

    this.onClicked.emit({ show: this.isShowEnabled, hide: this.isHideEnabled });
  }
}
