import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-education-form',
  templateUrl: './ddiq-education-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class EducationFormComponent implements OnInit {
  @Input() educationInstitutionsHolder;
  @Output() educationInstitutionsHolderChange = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {
    if (!this.educationInstitutionsHolder || this.educationInstitutionsHolder.length === 0) {
      this.educationInstitutionsHolder = [{}];
    }
  }

  addEducationInstitution() {
    this.educationInstitutionsHolder.push({});
  }

  removeEducationInstitution(educationInstitutionIndex) {
    this.educationInstitutionsHolder.splice(educationInstitutionIndex, 1);
  }
}
