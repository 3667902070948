<div class="input quarter" [ngClass]="{disabled : oiqTextDisabled}" [ngSwitch]="format">
  <div *ngSwitchCase="'US'">
    <label [ngClass]="{required: isRequired}">{{label}} <span class="format-hint">(MM/DD/YYYY)</span></label
    ><br />
    <input
      class="mmdd"
      type="text"
      [(ngModel)]="model.month"
      autocomplete="off"
      name="month-{{nameAttr}}"
      placeholder="MM"
      [required]="isRequired"
      maxlength="2"
      pattern="[0-9]{0,2}"
      [disabled]="oiqTextDisabled"
      (ngModelChange)="onDateChange(model)" />
    <input
      class="mmdd"
      type="text"
      [(ngModel)]="model.day"
      autocomplete="off"
      name="day-{{nameAttr}}"
      placeholder="DD"
      [required]="isRequired"
      maxlength="2"
      pattern="[0-9]{0,2}"
      [disabled]="oiqTextDisabled"
      (ngModelChange)="onDateChange(model)" />
    <input
      class="yyyy"
      type="text"
      [(ngModel)]="model.year"
      autocomplete="off"
      name="year-{{nameAttr}}"
      placeholder="YYYY"
      [required]="isRequired"
      maxlength="4"
      pattern="[0-9]{4}"
      [disabled]="oiqTextDisabled"
      (ngModelChange)="onDateChange(model)" />
  </div>
  <div *ngSwitchDefault>
    <label [ngClass]="{required: isRequired}">{{label}} <span class="format-hint">(YYYY-MM-DD)</span></label
    ><br />
    <input
      class="yyyy"
      type="text"
      [(ngModel)]="model.year"
      autocomplete="off"
      name="year-{{nameAttr}}"
      placeholder="YYYY"
      [required]="isRequired"
      maxlength="4"
      pattern="[0-9]{4}"
      [disabled]="oiqTextDisabled"
      (ngModelChange)="onDateChange(model)" />
    <input
      class="mmdd"
      type="text"
      [(ngModel)]="model.month"
      autocomplete="off"
      name="month-{{nameAttr}}"
      placeholder="MM"
      [required]="isRequired"
      maxlength="2"
      pattern="[0-9]{0,2}"
      [disabled]="oiqTextDisabled"
      (ngModelChange)="onDateChange(model)" />
    <input
      class="mmdd"
      type="text"
      [(ngModel)]="model.day"
      autocomplete="off"
      name="day-{{nameAttr}}"
      placeholder="DD"
      [required]="isRequired"
      maxlength="2"
      pattern="[0-9]{0,2}"
      [disabled]="oiqTextDisabled"
      (ngModelChange)="onDateChange(model)" />
  </div>

  <div class="date-validation-error">
    <ng-content></ng-content>
  </div>
</div>
