<div id="legal" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="Legal"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!previewingEntity || legalHasContent()"
    [disable]="!legalHasContent()"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <div *ngIf="!isDeltaReport">
      <div id="person_legalSummaries" class="non-breaking">
        <strong class="push-down">Legal Summary</strong>
        <ddiq-source-metadata
          *ngIf="section.data.legalSummaries"
          class="inline top"
          [sourceMetadata]="'legalSummaries'"></ddiq-source-metadata>
        <div [ngSwitch]="section.data.legalSummaries.length > 0">
          <div *ngSwitchCase="true">
            <table>
              <thead>
                <tr>
                  <th class="center"><span translate>Type</span></th>
                  <th class="center"><span translate>Number of Records</span></th>
                  <th class="center"><span translate>Most Recent Record</span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let legalSummary of section.data.legalSummaries | orderBy: 'recordType'">
                  <td>{{ legalSummary.recordType }}</td>
                  <td class="center">
                    <div [ngSwitch]="legalSummary.numberOfRecords != undefined">
                      <div *ngSwitchCase="true">{{ legalSummary.numberOfRecords || '0' }}</div>
                      <div *ngSwitchCase="false">Record Found</div>
                    </div>
                  </td>
                  <td class="center">{{ legalSummary.mostRecentRecordDate | dateFormat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngSwitchCase="false">
            <p class="no-references-found">No legal summary discovered</p>
          </div>
        </div>
      </div>
      <hr class="clr" />
    </div>

    <div *ngFor="let subsection of getSubSections(section.metadata.sectionId)">
      <div [ngSwitch]="subsection">
        <div id="person_legal_webContent" *ngSwitchCase="'incidents'" class="sub-section">
          <strong class="push-down">Web Content</strong>
          <ddiq-incidents-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.contentEventGroups"
            (sortDiscoveries)="sortDiscoveries('content', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-incidents-group>
        </div>
        <div id="person_legalFilings" *ngSwitchCase="'legalFilings'" class="sub-section">
          <div *ngIf="!isDeltaReport">
            <strong class="push-down">Legal Filings</strong>
            <ddiq-legal-filings-group
              [idPrefix]="section.metadata.sectionId"
              [groups]="section.data.legalActivitiesEventGroups"
              (sortDiscoveries)="sortDiscoveries('legalActivities', $event.sortName, $event.eventGroup)"
              [expandAll]="isExpandAllGroups">
            </ddiq-legal-filings-group>
          </div>
        </div>
        <div *ngIf="!isDeltaReport">
          <div id="person_inmateProfiles" *ngSwitchCase="'inmateProfiles'" class="sub-section">
            <strong class="push-down">Inmate Profiles</strong>
            <ddiq-inmate-profiles-group
              [idPrefix]="section.metadata.sectionId"
              [groups]="section.data.inmateProfilesEventGroups"
              (sortDiscoveries)="sortDiscoveries('inmateProfiles', $event.sortName, $event.eventGroup)"
              [expandAll]="isExpandAllGroups">
            </ddiq-inmate-profiles-group>
          </div>
        </div>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
