<div class="input {{size}}">
  <label [ngClass]="{required: oiqFieldRequired}">{{label}}</label>

  <div class="input-container">
    <input
      [name]="name"
      type="text"
      [required]="oiqFieldRequired"
      [pattern]="pattern"
      [disabled]="textDisabled"
      [placeholder]="oiqPlaceHolder"
      [(ngModel)]="model"
      [maxlength]="maxLength"
      (ngModelChange)="onTextChange(model)" />
    <button type="button" *ngIf="buttonEnabled" class="btn btn-primary btn-sm" (click)="buttonClick(model)">
      Import
    </button>
  </div>
</div>
