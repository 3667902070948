import { Injectable } from '@angular/core';
import moment from 'moment';

const format = 'YYYY-MM-DD';
@Injectable({
  providedIn: 'root',
})
export default class ChainableFilterDateUtils {
  normalizeDate(date) {
    let toNormalize = date;

    if (date && typeof date === 'object') {
      toNormalize = [date.year, date.month - 1 || 0, date.day || 1];
    }

    return toNormalize ? moment.utc(toNormalize).format(format) : toNormalize;
  }
}
