import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class ScreeningFeatureService {
  constructor(private oiqProperties: OiqProperties) {}

  isEnabled = () => {
    return this.oiqProperties.isScreenIqEnabled;
  };
}
