import { Injectable } from '@angular/core';

@Injectable()
export default class TabsData {
  counts: { [key: string]: any };
  pendingSearches: number;
  activeTab: number;

  constructor() {
    this.initializeTabsData();
  }

  clear() {
    this.initializeTabsData();
  }

  private initializeTabsData() {
    this.counts = {
      companyCount: 0,
      personCount: 0,
      inProgressCount: 0,
      queuedCount: 0,
    };

    this.pendingSearches = 2;
    this.activeTab = undefined;
  }
}
