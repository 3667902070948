import { Injectable } from '@angular/core';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import DateFormatPipe from '../../oiq/ddiq-filters/date-format.pipe';
import ApiFetchService from '../../oiq/common-services/api-fetch.service';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import RiskAssessmentService from '../risk/risk-assessment.service';
import OiqProperties from '../common-services/oiq-properties.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import UserService from '../user/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Injectable()
export default class SearchService {
  previousQuery: { [key: string]: any } = {};
  registeredSearches: Array<any> = []; // functions: searches created by entity specific search controllers
  setPaginations: Array<any> = []; // functions: set pagination without firing a new search
  searchSettings: { [key: string]: any } = {};
  dateValidationPattern: any = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  datePattern: string = 'yy-mm-dd';
  private routeParams: ParamMap;

  constructor(
    private dateFormat: DateFormatPipe,
    private ApiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private urlGenerator: UrlGenerator,
    private riskAssessmentService: RiskAssessmentService,
    private user: UserService
  ) {
    if (this.oiqProperties.dateFormat === 'US') {
      this.datePattern = 'mm/dd/yy';
    }

    this.searchSettings.scoreEnabled = this.oiqProperties.profileScoreEnabled;
    this.searchSettings.overviewEnabled = this.oiqProperties.project.isInsure;
  }

  go(query, dates, isResetPagination) {
    const INITIAL_SEARCH_PAGE = 1;
    let i,
      validatedSubmission: { [key: string]: any } = {};

    // create query object for query functions
    validatedSubmission.q = query.q || null;
    validatedSubmission.status = query.status || null;
    validatedSubmission.owner = query.owner || null;
    validatedSubmission.id = query.id || null;
    validatedSubmission.reqOrg = query.reqOrg || null;
    validatedSubmission.correlationId = query.correlationId || null;
    validatedSubmission.govId = query.govId || null;
    validatedSubmission.crawlPlanType = query.crawlPlanType || null;
    validatedSubmission.profileCollectionKey = query.profileCollectionKey || null;
    if (dates.after) {
      let convertedDate = new Date(dates.after);
      validatedSubmission.after = jQuery.datepicker.formatDate('yy-mm-dd', convertedDate);
    } else {
      validatedSubmission.after = null;
    }
    if (dates.before) {
      let convertedDate = new Date(dates.before);
      validatedSubmission.before = jQuery.datepicker.formatDate('yy-mm-dd', convertedDate);
    } else {
      validatedSubmission.before = null;
    }
    validatedSubmission.cOrder = query.cOrder;
    validatedSubmission.cDesc = query.cDesc;
    validatedSubmission.pOrder = query.pOrder;
    validatedSubmission.pDesc = query.pDesc;

    // push params to the url
    this.router.navigate([], {
      queryParams: {
        q: validatedSubmission.q,
        status: validatedSubmission.status,
        owner: validatedSubmission.owner,
        id: validatedSubmission.id,
        reqOrg: validatedSubmission.reqOrg,
        correlationId: validatedSubmission.correlationId,
        after: validatedSubmission.after || null,
        before: validatedSubmission.before || null,
        cOrder: validatedSubmission.cOrder,
        cDesc: validatedSubmission.cDesc,
        pOrder: validatedSubmission.pOrder,
        pDesc: validatedSubmission.pDesc || null,
        govId: validatedSubmission.govId,
        crawlPlanType: validatedSubmission.crawlPlanType || null,
        profileCollectionKey: validatedSubmission.profileCollectionKey || null,
      },
      queryParamsHandling: 'merge',
    });

    this.updatePreviousQuery(validatedSubmission);

    if (isResetPagination) {
      // need to reset pagination for all registered searches
      for (i = 0; i < this.setPaginations.length; i++) {
        this.setPaginations[i](INITIAL_SEARCH_PAGE, 'date', true);
      }
    }

    // call registered searches
    for (i = 0; i < this.registeredSearches.length; i++) {
      this.registeredSearches[i].search(validatedSubmission, 0);
    }
  }

  parseUrl() {
    const urlQuery: { [key: string]: any } = {};

    urlQuery.q = this.route.snapshot.queryParams.q || null;
    urlQuery.status = this.route.snapshot.queryParams.status || null;
    urlQuery.owner = this.route.snapshot.queryParams.owner || null;
    urlQuery.id = this.route.snapshot.queryParams.id || null;
    urlQuery.reqOrg = this.route.snapshot.queryParams.reqOrg || null;
    urlQuery.correlationId = this.route.snapshot.queryParams.correlationId || null;
    urlQuery.after = this.route.snapshot.queryParams.after || null;
    urlQuery.before = this.route.snapshot.queryParams.before || null;
    urlQuery.cOrder = this.route.snapshot.queryParams.cOrder;
    urlQuery.cDesc = this.route.snapshot.queryParams.cDesc;
    urlQuery.pOrder = this.route.snapshot.queryParams.pOrder;
    urlQuery.pDesc = this.route.snapshot.queryParams.pDesc;
    urlQuery.govId = this.route.snapshot.queryParams.govId;
    urlQuery.crawlPlanType = this.route.snapshot.queryParams.crawlPlanType;
    urlQuery.profileCollectionKey = this.route.snapshot.queryParams.profileCollectionKey;

    if (urlQuery.after && !urlQuery.after.match(this.dateValidationPattern)) {
      urlQuery.after = null;
    }

    if (urlQuery.before && !urlQuery.before.match(this.dateValidationPattern)) {
      urlQuery.before = null;
    }

    this.updatePreviousQuery(urlQuery);
  }

  private createEntitySearchFunction(entityType, pagination, results) {
    const router = this.router,
      dateFormat = this.dateFormat,
      ApiFetchService = this.ApiFetchService,
      RiskAssessmentService = this.riskAssessmentService,
      User = this.user,
      UrlGenerator = this.urlGenerator,
      ErrorHandler = this.errorHandler,
      overviewEnabled = this.searchSettings.overviewEnabled,
      prefix = entityType === 'company' ? 'c' : 'p',
      callbacks = [],
      hooks = {
        registerCallback: registerCallback,
        search: search,
      };

    this.registeredSearches.push(hooks);

    return hooks;

    // register function(s) to invoke after search results return
    function registerCallback(callback) {
      callbacks.push(callback);
    }

    function search(query, start) {
      const count = results.visible;

      // push companyStart/personStart to the URL
      router.navigate([], {
        queryParams: {
          [prefix + 'Order']: pagination.orderBy,
          [prefix + 'Desc']: pagination.descending || null,
          q: query.q,
          status: query.status,
          owner: query.owner,
          id: query.id,
          reqOrg: query.reqOrg,
          correlationId: query.correlationId,
          after: query.after || null,
          before: query.before || null,
          govId: query.govId || null,
          crawlPlanType: query.crawlPlanType || null,
          profileCollectionKey: query.profileCollectionKey || null,
        },
        queryParamsHandling: 'merge',
      });

      ApiFetchService.findEntity(
        entityType,
        start,
        count,
        query.q,
        query.status,
        query.owner,
        query.id,
        query.reqOrg,
        query.correlationId,
        query.after,
        query.before,
        pagination.orderBy,
        pagination.descending === true ? 'desc' : 'asc',
        query.govId,
        query.crawlPlanType,
        query.profileCollectionKey
      )
        .then(searchSuccess, searchFail)
        .then(function (reports) {
          // after retrieving data, iterate through callbacks
          if (reports !== undefined) {
            for (let i = 0; i < callbacks.length; i++) {
              callbacks[i](reports);
            }
          }
        });

      function searchSuccess(data) {
        results.reports = data.results;
        results.total = data.totalMatchCount;
        results.totalMatchRelation = data.totalMatchRelation;

        results.reports.forEach(function (report) {
          report.oiqOwnerFullName = User.getVisibleUser(report.oiqOwner).fullName;
          report.calculatedRisk = RiskAssessmentService.getRiskValues(report.profileRisk);
        });

        pagination.total = Math.ceil(results.total / results.visible);

        processReports(results.reports);

        return results;
      }

      function processReports(reports) {
        let i, report, reportUrl, pdfUrl;
        function createOption(reportVersion) {
          return {
            label:
              'Version ' +
              reportVersion.versionId +
              ' - ' +
              dateFormat.transform(reportVersion.currentVersionRunDate) +
              (reportVersion.seedDataUpdate ? ' *' : ''),
            versionId: reportVersion.versionId,
            reportUrl: UrlGenerator.generateReport(reportVersion.oiqEntityId, entityType, reportVersion.oiqEntityId),
          };
        }
        for (i = 0; i < reports.length; i++) {
          report = reports[i];

          pdfUrl = UrlGenerator.generatePdf(report.oiqEntityId, entityType, report.oiqEntityId);
          reportUrl = UrlGenerator.generateReport(report.oiqEntityId, entityType, report.oiqEntityId);

          if (overviewEnabled) {
            report.overviewLink = UrlGenerator.generateOverview(report.oiqEntityId);
          }
          report.completedVersions = (report.versions || []).map(createOption);

          report.profileLink = reportUrl;
          report.pdfLink = pdfUrl;
        }
      }

      function searchFail(response) {
        ErrorHandler.error(entityType + ' Search Failed: ', response);
      }
    }
  }

  createCompanySearchFunction(pagination, results) {
    return this.createEntitySearchFunction('company', pagination, results);
  }

  createPersonSearchFunction(pagination, results) {
    return this.createEntitySearchFunction('person', pagination, results);
  }

  updatePreviousQuery(query) {
    this.previousQuery.q = query.q;
    this.previousQuery.status = query.status;
    this.previousQuery.owner = query.owner;
    this.previousQuery.id = query.id;
    this.previousQuery.reqOrg = query.reqOrg;
    this.previousQuery.correlationId = query.correlationId;
    this.previousQuery.after = query.after || null;
    this.previousQuery.before = query.before || null;
    this.previousQuery.cOrder = query.cOrder;
    this.previousQuery.cDesc = query.cDesc;
    this.previousQuery.pOrder = query.pOrder;
    this.previousQuery.pDesc = query.pDesc;
    this.previousQuery.govId = query.govId;
    this.previousQuery.crawlPlanType = query.crawlPlanType;
    this.previousQuery.profileCollectionKey = query.profileCollectionKey;
  }

  addSetPagination(setPaginationFn) {
    this.setPaginations.push(setPaginationFn);
  }

  clearAll() {
    this.registeredSearches = [];
    this.setPaginations = [];
  }
}
