import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ddiq-monitor-hits-entity-name',
  template: `<div class="entity-name-container">
    <span class="entity-icon-container">
      <i class="fa entity-icon">{{ entityIcon }}</i>
      <div class="entity-icon-label">{{ entityType }}</div>
    </span>
    <a class="monitor-seed-data-name entity-name" href="{{ profileLink }}">{{ entityName }} </a>
  </div>`,
})
export default class MonitorHitsEntityName implements OnInit {
  icons: {
    company: string;
    person: string;
  } = {
    company: '\uf19c', //fa-institution,
    person: '\uf007', //fa-user
  };

  entityIcon: string;

  @Input() entityType: string = '';
  @Input() entityName: string;
  @Input() profileLink: string;

  ngOnInit(): void {
    this.entityIcon = this.icons[this.entityType.toLowerCase()];
  }
}
