import { Inject, Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import ProfileStatusAdjudicationGuard from './profile-status-adjudication-guard';
import ProfileStatusFeatureService from './profile-status-feature.service';

@Injectable({
  providedIn: 'root',
})
export default class ProfileStatusService {
  CONFLICT_STATUS = 409;

  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private modalTrackingService: ModalTrackingService,
    private profileStatusFeature: ProfileStatusFeatureService,
    private profileStatusAdjudicationGuard: ProfileStatusAdjudicationGuard
  ) {}

  getAdjudicationMetaData(entityId, entityType) {
    const actions = this.profileStatusFeature.getNormalizedAdjudicationRequiredNames();
    return this.apiFetchService.getAdjudicationMetaData(entityId, entityType, actions);
  }

  validateStatuses(entityType, entityId, profileStatusNames) {
    if (!this.profileStatusFeature.isAdjudicationRequiredEnabled()) {
      return this.makeAllProfileStatusesValid(profileStatusNames);
    } else {
      return this.validateAgainstAdjudications(entityType, entityId, profileStatusNames);
    }
  }

  validateAgainstAdjudications(entityType, entityId, profileStatusNames) {
    return this.getAdjudicationMetaData(entityId, entityType).then((adjudicationReport) => {
      return this.applyValidationsToProfileStatuses(adjudicationReport, profileStatusNames);
    });
  }

  applyValidationsToProfileStatuses(adjudicationReport, profileStatusNames) {
    const { adjudicationActions, confidenceThreshold, adjudicationRequiredStatuses } =
      this.profileStatusFeature.getAdjudicationRequiredAdminConfigs();
    const requiresAdjudication = this.profileStatusAdjudicationGuard.requiresAdjudication(adjudicationReport, {
      adjudicationActions,
      confidenceThreshold,
    });

    return profileStatusNames.map((name) => {
      let isValid = true;
      if (adjudicationRequiredStatuses.indexOf(name) !== -1) {
        isValid = !requiresAdjudication;
      }

      const validation = { name, isValid, reason: null };
      if (!isValid) {
        validation.reason = { adjudicationActions: adjudicationActions.concat(), confidenceThreshold };
      }
      return validation;
    });
  }

  makeAllProfileStatusesValid(profileStatusNames) {
    const validationResults = profileStatusNames.map((name) => {
      return { name, isValid: true };
    });

    return Promise.resolve(validationResults);
  }

  updateStatus(statusPayload) {
    return this.apiFetchService
      .updateStatus(this.entity.getType(), this.entity.getId(), statusPayload)
      .then(this.successSubmit(), this.submitFail());
  }

  successSubmit() {
    return (response) => (this.entity.getData().profileStatus = response.status);
  }

  submitFail() {
    return (response) => {
      if (response.status === this.CONFLICT_STATUS) {
        this.entity.getData().profileStatus = response.data.status;
        this.modalTrackingService.openError('statusUpdateError');
      }
    };
  }
}
