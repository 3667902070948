import { Component, Input, OnInit } from '@angular/core';
import CorporateGraph from '../corporate-graph/corporate-graph.service';
import OwnershipDataExtractionService from './ownership-data-extraction.service';

@Component({
  selector: 'ddiq-corporate-record',
  templateUrl: './ddiq-corporate-record.component.tpl.html',
})
export default class CorporateRecordComponent implements OnInit {
  @Input() record: any;
  @Input() adjudicationType: string;
  @Input() isAdjudicateable: boolean;

  additionalData: any;

  constructor(
    private ownershipDataExtractionService: OwnershipDataExtractionService,
    private corporateGraph: CorporateGraph
  ) {}

  ngOnInit() {
    const ownershipData = this.ownershipDataExtractionService.extractOwners(this.record);

    this.record.directOwners = ownershipData.directOwners;
    this.record.ultimateOwners = ownershipData.ultimateOwners;

    this.additionalData = (this.record.additionalData || []).filter((datum) => datum.label !== 'NON_US_OWNERSHIP');
  }

  isGraphEnabled() {
    return this.corporateGraph.isGraphEnabled();
  }

  hasAdditionalData() {
    const record = this.record;

    return (
      record &&
      (record.companyType ||
        record.companySubtype ||
        record.description ||
        record.website ||
        record.primaryContact ||
        record.primaryContactAddress ||
        record.primaryContactMailingAddress ||
        record.employeeCount ||
        record.parentName ||
        record.parentAddress ||
        (record.companyClassification || {}).code ||
        (record.companyClassification || {}).description ||
        (record.companyClassification || {}).codeSchema ||
        Object.keys(record.companyClassificationsByCodeSchema || {}).length ||
        (record.additionalNames || []).length ||
        (record.additionalAddresses || []).length ||
        (record.phoneNumbers || []).length ||
        (this.additionalData || []).length ||
        (record.historicNames || []).length ||
        (record.officers || []).length ||
        (record.beneficialOwners || []).length ||
        (record.directOwners || []).length ||
        (record.identificationNumbers || []).length)
    );
  }
}
