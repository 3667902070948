import { Injectable } from '@angular/core';
import EventGroupsTemplate from '../event-groups/event-groups-template';
import EventGroupSort from '../event-groups/event-group-sort.service';
import EventGroupsBuilderService from './event-groups-builder.service';
import EventGroupBuilderService from './event-group-builder.service';
import TranslateService from '../common-services/translate.service';
import EventSeverityService from './event-severity.service';
import AntiCollideClassificationPipe from '../ddiq-filters/anti-collide-classification.pipe';
import IncidentsUtils from '../themed-entity/incidents-utils.service';

@Injectable({
  providedIn: 'root',
})
export class WebContentEventGroupsService extends EventGroupsTemplate {
  constructor(
    private eventGroupSort: EventGroupSort,
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private eventGroupBuilderService: EventGroupBuilderService,
    private translateService: TranslateService,
    private eventSeverityService: EventSeverityService,
    private antiCollideClassification: AntiCollideClassificationPipe,
    private incidentsUtils: IncidentsUtils
  ) {
    super('contentEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
  }

  addEventGroupsToSection() {
    return (section) => {
      const incidentsByClassification = section.data.content;

      if (!incidentsByClassification) return;

      const eventGroups = Object.keys(incidentsByClassification).map((classification) => {
        const incidents = incidentsByClassification[classification];
        const antiCollideClassification = this.antiCollideClassification.transform(classification);

        return this.eventGroupBuilderService
          .newBuilder()
          .withDiscoveries(incidents)
          .withSort((discoveries) => this.incidentsUtils.sortIncidents(discoveries))
          .withTitle(this.translateService.translate(antiCollideClassification))
          .add('severity', this.eventSeverityService.fromIncident(incidents[0]))
          .add('classification', classification)
          .add('antiCollideClassification', antiCollideClassification)
          .add('translatedClassification', this.translateService.translate(antiCollideClassification))
          .build();
      });

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService
        .newEventGroupsModel(eventGroups)
        .withSort((eventGroups) => this.eventGroupSort.sort(eventGroups))
        .build();
    };
  }
}
