import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable({
  providedIn: 'root',
})
export default class ProtectedArticlesService {
  constructor(private apiFetchService: ApiFetchService) {}

  getRestResource(url) {
    return this.apiFetchService.getRestResource(url, 'text');
  }

  ping() {
    return this.apiFetchService.ping();
  }
}
