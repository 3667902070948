import { Component, OnInit } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import ApiFetchService from '../common-services/api-fetch.service';
import Diff from '../common-services/diff.service';
import Entity from '../common-services/entity.service';

@Component({
  selector: 'ddiq-diff-header',
  templateUrl: 'ddiq-diff-header.component.tpl.html',
})
export default class DdiqDiffHeaderComponent implements OnInit {
  isDiff;

  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private urlGenerator: UrlGenerator,
    public diff: Diff
  ) {}

  ngOnInit() {
    this.isDiff = this.entity.isDiff();
    if (this.isDiff) {
      this.apiFetchService.summary(this.entity.getType(), this.entity.getDiffId(), false).then((data) => {
        this.diff.baseline = data;
        this.diff.baseline.displayName = this.entity.extractDisplayName(data);
      });

      this.apiFetchService.summary(this.entity.getType(), this.entity.getId(), false).then((data) => {
        this.diff.current = data;
        this.diff.current.displayName = this.entity.extractDisplayName(data);
      });

      this.diff.currentProfileUrl = this.urlGenerator.generateReport(this.entity.getId(), this.entity.getType());
      this.diff.baselineProfileUrl = this.urlGenerator.generateReport(this.entity.getDiffId(), this.entity.getType());
    }
  }
}
