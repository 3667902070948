import { Component, Input } from '@angular/core';
import PreviousAddressesPipe from './previous-addresses.pipe';

@Component({
  selector: 'person-additional-input-data-detail',
  templateUrl: './person-additional-input-data-detail.component.tpl.html',
})
export default class PersonAdditionalInputDataDetailComponent {
  @Input() seedData;

  constructor(private previousAddresses: PreviousAddressesPipe) {}

  hasPreviousAddresses() {
    if (typeof this.seedData !== 'undefined') {
      return !!this.previousAddresses.transform(this.seedData.previousAddresses).length;
    }
  }
}
