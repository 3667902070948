import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import ApiFetchService from '../common-services/api-fetch.service';

@Component({
  selector: 'profile-collection-list',
  templateUrl: './profile-collection-list.component.tpl.html',
})
export default class ProfileCollectionListComponent implements OnInit {
  dropdownList: any[];
  selectedItems: any[];
  dropdownSettings: IDropdownSettings;
  isDisabled: boolean;
  list: any[];

  isRequired: boolean;

  isInvalid: boolean;

  @Input() model: any[];
  @Input() label: string;
  @Input() required: boolean;
  @Output() onListChange = new EventEmitter<any[]>();
  constructor(private ApiFetchService: ApiFetchService, private elementRef: ElementRef) {
    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {};
    this.isDisabled = false;
  }

  ngOnInit() {
    this.isRequired = this.elementRef.nativeElement.getAttribute('required') !== null;
    this.checkIfValid();

    this.getDropDownList().then((data) => {
      this.dropdownList = data.results.map((item) => ({
        profile_collection_key: item.profileCollectionKey,
        profile_collection_name: item.name,
      }));
      this.removeInvalidProfileCollectionsFromModel();
      if (this.dropdownList.length === 1) {
        this.model.length = 0;
        let item = this.dropdownList[0];
        this.selectedItems.push(item);
        this.selectedItems = this.selectedItems.slice();
        this.onItemSelect(item);
        this.isDisabled = true;
      } else if (this.model.length > 0) {
        this.dropdownList.forEach((pc) => {
          if (this.model.includes(pc.profile_collection_key)) {
            this.selectedItems.push({
              profile_collection_key: pc.profile_collection_key,
              profile_collection_name: pc.profile_collection_name,
            });
            this.selectedItems = this.selectedItems.slice();
          }
        });
        this.checkIfValid();
      } else if (this.dropdownList.length > 1) {
        this.ApiFetchService.getDefaultProfileCollection().then((data) => {
          let defaultPC = this.dropdownList.findIndex((pc) => pc.profile_collection_key === data.profileCollectionKey);
          if (defaultPC > -1) {
            let item = this.dropdownList[defaultPC];
            this.selectedItems.push(item);
            this.selectedItems = this.selectedItems.slice();
            this.onItemSelect(item);
          }
        });
      }

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'profile_collection_key',
        textField: 'profile_collection_name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
      };
    });
  }

  removeInvalidProfileCollectionsFromModel() {
    let profileCollectionKeysInDropdown = this.dropdownList.map((item) => item.profile_collection_key);
    for (let i = 0; i < this.model.length; i++) {
      if (!profileCollectionKeysInDropdown.includes(this.model[i])) {
        this.model.splice(i, 1);
        i--;
      }
    }
  }

  getDropDownList() {
    return this.ApiFetchService.getProfileCollections({ currentUserPermissions: 'profile_submit' });
  }

  onChange(changedModel: any) {
    this.onListChange.emit(changedModel);
  }

  checkIfValid() {
    this.isInvalid = this.model.length === 0 && this.isRequired;
  }

  onItemSelect(item: any) {
    this.model.push(item.profile_collection_key);
    this.checkIfValid();
  }

  onSelectAll(items: any) {
    this.model.length = 0;
    items.forEach((i) => this.model.push(i.profile_collection_key));
    this.checkIfValid();
  }

  onItemDeselect(item: any) {
    const index = this.model.indexOf(item.profile_collection_key);
    if (index !== -1) {
      this.model.splice(index, 1);
    }
    this.checkIfValid();
  }

  onDeselectAll(items: any) {
    this.model.length = 0;
    this.checkIfValid();
  }
}
