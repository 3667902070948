import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'arrayDedupe',
})
export default class ArrayDedupePipe implements PipeTransform {
  transform(value: any, ...args): any {
    return (value || []).filter(function (item, index, array) {
      return array.indexOf(item) === index;
    });
  }
}
