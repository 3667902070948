<div class="filtered-messages alert alert-warning" *ngIf="isFiltered">
  <h3 class="filtered-messages-title">Active Filters</h3>

  <div class="filtered-messages-left-column">
    <div class="filtered-Relevance" *ngIf="isRelevanceFiltered">
      <span class="filtered-Relevance-label">Confidence Level:</span>
      <ddiq-association-confidence [level]="level"></ddiq-association-confidence>
      <span [textContent]="level | associationTooltip | translatef"></span>
    </div>

    <div class="filtered-dates" *ngIf="fromDate || toDate">
      <span class="filtered-dates-label">Dates:</span>

      <span class="filtered-fromDate" *ngIf="fromDate" [textContent]="'From ' + (fromDate | dateFormat)"> </span>

      <span class="filtered-toDate" *ngIf="toDate" [textContent]="'To ' + (toDate | dateFormat)"> </span>
    </div>

    <div class="filtered-confirmedContent" *ngIf="confirmedContent">
      <span [textContent]="'Showing Confirmed Content Only'"></span>
    </div>

    <div class="filtered-starredContent" *ngIf="starredContent">
      <span [textContent]="'Showing Starred Content Only'"></span>
    </div>

    <div class="filtered-adjudicated" *ngIf="adjudicated">
      <span [textContent]="'Hiding Adjudicated Content'"></span>
    </div>

    <div class="filtered-hide-confirmed" *ngIf="hideConfirmed">
      <span [textContent]="'Hiding Confirmed Content'"></span>
    </div>

    <div class="filtered-hide-escalated" *ngIf="hideEscalated">
      <span [textContent]="'Hiding Starred Content'"></span>
    </div>

    <div class="filtered-adjudicatedOnly" *ngIf="adjudicatedOnly">
      <span [textContent]="'Showing Adjudicated Content Only'"></span>
    </div>
  </div>

  <div class="filtered-hiddenSections" *ngIf="hiddenSections">
    <h4 class="filtered-hiddenSections-title">Hide Sections:</h4>
    <ul class="filtered-hiddenSections-ul">
      <li *ngFor="let section of sections | filterByImpure: ['metadata.filtered']: true">
        {{section.metadata.navLinkText}}
      </li>
    </ul>
  </div>
</div>
