import ConfigService from './config.service';

export default function (settings) {
  return {
    provide: ConfigService,
    useFactory: () => {
      return new ConfigService(settings);
    },
    deps: [],
  };
}
