import { Injectable } from '@angular/core';
import ChainableFilterDateUtils from './chainable-filter-date-utils.service';
import AssociationBuckets from '../association/association-buckets.service';
import { Constant } from '../common/ddiq-constants';

@Injectable({
  providedIn: 'root',
})
export default class ChainableIncidentFilter {
  buckets;

  constructor(private dateUtils: ChainableFilterDateUtils, private associationBuckets: AssociationBuckets) {
    this.buckets = this.associationBuckets.getBuckets();
  }

  // reset all filters
  clear() {
    return (incident) => {
      incident.filtered = false;
      return false;
    };
  }

  // are all incident dates after date
  isAfterDate(date) {
    if (!date) return;

    date = this.dateUtils.normalizeDate(date);

    return (incident) => {
      let firstDate = this.dateUtils.normalizeDate(incident.firstReportedDate),
        lastDate = this.dateUtils.normalizeDate(incident.lastReportedDate);

      if (firstDate && firstDate > date) return true;
      if (firstDate) return false;
      if (lastDate && lastDate > date) return true;
      return false;
    };
  }

  // are all incident dates before date
  isBeforeDate(date) {
    if (!date) return;

    date = this.dateUtils.normalizeDate(date);

    return (incident) => {
      let firstDate = this.dateUtils.normalizeDate(incident.firstReportedDate),
        lastDate = this.dateUtils.normalizeDate(incident.lastReportedDate);

      if (lastDate && lastDate < date) return true;
      if (lastDate) return false;
      if (firstDate && firstDate < date) return true;
      return false;
    };
  }

  isBelowSummarizedAssociationBucket(minScore) {
    if (minScore === undefined) return;
    return (incident) => {
      if (typeof this.buckets[incident.summarizedAssociationBucket] === 'undefined') return true;
      return this.buckets[incident.summarizedAssociationBucket] < minScore;
    };
  }

  isExcludedAdjudication(excludeAdjudicated) {
    if (!excludeAdjudicated) {
      return;
    }

    return (incident) => {
      const adjudication = this.getAdjudication(incident);
      return excludeAdjudicated && !!(adjudication.adjudicated || adjudication.disabled);
    };
  }

  isExcludeNonEscalated(escalatedOnly) {
    if (!escalatedOnly) {
      return;
    }

    return (incident) => {
      const adjudication = this.getAdjudication(incident);
      return escalatedOnly && !adjudication.escalated;
    };
  }

  isExcludeNonConfirmed(confirmedOnly) {
    if (!confirmedOnly) {
      return;
    }

    return (incident) => {
      const adjudication = this.getAdjudication(incident);
      return confirmedOnly && !adjudication.confirmed;
    };
  }

  isExcludeNonAdjudicated(adjudicatedOnly) {
    if (!adjudicatedOnly) {
      return;
    }

    return (incident) => {
      const adjudication = this.getAdjudication(incident);
      return adjudicatedOnly && !adjudication.adjudicated && !adjudication.disabled;
    };
  }

  isHidingConfirmed(hideConfirmed) {
    if (!hideConfirmed) {
      return;
    }

    return (incident) => {
      const adjudication = this.getAdjudication(incident);
      return !!adjudication.confirmed;
    };
  }

  isHidingEscalated(hideEscalated) {
    if (!hideEscalated) {
      return;
    }

    return (incident) => {
      const adjudication = this.getAdjudication(incident);
      return !!adjudication.escalated;
    };
  }

  getAdjudication(incident) {
    if (Constant.WEB_CONTENT_TYPE !== 'classification') {
      return incident.adjudication || {};
    } else {
      return incident;
    }
  }
}
