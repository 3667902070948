import { Injectable } from '@angular/core';
import EventGroupFilterService from '../event-groups/event-group-filter-service.service';

@Injectable({
  providedIn: 'root',
})
export default class EventGroupBuilderService {
  constructor(private eventGroupFilterService: EventGroupFilterService) {}

  newBuilder() {
    let eventGroup = {
      title: '',
      discoveries: [],
      hasDiscoveries: false,
      hasMoreEntries: false,
      isAllFiltered: false,
      unFilteredCount: 0,
      activeSortButtons: '',
    };

    let discoverySort = (...args) => {
      /* default noop */
    };

    return {
      from(sourceEventGroup) {
        eventGroup = Object.assign({}, sourceEventGroup);
        eventGroup.discoveries = [...sourceEventGroup.discoveries];
        return this;
      },
      withDiscoveries(discoveries) {
        eventGroup.discoveries = [...discoveries];
        return this;
      },
      withTitle(title) {
        eventGroup.title = title;
        return this;
      },
      withSort(sort) {
        if (sort) {
          discoverySort = sort;
        }
        return this;
      },
      add(name, value) {
        eventGroup[name] = value;
        return this;
      },
      build: () => {
        const discoveries = eventGroup.discoveries;
        discoverySort(discoveries);

        const { filteredOutCount, filtered } = this.eventGroupFilterService.getFilteredInfo(discoveries);
        const moreEntriesCount = discoveries.length - 1;

        eventGroup.unFilteredCount = filtered.length;
        eventGroup.hasDiscoveries = discoveries.length > filteredOutCount;
        eventGroup.hasMoreEntries = moreEntriesCount >= 1 && filteredOutCount < moreEntriesCount;
        eventGroup.isAllFiltered = !!discoveries.length && discoveries.length === filteredOutCount;

        return eventGroup;
      },
    };
  }
}
