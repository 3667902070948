<header>
  <h1>Version Information</h1>
  <br class="clr" />
</header>

<section class="fs" *ngIf="oiqVersionInfo">
  <div>
    <table class="data-table">
      <tbody>
        <tr>
          <td class="no-pad">Product:</td>
          <td class="no-pad">{{ oiqVersionInfo.product }}</td>
        </tr>
        <tr>
          <td class="no-pad">Client:</td>
          <td class="no-pad">{{ oiqVersionInfo.client }}</td>
        </tr>
        <tr>
          <td class="no-pad">Install Type:</td>
          <td class="no-pad">{{ oiqVersionInfo.installType }}</td>
        </tr>
        <tr>
          <td class="no-pad">Server Version:</td>
          <td class="no-pad">{{ oiqVersionInfo.version }}</td>
        </tr>
        <tr>
          <td class="no-pad">Server Branch:</td>
          <td class="no-pad">{{ oiqVersionInfo.branch }}</td>
        </tr>
        <tr>
          <td class="no-pad">UI Version:</td>
          <td class="no-pad">{{ oiqVersionInfo.ui }}</td>
        </tr>
        <tr>
          <td class="no-pad">NLP Version:</td>
          <td class="no-pad">{{ oiqVersionInfo.nlp }}</td>
        </tr>
        <tr>
          <td class="no-pad">Build Number:</td>
          <td class="no-pad">{{ oiqVersionInfo.buildNumber }}</td>
        </tr>
        <tr>
          <td class="no-pad">Build Revision:</td>
          <td class="no-pad">{{ oiqVersionInfo.buildRevision }}</td>
        </tr>
        <tr>
          <td class="no-pad">Build Date:</td>
          <td class="no-pad">{{ oiqVersionInfo.buildDateTimeString }}</td>
        </tr>
        <tr>
          <td class="no-pad">Deploy Date:</td>
          <td class="no-pad">{{ oiqVersionInfo.deployDateTimeString }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <br class="clr" />
</section>
