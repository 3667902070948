<p *ngIf="!isQueryEmpty" class="search-results">
  Your search <span *ngIf="lastQueryString">for <span>"{{lastQueryString}}"</span></span> has {{results.total}} results
  on {{pagination.total}} pages
</p>

<table class="results-table">
  <tr>
    <th *ngIf="profileScoreEnabled" class="profile-score" (click)="changeSorting('risk')">
      <ddiq-sortable-column [sort]="pagination" column="score"></ddiq-sortable-column>
    </th>
    <th (click)="changeSorting('name')">
      <ddiq-sortable-column column="name" [sort]="pagination">Profile Name</ddiq-sortable-column>
    </th>
    <th class="search-medium" (click)="changeSorting('submission')">
      <ddiq-sortable-column column="submission" [sort]="pagination"
        >{{ 'REFERENCE_ID' | translatef }}</ddiq-sortable-column
      >
    </th>
    <th class="search-narrow" (click)="changeSorting('profileStatus')" *ngIf="canViewStatus">
      <ddiq-sortable-column column="profileStatus" [sort]="pagination">Status</ddiq-sortable-column>
    </th>
    <th class="search-narrow" (click)="changeSorting('date')">
      <ddiq-sortable-column column="date" [sort]="pagination">Date</ddiq-sortable-column>
    </th>
    <th class="search-narrow" *ngIf="isProfileCollectionEnabled"><div>Collections</div></th>
    <th class="search-narrow"><div>Profile Type</div></th>
    <th class="search-narrow" (click)="changeSorting('owner')">
      <ddiq-sortable-column column="owner" [sort]="pagination">Owner</ddiq-sortable-column>
    </th>
    <th class="search-narrow" (click)="changeSorting('reqOrg')" *ngIf="canSearchReqOrg">
      <ddiq-sortable-column column="reqOrg" [sort]="pagination">{{ 'REQ_ORG' | translatef }}</ddiq-sortable-column>
    </th>
    <th class="search-narrow" (click)="changeSorting('correlationId')" *ngIf="searchCorrelationId">
      <ddiq-sortable-column column="correlationId" [sort]="pagination"
        >{{ 'CORRELATION_ID' | translatef }}</ddiq-sortable-column
      >
    </th>
    <th class="search-narrow" *ngIf="isGovIdSearchEnabled"><div>Identification</div></th>
  </tr>

  <tr *ngFor="let company of results.reports">
    <td *ngIf="profileScoreEnabled">
      <ddiq-risk-review class="tool-tip-right" [risk]="company.calculatedRisk"></ddiq-risk-review>
    </td>

    <td>
      <span *ngIf="company.viewDetailsAllowed"><a href="{{ company.profileLink }}">{{ company.name }}</a></span>
      <span *ngIf="!company.viewDetailsAllowed">{{ company.name }}</span>
      <div *ngIf="profileRefreshEnabled && company.completedVersions && company.completedVersions.length">
        <label for="profile-versions">Completed Refresh Runs:</label>
        <select id="profile-versions" onchange="javascript:location.href = this.value;">
          <option *ngFor="let v of company.completedVersions" value="{{v.reportUrl}}">{{v.label}}</option>

          <option></option>
        </select>
      </div>
      <span *ngIf="company.hidden"><i class="fa fa-user-secret fa-lg" title="Hidden"></i></span>
    </td>

    <td>{{ company.submissionId }}</td>
    <td *ngIf="canViewStatus">{{ company.profileStatus }}</td>
    <td>{{ company.oiqCreatedDate | dateFormat }}</td>
    <td *ngIf="isProfileCollectionEnabled">
      <ddiq-limited-list class="collection-box" [list]="company.collections" [limit]="3" [isOpen]="false">
        <ng-template #parentTemplate let-limitedList>
          <span class="badge" *ngFor="let collection of limitedList">{{collection.name}}</span>
        </ng-template>
      </ddiq-limited-list>
    </td>
    <td>{{ company.crawlPlanDescription }}</td>

    <td>
      <a *ngIf="isInGroup" (click)="openDelegationModalFn(company)">{{ company.oiqOwnerFullName }}</a>
      <span *ngIf="!isInGroup">{{ company.oiqOwnerFullName }}</span>
    </td>

    <td *ngIf="canSearchReqOrg">{{ company.reqOrg }}</td>
    <td *ngIf="searchCorrelationId">{{ company.correlationId }}</td>

    <td class="profile-library-id" *ngIf="isGovIdSearchEnabled">
      <ddiq-limited-list [list]="company.identificationNumbers" [limit]="1">
        <ng-template #parentTemplate let-limitedList>
          <ul>
            <li *ngFor="let id of limitedList">{{id.identificationType}}: {{id.number}}</li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </td>
  </tr>
</table>

<exg-pagination
  [numPages]="pagination.total"
  [currentPage]="pagination.current"
  [paginationBarLength]="pagination.visible"
  [tableTotal]="results.total"
  (onSelectPage)="onSelectPage($event.page)"
  totalLabel="profiles">
</exg-pagination>
