<header>
  <h1>{{ updateSeedData && 'Refresh Person Profile' || 'Build Person Profile'}}</h1>
</header>
<section class="build">
  <form novalidate (ngSubmit)="submit(buildPersonModal)" #buildPerson="ngForm">
    <ddiq-text
      name="personName"
      id="person-name-input"
      [label]="'Full Name' | translatef"
      [size]="externalReferenceCodeEnabled ? 'half' : 'three-quarters'"
      [(model)]="buildParams.profiles[0].personName"
      [textDisabled]="updateSeedData"
      required="true"></ddiq-text>

    <ddiq-text
      name="submissionId"
      id="person-reference-id-input"
      [label]="'REFERENCE_ID' | translatef"
      size="quarter"
      [(model)]="buildParams.submissionId"
      [oiqFieldRequired]="submissionIdFieldRequired">
    </ddiq-text>

    <div *ngIf="externalReferenceCodeEnabled">
      <ddiq-text
        name="externalReferenceCode"
        id="person-external-reference-code-input"
        size="quarter"
        [label]="'EXTERNAL_REFERENCE_CODE' | translatef"
        [(model)]="buildParams.profiles[0].externalReferenceCode"
        [oiqFieldRequired]="externalReferenceCodeRequired"></ddiq-text>
    </div>

    <ddiq-dup
      name="alternativeNamesHolder"
      id="know-aliases-input"
      size="half"
      [label]="'Known Aliases / Other Names'"
      [(model)]="buildParams.profiles[0].alternativeNamesHolder"
      key="name"
      [excludesSingleWords]="true"></ddiq-dup>

    <ddiq-fuzzy-date
      id="birth-date-input"
      size="quarter"
      [label]="'Birth Date' | translatef"
      [(model)]="buildParams.profiles[0].birthdate"
      [format]="dateFormat">
      <p
        class="lookback-error"
        *ngIf="buildParams.profiles[0].birthdate && !isValidBirthDate(buildParams.profiles[0].birthdate)">
        Invalid Date of Birth.
      </p>
    </ddiq-fuzzy-date>

    <ddiq-text
      name="birthdate"
      *ngIf="!isValidEnoughDate(buildParams.profiles[0].birthdate)"
      id="person-age-input"
      [label]="'Age' | translatef"
      size="eighth"
      [(model)]="buildParams.profiles[0].age"></ddiq-text>
    <div *ngIf="isValidEnoughDate(buildParams.profiles[0].birthdate)" class="input eighth">
      <label>Age</label>
      <div>{{calculateAge(buildParams.profiles[0].birthdate)}}</div>
    </div>
    <ddiq-select
      name="sex"
      id="person-sex-select"
      [label]="'Sex' | translatef"
      size="eighth"
      [(model)]="buildParams.profiles[0].sex"
      [options]="sexOptions"
      [prompt]="'Select one'| translatef"
      [oiqFieldRequired]="sexFieldRequired">
    </ddiq-select>

    <div class="input half" *ngIf="isMrzEnabled">
      <label>MRZ</label>
      <textarea class="textarea" rows="3" name="mrz" [(ngModel)]="buildParams.profiles[0].mrz" #mrz="ngModel">
      </textarea>
    </div>

    <br class="clr" />

    <div class="clearfix">
      <div
        id="submission-type-input"
        class="build-form-submission-type"
        [ngClass]="{half: crawlPlans.length, full: !crawlPlans.length}"
        *ngIf="globalModeEnabled">
        <span class="input">
          <label>Submission Type</label>
        </span>
        <div class="submission-type-radio-btns">
          <div class="radio" *ngFor="let personType of personTypes">
            <label
              ><input
                type="radio"
                [(ngModel)]="buildParams.profiles[0].personType"
                [value]="personType.value"
                name="personType" />{{personType.label}}</label
            >
          </div>
        </div>
      </div>
      <div
        *ngIf="languagesEnabled"
        [ngClass]="{quarter: (crawlPlans.length > 1) && globalModeEnabled, half: (!(crawlPlans.length > 1) && globalModeEnabled) || (!globalModeEnabled && (crawlPlans.length > 1)), full: !(crawlPlans.length > 1) && !globalModeEnabled}">
        <ddiq-select-dup
          name="requestedLanguages"
          id="languages-select"
          [size]="'full'"
          [label]="'Languages'"
          [(model)]="buildParams.profiles[0].requestedLanguages"
          [options]="languages"
          required></ddiq-select-dup>
      </div>
      <div
        id="submission-crawlplan-input"
        class="build-form-crawlplan-type input"
        [ngClass]="{quarter: languagesEnabled && globalModeEnabled, half: (!globalModeEnabled && languagesEnabled) || (!languagesEnabled && globalModeEnabled), full: !globalModeEnabled && !languagesEnabled, disabled: updateSeedData}"
        *ngIf="crawlPlans.length > 1">
        <ddiq-select
          name="crawlPlanType"
          [label]="'PROFILE_TYPE'| translatef"
          [(model)]="buildParams.profiles[0].crawlPlanType"
          [options]="crawlPlans"
          value="id"
          displayValue="name"
          [prompt]="crawlPlanPreselected ? '' : 'Choose profile type' | translatef"
          [oiqFieldRequired]="true"
          [oiqTextDisabled]="updateSeedData">
        </ddiq-select>
      </div>

      <!--To keep the collection dropdown just below the profile type -->
      <div
        id="blank-space-input"
        class="input half"
        *ngIf="profileCollectionEnabled && profileCollections.length > 1 && (globalModeEnabled || languagesEnabled)">
        <label>&nbsp;</label>
      </div>
      <div
        id="submission-collection-input"
        class="build-form-collection-type input"
        *ngIf="profileCollectionEnabled && profileCollections.length > 1"
        [ngClass]="{ half: (globalModeEnabled || languagesEnabled), full: !globalModeEnabled && !languagesEnabled}">
        <profile-collection-list
          [label]="'Collection'"
          [model]="buildParams.profiles[0].profileCollectionKeys"
          (onListChange)="validateProfileCollection($event)"
          required></profile-collection-list>
      </div>
      <div
        id="submission-lookback-input"
        class="build-form-lookback sixth input"
        *ngIf="lookbackEnabled && !isValidLookback(buildParams.profiles[0].lookbackConfig.lookbackDate)">
        <span class="input">
          <label>Lookback Timeframe</label>
        </span>
        <input
          id="lookback-config-value"
          type="text"
          [(ngModel)]="buildParams.profiles[0].lookbackConfig.value"
          name="lookbackConfigValue"
          [ngClass]="{required: !!buildParams.profiles[0].lookbackConfig.timeunit}"
          [required]="!!buildParams.profiles[0].lookbackConfig.timeunit"
          #lookbackConfigValue="ngModel" />
        <select
          id="lookback-config-timeunits"
          name="lookbackTimeunits"
          [(ngModel)]="buildParams.profiles[0].lookbackConfig.timeunit"
          [ngClass]="{required: !!buildParams.profiles[0].lookbackConfig.value}"
          [required]="!!buildParams.profiles[0].lookbackConfig.value"
          #timeunit="ngModel">
          <option *ngFor="let timeunit of lookbackTimeunits" [value]="timeunit">{{timeunit | translatef}}</option>
        </select>
      </div>
      <div
        id="submission-lookback-hide-time"
        class="build-form-lookback sixth input"
        *ngIf="lookbackEnabled && isValidLookback(buildParams.profiles[0].lookbackConfig.lookbackDate)">
        <span class="input">
          <label>Lookback Timeframe</label>
        </span>
      </div>
      <div *ngIf="lookbackEnabled">
        <ddiq-fuzzy-date
          id="lookback-date"
          size="sixth"
          [label]="'Lookback Date' | translatef"
          [(model)]="buildParams.profiles[0].lookbackConfig.lookbackDate"
          [format]="dateFormat"></ddiq-fuzzy-date>
      </div>
      <div
        class="lookback-error three-quarters"
        *ngIf="lookbackEnabled && buildParams.profiles[0].lookbackConfig.lookbackDate.year && !isValidLookback(buildParams.profiles[0].lookbackConfig.lookbackDate)">
        Invalid lookback date {{buildParams.profiles[0].lookbackConfig.lookbackDate | dateFormat}}. Please select a date
        after 1970. If run as is, the date will default to a system default setting.
      </div>
      <div class="half clr">
        <ddiq-select-dup
          name="citizenships"
          id="person-citizenship-select"
          *ngIf="!buildParams.profiles[0].personType"
          [size]="'full'"
          [label]="'Citizenships'"
          [(model)]="buildParams.profiles[0].citizenships"
          key="country"
          [options]="countries"
          [prompt]="'Choose country' | translatef"></ddiq-select-dup>
      </div>
      <div class="half">
        <ddiq-select-dup
          name="countriesOfBusiness"
          id="person-country-of-do-select"
          *ngIf="!buildParams.profiles[0].personType"
          [size]="'full'"
          [label]="'Countries of Doing Business'"
          [(model)]="buildParams.profiles[0].countriesOfBusiness"
          key="country"
          [options]="countries"
          [prompt]="'Choose country' | translatef"></ddiq-select-dup>
      </div>
    </div>

    <ddiq-text
      name="streetAddress"
      id="person-street-address-input"
      [label]="'Street Address' | translatef"
      size="half"
      [(model)]="buildParams.profiles[0].currentAddress.streetAddress"></ddiq-text>
    <!-- SS: simplifying assumption: we only care about building names in places we care about district (e.g. the UK) -->
    <div *ngIf="districtEnabled">
      <ddiq-text
        name="buildingName"
        id="person-district-building-name-input"
        [label]="'Building Name' | translatef"
        size="quarter"
        [(model)]="buildParams.profiles[0].currentAddress.buildingName"></ddiq-text>
      <ddiq-text
        name="district"
        id="person-district-input"
        [label]="'District' | translatef"
        size="quarter"
        [(model)]="buildParams.profiles[0].currentAddress.district"></ddiq-text>
    </div>
    <br class="clr" />

    <ddiq-text
      name="city"
      id="person-city-town-input"
      [label]="'City/Town' | translatef"
      size="quarter"
      [(model)]="buildParams.profiles[0].currentAddress.city"></ddiq-text>
    <ddiq-text
      name="stateOrProvince"
      id="person-state-province-region-input"
      [label]="'State/Province/Region' | translatef"
      size="quarter"
      [(model)]="buildParams.profiles[0].currentAddress.stateOrProvince"></ddiq-text>
    <ddiq-text
      name="zipOrPostalCode"
      id="person-zip-postal-code-input"
      [label]="'ZIP/Postal Code' | translatef"
      size="quarter"
      [(model)]="buildParams.profiles[0].currentAddress.zipOrPostalCode"></ddiq-text>
    <ddiq-select
      name="country"
      id="person-country-select"
      *ngIf="buildParams.profiles[0].personType !== 'GLOBAL'"
      [label]="'Country' | translatef"
      size="quarter"
      [(model)]="buildParams.profiles[0].currentAddress.country"
      [options]="countries"
      [prompt]="'Choose country' | translatef"
      [oiqFieldRequired]="countryFieldRequired">
    </ddiq-select>
    <ddiq-select
      name="country"
      id="person-global-country-select"
      *ngIf="buildParams.profiles[0].personType === 'GLOBAL'"
      [label]="'Country'| translatef"
      size="quarter"
      [(model)]="buildParams.profiles[0].currentAddress.country"
      [options]="countries"
      [prompt]="'Choose country' | translatef">
    </ddiq-select>
    <br class="clr" />

    <!-- MDG: Why is this here?  It is always hidden -->
    <ddiq-text
      name="additionalContentAssociationData"
      *ngIf="false"
      [label]="'Other Attribute' | translatef"
      size="half"
      [(model)]="buildParams.profiles[0].additionalContentAssociationData"
      max-length="30"></ddiq-text>
    <div *ngIf="false && extendedSubmissionFields">
      <ddiq-text
        name="nationality"
        [label]="'Nationality' | translatef"
        size="quarter"
        [(model)]="buildParams.profiles[0].nationality"></ddiq-text>
    </div>
    <br class="clr" />

    <div *ngIf="extendedSubmissionFields">
      <ddiq-text
        name="birthplace-city"
        id="person-city-of-birth-input"
        size="third"
        [label]="'City of Birth' | translatef"
        [(model)]="buildParams.profiles[0].birthplace.city"></ddiq-text>
      <ddiq-text
        name="birthplace-stateOrProvince"
        id="person-state-of-birth-input"
        size="third"
        [label]="'State/Province/Region of Birth' | translatef"
        [(model)]="buildParams.profiles[0].birthplace.stateOrProvince"></ddiq-text>
      <ddiq-select
        name="birthplace-country"
        id="person-country-of-birth-select"
        size="quarter"
        [label]="'Country of Birth/Nationality' | translatef"
        [(model)]="buildParams.profiles[0].birthplace.country"
        [options]="countries"
        [prompt]="'Choose country' | translatef">
      </ddiq-select>
    </div>
    <br class="clr" />

    <ddiq-accordion-top id="person-add-input-toggle" label="Additional Input" toggle-function="toggleSection" open>
      <ddiq-accordion id="current-employment" label="Current Employment" open>
        <ddiq-text
          name="companyName"
          id="current-employer-input"
          [label]="'Current Employer' | translatef"
          size="half"
          [(model)]="buildParams.profiles[0].currentPosition.companyName"
          [oiqFieldRequired]="isInvalidCurrentEmployer(buildParams.profiles[0].currentPosition)"></ddiq-text>
        <ddiq-text
          name="jobTitle"
          id="current-job-title-input"
          [label]="'Current Job Title' | translatef"
          size="half"
          [(model)]="buildParams.profiles[0].currentPosition.jobTitle"></ddiq-text>
        <br class="clr" />
      </ddiq-accordion>

      <div class="form-group">
        <ddiq-accordion
          id="related-companies"
          label="Related Companies"
          [hasData]="hasCompanies(buildParams.profiles[0].companies)">
          <ddiq-person-related-companies-form [(companies)]="buildParams.profiles[0].companies" [countries]="countries">
          </ddiq-person-related-companies-form>
        </ddiq-accordion>
      </div>
      <div class="form-group">
        <ddiq-accordion id="education-input" label="Education" [hasData]="hasEducation()">
          <ddiq-education-form [educationInstitutionsHolder]="buildParams.profiles[0].educationInstitutionsHolder">
          </ddiq-education-form>
        </ddiq-accordion>
      </div>

      <div class="form-group">
        <ddiq-accordion id="person-email-addresses-input" label="Email Addresses" [hasData]="hasEmail()">
          <ddiq-email-address-form [emailAddresses]="buildParams.profiles[0].emailAddresses"> </ddiq-email-address-form>
        </ddiq-accordion>
      </div>

      <div class="form-group">
        <ddiq-accordion id="person-phone-number-input" label="Phone Numbers" [hasData]="hasPhoneNumbers()">
          <ddiq-phone-number-form [phoneNumbers]="buildParams.profiles[0].phoneNumbers"></ddiq-phone-number-form>
        </ddiq-accordion>
      </div>

      <div class="form-group">
        <ddiq-accordion
          id="person-previous-addresses-input"
          label="Previous Addresses"
          [hasData]="hasPreviousAddresses()">
          <ddiq-previous-address-form
            [previousAddresses]="buildParams.profiles[0].previousAddresses"
            [countries]="countries"
            [districtEnabled]="districtEnabled">
          </ddiq-previous-address-form>
        </ddiq-accordion>
      </div>

      <div class="form-group">
        <ddiq-accordion id="person-known-associates-input" label="Known Associates" [hasData]="hasKnownAssociates()">
          <ddiq-known-associate-form [relatedPeople]="buildParams.profiles[0].people"> </ddiq-known-associate-form>
        </ddiq-accordion>
      </div>
    </ddiq-accordion-top>

    <div class="block center-block">
      <button
        id="submitCompany"
        type="submit"
        class="btn btn-primary btn-block"
        [disabled]="!buildPerson.form.valid || isProfileCollectionInvalid"
        *ngIf="canSubmitProfile"
        [textContent]="updateSeedData ?  'Refresh Profile' : 'Build Profile' | translatef"
        (keyup.enter)="buildPerson.form.valid && !isProfileCollectionInvalid &&
        User.canSubmitProfile() && submit(buildPersonModal)"></button>
    </div>
    <ddiq-modal
      id="build-profile-permission-missing"
      class="ddiq-modal small-modal"
      [trigger]="'buildProfilePermissionMissing'">
      <ddiq-profile-collection-missing-permission-for-submission></ddiq-profile-collection-missing-permission-for-submission>
    </ddiq-modal>
    <div *ngIf="buildPersonModal.open">
      <ddiq-modal
        class="ddiq-modal profile-build-modal"
        [trigger]="buildPersonModal"
        [ngClass]="{ 'small-modal' : !didYouMean.on}"
        [focus]="'buildProfile'">
        <div
          class="build-modal"
          [ngSwitch]="updateSeedData ? false : existingProfiles.isOn || didYouMean.isOn || nameSuggestion.isOn">
          <div *ngSwitchCase="false" style="height: 100%">
            <ddiq-confirm-modal [toggleConfirm]="confirm.toggle">
              <div class="modal-dialog-content">
                <div class="modal-body">
                  <h3>Confirm Submission</h3>
                  <strong>You Entered:</strong>
                  <div class="no-suggestion">
                    <div>
                      <strong>{{ buildParams.profiles[0].personName }}</strong>
                    </div>
                    <div *ngIf="buildParams.profiles[0].birthdate.year || buildParams.profiles[0].age">
                      <span *ngIf="buildParams.profiles[0].birthdate.year"
                        ><b>Date of Birth:</b> {{ buildParams.profiles[0].birthdate | dateFormat }}<br
                      /></span>
                      <span *ngIf="buildParams.profiles[0].age"><b>Age:</b> {{ buildParams.profiles[0].age }}</span>
                    </div>
                    <div *ngIf="buildParams.profiles[0].currentAddress | address" class="pushdown">
                      <b>Address:</b> {{ buildParams.profiles[0].currentAddress | address }}
                    </div>
                    <div
                      *ngIf="buildParams.profiles[0].currentPosition.jobTitle || buildParams.profiles[0].currentPosition.companyName"
                      class="pushdown">
                      <b>Employment:</b><br />
                      {{ buildParams.profiles[0].currentPosition.jobTitle }}
                      <span
                        *ngIf="buildParams.profiles[0].currentPosition.jobTitle && buildParams.profiles[0].currentPosition.companyName">
                        at
                      </span>
                      {{ buildParams.profiles[0].currentPosition.companyName }}
                    </div>
                    <div *ngIf="hasCompanies(buildParams.profiles[0].companies)" class="pushdown">
                      <b>Related Companies:</b>
                      <div *ngFor="let company of buildParams.profiles[0].companies">
                        <div *ngIf="company.companyName">
                          <span *ngIf="company.relationshipType">{{company.relationshipType}} with</span>
                          {{company.companyName}}
                        </div>
                        <div *ngIf="company.address | address">{{company.address | address}}</div>
                      </div>
                    </div>
                    <div
                      *ngIf="buildParams.profiles[0].previousAddresses.length && PU.hasNonEmptyValue(buildParams.profiles[0].previousAddresses, 'address.country')"
                      class="pushdown">
                      <b>Previous Addresses:</b><br />
                      <div *ngFor="let previousAddress of buildParams.profiles[0].previousAddresses">
                        <span *ngIf="PU.isAddressFilled(previousAddress.address)"
                          >{{ previousAddress.address | address }}</span
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="buildParams.profiles[0].alternativeNamesHolder.length && PU.hasNonEmptyValue(buildParams.profiles[0].alternativeNamesHolder, 'name')"
                      class="pushdown">
                      <b>Known Aliases:</b>
                      <span
                        *ngFor="let alternativeName of buildParams.profiles[0].alternativeNamesHolder; let last = last;">
                        <span *ngIf="alternativeName.name"
                          >{{ alternativeName.name }}<span *ngIf="!last">, </span></span
                        >
                      </span>
                    </div>
                    <div
                      *ngIf="buildParams.profiles[0].people.length && PU.hasNonEmptyValue(buildParams.profiles[0].people, 'personName')"
                      class="pushdown">
                      <b>Known Associates:</b>
                      <span *ngFor="let relatedPerson of buildParams.profiles[0].people; let last = last;">
                        <span *ngIf="relatedPerson.personName"
                          >{{ relatedPerson.personName }}<span *ngIf="relatedPerson.relationshipType">
                            ({{ relatedPerson.relationshipType }})</span
                          ><span *ngIf="!last">, </span></span
                        >
                      </span>
                    </div>
                  </div>
                  <div
                    class="pushdown"
                    *ngIf="!isScreeningEnabled && !updateSeedData && monitoringEnabled && profileRefreshEnabled && (!isSparseProfile() || isSparseProfile() && hasSelectedCompletion())">
                    <div *ngIf="monitoringEnabled">
                      <ddiq-monitor-categories
                        *ngIf="buildPersonModal.open"
                        (toggled)="onMonitoringToggled($event.toggled)">
                      </ddiq-monitor-categories>
                    </div>
                    <div class="build-modal-profile-refresh" *ngIf="profileRefreshEnabled">
                      <ul>
                        <li>
                          <input
                            id="profile-refresh-checkbox"
                            name="profile-refresh-toggle"
                            type="checkbox"
                            (change)="resetProfileRefresh(request.isRefreshable)"
                            [(ngModel)]="request.isRefreshable"
                            #profileRefresh="ngModel" />
                          <label for="profile-refresh-checkbox">Schedule Profile Refresh</label>
                        </li>
                        <li>
                          <ul class="profile_refresh_flex_container">
                            <li class="profile_refresh_field">
                              <label for="request-frequency">Refresh Frequency</label>
                              <select
                                id="request-frequency"
                                name="requestFrequency"
                                [(ngModel)]="request.frequency"
                                [disabled]="!request.isRefreshable"
                                #refreshFrequency="ngModel">
                                <option *ngFor="let option of profileRefreshFrequencies" value="{{option}}">
                                  {{option | translatef}}
                                </option>
                              </select>
                            </li>
                            <li class="profile_refresh_field">
                              <ddiq-date
                                id="schedule-start-date-input"
                                [inputId]="'schedule-start-date'"
                                label="From Date"
                                [(model)]="request.from"
                                [options]="request.dates.options.after"
                                [oiqTextDisabled]="!request.isRefreshable"
                                [labelPattern]="labelPattern"
                                [datePattern]="datePattern"></ddiq-date>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div *ngIf="isSparseProfile()">
                    <p class="sparse-profile">
                      <span>
                        <span class="inline fa fa-warning fa-2x"></span>You have not entered sufficient information to
                        associate content with a high level of confidence.
                      </span>
                    </p>
                  </div>
                  <button
                    id="buildProfile"
                    class="btn btn-primary"
                    (click)="buildProfile(buildPersonModal, confirm);"
                    [disabled]="refreshSelectionInvalid() ">
                    {{ isSparseProfile() && 'Proceed' || updateSeedData && 'Refresh Profile' || 'Build Profile'}}
                  </button>
                  <button class="btn btn-default" (click)="cancelDialog(buildPersonModal, confirm);">Cancel</button>
                </div>
              </div>
            </ddiq-confirm-modal>
          </div>
          <!-- didYouMean -->
          <div *ngSwitchCase="true" style="height: 100%">
            <ddiq-confirm-modal [toggleConfirm]="didYouMean.toggle">
              <div class="modal-dialog-content">
                <div class="modal-body">
                  <div class="build-left push-down">
                    <div class="suggestion">
                      <h3>{{ nameSuggestion.inProcess && 'Name Suggestions' || 'Confirm Submission' }}</h3>
                      <strong>You Entered</strong><br />
                      <div>
                        <strong>{{ buildParams.profiles[0].personName }}</strong>
                      </div>
                      <div *ngIf="buildParams.profiles[0].birthdate.year || buildParams.profiles[0].age">
                        <span *ngIf="buildParams.profiles[0].birthdate.year"
                          ><b>Date of Birth:</b> {{ buildParams.profiles[0].birthdate | dateFormat }}<br
                        /></span>
                        <span *ngIf="buildParams.profiles[0].age"><b>Age:</b> {{ buildParams.profiles[0].age }}</span>
                      </div>
                      <div *ngIf="buildParams.profiles[0].currentAddress | address" class="pushdown">
                        <b>Address:</b> {{ buildParams.profiles[0].currentAddress | address }}
                      </div>
                      <div
                        *ngIf="buildParams.profiles[0].currentPosition.jobTitle || buildParams.profiles[0].currentPosition.companyName"
                        class="pushdown">
                        <b>Employment:</b><br />
                        {{ buildParams.profiles[0].currentPosition.jobTitle }}
                        <span
                          *ngIf="buildParams.profiles[0].currentPosition.jobTitle && buildParams.profiles[0].currentPosition.companyName">
                          at
                        </span>
                        {{ buildParams.profiles[0].currentPosition.companyName }}
                      </div>
                      <div *ngIf="hasCompanies(buildParams.profiles[0].companies)" class="pushdown">
                        <b>Related Companies:</b>
                        <div *ngFor="let company of buildParams.profiles[0].companies">
                          <div *ngIf="company.companyName">
                            <span *ngIf="company.relationshipType">{{company.relationshipType}} with</span>
                            {{company.companyName}}
                          </div>
                          <div *ngIf="company.address | address">{{company.address | address}}</div>
                        </div>
                      </div>
                      <div
                        *ngIf="buildParams.profiles[0].previousAddresses.length && PU.hasNonEmptyValue(buildParams.profiles[0].previousAddresses, 'address.country')"
                        class="pushdown">
                        <b>Previous Addresses:</b><br />
                        <div *ngFor="let previousAddress of buildParams.profiles[0].previousAddresses">
                          <span *ngIf="PU.isAddressFilled(previousAddress.address)"
                            >{{ previousAddress.address | address }}</span
                          >
                        </div>
                      </div>
                      <div *ngIf="hasAlternativeNames() || hasSelectedNameSuggestion()" class="pushdown">
                        <b>Known Aliases:</b><br />
                        <span *ngFor="let alternativeName of collectAllAlternativeNames(); let last = last;">
                          <span>{{ alternativeName }}<span *ngIf="!last">, </span></span>
                        </span>
                      </div>
                      <div
                        *ngIf="buildParams.profiles[0].people.length && PU.hasNonEmptyValue(buildParams.profiles[0].people, 'personName')"
                        class="pushdown">
                        <b>Known Associates:</b>
                        <span *ngFor="let relatedPerson of buildParams.profiles[0].people; let last = last;">
                          <span *ngIf="relatedPerson.personName"
                            >{{ relatedPerson.personName }}<span *ngIf="relatedPerson.relationshipType">
                              ({{ relatedPerson.relationshipType }})</span
                            ><span *ngIf="!last">, </span></span
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="build-right push-down">
                    <div [ngClass]="{'suggestion' : !didYouMean.warn, 'suggestion-short' : didYouMean.warn}">
                      <h3>{{ suggestion.headerMessage| translatef }}</h3>
                      <strong>{{ suggestion.message| translatef }}</strong><br />
                      <img
                        *ngIf="existingProfiles.searching || nameSuggestion.generating || validating"
                        [src]="'./img/loader.gif'"
                        class="validation-loading-img pushdown" />
                      <div *ngIf="existingProfiles.inProcess">
                        <table class="list-table">
                          <tbody>
                            <tr
                              *ngFor="let suggestion of existingProfiles.suggestions"
                              class="suggestion"
                              [ngClass]="{'suggestion-selected' : suggestion.selected}">
                              <td>
                                <div class="right">
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="viewExistingReport(suggestion.reportUrl)">
                                    View
                                  </button>
                                </div>
                                <div>
                                  <strong class="wrappable">{{ suggestion.name }}</strong><br />
                                  <strong *ngIf="existingProfiles.useSubmissionId" class="wrappable"
                                    >{{ suggestion.submissionId }}</strong
                                  >
                                  <span *ngIf="suggestion.birthdate"
                                    >Date of Birth: {{suggestion.birthdate | dateFormat}}</span
                                  >
                                  <span>Built: {{suggestion.oiqCreatedDate | dateFormat}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div *ngIf="nameSuggestion.inProcess">
                        <table class="list-table">
                          <tbody>
                            <tr
                              *ngFor="let suggestion of nameSuggestion.suggestions"
                              class="suggestion"
                              [ngClass]="{'suggestion-selected' : suggestion.selected}">
                              <td>
                                <div class="right">
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="suggestion.selected = true; nameSuggestion.handled = true;"
                                    *ngIf="!suggestion.selected">
                                    Select
                                  </button>
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="suggestion.selected = false; nameSuggestion.handled = false;"
                                    *ngIf="suggestion.selected">
                                    Unselect
                                  </button>
                                </div>
                                <div>
                                  <strong class="wrappable">{{ suggestion.suggestion }}</strong>
                                </div>
                                <div>({{ suggestion.language | translatef }})</div>
                                <div *ngIf="suggestion.source"><b>Source:</b>{{ suggestion.source | translatef }}</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div *ngIf="didYouMean.inProcess">
                        <table class="list-table">
                          <tbody>
                            <tr
                              *ngFor="let completion of didYouMean.completions"
                              class="completion"
                              [ngClass]="{'completion-selected' : completion.selected}">
                              <td>
                                <div class="right">
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="completion.selected = true; didYouMean.handled = true;"
                                    *ngIf="!completion.selected">
                                    Select
                                  </button>
                                  <button
                                    class="btn btn-primary btn-sm btn-block"
                                    (click)="completion.selected = false; didYouMean.handled = false;"
                                    *ngIf="completion.selected">
                                    Unselect
                                  </button>
                                </div>
                                <div>
                                  <strong class="wrappable">{{ completion.name }}</strong
                                  ><span *ngIf="completion.birthdate.year" class="inline">
                                    ({{ completion.birthdate.year + '-' + completion.birthdate.month + '-' +
                                    completion.birthdate.day }})</span
                                  >
                                </div>
                                <span *ngIf="completion.biography">{{completion.biography}}</span>
                                <span *ngIf="completion.alternativeNames.length > 0"
                                  ><b>Alternative Names:</b>
                                  <span *ngFor="let altName of completion.alternativeNames" class="no-margin"
                                    >{{altName}}</span
                                  ></span
                                >
                                <span *ngIf="completion.age"><b>Age:</b> {{ completion.age }}</span>
                                <span *ngIf="completion.location"
                                  ><b>Current Address:</b><span>{{completion.location | address}}</span></span
                                >
                                <div *ngIf="completion.seedSources.length">
                                  <b>Sources:</b><br />
                                  <div *ngFor="let source of completion.seedSources">{{ source | translatef }}</div>
                                </div>
                                <ddiq-simple-collapse
                                  [simpleCollapse]="simpleCollapse"
                                  [showButton]="completion.previousAddresses.length > 0 || hasPhoneNumber(completion)">
                                  <span *ngIf="completion.previousAddresses.length > 0"
                                    ><b>Previous Addresses:</b>
                                    <span *ngFor="let prevAddress of completion.previousAddresses | slice:3"
                                      >{{prevAddress.address | address}}<span *ngIf="prevAddress.startDate"
                                        >({{prevAddress.startDate.year}}<span
                                          *ngIf="prevAddress.endDate"
                                          class="inline">
                                          - {{prevAddress.endDate.year}}</span
                                        >)</span
                                      ></span
                                    ></span
                                  >
                                  <span *ngIf="hasPhoneNumber(completion)"
                                    ><b>Phone Numbers:</b>
                                    <span *ngFor="let phone of completion.phoneNumbers" class="no-margin"
                                      >{{phone.number}}<span *ngIf="phone.connectionType" class="inline">
                                        ({{phone.connectionType}})</span
                                      ></span
                                    ></span
                                  >
                                </ddiq-simple-collapse>
                                <div *ngIf="completion.currentCompanyName">
                                  <b>Current Company:</b><br />
                                  <div>{{ completion.currentCompanyName }}</div>
                                </div>
                                <div *ngIf="completion.currentJobTitle">
                                  <b>Current Job Title:</b><br />
                                  <div>{{ completion.currentJobTitle }}</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="center pushdown clr">
                    <span *ngIf="didYouMean.handled && !submitting"
                      ><b>NOTE:</b> Clicking 'Build Profile' will merge the information from the selected items with the
                      currently entered information.</span
                    >
                    <span *ngIf="submitting"
                      ><img [src]="'./img/loader.gif'" class="submitting-img" /> Submitting Profile...</span
                    >
                  </div>
                  <div
                    class="pushdown"
                    *ngIf="!isScreeningEnabled && !updateSeedData && monitoringEnabled && profileRefreshEnabled && (!isSparseProfile() || isSparseProfile() && hasSelectedCompletion())">
                    <div *ngIf="monitoringEnabled">
                      <ddiq-monitor-categories
                        *ngIf="buildPersonModal.open"
                        (toggled)="onMonitoringToggled($event.toggled)">
                      </ddiq-monitor-categories>
                    </div>
                    <div class="build-modal-profile-refresh" *ngIf="profileRefreshEnabled">
                      <ul>
                        <li>
                          <input
                            id="profile-refresh-checkbox"
                            name="profile-refresh-toggle"
                            type="checkbox"
                            (change)="resetProfileRefresh(request.isRefreshable)"
                            [(ngModel)]="request.isRefreshable"
                            #profileRefresh="ngModel" />
                          <label for="profile-refresh-checkbox">Schedule Profile Refresh</label>
                        </li>
                        <li>
                          <ul class="profile_refresh_flex_container">
                            <li class="profile_refresh_field">
                              <label for="request-frequency">Refresh Frequency</label>
                              <select
                                id="request-frequency"
                                name="requestFrequency"
                                [(ngModel)]="request.frequency"
                                [disabled]="!request.isRefreshable"
                                #refreshFrequency="ngModel">
                                <option *ngFor="let option of profileRefreshFrequencies" value="{{option}}">
                                  {{option| translatef}}
                                </option>
                              </select>
                            </li>
                            <li class="profile_refresh_field">
                              <ddiq-date
                                id="schedule-start-date-input"
                                [inputId]="'schedule-start-date'"
                                label="From Date"
                                [(model)]="request.from"
                                [options]="request.dates.options.after"
                                [oiqTextDisabled]="!request.isRefreshable"
                                [labelPattern]="labelPattern"
                                [datePattern]="datePattern"></ddiq-date>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div *ngIf="isSparseProfile() && !hasSelectedCompletion()">
                    <p
                      class="sparse-profile"
                      *ngIf="isSparseProfile() && !hasSelectedCompletion() && !hasSelectedNameSuggestion()">
                      <span
                        ><span class="inline fa fa-warning fa-2x"></span>You have not entered sufficient information to
                        associate content with a high level of confidence.</span
                      >
                    </p>
                  </div>
                  <button
                    id="suggestNames"
                    *ngIf="existingProfiles.inProcess"
                    class="btn btn-primary"
                    [disabled]="existingProfiles.searching"
                    (click)="nameSuggestion.generate(buildPersonModal);">
                    Proceed
                  </button>
                  <button
                    id="validate"
                    *ngIf="nameSuggestion.inProcess"
                    class="btn btn-primary"
                    [disabled]="nameSuggestion.generating"
                    (click)="mergeSelectedNameSuggestions();didYouMean.validate(buildPersonModal);">
                    {{ isSparseProfile() && !hasSelectedNameSuggestion() && 'Proceed' || 'Validate'}}
                  </button>
                  <button
                    id="buildProfile"
                    *ngIf="didYouMean.inProcess"
                    class="btn btn-primary"
                    [disabled]="(validating || submitting || refreshSelectionInvalid() )"
                    (click)="mergeSelected();buildProfile(buildPersonModal, didYouMean);">
                    {{ isSparseProfile() && !hasSelectedCompletion() && 'Proceed' || 'Build Profile'}}
                  </button>
                  <button class="btn btn-default" (click)="cancelDialog(buildPersonModal, didYouMean);">Cancel</button>
                </div>
              </div>
            </ddiq-confirm-modal>
          </div>
        </div>
      </ddiq-modal>
    </div>
  </form>
  <ddiq-footer></ddiq-footer>
</section>

<ddiq-error-modal id="error-modal" class="ddiq-modal" trigger="error" closeable></ddiq-error-modal>
