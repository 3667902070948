//@ts-nocheck
import { APP_INITIALIZER } from '@angular/core';
import AppRedirect from './app-redirect';
import AppRedirectUrl from './app-redirect-url';

export default function () {
  return {
    provide: APP_INITIALIZER,
    useFactory() {
      return () => {
        const global = window;
        global.Exiger.AppRedirectUrl = AppRedirectUrl;
        global.Exiger.AppRedirect = AppRedirect;

        new global.Exiger.AppRedirect(window.location, new global.Exiger.AppRedirectUrl()).redirect();
        const code = window.Exiger.Utils.getParameter('code', window.location.href);

        if (code) {
          const state = window.Exiger.Utils.getParameter('state', window.location.href);
          window.location.href = window.location.origin + window.location.pathname + '#/admin';
          window.sessionStorage.setItem('authorization_code', code);
          window.sessionStorage.setItem('state', state);
        }
      };
    },
    multi: true,
  };
}
