import { Component, DoCheck } from '@angular/core';
import ScreeningFeatureService from './screening-feature.service';
import ScreeningHitCategoriesService from './screening-hit-categories.service';

@Component({
  selector: 'screening-hit-category',
  templateUrl: './screening-hit-category.component.tpl.html',
})
export default class ScreeningHitCategoryComponent implements DoCheck {
  prompt = { label: 'Any Category', value: 'ANY_CATEGORY' };
  isEnabled;
  categories;
  selectedCategory;

  constructor(
    private screeningFeature: ScreeningFeatureService,
    private screeningHitCategories: ScreeningHitCategoriesService
  ) {
    this.isEnabled = this.screeningFeature.isEnabled();
    this.categories = [this.prompt].concat(this.screeningHitCategories.getAll());
  }

  onCategoryChange() {
    this.screeningHitCategories.select(this.selectedCategory);
  }

  ngDoCheck() {
    this.selectedCategory = (this.screeningHitCategories.getSelectedCategory() || this.prompt).value;
  }
}
