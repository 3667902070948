<oiq-drop-down
  class="action-menu btn btn-sm btn-drop-down"
  [toggleName]="'btn-status'"
  [ngClass]="{ 'btn-primary': isValidConfig, 'btn-error': !isValidConfig, 'no-print': !hasProfileStatus }"
  [isDisabled]="!isValidConfig || isBusy">
  <oiq-drop-down-toggle [toggleTitle]="dropDownMessage || 'Click to change statuses'">
    <i class="fa fa-exclamation-triangle fa-sm" *ngIf="!isValidConfig"></i>
    {{ entity.getData().profileStatus || 'Change Status'}}
  </oiq-drop-down-toggle>
  <oiq-drop-down-items
    class="actions drop-down-items"
    *ngFor="let status of statuses"
    [ngClass]="{ 'disabled': !status.isValid }"
    [attr.title]="status.reason | profileStatusError"
    (click)="updateStatus(status.name, status.isValid)">
    <a class="profile-status-item">{{status.name}}</a>
  </oiq-drop-down-items>
</oiq-drop-down>
