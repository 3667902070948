import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'watch-list-header',
  templateUrl: './watch-list-header.component.tpl.html',
})
export default class WatchListHeader implements OnInit {
  @Input() moreSourcesCount;
  @Input() watchList;

  sources;
  constructor() {}

  ngOnInit() {
    this.sources = this.getSource();
  }

  getSource() {
    let source,
      watchList = this.watchList;

    if (watchList) {
      source = (watchList.seedSources || [])[0];
    }
    return source;
  }
}
