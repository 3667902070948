import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';

@Injectable()
export default class ServiceUnavailableInterceptor implements HttpInterceptor {
  constructor(private messageBusService: MessageBusService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status >= 502 && error.status <= 504) {
          this.messageBusService.send({ type: 'event:service-unavailable' });
        }
        return throwError(error);
      })
    );
  }
}
