import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import CorporateGraph from './corporate-graph.service';

@Injectable({
  providedIn: 'root',
})
export default class CorporateGraphResolver implements Resolve<OiqProperties> {
  graphs = {
    ownershipData: 'ownership',
    familyTree: 'familytree',
  };

  constructor(
    private oiqProperties: OiqProperties,
    private apiFetchService: ApiFetchService,
    private corporateGraph: CorporateGraph
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return Promise.all([this.oiqProperties.apiPropertiesPromise, this.preLoadGraphData(route.params)]);
  }

  preLoadGraphData(routeParams) {
    const entityId = routeParams.entityId,
      recordId = routeParams.recordId,
      graphType = this.graphs[routeParams.graphType];

    return this.apiFetchService.getCorporateGraphData(entityId, recordId, graphType).then((data) => {
      const graphData = this.corporateGraph.map(data);

      this.corporateGraph.setIds(graphData);

      if (this.corporateGraph.isOwnershipGraph(graphType)) {
        this.corporateGraph.initPercentages(graphData);
      }

      return graphData;
    });
  }
}
