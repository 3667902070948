import { HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import ConfigService from '../../../../../shared/services/http/config.service';
import Theme from './theme.service';
import $ from 'jquery';
import utils from '../../../exiger/utils';

export default function () {
  return {
    provide: APP_INITIALIZER,
    useFactory: (httpBackend: HttpBackend, configService: ConfigService, theme: Theme) => {
      return () => {
        // creating the httpclient with the backend bypasses interceptors.
        return new HttpClient(httpBackend)
          .get(`${configService.settings.project.apiUrl}rest/theme`)
          .toPromise()
          .then((response: any) => {
            let themePath = 'css/themes/' + response.name,
              themeConfigUrl = themePath + '/config/theme-config.json';

            return new HttpClient(httpBackend)
              .get(themeConfigUrl)
              .toPromise()
              .then((themeConfig: any) => {
                let cssUrl = themePath + '/theme.' + (themeConfig.rev ? themeConfig.rev + '.css' : 'css'),
                  link = $('<link id="theme" rel="stylesheet" href="' + cssUrl + '" />'),
                  head = $('head');

                head.prepend(getFontURL(themeConfig)).prepend(getFaviconIco(themeConfig));

                return new Promise<void>((resolve) => {
                  if (utils.isPDF()) {
                    head.prepend(link);
                    theme.setConfig({
                      theme: response,
                      dictionary: themeConfig.dictionary,
                      pageConfig: themeConfig.pageConfig,
                      themePath: themePath,
                    });
                    resolve();
                  } else {
                    link.on('load', function () {
                      theme.setConfig({
                        theme: response,
                        dictionary: themeConfig.dictionary,
                        pageConfig: themeConfig.pageConfig,
                        themePath: themePath,
                      });
                      resolve();
                    });
                    head.prepend(link);
                  }
                });
              });
          });
      };
    },
    deps: [HttpBackend, ConfigService, Theme],
    multi: true,
  };
}

function getFontURL(themeConfig) {
  var defaultFontUrl =
      'https://fonts.googleapis.com' +
      '/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
    fontUrl = themeConfig.pageConfig.fontUrl || defaultFontUrl;

  return '<link href="' + fontUrl + '" rel="stylesheet" type="text/css">';
}

function getFaviconIco(themeConfig) {
  return '<link rel="icon" type="image/x-icon" href="' + (themeConfig.pageConfig.favicon || 'favicon.ico') + '" />';
}
