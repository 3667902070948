import { HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import ConfigService from '../../../../../shared/services/http/config.service';
import SupportContactInfo from './support-contact-info.service';

export function configSupportContactInfo() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (httpBackend: HttpBackend, configService: ConfigService, supportInfo: SupportContactInfo) => {
      return () => {
        // creating the httpclient with the backend bypasses interceptors.
        return new HttpClient(httpBackend)
          .get(`${configService.settings.project.apiUrl}rest/supportcontactinfo`)
          .toPromise()
          .then((response) => supportInfo.setConfiguredContactInfo(response));
      };
    },
    deps: [HttpBackend, ConfigService, SupportContactInfo],
    multi: true,
  };
}
