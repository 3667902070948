// @ts-nocheck
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ConfigService {
  settings: any;
  constructor(private config) {
    this.settings = { project: config };
  }
}
