import { Input, Component } from '@angular/core';
import DynamicAdjudicationService from './dynamic-adjudication.service';

@Component({
  selector: 'oiq-dynamic-adjudication-aware',
  templateUrl: './oiq-dynamic-adjudication-aware.component.tpl.html',
})
export default class OiqDynamicAdjudicationAware {
  @Input() model: any;

  constructor(private dynamicAdjudicationService: DynamicAdjudicationService) {}

  toggleSelected() {
    this.model.selected = !this.model.selected;
    if (this.model.selected) {
      this.dynamicAdjudicationService.incrementCount();
    } else {
      this.dynamicAdjudicationService.decrementCount();
    }
  }

  mode() {
    return this.dynamicAdjudicationService.getMode();
  }

  dynamicAdjudicationActive() {
    const mode = this.dynamicAdjudicationService.getMode();
    if (
      (mode === 'CONFIRM' && !this.model.confirmed) ||
      (mode === 'ESCALATE' && !this.model.escalated) ||
      (mode === 'REMOVE' && !this.model.disabled)
    ) {
      return true;
    }
    return mode === 'COMMENT';
  }
}
