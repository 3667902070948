<img [hidden]="!results.isLoading" [src]="'./img/loader.gif'" class="monitor-loading-img" />

<p [hidden]="!(!isQueryEmpty || !results.reports.length)" class="search-results">
  Your search <span [hidden]="!lastQueryString">for <span>"{{lastQueryString}}"</span></span> has {{results.total}}
  results on {{pagination.total}} pages
</p>

<table class="results-table" [hidden]="!results.reports.length">
  <tr>
    <th *ngIf="isScreeningFeatureEnabled">Entity Name</th>
    <th *ngIf="isScreeningFeatureEnabled">Entity Type</th>
    <th *ngIf="isScreeningFeatureEnabled">{{'REFERENCE_ID' | translatef}}</th>
    <th *ngIf="isScreeningFeatureEnabled">Primary Address Country</th>

    <th *ngIf="!isScreeningFeatureEnabled" class="monitor-result-seed-data" (click)="changeSorting('name')">
      <ddiq-sortable-column column="name" [sort]="pagination">Input Data</ddiq-sortable-column>
    </th>

    <th *ngIf="!isScreeningFeatureEnabled" class="monitor-result-hit"></th>

    <th class="center no-wrap" (click)="changeSorting('date')">
      <ddiq-sortable-column column="date" [sort]="pagination">Latest Match</ddiq-sortable-column>
    </th>
    <th class="center no-wrap" (click)="changeSorting('owner')">
      <ddiq-sortable-column column="owner" [sort]="pagination">Owner</ddiq-sortable-column>
    </th>
    <th class="search-narrow" [hidden]="!isGovIdSearchEnabled"><div>Identification</div></th>
  </tr>

  <tr *ngFor="let result of results.reports">
    <td class="screening-feature-show" *ngIf="isScreeningFeatureEnabled">
      <div class="monitor-hit-count">
        <span>{{ result.monitorHitTotal }} match{{result.monitorHitTotal > 1 ? 'es' : ''}}</span>
      </div>
      <a [href]="result.profileLink" target="_blank" rel="noreferrer noopener">{{result.seedData.name}}</a>
    </td>

    <td *ngIf="isScreeningFeatureEnabled" class="top">{{result.entityType}}</td>
    <td *ngIf="isScreeningFeatureEnabled" class="top">{{result.submissionId}}</td>
    <td *ngIf="isScreeningFeatureEnabled" class="top">
      {{result.seedData.userEnteredAddress?.country || result.seedData.location?.country}}
    </td>

    <td class="top" *ngIf="!isScreeningFeatureEnabled">
      <div class="monitor-hit-count">
        <span>{{ result.monitorHitTotal }} match{{result.monitorHitTotal > 1 ? 'es' : ''}}</span>
      </div>
      <ddiq-monitor-seed-data
        [seedData]="result.seedData"
        [entityType]="result.entityType"
        [profileLink]="result.profileLink"
        [submissionId]="result.submissionId"></ddiq-monitor-seed-data>
    </td>

    <td *ngIf="!isScreeningFeatureEnabled" class="top">
      <ddiq-monitor-results [result]="result"></ddiq-monitor-results>
    </td>

    <td class="top center no-wrap">{{ result.oiqLastUpdatedDate | dateFormat }}</td>
    <td class="top">{{ result.oiqOwnerFullName }}</td>
    <td class="profile-library-id top" *ngIf="isGovIdSearchEnabled">
      <ddiq-limited-list [list]="result.monitor.identificationNumbers" [limit]="1">
        <ng-template #parentTemplate let-limitedList>
          <ul>
            <li *ngFor="let id of limitedList">{{id.identificationType}}: {{id.number}}</li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </td>
  </tr>
</table>

<exg-pagination
  [numPages]="pagination.total"
  [currentPage]="pagination.current"
  [paginationBarLength]="pagination.visible"
  [tableTotal]="results.total"
  (onSelectPage)="onSelectPage($event.page)"
  [totalLabel]="'profiles'">
</exg-pagination>
