<div [hidden]="!hasData">
  <table [ngClass]="{'closed-summary-warning': adjudicated != total, 'closed-summary-success': adjudicated == total}">
    <tr class="closed-summary-open">
      <th>Open:</th>
      <td class="closed-summary-open-count">{{total - adjudicated}}</td>
    </tr>

    <tr class="closed-summary-complete">
      <th>Closed:</th>
      <td class="closed-summary-complete-count text-right">{{adjudicated}}</td>
    </tr>
  </table>
</div>
