<div>
  <ul>
    <li *ngFor="let structured of data.structuredCounts">
      <div class="structured-data">
        <span class="detail-key">{{structured.key | translatef}}</span>
        <span class="detail-value">{{structured.value}}</span>
      </div>
    </li>

    <li *ngIf="!noWebContent">
      <div class="structured-data">
        <i
          class="fa"
          [ngClass]="{'fa-plus-square-o': !data.open, 'fa-minus-square-o': data.open}"
          (click)="data.open = !data.open"
          *ngIf="data.incidentHits && !isForPrint">
        </i>

        <span class="detail-key">{{'Web Content' | translatef}}</span>
        <span class="detail-value">{{data.incidentHits}}</span>
      </div>

      <ul *ngIf="data.open">
        <li *ngFor="let webcontent of data.webContentCounts">
          <div class="web-content">
            <span class="detail-key">{{webcontent.key | translatef}}</span>
            <span class="detail-value">{{webcontent.value}}</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>
