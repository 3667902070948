import { Injectable } from '@angular/core';
import Monitor from '../monitor/monitor.service';
import ApiFetchService from './api-fetch.service';
import Entity from './entity.service';

@Injectable({
  providedIn: 'root',
})
export default class EntityCache {
  constructor(private apiFetchService: ApiFetchService, private monitor: Monitor, private entity: Entity) {}

  fetchAsEntity(type, id) {
    return new Promise((resolve, reject) => {
      this.fetchEntity(type, id).then(
        (entityData) => {
          //FIXME: RV looks like the entity object needs methods to be
          //called in an order.  Re-think the usefulness of this object
          //all together.
          this.entity.setType(type);
          this.entity.setId(id);
          this.entity.setData(entityData);
          resolve(this.entity);
        },
        function fetchError(data) {
          reject(data);
        }
      );
    });
  }

  fetchEntity(type, id) {
    return new Promise((resolve, reject) => {
      const isDeltaReport = this.monitor.isDeltaReport();

      if (isDeltaReport) {
        this.apiFetchService.getMonitorDeltaReport(type, id).then(successCallback, failCallback);
      } else {
        this.apiFetchService.entity(type, id).then(successCallback, failCallback);
      }

      function successCallback(data) {
        if (data !== 'null') {
          resolve(data);
        } else {
          reject('Fetch of ' + type + ' #' + id + ' came back null');
        }
      }

      function failCallback() {
        reject('Fetch of ' + type + ' #' + id + ' returned an error');
      }
    });
  }

  invalidate(id) {}
}
