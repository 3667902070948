<ul class="container">
  <li *ngIf="submissionId" class="header-item submission-id">
    <span class="label">{{'REFERENCE_ID' | translatef}}</span> {{submissionId}}
  </li>

  <li *ngIf="latestMatchDate" class="header-item latest-match-date">
    <span class="label">Latest Match:</span> {{latestMatchDate | dateFormat}}
  </li>

  <li class="header-item last-run-date">
    <span class="label">Last Run:</span> {{lastRunDate ? (lastRunDate | dateFormat) : 'Never'}}
  </li>

  <li *ngIf="ownerName" class="header-item owner-name"><span class="label">Owner:</span> {{ownerName}}</li>

  <li *ngIf="adjudicatedHitsCount" class="header-item adjudicated-hits-counts">
    <span class="label">Closed:</span> {{adjudicatedHitsCount}}
  </li>

  <li *ngIf="isGovIdEnabled && idNumbers?.length" class="header-item profile-library-id id-numbers">
    <span class="label">Identification:</span>
    <!-- We require the placeholder to prevent the flexbox element from collapsing its space when list is toggled  open -->
    <div class="limited-list-container">
      <ddiq-limited-list class="id-number-placeholder" [list]="idNumbers" [limit]="1">
        <ng-template #parentTemplate let-limitedList>
          <ul>
            <li class="id-number" *ngFor="let id of limitedList | slice:0:1">
              {{id.identificationType}}: {{id.number}}
            </li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
      <ddiq-limited-list class="id-number-drop-down" [list]="idNumbers" [limit]="1">
        <ng-template #parentTemplate let-limitedList>
          <ul>
            <li class="id-number" *ngFor="let id of limitedList">{{id.identificationType}}: {{id.number}}</li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </div>
  </li>
</ul>
