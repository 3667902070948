import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doingBusinessIn',
})
export default class DoingBusinessInPipe implements PipeTransform {
  transform(addresses: any) {
    return (addresses || []).filter(function (address) {
      return address && address.label === 'DOING_BUSINESS_IN';
    });
  }
}
