<div id="compare">
  <div class="modal-title">Compare Profiles</div>

  <div class="message">
    <span *ngIf="diff.profiles.length">Select a baseline profile for <em>{{ diff.current.name }}</em>:</span>
    <span *ngIf="diff.profiles.length === 0">No profiles available for comparison.</span>
  </div>

  <div *ngIf="diff.profiles.length" [ngSwitch]="entityType" class="suggestion">
    <table *ngSwitchCase="'company'">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Address</th>
          <th>Build Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let profile of diff.profiles">
          <td>{{ profile.name }}</td>
          <td>{{ profile.mainAddress | address }}</td>
          <td>{{ profile.oiqCreatedDate | dateFormat }}</td>
          <td>
            <a [href]="profile.diffUrl" class="btn btn-primary btn-sm"> Compare </a>
          </td>
        </tr>
      </tbody>

      <tbody></tbody>
    </table>

    <table *ngSwitchCase="'person'">
      <thead>
        <tr>
          <th>Name</th>
          <th>Reference Tag</th>
          <th>Build Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let profile of diff.profiles">
          <td>
            <span *ngIf="profile.name">{{ profile.name }}</span>
            <span *ngIf="!profile.name">{{ profile.firstName }} {{ profile.middleName }} {{ profile.lastName }}</span>
          </td>
          <td>{{ profile.submissionId }}</td>
          <td>{{ profile.oiqCreatedDate | dateFormat }}</td>
          <td>
            <a [href]="profile.diffUrl" class="btn btn-primary btn-sm"> Compare </a>
          </td>
        </tr>
      </tbody>

      <tbody></tbody>
    </table>
  </div>
</div>
