import { Component, DoCheck, Input, OnInit } from '@angular/core';
@Component({
  selector: 'ddiq-support-contact-info',
  templateUrl: './ddiq-support-contact-info.component.tpl.html',
})
export default class SupportContactInfoComponent implements OnInit, DoCheck {
  @Input() subject;
  @Input() withPageLinkIncluded;
  @Input() text;
  supportSubject;
  supportBody;
  emailBody;

  constructor() {}

  ngOnInit(): void {
    this.supportSubject = this.subject || encodeURIComponent('DDIQ Support');
    this.supportBody = encodeURIComponent('Support needed for page: ');
  }

  ngDoCheck(): void {
    const path = location.href;
    const supportHref = encodeURIComponent(path);

    this.emailBody = `${this.supportBody}${supportHref}`;
  }
}
