<div id="references" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="References"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!entityAware.isPreview || referencesHasContent()"
    [disable]="!referencesHasContent()"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <div id="company_dataSources" class="push-down non-breaking">
      <strong class="push-down" translate>Data Sources</strong>

      <div load-source [saveIn]="section.data" #loadSource="loadSource">
        <div [ngSwitch]="loadSource.saveIn.sourceCount > 0">
          <div *ngSwitchCase="false">
            <p *ngIf="!entityAware.isDiff">No Data Sources</p>
            <p *ngIf="entityAware.isDiff">Data Sources are not included in the comparison</p>
            <hr />
          </div>

          <div *ngSwitchDefault>
            <div [ngSwitch]="loadSource.saveIn.profileCanada && loadSource.saveIn.profileCanada.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Profile Canada" openonprint>
                  <div class="data-source">
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.profileCanada.profileUrl" target="_blank"
                        >{{loadSource.saveIn.profileCanada.profileUrl}}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.name">
                      <strong><span translate>Name</span>:</strong> {{loadSource.saveIn.profileCanada.name}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.address">
                      <strong><span translate>Address</span>:</strong>
                      {{loadSource.saveIn.profileCanada.address.streetAddress}}
                      {{loadSource.saveIn.profileCanada.address.city}}
                      {{loadSource.saveIn.profileCanada.address.stateOrProvince}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.phoneNo">
                      <strong><span translate>Phone number</span>:</strong> {{loadSource.saveIn.profileCanada.phoneNo}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.faxNo">
                      <strong><span translate>Fax number</span>:</strong> {{loadSource.saveIn.profileCanada.faxNo}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.website">
                      <strong><span translate>Website</span>:</strong> {{loadSource.saveIn.profileCanada.website |
                      websiteNameNormalizer}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.tollFreeNo">
                      <strong><span translate>Toll free</span>:</strong> {{loadSource.saveIn.profileCanada.tollFreeNo}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.isoNo">
                      <strong><span translate>ISO</span>:</strong> {{loadSource.saveIn.profileCanada.isoNo}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.businessActivity">
                      <strong><span translate>Business Activity</span>:</strong>
                      {{loadSource.saveIn.profileCanada.businessActivity}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.productsAndServicesDescription">
                      <strong><span translate>Products & Services Description</span>:</strong>
                      {{loadSource.saveIn.profileCanada.productsAndServicesDescription}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.approxNoOfEmployees">
                      <strong><span translate>Employee # (estimated)</span>:</strong>
                      {{loadSource.saveIn.profileCanada.approxNoOfEmployees}}
                    </p>
                    <p *ngIf="loadSource.saveIn.profileCanada.sales">
                      <strong><span translate>Last year sales (estimated)</span>:</strong>
                      {{loadSource.saveIn.profileCanada.sales}}
                    </p>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
            <div [ngSwitch]="loadSource.saveIn.bbb && loadSource.saveIn.bbb.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="BBB" openonprint>
                  <div class="data-source">
                    <div *ngIf="loadSource.saveIn.bbb.associationScore">
                      <ddiq-association [level]="loadSource.saveIn.bbb.associationBucket"></ddiq-association>
                    </div>
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.bbb.profileUrl" target="_blank"
                        >{{loadSource.saveIn.bbb.profileUrl}}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.bbb.name">
                      <strong><span translate>Name</span>:</strong> {{loadSource.saveIn.bbb.name}}
                    </p>
                    <p *ngIf="loadSource.saveIn.bbb.address && loadSource.saveIn.bbb.address.streetAddress">
                      <strong><span translate>Address</span>:</strong> {{loadSource.saveIn.bbb.address.streetAddress}}
                    </p>
                    <p *ngIf="loadSource.saveIn.bbb.phoneNumber">
                      <strong><span translate>Phone number</span>:</strong> {{loadSource.saveIn.bbb.phoneNumber}}
                    </p>
                    <p *ngIf="loadSource.saveIn.bbb.fax">
                      <strong><span translate>Fax number</span>:</strong> {{loadSource.saveIn.bbb.fax}}
                    </p>
                    <p *ngIf="loadSource.saveIn.bbb.email">
                      <strong><span translate>Email</span>:</strong> {{loadSource.saveIn.bbb.email}}
                    </p>
                    <p *ngIf="loadSource.saveIn.bbb.rating">
                      <strong><span translate>BBB rating</span>:</strong> {{loadSource.saveIn.bbb.rating}}
                    </p>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
            <div [ngSwitch]="loadSource.saveIn.zoomInfo && loadSource.saveIn.zoomInfo.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="ZoomInfo" openonprint>
                  <div class="data-source">
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.zoomInfo.profileUrl" target="_blank"
                        >{{loadSource.saveIn.zoomInfo.profileUrl}}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.organizationName">
                      <strong><span translate>Name</span>:</strong> {{loadSource.saveIn.zoomInfo.organizationName}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.companyDescription">
                      <strong><span translate>Description</span>:</strong>
                      {{loadSource.saveIn.zoomInfo.companyDescription}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.postalAddress">
                      <strong><span translate>Address</span>:</strong>
                      {{loadSource.saveIn.zoomInfo.postalAddress.streetAddress}}
                      {{loadSource.saveIn.zoomInfo.postalAddress.city}}
                      {{loadSource.saveIn.zoomInfo.postalAddress.stateOrProvince}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.organizationPhone">
                      <strong><span translate>Phone number</span>:</strong>
                      {{loadSource.saveIn.zoomInfo.organizationPhone}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.url">
                      <strong><span translate>Website</span>:</strong> {{loadSource.saveIn.zoomInfo.url}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.employees">
                      <strong><span translate>Number of employees</span>:</strong>
                      {{loadSource.saveIn.zoomInfo.employees}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.revenue">
                      <strong><span translate>Last year sales (estimated)</span>:</strong>
                      {{loadSource.saveIn.zoomInfo.revenue}}
                    </p>
                    <p *ngIf="loadSource.saveIn.zoomInfo.ticker">
                      <strong><span translate>Stock Ticker</span>:</strong> {{loadSource.saveIn.zoomInfo.ticker}}
                    </p>
                    <div *ngIf="loadSource.saveIn.zoomInfo.industries && loadSource.saveIn.zoomInfo.industries.length">
                      <strong><span translate>Industries</span>:</strong>
                      <ul>
                        <li *ngFor="let industry of loadSource.saveIn.zoomInfo.industries">{{industry}}</li>
                      </ul>
                    </div>
                    <div *ngIf="loadSource.saveIn.zoomInfo.mergers && loadSource.saveIn.zoomInfo.mergers.length">
                      <strong><span translate>Mergers</span>:</strong>
                      <ul>
                        <li *ngFor="let merger of loadSource.saveIn.zoomInfo.mergers">{{merger.organizationName}}</li>
                      </ul>
                    </div>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
            <div
              [ngSwitch]="loadSource.saveIn.oiqPremiumDataSource && loadSource.saveIn.oiqPremiumDataSource.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Premium Data Source" openonprint>
                  <div class="data-source">
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].name">
                      <strong><span translate>Name</span>:</strong> {{loadSource.saveIn.oiqPremiumDataSource[0].name}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].dba[0]">
                      <strong><span translate>DBA</span>:</strong> {{loadSource.saveIn.oiqPremiumDataSource[0].dba[0]}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].primaryUrl">
                      <strong><span translate>Company Website</span>:</strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].primaryUrl | websiteNameNormalizer}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].shortDescription">
                      <strong><span translate>Description(short)</span>:</strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].shortDescription | stripHTML}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].longDescription">
                      <strong><span translate>Description(long)</span>:</strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].longDescription | stripHTML}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].addresses[0]">
                      <strong><span translate>Address</span>:</strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].addresses[0].streetAddress}}
                      {{loadSource.saveIn.oiqPremiumDataSource[0].addresses[0].city}}
                      {{loadSource.saveIn.oiqPremiumDataSource[0].addresses[0].stateOrProvince}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].phoneNumbers[0]">
                      <strong><span translate>Phone number</span>:</strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].phoneNumbers[0].countryCode}}
                      {{loadSource.saveIn.oiqPremiumDataSource[0].phoneNumbers[0].areaCode}}
                      {{loadSource.saveIn.oiqPremiumDataSource[0].phoneNumbers[0].phoneNumber}}
                    </p>
                    <p
                      *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].financialData[0] && loadSource.saveIn.oiqPremiumDataSource[0].financialData[0].totalEmployees">
                      <strong><span translate>Number of employees</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].financialData[0].totalEmployees}}
                    </p>
                    <p
                      *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].financialData[0] && loadSource.saveIn.oiqPremiumDataSource[0].financialData[0].sales">
                      <strong><span translate>Last year sales (estimated)</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].financialData[0].sales}}mil
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].netIncome">
                      <strong><span translate>Net income</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].netIncome}}mil
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].netIncomeGrowth">
                      <strong><span translate>Net income growth</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].netIncomeGrowth}}%
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].salesGrowth">
                      <strong><span translate>Sales growth</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].salesGrowth}}%
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].financialPeriod">
                      <strong><span translate>Financial period</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].financialPeriod}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].assets">
                      <strong><span translate>Assets</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].assets}}mil
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].marketValue">
                      <strong><span translate>Market value</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].marketValue}}mil
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].marketValuationDate">
                      <strong><span translate>Market value date</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].marketValuationDate}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].bankName">
                      <strong><span translate>Bank name</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].bankName}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].accountingFirm">
                      <strong><span translate>Accounting firm</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].accountingFirm}}
                    </p>
                    <div *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].exchanges.length">
                      <strong><span translate>Stock ticker</span></strong>
                      <ul>
                        <li *ngFor="let exchange of loadSource.saveIn.oiqPremiumDataSource[0].exchanges">
                          {{exchange.exchange}}: {{exchange.symbol}}: {{exchange.country}}
                        </li>
                      </ul>
                    </div>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].numberOfPCs">
                      <strong><span translate>Number of PCs</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].numberOfPCs}}
                    </p>
                    <p
                      *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].creditRiskScore && loadSource.saveIn.oiqPremiumDataSource[0].creditRiskScore.description">
                      <strong><span translate>Credit score</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].creditRiskScore.description}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].spaceAtAddress">
                      <strong><span translate>Space at Address</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].spaceAtAddress}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].spaceAtAddressAccuracy">
                      <strong><span translate>Address accuracy</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].spaceAtAddressAccuracy}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].spaceAtAddressUnitOfMeasurement">
                      <strong><span translate>Unit of measurement</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].spaceAtAddressUnitOfMeasurement}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].yearFounded">
                      <strong><span translate>Year founded</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].yearFounded}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].ownedOrLeased">
                      <strong><span translate>Own or lease</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].ownedOrLeased}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].manufacturingIndicator">
                      <strong><span translate>Manufacturing Indicator</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].manufacturingIndicator}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqPremiumDataSource[0].legalStatus">
                      <strong><span translate>Legal Status</span></strong>
                      {{loadSource.saveIn.oiqPremiumDataSource[0].legalStatus}}
                    </p>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
            <div [ngSwitch]="loadSource.saveIn.oiqCrawlProfile && loadSource.saveIn.oiqCrawlProfile.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Extracted from Company Website" openonprint>
                  <div class="data-source">
                    <p *ngIf="loadSource.saveIn.oiqCrawlProfile.website">
                      <strong><span translate>Website</span>:</strong> {{loadSource.saveIn.oiqCrawlProfile.website}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqCrawlProfile.description">
                      <strong><span translate>Description</span>:</strong>
                      {{loadSource.saveIn.oiqCrawlProfile.description}}
                    </p>
                    <p
                      *ngIf="loadSource.saveIn.oiqCrawlProfile.mainLocation && loadSource.saveIn.oiqCrawlProfile.mainLocation.streetAddress">
                      <strong><span translate>Address</span>:</strong>
                      {{loadSource.saveIn.oiqCrawlProfile.mainLocation.streetAddress}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqCrawlProfile.mainPhoneNumber">
                      <strong><span translate>Phone number</span>:</strong>
                      {{loadSource.saveIn.oiqCrawlProfile.mainPhoneNumber}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqCrawlProfile.revenues">
                      <strong><span translate>Last year sales (estimated)</span>:</strong>
                      {{loadSource.saveIn.oiqCrawlProfile.revenues | currencyOrNull: 'true'}}
                    </p>
                    <p *ngIf="loadSource.saveIn.oiqCrawlProfile.foundedDate">
                      <strong><span translate>Founded Date</span>:</strong>
                      {{loadSource.saveIn.oiqCrawlProfile.foundedDate}}
                    </p>
                    <div
                      *ngIf="loadSource.saveIn.oiqCrawlProfile.management && loadSource.saveIn.oiqCrawlProfile.management.length">
                      <strong><span translate>Management</span>:</strong>
                      <ul>
                        <li *ngFor="let person of loadSource.saveIn.oiqCrawlProfile.management">
                          <span *ngIf="person.name">{{ person.name }}</span>
                          <span *ngIf="!person.name"
                            >{{ person.firstName }} {{ person.middleName }} {{ person.lastName }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div
                      *ngIf="loadSource.saveIn.oiqCrawlProfile.locations && loadSource.saveIn.oiqCrawlProfile.locations.length">
                      <strong><span translate>Other Locations</span>:</strong>
                      <ul>
                        <li *ngFor="let location of loadSource.saveIn.oiqCrawlProfile.locations">
                          {{ location.streetAddress }} {{ location.city }} {{ location.stateOrProvince }} {{
                          location.country }}
                        </li>
                      </ul>
                    </div>
                    <div
                      *ngIf="loadSource.saveIn.oiqCrawlProfile.certifications && loadSource.saveIn.oiqCrawlProfile.certifications.length">
                      <strong><span translate>Certifications</span>:</strong>
                      <ul>
                        <li *ngFor="let certification of loadSource.saveIn.oiqCrawlProfile.certifications">
                          {{certification.certificationName}}
                        </li>
                      </ul>
                    </div>
                    <div
                      *ngIf="loadSource.saveIn.oiqCrawlProfile.associations && loadSource.saveIn.oiqCrawlProfile.associations.length">
                      <strong><span translate>Associations</span>:</strong>
                      <ul>
                        <li *ngFor="let association of loadSource.saveIn.oiqCrawlProfile.associations">
                          {{association}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
            <div [ngSwitch]="loadSource.saveIn.googlePlaces && loadSource.saveIn.googlePlaces.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Google Places" openonprint>
                  <div class="data-source">
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.googlePlaces.profileUrl" target="_blank"
                        >{{loadSource.saveIn.googlePlaces.profileUrl}}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.googlePlaces.name">
                      <strong><span translate>Name</span>:</strong> {{loadSource.saveIn.googlePlaces.name}}
                    </p>
                    <p *ngIf="loadSource.saveIn.googlePlaces.address">
                      <strong><span translate>Address</span>:</strong> {{loadSource.saveIn.googlePlaces.address}}
                    </p>
                    <p *ngIf="loadSource.saveIn.googlePlaces.phoneNumber">
                      <strong><span translate>Phone number</span>:</strong>
                      {{loadSource.saveIn.googlePlaces.phoneNumber}}
                    </p>
                    <p *ngIf="loadSource.saveIn.googlePlaces.rating">
                      <strong><span translate>Rating</span>:</strong> {{loadSource.saveIn.googlePlaces.rating}}
                    </p>
                    <p *ngIf="loadSource.saveIn.googlePlaces.website">
                      <strong><span translate>Website</span>:</strong> {{loadSource.saveIn.googlePlaces.website}}
                    </p>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
            <div [ngSwitch]="loadSource.saveIn.yellowPages && loadSource.saveIn.yellowPages.hasContent">
              <div *ngSwitchCase="true">
                <ddiq-accordion label="Yellow Pages" openonprint>
                  <div class="data-source">
                    <p>
                      <strong><span translate>From</span>:</strong>
                      <a [href]="loadSource.saveIn.yellowPages[0].profileUrl" target="_blank"
                        >{{loadSource.saveIn.yellowPages[0].profileUrl}}</a
                      >
                    </p>
                    <p *ngIf="loadSource.saveIn.yellowPages[0].name">
                      <strong><span translate>Name</span>:</strong> {{loadSource.saveIn.yellowPages[0].name}}
                    </p>
                    <p
                      *ngIf="loadSource.saveIn.yellowPages[0].address && loadSource.saveIn.yellowPages[0].address.streetAddress">
                      <strong><span translate>Address</span>:</strong>
                      {{loadSource.saveIn.yellowPages[0].address.streetAddress}}
                    </p>
                    <p *ngIf="loadSource.saveIn.yellowPages[0].profileDescription">
                      <strong><span translate>Description</span>:</strong>
                      {{loadSource.saveIn.yellowPages[0].profileDescription}}
                    </p>
                    <p *ngIf="loadSource.saveIn.yellowPages[0].phoneNumber">
                      <strong><span translate>Phone Number</span>:</strong>
                      {{loadSource.saveIn.yellowPages[0].phoneNumber}}
                    </p>
                    <p *ngIf="loadSource.saveIn.yellowPages[0].website">
                      <strong><span translate>Website</span>:</strong> {{loadSource.saveIn.yellowPages[0].website}}
                    </p>
                    <div *ngIf="loadSource.saveIn.yellowPages[1]">
                      <strong><span translate>Other Profile Links</span>:</strong>
                      <ul>
                        <li *ngFor="let company of loadSource.saveIn.yellowPages; let first = first">
                          <ng-container *ngIf="!first">
                            <a [href]="company.profileUrl" target="_blank"
                              ><span translate>Yellow Pages Profile</span></a
                            >
                          </ng-container>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ddiq-accordion>
              </div>
            </div>
          </div>
        </div>
        <!-- end sources sub-section-body -->
      </div>

      <!-- end sources sub-section -->
    </div>

    <div id="company_references_webContent">
      <strong class="push-down">Web Content</strong>
      <ddiq-incidents-group
        [idPrefix]="section.metadata.sectionId"
        [groups]="section.data.contentEventGroups"
        (sortDiscoveries)="sortDiscoveries($event.sortName, $event.eventGroup)"
        [isDisplayCreatedDate]="entityAware.isDeltaReport"
        [expandAll]="isExpandAllGroups">
      </ddiq-incidents-group>
    </div>
  </ddiq-accordion-top>
</div>
<div *ngIf="section.metadata.filtered" ddiq-pdf-load-complete></div>
