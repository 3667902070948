<ddiq-monitor-seed-data
  [seedData]="report.seedData"
  [entityType]="report.entityType"
  [profileLink]="report.profileLink"
  [submissionId]="report.submissionId">
  <ddiq-monitor-hits-count [monitorHitTotal]="report.monitorHitTotal"></ddiq-monitor-hits-count>
</ddiq-monitor-seed-data>

<div class="monitor-content">
  <ddiq-monitor-hits-header
    [latestMatchDate]="report.oiqLastUpdatedDate"
    [lastRunDate]="report.monitor.lastRunAt"
    [ownerName]="report.oiqOwnerFullName"
    [isGovIdEnabled]="isGovIdSearchEnabled"
    [idNumbers]="report.seedData.identificationNumbers"></ddiq-monitor-hits-header>

  <div class="monitor-hit-items-container">
    <div *ngFor="let hit of report.hits | slice:0:5">
      <ng-container [ngSwitch]="true">
        <ddiq-watch-list
          *ngSwitchCase="hit.type ==='company_watchlist' || hit.type === 'person_watchlist'"
          [watchList]="hit.data"
          [entityType]="report.entityType"
          [entityId]="report.oiqEntityId"
          [noReassess]="true"
          [isAdjudicateable]="report.isAdjudicatableByUser"
          [displayCreatedDate]="true"
          [isDisplayClassifications]="true"></ddiq-watch-list>
        <ddiq-regulatory-profile
          *ngSwitchCase="hit.type ==='regulatory_profile'"
          [profile]="hit.data"
          [entityType]="report.entityType"
          [entityId]="report.oiqEntityId"
          [noReassess]="true"
          [isAdjudicateable]="report.isAdjudicatableByUser"
          [displayCreatedDate]="true"
          [isDisplayClassifications]="true"></ddiq-regulatory-profile>
        <ddiq-website-scorecard
          *ngSwitchCase="hit.type ==='adverse_websitescorecard'"
          [scorecard]="hit.data"
          [entityType]="report.entityType"
          [entityId]="report.oiqEntityId"
          [noReassess]="true"
          [isAdjudicateable]="report.isAdjudicatableByUser"
          [displayCreatedDate]="true"
          [isDisplayClassifications]="true"></ddiq-website-scorecard>
        <ddiq-incident
          *ngSwitchDefault
          [incident]="hit.data"
          [classification]="hit.data.summary[0].labelKey"
          [entityType]="report.entityType"
          [entityId]="report.oiqEntityId"
          [noReassess]="true"
          [isAdjudicateable]="hit.data.adjudicable && report.isAdjudicatableByUser"
          [displayCreatedDate]="true"
          [isDisplayClassifications]="true"
          [showArticleCacheLinks]="showArticleCacheLinks"
          [showTranslatedSnippets]="showTranslatedSnippets"></ddiq-incident>
      </ng-container>
    </div>
  </div>
  <button
    *ngIf="report.monitorHitTotal > 5"
    (click)="showDeltaReport(report.profileLink)"
    type="button"
    class="btn btn-primary btn-sm title-button no-print">
    +{{report.monitorHitTotal - 5}} more
  </button>
</div>
