<div id="delegate">
  <div class="modal-title">Assign Profile</div>

  <div class="modal-content center-block">
    <p class="alert alert-danger pushdown" *ngIf="errorMsg">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <span *ngIf="errorMsg === 'OWNER_CHANGE_CONFLICT_ERROR'">
        The profile for which you were trying to change the owner has changed since it was last loaded.<br />
        The profile has been updated with the current owner information.
      </span>
      <span *ngIf="errorMsg !== 'OWNER_CHANGE_CONFLICT_ERROR'">
        An unexpected error occurred while trying to assign this profile.
      </span>
    </p>
    <p class="alert alert-warning pushdown" *ngIf="isMonitored">
      This profile is monitored. It can only be assigned to users able to monitor profiles.
      <br />
      The following listed users can monitor profiles:
    </p>
    <div class="clearfix pushdown">
      <div class="input">
        <label class="inline">Search:</label>
        <div class="inline">
          <input type="text" [(ngModel)]="userSearch" />
        </div>
      </div>
      <div class="input">
        <label>{{'Select User' | translatef}}</label>
        <select size="7" [(ngModel)]="selectedUser">
          <option *ngFor="let user of filterUsers()" [ngValue]="user.username">
            {{user.fullName}} ({{user.emailAddress}})
          </option>
        </select>
      </div>
    </div>
    <div>
      <span
        >This action will assign this profile to the selected user. <br /><br />
        Any interactions with this profile that generate email notifications will send them to the new owner.
      </span>
    </div>
  </div>

  <div class="center push-down pushdown clr">
    <button type="button" (click)="assign();" [disabled]="isDisabled()" class="btn btn-primary">Assign</button>
    <button type="button" (click)="cancel();" class="btn btn-default">Cancel</button>
  </div>
</div>
