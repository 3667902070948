import { Injectable, OnInit } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import Entity from '../common-services/entity.service';
import DateFormatPipe from '../ddiq-filters/date-format.pipe';

@Injectable({
  providedIn: 'root',
})
export default class ProfileRefreshService implements OnInit {
  constructor(private entity: Entity, private urlGenerator: UrlGenerator, private dateFormatPipe: DateFormatPipe) {}

  profileRefreshData;

  ngOnInit() {
    this.profileRefreshData = {
      versionOptions: [],
      comparisonDates: [],
      isProfileRefreshDelta: false,
      isScheduleActive: false,
      isInProgress: false,
    };
  }

  hasVersions() {
    return this.profileRefreshData && this.profileRefreshData.versionOptions?.length > 0;
  }

  getVersions() {
    return this.profileRefreshData.versionOptions;
  }

  updateProfileRefreshData(response) {
    let comparisonDates = [];
    this.profileRefreshData = response || {};
    this.profileRefreshData.isProfileRefreshDelta = false;
    (this.profileRefreshData.versions || []).forEach((version) => {
      if (version.oiqEntityId === this.entity.getId()) {
        this.profileRefreshData.isProfileRefreshDelta = true;
        comparisonDates.push(version.currentVersionRunDate);
        comparisonDates.push(version.previousVersionRunDate);
      }
    });
    this.profileRefreshData.comparisonDates = comparisonDates;

    this.profileRefreshData.versionOptions = (this.profileRefreshData.versions || []).map((version) => {
      let reportUrl = this.urlGenerator.generateReport(version.oiqEntityId, this.entity.getType(), version.oiqEntityId);
      return {
        label:
          'Version ' +
          version.versionId +
          ' - ' +
          this.dateFormatPipe.transform(version.currentVersionRunDate) +
          (version.seedDataUpdate ? ' *' : ''),
        value: reportUrl.replace('#', ''),
        id: version.oiqEntityId,
      };
    });
  }

  isProfileRefreshActive() {
    return (this.profileRefreshData || {}).isScheduleActive;
  }

  isProfileRefreshScheduled() {
    return (this.profileRefreshData || {}).isScheduleActive && !(this.profileRefreshData || {}).isInProgress;
  }

  isProfileRefreshRunning() {
    return (this.profileRefreshData || {}).isInProgress && (this.profileRefreshData || {}).status !== 'NEW';
  }

  isProfileRefreshDelta() {
    return this.profileRefreshData && this.profileRefreshData.isProfileRefreshDelta;
  }

  isProfileRefreshScheduledOnlyOnce() {
    return (
      this.profileRefreshData &&
      this.profileRefreshData.schedule &&
      this.profileRefreshData.schedule.frequency === 'ONCE'
    );
  }

  getRefreshRunStartDate() {
    let dates = this.profileRefreshData.comparisonDates;
    if (dates) {
      if (dates[0]) {
        return dates[0];
      }
      return this.profileRefreshData.schedule ? this.profileRefreshData.schedule.from : new Date();
    }
    return new Date();
  }

  getRefreshRunCompleteDate() {
    let dates = this.profileRefreshData.comparisonDates;
    return dates ? dates[1] : new Date();
  }

  getRootEntityUrl() {
    if (this.profileRefreshData && this.profileRefreshData.baselineProfileKey) {
      let baselineProfileKey = this.profileRefreshData.baselineProfileKey.key;
      return this.urlGenerator.generateReport(baselineProfileKey, this.entity.getType(), baselineProfileKey);
    }
  }

  isScheduledProfile() {
    return this.profileRefreshData && this.profileRefreshData.status && this.profileRefreshData.status === 'NEW';
  }

  getRefreshScheduledOn() {
    return this.profileRefreshData && this.profileRefreshData.schedule
      ? this.profileRefreshData.schedule.from
      : new Date();
  }

  getNextScheduledRun() {
    if (this.profileRefreshData && this.profileRefreshData.versions) {
      let v;
      for (v = 0; v < this.profileRefreshData.versions.length; v++) {
        if (
          this.profileRefreshData.versions[v].status === 'NEW' ||
          this.profileRefreshData.versions[v].status === 'SCHEDULED' ||
          this.profileRefreshData.versions[v].status === 'SUBMITTED'
        ) {
          return this.profileRefreshData.versions[v].nextRun;
        }
      }
    }
    return this.profileRefreshData ? this.profileRefreshData.nextRun : new Date();
  }

  getRefreshFrequency() {
    return this.profileRefreshData && this.profileRefreshData.schedule
      ? this.profileRefreshData.schedule.frequency
      : 'ONCE';
  }

  isLatestVersionForEntity(entityId) {
    return this.hasVersions() && entityId === this.getVersions().slice(-1)[0].id;
  }
}
