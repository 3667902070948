import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import DdiqBuildPersonComponent from './ddiq-build-person.component';
import BuildPersonResolverService from './build-person-resolver.service';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ProfileCollectionModule } from '../profile-collection/profile-collection.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import OiqNavModule from '../nav/oiq-nav.module';
import { DdiqConfirmationDialogModule } from '../confirmation-dialog/ddiq-confirmation-dialog.module';
import { ProfileCollectionPermissionMissingForSubmissionModule } from '../profile-collection/ddiq-profile-collection-missing-permission-for-submission.module';
import { PersonProfileFormsModule } from '../person-profile-forms/person-profile-forms.module';
import { DdiqMonitorModule } from '../monitor/oiq-monitor.module';

@NgModule({
  imports: [
    CommonModule,
    DdiqUpgradeModule,
    FormsModule,
    PipesModule,
    ProfileCollectionModule,
    ThemedEntityModule,
    OiqNavModule,
    DdiqConfirmationDialogModule,
    ProfileCollectionPermissionMissingForSubmissionModule,
    PersonProfileFormsModule,
    DdiqMonitorModule,
  ],
  providers: [BuildPersonResolverService],
  declarations: [DdiqBuildPersonComponent],
})
export class DdiqBuildPersonModule {}
