// profile collections utils
export default function profileCollectionsParams() {
  // Here, the count is set to the max integer 2147483647 to get all matching profile collections since the corresponding api
  // returns a paginated response. The current default value is 10 in the backend api. We can update the backend api with the
  // reasonal default value for count and remove count from here once profile collection feature is enabled in production.
  const params = {
    start: 0,
    count: 2147483647,
    needAggregates: false,
    entityType: '',
    entityId: '',
    currentUserPermissions: '',
  };
  return {
    withEntityType(entityType) {
      params.entityType = entityType;
      return this;
    },

    withEntityId(entityId) {
      params.entityId = entityId;
      return this;
    },

    withUserPermissions(userPermissions) {
      params.currentUserPermissions = userPermissions;
      return this;
    },

    build() {
      return params;
    },
  };
}
