import { DatePipe } from '@angular/common';
import { Component, DoCheck, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import TranslateService from '../common-services/translate.service';
import Monitor from '../monitor/monitor.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import ExclusionsService from './exclusions.service';

@Component({
  selector: 'ddiq-exclusion-management-modal',
  templateUrl: './ddiq-exclusion-management-modal.component.tpl.html',
})
export default class ExclusionManagementModalComponent implements OnInit, DoCheck {
  constructor(
    private apiFetchService: ApiFetchService,
    private oiqProperties: OiqProperties,
    private exclusionsService: ExclusionsService,
    private modalTrackingService: ModalTrackingService,
    private translateService: TranslateService,
    private entity: Entity,
    private monitor: Monitor,
    private datePipe: DatePipe
  ) {}

  updating: boolean;
  retrievalError: boolean;
  error;
  selections;
  isMonitorDeltaReport: boolean;
  changedValues: boolean;
  previousOpen: boolean = false;

  ngOnInit(): void {
    this.updating = false;
    this.retrievalError = false;
    this.error = null;
    this.selections = {
      RECORD_CLUSTER: [],
      WATCHLIST: [],
      REGULATORY: [],
      WEB_CONTENT: [],
    };
  }

  ngDoCheck() {
    if (
      this.modalTrackingService.getTriggers().exclusionManagement.open &&
      this.modalTrackingService.getTriggers().exclusionManagement.open !== this.previousOpen
    ) {
      this.previousOpen = this.modalTrackingService.getTriggers().exclusionManagement.open;
      this.apiFetchService.getExclusions(this.entity.getType(), this.entity.getId(), this.monitor.isDeltaReport()).then(
        (data) => this.init(data),
        (data) => this.exclusionsRetrievalFailure(data)
      );
    }
  }

  clear() {
    this.updating = false;
    this.retrievalError = false;
    this.error = null;
    this.selections = {
      RECORD_CLUSTER: [],
      WATCHLIST: [],
      REGULATORY: [],
      WEB_CONTENT: [],
    };
    this.modalTrackingService.getTriggers().exclusionManagement.open = false;
  }

  updateExclusions() {
    var exclusions = {
        RECORD_CLUSTER: [],
        WATCHLIST: [],
        REGULATORY: [],
        WEB_CONTENT: [],
      },
      selections = this.selections;

    this.updating = true;

    Object.keys(selections).forEach((exclusionType) => {
      if (Array.isArray(selections[exclusionType])) {
        selections[exclusionType].forEach((exclusion) => {
          if (exclusion.action) {
            exclusions[exclusionType].push({
              action: exclusion.action,
              id: exclusion.id,
              exclusion: exclusion.type,
              expiry: this.datePipe.transform(exclusion.expiry, 'yyyy-MM-dd'),
            });
          }
        });
      }
    });

    this.apiFetchService
      .updateExclusions(this.entity.getType(), this.entity.getId(), this.monitor.isDeltaReport(), exclusions)
      .then(
        () => this.updateExclusionsSuccess(),
        (data) => this.updateExclusionsFailure(data)
      );
  }

  updateExclusionsSuccess() {
    this.clear();
  }

  updateExclusionsFailure(response) {
    this.retrievalError = true;
    this.error = response;
  }

  cancel() {
    this.clear();
  }

  isExpired(exclusion) {
    return exclusion.expiry && exclusion.expiry < new Date();
  }

  updateExclusion(exclusion) {
    if (exclusion.id) {
      if (!exclusion.selected) {
        exclusion.action = 'REMOVE';
        exclusion.expiry = null;
      } else {
        exclusion.action = 'UPDATE';
        exclusion.expiry = this.getExpiry();
      }
    } else if (exclusion.selected) {
      exclusion.action = 'ADD';
      exclusion.expiry = this.getExpiry();
    } else {
      exclusion.action = null;
      exclusion.expiry = null;
    }
  }

  getExpiry() {
    var expiryPeriod = this.monitor.isDeltaReport()
        ? this.oiqProperties.monitoringDefaultExclusionPeriod
        : this.oiqProperties.profileRefreshDefaultExclusionPeriod,
      result = new Date();
    result.setDate(result.getDate() + expiryPeriod);
    return result;
  }

  init(data) {
    var key,
      profileHits,
      handledTypes = [];
    Object.keys(data).forEach((exclusionType) => {
      if (data[exclusionType] && data[exclusionType].length) {
        data[exclusionType].forEach((exclusion) => {
          this.selections[exclusionType].push({
            type: exclusion.exclusion,
            translation: this.translateService.translate(exclusion.exclusion),
            selected: true,
            id: exclusion.id,
            expiry: new Date(exclusion.expiry),
          });
          handledTypes.push(exclusionType + '|' + exclusion.exclusion);
        });
      }
    });
    profileHits = this.exclusionsService.getProfileHits();
    if (profileHits) {
      Object.keys(profileHits).forEach((exclusionType) => {
        if (profileHits[exclusionType] && profileHits[exclusionType].length) {
          profileHits[exclusionType].forEach((exclusion) => {
            key = exclusionType + '|' + exclusion;
            if (handledTypes.indexOf(key) === -1) {
              this.selections[exclusionType].push({
                type: exclusion,
                translation: this.translateService.translate(exclusion),
                selected: false,
              });
              handledTypes.push(key);
            }
          });
        }
      });
    }
    this.isMonitorDeltaReport = this.monitor.isDeltaReport();
  }

  exclusionsRetrievalFailure(response) {
    this.retrievalError = true;
    this.error = response;
  }
}
