import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import CheckContentService from '../common-services/check-content.service';
import NavTrackingService from '../nav/nav-tracking.service';
import Filter from './filter.service';

@Component({
  selector: 'ddiq-profile-nav',
  templateUrl: 'ddiq-profile-nav.component.tpl.html',
})
export default class ProfileNavComponent implements OnInit, OnDestroy {
  private deregister: Subscription;
  isFiltered: boolean;
  isShowFilters: boolean;
  eventNameSearch: string;
  @Input() sections;

  constructor(
    private messageBusService: MessageBusService,
    private checkContentService: CheckContentService,
    private filter: Filter,
    private navTrackingService: NavTrackingService
  ) {}

  ngOnInit() {
    this.deregister = this.messageBusService.on('event:profile-filtered', (isFiltered) => {
      this.isFiltered = isFiltered;
    });
  }

  ngOnDestroy() {
    this.deregister.unsubscribe();
  }

  showSectionNav(sections: any[]): any[] {
    return sections.filter((section) => {
      if (!section.metadata) {
        return false;
      }

      // not explicitly false -- blank means display
      return section.metadata.showInSectionNav !== false && !section.metadata.filtered;
    });
  }

  sectionHasContent(section) {
    return this.checkContentService.checkForContent(section.data, true);
  }

  getEventCountBadgeText(sectionId, label) {
    return this.getEventCounts(sectionId, label).notAdjudicatedAndNotFilteredCount;
  }

  getEventCounts(sectionId, label) {
    return this.filter.getEventCounts(sectionId, label.labelKey, label.kind);
  }

  getEventCountBadgeTitleText(sectionId, label) {
    const filterInfo = this.getEventCounts(sectionId, label);

    return (
      filterInfo.adjudicatedCount +
      ' cleared, ' +
      filterInfo.notAdjudicatedAndNotFilteredCount +
      ' ' +
      ' active, ' +
      filterInfo.filteredOutCount +
      ' ' +
      ' filtered'
    );
  }

  trackById(section, index, label) {
    return section.metadata.sectionId + '_' + label.labelKey;
  }

  sectionFilter(section) {
    const filterSections = this.filter.getSections();

    for (let i = 0; i < filterSections.length; i++) {
      const filterSectionNameIsSameAsSectionId = filterSections[i].name === section.metadata.sectionId;
      const filterSectionNameIsOther = filterSections[i].name === 'other';
      const sectionIdIsReferences = section.metadata.sectionId === 'references';

      if (filterSectionNameIsSameAsSectionId || (filterSectionNameIsOther && sectionIdIsReferences)) {
        return filterSections[i];
      }
    }

    return {
      labels: [],
    };
  }

  toggleFilterSection() {
    this.isShowFilters = !this.isShowFilters;
  }

  classificationClick(label, sectionId) {
    this.navTrackingService.setEventActive(label, sectionId);
  }

  isEventActive(eventLabel, sectionId) {
    return this.navTrackingService.isEventActive(eventLabel, sectionId);
  }
}
