import { Component, Inject, OnInit, OnDestroy, Input } from '@angular/core';
import oiqAdjudicationAware from '../adjudication/oiq-adjudication-aware.directive';
import CollectionListChangeNotificationService from '../common-services/collection-list-change-notification.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import PdfFeatureService from '../pdf/pdf-feature.service';

@Component({
  selector: 'seed-data-tabs',
  templateUrl: './seed-data-tabs.component.tpl.html',
})
export default class SeedDataTabs implements OnInit, OnDestroy {
  @Input() seedData: any;
  @Input() section: any;
  @Input() hideComments: boolean;

  isPerson: boolean;
  isCompany: boolean;
  entityId: any;
  entityType: string;
  isCommentDisabled: boolean;
  isShowComment: boolean;
  isProfileCollectionEnabled: boolean;
  isAdjudicationEnabled: boolean;
  canAdjudicate: boolean;
  isAdjudicatable: boolean;
  curTab: number = 1;
  collectionChangeSubscription = null;

  constructor(
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private pdfFeature: PdfFeatureService,
    private oiqAdjudicationAware: oiqAdjudicationAware,
    private collectionListChangeNotificationService: CollectionListChangeNotificationService
  ) {}

  ngOnInit(): void {
    this.isPerson = this.entity.isPerson();
    this.isCompany = this.entity.isCompany();

    this.entityId = this.entity.getId();
    this.entityType = this.entity.getType();

    this.isCommentDisabled = this.entity.isInPreview();
    this.isShowComment = this.pdfFeature.showCaseComments();
    this.isProfileCollectionEnabled = this.oiqProperties.isProfileCollectionEnabled;
    this.isAdjudicationEnabled = this.oiqAdjudicationAware.isAdjudicationEnabled();
    this.canAdjudicate = this.oiqAdjudicationAware.canAdjudicate();
    this.isAdjudicatable =
      !!(this.entity.getData() || {}).singleClassificationModel && this.entity.isAdjudicatableByUser();

    if (this.collectionListChangeNotificationService.collectionList) {
      this.collectionChangeSubscription = this.collectionListChangeNotificationService.collectionList.subscribe(
        (value) => {
          if (value.unassignedProfileCollections) {
            value.unassignedProfileCollections.forEach((unassignedCollection) => {
              let index = this.seedData.collections
                .map((collection) => collection.profileCollectionKey)
                .indexOf(unassignedCollection.profileCollectionKey);
              if (index !== -1) {
                this.seedData.collections.splice(index, 1);
              }
            });
          }
          if (value.assignedProfileCollections) {
            value.assignedProfileCollections.forEach((assignedCollection) => {
              if (
                !this.seedData.collections
                  .map((collection) => collection.profileCollectionKey)
                  .includes(assignedCollection.profileCollectionKey)
              ) {
                this.seedData.collections.push(assignedCollection);
              }
            });
          }
        }
      );
    }
  }

  editComment() {
    this.oiqAdjudicationAware.editComment();
  }

  ngOnDestroy() {
    if (this.collectionChangeSubscription) {
      this.collectionChangeSubscription.unsubscribe();
    }
  }
}
