import { OnInit, DoCheck, Component } from '@angular/core';
import moment from 'moment';
import _ from 'underscore';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import ApiFetchService from '../../oiq/common-services/api-fetch.service';
import TabsData from '../../oiq/profile-library/tabs-data.service';
import EntityCache from '../common-services/entity-cache.service';
import OiqProperties from '../common-services/oiq-properties.service';
import ProgressChecker from '../common-services/progress-checker.service';
import UserService from '../user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'in-progress-abstract-component',
  template: '',
})
export default class InProgressProfileComponent implements OnInit, DoCheck {
  canSearchReqOrg: boolean;
  pagination: { [key: string]: any };
  pagingParams: { [key: string]: any };
  buildingEntities: { [key: string]: any };
  previousBuildingEntitiesResult: { [key: string]: any };
  profileProgress: any;
  profileQueued: any;
  isProfileCollectionEnabled: boolean;

  constructor(
    protected messageBusService: MessageBusService,
    protected apiFetchService: ApiFetchService,
    protected tabsData: TabsData,
    protected oiqProperties: OiqProperties,
    protected urlGenerator: UrlGenerator,
    protected progressChecker: ProgressChecker,
    protected entityCache: EntityCache,
    protected user: UserService,
    protected router: Router
  ) {
    this.canSearchReqOrg = oiqProperties.reqOrgEnabled;
    this.isProfileCollectionEnabled = oiqProperties.isProfileCollectionEnabled;
    this.pagination = {
      current: 1,
      total: 5,
      visible: 7,
    };

    this.pagingParams = {
      start: 0,
      count: 10,
    };
  }

  ngOnInit() {
    this.buildingEntities =
      this.progressChecker.addProgressChecker(
        'inProgressProfiles',
        this.apiFetchService.inProgressProfiles,
        false,
        this.getParams()
      ) || [];
    this.previousBuildingEntitiesResult = { ...this.buildingEntities };

    this.progressChecker.repeatedProgressCheck();
  }

  ngDoCheck() {
    const newResults = this.buildingEntities;
    const oldResults = this.previousBuildingEntitiesResult;

    const newCount = newResults.data.queuedCount + newResults.data.startedCount || 0;
    const oldCount = oldResults.data.queuedCount + oldResults.data.startedCount || 0;

    if (newCount !== oldCount) {
      this.trackCounts(newResults.data);

      this.updateInProgressCount();
      this.evictCachedPreviewEntities(newResults.results, oldResults.results);
    }

    this.updatePreviewUrl(newResults.results);
    this.previousBuildingEntitiesResult = { ...newResults };
  }

  getParams() {
    return () => this.pagingParams;
  }

  buildingProfiles() {
    return this.buildingEntities.results;
  }

  /**
   * High (JMSPriority > 4 && <= 9)
   * Default (JMSPriority == 4)
   * Low (JMSPriority > 0 && < 4)
   */
  getPriorityClass(priority) {
    if (priority > 4) {
      return 'PRIORITY_HIGH';
    }

    if (priority < 4) {
      return 'PRIORITY_LOW';
    }

    return 'PRIORITY_DEFAULT';
  }

  getDuration(profile) {
    if (profile.status === 'queued') {
      return '';
    }

    return moment.duration(profile.duration).humanize();
  }

  showPreviewLink(profile) {
    return (
      this.oiqProperties.previewEnabled &&
      profile.previewEnabled &&
      (profile.status === 'started' || profile.status === 'rootFinished' || profile.status === 'failed')
    );
  }

  getProfileProgress(profile) {
    let startedCount = 1;
    let finishedCount = 0;

    if ((profile.status === 'started' || profile.status === 'rootFinished') && profile.subCrawlsStarted > 0) {
      startedCount = profile.subCrawlsStarted;
      finishedCount = profile.subCrawlsFinished || 1;
    } else if (profile.status !== 'queued') {
      finishedCount = 1;
    }

    return finishedCount + ' of ' + startedCount;
  }

  updatePreviewUrl(results) {
    (results || []).forEach((result) => {
      result.profileLink = this.urlGenerator.generateReport(
        result.oiqEntityId,
        result.entityType.toLowerCase(),
        result.oiqEntityId
      );
      result.oiqOwnerFullName = this.user.getVisibleUser(result.oiqOwner).fullName;
    });
  }

  trackCounts(data) {
    this.messageBusService.send({ type: 'event:updatedProgress' });

    this.profileProgress = data.startedCount;
    this.profileQueued = data.queuedCount;
  }

  updateInProgressCount() {
    this.tabsData.counts.activeCount = this.profileProgress;
    this.tabsData.counts.totalCount = this.profileProgress + this.profileQueued;

    this.pagination.numPages = Math.ceil(this.tabsData.counts.totalCount / 10);
    this.pagination.totalCount = this.tabsData.counts.totalCount;
  }

  evictCachedPreviewEntities(newResults, oldResults) {
    (oldResults || []).forEach((previous) => {
      if (!_.find(newResults || [], this.byEntityId(previous))) {
        this.entityCache.invalidate(previous.oiqEntityId);
      }
    });
  }

  byEntityId(entity) {
    return function (current) {
      return entity.oiqEntityId === current.oiqEntityId;
    };
  }

  // Links don't work with ng-disabled
  gotoLibrary() {
    this.tabsData.activeTab = 2;
    this.router.navigate(['/profiles']);
  }

  onSelectPage(page) {
    if (this.pagination.current !== page) {
      this.pagingParams.start = 10 * (page - 1);

      this.apiFetchService.inProgressProfiles(this.pagingParams).then(
        (data) => {
          this.buildingEntities.data = data;
          this.buildingEntities.results = data.results;
        },
        (data) => console.log(data)
      );

      this.pagination.current = page;
    }
  }
}
