import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import AllowedHttpRequests from './csrf-security.enum';
@Injectable()
export class CsrfSecurityInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpMethod = req.method;

    if (AllowedHttpRequests[httpMethod]) {
      const authReq = req.clone({
        headers: req.headers.set('X-Requested-With', 'ajax'),
      });

      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
