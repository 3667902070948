import { Injectable } from '@angular/core';
import PdfFeatureService from '../pdf/pdf-feature.service';

const entityDiscoveries = [
  'licenses',
  'other',
  'legalActivities',
  'legal',
  'oshaSafetyInspections',
  'noteworthy',
  'adverse',
  'regulatoryProfiles',
  'regulatory',
  'watchLists',
  'corporateRecords',
  'websiteScorecards',
];

@Injectable({
  providedIn: 'root',
})
export default class EntityPreprocessor {
  constructor(private pdfFeature: PdfFeatureService) {}

  process(entity) {
    if (this.pdfFeature.excludeAutoAdjudicated()) {
      return excludeAutoAdjudicated(entity, entityDiscoveries);
    }
  }
}

function excludeAutoAdjudicated(entity, discoveryList) {
  discoveryList.forEach(function (discoveryGroup) {
    //All else of structured
    if (Array.isArray(entity[discoveryGroup])) {
      if (!entity[discoveryGroup].length) {
        return;
      }
      entity[discoveryGroup] = entity[discoveryGroup].filter(isNotAutoAdjudicated);
    }
    //Incidents, WatchLists, regulatoryProfiles
    else if (typeof entity[discoveryGroup] === 'object') {
      Object.keys(entity[discoveryGroup]).forEach(function (classifications) {
        entity[discoveryGroup][classifications] = entity[discoveryGroup][classifications].filter(function (discovery) {
          var result;
          if (discovery.adjudication) {
            result = isNotAutoAdjudicated(discovery);
          } else {
            result = discovery.summary ? discovery.summary.some(isNotAutoAdjudicated) : true;
          }
          return result;
        });
      });
    }
  });

  function isNotAutoAdjudicated(discovery) {
    return discovery.adjudication ? !discovery.adjudication.auto : true;
  }
}
