import { Injectable } from '@angular/core';
import { CorporateRecordEventGroupsService } from '../common/corporate-record-event-groups.service';
import InmateProfileEventGroupsService from '../common/inmate-profile-event-groups.service';
import LegalFilingEventGroupsService from '../common/legal-filing-event-groups.service';
import { OshaInspectionEventGroupsService } from '../common/osha-inspection-event-groups.service';
import { ProductRecallEventGroupsService } from '../common/product-recall-event-groups.service';
import ProfessionalLicenseEventGroupsService from '../common/professional-license-event-groups.service';
import RegulatoryProfileEventGroupsService from '../common/regulatory-profile-event-groups.service';
import { WatchListEventGroupsService } from '../common/watch-list-event-groups.service';
import { WebContentEventGroupsService } from '../common/web-content-event-groups.service';
import IncidentsUtils from '../themed-entity/incidents-utils.service';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';
import WebsiteScorecardEventGroupsService from '../common/website-scorecard-event-groups.service';

function cloneEventGroup(eventGroup) {
  const copy = Object.assign({}, eventGroup);
  copy.discoveries = [...eventGroup.discoveries];
  return copy;
}

function sortEventGroups(config) {
  if (!config.sorter) return;

  const eventGroups = config.section.data[config.eventGroupsName];
  const index = eventGroups.list.indexOf(config.sort.eventGroup);
  const copy = cloneEventGroup(config.sort.eventGroup);

  copy.activeSortButtons = config.sort.sortName;
  config.sorter(copy.discoveries);

  eventGroups.list[index] = copy;
}

@Injectable({ providedIn: 'root' })
class EventGroupsService {
  contentSorts: any;
  eventGroupSorts: any;
  toggleHandlerLookup: any;
  structuredDataSorts: any;

  constructor(
    private webContentEventGroupsService: WebContentEventGroupsService,
    private productRecallEventGroupsService: ProductRecallEventGroupsService,
    private oshaInspectionEventGroupsService: OshaInspectionEventGroupsService,
    private websiteScorecardEventGroupsService: WebsiteScorecardEventGroupsService,
    private corporateRecordEventGroupsService: CorporateRecordEventGroupsService,
    private regulatoryProfileEventGroupsService: RegulatoryProfileEventGroupsService,
    private inmateProfileEventGroupsService: InmateProfileEventGroupsService,
    private professionalLicenseEventGroupsService: ProfessionalLicenseEventGroupsService,
    private legalFilingEventGroupsService: LegalFilingEventGroupsService,
    private watchListEventGroupsService: WatchListEventGroupsService,
    private incidentsUtils: IncidentsUtils,
    private StructuredDataSorts: StructuredDataSorts
  ) {
    this.contentSorts = {
      association(discoveries) {
        incidentsUtils.sortByAssociation(discoveries);
      },
      date(discoveries) {
        incidentsUtils.sortByDate(discoveries);
      },
      adjudication(discoveries) {
        incidentsUtils.sortByAdjudicated(discoveries);
      },
    };

    this.structuredDataSorts = {
      association(discoveries) {
        StructuredDataSorts.sortByAssociation(discoveries);
      },
      adjudication(discoveries) {
        StructuredDataSorts.sortByAdjudication(discoveries);
      },
    };

    this.eventGroupSorts = {
      content: (section, sort) => {
        const eventGroupsName = this.webContentEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.contentSorts[sort.sortName] });
      },
      corporateRecords: (section, sort) => {
        const eventGroupsName = this.corporateRecordEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      regulatoryProfiles: (section, sort) => {
        const eventGroupsName = this.regulatoryProfileEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      oshaSafetyInspections: (section, sort) => {
        const eventGroupsName = this.oshaInspectionEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      websiteScorecards: (section, sort) => {
        const eventGroupsName = this.websiteScorecardEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      inmateProfiles: (section, sort) => {
        const eventGroupsName = this.inmateProfileEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      professionalLicenses: (section, sort) => {
        const eventGroupsName = professionalLicenseEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      legalActivities: (section, sort) => {
        const eventGroupsName = legalFilingEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
      watchLists: (section, sort) => {
        const eventGroupsName = watchListEventGroupsService.eventGroupsName;
        sortEventGroups({ section, eventGroupsName, sort, sorter: this.structuredDataSorts[sort.sortName] });
      },
    };

    this.toggleHandlerLookup = {
      incidents: () => this.webContentEventGroupsService.toggleEventGroups(),
      corporaterecords: () => this.corporateRecordEventGroupsService.toggleEventGroups(),
      regulatoryprofiles: () => this.regulatoryProfileEventGroupsService.toggleEventGroups(),
      oshasafetyinspections: () => this.oshaInspectionEventGroupsService.toggleEventGroups(),
      websitescorecards: () => this.websiteScorecardEventGroupsService.toggleEventGroups(),
      inmateprofiles: () => this.inmateProfileEventGroupsService.toggleEventGroups(),
      professionallicenses: () => this.professionalLicenseEventGroupsService.toggleEventGroups(),
      legalactivities: () => this.legalFilingEventGroupsService.toggleEventGroups(),
      watchlists: () => this.watchListEventGroupsService.toggleEventGroups(),
      productrecalls: () => this.productRecallEventGroupsService.toggleEventGroups(),
    };
  }

  getEventGroupBuildHandlers() {
    return [
      this.webContentEventGroupsService.addEventGroupsToSection(),
      this.corporateRecordEventGroupsService.addEventGroupsToSection(),
      this.regulatoryProfileEventGroupsService.addEventGroupsToSection(),
      this.oshaInspectionEventGroupsService.addEventGroupsToSection(),
      this.websiteScorecardEventGroupsService.addEventGroupsToSection(),
      this.inmateProfileEventGroupsService.addEventGroupsToSection(),
      this.professionalLicenseEventGroupsService.addEventGroupsToSection(),
      this.productRecallEventGroupsService.addEventGroupsToSection(),
      this.legalFilingEventGroupsService.addEventGroupsToSection(),
      this.watchListEventGroupsService.addEventGroupsToSection(),
    ];
  }

  getEventGroupRefreshHandlers() {
    return [
      this.webContentEventGroupsService.refreshEventGroupsOnSection(),
      this.corporateRecordEventGroupsService.refreshEventGroupsOnSection(),
      this.regulatoryProfileEventGroupsService.refreshEventGroupsOnSection(),
      this.oshaInspectionEventGroupsService.refreshEventGroupsOnSection(),
      this.websiteScorecardEventGroupsService.refreshEventGroupsOnSection(),
      this.inmateProfileEventGroupsService.refreshEventGroupsOnSection(),
      this.professionalLicenseEventGroupsService.refreshEventGroupsOnSection(),
      this.productRecallEventGroupsService.refreshEventGroupsOnSection(),
      this.legalFilingEventGroupsService.refreshEventGroupsOnSection(),
      this.watchListEventGroupsService.refreshEventGroupsOnSection(),
    ];
  }

  getEventGroupSorts() {
    return this.eventGroupSorts;
  }

  getToggleHandlersForKinds(eventGroupKinds) {
    if (!Array.isArray(eventGroupKinds)) return [];

    const knownNames = eventGroupKinds.filter(
      (kind) => Object.keys(this.toggleHandlerLookup).indexOf(kind.toLowerCase()) !== -1
    );

    return knownNames.map((eventGroupKind) => {
      return this.toggleHandlerLookup[eventGroupKind.toLowerCase()]();
    });
  }
}

export default EventGroupsService;
