import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
})
export default class AddressPipe implements PipeTransform {
  constructor() {}

  /**
   * Takes in an address object and formats into a readable string
   * @param address
   * @returns {String}
   */
  transform(address: any) {
    if (!address) return '';

    if (address.rawAddress) {
      return address.rawAddress;
    }

    var singleLineAddress = '';

    if (address.streetAddress) {
      singleLineAddress += address.streetAddress;
    }

    if (address.streetAddressLineTwo || address.secondLineStreetAddress) {
      if (singleLineAddress) {
        singleLineAddress += ', ';
      }
      singleLineAddress += address.streetAddressLineTwo || address.secondLineStreetAddress;
    }

    if (address.buildingName) {
      if (singleLineAddress) {
        singleLineAddress += ', ';
      }

      singleLineAddress += address.buildingName;
    }

    if (address.city) {
      if (singleLineAddress) {
        singleLineAddress += ', ';
      }

      singleLineAddress += address.city;
    }

    if (address.district) {
      if (singleLineAddress) {
        singleLineAddress += ', ';
      }

      singleLineAddress += address.district;
    }

    if (address.stateProvince || address.stateOrProvince) {
      if (singleLineAddress) {
        singleLineAddress += ', ';
      }

      singleLineAddress += address.stateProvince || address.stateOrProvince;
    }

    if (address.zipPostalCode || address.zipOrPostalCode) {
      if (singleLineAddress) {
        singleLineAddress += ' ';
      }
      singleLineAddress += address.zipPostalCode || address.zipOrPostalCode;
    }

    if (address.country) {
      if (singleLineAddress) {
        singleLineAddress += ', ';
      }

      singleLineAddress += address.country;
    }

    return singleLineAddress;
  }
}
