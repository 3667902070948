import { Injectable } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export default class Scrollbar {
  constructor(private windowService: WindowRefService) {}

  isWindows() {
    return this.windowService.nativeWindow.navigator.platform.toLowerCase().trim().indexOf('win') === 0;
  }
}
