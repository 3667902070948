import { Input, Component } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import Entity from '../common-services/entity.service';

const type = {
  ownershipData: 'ownershipDataNodesCount',
  familyTree: 'familyTreeNodesCount',
};

@Component({
  selector: 'corporate-graph-buttons',
  templateUrl: './corporate-graph-buttons.component.tpl.html',
  preserveWhitespaces: true,
})
export default class CorporateGraphButtons {
  @Input() record;

  private window: any;

  constructor(private windowRef: WindowRefService, private entity: Entity) {
    this.window = windowRef.nativeWindow;
  }

  hasGraphData(graphType) {
    return !!this.getNodeCount(graphType);
  }

  getNodeCount(graphType) {
    return this.record[type[graphType]];
  }

  goToGraph(graphType) {
    this.window.open(
      `#/corporate-graph/${this.entity.getId()}/${this.entity.getType()}/${this.record.id}/${graphType}`,
      '_blank'
    );
  }
}
