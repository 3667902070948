import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import Theme from './theme.service';

@Directive({
  selector: '[data-theme-alt]',
})
export default class ThemeAltDirective implements OnInit {
  constructor(private elementRef: ElementRef, private theme: Theme) {}

  ngOnInit() {
    let nativeElement = this.elementRef.nativeElement;
    const defaultImgAltTxt = 'DDIQ - Where all due diligence begins',
      imgAltTxt = this.theme.getAltTxt() || defaultImgAltTxt;

    nativeElement.alt = imgAltTxt;
  }
}
