import SessionTerminationMessageComponent from './session-termination-message.component';
import { Injector, NgModule } from '@angular/core';
import SessionTerminationMessageService from './session-termination-message.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import UserService from '../user/user.service';

@NgModule({
  declarations: [SessionTerminationMessageComponent],
  providers: [SessionTerminationMessageService],
  exports: [SessionTerminationMessageComponent],
})
export class SessionTerminationMessageModule {
  constructor(private injector: Injector) {}

  configure() {
    const sessionTerminationMessage: SessionTerminationMessageService = this.injector.get(
      SessionTerminationMessageService
    );
    const messageBusService: MessageBusService = this.injector.get(MessageBusService);
    const user: UserService = this.injector.get(UserService);

    if (sessionTerminationMessage.hasMessage()) {
      if (user.isInitialized()) {
        sessionTerminationMessage.startCheck();
      } else {
        messageBusService.on('event:auth-loginConfirmed', () => {
          sessionTerminationMessage.startCheck();
        });
      }
    }
  }
}
