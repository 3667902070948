import { Injectable } from '@angular/core';
import ApiFetchService from '../../../../../shared/services/http/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';

let props;

@Injectable({
  providedIn: 'root',
})
export default class CorporateGraph {
  defaultPercent: number;
  allRegions = [];

  constructor(private _OiqProperties: OiqProperties, private apiFetchService: ApiFetchService) {
    props = _OiqProperties;
    this.defaultPercent = 100;

    this.mapRegionsObject();
  }

  async mapRegionsObject() {
    const regions = await this.apiFetchService.getRegions();

    this.allRegions = regions.reduce((obj, region) => {
      obj[region.name] = region.code;
      return obj;
    }, {});
  }
  map(record) {
    const rootNode = {
        name: record.name,
        entityType: 'COMPANY',
        address: record.address,
        additionalData: record.additionalData,
      },
      graph = record.ownershipData || record.familyTree;

    graph.nodes.unshift(rootNode);

    graph.edges.forEach((link) => {
      reName(link, 'sourceNodeID', 'target');
      reName(link, 'targetNodeID', 'source');
    });

    return {
      nodes: graph.nodes,
      links: graph.edges,
    };
  }

  setIds({ nodes, links }) {
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      node.id = i;
    }

    for (let i = 0; i < links.length; i++) {
      let link = links[i];
      link.id = i;
    }
  }

  isOwnerOfRootNode(item) {
    return this.isLink(item) ? item.target === 0 || item.target.id === 0 : item.level === 0;
  }

  initPercentages({ nodes, links }) {
    const mutatedLinks = [...links];

    nodes.forEach((node) => {
      const percentages = mutatedLinks.reduceRight((percentages, link, index) => {
        if (isNodeSourceOfLink(node, link)) {
          link.percent = getPercentages(this.defaultPercent, link);

          if (this.isOwnerOfRootNode(link)) {
            node.level = 0;
            if (!Array.isArray(node.linksToRoot)) {
              node.linksToRoot = [];
            }
            node.linksToRoot.push({
              type: link.relationshipType,
              percent: link.percent,
            });
          }
          if (!isNaN(link.percent)) {
            percentages.push(link.percent);
          }

          mutatedLinks.splice(index, 1);
        }

        return percentages;
      }, []);
      node.minPercent = percentages.length ? Math.min(...percentages) : undefined;
      node.maxPercent = percentages.length ? Math.max(...percentages) : undefined;
    });
  }

  isLink(item) {
    return item.hasOwnProperty('source') && item.hasOwnProperty('target');
  }

  shallowCopy(nodes, links) {
    return { nodes: [...nodes], links: [...links] };
  }

  isGraphEnabled() {
    return props.isCorporateGraphEnabled;
  }

  isOwnershipGraph(graphType) {
    return graphType === 'ownershipData' || graphType === 'ownership';
  }

  isRootNode(item) {
    return !this.isLink(item) && item.id === 0;
  }

  isNonUsCountry(record) {
    return (
      !!countryTxtModel(record) &&
      !!(record.additionalData || []).length &&
      record.additionalData.some((data) => data.label === 'NON_US_OWNERSHIP' && !!data.value)
    );
  }

  isSanctionedCountry(record) {
    let country = countryTxtModel(record);

    if (this.allRegions[country]) {
      country = this.allRegions[country];
    }

    return country && props.sanctionedCountries.includes(country);
  }

  isHighRiskCountry(record) {
    let country = countryTxtModel(record);

    if (this.allRegions[country]) {
      country = this.allRegions[country];
    }

    return country && props.highRiskCountries.includes(country);
  }

  isNotLocalCountry(record) {
    let country = countryTxtModel(record);

    if (this.allRegions[country]) {
      country = this.allRegions[country];
    }

    return country && !props.localCountries.includes(country);
  }
}

function getPercentages(percent, link) {
  return typeof link.percentageInterest === 'undefined' ? percent : link.percentageInterest;
}

function countryTxtModel(d) {
  return (d.address || {}).country;
}

function reName(obj, oldName, newName) {
  if (oldName === newName) {
    return obj;
  }
  if ((obj || {}).hasOwnProperty(oldName)) {
    obj[newName] = obj[oldName];
    obj[newName]++;
    delete obj[oldName];
  }
  return obj;
}

function isNodeSourceOfLink(node, link) {
  return node.id === link.source || node.id === link.source.id;
}
