import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'id-submission-inputs',
  templateUrl: 'id-submission-inputs.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class IdSubmissionInputsComponent implements OnInit {
  @Input() idModel;
  @Input() idConfigs;
  @Output() onIdModelChange = new EventEmitter<any>();
  @Output() buttonClicked = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {
    if (this.isResubmit(this.idModel)) {
      if (this.isIncorrectConfig(this.idConfigs, this.idModel)) {
        console.error(
          'Admin configuration of ID Submission does not match resubmit content:',
          this.idModel.filter((id) => !!id.number)
        );
      }

      this.idConfigs.forEach((config, toIndex) => {
        const fromIndex = this.idModel.map((id) => id.identificationType).indexOf(config.name);

        toIndex = toIndex + 1;

        if (fromIndex !== -1) {
          if (toIndex !== fromIndex) {
            const hold = this.idModel[fromIndex];
            this.idModel.splice(fromIndex, 1);
            this.idModel.splice(toIndex, 0, hold);
          }
        } else {
          this.idModel.splice(toIndex, 0, this.modelTpl(config));
        }
      });
    } else {
      this.idConfigs.forEach((config) => {
        this.idModel.push(this.modelTpl(config));
      });
    }
  }

  modelTpl(config) {
    return {
      identificationType: config.name,
      genericIdType: config.genericType,
      minSeedDataToSearch: config.minSeedDataToSearch,
    };
  }

  isResubmit(model) {
    return (model || []).some((id) => !!id.number);
  }

  isIncorrectConfig(config, model) {
    let modelCopy = model.concat();

    removeRegistrationNumber();

    for (let i = 0; i < modelCopy.length; i++) {
      if (!isResubmitIdTypeInConfig(modelCopy[i])) {
        return true;
      }
    }
    return false;

    function isResubmitIdTypeInConfig(resubmitted) {
      return config.some((id) => id.name === resubmitted.identificationType);
    }

    function removeRegistrationNumber() {
      modelCopy.shift();
    }
  }

  onChange() {
    let idSubmissionValid = true;
    for (let i = 0; i < this.idConfigs.length; i++) {
      if (this.idConfigs[i].required) {
        if (!this.idModel[i + 1].number) {
          idSubmissionValid = false;
          break;
        }
      }
    }
    this.onIdModelChange.emit(idSubmissionValid);
  }

  sendCageData(data) {
    this.buttonClicked.emit(data);
  }
}
