import { APP_INITIALIZER } from '@angular/core';
import TokenService from './token-service';
import { ActivatedRoute } from '@angular/router';

export default function configJwt() {
  return {
    provide: APP_INITIALIZER,
    useFactory(tokenService: TokenService, route: ActivatedRoute) {
      return () => {
        const jwt = route.snapshot.paramMap.get('jwt');
        tokenService.saveToken(jwt);
      };
    },
    deps: [TokenService, ActivatedRoute],
    multi: true,
  };
}
