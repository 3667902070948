import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, SlicePipe } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';
import MonitorClosedSummaryComponent from './ddiq-monitor-closed-summary.component';
import MonitorCategories from './monitor-categories.service';
import Monitor from './monitor.service';
import MonitorCrawlPlans from './monitor-crawlplans.service';
import MonitorDailyReportSearchComponent from './ddiq-monitor-daily-report-search.component';
import MonitorProfileComponent from './ddiq-monitor-profile.component';
import MonitorTabsData from './monitor-tabs-data.service';
import MonitorLinkComponent from './ddiq-monitor-link.component';
import MonitorSearchService from './monitor-search.service';
import MonitorResolverService from './monitor-resolver.service';
import MonitorClosedSearchComponent from './ddiq-monitor-closed-search.component';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import { OiqIncidentsModule } from '../incidents/oiq-incidents.module';
import OiqRegulatoryProfilesModule from '../regulatory-profiles/oiq-regulatory-profiles.module';
import { OiqWatchLists } from '../watch-lists/oiq-watch-lists.module';
import { WebsiteScorecardsModule } from '../website-scorecards/ddiq-website-scorecards.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import MonitorHitsContainer from './ddiq-monitor-hits-container.component';
import MonitorNoHits from './ddiq-monitor-no-hits.component';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import MonitorHits from './ddiq-monitor-hits.component';
import DateFormatPipe from '../ddiq-filters/date-format.pipe';
import AdditionalLocationSubmissionPipe from '../ddiq-filters/additional-location-submission.pipe';
import DoingBusinessInLocationSubmissionPipe from '../ddiq-filters/doing-business-in-location-submission.pipe';
import DoingBusinessInPipe from '../ddiq-filters/doing-business-in.pipe';
import IncorporatedInLocationSubmissionPipe from '../ddiq-filters/incorporated-in-location-submission.pipe';
import CitizenOfPipe from '../ddiq-filters/citizen-of.pipe';
import LocationLabelPipe from '../ddiq-filters/location-label.pipe';
import AddressPipe from '../ddiq-filters/address.pipe';
import MonitorSeedData from './ddiq-monitor-seed-data.component';
import MonitorHitsHeader from './ddiq-monitor-hits-header.component';
import MonitorHitsEntityName from './ddiq-monitor-hits-entity-name.component';
import MonitorSortBy from './ddiq-monitor-sort-by.component';
import MonitorHitsCount from './ddiq-monitor-hits-count.component';
import { SharedModule } from '../../../../../shared/shared.module';
import MonitorReportContainer from './ddiq-monitor-report-container.component';
import Drawer from './ddiq-drawer.component';
import MonitorFilters from './ddiq-monitor-filters.component';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import FilterButtons from './ddiq-filter-buttons.component';
import MonitorEventFilter from './ddiq-monitor-event-filter.component';
import MonitorCategoriesComponent from './ddiq-monitor-categories.component';
import MonitorHitSearchComponent from './ddiq-monitor-hit-search.component';
import MonitorResultsComponent from './ddiq-monitor-results.component';
import MonitorSearchComponent from './ddiq-monitor-search.component';
import { ScreeningModule } from '../screening/screening.module';

@NgModule({
  imports: [
    CommonModule,
    OiqWatchLists,
    OiqRegulatoryProfilesModule,
    DdiqUIModule,
    OiqIncidentsModule,
    SharedModule,
    PipesModule,
    DdiqUpgradeModule,
    ThemedEntityModule,
    FormsModule,
    NgPipesModule,
    ScreeningModule,
    WebsiteScorecardsModule,
  ],
  providers: [
    AdditionalLocationSubmissionPipe,
    AddressPipe,
    CitizenOfPipe,
    DateFormatPipe,
    DatePipe,
    DoingBusinessInLocationSubmissionPipe,
    DoingBusinessInPipe,
    IncorporatedInLocationSubmissionPipe,
    LocationLabelPipe,
    SlicePipe,
    TranslatefPipe,
    NgForm,
    MonitorCategories,
    MonitorTabsData,
    MonitorCrawlPlans,
    Monitor,
    MonitorSearchService,
    MonitorResolverService,
  ],
  declarations: [
    MonitorHitsContainer,
    MonitorNoHits,
    MonitorHits,
    MonitorSeedData,
    MonitorHitsHeader,
    MonitorHitsEntityName,
    MonitorSortBy,
    MonitorHitsCount,
    MonitorReportContainer,
    Drawer,
    MonitorFilters,
    FilterButtons,
    MonitorEventFilter,
    MonitorProfileComponent,
    MonitorClosedSummaryComponent,
    MonitorCategoriesComponent,
    MonitorHitSearchComponent,
    MonitorResultsComponent,
    MonitorSearchComponent,
    MonitorEventFilter,
    MonitorClosedSearchComponent,
    MonitorLinkComponent,
    MonitorDailyReportSearchComponent,
  ],
  exports: [
    MonitorLinkComponent,
    MonitorCategoriesComponent,
    MonitorProfileComponent,
    MonitorSearchComponent,
    MonitorReportContainer,
  ],
})
export class DdiqMonitorModule {}
