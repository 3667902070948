import { Component, Input } from '@angular/core';
import OiqPropertiesService from '../common-services/oiq-properties.service';
import PartialUtils from '../common-services/partial-utils.factory';
import CitizenOfPipe from '../ddiq-filters/citizen-of.pipe';
import DoingBusinessInPipe from '../ddiq-filters/doing-business-in.pipe';
import Entity from '../common-services/entity.service';

@Component({
  selector: 'person-input-data-detail',
  templateUrl: './person-input-data-detail.component.tpl.html',
  preserveWhitespaces: true,
})
export default class PersonInputDataDetailComponent {
  @Input() seedData;
  @Input() section;
  importedFrom;

  constructor(
    private partialUtils: PartialUtils,
    private oiqProperties: OiqPropertiesService,
    private doingBusinessIn: DoingBusinessInPipe,
    private citizenOf: CitizenOfPipe,
    private entity: Entity
  ) {
    this.importedFrom = this.entity.getData().importedFrom;
  }

  showAddressDetails() {
    let location = this.seedData.location || {};
    let isAddressFilled = this.partialUtils.isAddressFilled(this.seedData.location);

    return location.rawAddress === undefined && isAddressFilled;
  }

  hasDoingBusinessIn() {
    return !!this.doingBusinessIn.transform(this.seedData.previousAddresses).length;
  }

  hasCitizenOf() {
    return !!this.citizenOf.transform(this.seedData.previousAddresses).length;
  }

  canShowIdentificationNumbers() {
    return (
      this.oiqProperties.isPersonDisplayIdentificationsEnabled === true && !!this.seedData.identificationNumbers?.length
    );
  }

  trackByIndex(index) {
    return index;
  }
}
