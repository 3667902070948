import { AfterViewInit, Component } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Component({
  selector: '[ddiq-pdf-load-complete]',
  template: '<ng-content></ng-content>',
})
export default class PdfLoadCompleteDirective implements AfterViewInit {
  private window: Window;

  constructor(private windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.window.status = 'oiqLoadComplete';
    }, 3000);
  }
}
