<div class="corporate-graph-vis">
  <div class="loading">
    <img data-ng-src="./img/loader.gif" class="loading-ico" />
  </div>
  <header>
    <div class="entity-header">
      <h1>{{graphTitle}}</h1>
    </div>
  </header>
  <div class="graph-buttons">
    <button class="zoom-fit" (click)="zoomFit()">Center Graph</button>
  </div>
  <corporate-graph-drawer [filterCallback]="filterCallback" *ngIf="isOwnershipGraph"> </corporate-graph-drawer>
  <div *ngIf="isDynamic" class="graph-legend">
    <div class="legend-header">Legend</div>
    <div class="legend-content">
      <ul>
        <li *ngFor="let item of legend" [style.color]="item.color">{{item.name}}</li>
      </ul>
    </div>
  </div>
  <div id="corporate-record-graph"></div>
</div>
