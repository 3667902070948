import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-osha-inspection',
  templateUrl: './ddiq-osha-inspection.component.tpl.html',
})
export default class OshaInspectionComponent {
  @Input() inspection: any;
  @Input() isAdjudicateable: boolean;

  constructor() {}
}
