import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-fuzzy-date',
  templateUrl: './ddiq-fuzzy-date.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  preserveWhitespaces: true,
})
export default class FuzzyDateComponent implements OnInit {
  @Input() model: any;
  @Input() label: string;
  @Input() required;
  @Input() format: string;
  @Input() oiqTextDisabled: boolean;
  isRequired: boolean;
  nameAttr: string;

  @Output() modelChange = new EventEmitter<string>();
  constructor() {}
  ngOnInit() {
    this.nameAttr = (this.label || 'fuzzy-date').toLowerCase().replace(/ /g, '-');

    this.isRequired = typeof this.required !== 'undefined';
    if (!this.model) {
      //initialise object properties
      this.model = {
        day: '',
        month: '',
        year: '',
      };
    }
  }

  onDateChange(value: string) {
    this.modelChange.emit(value);
  }
}
