<div #entityAware="oiqEntityAware" oiq-entity-aware>
  <ng-container *ngFor="let group of groups.list; trackBy:classification">
    <article
      id="{{idPrefix}}_INMATE_PROFILE"
      *ngIf="group.hasDiscoveries"
      class="panel oiq-inmate-profiles discovery-group">
      <div data-oiq-collapsible-ctrl [expandAll]="expandAll || group.isEventGroupOpen">
        <header class="panel-header oiq-inmate-profiles-header">
          <oiq-group-dynamic-adjudication-aware
            [adjudicationType]="'INMATE_PROFILE'"
            [section]="idPrefix"
            [classification]="'INMATE_PROFILE'">
          </oiq-group-dynamic-adjudication-aware>

          <h3 class="panel-title oiq-inmate-profiles-title">{{group.title}}</h3>

          <div class="panel-header-right">
            <ddiq-sort-buttons
              *ngIf="group.hasMoreEntries"
              class="pull-left btn-sort-group-margin"
              [entities]="group.discoveries"
              [order-by]="'association, adjudication'"
              [btn-texts]=""
              (onClick)="sort({ sortName: $event.btnName, eventGroup: group})"
              [activeBtn]="group.activeSortButtons">
            </ddiq-sort-buttons>

            <button class="btn btn-sm btn-default" *ngIf="group.hasMoreEntries" data-oiq-collapsible-toggle>
              <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
              View all {{(group.discoveries | moreEntries).length + 1}} events
            </button>
          </div>
        </header>

        <section class="panel-body">
          <article
            class="oiq-inmate-profiles-featured-profile"
            *ngFor="let featured of group.discoveries | firstUnfilteredDiscovery">
            <ddiq-inmate-profile [profile]="featured" [isAdjudicateable]="entityAware.isAdjudicatable">
            </ddiq-inmate-profile>
          </article>

          <article class="oiq-inmate-profiles-more-entries discovery-group" *ngIf="group.hasMoreEntries">
            <ddiq-collapsible>
              <ddiq-inmate-profile
                *ngFor="let entry of group.discoveries | moreEntries; trackBy:id"
                [profile]="entry"
                [isAdjudicateable]="entityAware.isAdjudicatable">
              </ddiq-inmate-profile>
            </ddiq-collapsible>
          </article>
        </section>
      </div>
    </article>
  </ng-container>
</div>

<p *ngIf="groups.list.length === 0" class="oiq-inmate-profiles-msg-no-results">No results discovered</p>

<p *ngIf="groups.showAllFilteredMessage" class="oiq-inmate-profiles-msg-all-filtered">All results filtered</p>
