import PdfLoadCompleteDirective from './ddiq-pdf-load-complete.directive';
import PdfFeatureService from './pdf-feature.service';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PdfLoadCompleteDirective],
  providers: [PdfFeatureService],
  exports: [PdfLoadCompleteDirective],
})
export class PdfModule {}
