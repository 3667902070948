import { Component, Input } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-monitor-no-hits',
  templateUrl: 'ddiq-monitor-no-hits.component.tpl.html',
})
export default class MonitorNoHits {
  isGovIdSearchEnabled: boolean;

  @Input() report: {
    name: string;
    profileLink: string;
    submissionId: string;
    oiqLastUpdatedDate: string;
    oiqOwnerFullName: string;
    monitor: { [key: string]: any };
    seedData: { [key: string]: any };
    entityType: string;
  } = {
    name: '',
    profileLink: '',
    submissionId: '',
    oiqLastUpdatedDate: '',
    oiqOwnerFullName: '',
    monitor: {},
    seedData: {},
    entityType: '',
  };
  constructor(private oiqProperties: OiqProperties) {
    this.isGovIdSearchEnabled = oiqProperties.isGovIdSearchEnabled;
  }
}
