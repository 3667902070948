<article class="awards-summary">
  <dl>
    <dt>Total Count</dt>
    <dd class="award-summary-total-count">{{summary.totalCount}}</dd>
  </dl>
  <dl>
    <dt class="label">Total Amount</dt>
    <dd class="award-summary-total-amount">{{summary.totalAmount | currency}}</dd>
  </dl>
</article>
<ng-container *ngFor="let award of awards">
  <oiq-government-award [award]="award"></oiq-government-award>
</ng-container>
