import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import utils from '../../../exiger/utils';
import ProtectedArticlesService from './protected-articles.service';
import redirectQueryParamsConstants from '../../../exiger/redirect-query-params.constants';

@Component({
  selector: 'ddiq-protected-articles',
  templateUrl: './ddiq-protected-articles.component.tpl.html',
})
export default class ProtectedArticlesComponent implements OnInit {
  restResourceUrl;
  isPdf;
  protectedContent;
  hideLoadingIndicator;
  pdfUrl;

  constructor(private windowRef: WindowRefService, private protectedArticles: ProtectedArticlesService) {
    this.restResourceUrl = utils.getParameter(
      redirectQueryParamsConstants.restResourceUrlStr,
      this.windowRef.nativeWindow.location
    );
  }

  ngOnInit() {
    if (!this.restResourceUrl) {
      return;
    }
    this.isPdf = isPdf(this.restResourceUrl);

    if (isEmbedded(this.restResourceUrl)) {
      this.protectedArticles.getRestResource(this.restResourceUrl).then((response) => {
        this.protectedContent = response;
        this.hideLoadingIndicator = true;
      });
    } else if (this.isPdf) {
      this.hideLoadingIndicator = true;
      this.protectedArticles.ping().then(() => {
        this.pdfUrl = getPrefix(this.windowRef.nativeWindow.location) + this.restResourceUrl;
      });
      // currently we treat pdfs as regular non-embedded content
    } else if (isNonEmbedded(this.restResourceUrl)) {
      // force a call to the server for before doing anything to ensure user is logged in
      // before replacing the current window
      this.protectedArticles.ping().then(() => {
        this.windowRef.nativeWindow.open(
          getPrefix(this.windowRef.nativeWindow.location) + this.restResourceUrl,
          '_self'
        );
      });
    }
  }
}

// Currently: bca articles, factiva articles
function isNonEmbedded(restResourceUrl) {
  return restResourceUrl.indexOf('urn:bca:') !== -1 || isFactivaArticle(restResourceUrl);
}

// Currently: lexis articles
function isEmbedded(restResourceUrl) {
  return restResourceUrl.indexOf('lexis') !== -1;
}

// Currently: bvd, securityScorecard and factiva pdfs
function isPdf(restResourceUrl) {
  return (
    restResourceUrl.indexOf('bvdReport') !== -1 ||
    restResourceUrl.indexOf('securityScorecardReport') !== -1 ||
    isFactivaPdf(restResourceUrl)
  );
}

function isFactivaPdf(url) {
  return url.indexOf('factivaReport') !== -1 && url.indexOf('webpage:archive') === -1;
}

function isFactivaArticle(url) {
  return url.indexOf('factivaReport/webpage:archive') !== -1;
}

function getPrefix(location) {
  return `${location.protocol}//${location.hostname}:${location.port}/ddiq-services/rest/`;
}
