<section class="additional-item">
  <div *ngFor="let email of emailAddresses; let $index = index;">
    <button type="button" class="fa fa-trash trash" (click)="removeEmail($index)"></button>
    <ddiq-text
      name="email-addresses-email-{{$index}}"
      label="Email Address"
      size="half"
      [(model)]="email.email"></ddiq-text>
    <div class="input half">
      <label>Label</label>
      <ddiq-actions [prompt]="email.label">
        <a *ngFor="let label of emailLabels | arrayExclude: email.label" (click)="email.label = label;">{{label}}</a>
      </ddiq-actions>
    </div>
    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary" (click)="addEmail()">Add Email Address</button>
</section>
