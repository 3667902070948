import { Component, OnInit } from '@angular/core';
import AuthService from '../../../../../shared/services/common/auth.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import SessionTerminationMessageService from '../session-termination-message/session-termination-message.service';
import SystemUseNoticeService from '../system-use-notice/system-use-notice.service';
import LogoutService from '../user/logout.service';
import UserService from '../user/user.service';

const loginPanel = 1,
  changePasswordPanel = 2,
  termsConditionsPanel = 3,
  emailSentPanel = 5,
  loggedOutPanel = 6,
  servicesUnavailablePanel = 7,
  upgradeRequiredPanel = 8,
  sessionTimedOutPanel = 9;

@Component({
  selector: 'ddiq-login',
  templateUrl: './ddiq-login.component.tpl.html',
})
export default class LoginComponent implements OnInit {
  passwordValidationPattern = /^(?=.{9,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/;
  login: any = {};
  user: any = {};
  panel;
  showSystemUseNotice;
  systemUseNoticeContent;
  sessionTerminationMessage;

  constructor(
    private apiFetchService: ApiFetchService,
    private authService: AuthService,
    private userService: UserService,
    private logoutService: LogoutService,
    private systemUseNoticeService: SystemUseNoticeService,
    private sessionTerminationMessageService: SessionTerminationMessageService,
    private windowRef: WindowRefService,
    private oiqProperties: OiqProperties,
    private messageBusService: MessageBusService
  ) {}

  ngOnInit() {
    if (this.oiqProperties.avoidLoginPage) {
      this.panel = loggedOutPanel;
    } else if (this.hasUserLoggedOut()) {
      this.setSessionTimedOutPanel();
    } else {
      this.panel = loginPanel;
    }

    if (this.userService.isInitialized() && !this.userService.isTermsConditionsAccepted()) {
      this.panel = termsConditionsPanel;
      this.messageBusService.send({ type: 'event:showPanels' });
    }

    if (this.userService.isInitialized() && this.userService.passwordNeedsReset()) {
      this.panel = loginPanel;
      this.messageBusService.send({ type: 'event:showPanels' });
    }

    this.messageBusService.on('event:service-unavailable', () => {
      this.changePanel(servicesUnavailablePanel);
    });

    this.messageBusService.on('event:upgrade-required', () => {
      this.changePanel(upgradeRequiredPanel);
    });

    this.messageBusService.on('event:update-user-password-failed', (reason) => {
      /* jshint unused: true */
      this.login.error = {
        reason: reason,
      };
      this.login.fail = true;
      this.changePanel(loginPanel);
    });

    this.messageBusService.on('event:session-timed-out', () => {
      this.setSessionTimedOutPanel();
    });

    this.initializeSystemUseNotice();
  }

  isSsoEnabled() {
    return this.oiqProperties.isSsoEnabled;
  }

  isSloEnabled() {
    return this.oiqProperties.isSloEnabled;
  }

  handleLogout() {
    this.logoutHandler();
  }

  submitSuccess(user) {
    this.userService.initialize(user);
    if (!this.isSsoEnabled() && (user.forcePasswordReset || user.lockReason === 'EXPIRED_PASSWORD')) {
      this.changePanel(changePasswordPanel);
    } else if (!user.termsConditionsAccepted) {
      this.changePanel(termsConditionsPanel);
    } else {
      this.apiFetchService.visibleUsers().then(
        (data) => {
          this.userService.initializeVisibleUsers(data.users);
          this.authService.loginConfirmed();
          // clear credentials from form -- controller is only invoked once per app
          // load, so they will persist if not cleared
          this.user = {};
          this.login = {};
          setTimeout(() => {
            this.initializeSystemUseNotice();
            this.changePanel(loginPanel);
          }, 1000);
        },
        (response) => this.submitFailure(response)
      );
    }
    this.login.isWaiting = false;
  }

  submitFailure(response) {
    if (
      !this.isSsoEnabled() &&
      response.error &&
      response.error.reason &&
      response.error.reason === 'EXPIRED_PASSWORD'
    ) {
      this.changePanel(changePasswordPanel);
    } else {
      this.login.error = response.error;
      this.login.fail = true;
      this.login.pass = '';
    }
    this.login.isWaiting = false;
  }

  checkCredentials() {
    this.messageBusService.send({ type: 'autofill:update' });
    delete this.login.error;
    this.login.fail = false;
    this.login.isWaiting = true;
    this.apiFetchService.user(this.login.user, this.login.pass, true).then(
      (user) => this.submitSuccess(user),
      (response) => this.submitFailure(response)
    );
  }

  submitTermsAndConditionsAgreement() {
    const terms = {
      username: this.login.user || this.userService.getUserName(),
      termsConditionsAccepted: true,
    };
    this.apiFetchService.updateTermsAndConditions(terms.username, terms).then(
      (user) => this.submitSuccess(user),
      (response) => this.submitFailure(response)
    );
  }

  cancelTermsAndConditionsAgreement() {
    this.logoutService.logout().then(() => {
      this.login.error = {
        reason: 'TERMS_NOT_ACCEPTED',
      };
      this.login.fail = true;
      this.login.pass = '';
      this.changePanel(loginPanel);
    });
  }

  submitPasswordChange() {
    this.user.authError = '';
    const newPassword = {
      oldPassword: this.user.oldPassword,
      newPassword: this.user.newPassword,
    };
    this.apiFetchService.updatePassword(this.login.user, newPassword, this.login.user, newPassword.oldPassword).then(
      (user) => {
        // Since the current session is terminated after the user changes his/her own password,
        // the user will be redirected to the login page to re-login
        window.location.reload();
      },
      () => {
        this.user.authError = 'Incorrect old password';
        this.user.oldPassword = '';
        this.user.newPassword = '';
        this.user.newPasswordConfirm = '';
      }
    );
  }

  validPassword() {
    const validPassword = this.passwordValidationPattern.test(this.user.newPassword),
      passwordsMatch = this.user.newPassword === this.user.newPasswordConfirm,
      passwordReused = this.user.newPassword === this.user.oldPassword;

    if (!validPassword) {
      this.user.error =
        'Password must be a minimum 9 characters with: 1 digit, 1 uppercase, 1 lowercase and 1 special character';
    } else if (passwordReused && this.user.newPassword && this.user.newPassword.length > 0) {
      this.user.error = 'Cannot reuse the same password';
    } else if (passwordsMatch) {
      this.user.error = '';
    } else {
      this.user.error = 'New passwords do not match';
    }

    return (
      validPassword && passwordsMatch && !passwordReused && this.user.newPassword && this.user.newPassword.length > 0
    );
  }

  emailSent() {
    this.changePanel(emailSentPanel);
  }

  changePanel(p) {
    this.panel = p;
  }

  acceptSystemUseNotice() {
    this.dismissSystemUseNotice();
  }

  initializeSystemUseNotice() {
    this.showSystemUseNotice = this.systemUseNoticeService.hasNotice();
    this.systemUseNoticeContent = this.systemUseNoticeService.getNotice();
  }

  dismissSystemUseNotice() {
    this.showSystemUseNotice = false;
  }

  setSessionTimedOutPanel() {
    if (this.sessionTerminationMessageService.hasMessage()) {
      this.sessionTerminationMessageService.stopCheck();
      this.sessionTerminationMessage = this.sessionTerminationMessageService.getMessage();
      this.panel = sessionTimedOutPanel;
    } else {
      this.logoutHandler();
    }
  }

  hasUserLoggedOut() {
    return this.sessionTerminationMessageService.showMessageOnUserLogOut();
  }

  logoutHandler() {
    if ((this.isSloEnabled() && this.oiqProperties.avoidLoginPage) || this.hasUserLoggedOut()) {
      // to remove the ?loggedOut=true query string from url and redirecting to login page
      this.windowRef.nativeWindow.location.href = '/';
    } else {
      this.changePanel(loginPanel);
    }
  }
}
