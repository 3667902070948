import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const activeClass = 'scroll-nav-active';
@Injectable({ providedIn: 'root' })
export class ProfileStylingService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  updateNavigationClasses(element: HTMLElement): void {
    this.resetOldActiveElement();
    this.addNewActiveElement(element);
  }

  private resetOldActiveElement(): void {
    const oldElement = this.document.querySelector(`.${activeClass}`);

    if (oldElement) {
      oldElement.classList.remove(activeClass);
    }
  }

  private addNewActiveElement(element: HTMLElement): void {
    if (!element) return;

    element.classList.add(activeClass);
  }
}
