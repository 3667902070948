import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHTML',
})
export default class StripHTMLPipe implements PipeTransform {
  constructor() {}

  /**
   * Returns String with HTML tags removed
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    return String(input).replace(/<[^>]+>/gm, '');
  }
}
