import { Attribute, HostListener, OnDestroy, DoCheck, ElementRef, Input, OnInit, Directive } from '@angular/core';
import OiqCollapsibleCtrlDirective from './oiq-collapsible-ctrl.directive';

@Directive({
  selector: '[data-oiq-collapsible-toggle]',
  exportAs: 'oiqCollapsibleToggle',
})
export default class OiqCollapsibleToggle implements OnInit, OnDestroy, DoCheck {
  @Input('is-toggle-open') initialToggleState: boolean = false;

  isCollapsibleOpen: boolean = false;

  constructor(
    @Attribute('data-toggle-for') private name: string,
    private elementRef: ElementRef,
    private oiqCollapsibleCtrl: OiqCollapsibleCtrlDirective
  ) {}

  ngOnInit() {
    if (this.name) {
      this.oiqCollapsibleCtrl.registerToggle(this.name, this.initialToggleState);
    }
  }

  @HostListener('click') toggleClicked() {
    this.oiqCollapsibleCtrl.toggle(this.name);
  }

  ngOnDestroy() {
    this.oiqCollapsibleCtrl.unregisterToggle(this.name);
  }

  ngDoCheck() {
    // TODO: RV it would be ideal to use an observable for this!
    this.isCollapsibleOpen = this.oiqCollapsibleCtrl.isOpen(this.name);
  }
}
