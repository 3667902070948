import { Injectable } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import dataDogConfig from './data-dog-config';
import settings from './data-dog-settings.json';
import DataDogRef from './data-dog.ref';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable()
export class DataDogService {
  constructor(
    private messageBus: MessageBusService,
    private dataDogRef: DataDogRef,
    private ApiFetchService: ApiFetchService
  ) {}

  configure() {
    if (dataDogConfig.isEnabled) {
      this.ApiFetchService.version().then((version) => {
        settings.version = version.ui;
        this.messageBus.on('event:auth-loginConfirmed', () => this.dataDogRef.dataDog.init(settings));
      });
    } else {
      console.log('Datadog is disabled for this build');
    }
  }
}
