<div class="drawer" [ngClass]="{'drawer-open': isDrawerOpen}">
  <div class="drawer-tab" (click)="toggleDrawerOpen()">
    <div class="drawer-tab-inner">
      <span class="icon"><i [ngClass]="'fa fa-sm fa-chevron-' + (isDrawerOpen ? 'left': 'right')"></i></span>
    </div>
  </div>
  <div class="drawer-content">
    <div class="drawer-header">
      <span class="filter-icon"> <i class="fa fa-filter" aria-hidden="true"></i>Filters </span>
    </div>
    <div class="drawer-body">
      <div class="radio-inputs">
        <div class="input-label-group" *ngFor="let radioButton of radioButtons">
          <input
            class="radio"
            (change)="radioButtonClick()"
            [(ngModel)]="filterState.ownershipType"
            type="radio"
            id="{{radioButton.value}}"
            name="filter"
            [value]="radioButton.value"
            [checked]="radioButton.value === filterState.ownershipType" />
          <label [for]="radioButton.value">{{radioButton.labelText}}</label>
        </div>
      </div>
      <div
        class="percent-slider"
        title="{{isShowAll() ? 'Filter slider available in the two other views' : undefined}}"
        [ngClass]="{'disabled': isShowAll()}">
        <div class="title">Ownership:</div>
        <input class="percent-input" readonly type="text" />
        <div class="slider-container"></div>
      </div>
    </div>
  </div>
</div>
