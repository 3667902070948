import { Injectable } from '@angular/core';
import ConfigService from '../../../../../shared/services/http/config.service';
import AdviserInfoUrl from './adviser-info-url.service';

@Injectable({
  providedIn: 'root',
})
export default class SourceUrl {
  constructor(private config: ConfigService, private adviserInfoUrl: AdviserInfoUrl) {}

  getRedirectUrl(url) {
    const queryParams = this.adviserInfoUrl
      .getCandidateUrls(url)
      .map((alternateUrl) => `url=${alternateUrl}`)
      .join('&');

    if (!queryParams) {
      return undefined;
    }

    return makeRedirectUrl(`${location.origin}/`, this.config.settings.project.apiUrl, queryParams);
  }
}

function makeRedirectUrl(host, apiPath, queryParams) {
  return `${host}${apiPath}rest/sourceurl?${queryParams}`;
}
