<div class="inline tt no-print" *ngIf="metadata && metadata.length">
  <div class="tool">
    <img [src]="'img/pxs/original/cloud.png'" alt />
  </div>
  <div class="tip src-meta">
    <div *ngFor="let sourcedValue of metadata">
      <ul>
        <li class="sourced" [ngClass]="{'sourced-text': sourcedValue.isText}"><span>{{ sourcedValue.value }}</span></li>
        <ul>
          <li *ngFor="let source of sourcedValue.sources"><span class="sources">{{ source | translatef }}</span></li>
        </ul>
      </ul>
    </div>
  </div>
</div>
