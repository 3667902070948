<div class="monitor-event-filter" *ngIf="events?.length">
  <div class="events-search-box">
    <i class="fa fa-search" aria-hidden="true"></i>
    <input
      type="search"
      name="eventNameSearch"
      placeholder="Find event names"
      [(ngModel)]="eventNameSearch"
      title="Start typing to find event names" />
  </div>

  <div class="events-container" [class]="{'allow-scroll': events?.length > displayLimit}">
    <div class="events" *ngFor="let event of events | filterBy: ['name'] : eventNameSearch | orderBy: 'name'">
      <label class="event-checkbox filter-sm">
        <input
          class="event-input"
          #checkboxes
          type="checkbox"
          [checked]="eventModel.includes(event.key)"
          [value]="event.key"
          (change)="onClick($event)" />
        <span class="event-name">{{event.name}}</span>
      </label>
    </div>
  </div>
</div>

<div class="no-events" *ngIf="!events?.length">{{noResultMsg}}</div>
