<div>
  <table>
    <tr>
      <th>{{'Name' | translatef}}</th>
      <th class="search-medium">{{ 'REFERENCE_ID' | translatef }}</th>
      <th class="search-narrow">{{'Status' | translatef}}</th>
      <th class="search-narrow" *ngIf="isProfileCollectionEnabled">{{'Collections' | translatef}}</th>
      <th class="search-narrow">{{'Owner' | translatef}}</th>
      <th class="search-narrow">{{'Priority' | translatef}}</th>
      <th class="search-narrow">{{'Progress' | translatef}}</th>
      <th class="search-narrow">{{'Duration' | translatef}}</th>
      <th class="search-narrow" *ngIf="canSearchReqOrg">{{'Req.Org' | translatef}}</th>
    </tr>
    <tr *ngFor="let profile of buildingProfiles()">
      <td *ngIf="showPreviewLink(profile)"><a [href]="profile.profileLink">{{ profile.name }}</a></td>
      <td *ngIf="!showPreviewLink(profile)">{{ profile.name }}</td>
      <td>{{ profile.submissionId }}</td>
      <td>{{ profile.status | translatef }}</td>
      <td *ngIf="isProfileCollectionEnabled">
        <ddiq-limited-list class="collection-box" [list]="profile.collections" [limit]="3" [isOpen]="false">
          <ng-template #parentTemplate let-limitedList>
            <span class="badge" *ngFor="let collection of limitedList"> {{collection.name}} </span>
          </ng-template>
        </ddiq-limited-list>
      </td>
      <td>{{ profile.oiqOwnerFullName | translatef }}</td>
      <td>{{ getPriorityClass(profile.priority) | translatef }}</td>
      <td>{{ getProfileProgress(profile) }}</td>
      <td>{{ getDuration(profile) }}</td>
      <td *ngIf="canSearchReqOrg">{{ profile.reqOrg }}</td>
    </tr>
  </table>
  <exg-pagination
    [numPages]="pagination.numPages"
    [currentPage]="pagination.current"
    [paginationBarLength]="pagination.visible"
    [tableTotal]="pagination.totalCount"
    (onSelectPage)="onSelectPage($event.page)"
    totalLabel="profiles"></exg-pagination>
</div>
