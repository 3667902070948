import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import ApiFetchService from '../common-services/api-fetch.service';
import Diff from '../common-services/diff.service';
import EntityCache from '../common-services/entity-cache.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import PollingService from '../common-services/polling.service';
import RelationshipService from '../common-services/relationship-service.factory';
import ProfileRefreshService from '../profilerefresh/profile-refresh.service';
import SelectedArticlesFeatureService from '../protected-articles/selected-articles-feature.service';
import UserService from '../user/user.service';
import profileCollectionsParams from '../profile-collection/profile-collection-param-generator';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ErrorHandler from '../../../../../shared/services/common/error-handler.service';
import ModalTrackingService from '../modal/modal-tracking.service';
import PersonBuildService from '../themed-entity/person-build.service';
import CompanyBuildService from '../themed-entity/company-build.service';
import SeedDataService from '../themed-entity/seed-data.service';
import ProfileStatusFeatureService from '../profile-status/profile-status-feature.service';
import ScreeningFeatureService from '../screening/screening-feature.service';
import Monitor from '../monitor/monitor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ddiq-profile-header',
  templateUrl: 'ddiq-profile-header.component.tpl.html',
})
export default class DdiqProfileHeaderComponent implements OnInit {
  @Input() profile;
  @Output() reloadProfileFn = new EventEmitter<any>();

  monitoringEnabled: boolean;
  isPersonSubmissionEnabled: boolean;
  isInGroup: boolean;
  isSelectedArticlesEnabled: boolean;
  profileRefreshEnabled: boolean;
  auditUrl;
  integrityUrl;
  isProfileCollectionEnabled: boolean;
  isProfileCollectionEditable: boolean = false;
  updateProfileBtnDisabled;
  pdfGenerationEngine: string;

  constructor(
    private pollingService: PollingService,
    private oiqProperties: OiqProperties,
    public user: UserService,
    public entity: Entity,
    private screeningFeature: ScreeningFeatureService,
    private profileStatusFeature: ProfileStatusFeatureService,
    private modalTrackingService: ModalTrackingService,
    private profileRefreshService: ProfileRefreshService,
    public monitor: Monitor,
    private selectedArticlesFeature: SelectedArticlesFeatureService,
    public diff: Diff,
    private entityCache: EntityCache,
    private relationshipService: RelationshipService,
    private apiFetchService: ApiFetchService,
    private seedDataService: SeedDataService,
    private personBuildService: PersonBuildService,
    private companyBuildService: CompanyBuildService,
    private urlGenerator: UrlGenerator,
    private messageBusService: MessageBusService,
    private router: Router,
    private errorHandler: ErrorHandler
  ) {}

  ngOnInit() {
    this.monitoringEnabled = this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled;
    this.isPersonSubmissionEnabled = this.oiqProperties.personSubmissionEnabled;
    this.isInGroup = this.user.isInGroup();
    this.isSelectedArticlesEnabled = this.selectedArticlesFeature.isEnabled();
    this.profileRefreshEnabled = this.oiqProperties.profileRefreshEnabled;
    this.auditUrl = this.urlGenerator.generateAudit(this.entity.getType(), this.entity.getId());
    this.integrityUrl = this.urlGenerator.generateIntegrity(
      this.entity.getType(),
      this.entity.getId(),
      this.monitor.isDeltaReport()
    );
    this.isProfileCollectionEnabled = this.oiqProperties.isProfileCollectionEnabled;
    this.apiFetchService
      .getProfileCollections(this.getProfileCollectionsAssociatedWithProfileWithCurrentUserAsStewardParams())
      .then((data) => {
        if (data.totalMatches && this.isProfileCollectionEnabled) {
          this.isProfileCollectionEditable = true;
        }
      });
    this.pdfGenerationEngine = this.oiqProperties.pdfGenerationEngine;
  }

  isProfileRefreshActive() {
    return this.profileRefreshService.isProfileRefreshActive();
  }

  isProfileRefreshRunning() {
    return this.profileRefreshService.isProfileRefreshRunning();
  }

  isProfileRefreshDelta() {
    return this.profileRefreshService.isProfileRefreshDelta();
  }

  isPDFEngineBrowser() {
    return this.pdfGenerationEngine === 'BROWSER';
  }

  print() {
    window.print();
  }

  canUpdateSeedData() {
    return (
      this.oiqProperties.profileRefreshEnabled &&
      !this.entity.isDiff() &&
      !this.monitor.isMonitorActive() &&
      !this.monitor.isDeltaReport() &&
      !this.user.isReadOnlyUser() &&
      !this.isProfileRefreshRunning() &&
      ((!this.isProfileRefreshDelta() && !this.profileRefreshService.hasVersions()) ||
        (this.isProfileRefreshDelta && this.profileRefreshService.isLatestVersionForEntity(this.entity.getId())))
    );
  }

  canScheduleProfileRefresh() {
    return (
      this.oiqProperties.profileRefreshEnabled &&
      !this.entity.isDiff() &&
      this.entity.getData() &&
      !this.entity.getData().hidden &&
      !this.entity.getData().isImportedWithoutACrawlPlan &&
      !this.monitor.isDeltaReport() &&
      !this.user.isReadOnlyUser() &&
      !this.isProfileRefreshActive() &&
      !this.isProfileRefreshDelta()
    );
  }

  unscheduleProfileRefresh() {
    this.apiFetchService.unscheduleProfileRefresh(this.entity.getType(), this.entity.getId()).then(
      (response) => this.profileRefreshService.updateProfileRefreshData(response),
      (response) => {
        this.profileRefreshService.updateProfileRefreshData(response.data);
        this.modalTrackingService.openError('refreshInProgressError');
      }
    );
  }

  isProfileRefreshScheduled() {
    return this.profileRefreshService.isProfileRefreshScheduled();
  }

  disableMonitoring() {
    this.apiFetchService.unscheduleMonitor(this.entity.getType(), this.entity.getId()).then(
      (response) => this.monitor.setMonitorData(response),
      () => {
        this.monitor.setMonitorData(null);
        this.modalTrackingService.openError('monitorError');
      }
    );
  }

  exclusionsAvailable() {
    return (
      !this.entity.isDiff() &&
      ((this.oiqProperties.profileRefreshEnabled &&
        (this.profileRefreshService.isProfileRefreshScheduled() ||
          this.profileRefreshService.isProfileRefreshDelta()) &&
        this.oiqProperties.profileRefreshExclusionsEnabled) ||
        (this.monitor.isDeltaReport() && this.oiqProperties.monitoringExclusionsEnabled))
    );
  }

  isPreviewUpdateAvailable() {
    return this.pollingService.getPollResults().isPreviewUpdateAvailable;
  }

  isLastPreviewUpdate() {
    return this.pollingService.getPollResults().isLastPreviewUpdate;
  }

  isProfileStatusEnabled() {
    return (
      this.profileStatusFeature.isVisible() &&
      this.entity.allowsStatusChanges() &&
      !this.user.isReadOnlyUser() &&
      (this.screeningFeature.isEnabled() || !this.monitor.isDeltaReport())
    );
  }

  profileStatusErrorHandler(reason) {
    const errorModal = this.modalTrackingService.getTriggers().error;
    let errorTemplate = 'profileStatusUnknownError';

    if (reason === 'STALE_PROFILE_STATUSES') {
      errorTemplate = 'profileStatusStaleError';
    }

    errorModal.template = errorTemplate;
    errorModal.open = true;
  }

  isScreeningFeatureEnabled() {
    return this.screeningFeature.isEnabled();
  }

  updateProfile() {
    this.updateProfileBtnDisabled = true;
    this.entityCache.invalidate(this.entity.getId());
    this.relationshipService.clear();

    this.reloadProfileFn.emit();
  }

  modalTrigger(modalKey) {
    return this.modalTrackingService.getTriggers()[modalKey];
  }

  isMonitored() {
    return this.monitor.isMonitorActive();
  }

  resubmitReport() {
    this.seedDataService.fetchInputDataForProfile().then((seedData) => {
      let parent;

      this.entity.setSeedData(seedData);
      parent = this.entity.getSeedData();
      parent.crawlPlanType = this.entity.getData().crawlPlanType;
      parent.externalReferenceCode = this.entity.getData().externalReferenceCode;

      if (this.entity.isPerson()) {
        this.personBuildService.queuePersonToBuild(parent);
        this.router.navigate(['/build/person']);
      } else if (this.entity.isCompany()) {
        this.companyBuildService.queueCompanyToBuild(parent);
        this.router.navigate(['/build/company']);
      }
    });
  }

  updateRefreshSeedData() {
    this.seedDataService.fetchInputDataForProfile().then((seedData) => {
      let parent;

      this.entity.setSeedData(seedData);
      parent = this.entity.getSeedData();
      parent.crawlPlanType = this.entity.getData().crawlPlanType;
      parent.externalReferenceCode = this.entity.getData().externalReferenceCode;
      parent.refreshProfileId = this.entity.getId();

      if (this.entity.isPerson()) {
        this.personBuildService.queuePersonToBuild(parent);
        this.router.navigate(['/build/person']);
      } else if (this.entity.isCompany()) {
        this.companyBuildService.queueCompanyToBuild(parent);
        this.router.navigate(['/build/company']);
      }
    });
  }

  hideProfile(hidden) {
    this.apiFetchService.hideProfile(hidden).then(
      () => {
        this.entityCache.fetchEntity(this.entity.getType(), this.entity.getId()).then(() => {
          this.router.navigate(['/profiles']);
        });
      },
      (error) => {
        this.errorHandler.log(error);
      }
    );
  }

  expandAllEventGroupsToggled(isOpen) {
    this.messageBusService.send({ type: 'event:expand-all-event-groups-toggled', data: isOpen });
  }

  getProfileCollectionsAssociatedWithProfileWithCurrentUserAsStewardParams() {
    return profileCollectionsParams()
      .withUserPermissions('profile_assign')
      .withEntityId(this.entity.getId())
      .withEntityType(this.entity.getType())
      .build();
  }
}
