import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'moreEntries',
})
export default class MoreEntriesPipe implements PipeTransform {
  transform(discoveries): any {
    if (!discoveries) {
      return [];
    }
    return discoveries.filter((discovery) => !discovery.filtered).slice(1);
  }
}
