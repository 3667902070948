import { Inject, Pipe, PipeTransform } from '@angular/core';
import PartialUtils from '../common-services/partial-utils.factory';

@Pipe({
  name: 'submittedPeople',
})
export default class SubmittedPeoplePipe implements PipeTransform {
  constructor(private partialUtils: PartialUtils) {}

  transform(input: any) {
    return (input || []).filter((person) => {
      return this.partialUtils.isUserSubmitted(person.seedSources);
    });
  }
}
