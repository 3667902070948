<header>
  <h1 class="monitor-report-title">Monitored Reports</h1>
</header>
<section class="review">
  <ddiq-drawer drawerOpenWidth="225px">
    <form class="monitor-filters-form" novalidate (ngSubmit)="search(dates)" #find="ngForm">
      <div class="monitor-report-filters">
        <ddiq-date
          label="Date From"
          [(model)]="dates.after"
          [options]="dates.options.after"
          (modelChange)="onAfterDateChange($event)"
          [labelPattern]="dateLabelPattern"
          [datePattern]="dateInputPattern"></ddiq-date>
        <ddiq-date
          label="Date To"
          [(model)]="dates.before"
          [options]="dates.options.before"
          (modelChange)="onBeforeDateChange($event)"
          [labelPattern]="dateLabelPattern"
          [datePattern]="dateInputPattern"></ddiq-date>
      </div>
      <div class="monitor-filters-btn">
        <button
          type="submit"
          class="btn btn-primary submit"
          [class]="{disabled : !find.valid}"
          [disabled]="!find.valid">
          Apply
        </button>
        <button type="button" class="btn btn-danger clear" (click)="clearSearch(dates)">Clear</button>
      </div>
    </form>
  </ddiq-drawer>
  <ddiq-monitor-daily-report-search></ddiq-monitor-daily-report-search>
</section>
