import { Component, DoCheck, OnInit } from '@angular/core';
import jQuery from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/slider';
import AssociationBuckets from '../association/association-buckets.service';
import Filter from './filter.service';
let $ = jQuery;

@Component({
  selector: 'ddiq-association-slider',
  templateUrl: './ddiq-association-slider.component.tpl.html',
})
export default class AssociationSliderComponent implements OnInit, DoCheck {
  previousAssociationBucket;
  level;
  associationBuckets;

  constructor(private associationBucketsService: AssociationBuckets, private filter: Filter) {
    this.associationBuckets = this.associationBucketsService.getBuckets();
  }

  ngOnInit() {
    //I think most of this should be moved out of here.  This directive
    //should expose an api that can consume data and comunicate interesting
    //happenings.
    let selectedBucket,
      initialAssociationBucket = this.associationBucketsService.getInitialBucket(),
      filter = this.filter.getFilter(),
      max = Object.keys(this.associationBuckets).length - 1,
      slider = $('.slider-container');

    if (filter && typeof filter.associationBucket !== 'undefined') {
      selectedBucket = filter.associationBucket;
    } else {
      selectedBucket = initialAssociationBucket;
    }

    this.level = getBucketName(selectedBucket, this.associationBuckets);
    slider.slider({
      range: 'max',
      min: 0,
      max: max,
      value: selectedBucket,
      slide: (event, ui) => {
        /* jshint unused: true */

        selectedBucket = ui.value;
        this.level = getBucketName(selectedBucket, this.associationBuckets);
        let filter = this.filter.getFilter();
        filter.associationBucket = selectedBucket;
        this.filter.apply(filter);
      },
    });

    let spacing = 100 / max;
    $('.ui-slider-tick-mark').remove();

    for (let i = 0; i <= max; i++) {
      $('<span class="ui-slider-tick-mark"></span>')
        .css('left', spacing * i + '%')
        .appendTo(slider);
    }

    if (filter) {
      filter.associationBucket = selectedBucket;
    }
  }

  ngDoCheck() {
    let filter = this.filter.getFilter();

    if (filter && filter.associationBucket !== this.previousAssociationBucket) {
      this.updateSlider();
      this.previousAssociationBucket = filter.associationBucket;
    }
  }

  updateSlider() {
    let selectedBucket,
      filter = this.filter.getFilter();

    if (filter) {
      selectedBucket = filter.associationBucket;

      $('.slider-container').slider('value', selectedBucket);

      this.level = getBucketName(selectedBucket, this.associationBuckets);
    }
  }
}

function getBucketName(selectedBucket, buckets) {
  let bucketName = Object.keys(buckets).filter(function (bucket) {
    return buckets[bucket] === selectedBucket;
  });

  if (bucketName.length === 1) {
    return bucketName.toString();
  } else {
    return 'low';
  }
}
