import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import utils from '../../../exiger/utils';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export default class PdfFeatureService {
  private window: Window;

  constructor(private oiqProperties: OiqProperties, private windowRef: WindowRefService) {
    this.window = this.windowRef.nativeWindow;
  }

  showCaseComments() {
    return !this.isPdfRender() || (this.isPdfRender() && !this.oiqProperties.pdfExcludeCaseComments);
  }

  excludeAutoAdjudicated() {
    return this.isPdfRender() && this.oiqProperties.pdfExcludeAutoAdjudicated;
  }

  isPdfRender() {
    const printParam = utils.getParameter('print', this.window.location.href);
    return !!printParam;
  }
}
