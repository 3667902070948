import { Component, Input } from '@angular/core';
import PartialUtils from '../common-services/partial-utils.factory';
import AdditionalLocationSubmissionPipe from '../ddiq-filters/additional-location-submission.pipe';
import SubmittedPeoplePipe from './submitted-people.pipe';

@Component({
  selector: 'company-additional-input-data-detail',
  templateUrl: './company-additional-input-data-detail.component.tpl.html',
})
export default class CompanyAdditionalInputDataDetailComponent {
  @Input() seedData: any = {};
  isAddressFilled;

  constructor(
    private submittedPeople: SubmittedPeoplePipe,
    private additionalLocationSubmission: AdditionalLocationSubmissionPipe,
    private partialUtils: PartialUtils
  ) {
    this.isAddressFilled = this.partialUtils.isAddressFilled;
  }

  hasUserSubmittedPeople() {
    if (typeof this.seedData !== 'undefined') {
      return !!this.submittedPeople.transform(this.seedData.people).length;
    }
  }

  hasAdditionalLocations() {
    if (typeof this.seedData !== 'undefined') {
      return !!this.additionalLocationSubmission.transform(this.seedData.otherLocations).length;
    }
  }

  hasParts() {
    return ((this.seedData && this.seedData.parts) || []).some((part) => {
      return part;
    });
  }

  trackByIndex(index) {
    return index;
  }
}
