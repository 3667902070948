import { Component, Input, OnInit } from '@angular/core';
import MonitorSearchService from './monitor-search.service';
import MonitorTabsData from './monitor-tabs-data.service';

@Component({
  selector: 'ddiq-monitor-daily-report-search',
  templateUrl: './ddiq-monitor-daily-report-search.component.tpl.html',
})
export default class MonitorDailyReportSearchComponent implements OnInit {
  @Input() isQueryEmpty;
  @Input() lastQueryString;

  isPaginationResetting = false;
  pagination = {
    current: 1,
    total: 0,
    visible: 7,
    orderBy: 'date',
    descending: true,
  };

  results = {
    visible: 10,
    reports: [],
    total: 0,
    isLoading: false,
  };
  lastSuccessfulQuery;
  search;

  constructor(private monitorSearchService: MonitorSearchService, private monitorTabsData: MonitorTabsData) {}

  ngOnInit() {
    /**
     * we need to be able to reset the pagination (via clearSearch or search) without
     * firing an additional search
     */
    this.monitorSearchService.addSetPagination((currentPage, orderBy, descending) => {
      if (this.pagination.current !== currentPage) {
        this.isPaginationResetting = true;
        this.pagination.current = currentPage;
        this.pagination.orderBy = orderBy;
        this.pagination.descending = descending;
      }
    });

    this.search = this.goSearch();
    this.initMonitorDailyReportSearch();
  }

  initMonitorDailyReportSearch() {
    this.pagination.current = 1;
    this.lastSuccessfulQuery = this.monitorSearchService.previousQuery;
    this.search(this.pagination.current);
  }

  goSearch() {
    const cSearch = this.monitorSearchService.createMonitorDailyReportFunction(this.pagination, this.results);
    cSearch.registerCallback((updatedResults) => {
      if (updatedResults) {
        for (let i = 0; i < updatedResults.reports.length; i++) {
          for (let j = 0; j < updatedResults.reports[i].categoryCounts.length; j++) {
            const category = updatedResults.reports[i].categoryCounts[j].category;
            updatedResults.reports[i][category] = updatedResults.reports[i].categoryCounts[j].count;
          }
        }

        this.monitorTabsData.counts.reportCount = updatedResults.total;
      }

      this.monitorTabsData.pendingSearches--;
    });

    return (currentPage, previousPage) => {
      if (currentPage !== previousPage && !this.isPaginationResetting) {
        cSearch.search(this.lastSuccessfulQuery, (currentPage - 1) * this.results.visible);
      }
      this.isPaginationResetting = false;
    };
  }

  orderStyle(orderBy) {
    return orderBy === this.pagination.orderBy && 'sort-' + this.pagination.descending;
  }

  changeSorting(orderBy) {
    const sort = this.pagination;

    if (sort.orderBy === orderBy) {
      sort.descending = !sort.descending;
    } else {
      sort.orderBy = orderBy;
      sort.descending = orderBy === 'date';
    }

    this.search(this.pagination.current);
  }

  onSelectPage(page) {
    this.isPaginationResetting = false;

    if (this.pagination.current !== page) {
      this.search(page, this.pagination.current);
      this.pagination.current = page;
    }
  }
}
