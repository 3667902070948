<ul class="source-info-section source-info-source-names" *ngIf="sources.length">
  <li><strong>Sources:</strong></li>

  <li class="source-info-source-name" *ngFor="let source of sources; trackBy: trackByIndex" [textContent]="source"></li>
</ul>

<ul class="source-info-section source-info-articles" *ngIf="articles.length">
  <li class="source-info-heading">
    <strong> Correlating Articles {{ 'OIQ_DISCOVERY_PROCESS' | translatef }}: </strong>
  </li>

  <li class="source-info-article" *ngFor="let article of articles; trackBy: trackByIndex">
    <a
      class="source-info-article-link"
      href="{{ article }}"
      [textContent]="article | websiteNameNormalizer: 80"
      target="_blank"
      rel="noopener noreferrer">
    </a>

    <a
      class="cached-article-nav source-info-article-cached-link"
      *ngIf="shouldShowCachedArticleLink(article)"
      href="{{ article | cachedArticleHref: currentEntityId }}"
      target="_blank"
      rel="noopener noreferrer"
      >( cached )
    </a>
  </li>
</ul>
