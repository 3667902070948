<ng-content></ng-content>
<article>
  <ddiq-monitor-hits-entity-name
    *ngIf="isMonitorRevamp"
    [entityType]="entityType"
    [entityName]="seedData.name"
    [profileLink]="profileLink"></ddiq-monitor-hits-entity-name>
  <button class="monitor-seed-data-toggle" *ngIf="displayShowAll" (click)="toggleShowAll()">
    <i class="fa" [class]="{ 'fa-chevron-up': isShowingAll, 'fa-chevron-down': !isShowingAll }"> Show All </i>
  </button>
  <dl class="monitor-seed-data-content">
    <ng-container *ngIf="!isMonitorRevamp">
      <dt
        class="monitor-seed-data-entity-type monitor-seed-data-label monitor-seed-data-name-label"
        *ngIf="entityType === 'company'">
        Company:
      </dt>
      <dt
        class="monitor-seed-data-entity-type monitor-seed-data-label monitor-seed-data-name-label"
        *ngIf="entityType === 'person'">
        Person:
      </dt>
      <dd class="monitor-seed-data-value">
        <a class="monitor-seed-data-name" href="{{profileLink}}">{{seedData.name}} </a>
      </dd>
    </ng-container>

    <dt
      class="monitor-seed-data-alternative-names-label monitor-seed-data-label"
      *ngIf="seedData.alternativeNames?.length">
      Alternative Names:
    </dt>
    <dd class="monitor-seed-data-alternative-names monitor-seed-data-value" *ngIf="seedData.alternativeNames?.length">
      <ddiq-limited-list [list]="seedData.alternativeNames" [limit]="nameListLimit" [isOpen]="isShowingAll">
        <ng-template #parentTemplate let-limitedList>
          <ul class="monitor-seed-data-list">
            <li
              class="monitor-seed-data-alternative-name monitor-seed-data-list-item"
              *ngFor="let alternativeName of limitedList">
              {{alternativeName}}
            </li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </dd>

    <dt class="monitor-seed-data-dba-names-label monitor-seed-data-label" *ngIf="seedData.dbaNames?.length">
      Doing Business As:
    </dt>
    <dd class="monitor-seed-data-dba-names monitor-seed-data-value" *ngIf="seedData.dbaNames?.length">
      <ddiq-limited-list [list]="seedData.dbaNames" [limit]="nameListLimit" [isOpen]="isShowingAll">
        <ng-template #parentTemplate let-limitedList>
          <ul class="monitor-seed-data-list">
            <li class="monitor-seed-data-dba-name monitor-seed-data-list-item" *ngFor="let dbaName of limitedList">
              {{dbaName}}
            </li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </dd>

    <dt
      class="monitor-seed-data-birth-date-label monitor-seed-data-label monitor-seed-data-birth-day-label"
      *ngIf="seedData.birthdate">
      Birth Date:
    </dt>
    <dd class="monitor-seed-data-birth-date monitor-seed-data-value" *ngIf="seedData.birthdate">
      {{seedData.birthdate | dateFormat}}
    </dd>

    <dt class="monitor-seed-data-age-label monitor-seed-data-label monitor-seed-data-age-label" *ngIf="seedData.age">
      Age:
    </dt>
    <dd class="monitor-seed-data-age monitor-seed-data-value" *ngIf="seedData.age">{{seedData.age}}</dd>

    <dt class="monitor-seed-data-gender-label monitor-seed-data-label monitor-seed-data-sex-label" *ngIf="seedData.sex">
      Sex:
    </dt>
    <dd class="monitor-seed-data-gender monitor-seed-data-value" *ngIf="seedData.sex">{{seedData.sex}}</dd>

    <dt class="monitor-seed-data-current-address-label monitor-seed-data-label" *ngIf="currentAddress && !rawAddress">
      Current Address:
    </dt>
    <dd class="monitor-seed-data-current-address monitor-seed-data-value" *ngIf="currentAddress && !rawAddress">
      <span class="monitor-seed-data-current-address-street" *ngIf="currentAddress.streetAddress"
        >{{currentAddress.streetAddress}}</span
      >
      <span class="monitor-seed-data-current-address-building-name" *ngIf="currentAddress.buildingName"
        >{{currentAddress.buildingName}}</span
      >
      <span class="monitor-seed-data-current-address-district" *ngIf="currentAddress.district"
        >{{currentAddress.district}}</span
      >
      <span class="monitor-seed-data-current-address-city" *ngIf="currentAddress.city">{{currentAddress.city}}</span>
      <span class="monitor-seed-data-current-address-state-province" *ngIf="currentAddress.stateProvince"
        >{{currentAddress.stateProvince}}</span
      >
      <span class="monitor-seed-data-current-address-zip-postal" *ngIf="currentAddress.zipPostalCode"
        >{{currentAddress.zipPostalCode}}</span
      >
      <span class="monitor-seed-data-current-address-country" *ngIf="currentAddress.country"
        >{{currentAddress.country}}</span
      >
    </dd>

    <dt class="monitor-seed-data-raw-address-label monitor-seed-data-label" *ngIf="rawAddress">Raw Address:</dt>
    <dd class="monitor-seed-data-raw-address monitor-seed-data-value" *ngIf="rawAddress">{{rawAddress}}</dd>

    <dt class="monitor-seed-data-locations-label monitor-seed-data-label" *ngIf="locations?.length">Locations:</dt>
    <dd class="monitor-seed-data-locations monitor-seed-data-value" *ngIf="locations?.length">
      <ddiq-limited-list [list]="locations" [limit]="locationListLimit" [isOpen]="isShowingAll">
        <ng-template #parentTemplate let-limitedList>
          <ul class="monitor-seed-data-list">
            <li class="monitor-seed-data-location monitor-seed-data-list-item" *ngFor="let location of limitedList">
              <span class="monitor-location-label">{{(location | locationLabel) + ':'}}</span>
              <span class="monitor-location-address">{{location.address | address}}</span>
            </li>
          </ul>
        </ng-template>
      </ddiq-limited-list>
    </dd>

    <dt class="monitor-seed-data-label monitor-seed-data-submission-id-label" *ngIf="submissionId">
      {{'REFERENCE_ID' | translatef}}:
    </dt>
    <dd class="monitor-seed-data-submission-id submission-id monitor-seed-data-value" *ngIf="submissionId">
      <span>{{submissionId}}</span>
    </dd>

    <dt class="monitor-seed-data-created-by-label monitor-seed-data-label" *ngIf="seedData.oiqCreatedBy">
      Submitted By:
    </dt>
    <dd class="monitor-seed-data-created-by monitor-seed-data-value" *ngIf="seedData.oiqCreatedBy">
      {{seedData.oiqCreatedBy}}
    </dd>
  </dl>
</article>
