import {
  Component,
  Input,
  Output,
  EventEmitter,
  DoCheck,
  Attribute,
  ElementRef,
  Optional,
  OnInit,
} from '@angular/core';
import OiqCollapsibleCtrlDirective from './oiq-collapsible-ctrl.directive';

@Component({
  selector: 'ddiq-collapsible',
  templateUrl: './ddiq-collapsible.component.tpl.html',
})
export default class DdiqCollapsibleComponent implements OnInit, DoCheck {
  @Input() showOpen: boolean = false;
  @Input() toggleText: string;
  @Output() onToggle = new EventEmitter();

  isVisible: boolean;
  shouldRenderBtn: boolean;

  constructor(
    private elementRef: ElementRef,
    @Attribute('data-for-toggle') private forToggle: string,
    @Optional() private oiqCollapsibleCtrl: OiqCollapsibleCtrlDirective
  ) {}

  ngDoCheck() {
    if (this.oiqCollapsibleCtrl) {
      let isOpen = this.oiqCollapsibleCtrl.isOpen(this.forToggle || '');
      if (isOpen !== this.isVisible) {
        this.isVisible = isOpen;
      }
    }
  }

  toggle() {
    this.isVisible = !this.isVisible;
    this.onToggle.emit();
  }

  ngOnInit(): void {
    if (!this.oiqCollapsibleCtrl) {
      this.shouldRenderBtn = !!this.toggleText;
      this.isVisible = this.showOpen;
    }
  }
}
