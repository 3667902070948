import { Injectable } from '@angular/core';
import ApiFetchService from './api-fetch.service';
import { PollResults } from './poll-results.model';
import { PollingParams } from './poll-params.model';

const pollInterval = 5000;

@Injectable({
  providedIn: 'root',
})
export default class PollingService {
  private settings = {
    entityType: '',
    entityId: 0,
  };
  private pollResults: PollResults = {
    lastUpdated: { time: undefined },
    isPreviewUpdateAvailable: false,
    isLastPreviewUpdate: false,
  };
  private pollParams: PollingParams = { building: false };
  private timeout;
  private entityInProgress;

  constructor(private apiFetchService: ApiFetchService) {
    this.entityInProgress = {
      poll: () => {
        return this.apiFetchService
          .lastUpdated(this.settings.entityType, this.settings.entityId)
          .then((lastUpdated) => {
            const oldTime = this.pollResults.lastUpdated && this.pollResults.lastUpdated.time,
              newTime = lastUpdated && lastUpdated.time,
              hasChanged = oldTime && oldTime !== newTime,
              hasCompleted = oldTime && !newTime;

            this.pollResults.isPreviewUpdateAvailable = this.pollResults.isPreviewUpdateAvailable || hasChanged;
            this.pollResults.isLastPreviewUpdate = this.pollResults.isLastPreviewUpdate || hasCompleted;

            this.pollResults.lastUpdated = lastUpdated;
          });
      },

      checkIsBuildRunning: () => {
        // double bang converts to boolean if undefined
        return !!(this.pollResults.lastUpdated && this.pollResults.lastUpdated.time);
      },
    };

    // init pollResults
    this.clearResults();
  }
  /**
   *
   * @param entityType
   * @param entityId
   * @param params
   * @param forceClear
   *   used to clear params/results.  only needed (i think) when first
   *   initializing EntityCtrl, and only when it's a direct repeat load
   *   e.g. navigating from Joe Smith -> Overview -> Joe Smith
   */
  init(entityType, entityId, params, forceClear?) {
    if (typeof forceClear === 'undefined') {
      forceClear = false;
    }

    this.stopPoll();

    if (forceClear || entityType + entityId !== this.settings.entityType + this.settings.entityId) {
      this.clearParams();
      this.clearResults();
    }

    this.setParams(entityType, entityId, params);

    this.go();
  }

  stop() {
    this.stopPoll();
  }

  getPollResults() {
    return this.pollResults;
  }

  private stopPoll() {
    if (typeof this.timeout !== 'undefined') {
      clearTimeout(this.timeout);
    }
  }

  private setParams(entityType, entityId, params) {
    this.settings.entityType = entityType;
    this.settings.entityId = entityId;

    Object.keys(params).forEach((key) => {
      this.pollParams[key] = params[key];
    });
  }

  private go() {
    const promises = [];

    if (this.pollParams.building) {
      promises.push(this.entityInProgress.poll());
    }

    if (promises.length === 0) {
      return;
    }

    const promise = Promise.all(promises);

    promise.then(
      () => {
        if (this.pollParams.building) {
          this.pollParams.building = this.entityInProgress.checkIsBuildRunning();
        }

        this.timeout = setTimeout(() => this.go(), pollInterval);
        // restart poll here
      },
      () => {
        this.clearParams();
        this.clearResults();
      }
    );
  }

  private clearResults() {
    // pollResults is watched, so do not point to a new object
    this.pollResults.isPreviewUpdateAvailable = false;
    this.pollResults.isLastPreviewUpdate = false;
    this.pollResults.lastUpdated = { time: undefined };
  }

  private clearParams() {
    this.settings.entityId = 0;
    this.settings.entityType = '';

    this.pollParams = { building: false };
  }
}
