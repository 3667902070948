import { NgModule } from '@angular/core';
import FirstUnfilteredDiscoveryPipe from './first-unfiltered-discovery.pipe';
import MoreEntriesPipe from './more-entries.pipe';
import AdditionalDataValuePipe from './additional-data-value.pipe';
import AddressPipe from './address.pipe';
import AntiCollideClassificationPipe from './anti-collide-classification.pipe';
import ArrayExcludePipe from './array-exclude.pipe';
import AssociationTooltipPipe from './association-tooltip.pipe';
import ConvertHtmlSpecificCharsPipe from './convert-html-specific-chars.pipe';
import CurrencyOrNullPipe from './currency-or-null.pipe';
import CurrencyMeasurePipe from './currency-measure.pipe';
import DateFormatPipe from './date-format.pipe';
import ExcludeAdjudicationActionsPipe from './exclude-adjudication-actions.pipe';
import FrontZeroPadPipe from './front-zero-pad.pipe';
import IsSubmittedPipe from './is-submitted.pipe';
import LegalifyClassificationPipe from './legalify-classification.pipe';
import MaxValuePipe from './max-value.pipe';
import MillisToTimePipe from './millis-to-time.pipe';
import MoneyFormatPipe from './money-format.pipe';
import NewlineToBrPipe from './newline-to-br.pipe';
import NullsToEndPipe from './nulls-to-end.pipe';
import PulloutidPipe from './pulloutid.pipe';
import SitenamePipe from './sitename.pipe';
import SortableDatePipe from './sortable-date.pipe';
import StartFromPipe from './start-from.pipe';
import StripHTMLPipe from './strip-html.pipe';
import StripScriptAndMetaPipe from './strip-script-and-meta.pipe';
import StripWWWPipe from './strip-www.pipe';
import ToArrayPipe from './to-array.pipe';
import TranslatefPipe from './translatef.pipe';
import TruncatePipe from './truncate.pipe';
import UrlNormalizerPipe from './url-normalizer.pipe';
import WebsiteNameNormalizerPipe from './website-name-normalizer.pipe';
import WhitespacePipe from './whitespace.pipe';
import WithRolePipe from './with-role.pipe';
import YearFormatPipe from './year-format.pipe';
import YesNoPipe from './yes-no.pipe';
import LocationLabelPipe from './location-label.pipe';
import AdditionalLocationSubmissionPipe from './additional-location-submission.pipe';
import DoingBusinessInLocationSubmissionPipe from './doing-business-in-location-submission.pipe';
import DoingBusinessInPipe from './doing-business-in.pipe';
import IncorporatedInLocationSubmissionPipe from './incorporated-in-location-submission.pipe';
import CitizenOfPipe from './citizen-of.pipe';
import SanitizeUrlPipe from './sanitize-url-pipe';
import CommonServicesModule from '../common-services/common-services.module';

@NgModule({
  imports: [CommonServicesModule],
  declarations: [
    DateFormatPipe,
    TranslatefPipe,
    AdditionalDataValuePipe,
    AddressPipe,
    AntiCollideClassificationPipe,
    ArrayExcludePipe,
    AssociationTooltipPipe,
    ConvertHtmlSpecificCharsPipe,
    CurrencyOrNullPipe,
    CurrencyMeasurePipe,
    ExcludeAdjudicationActionsPipe,
    FrontZeroPadPipe,
    IsSubmittedPipe,
    LegalifyClassificationPipe,
    MaxValuePipe,
    MillisToTimePipe,
    MoneyFormatPipe,
    NewlineToBrPipe,
    NullsToEndPipe,
    PulloutidPipe,
    SitenamePipe,
    SortableDatePipe,
    StartFromPipe,
    StripHTMLPipe,
    StripScriptAndMetaPipe,
    StripWWWPipe,
    ToArrayPipe,
    TruncatePipe,
    UrlNormalizerPipe,
    WebsiteNameNormalizerPipe,
    WhitespacePipe,
    WithRolePipe,
    YearFormatPipe,
    YesNoPipe,
    AdditionalLocationSubmissionPipe,
    DoingBusinessInLocationSubmissionPipe,
    DoingBusinessInPipe,
    IncorporatedInLocationSubmissionPipe,
    CitizenOfPipe,
    LocationLabelPipe,
    FirstUnfilteredDiscoveryPipe,
    MoreEntriesPipe,
    SanitizeUrlPipe,
  ],
  providers: [
    ExcludeAdjudicationActionsPipe,
    AntiCollideClassificationPipe,
    TruncatePipe,
    StripHTMLPipe,
    AddressPipe,
    ConvertHtmlSpecificCharsPipe,
    IsSubmittedPipe,
    AdditionalLocationSubmissionPipe,
    CitizenOfPipe,
    DoingBusinessInLocationSubmissionPipe,
    DoingBusinessInPipe,
    IncorporatedInLocationSubmissionPipe,
    LocationLabelPipe,
    MaxValuePipe,
    SortableDatePipe,
    ToArrayPipe,
    ArrayExcludePipe,
    NullsToEndPipe,
  ],
  exports: [
    DateFormatPipe,
    TranslatefPipe,
    AddressPipe,
    ArrayExcludePipe,
    ConvertHtmlSpecificCharsPipe,
    IsSubmittedPipe,
    AdditionalLocationSubmissionPipe,
    CitizenOfPipe,
    DoingBusinessInLocationSubmissionPipe,
    DoingBusinessInPipe,
    IncorporatedInLocationSubmissionPipe,
    LocationLabelPipe,
    AssociationTooltipPipe,
    ExcludeAdjudicationActionsPipe,
    AntiCollideClassificationPipe,
    StripScriptAndMetaPipe,
    FirstUnfilteredDiscoveryPipe,
    MoreEntriesPipe,
    NewlineToBrPipe,
    UrlNormalizerPipe,
    SitenamePipe,
    MaxValuePipe,
    SortableDatePipe,
    AdditionalDataValuePipe,
    StripHTMLPipe,
    WebsiteNameNormalizerPipe,
    YearFormatPipe,
    StripWWWPipe,
    CurrencyMeasurePipe,
    ToArrayPipe,
    TruncatePipe,
    MoneyFormatPipe,
    StartFromPipe,
    MillisToTimePipe,
    NullsToEndPipe,
    CurrencyOrNullPipe,
    WithRolePipe,
    SanitizeUrlPipe,
  ],
})
export class PipesModule {}
