import { Injectable } from '@angular/core';
import OiqProperties from '../../../ddiq/js/modules/oiq/common-services/oiq-properties.service';
import ConfigService from '../http/config.service';
import { LocationRefService } from './location-ref.service';

@Injectable({
  providedIn: 'root',
})
export default class UrlGenerator {
  private location;

  constructor(
    private config: ConfigService,
    private locationRef: LocationRefService,
    private oiqProperties: OiqProperties
  ) {
    this.location = this.locationRef.nativeLocation;
  }

  /**
   * once we start including parentId with the serialized entity, the overview part of the url can leave
   *
   * @param parentId
   * @param entityType
   * @param entityId -- reuses parentId if not supplied
   * @returns {string} - the link to the url, relative to the project
   */
  generateReport(parentId, entityType, entityId?, params?) {
    entityId = entityId || parentId;
    return '#/overview/' + parentId + '/' + entityType + '/' + entityId + (params ? params : '');
  }

  /**
   * create a url to a report pdf
   * @param parentId
   * @param entityType
   * @param entityId
   * @param filter
   * @returns {string} a url pointing to the report pdf
   */
  generatePdf(parentId, entityType, entityId, params?) {
    if (parentId === entityId) {
      return this.pdfUrlFromLocation(entityType + '/' + entityId + (params ? params : ''));
    }
    return this.pdfUrlFromLocation('company/' + parentId + '/' + entityType + '/' + entityId + (params ? params : ''));
  }

  /**

   * create a url to a diff report pdf
   * @param entityId
   * @returns {string} a url pointing to the report pdf
   */
  generateDiffPdf(entityId, entityType, baselineId, params) {
    return this.pdfUrlFromLocation('diff/' + entityType + '/' + entityId + '/' + baselineId + (params ? params : ''));
  }

  /**
   * create a url to a report pdf
   * @param location
   * @returns {string} a url pointing to the report pdf
   */
  pdfUrlFromLocation(location) {
    return `${this.makeRestUrl()}pdf/${location}`;
  }

  /**
   * used by Insure only
   * @param entityId
   */
  generateOverview(entityId) {
    return '#/overview/' + entityId;
  }

  generateDiff(currentId, entityType, baselineId, params) {
    return this.generateReport(currentId, entityType) + '/diff/' + baselineId + (params ? params : '');
  }

  generateAudit(entityType, entityId) {
    if (this.oiqProperties.auditReportUrlTemplate) {
      return this.oiqProperties.auditReportUrlTemplate
        .replace(/:entityType/g, entityType)
        .replace(/:entityId/g, entityId);
    }
    return this.location.href.replace(this.location.hash, '#/audit/' + entityType + '/' + entityId);
  }

  generateAuditPdf(entityType, entityId) {
    return this.pdfUrlFromLocation('audit/' + entityType + '/' + entityId);
  }

  generateIntegrity(entityType, entityId, isDeltaReport) {
    if (this.oiqProperties.integrityReportUrlTemplate) {
      return this.oiqProperties.integrityReportUrlTemplate
        .replace(/:entityId/g, entityId)
        .replace(/:entityType/g, entityType)
        .replace(':isMonitor', isDeltaReport);
    }
    return this.location.href.replace(
      this.location.hash,
      '#/integrity/' + entityType + '/' + entityId + (isDeltaReport ? '?monitor=true' : '')
    );
  }

  generateIntegrityPdf(entityType, entityId, isDeltaReport) {
    return this.pdfUrlFromLocation('integrity/' + entityType + '/' + entityId + (isDeltaReport ? '?monitor=true' : ''));
  }

  generateSelectedArticlePdf(entityType, entityId, selectedArticlesId) {
    return this.pdfUrlFromLocation(entityType + '/' + entityId + '/articles/' + selectedArticlesId);
  }

  generateCachedArticleRouteUrl(cachedUrl) {
    const routeUrl = `#/cached-article?url=${encodeURIComponent(cachedUrl)}`,
      redirectUrl = `?redirectUrl=${encodeURIComponent(routeUrl)}`;

    return this.replaceCurrentUrl(redirectUrl);
  }

  generateCachedArticleUrl(articleUrl, entityId) {
    const encodeArticleUrl = encodeURIComponent(articleUrl),
      encodedEntityId = encodeURIComponent(entityId);

    return `${this.makeRestUrl()}oiqPrivate/article?url=${encodeArticleUrl}&id=${encodedEntityId}`;
  }

  private replaceCurrentUrl(url) {
    let absUrl = this.location.href;
    absUrl = absUrl.replace(this.location.hash, url);
    return absUrl;
  }

  private makeRestUrl() {
    const port = this.location.port ? `:${this.location.port}` : '',
      apiUrl = this.getConfiguredApiUrl();
    return `${this.location.protocol}//${this.location.hostname}${port}${apiUrl}rest/`;
  }

  private getConfiguredApiUrl() {
    const slash = '/';
    let apiUrl = this.config.settings.project.apiUrl;

    if (apiUrl.charAt(0) !== slash) {
      apiUrl = `${slash}${apiUrl}`;
    }
    return apiUrl;
  }
}
