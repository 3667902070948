import { NgModule } from '@angular/core';
import DdiqSimpleCollapseComponent from './ddiq-simple-collapse.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';

@NgModule({
  imports: [CommonModule, FormsModule, PipesModule],
  declarations: [DdiqSimpleCollapseComponent],
  exports: [DdiqSimpleCollapseComponent],
})
export class DdiqUpgradeModule {}
