import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ddiq-system-use-notice',
  templateUrl: './system-use-notice.component.tpl.html',
})
export default class SystemUseNoticeComponent {
  @Input() content: any;
  @Output() noticeAccepted = new EventEmitter<any>();

  onNoticeAccepted() {
    this.noticeAccepted.emit();
  }
}
