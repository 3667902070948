<div class="alert alert-danger crawl-error" *ngIf="hasErrors">
  <div class="alert-icon">
    <span class="fa fa-exclamation-circle fa-2x"></span>
  </div>
  <div>
    <p>Please be advised that the following sources were not successfully searched:</p>
    <ul class="crawl-error-list">
      <li *ngFor="let sourceWithErrors of sourcesWithErrors">{{sourceWithErrors}}</li>
    </ul>
    <p>More information may be available in the Audit Report.</p>
    <p>
      Please resubmit your request or, if this error persists, contact support at
      <ddiq-support-contact-info [withPageLinkIncluded]="true"></ddiq-support-contact-info> for further assistance.
    </p>
  </div>
</div>
