import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsModule } from '../alerts/oiq-alerts.module';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import OiqSourcesModule from '../sources/oiq-sources.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../../../../shared/shared.module';
import CorporateInfoComponent from './ddiq-corporate-info.component';
import { OiqRiskModule } from '../risk/oiq-risk.module';
import { DdiqCorporateRecordsModule } from '../corporate-records/ddiq-corporate-records.module';
import { NetworkModule } from '../network/oiq-network.module';
import { OiqRelatedEntityModule } from '../related-entity/oiq-related-entity.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import DdiqPersonBasicInfoComponent from './ddiq-person-basic-info.component';
import { OiqProfessionalLicensesModule } from '../professional-licenses/oiq-professional-licenses.module';
import { ProfileFiltersModule } from '../profile-filters/oiq-profile-filters.module';
import ProfileInputModule from '../profile-input-details/profile-input.module';
import { AuditModule } from '../audit/ddiq-audit.module';
import PIQCertifiedSupplier from './ddiq-piq-certified-supplier.component';

@NgModule({
  imports: [
    CommonModule,
    DdiqUIModule,
    PipesModule,
    DdiqDirectivesModule,
    SharedModule,
    OiqSourcesModule,
    NgPipesModule,
    AlertsModule,
    OiqRiskModule,
    DdiqUIModule,
    DdiqCorporateRecordsModule,
    NetworkModule,
    OiqRelatedEntityModule,
    ThemedEntityModule,
    OiqProfessionalLicensesModule,
    ProfileFiltersModule,
    DdiqUpgradeModule,
    ProfileInputModule,
    AuditModule,
  ],
  declarations: [CorporateInfoComponent, DdiqPersonBasicInfoComponent, PIQCertifiedSupplier],
  exports: [CorporateInfoComponent, DdiqPersonBasicInfoComponent, PIQCertifiedSupplier],
})
export class DdiqGeneralInformationModule {}
