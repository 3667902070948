<div class="nav-filter">
  <div class="filter">
    <ddiq-association-slider class="association-slider"></ddiq-association-slider>
  </div>

  <div class="filter">
    <ddiq-date
      [inputId]="'date-from'"
      [label]="'Date From:'"
      [size]="'half'"
      [model]="dates.after"
      [options]="dates.options.after"
      [datePattern]="datePattern"
      (modelChange)="onAfterDateChange($event)"></ddiq-date>
    <ddiq-date
      [inputId]="'date-to'"
      [label]="'Date To:'"
      [size]="'half'"
      [model]="dates.before"
      [options]="dates.options.before"
      [datePattern]="datePattern"
      (modelChange)="onBeforeDateChange($event)"></ddiq-date>
    <div class="quicklink">
      <a (click)="setLastDateRange(30)">Last 30 days</a> | <a (click)="setLastDateRange(1, 'years')">Last year</a> |
      <a (click)="setLastDateRange(dateFilterMaximumYears, 'years')">Last {{dateFilterMaximumYears}} years</a>
    </div>
  </div>

  <oiq-disposition-filter-toggles
    *ngIf="adjudicationEnabled"
    [toggles]="dispositionToggles"></oiq-disposition-filter-toggles>

  <form name="filterSection">
    <fieldset>
      <legend class="title">Hide Sections:</legend>
      <div *ngFor="let section of sections | filterBy: ['isConfigured']: true">
        <label class="filter-sm" for="filter-check-{{section.metadata.sectionId}}" title="{{sectionState(section)}}">
          <input
            type="checkbox"
            name="filter-check-{{section.metadata.sectionId}}"
            [checked]="section.metadata.filtered"
            id="filter-check-{{section.metadata.sectionId}}"
            [(ngModel)]="section.metadata.filtered"
            name="filter-check-{{section.metadata.sectionId}}"
            (change)="toggleReportSection(section)" />
          {{section.metadata.navLinkText}}
        </label>
      </div>
    </fieldset>
  </form>

  <div class="filter">
    <button type="button" (click)="reset()" class="btn btn-primary btn-sm right" [disabled]="!filtered">
      Clear Filters
    </button>
  </div>
</div>
