import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ddiq-professional-license',
  templateUrl: './ddiq-professional-license.component.tpl.html',
})
export default class DdiqProfessionalLicenseComponent implements OnInit {
  @Input() license;
  @Input() isAdjudicateable;
  hasAdditionalData: boolean;

  constructor() {}

  ngOnInit() {
    this.setHasAdditionalData();
  }

  setHasAdditionalData() {
    const license = this.license;

    this.hasAdditionalData =
      license &&
      ((license.jobFunctions || []).length ||
        (license.specialties || []).length ||
        (license.specialties || []).length ||
        (license.additionalData || []).length);
  }
}
