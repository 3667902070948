import { Component, Input } from '@angular/core';
import ProfileDisplayService from '../common-services/profile-display.service';

@Component({
  selector: 'ddiq-expand-all-warning',
  templateUrl: 'ddiq-expand-all-warning.component.tpl.html',
})
export default class DdiqExpandAllWarningComponent {
  @Input() profile;

  constructor(private profileDisplayService: ProfileDisplayService) {}

  show() {
    return this.profileDisplayService.showWarning();
  }
}
