import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import EntityCache from '../common-services/entity-cache.service';

@Injectable({ providedIn: 'root' })
export default class NetworkResolverService implements Resolve<any> {
  constructor(private entityCache: EntityCache) {}

  resolve(route: ActivatedRouteSnapshot) {
    let entityId = route.params.entityId;
    let entityType = route.params.entityType;

    return this.entityCache.fetchAsEntity(entityType, entityId).then(
      function success(Entity) {
        return Promise.resolve(Entity);
      },
      function error() {
        return Promise.resolve();
      }
    );
  }
}
