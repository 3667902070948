export class EventGroupsTemplate {
  eventGroupsName: string;
  EventGroupBuilderService: any;
  EventGroupsBuilderService: any;

  constructor(eventGroupsName, EventGroupBuilderService, EventGroupsBuilderService) {
    this.eventGroupsName = eventGroupsName;
    this.EventGroupBuilderService = EventGroupBuilderService;
    this.EventGroupsBuilderService = EventGroupsBuilderService;
  }

  toggleEventGroups() {
    return (section, isOpen, canOpenPredicate = (eventGroup: any) => true): boolean => {
      const eventGroups = section.data[this.eventGroupsName];
      let eventGroupsToggledCount = 0;
      let isToggled = false;

      if (!eventGroups || section.metadata.filtered) return;

      for (let i = 0; i < eventGroups.list.length; i++) {
        const eventGroup = eventGroups.list[i];

        if (canOpenPredicate(eventGroup)) {
          const copy = this.EventGroupBuilderService.newBuilder().from(eventGroup).build();
          copy.isEventGroupOpen = isOpen;

          for (const discovery of copy.discoveries) {
            discovery.isOpen = isOpen;
          }

          eventGroups.list[i] = copy;
          eventGroupsToggledCount += 1;
          isToggled = true;
        }
      }
      eventGroups.isAllEventGroupsOpen = isOpen && eventGroupsToggledCount === eventGroups.list.length;

      return isToggled;
    };
  }

  refreshEventGroupsOnSection() {
    return (section) => {
      const data = section.data;

      if (!data[this.eventGroupsName]) return;
      data[this.eventGroupsName] = this.EventGroupsBuilderService.from(data[this.eventGroupsName]).build();
    };
  }
}

export default EventGroupsTemplate;
