import { Component, DoCheck, Input, OnInit } from '@angular/core';
import jQuery from 'jquery';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ModalTrackingService from '../modal/modal-tracking.service';
let $ = jQuery;

@Component({
  selector: 'ddiq-error-modal',
  templateUrl: './ddiq-error-modal.component.tpl.html',
})
export default class ErrorModalComponent implements OnInit, DoCheck {
  @Input() trigger;
  @Input() modalName;
  @Input() focus;
  @Input() closeable;

  previousOpen = false;
  vm;
  name;
  hasCloseBtn;
  results;

  constructor(private messageBusService: MessageBusService, private modalTrackingService: ModalTrackingService) {}

  ngOnInit() {
    this.name = this.modalName || '';
    this.hasCloseBtn = typeof this.closeable !== 'undefined';
  }

  ngDoCheck() {
    this.vm = this.modalTrackingService.getTriggers()[this.trigger];

    if (this.vm.open !== this.previousOpen) {
      if (this.vm.open) {
        if (this.vm.template === 'submissionError') {
          this.results = this.vm.errorInfo;
        }

        if (this.focus) {
          $('#' + this.focus).trigger('focus');
        }

        this.messageBusService.send({ type: 'event:' + this.name + '-modal-opened' });
      } else {
        this.vm.title = '';
        this.vm.template = '';
      }
      this.previousOpen = this.vm.open;
    }
  }

  close() {
    this.vm.open = false;
  }
}
