<header [ngClass]="{'monitor-revamp': isMonitorRevampEnabled}">
  <h1>Monitored Profiles</h1>
  <ddiq-monitor-sort-by
    *ngIf="isMonitorRevampEnabled"
    [query]="query"
    (initSort)="search($event.query, dates, true)"></ddiq-monitor-sort-by>
</header>

<ddiq-error-modal id="error-modal" class="ddiq-modal" trigger="error" closeable></ddiq-error-modal>

<section *ngIf="!isMonitorRevampEnabled" class="review">
  <form novalidate #find="ngForm" name="find" id="find" (ngSubmit)="search( query, dates, true );">
    <ddiq-text
      id="profile-name-input"
      name="profileName"
      label="Profile Name"
      size="third"
      [(model)]="query.q"></ddiq-text>
    <ddiq-date
      id="date-from-input"
      inputId="date-from"
      label="Date From"
      size="fixed-date"
      [(model)]="dates.after"
      [options]="dates.options.after"
      (modelChange)="onAfterDateChange($event)"
      [labelPattern]="labelPattern"
      [datePattern]="datePattern"></ddiq-date>

    <ddiq-date
      id="date-to-input"
      inputId="date-to"
      label="Date To"
      size="fixed-date"
      [(model)]="dates.before"
      [options]="dates.options.before"
      (modelChange)="onBeforeDateChange(date)"
      [labelPattern]="labelPattern"
      [datePattern]="datePattern"></ddiq-date>

    <ddiq-text
      id="profile-reference-id-input"
      name="referenceId"
      [label]="'REFERENCE_ID' | translatef"
      size="quarter"
      [(model)]="query.id"></ddiq-text>
    <ddiq-select
      name="groupUsers"
      id="owner-input"
      [hidden]="!groupUsers.length"
      size="quarter"
      [label]="'Owner' | translatef"
      [(model)]="query.owner"
      [options]="groupUsers"
      prompt=""
      value="username"
      displayValue="fullName"
      [oiqTextDisabled]="formDisabled">
    </ddiq-select>
    <ddiq-text
      id="profile-identification"
      name="profileIdentification"
      size="sixth"
      label="Identification"
      [hidden]="!isGovIdSearchEnabled"
      [(model)]="query.govId"
      [textDisabled]="formDisabled"></ddiq-text>
    <screening-hit-category></screening-hit-category>
    <br class="clr" />
    <button
      type="submit"
      id="find-profiles-button"
      class="btn btn-primary"
      [ngClass]="{disabled : find.invalid}"
      [disabled]="find.invalid">
      Filter
    </button>
    <button type="button" id="clear-button" class="btn btn-danger" (click)="clearSearch( query, dates );">Clear</button>
  </form>

  <hr class="clr" />

  <section class="inline-tabs">
    <ul *ngIf="!isScreeningFeatureEnabled">
      <li [ngClass]="{ active: currentTab == 0 }">
        <a (click)="changeTab(0)">Open <span [hidden]="!counts.hitCount">({{counts.hitCount}})</span></a>
      </li>
      <li [ngClass]="{ active: currentTab == 1 }">
        <a (click)="changeTab(1)">Closed <span [hidden]="!counts.closedCount">({{counts.closedCount}})</span></a>
      </li>
      <li [ngClass]="{ active: currentTab == 2 }" [hidden]="!canViewConfiguration">
        <a (click)="changeTab(2)">Reports <span [hidden]="!counts.reportCount">({{counts.reportCount}})</span></a>
      </li>
    </ul>

    <br class="clr" />

    <div [hidden]="currentTab !== 0">
      <ddiq-monitor-hit-search
        [isQueryEmpty]="isQueryEmpty()"
        [isGovIdSearchEnabled]="isGovIdSearchEnabled"
        [lastQueryString]="lastQueryString">
      </ddiq-monitor-hit-search>
    </div>

    <div *ngIf="!isScreeningFeatureEnabled" [hidden]="currentTab !== 1">
      <ddiq-monitor-closed-search [isQueryEmpty]="isQueryEmpty()" [lastQueryString]="lastQueryString">
      </ddiq-monitor-closed-search>
    </div>

    <div *ngIf="!isScreeningFeatureEnabled" [hidden]="currentTab !== 2">
      <ddiq-monitor-daily-report-search [isQueryEmpty]="isQueryEmpty()" [lastQueryString]="lastQueryString">
      </ddiq-monitor-daily-report-search>
    </div>
  </section>
</section>

<section *ngIf="isMonitorRevampEnabled" class="review monitor-revamp">
  <ddiq-drawer class="monitor-drawer">
    <form
      class="monitor-filters-form"
      novalidate
      #find="ngForm"
      name="find"
      id="find"
      (ngSubmit)="search( query, dates, true );">
      <div class="monitor-legacy-filters">
        <ddiq-text name="profileName" label="Profile Name" [(model)]="query.q"></ddiq-text>
        <ddiq-date
          label="Date From"
          [(model)]="dates.after"
          [options]="dates.options.after"
          (modelChange)="onAfterDateChange($event)"
          [labelPattern]="labelPattern"
          [datePattern]="datePattern"></ddiq-date>
        <ddiq-date
          label="Date To"
          [(model)]="dates.before"
          [options]="dates.options.before"
          (modelChange)="onBeforeDateChange($event)"
          [labelPattern]="labelPattern"
          [datePattern]="datePattern"></ddiq-date>
        <ddiq-text name="referenceId" [label]="'REFERENCE_ID' | translatef" [(model)]="query.id"></ddiq-text>
        <ddiq-select
          [hidden]="!groupUsers.length"
          [label]="'Owner' | translatef"
          [(model)]="query.owner"
          [options]="groupUsers"
          prompt=""
          value="username"
          displayValue="fullName"
          name="groupUsers"
          [oiqTextDisabled]="formDisabled"></ddiq-select>
        <ddiq-text
          name="profileIdentification"
          label="Identification"
          [hidden]="!isGovIdSearchEnabled"
          [(model)]="query.govId"
          [textDisabled]="formDisabled"></ddiq-text>
      </div>

      <ddiq-monitor-filters [query]="query"></ddiq-monitor-filters>

      <div class="monitor-filters-btn">
        <button type="submit" class="btn btn-primary" [ngClass]="{disabled : find.invalid}" [disabled]="find.invalid">
          Apply
        </button>
        <button type="button" class="btn btn-danger" (click)="clearSearch( query, dates );">Clear</button>
      </div>
    </form>
  </ddiq-drawer>

  <ddiq-monitor-hits-container [searchQuery]="searchQuery"></ddiq-monitor-hits-container>
</section>
