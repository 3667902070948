import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayExclude',
})
export default class ArrayExcludePipe implements PipeTransform {
  constructor() {}

  /**
   * Returns Modified array of `inputArray` with all `itemToExclude`s removed
   * @param inputArray
   * @param itemToExclude
   * @returns {Array}
   */
  transform(inputArray: any, itemToExclude: any) {
    return (inputArray || []).filter(function (item) {
      return item !== itemToExclude;
    });
  }
}
