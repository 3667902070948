<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<div class="modal" *ngIf="vm && vm.open">
  <div *ngIf="!hasCloseBtn" class="modal-dialog">
    <div class="modal-transclude">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
  <div *ngIf="hasCloseBtn">
    <div>
      <button class="modal-close-btn" type="button" (click)="closeClicked()"></button>
      <div class="modal-transclude">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </div>
</div>
