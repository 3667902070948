<div
  class="oiq-adjudication-actions"
  *ngIf="isAdjudicationEnabled && canAdjudicate"
  [ngClass]="{ 'is-bulk-adjudicating': isBulkAdjudicating() }">
  <div class="inline reasons" *ngIf="!(isBusy || isEditingComment)">
    <span
      class="fa fa-lg"
      *ngIf="isConfirmActionEnabled()"
      title="{{ 'CONFIRM_CONTENT' | translatef }}"
      (click)="handleConfirmation()"
      [ngClass]="{'fa-check-square-o oiq-adjudication-actions-unconfirm': model.confirmed, 'fa-square-o oiq-adjudication-actions-confirm': !model.confirmed}">
    </span>

    <span
      class="fa fa-lg"
      *ngIf="isStarActionEnabled()"
      title="{{ 'ESCALATE_CONTENT' | translatef }}"
      (click)="!model.confirmed && handleEscalation()"
      [ngClass]="{'fa-star oiq-adjudication-actions-deescalate': model.escalated, 'fa-star-o oiq-adjudication-actions-escalate': !model.escalated, 'disabled': model.confirmed}">
    </span>

    <span
      class="fa fa-lg"
      title="{{ 'REMOVE_CONTENT' | translatef }}"
      (click)="!model.confirmed && handleClick()"
      [ngClass]="{'fa-plus oiq-adjudication-actions-add': model.disabled, 'fa-times oiq-adjudication-actions-remove': !model.disabled, 'disabled': model.confirmed}">
    </span>

    <div [hidden]="!isActive" class="adjudication-reason hide-reason" (mouseleave)="cancel()">
      <div *ngFor="let adjudicationReason of getAdjudicationReasons()">
        <span (click)="save(adjudicationReason)" class="adjudication-reason-label"
          >{{adjudicationReason | translatef}}</span
        >
      </div>
    </div>
  </div>

  <span
    class="inline fa fa-commenting-o fa-lg oiq-adjudication-actions-comment"
    title="{{ 'ADD_NOTES' | translatef }}"
    (click)="editComment()"
    *ngIf="!(isBusy || isEditingComment)">
  </span>

  <div *ngIf="isBusy">
    <img src="./img/loading.gif" class="adjudicating" />
  </div>
</div>
