import { Injectable } from '@angular/core';
import MessageBusService from './message-bus.service';
import HttpBufferService from '../http/http-buffer.service';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  private authSuccessCallback: Function;

  constructor(private httpBuffer: HttpBufferService, private messageBusService: MessageBusService) {}

  loginConfirmed() {
    this.messageBusService.send({ type: 'event:auth-loginConfirmed' });
    this.httpBuffer.retryAll();
    if (this.authSuccessCallback) {
      this.authSuccessCallback();
    }
  }

  setAuthSuccessCallback(authCb) {
    this.authSuccessCallback = authCb;
    this.authSuccessCallback();
  }
}
