import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeAdjudicationActions',
})
export default class ExcludeAdjudicationActionsPipe implements PipeTransform {
  constructor() {}

  /**
   * Filters array items based on items in `excluded` array matching with `adjudicationEntries` action key
   * @param adjudicationEntries
   * [
   *    {
   *      ...
   *      action:
   *    }
   * ]
   * @param excluded
   * @returns {Array}
   */
  transform(adjudicationEntries: any, excluded: any) {
    return (adjudicationEntries || []).filter(function (adjudicationEntry) {
      return (excluded || []).indexOf(adjudicationEntry.action) === -1;
    });
  }
}
