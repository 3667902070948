import { Component, Host, Input } from '@angular/core';
import DropDownComponent from './oiq-drop-down.component';
@Component({
  selector: 'oiq-drop-down-toggle',
  template: ` <span title="{{ toggleTitle || 'Click to open' }}">
    <ng-content></ng-content>
    <span [hidden]="!isOpen" class="risk-summary-chevron btn-link">
      <i class="fa fa-caret-up"></i>
    </span>
    <span [hidden]="isOpen" class="risk-summary-chevron btn-link">
      <i class="fa fa-caret-down"></i>
    </span>
  </span>`,
})
export default class DropDownToggleComponent {
  @Input() toggleTitle;

  constructor(@Host() private dropDownComponent: DropDownComponent) {}

  get isOpen() {
    return this.dropDownComponent.isOpen;
  }
}
