import { Pipe, PipeTransform } from '@angular/core';
import { FilterConstants } from './ddiq-filters.constants';

@Pipe({
  name: 'antiCollideClassification',
})
export default class AntiCollideClassificationPipe implements PipeTransform {
  constructor() {}

  /**
   * Checks if `classification` exists in the antiCollideClassifications array
   * and returns String appended by `UNS_` if it exists
   * @param classification
   * @returns {String}
   */
  transform(classification: string) {
    if (FilterConstants.antiCollideClassifications.indexOf(classification) > -1) {
      return 'UNS_' + classification;
    }
    return classification;
  }
}
