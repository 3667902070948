import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-inmate-profile',
  templateUrl: './ddiq-inmate-profile.component.tpl.html',
})
export default class DdiqInmateProfileComponent {
  @Input() profile: any;
  @Input() isAdjudicateable: boolean;

  constructor() {}
}
