import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'yearFormat',
})
export default class YearFormatPipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  /**
   * Returns a YYYY year string
   * input can be a string or an object with a `year` key
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    if (typeof input !== 'undefined') {
      if (typeof input === 'string') {
        // MMM d, y
        let date = this.date.transform(input, 'mediumDate');
        return date.substring(date.length - 4, date.length);
      } else if (typeof input === 'object') {
        return input.year;
      }
    } else {
      return input;
    }
  }
}
