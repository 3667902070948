<div #entityAware="oiqEntityAware" oiq-entity-aware>
  <ng-container *ngFor="let group of groups.list; trackBy:classification">
    <article *ngIf="group.hasDiscoveries" class="panel oiq-corporate-records discovery-group">
      <div data-oiq-collapsible-ctrl [expandAll]="expandAll || group.isEventGroupOpen">
        <header class="panel-header oiq-corporate-records-header">
          <oiq-group-dynamic-adjudication-aware
            [adjudicationType]="adjudicationType"
            [section]="idPrefix"
            [classification]="adjudicationType">
          </oiq-group-dynamic-adjudication-aware>
          <h3 class="panel-title oiq-corporate-records-title">{{group.title}}</h3>
          <div class="panel-header-right">
            <ddiq-sort-buttons
              *ngIf="group.hasMoreEntries"
              class="pull-left btn-sort-group-margin"
              [entities]="group.discoveries"
              [orderBy]="'association, adjudication'"
              (onClick)="sort({ sortName: $event.btnName, eventGroup: group})"
              [activeBtn]="group.activeSortButtons">
            </ddiq-sort-buttons>
            <button class="btn btn-sm btn-default" *ngIf="group.hasMoreEntries" data-oiq-collapsible-toggle>
              <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
              View all {{(group.discoveries | moreEntries).length + 1}} events
            </button>
          </div>
        </header>

        <section class="panel-body">
          <article
            class="oiq-corporate-records-featured-incident"
            *ngFor="let featured of group.discoveries | firstUnfilteredDiscovery">
            <ddiq-corporate-record
              [record]="featured"
              [adjudicationType]="adjudicationType"
              [isAdjudicateable]="entityAware.isAdjudicatable">
            </ddiq-corporate-record>
          </article>

          <article class="oiq-corporate-records-more-entries discovery-group" *ngIf="group.hasMoreEntries">
            <ddiq-collapsible>
              <ddiq-corporate-record
                class="oiq-corporate-records-more-entry"
                *ngFor="let entry of group.discoveries | moreEntries; trackBy:id"
                [record]="entry"
                [adjudicationType]="adjudicationType"
                [isAdjudicateable]="entityAware.isAdjudicatable">
              </ddiq-corporate-record>
            </ddiq-collapsible>
          </article>
        </section>
      </div>
    </article>
  </ng-container>
</div>

<p *ngIf="groups.list.length === 0" class="oiq-corporate-records-msg-no-results">No results discovered</p>

<p *ngIf="groups.showAllFilteredMessage" class="oiq-corporate-records-msg-all-filtered">All results filtered</p>
