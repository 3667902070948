import { HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import ConfigService from '../../../../../shared/services/http/config.service';
import SessionTermininationMessageService from './session-termination-message.service';
import utils from '../../../exiger/utils';

export default function () {
  return {
    provide: APP_INITIALIZER,
    useFactory: (
      httpBackend: HttpBackend,
      configService: ConfigService,
      sessionTerminationMessage: SessionTermininationMessageService
    ) => {
      return () => {
        // creating the httpclient with the backend bypasses interceptors.
        return new HttpClient(httpBackend)
          .get(`${configService.settings.project.apiUrl}rest/system/sessionTerminationMessage`, {
            responseType: 'text',
          })
          .toPromise()
          .then((response) => {
            const loggedOut = utils.getParameter('loggedOut', window.location);
            const setShowMessage = loggedOut ? true : false;
            const terminationMessage = response !== null ? response : '';

            sessionTerminationMessage.setShowMessage(setShowMessage);
            sessionTerminationMessage.setMessage(terminationMessage);
          });
      };
    },
    deps: [HttpBackend, ConfigService, SessionTermininationMessageService],
    multi: true,
  };
}
