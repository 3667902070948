import { Component, Input, OnChanges, OnInit } from '@angular/core';
import utils from '../../../exiger/utils';
import ImageInDbPipe from './image-in-db.pipe';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';

@Component({
  selector: 'watch-list-detail',
  templateUrl: './watch-list-detail.component.tpl.html',
})
export default class WatchListDetail implements OnInit, OnChanges {
  @Input() isOpen;
  @Input() watchList;
  @Input() isDisplayClassifications;

  imageAnchors;
  source;
  moreSources;
  event;
  moreEvents;
  hasEventsWithDescription;
  imagesInDb;
  hasImagesToRender: boolean;

  constructor(private imageInDbPipe: ImageInDbPipe, private translatefPipe: TranslatefPipe) {}

  ngOnInit() {
    if (this.watchList.events) {
      this.hasEventsWithDescription = this.watchList.events.some((event) => !!event.description);
    }
    const images = this.getImagesWithProperties();
    this.imagesInDb = this.getImagesInDb(images).slice(0, 2);
    this.imageAnchors = this.getRemainingImagesWithoutDuplicates(images);
    this.hasImagesToRender = this.imagesInDb.length || this.imageAnchors.length;

    this.isOpen = this.isOpen || utils.getParameter('print', location.href) === 'expanded';
  }

  ngOnChanges() {
    this.source = (this.watchList.seedSources || [])[0];
    this.moreSources = (this.watchList.seedSources || []).slice(1);

    this.event = (this.watchList.events || [])[0];
    this.moreEvents = (this.watchList.events || []).slice(1);
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  hasMoreData() {
    let moreSources = this.moreSources || [],
      moreEvents = this.moreEvents || [];

    return (
      !!moreSources.length ||
      !!moreEvents.length ||
      this.hasRelatedEntities() ||
      this.hasAdditionalData() ||
      this.hasAdditionalCategories() ||
      this.hasAssociatedCountryCodes() ||
      this.hasGovernmentIds() ||
      this.hasPhoneNumbers()
    );
  }

  hasAdditionalData() {
    return this.watchList.additionalData && this.watchList.additionalData.length;
  }

  hasAdditionalCategories() {
    return this.watchList.additionalCategories && this.watchList.additionalCategories.length;
  }

  hasAssociatedCountryCodes() {
    return this.watchList.associatedCountryCodes && this.watchList.associatedCountryCodes.length;
  }

  hasGovernmentIds() {
    return this.watchList.governmentIds && this.watchList.governmentIds.length;
  }

  hasPhoneNumbers() {
    return this.watchList.phoneNumbers && this.watchList.phoneNumbers.length;
  }

  hasRelatedEntities() {
    return this.watchList.relatedEntities && this.watchList.relatedEntities.length;
  }

  trackByIndex(index) {
    return index;
  }

  private getRemainingImagesWithoutDuplicates(images) {
    return images.filter((image) => image.sourceUrl && this.imagesInDb.indexOf(image) === -1);
  }

  private getImagesInDb(images) {
    return this.imageInDbPipe.transform(images).map((image) => {
      image.isInDb = true;
      return image;
    });
  }

  private getImagesWithProperties() {
    return (this.watchList.images || []).filter((image) => Object.keys(image).length);
  }
}
