import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withRole',
})
export default class WithRolePipe implements PipeTransform {
  constructor() {}

  /**
   * Returns an array filtered by 'role'
   * inverse will return all items that were not filtered
   * @param users
   * @param role
   * @param inverse
   * @returns {Array}
   */
  transform(users: any, role: any, inverse?: any) {
    return (users || []).filter(function (user) {
      if (inverse) {
        return (user.roles || []).indexOf(role) === -1;
      }
      return (user.roles || []).indexOf(role) > -1;
    });
  }
}
