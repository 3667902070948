import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class EventSeverityService {
  fromIncident(incident) {
    if (!incident || !Array.isArray(incident.summary)) {
      return 0;
    }

    return incident.summary[0].severity;
  }
}
