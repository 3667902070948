import { NgModule } from '@angular/core';
import { ExternalUrlMsgDirective } from './ddiq-external-url-msg.directive';
import ExternalUrlMsgService from './ddiq-external-url-msg.service';
import { ExternalUrlMsgComponent } from './ddiq-external-url-msg.component';
import { DdiqModalModule } from '../modal/ddiq-modal.module';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@NgModule({
  imports: [DdiqModalModule],
  providers: [ExternalUrlMsgService, WindowRefService],
  declarations: [ExternalUrlMsgDirective, ExternalUrlMsgComponent],
  exports: [ExternalUrlMsgDirective],
})
export class ExternalUrlMsgModule {}
