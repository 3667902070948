import { Component, Input } from '@angular/core';
import PartialUtils from '../common-services/partial-utils.factory';
import DoingBusinessInLocationSubmissionPipe from '../ddiq-filters/doing-business-in-location-submission.pipe';
import IncorporatedInLocationSubmissionPipe from '../ddiq-filters/incorporated-in-location-submission.pipe';
import Entity from '../common-services/entity.service';

@Component({
  selector: 'company-input-data-detail',
  templateUrl: './company-input-data-detail.component.tpl.html',
  preserveWhitespaces: true,
})
export default class CompanyInputDataDetailComponent {
  @Input() seedData;
  @Input() section;
  isAddressFilled;
  importedFrom;

  constructor(
    private doingBusinessInLocationSubmission: DoingBusinessInLocationSubmissionPipe,
    private incorporatedInLocationSubmission: IncorporatedInLocationSubmissionPipe,
    private partialUtils: PartialUtils,
    private entity: Entity
  ) {
    this.isAddressFilled = this.partialUtils.isAddressFilled;
    this.importedFrom = this.entity.getData().importedFrom;
  }

  hasIncorporatedInLocation() {
    return !!this.incorporatedInLocationSubmission.transform(this.seedData.otherLocations).length;
  }

  hasDoingBusinessInLocations() {
    return !!this.doingBusinessInLocationSubmission.transform(this.seedData.otherLocations).length;
  }
}
