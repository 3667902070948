import { Component, OnDestroy, OnInit } from '@angular/core';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import _ from 'underscore';
import OiqProperties from '../common-services/oiq-properties.service';
import Entity from '../common-services/entity.service';
import AddressPipe from '../ddiq-filters/address.pipe';
import { DatePipe } from '@angular/common';
import RelatedEntityProcessor from '../themed-entity/related-entity-processor.service';
import RelationshipService from '../common-services/relationship-service.factory';
import RelationshipFilter from '../themed-entity/relationship-filter.service';
import CompanyBuildService from '../themed-entity/company-build.service';

import { Router } from '@angular/router';

@Component({
  selector: 'ddiq-related-companies',
  templateUrl: './ddiq-related-companies.component.tpl.html',
})
export default class DdiqRelatedCompaniesComponent implements OnInit, OnDestroy {
  companyProcessor;
  companies = {
    count: 0,
    submitted: [],
    acquirer: [],
    acquisitions: [],
    parent: [],
    subsidiary: [],
    owner: [],
    fundingfrom: [],
    fundingto: [],
    supplier: [],
    consumer: [],
    collaboration: [],
    competitor: [],
    employers: [],
    referenced: [],
  };
  alertsEnabled;
  isRelatedEntityAdjudicationEnabled;
  filterObserver;
  listAlertCount;
  constructor(
    private router: Router,
    private addressPipe: AddressPipe,
    private datePipe: DatePipe,
    private relatedEntityProcessor: RelatedEntityProcessor,
    private companyBuildService: CompanyBuildService,
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private relationshipService: RelationshipService,
    private adjudicationFeature: AdjudicationFeatureService,
    private relationshipFilter: RelationshipFilter
  ) {}

  ngOnInit() {
    this.companyProcessor = this.relatedEntityProcessor.for('company');
    this.alertsEnabled = this.oiqProperties.subCompanyAlertsEnabled;
    this.isRelatedEntityAdjudicationEnabled =
      this.adjudicationFeature.isRelatedEntityAdjudicationEnabled() && this.entity.isAdjudicatableByUser();
    this.filterObserver = () => this.initCompaniesList(false);
    this.relationshipFilter.registerAfterFilterObserver(this.filterObserver);

    this.initCompaniesList(false);
  }

  ngOnDestroy() {
    this.relationshipFilter.unRegisterAfterFilterObserver(this.filterObserver);
  }

  refresh() {
    this.initCompaniesList(true);
  }

  initCompaniesList(forceReload) {
    if (forceReload) {
      this.relationshipService.companies(this.entity.getType(), this.entity.getId(), forceReload).then(() => {
        this.setCompaniesData();
      });
      return;
    }

    this.setCompaniesData();
  }

  setCompaniesData() {
    let companies;
    if (!this.entity.isDiff()) {
      companies = this.relationshipService.getCompanies();
    } else {
      companies = this.relationshipService.getCompaniesDiff();
    }
    return this.processCompanies(companies);
  }

  processCompanies(comps) {
    comps = this.companyProcessor.processEntities(comps);
    comps = this.calculateSingleLineAddress(comps);

    // should bucket into: submitted, employers, referenced
    this.companies = this.bucketCompanies(comps);
    this.companies.submitted = initPositions(this.companies.submitted);
    this.companies.employers = initPositions(this.companies.employers);
    this.updatePositions(_.union(this.companies.submitted, this.companies.employers));
    this.companies.employers = sortCurrentPositionToTop(this.companies.employers);

    this.listAlertCount = this.alertsEnabled ? this.companyProcessor.countAlerts(comps) : 0;

    this.companies.count =
      this.companies.submitted.length +
      this.companies.acquirer.length +
      this.companies.acquisitions.length +
      this.companies.parent.length +
      this.companies.subsidiary.length +
      this.companies.owner.length +
      this.companies.fundingfrom.length +
      this.companies.fundingto.length +
      this.companies.supplier.length +
      this.companies.consumer.length +
      this.companies.collaboration.length +
      this.companies.competitor.length +
      this.companies.employers.length +
      this.companies.referenced.length;

    this.relationshipService.setCompanyCount(this.companies.count);
    // pass through to check for pending reports
    return comps;
  }

  updatePositions(companies) {
    let positions = this.markupPositions(this.entity.getData().positions || []);

    // if a position org matches (strict string comparison) an employer
    // name, add it to the employer object
    _.forEach(positions, (position) => {
      let matchCompany;

      (companies || []).some((company) => {
        if (company.base.name === position.organization) {
          matchCompany = company;
        }
        return !!matchCompany;
      });

      if (matchCompany && position.title) {
        matchCompany.base.positions.push(position);
      } else {
        // didn't match the position, so create an additional employer
        companies.push(
          this.companyProcessor.processEntity({
            base: {
              name: position.organization,
              positions: position.title ? [position] : [],
            },
          })
        );
      }
    });

    return companies;
  }

  markupPositions(positions) {
    return _.map(positions, (position) => {
      position.isDated = isPositionDated(position);
      position.uiStartDate = this.getStartDate(position);
      position.uiEndDate = this.getEndDate(position);

      return position;
    });
  }

  getEndDate(position) {
    return position.isCurrent ? 'Present' : this.datePipe.transform(position.endDate, 'MMM, yyyy');
  }

  getStartDate(position) {
    return this.datePipe.transform(position.startDate, 'MMM, yyyy');
  }

  calculateSingleLineAddress(entities) {
    return _.map(entities, (entity) => {
      entity.base.singleLineAddress = this.addressPipe.transform(entity.base.address);
      return entity;
    });
  }

  bucketCompanies(entities) {
    const parentRel = 'Parent',
      subsidiaryRel = 'Subsidiary',
      ownerRel = 'Owner',
      entityAcquiredByRel = 'EntityAcquiredBy',
      entityAcquiresRel = 'EntityAcquires',
      receivingFundingFromRel = 'ReceivedFundingFrom',
      providedFundingToRel = 'ProvidedFundingTo',
      supplierRel = 'Supplier',
      consumerRel = 'Consumer',
      businessCollaborationRel = 'BusinessCollaboration',
      competitorRel = 'Competitor',
      employedByRel = 'EmployedBy',
      associatedWithRel = 'AssociatedWith';

    return this.companyProcessor.getBucketedEntities(entities, [
      {
        bucket: 'submitted',
        matchEntity: (entity) => this.companyProcessor.isSubmitted(entity),
        onBucketed: this.onBucketed(associatedWithRel, 'SUBMITTED'),
      },
      {
        bucket: 'parent',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, parentRel);
        },
        onBucketed: this.onBucketed(parentRel),
      },
      {
        bucket: 'subsidiary',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, subsidiaryRel);
        },
        onBucketed: this.onBucketed(subsidiaryRel),
      },
      {
        bucket: 'owner',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, ownerRel);
        },
        onBucketed: this.onBucketed(ownerRel),
      },
      {
        bucket: 'acquirer',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, entityAcquiredByRel);
        },
        onBucketed: this.onBucketed(entityAcquiredByRel),
      },
      {
        bucket: 'acquisitions',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, entityAcquiresRel);
        },
        onBucketed: this.onBucketed(entityAcquiresRel),
      },

      {
        bucket: 'fundingfrom',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, receivingFundingFromRel);
        },
        onBucketed: this.onBucketed(receivingFundingFromRel),
      },
      {
        bucket: 'fundingto',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, providedFundingToRel);
        },
        onBucketed: this.onBucketed(providedFundingToRel),
      },
      {
        bucket: 'supplier',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, supplierRel);
        },
        onBucketed: this.onBucketed(supplierRel),
      },
      {
        bucket: 'consumer',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, consumerRel);
        },
        onBucketed: this.onBucketed(consumerRel),
      },
      {
        bucket: 'collaboration',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, businessCollaborationRel);
        },
        onBucketed: this.onBucketed(businessCollaborationRel),
      },
      {
        bucket: 'competitor',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, competitorRel);
        },
        onBucketed: this.onBucketed(competitorRel),
      },
      {
        bucket: 'employers',
        matchEntity: (entity) => {
          return this.companyProcessor.isRelationship(entity, employedByRel);
        },
        onBucketed: this.onBucketed(employedByRel),
      },
      {
        bucket: 'referenced',
        matchEntity: (entity) => {
          return (
            this.companyProcessor.isRelationship(entity, associatedWithRel) &&
            !this.oiqProperties.relationships.disableAssociatedCompanies
          );
        },
        onBucketed: this.onBucketed(associatedWithRel),
      },
    ]);
  }

  /**
   * onBucketed entity
   * @param relationshipName relationship name used to getRelationship
   * @param sourceInfoName optional param initialized to the relationshipName when not provided
   */
  onBucketed(relationshipName, sourceInfoName = relationshipName) {
    return (entity) => {
      entity.relationship = this.companyProcessor.getRelationship(entity.relationships, relationshipName);
      entity.escalated = entity.relationship?.escalated;
      this.companyProcessor.addSourceInfo(entity, sourceInfoName);
      this.relationshipFilter.applyFilters(entity);
    };
  }

  requestReport(company) {
    const parent = this.entity.getData();

    this.relationshipService.clear();
    company.reportRequested = true;
    company.parentCompanyEntityId = company.oiqEntityId;
    company.externalReferenceCode = parent.externalReferenceCode;

    if (this.entity.isPerson()) {
      if (company.positions && company.positions.length >= 1) {
        parent.currentJobTitle = company.positions[0].title;
        parent.currentCompanyName = company.positions[0].organization;
      } else {
        parent.currentCompanyName = company.name;
      }
      if (parent.location && !company.address) {
        company.address = { country: parent.location.country };
      }
      company.people = [parent];
    } else if (this.entity.isCompany() && parent.mainAddress && !company.address) {
      company.address = { country: parent.mainAddress.country };
    }

    this.companyBuildService.queueCompanyToBuild(company);
    this.companyBuildService.setParentUrl(this.router.url);
    this.router.navigate(['/build/company']);
  }
}

function initPositions(employers) {
  return _.map(employers, function (employer) {
    employer.base.positions = [];
    return employer;
  });
}

function sortCurrentPositionToTop(employers) {
  return _.sortBy(employers, function (employer) {
    return _.findWhere(employer.base.positions, {
      isCurrent: true,
    })
      ? -1
      : 0;
  });
}

function isPositionDated(position) {
  return position.isCurrent || position.startDate || position.endDate;
}
