import { Component, Input, OnInit } from '@angular/core';
/*
This component takes a data attribute (string).
If data is a url we create an anchor element for that url and append to DOM.
If data is text containing a url (enclosed by parentheses), we extract the url,
create an anchor element and append the url to its href. The full data becomes the anchor's innerHTML.
If no url in data, we create a span element and append the data to its innerHTML.
*/

@Component({
  selector: 'ddiq-link',
  templateUrl: './ddiq-link.component.tpl.html',
})
export default class LinkComponent implements OnInit {
  //regex matches case in-sensitive http or https that are enclosed in parentheses
  //contained anywhere in the input string.
  private readonly urlRegex = /\((http|https):\/\/.*\)/gi;

  //regex matches case-insensitive http and https at the start of the input string.
  private readonly startsWithProtocol = /^https?:\/\//i;

  @Input() data: any;
  @Input() isDisabled: boolean;
  disabledClass: string;
  href: string;

  ngOnInit() {
    const input = this.data;

    this.disabledClass = this.isDisabled ? 'disabled' : '';

    if (input.match(this.startsWithProtocol)) {
      this.href = input;
    } else if (this.hasUrlInText(input)) {
      const url = this.extractUrl(input);
      this.href = this.removeParentheses(url);
    }
  }

  private hasUrlInText(txt) {
    return this.urlRegex.test(txt);
  }

  private extractUrl(txt) {
    return txt.match(this.urlRegex)[0];
  }

  private removeParentheses(url) {
    return url.substring(1, url.length - 1);
  }
}
