<div id="corporate">
  <div *ngIf="!isDeltaReport">
    <ddiq-crawl-error></ddiq-crawl-error>

    <div class="alert alert-warning" *ngIf="showInsufficientDataMsg">
      <div class="alert-icon">
        <span class="fa fa-exclamation-circle fa-2x"></span>
      </div>
      <div>
        <p>
          Please be advised there may be relevant results scored as 'Uncertain' due to the lack of input data provided.
          To view these results, set the confidence filter on the left hand to 'Uncertain'.
        </p>
        <p *ngIf="user.canSubmitProfile()">
          Alternatively, click the 'Resubmit' button on the top right hand side to resubmit the profile with additional
          input data.
        </p>
      </div>
    </div>

    <ddiq-filtered-messages></ddiq-filtered-messages>
    <ddiq-risk-summary class="non-breaking"></ddiq-risk-summary>
  </div>

  <section *ngIf="!entity.isDiff()">
    <seed-data [section]="section" [hideComments]="isDeltaReport"></seed-data>
  </section>

  <ddiq-accordion-top
    *ngIf="!isDeltaReport && !section.metadata.filtered"
    label="General Information"
    [toggleScope]="section.metadata"
    [open]="isSectionOpen"
    toggle-function="toggleSection"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()"
    [alerts]="alertCount">
    <ddiq-alert-list [source]="corporateAlerts"></ddiq-alert-list>
    <div *ngIf="isAddressFilled(section.data.mainAddress)">
      <strong>Address</strong>
      <ddiq-field-source-info [field]="'address'" [fieldValue]="section.data.mainAddress | address">
        <p>
          <span *ngIf="section.data.mainAddress.streetAddress">{{ section.data.mainAddress.streetAddress }}<br /></span>
          <span *ngIf="section.data.mainAddress.buildingName">{{ section.data.mainAddress.buildingName }}<br /></span>
          <span
            *ngIf="(section.data.mainAddress.district ||section.data.mainAddress.city || section.data.mainAddress.stateProvince)"
            >{{ section.data.mainAddress.district }} {{ section.data.mainAddress.city }} {{
            section.data.mainAddress.stateProvince }}<br
          /></span>
          <span *ngIf="(section.data.mainAddress.zipPostalCode || section.data.mainAddress.country)"
            >{{ section.data.mainAddress.zipPostalCode }} {{ section.data.mainAddress.country }}<br
          /></span>
        </p>
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.dbaNames?.length">
      <strong>Alternative Names</strong>
      <ul class="no-list">
        <li *ngFor="let name of section.data.dbaNames">
          <ddiq-field-source-info [field]="'name'" [fieldValue]="name"> {{ name }} </ddiq-field-source-info>
        </li>
      </ul>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.yearFounded">
      <strong>Year Founded</strong>
      <ddiq-field-source-info
        [field]="'yearFounded'"
        [fieldValue]="section.data.yearFounded"
        data-testid="yearFounded-value">
        {{ section.data.yearFounded }}
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.directOwners?.length">
      <strong>Direct Owners:</strong>
      <ddiq-field-source-info [field]="'ownership'" [fieldValue]="section.data.ownershipRecordId">
        <ul class="no-list">
          <li *ngFor="let owner of section.data.directOwners">
            <span class="no-margin">
              <span class="inline"> {{owner.name}} </span>
              <span class="inline" [hidden]="!owner.percentageInterest"> ({{owner.percentageInterest}}%)</span>
            </span>
          </li>
        </ul>
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.ultimateOwners?.length">
      <strong>Ultimate Owners:</strong>
      <ddiq-field-source-info [field]="'ownership'" [fieldValue]="section.data.ownershipRecordId">
        <ul class="no-list">
          <li *ngFor="let owner of section.data.ultimateOwners">
            <span class="no-margin">
              <span class="inline"> {{owner.name}} </span>
              <span class="inline" [hidden]="!owner.percentageInterest"> ({{owner.percentageInterest}}%)</span>
            </span>
          </li>
        </ul>
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.formerNames?.length">
      <strong>Former Names</strong>
      <ul>
        <li *ngFor="let name of section.data.formerNames">{{ name }}</li>
      </ul>
      <hr class="clr" />
    </div>

    <div *ngIf="hasOtherCompanyData()">
      <strong>Links</strong><br />
      <span id="company_WEBSITE" *ngIf="section.data.website" class="website-link">
        <ddiq-field-source-info
          [field]="'website'"
          [fieldValue]="section.data.website | stripWWW| websiteNameNormalizer ">
          <b>Website:</b>
          <a href="{{ section.data.website }}" target="_blank" class="value">
            {{ section.data.website | websiteNameNormalizer}}
          </a>
          <span class="accordian-alert" *ngIf="hasWebsiteAlert">
            <ddiq-alert-icon></ddiq-alert-icon>
          </span>
        </ddiq-field-source-info>
      </span>
      <span *ngIf="section.data.socialMedia?.facebook.profileUrl">
        <b>Facebook:</b>
        <a href="{{ section.data.socialMedia.facebook.profileUrl }}" target="_blank" class="value">
          {{ section.data.socialMedia.facebook.profileUrl | websiteNameNormalizer }}
        </a>
        <br />
      </span>
      <span *ngIf="section.data.socialMedia?.linkedIn.profileUrl">
        <b>LinkedIn:</b>
        <a href="{{ section.data.socialMedia.linkedIn.profileUrl }}" target="_blank" class="value">
          {{ section.data.socialMedia.linkedIn.profileUrl | websiteNameNormalizer }}
        </a>
        <br />
      </span>
      <span *ngIf="section.data.socialMedia?.twitter.profileUrl">
        <b>Twitter:</b>
        <a href="{{ section.data.socialMedia.twitter.profileUrl }}" target="_blank" class="value">
          {{ section.data.socialMedia.twitter.profileUrl | websiteNameNormalizer }}
        </a>
        <br />
      </span>
      <span *ngIf="section.data.socialMediaUrls?.length">
        <div *ngFor="let socialMedia of section.data.socialMediaUrls">
          <ddiq-field-source-info [field]="'socialMediaUrls'" [fieldValue]="socialMedia">
            <b>{{ socialMedia.title }}:</b>
            <a href="{{ socialMedia.url }}" target="_blank" rel="noopener noreferrer">
              {{ socialMedia.url | websiteNameNormalizer }}
            </a>
          </ddiq-field-source-info>
        </div>
        <br />
      </span>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.description">
      <strong>Description</strong>
      <ddiq-field-source-info
        [field]="'description'"
        [fieldValue]="section.data.description | stripHTML | convertHtmlSpecificChars| truncate:255">
        <p [innerHTML]="section.data.description"></p>
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.currentEmployeeCount">
      <strong>Total Employees</strong>
      <ddiq-field-source-info [field]="'currentEmployeeCount'" [fieldValue]="section.data.currentEmployeeCount">
        {{ formatNumber(section.data.currentEmployeeCount) }}
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div *ngIf="section.data.currentCyberEmployeeCount">
      <strong>Cybersecurity-Related Employees</strong>
      <ddiq-field-source-info
        [field]="'currentCyberEmployeeCount'"
        [fieldValue]="section.data.currentCyberEmployeeCount">
        {{ formatNumber(section.data.currentCyberEmployeeCount) }} ({{
        formatPercentage(section.data.currentCyberEmployeeRatio) }}% of Total Employees)
      </ddiq-field-source-info>
      <hr class="clr" />
    </div>

    <div>
      <div id="company_SIC_CODES" *ngIf="hasSicCodes()">
        <ddiq-accordion label="SIC Codes" openonprint [alerts]="hasClassificationAlert" class="sic-codes">
          <div class="non-breaking" [ngSwitch]="section.data.classifications.ussicClassifications?.length > 0">
            <div *ngSwitchCase="true">
              <div>
                <strong>{{'USSIC' | translatef}}</strong>
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th class="industryDescriptionWidth"><span>{{'Description' | translatef}}</span></th>
                      <th class="industryCodeWidth"><span>{{'Code' | translatef}}</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ussic of section.data.classifications.ussicClassifications">
                      <td>{{ ussic.description }}</td>
                      <td>
                        <ddiq-field-source-info [field]="'ussicClassification'" [fieldValue]="ussic.code">
                          {{ ussic.code }}
                        </ddiq-field-source-info>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="non-breaking" [ngSwitch]="section.data.classifications.naicsClassifications?.length > 0">
            <div *ngSwitchCase="true">
              <div>
                <strong>{{'NAICS' | translatef}}</strong>
                <span class="accordian-alert" *ngIf="hasClassificationAlert">
                  <ddiq-alert-icon></ddiq-alert-icon>
                </span>
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th class="industryDescriptionWidth"><span>{{'Description' | translatef}}</span></th>
                      <th class="industryCodeWidth"><span>{{'Code' | translatef}}</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let naics of section.data.classifications.naicsClassifications">
                      <td>{{ naics.description }}</td>
                      <td>
                        <ddiq-field-source-info [field]="'naicsClassification'" [fieldValue]="naics.code">
                          {{ naics.code }}
                        </ddiq-field-source-info>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="non-breaking" [ngSwitch]="section.data.classifications.uksicClassifications?.length > 0">
            <div *ngSwitchCase="true">
              <div>
                <strong>{{'UKSIC' | translatef}}</strong>
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th class="industryDescriptionWidth"><span>{{'Description' | translatef}}</span></th>
                      <th class="industryCodeWidth"><span>{{'Code' | translatef}}</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let uksic of section.data.classifications.uksicClassifications">
                      <td>{{ uksic.description }}</td>
                      <td>
                        <ddiq-field-source-info [field]="'uksicClassification'" [fieldValue]="uksic.code">
                          {{ uksic.code }}
                        </ddiq-field-source-info>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="non-breaking" [ngSwitch]="section.data.classifications.casicClassifications?.length > 0">
            <div *ngSwitchCase="true">
              <div>
                <strong>{{'CASIC' | translatef}}</strong>
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th class="industryDescriptionWidth"><span>{{'Description' | translatef}}</span></th>
                      <th class="industryCodeWidth"><span>{{'Code' | translatef}}</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let casic of section.data.classifications.casicClassifications">
                      <td>{{ casic.description }}</td>
                      <td>
                        <ddiq-field-source-info [field]="'casicClassification'" [fieldValue]="casic.code">
                          {{ casic.code }}
                        </ddiq-field-source-info>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ddiq-accordion>
      </div>
      <div class="text-right">
        <ddiq-visualize-network></ddiq-visualize-network>
      </div>
      <ddiq-piq-certified-supplier [corporateRecords]="section.data.corporateRecords"></ddiq-piq-certified-supplier>
      <div id="company_corporateRecord" [ngSwitch]="section.data.corporateRecords?.length > 0">
        <div
          id="corporate_{{'CORPORATE_RECORD' | validateHtmlIds}}"
          *ngIf="(section.data.corporateRecords | filterByImpure: ['filtered']: false)?.length"></div>

        <div *ngSwitchCase="true" class="non-breaking">
          <ddiq-accordion
            label="Corporate Records"
            openonprint
            [hasData]="isExpandAllGroups || section.data.corporateRecordsEventGroups.isAllEventGroupsOpen">
            <ddiq-corporate-records-group
              [groups]="section.data.corporateRecordsEventGroups"
              (sortDiscoveries)="sortDiscoveries($event.sortName, $event.eventGroup)"
              [idPrefix]="section.metadata.sectionId"
              [entityType]="entityType"
              [expandAll]="isExpandAllGroups"></ddiq-corporate-records-group>
          </ddiq-accordion>
        </div>
      </div>
      <div *ngIf="!previewingEntity">
        <ddiq-related-people></ddiq-related-people>
        <ddiq-related-locations></ddiq-related-locations>
        <ddiq-related-companies></ddiq-related-companies>
      </div>
    </div>
    <!--end section body-->
  </ddiq-accordion-top>
</div>
