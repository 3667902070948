<main id="cover">
  <section class="center">
    <div class="cover-brand">
      <div class="brand">
        <img data-theme-logo="xl" />
        <span class="brand-sub-text">Powered by DDIQ</span>
      </div>
    </div>
    <div [ngSwitch]="pdfType">
      <div *ngSwitchCase="'diff'">
        <table class="cover">
          <tr>
            <th>Comparison of:</th>
            <td>
              <span [textContent]="current.fullName"></span><br />
              <span>({{current.oiqCreatedDate | dateFormat}})</span>
            </td>
          </tr>
          <tr>
            <th>to:</th>
            <td>
              <span [textContent]="baseline.fullName"></span><br />
              <span>({{baseline.oiqCreatedDate | dateFormat}})</span>
            </td>
          </tr>
          <tr *ngIf="showRequestedBy">
            <th>Submitted by:</th>
            <td>{{current.oiqCreatedBy}}</td>
          </tr>
          <tr>
            <th>Processed on:</th>
            <td>{{current.oiqCreatedDate | dateFormat}}</td>
          </tr>
        </table>
      </div>
      <div *ngSwitchCase="'monitor'">
        <table class="cover">
          <tr>
            <th>Monitoring Report for:</th>
            <td>
              <span [textContent]="summary.fullName"></span>
            </td>
          </tr>
          <tr>
            <th>Baseline Profile Created:</th>
            <td>{{summary.oiqCreatedDate | dateFormat}}</td>
          </tr>
          <tr>
            <th>Monitoring Last Run:</th>
            <td>{{monitor.lastRunAt | dateFormat}}</td>
          </tr>
          <tr *ngIf="showRequestedBy">
            <th>Submitted by:</th>
            <td>{{summary.oiqCreatedBy}}</td>
          </tr>
        </table>
      </div>
      <div *ngSwitchDefault>
        <table class="cover">
          <tr>
            <th>Subject:</th>
            <td [textContent]="summary.fullName"></td>
          </tr>
          <tr *ngIf="showRequestedBy">
            <th>Submitted by:</th>
            <td>{{summary.oiqCreatedBy}}</td>
          </tr>
          <tr>
            <th>Processed on:</th>
            <td>{{summary.oiqCreatedDate | dateFormat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</main>
