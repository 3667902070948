<section class="additional-item">
  <div *ngFor="let company of companies; let $index=index;">
    <button type="button" class="fa fa-trash trash" (click)="remove($index)"></button>

    <ddiq-text
      name="rel-co-companyName-{{$index}}"
      label="Company Name"
      size="half"
      [(model)]="company.companyName"></ddiq-text>
    <ddiq-text
      name="rel-co-relationshipType-{{$index}}"
      label="Relationship"
      size="half"
      [(model)]="company.relationshipType"></ddiq-text>
    <ddiq-text
      name="rel-co-streetAddress-{{$index}}"
      [label]="'Street Address' | translatef"
      [(model)]="company.address.streetAddress"
      size="half"></ddiq-text>
    <ddiq-text
      name="rel-co-city-{{$index}}"
      label="City/Town"
      [(model)]="company.address.city"
      size="quarter"></ddiq-text>
    <ddiq-text
      name="rel-co-stateOrProvince-{{$index}}"
      label="State/Province/Region"
      [(model)]="company.address.stateOrProvince"
      size="quarter"></ddiq-text>
    <ddiq-text
      name="rel-co-zipOrPostalCode-{{$index}}"
      label="ZIP/Postal Code"
      [(model)]="company.address.zipOrPostalCode"
      size="quarter"></ddiq-text>

    <ddiq-select
      name="rel-co-person-country-{{$index}}"
      label="Country"
      [(model)]="company.address.country"
      size="quarter"
      [options]="countries"
      [prompt]="'Choose country' | translatef">
    </ddiq-select>

    <br class="clr" />
  </div>

  <button type="button" class="btn btn-primary" (click)="addNew()">Add Related Company</button>
</section>
