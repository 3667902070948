import { enableProdMode, VERSION } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { displayUnsupportedMessage, isSupportedBrowser } from './unsupported-browser';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (!isSupportedBrowser()) {
  displayUnsupportedMessage();
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      console.log(`Finished bootstrapping Angular ${VERSION.full}`);
    });
}
