import OiqProperties from '../common-services/oiq-properties.service';
import ApiFetchService from '../common-services/api-fetch.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class AuditResolver implements Resolve<OiqProperties | ApiFetchService> {
  private entityType: string;
  private entityId: string;

  constructor(private oiqProperties: OiqProperties, private apiFetchServices: ApiFetchService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this.entityType = route.params.entityType;
    this.entityId = route.params.entityId;

    return Promise.all([this.preLoadOiqProperties(), this.preLoadAuditData(), this.preLoadSummaryData()]);
  }

  private preLoadOiqProperties(): Promise<OiqProperties> {
    return this.oiqProperties.apiPropertiesPromise;
  }

  private preLoadAuditData(): Promise<ApiFetchService> {
    return this.apiFetchServices.audit(this.entityType, this.entityId, false);
  }

  private preLoadSummaryData(): Promise<ApiFetchService> {
    return this.apiFetchServices.summary(this.entityType, this.entityId, false);
  }
}
