import { EventEmitter, Component, Inject, OnChanges, Input, Output } from '@angular/core';

@Component({
  selector: 'exg-pagination',
  templateUrl: './pagination.component.tpl.html',
  preserveWhitespaces: true,
})
export default class PaginationComponent implements OnChanges {
  pages: any;
  isInit: boolean;
  offset: number;
  isBoundaryLinks: boolean;
  isDirectionLinks: boolean;
  previous: any;
  next: any;
  first: any;
  last: any;

  @Input() numPages: number;
  @Input() currentPage: number;
  @Input() paginationBarLength: number;
  @Input() tableTotal: number;
  @Input() totalLabel: string;
  @Input() tableId: string;
  @Input() offsetAdjust: number;
  @Output() onSelectPage = new EventEmitter<any>();
  @Input() boundaryLinks: boolean;
  @Input() directionLinks: boolean;
  @Input() nextText: string;
  @Input() previousText: string;
  @Input() lastText: string;
  @Input() firstText: string;

  constructor(@Inject('paginationConfig') private paginationConfig) {
    this.isInit = true;
  }

  isNumber(page) {
    return typeof page === 'number' && isFinite(page);
  }

  ngOnChanges(changes) {
    if (this.isInit) {
      this.offset = this.offsetAdjust || 75;

      this.isBoundaryLinks =
        typeof this.boundaryLinks !== 'undefined' ? this.boundaryLinks : this.paginationConfig.boundaryLinks;

      this.isDirectionLinks =
        typeof this.directionLinks !== 'undefined' ? this.directionLinks : this.paginationConfig.directionLinks;

      const setButtons = (position) => {
        if (this[`${position}Text`]) {
          this[position] = { text: this[`${position}Text`], css: this.paginationConfig[position].css };
        } else {
          this[position] = this.paginationConfig[position];
        }
      };

      setButtons('first');
      setButtons('previous');
      setButtons('next');
      setButtons('last');

      this.isInit = false;
    }

    if (isSumOfPageValuesChanged(changes)) {
      //set the default paginationBarLength to numPages if less than max
      var paginationBarLength = this.paginationBarLength < this.numPages ? this.paginationBarLength : this.numPages;
      var startPage = this.currentPage - Math.floor(paginationBarLength / 2);

      //adjust the startPage within boundary
      if (startPage < 1) {
        startPage = 1;
      }

      if (startPage + paginationBarLength - 1 > this.numPages) {
        startPage = startPage - (startPage + paginationBarLength - 1 - this.numPages);
      }

      // Add page number links
      this.pages = [];
      var number = startPage;
      for (var i = 0; i < paginationBarLength; ++i) {
        var page = makePage(number + i, number + i, this.isActive(number + i), false);
        this.pages.push(page);
      }

      // Add previous & next links
      if (this.isDirectionLinks) {
        var previousPage = makePage(
          this.currentPage - 1,
          this.previous.text,
          false,
          this.noPrevious(),
          this.previous.css
        );
        this.pages.unshift(previousPage);

        var nextPage = makePage(this.currentPage + 1, this.next.text, false, this.noNext(), this.next.css);
        this.pages.push(nextPage);
      }

      // Add first & last links
      if (this.isBoundaryLinks) {
        var firstPage = makePage(1, this.first.text, false, this.noPrevious(), this.first.css);
        this.pages.unshift(firstPage);

        var lastPage = makePage(this.numPages, this.last.text, false, this.noNext(), this.last.css);
        this.pages.push(lastPage);
      }

      if (this.currentPage > this.numPages) {
        this.selectPage(this.numPages);
      }
    }
  }

  noPrevious() {
    return this.currentPage === 1;
  }

  noNext() {
    return this.currentPage === this.numPages;
  }

  isActive(page) {
    return this.currentPage === page;
  }

  selectPage(page) {
    if (!this.isActive(page) && page > 0 && page <= this.numPages) {
      this.currentPage = page;
      this.onSelectPage.emit({ page });
    }
  }
}

function isSumOfPageValuesChanged(changes) {
  const numPages = changes.numPages || {};
  const currentPage = changes.currentPage || {};
  const paginationBarLength = changes.paginationBarLength || {};

  if (numPages.firstChange || currentPage.firstChange || paginationBarLength.firstChange) {
    return true;
  } else {
    const previous = sumOfPaginationBindingNums('previousValue', changes),
      current = sumOfPaginationBindingNums('currentValue', changes);

    return previous !== current;
  }
}

function sumOfPaginationBindingNums(state, changes) {
  return ['numPages', 'currentPage', 'paginationBarLength'].reduce(
    (accum, curr) => (isNaN((changes[curr] || {})[state]) ? accum : changes[curr][state] + accum),
    0
  );
}

// Create page object used in template
function makePage(number, text, isActive, isDisabled, cssClass?) {
  return {
    number: number,
    text: text,
    active: isActive,
    disabled: isDisabled,
    cssClass: cssClass,
  };
}
