import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-text-submit',
  templateUrl: 'ddiq-text-submit.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DdiqTextSubmitComponent implements OnInit {
  @Input() model;
  @Input() label;
  @Input() pattern;
  @Input() maxLength;
  @Input() textDisabled;
  @Input() oiqFieldRequired;
  @Input() oiqPlaceHolder;
  @Input() size;
  @Input() name;
  @Input() buttonName;
  @Input() buttonEnabled;

  @Output() modelChange = new EventEmitter<string>();
  @Output() buttonClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (!this.oiqPlaceHolder) {
      this.oiqPlaceHolder = '';
    }
    if (this.model) {
      this.model = this.model.replace('\n', ' ');
    }
    if (!this.pattern) {
      this.pattern = /^.*$/;
    }
  }

  onTextChange(value: string) {
    this.modelChange.emit(value);
  }

  buttonClick(value) {
    this.buttonClicked.emit(value);
  }
}
