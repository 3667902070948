import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import AdjudicationType from '../adjudication/adjudication-type.service';

@Component({
  selector: 'ddiq-watch-list-group',
  templateUrl: './ddiq-watch-list-group.component.tpl.html',
})
export default class WatchListGroup implements OnInit {
  @Input() groups;
  @Input() isDisplayCreatedDate;
  @Input() idPrefix;
  @Input() expandAll;
  @Output() sortDiscoveries: any = new EventEmitter();
  adjudicationType;

  constructor(private adjudicationTypeService: AdjudicationType) {}

  ngOnInit() {
    const eventGroups = this.groups.list;

    if (eventGroups && eventGroups.length) {
      this.adjudicationType = this.adjudicationTypeService.getAdjudicationTypeFor(
        'watchlists',
        eventGroups[0].discoveries
      );
    }
  }

  classification(index: number, group: any) {
    return group.classification;
  }

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  id(index: number, watchlist: any) {
    return watchlist.id;
  }
}
