//@ts-nocheck

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class Entity {
  constructor() {
    this.entityInfo = {};
  }

  getId() {
    return this.get('id');
  }

  setId(value) {
    this.set('id', value);
  }

  getCssType() {
    return this.get('cssType');
  }

  setCssType(value) {
    this.set('cssType', value);
  }

  getData() {
    return this.get('data');
  }

  setData(data) {
    this.entityInfo.data = data;
    this.entityInfo.displayName = this.extractDisplayName(data);
    this.entityInfo.profileMode = (data || {}).profileMode;
    this.entityInfo.lookbackPeriodStart = (data || {}).lookbackPeriodStart;
    this.entityInfo.failed = (data || {}).failed;
  }

  getSeedData() {
    return this.get('seedData');
  }

  setSeedData(value) {
    this.set('seedData', value);
  }

  getLookbackPeriodStart() {
    return this.get('lookbackPeriodStart');
  }

  getDisplayName() {
    return this.get('displayName');
  }

  getProfileMode() {
    return this.get('profileMode');
  }

  isDiff() {
    return this.get('diff') === true;
  }

  setDiff(value) {
    this.set('diff', value);
  }

  getDiffId() {
    return this.get('diffId');
  }

  setDiffId(value) {
    this.set('diffId', value);
  }

  getParentId() {
    return this.get('parentId');
  }

  setParentId(value) {
    this.set('parentId', value);
  }

  getDynamicSectionConfig() {
    return this.get('dynamicSectionConfig');
  }

  setDynamicSectionConfig(value) {
    this.set('dynamicSectionConfig', value);
  }

  getType() {
    return this.get('type');
  }

  setType(value) {
    this.set('type', value);
  }

  getFailed() {
    return this.get('failed');
  }

  // moar
  isInPreview() {
    return this.getProfileMode() === 'PREVIEW';
  }

  isFailed() {
    return this.getFailed() === true;
  }

  isNowBuilding() {
    return this.isInPreview() && !this.isFailed();
  }

  isCompany() {
    return this.getType() === 'company';
  }

  isPerson() {
    return this.getType() === 'person';
  }

  isProperty() {
    return this.getType() === 'property';
  }

  isUsingLookback() {
    return !!this.getLookbackPeriodStart();
  }

  clear() {
    this.entityInfo = {};
  }

  getAlerts() {
    var alertProperty = this.getType() + 'Alerts';

    return this.getData()[alertProperty] || [];
  }

  allowsStatusChanges() {
    return !this.isInPreview() && !this.isProperty() && !this.isDiff();
  }

  hasFoundGovernmentAwards() {
    return getGovernmentProcurement(this.getData()).awardsFound;
  }

  wasSearchedForGovernmentAwards() {
    return getGovernmentProcurement(this.getData()).awardsSearched;
  }

  extractDisplayName(data) {
    var name, nameParts;

    data = data || {};

    if (this.isCompany()) {
      name = data.name;
    } else if (this.isProperty()) {
      name = data.displayName;

      if (data.address && data.address.city) {
        name = name + ', ' + data.address.city;
      }
    } else if (this.isPerson()) {
      if (data.name) {
        name = data.name;
      } else {
        nameParts = [];
        nameParts.push(data.firstName || '');
        nameParts.push(data.middleName || '');
        nameParts.push(data.lastName || '');
        name = nameParts.join(' ');
      }
    }

    return name;
  }

  setIsAdjudicatableByUser(value) {
    this.set('adjudicatableByUser', value);
  }

  isAdjudicatableByUser() {
    return this.get('adjudicatableByUser') === true;
  }

  private get(field) {
    return this.entityInfo[field];
  }

  private set(field, value) {
    this.entityInfo[field] = value;
  }
}

function getGovernmentProcurement(entityData) {
  return (
    entityData.governmentProcurement || {
      awardsFound: false,
      awardsSearched: false,
    }
  );
}
