import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

const articleStyle = `
  .article-entity-name { background-color: #FFFF00; } 
  .article-entity-keyword { background-color: #7FDBFF; } 
`;

@Component({
  selector: 'ddiq-protected-article',
  templateUrl: './ddiq-protected-article.component.tpl.html',
})
export default class ProtectedArticleComponent implements AfterViewInit {
  @Input() content;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const iframeElem = this.elementRef.nativeElement.querySelector('iframe');
    const doc = iframeElem.contentWindow.document;
    const articleCSS = document.createElement('style');

    articleCSS.type = 'text/css';
    articleCSS.appendChild(doc.createTextNode(articleStyle));

    doc.open();
    doc.write(this.content);
    doc.close();

    const height = doc.documentElement.offsetHeight;
    iframeElem.style.height = `${height}px`;
    doc.head.appendChild(articleCSS);
  }
}
