import { Component, DoCheck, Input, OnInit } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import AlertService from '../common-services/alert.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import PartialUtils from '../common-services/partial-utils.factory';
import RelationshipService from '../common-services/relationship-service.factory';
import DateFormatPipe from '../ddiq-filters/date-format.pipe';
import Monitor from '../monitor/monitor.service';
import AlertSummarizer from '../themed-entity/alert-summarizer.service';
import StructuredDataUtils from '../themed-entity/structured-data-utils.service';
import UserService from '../user/user.service';

@Component({
  selector: 'ddiq-person-basic-info',
  templateUrl: './ddiq-person-basic-info.component.tpl.html',
})
export default class DdiqPersonBasicInfoComponent implements OnInit, DoCheck {
  adjudicationEnabled;
  canAdjudicate;
  canSubmitProfile;
  hasRelatedCompaniesOrLocations: boolean = true;
  isDeltaReport;
  isDiff;
  entityId;
  entityType;
  previewingEntity;
  contentCounter: any;
  basicInfoAlerts;
  alertCount;
  isSectionOpen;
  isSectionDisabled;
  seedData;

  containsOtherPersonData;
  containsHistoryInfo;
  containsVerificationInfo;
  isDefined;

  @Input() section;
  @Input() isExpandAllGroups;

  constructor(
    private entity: Entity,
    private PU: PartialUtils,
    private alertSummarizer: AlertSummarizer,
    private alertService: AlertService,
    private oiqProperties: OiqProperties,
    private relationshipService: RelationshipService,
    private user: UserService,
    private structuredDataUtils: StructuredDataUtils,
    private monitor: Monitor,
    private messageBusService: MessageBusService,
    private dateFormatPipe: DateFormatPipe
  ) {}

  ngOnInit() {
    this.adjudicationEnabled = this.oiqProperties.adjudicationEnabled;
    this.canAdjudicate = this.user.canAdjudicateProfiles();
    this.canSubmitProfile = this.user.canSubmitProfile();
    this.isDeltaReport = this.monitor.isDeltaReport();
    this.isDiff = this.entity.isDiff();
    this.entityId = this.entity.getId();
    this.entityType = this.entity.getType();
    this.previewingEntity = this.entity.isInPreview();

    this.contentCounter = {
      _basicInfo: this.structuredDataUtils.observedCount('basicInfo'),

      filtered: function () {
        return this._basicInfo.filtered;
      },
      unfiltered: function () {
        return this._basicInfo.unfiltered;
      },
      filteredEscalated: function () {
        return this._basicInfo.filteredEscalated;
      },
      unfilteredEscalated: function () {
        return this._basicInfo.unfilteredEscalated;
      },
    };
    // needed due to scope being unavailable in nested collapses
    markOtherCorporateInfo(this.section.data.corporateRecords);

    this.checkCompaniesAndLocations();
    // these are exposed on the scope for use in the section header
    this.basicInfoAlerts = this.alertService.getThemedEntityAlerts(
      this.entityType,
      this.entity.getAlerts(),
      this.section.metadata.sectionId
    );
    this.alertCount = countAlerts(this.basicInfoAlerts);

    // and here they're added to the AlertSummarizer for the print header
    this.alertSummarizer.addAlertCount(this.section.metadata.sectionId, this.alertCount);
    this.alertSummarizer.addAlertList(this.section.metadata.sectionId, this.basicInfoAlerts);

    this.containsOtherPersonData = this.hasOtherPersonData();
    this.containsHistoryInfo = this.hasHistoryInfo();
    this.containsVerificationInfo = this.hasVerificationInfo();
    this.isDefined = this.PU.isDefined(this.section.data.biography);
  }

  ngDoCheck() {
    if (this.entity.getSeedData()) {
      this.seedData = this.entity.getSeedData();
    }
  }

  checkCompaniesAndLocations() {
    Promise.all([
      this.relationshipService.companies(this.entityType, this.entityId, null),
      this.relationshipService.locations(this.entityType, this.entityId, null),
    ]).then((result) => {
      const [companies, locations] = result;
      const hasContent = this.sectionHasContentToDisplay();

      this.isSectionOpen = this.previewingEntity || hasContent;
      this.isSectionDisabled = !hasContent;

      for (let i = 0; i < companies.length; i++) {
        if (companies[i].base.name) {
          this.hasRelatedCompaniesOrLocations = true;
          return;
        }
      }

      for (let i = 0; i < locations.length; i++) {
        if (
          locations[i].base.address.streetAddress &&
          locations[i].base.address.city &&
          locations[i].base.address.country
        ) {
          this.hasRelatedCompaniesOrLocations = true;

          return;
        }
      }

      this.hasRelatedCompaniesOrLocations = false;
    });
  }

  sectionHasContentToDisplay() {
    return (
      this.relationshipService.hasRelationships() ||
      this.hasVerificationInfo() ||
      this.hasOtherPersonData() ||
      this.hasHistoryInfo() ||
      this.section.data.seedSource ||
      ['professionalLicenses', 'corporateRecords'].some(
        (arrayName) => this.section.data[arrayName] && this.section.data[arrayName].length
      )
    );
  }

  hasVerificationInfo() {
    if (!this.section.data) {
      return false;
    }

    return (
      this.section.data.currentJobTitle ||
      this.section.data.emailAddresses?.length ||
      this.section.data.phoneNumbers?.length ||
      this.section.data.alternativeNames?.length ||
      this.section.data.corporateRecords?.length ||
      this.section.data.professionalLicenses?.length ||
      this.section.data.relatedPeople?.length ||
      this.section.data.birthdate ||
      this.section.data.currentCompensationAmount ||
      this.PU.isAddressFilled(this.section.data.mainAddress)
    );
  }

  getImgUrl() {
    if (!this.section.data || !this.section.data.image) {
      return '';
    }

    const oiqImg = this.section.data.image.oiqImageUrl;
    const sourceImg = this.section.data.image.sourceUrl;
    const isOiqImgInDb = oiqImg && oiqImg.substring(oiqImg.lastIndexOf('/') + 1) !== '0';

    if (!isOiqImgInDb && sourceImg) {
      return sourceImg;
    }

    return oiqImg;
  }

  hasOtherPersonData() {
    if (!this.section.data) {
      return false;
    }

    return (
      this.section.data.image ||
      (this.section.data.socialMedia &&
        ((this.section.data.socialMedia.data.linkedInProfile &&
          this.section.data.socialMedia.data.linkedInProfile.linkedInUrl) ||
          (this.section.data.socialMedia.data.facebookAccount &&
            this.section.data.socialMedia.data.facebookAccount.url) ||
          (this.section.data.socialMedia.data.twitterAccount &&
            this.section.data.socialMedia.data.twitterAccount.twitterUrl)))
    );
  }

  hasHistoryInfo() {
    if (!this.section.data) {
      return false;
    }

    return (
      this.PU.isDefined(this.section.data.biography) ||
      this.section.data.industries?.length ||
      this.section.data.education?.length ||
      this.section.data.certifications?.length ||
      this.section.data.professionalLicenses?.length
    );
  }

  showInsufficientDataWarning() {
    if (!this.section.data) {
      return false;
    }

    if (!this.entity.getSeedData()) {
      return false;
    }

    return (
      !(
        this.dateFormatPipe.transform(this.section.data.birthdate) ||
        this.section.data.age ||
        this.dateFormatPipe.transform(this.seedData.birthdate) ||
        this.seedData.age
      ) &&
      !this.hasRelatedCompaniesOrLocations &&
      !(
        this.seedData.location &&
        this.seedData.location.streetAddress &&
        this.seedData.location.city &&
        this.seedData.location.country
      )
    );
  }

  sortDiscoveries(eventGroupKind, sortName, eventGroup) {
    this.messageBusService.send({
      type: 'event:sort-event-group',
      data: {
        sectionId: this.section.metadata.sectionId,
        eventGroupKind,
        sortName,
        eventGroup,
      },
    });
  }
}

/**
 * returns an alert count for this section.
 *
 * basic info section includes area code not existing, phone number not existing
 *
 * @param alerts
 * @returns {number}
 */
function countAlerts(alerts) {
  let count = 0;

  if (alerts !== undefined) {
    count += alerts.length;
  }

  return count;
}

function markOtherCorporateInfo(corporateRecords) {
  if (!corporateRecords) {
    return;
  }

  for (let i = 0; i < corporateRecords.length; i++) {
    corporateRecords[i].hasOtherCorporateInfo = hasOtherCorporateInfo(corporateRecords[i]);
  }
}

function hasOtherCorporateInfo(corporateRecord) {
  const hasStartDate =
    corporateRecord.positions &&
    corporateRecord.positions.length > 0 &&
    corporateRecord.positions[0].startDate !== undefined;
  const hasEndDate =
    corporateRecord.positions &&
    corporateRecord.positions.length > 0 &&
    corporateRecord.positions[0].endDate !== undefined;

  return (
    hasStartDate || hasEndDate || corporateRecord.nationality !== undefined || corporateRecord.birthdate !== undefined
  );
}
