import ValidateHtmlIdsPipe from '../ui/validate-html-ids.pipe';

type StructuredHitKind = 'corporateRecords' | 'regulatoryProfiles' | 'watchlists';
type IncidentsHitKind = 'incidents';

export type ProfileHitKind = StructuredHitKind | IncidentsHitKind;
export interface ProfileHit {
  filterFields: {
    kind: ProfileHitKind;
  };
  lookupId?: string;
  id: string;
}
export abstract class ProfileHitId {
  private validateHtmlIdPipe: ValidateHtmlIdsPipe;

  constructor() {
    this.validateHtmlIdPipe = new ValidateHtmlIdsPipe();
  }

  abstract doGetIdFrom(hit: ProfileHit): string;

  applyIdTo(hit: ProfileHit) {
    const id = this.doGetIdFrom(hit);
    hit.lookupId = this.validateHtmlIdPipe.transform(id);
  }
}
