import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ProfileCollectionModule } from '../profile-collection/profile-collection.module';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';
import OiqNavModule from '../nav/oiq-nav.module';
import DdiqBuildCompany from './ddiq-build-company.component';
import { ProfileCollectionPermissionMissingForSubmissionModule } from '../profile-collection/ddiq-profile-collection-missing-permission-for-submission.module';
import { IdSubmissionInputsModule } from '../id-submission/id-submission.module';
import { DdiqConfirmationDialogModule } from '../confirmation-dialog/ddiq-confirmation-dialog.module';
import BuildCompanyResolverService from './build-company-resolver.service';
import { PersonProfileFormsModule } from '../person-profile-forms/person-profile-forms.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import { DdiqMonitorModule } from '../monitor/oiq-monitor.module';

@NgModule({
  imports: [
    CommonModule,
    DdiqUpgradeModule,
    FormsModule,
    PipesModule,
    ProfileCollectionModule,
    ThemedEntityModule,
    OiqNavModule,
    ProfileCollectionPermissionMissingForSubmissionModule,
    IdSubmissionInputsModule,
    DdiqConfirmationDialogModule,
    PersonProfileFormsModule,
    DdiqUIModule,
    DdiqMonitorModule,
  ],
  providers: [BuildCompanyResolverService],
  declarations: [DdiqBuildCompany],
  exports: [DdiqBuildCompany],
})
export class DdiqBuildCompanyModule {}
