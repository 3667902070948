<article
  class="oiq-professional-license"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': license.escalated, confirmed: license.confirmed}"
  data-oiq-collapsible-ctrl
  [expandAll]="license.isOpen">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="license"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-professional-license-summary oiq-grid-cell">
          <ddiq-association [level]="license.associationBucket" class="oiq-professional-license-association">
          </ddiq-association>

          <span
            *ngIf="license.issueDate"
            class="oiq-professional-license-display-date"
            [ngClass]="{disabled: license.disabled}">
            <span class="oiq-professional-license-display-date-label sr-only"> Issue Date </span>
            <time
              class="oiq-professional-license-display-date-time"
              datetime="{{license.issueDate}}"
              [textContent]="license.issueDate | dateFormat"></time>
          </span>

          <ddiq-adjudication-actions
            *ngIf="isAdjudicateable && !entityAware.isPreview"
            [type]="'PROFESSIONAL_LICENSE'"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [targetId]="license.id"
            [model]="license">
          </ddiq-adjudication-actions>
        </div>

        <div class="oiq-professional-license-bd oiq-grid-cell">
          <article class="oiq-professional-license-grid" [ngClass]="{disabled: license.disabled}">
            <div class="oiq-professional-license-row" *ngIf="license.licenseeName" role="row">
              <div class="oiq-professional-license-hd" role="columnheader">Licensee:</div>
              <div class="oiq-professional-license-cell" role="gridcell" [textContent]="license.licenseeName"></div>
            </div>

            <div
              class="oiq-professional-license-row"
              role="row"
              *ngIf="license.seedSource.sourceUrl || license.seedSource.source">
              <div class="oiq-professional-license-hd" role="columnheader">Source:</div>
              <div class="oiq-professional-license-cell" role="gridcell">
                <a
                  *ngIf="license.seedSource.sourceUrl"
                  href="{{license.seedSource.sourceUrl}}"
                  target="_blank"
                  class="inline"
                  rel="noopener noreferrer"
                  [textContent]="(license.seedSource || record.seedSource) | extractSource | translatef"></a>
                <span
                  *ngIf="!license.seedSource.sourceUrl"
                  [textContent]="license.seedSource.source | translatef"></span>
              </div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.businessName">
              <div class="oiq-professional-license-hd" role="columnheader">Business Name:</div>
              <div class="oiq-professional-license-cell" role="gridcell" [textContent]="license.businessName"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.licenseType">
              <div class="oiq-professional-license-hd" role="columnheader">License Type:</div>
              <div class="oiq-professional-license-cell" role="gridcell" [textContent]="license.licenseType"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.additionalLicenseTypes.length">
              <div class="oiq-professional-license-hd" role="columnheader">Other Types:</div>
              <div class="oiq-professional-license-cell" role="gridcell">
                <ul class="oiq-professional-license-additional-types">
                  <li *ngFor="let type of license.additionalLicenseTypes" [textContent]="type"></li>
                </ul>
              </div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.licenseClass">
              <div class="oiq-professional-license-hd" role="columnheader">License Class:</div>
              <div class="oiq-professional-license-cell" role="gridcell" [textContent]="license.licenseClass"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.licenseNumber">
              <div class="oiq-professional-license-hd" role="columnheader">License Number:</div>
              <div class="oiq-professional-license-cell" role="gridcell" [textContent]="license.licenseNumber"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.issueDate">
              <div class="oiq-professional-license-hd" role="columnheader">Issued:</div>
              <div
                class="oiq-professional-license-cell"
                role="gridcell"
                [textContent]="license.issueDate | dateFormat"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.status">
              <div class="oiq-professional-license-hd" role="columnheader">Status:</div>
              <div class="oiq-professional-license-cell" role="gridcell" [textContent]="license.status"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.expirationDate">
              <div class="oiq-professional-license-hd" role="columnheader">Expired:</div>
              <div
                class="oiq-professional-license-cell"
                role="gridcell"
                [textContent]="license.expirationDate | dateFormat"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.address">
              <div class="oiq-professional-license-hd" role="columnheader">Address:</div>
              <div
                class="oiq-professional-license-cell"
                role="gridcell"
                [textContent]="license.address | address"></div>
            </div>

            <div class="oiq-professional-license-row" role="row" *ngIf="license.phoneNumber">
              <div *ngIf="license.phoneNumber.number">
                <div class="oiq-professional-license-hd" role="columnheader">Phone Number:</div>
                <div
                  class="oiq-professional-license-cell"
                  role="gridcell"
                  [textContent]="license.phoneNumber.number"></div>
              </div>
            </div>
          </article>
          <ddiq-collapsible>
            <article class="oiq-professional-license-grid" [ngClass]="{disabled: license.disabled}">
              <div class="oiq-professional-license-row" role="row" *ngIf="license.jobFunctions.length">
                <div class="oiq-professional-license-hd" role="columnheader">Job Functions:</div>
                <div class="oiq-professional-license-cell" role="gridcell">
                  <ul class="oiq-professional-license-job-functions">
                    <li *ngFor="let job of license.jobFunctions" [textContent]="job"></li>
                  </ul>
                </div>
              </div>

              <div class="oiq-professional-license-row" role="row" *ngIf="license.specialties.length">
                <div class="oiq-professional-license-hd" role="columnheader">Specialty:</div>
                <div class="oiq-professional-license-cell" role="gridcell">
                  <ul class="oiq-professional-license-specialities">
                    <li *ngFor="let specialty of license.specialties" [textContent]="specialty"></li>
                  </ul>
                </div>
              </div>

              <div
                class="oiq-professional-license-row"
                role="row"
                *ngFor="let additionalData of license.additionalData">
                <div
                  class="oiq-professional-license-hd"
                  [textContent]="(additionalData.label | translatef) + ':'"
                  role="columnheader"></div>
                <div class="oiq-professional-license-cell" role="gridcell">
                  <ul class="oiq-professional-license-additional-data">
                    <li *ngFor="let value of additionalData.value">
                      <span>
                        <ddiq-link [data]="value" [is-disabled]="license.disabled"> </ddiq-link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </article>
          </ddiq-collapsible>
          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'PROFESSIONAL_LICENSE'"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [targetId]="license.id"
            [model]="license"
            [label]="'Notes'"
            [active]="license.comment">
          </ddiq-adjudication-notes>
        </div>
        <div
          class="oiq-professional-license-toggle oiq-grid-cell"
          [ngClass]="{disabled: license.disabled}"
          *ngIf="hasAdditionalData">
          <a class="oiq-professional-license-show-all-data-toggle" data-oiq-collapsible-toggle>
            <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
            Show All Data
          </a>
        </div>
      </div>
    </div>
  </div>
</article>
