<section class="additional-item">
  <div *ngFor="let location of locations | startFrom:1; index as $index">
    <button type="button" class="fa fa-trash trash" (click)="removeLocation($index + 1)"></button>
    <ddiq-text
      name="loc-streetAddress-{{$index}}"
      size="half"
      [label]="'Street Address' | translatef"
      [(model)]="location.streetAddress"></ddiq-text>
    <div *ngIf="districtEnabled">
      <ddiq-text
        name="loc-buildingName-{{$index}}"
        size="quarter"
        [label]="'Building Name' | translatef"
        [(model)]="location.buildingName"></ddiq-text>
      <ddiq-text
        name="loc-district-{{$index}}"
        size="quarter"
        [label]="'District'"
        [(model)]="location.district"></ddiq-text>
    </div>
    <div *ngIf="!districtEnabled">
      <ddiq-text
        name="loc-buildingName-{{$index}}"
        size="half"
        [label]="'Building Name' | translatef"
        [(model)]="location.buildingName"></ddiq-text>
    </div>
    <br class="clr" />

    <ddiq-text
      name="loc-cityTown-{{$index}}"
      size="quarter"
      [label]="'City/Town'"
      [(model)]="location.city"></ddiq-text>
    <ddiq-text
      name="loc-stateOrProvince-{{$index}}"
      size="quarter"
      [label]="'State/Province/Region' | translatef"
      [(model)]="location.stateOrProvince"></ddiq-text>
    <ddiq-text
      name="loc-zipOrPostalCode-{{$index}}"
      size="quarter"
      [label]="'ZIP/Postal Code' | translatef"
      [(model)]="location.zipOrPostalCode"></ddiq-text>
    <ddiq-select
      name="loc-countries-{{$index}}"
      size="quarter"
      [label]="'Country' | translatef"
      [(model)]="location.country"
      [options]="countries"
      [prompt]="'Choose country' | translatef">
    </ddiq-select>
    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary add-location" (click)="addLocation()">Add Location</button>
</section>
