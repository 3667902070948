<article
  class="oiq-osha-inspection"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': inspection.escalated, confirmed: inspection.confirmed}">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="inspection"></oiq-dynamic-adjudication-aware>
    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-osha-inspection-summary oiq-grid-cell">
          <ddiq-association [level]="inspection.associationBucket" class="oiq-osha-inspection-association">
          </ddiq-association>

          <div class="text-center" [ngClass]="{disabled: inspection.disabled}">
            <span class="oiq-osha-inspection-display-date" *ngIf="inspection.inspectionDate">
              <span class="sr-only">Inspection Date</span>

              <time class="oiq-osha-inspection-display-date-time" [attr.datetime]="inspection.inspectionDate">
                {{inspection.inspectionDate | dateFormat}}
              </time>
            </span>

            <ddiq-adjudication-actions
              [type]="'OSHA_SAFETY_INSPECTION'"
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [entityType]="entityAware.entityType"
              [entityId]="entityAware.entityId"
              [noReassess]="entityAware.noReassess"
              [targetId]="inspection.id"
              [model]="inspection">
            </ddiq-adjudication-actions>
          </div>
        </div>

        <div class="oiq-osha-inspection-bd oiq-grid-cell">
          <article class="oiq-osha-inspection-grid" [ngClass]="{disabled: inspection.disabled}">
            <div class="oiq-osha-inspection-row" *ngIf="inspection.companyName" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Company:</div>
              <div class="oiq-osha-inspection-cell" role="gridcell" [textContent]="inspection.companyName"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.inspectionType" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Inspection Type:</div>
              <div class="oiq-osha-inspection-cell" role="gridcell" [textContent]="inspection.inspectionType"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.inspectionDate" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Inspection Date:</div>
              <div
                class="oiq-osha-inspection-cell"
                role="gridcell"
                [textContent]="inspection.inspectionDate | dateFormat"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.address" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Address:</div>
              <div class="oiq-osha-inspection-cell" role="gridcell" [textContent]="inspection.address | address"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.ownership" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Ownership:</div>
              <div class="oiq-osha-inspection-cell" role="gridcell" [textContent]="inspection.ownership"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.unionStatus" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Union Status:</div>
              <div class="oiq-osha-inspection-cell" role="gridcell" [textContent]="inspection.unionStatus"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.initialViolationCount" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Initial Violations:</div>
              <div
                class="oiq-osha-inspection-cell"
                role="gridcell"
                [textContent]="inspection.initialViolationCount"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.currentViolationCount" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Current Violations:</div>
              <div
                class="oiq-osha-inspection-cell"
                role="gridcell"
                [textContent]="inspection.currentViolationCount"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.initialPenalty" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Initial Penalty:</div>
              <div
                class="oiq-osha-inspection-cell"
                role="gridcell"
                [textContent]="inspection.initialPenalty | moneyFormat"></div>
            </div>

            <div class="oiq-osha-inspection-row" *ngIf="inspection.currentPenalty" role="row">
              <div class="oiq-osha-inspection-hd" role="columnheader">Current Penalty:</div>
              <div
                class="oiq-osha-inspection-cell"
                role="gridcell"
                [textContent]="inspection.currentPenalty | moneyFormat"></div>
            </div>
          </article>
          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'OSHA_SAFETY_INSPECTION'"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [noReassess]="entityAware.noReassess"
            [targetId]="inspection.id"
            [model]="inspection"
            [label]="'Notes'"
            [active]="inspection.comment">
          </ddiq-adjudication-notes>
        </div>
      </div>
    </div>
  </div>
</article>
