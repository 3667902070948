<div [ngSwitch]="people.count > 0">
  <div *ngSwitchCase="true">
    <ddiq-accordion id="related_PERSON" [label]="'People'" [alerts]="listAlertCount" openonprint>
      <ddiq-person-list
        title="User Submitted"
        [people]="people.submitted"
        (requestReport)="requestReport($event.person)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        [isRequestBtnEnabled]="isPersonSubmissionEnabled"
        (adjudicationComplete)="refresh()"></ddiq-person-list>
      <ddiq-person-list
        title="Owner"
        [people]="people.owner"
        (requestReport)="requestReport($event.person)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        [isRequestBtnEnabled]="isPersonSubmissionEnabled"
        (adjudicationComplete)="refresh()"></ddiq-person-list>
      <ddiq-person-list
        title="Key Management Personnel"
        [people]="people.management"
        (requestReport)="requestReport($event.person)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        [isRequestBtnEnabled]="isPersonSubmissionEnabled"
        (adjudicationComplete)="refresh()"></ddiq-person-list>
      <ddiq-person-list
        title="Employees"
        [people]="people.employed"
        (requestReport)="requestReport($event.person)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        [isRequestBtnEnabled]="isPersonSubmissionEnabled"
        (adjudicationComplete)="refresh()"></ddiq-person-list>
      <ddiq-person-list
        title="Family Member"
        [people]="people.family"
        (requestReport)="requestReport($event.person)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        [isRequestBtnEnabled]="isPersonSubmissionEnabled"
        (adjudicationComplete)="refresh()"></ddiq-person-list>
      <ddiq-person-list
        title="Referenced"
        [people]="people.referenced"
        (requestReport)="requestReport($event.person)"
        [alertsEnabled]="alertsEnabled"
        [adjudicationSupport]="isRelatedEntityAdjudicationEnabled"
        [isRequestBtnEnabled]="isPersonSubmissionEnabled"
        (adjudicationComplete)="refresh()"></ddiq-person-list>
    </ddiq-accordion>
  </div>
</div>
