import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class AdviserInfoUrl {
  getCandidateUrls(url) {
    const anchor = document.createElement('a');
    anchor.href = url;

    if (anchor.hostname !== 'www.adviserinfo.sec.gov') {
      return [];
    }

    return makeCandidateUrls(url, anchor);
  }
}

function makeCandidateUrls(url, anchor) {
  return [url, makeAlternateUrl(anchor, url)];
}

function makeAlternateUrl(anchor, url) {
  const segmentName = 'IAPD',
    segmentToReplace = findSegmentToReplace(segmentName, anchor.pathname);

  if (segmentToReplace) {
    return url.replace(segmentToReplace, '');
  }
  return prependSegment(segmentName, anchor);
}

function findSegmentToReplace(pathSegmentName, pathname) {
  return [`/${pathSegmentName}`, `${pathSegmentName}/`].find((segment) => pathname.indexOf(segment) === 0);
}

function prependSegment(segmentName, anchor) {
  let separator = '';
  if (anchor.pathname.indexOf('/') !== 0) {
    separator = '/';
  }
  anchor.pathname = `${segmentName}${separator}${anchor.pathname}`;
  return anchor.href;
}
