import { Injectable } from '@angular/core';
import EntityCache from '../common-services/entity-cache.service';
import Entity from '../common-services/entity.service';
import RelationshipService from '../common-services/relationship-service.factory';

@Injectable({
  providedIn: 'root',
})
export default class NetworkService {
  constructor(
    private relationshipService: RelationshipService,
    private entityCache: EntityCache,
    private entity: Entity
  ) {}

  graph(entityId, entityType) {
    // validate whether the entityId and entityType exists
    validate(entityId, entityType);
    let graph = { nodes: [], links: [] };
    return this.entityCache.fetchAsEntity(entityType, entityId).then((Entity) => {
      // pushes the `Entity` into the `graph` nodes
      applyEntity(graph, Entity, entityType);

      return this.graphFor(entityType, entityId, entityId, graph)
        .then(() => {
          return findNodesForTombstones(graph.nodes)();
        })
        .then(this.applyTombstonesToNodes().bind(this))
        .then(() => {
          return graph;
        });
    });
  }

  /**
   * Draws a node connecting to the `joinToEntityId` center node
   * @param entityType
   * @param entityId
   * @param joinToEntityId Center Node ID
   * @param {object} graph Object with format {nodes: [], links: []}
   * @returns
   */
  private graphFor(entityType, entityId, joinToEntityId, graph) {
    return Promise.all([
      this.relationshipService.people(entityType, entityId),
      this.relationshipService.companies(entityType, entityId),
      this.relationshipService.locations(entityType, entityId),
    ]).then((discoveries) => {
      let nodeConfigs,
        withProfiles,
        people = discoveries[0],
        companies = discoveries[1],
        locations = discoveries[2];

      nodeConfigs = people.concat(companies, locations).map(applyDiscovery(graph.nodes));

      linkNodes(graph, nodeConfigs, joinToEntityId);

      withProfiles = nodeConfigs
        .filter(function (config) {
          return isProfileSearchable(config.node);
        })
        .map((nodeConfig) => {
          return this.graphFor(nodeConfig.queryEntityType, nodeConfig.queryEntityId, nodeConfig.joinToEntityId, graph);
        });

      return Promise.all(withProfiles);
    });
  }

  /**
   * Takes in a callback argument to generate tombstones based on the nodes
   * @returns {Function}
   */
  applyTombstonesToNodes() {
    return (nodesWithProfiles) => {
      let promises = nodesWithProfiles.map((node) => {
        let entityType = node.entityType.toLowerCase(),
          entityId = node.entityId;

        return this.entityCache.fetchAsEntity(entityType, entityId).then((entityData: any) => {
          let tombstone = {};

          this.entity.setType(entityType);
          this.entity.setId(entityId);
          this.entity.setData(entityData.getData());

          tombstone['name'] = this.entity.getDisplayName();
          tombstone['alternativeNames'] = this.entity.getData().alternativeNames;

          tombstone['birthdate'] = this.entity.getData().birthdate;

          tombstone['location'] = getLocationFromEntity(this.entity);

          node.tombstone = tombstone;
        });
      });
      return Promise.all(promises);
    };

    function getLocationFromEntity(entity) {
      let location = entity.getData().location || entity.getData().mainAddress;

      if (!hasLocation(location)) {
        location = undefined;
      }

      return location;
    }

    function hasLocation(location) {
      return (
        location &&
        (location.streetAddress ||
          location.city ||
          location.zipPostalCode ||
          location.stateProvince ||
          location.country)
      );
    }
  }
}

/**
 *
 * Throws an error if either inputs are undefined
 * @param entityId
 * @param entityType
 */
function validate(entityId, entityType) {
  if (typeof entityType === 'undefined') {
    throw new Error('entityType is required');
  }

  if (typeof entityId === 'undefined') {
    throw new Error('entityId is required');
  }
}

/**
 * appends a new entity to `graph`'s nodes
 * @param {object} graph Object with format {nodes: [], links: []}
 * @param entity
 * @param entityType
 */
function applyEntity(graph, entity, entityType) {
  const percentEnriched = entity.getData().percentEnriched;

  graph.nodes.push({
    name: entity.getData().name,
    entityId: entity.getId(),
    entityType: entityType,
    hasProfile: hasProfile(percentEnriched),
    hasAlerts: hasAlerts(entity.getAlerts(), percentEnriched),
  });
}

/**
 * Returns a function to be used inside a Array.prototype.map()
 * @param {Array} nodes graph nodes array
 * @returns {Function} returns an object {}
 */
function applyDiscovery(nodes) {
  return function (discovery) {
    let node,
      nodeConfig = {},
      percentEnriched = discovery.base.percentEnriched,
      name = discovery.base.name,
      entityType = discovery.relationships[0].entityBType,
      oiqEntityId = discovery.base.oiqEntityId,
      isWithProfile = hasProfile(percentEnriched),
      isWithAlerts = hasAlerts(discovery.base.alerts, percentEnriched);

    node = findNode(name, entityType, nodes);

    if (!node) {
      node = {
        name: name,
        entityType: entityType,
        entityId: oiqEntityId,
        hasProfile: isWithProfile,
        hasAlerts: isWithAlerts,
      };
      nodes.push(node);
    } else if (!isRoot(nodes, node) && isWithProfile) {
      if (!node.hasProfile) {
        node.entityId = oiqEntityId;
        node.hasAlerts = isWithAlerts;
      }
      node.hasProfile = isWithProfile;
    }

    nodeConfig['node'] = node;

    nodeConfig['joinToEntityId'] = node.entityId;

    nodeConfig['queryEntityId'] = oiqEntityId;

    nodeConfig['queryEntityType'] = entityType;

    return nodeConfig;
  };
}

/**
 * Checks if percentEnriched exists and is greater than or equal to 100
 * @param {Number} percentEnriched
 * @returns {Boolean}
 */
function hasProfile(percentEnriched) {
  return !!(percentEnriched && percentEnriched >= 100);
}

/**
 * Checks if alerts exists and has content inside
 * @param {Array} alerts
 * @param {Number} percentEnriched
 * @returns {Boolean}
 */
function hasAlerts(alerts, percentEnriched) {
  return !!(alerts && alerts.length && hasProfile(percentEnriched));
}

/**
 * Searches for a specific node in `nodes` based on the name and type
 * @param {String} name
 * @param {String} type
 * @param {Array} nodes graph nodes array
 * @returns {Object}
 */
function findNode(name, type, nodes) {
  let i, current, node;

  for (i = 0; i < nodes.length; i++) {
    current = nodes[i];
    if (name === current.name && type.toLowerCase() === current.entityType.toLowerCase()) {
      node = current;
      break;
    }
  }
  return node;
}

/**
 * checks if `node` is the first element of `nodes` based on name
 * @param {Array} nodes
 * @param {Object} node
 * @returns {Boolean}
 */
function isRoot(nodes, node) {
  return nodes[0].name === node.name;
}

/**
 *
 * @param graph
 * @param {Array} configsToLink array of objects containing configurations{} for each node
 * @param entityId Primary entityId to attach the nodes to.
 */
function linkNodes(graph, configsToLink, entityId) {
  let i, j, sourceIndex, childIndex, nodeConfig, node, nodes, links;

  if (configsToLink.length) {
    nodes = graph.nodes;
    links = graph.links;

    for (i = 0; i < nodes.length; i++) {
      node = nodes[i];

      if (node.entityId === entityId) {
        sourceIndex = i;

        for (j = 0; j < configsToLink.length; j++) {
          nodeConfig = configsToLink[j];

          childIndex = nodes.indexOf(nodeConfig.node);

          if (childIndex) {
            links.push({
              source: sourceIndex,
              target: childIndex,
            });
          }
        }
        break;
      }
    }
  }
}

/**
 * Checks if the node has a profile and if the node's entityType is not 'property'
 * @param {Object} node
 * @returns {Boolean}
 */
function isProfileSearchable(node) {
  return node.hasProfile && node.entityType.toLowerCase() !== 'property';
}

/**
 * Returns function that returns a list of nodes that are filters by the `isProfileSearchable` function
 * @param {Array} nodes
 * @returns {Function}
 */
function findNodesForTombstones(nodes) {
  return function () {
    return nodes.filter(isProfileSearchable);
  };
}
