import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sitename',
})
export default class SiteNamePipe implements PipeTransform {
  constructor() {}

  /**
   * Retrieves base url name after `://`
   * @param url
   * @returns {String}
   */
  transform(url: string = '') {
    let cropLead = String(url).substr(String(url).indexOf('://') + 3);
    let firstSlash = cropLead.indexOf('/');

    if (firstSlash === -1) {
      return cropLead;
    }

    return cropLead.substr(0, cropLead.indexOf('/'));
  }
}
