import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Component({
  selector: 'ddiq-change-password-with-token',
  templateUrl: 'ddiq-change-password-with-token.component.tpl.html',
})
export default class ChangePasswordWithTokenComponent implements OnInit {
  passwordValidationPattern = /^(?=.{9,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/;
  request: {
    error: any;
    token: any;
    action: any;
  };
  user: {
    error?: string;
    newPasswordConfirm: string;
    newPassword: string;
  };
  private routeParams: ParamMap;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiFetchService: ApiFetchService,
    private messageBus: MessageBusService,
    private windowRefService: WindowRefService
  ) {
    this.routeParams = this.route.snapshot.paramMap;
    this.request = {
      token: undefined,
      action: undefined,
      error: undefined,
    };
    this.user = {
      error: undefined,
      newPassword: undefined,
      newPasswordConfirm: undefined,
    };
  }

  ngOnInit() {
    this.apiFetchService.verifyToken(this.routeParams.get('token')).then(
      () => {
        this.request.action = this.routeParams.get('action');
        this.request.token = this.routeParams.get('token');
      },
      (response) => {
        this.request.action = 'invalid';
        this.request.error = response.error;
      }
    );
  }

  changePasswordWithToken(token, user) {
    this.apiFetchService.changePasswordWithToken(token, user).then(
      () => {
        this.returnToLogin();
      },
      (response) => {
        this.request.error = response.error;
      }
    );
  }

  returnToLogin() {
    this.windowRefService.nativeWindow.location.href = '/';
  }

  validPassword() {
    const validPassword = this.passwordValidationPattern.test(this.user.newPassword);
    const passwordsMatch = this.user.newPassword === this.user.newPasswordConfirm;

    if (!validPassword) {
      this.user.error =
        'Password must be a minimum 9 characters with: 1 digit, 1 uppercase, 1 lowercase and 1 special character';
    } else if (passwordsMatch) {
      this.user.error = '';
    } else {
      this.user.error = 'New passwords do not match';
    }

    return validPassword && passwordsMatch && this.user.newPassword && this.user.newPassword.length > 0;
  }
}
