<div id="notify">
  <div class="modal-title">Notify User</div>

  <div class="modal-content center-block">
    <div class="clearfix pushdown">
      <div class="input">
        <label class="inline">Search:</label>
        <div class="inline">
          <input type="text" [(ngModel)]="userSearch" />
        </div>
      </div>

      <div class="input">
        <label>{{'Select User' | translatef}}</label>
        <select size="7" [(ngModel)]="selectedUser">
          <option *ngFor="let user of filterGroups()" [ngValue]="user">
            {{(user.fullName + ' (' + user.emailAddress + ')')}}
          </option>
        </select>
      </div>
      <div class="input">
        <label>{{'Notes' | translatef}}</label>
        <textarea class="textarea" [(ngModel)]="emailNotes"></textarea>
      </div>
    </div>
  </div>

  <div class="center push-down pushdown clr">
    <button type="button" (click)="notify()" [disabled]="!selectedUser || !emailNotes" class="btn btn-primary">
      Notify
    </button>
    <button type="button" (click)="cancel()" class="btn btn-default">Cancel</button>
  </div>
</div>
