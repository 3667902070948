import EventGroupsTemplate from '../event-groups/event-groups-template';
import { Injectable } from '@angular/core';
import EventGroupFilterService from '../event-groups/event-group-filter-service.service';
import EventGroupBuilderService from './event-group-builder.service';
import EventGroupsBuilderService from './event-groups-builder.service';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateRecordEventGroupsService extends EventGroupsTemplate {
  private eventGroupSorts: { adjudication(corporateRecords): void; association(corporateRecords): void };

  constructor(
    private structuredDataSorts: StructuredDataSorts,
    private eventGroupFilterService: EventGroupFilterService,
    private eventGroupBuilderService: EventGroupBuilderService,
    private eventGroupsBuilderService: EventGroupsBuilderService
  ) {
    super('corporateRecordsEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
    this.eventGroupSorts = {
      association(corporateRecords) {
        structuredDataSorts.sortByAssociation(corporateRecords);
      },
      adjudication(corporateRecords) {
        structuredDataSorts.sortByAdjudication(corporateRecords);
      },
    };
  }

  addEventGroupsToSection() {
    return (section) => {
      const corporateRecords = section.data.corporateRecords;

      if (!corporateRecords) return;

      const eventGroup = this.eventGroupBuilderService
        .newBuilder()
        .withDiscoveries(corporateRecords)
        .withTitle('Records')
        .withSort((discoveries) => this.structuredDataSorts.sortByAdjudication(discoveries))
        .build();

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService.newEventGroupsModel([eventGroup]).build();
    };
  }
}
