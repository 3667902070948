import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import UserService from '../user/user.service';
import isAllFiltered from '../themed-entity/is-all-filtered';

@Component({
  selector: 'ddiq-person-list',
  templateUrl: './ddiq-person-list.component.tpl.html',
})
export default class DdiqPersonListComponent implements OnInit {
  @Input() people;
  @Input() alertsEnabled: boolean;
  @Output() requestReport = new EventEmitter<any>();
  @Input() title;
  @Input() adjudicationSupport;
  @Output() adjudicationComplete = new EventEmitter<any>();
  @Input() isRequestBtnEnabled: boolean;

  canUserSubmitProfile: boolean;
  sortBy = {
    source: ['-base.totalSourceCount', 'base.name'],
    name: ['base.name', '-base.totalSourceCount'],
  };
  sortBtnOrderType = this.sortBy.source;

  isAllFiltered: (entities) => boolean;

  constructor(private user: UserService) {
    this.isAllFiltered = isAllFiltered;
  }

  ngOnInit() {
    this.canUserSubmitProfile = this.user.canSubmitProfile();
  }

  request(report) {
    this.requestReport.emit(report);
  }

  isAdjudicationComplete(event) {
    this.adjudicationComplete.emit(event);
  }

  oiqId(index: number, entity: any) {
    return entity.base.oiqId;
  }

  sort(btnName) {
    if (btnName) this.sortBtnOrderType = this.sortBy[btnName];
  }
}
