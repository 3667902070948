import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

let promise;

@Injectable({
  providedIn: 'root',
})
export default class SessionTerminationMessageService {
  message: string;
  showMessage: boolean;

  constructor(private apiFetchService: ApiFetchService) {}

  setMessage(message) {
    this.message = message;
  }

  setShowMessage(showMessage) {
    this.showMessage = showMessage;
  }

  getMessage() {
    return this.message;
  }

  hasMessage() {
    return !!(this.message || '').trim();
  }

  showMessageOnUserLogOut() {
    return this.showMessage;
  }

  startCheck() {
    this.stopCheck();
    promise = setInterval(() => {
      this.apiFetchService.ping();
    }, 1000 * 60 * 5);
  }

  stopCheck() {
    clearInterval(promise);
  }
}
