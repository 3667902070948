import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-known-associate-form',
  templateUrl: './ddiq-known-associate-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class KnownAssociateFormComponent implements OnInit {
  @Input() relatedPeople;

  constructor() {}

  ngOnInit() {
    if (!this.relatedPeople || this.relatedPeople.length === 0) {
      this.relatedPeople = [{}];
    }
  }

  removeRelatedPerson(idx) {
    this.relatedPeople.splice(idx, 1);
  }

  addRelatedPerson() {
    if (!this.relatedPeople) {
      this.relatedPeople = [];
    }
    this.relatedPeople.push({});
  }
}
