import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-select',
  templateUrl: './ddiq-select.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class DdiqSelectComponent implements OnInit {
  @Input() model;
  @Input() label;
  @Input() options;
  @Input() prompt;
  @Input() oiqTextDisabled;
  @Input() oiqFieldRequired;
  @Input() value;
  @Input() size;
  @Input() displayValue;

  @Input() name: string;

  @Output() modelChange = new EventEmitter<string>();
  constructor() {}
  ngOnInit() {
    if (!this.model) {
      // set the default drop down value if there is no existing default
      this.model = '';
    }
  }

  isRequired() {
    if (typeof this.oiqFieldRequired === 'function') {
      return this.oiqFieldRequired();
    } else {
      return this.oiqFieldRequired;
    }
  }

  getDisplayValue(option) {
    if (this.displayValue) {
      return option[this.displayValue];
    }

    return option;
  }

  getValue(option) {
    if (this.value) {
      return option[this.value];
    }

    return option;
  }

  onSelectChange(value: string) {
    this.modelChange.emit(value);
  }

  checkShowPrompt(): string {
    return this.model === undefined ? 'selected' : '';
  }
}
