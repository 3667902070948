import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'imageInDb' })
export default class ImageInDbPipe implements PipeTransform {
  constructor() {}

  transform(images: any): any {
    if (Array.isArray(images)) {
      return images.filter((image) => /.+\/ddiq-services\/rest\/image(\/\d+|\?)/.test(image.oiqImageUrl));
    }
    return [];
  }
}
