import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import moment from 'moment';
import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import Filter from './filter.service';
import AssociationBuckets from '../association/association-buckets.service';

const filterDateFormat = 'yy-mm-dd';

@Component({
  selector: 'ddiq-nav-filter',
  templateUrl: './ddiq-nav-filter.component.tpl.html',
})
export default class NavFilterComponent implements OnInit, OnDestroy, DoCheck {
  adjudicationEnabled;
  hideAdjudicationExclusion;
  datePattern;
  sections;
  dates;
  dateFilterMaximumYears;
  filtered;
  currentFilterState;
  dispositionToggles;
  deregister;

  constructor(
    private filter: Filter,
    private oiqProperties: OiqProperties,
    private associationBuckets: AssociationBuckets,
    private adjudicationFeatureService: AdjudicationFeatureService,
    private messageBusService: MessageBusService
  ) {
    this.adjudicationEnabled = this.oiqProperties.adjudicationEnabled;
    this.hideAdjudicationExclusion = this.oiqProperties.hideAdjudicationExclusion;
    this.datePattern = 'yy-mm-dd';

    if (this.oiqProperties.dateFormat === 'US') {
      this.datePattern = 'mm/dd/yy';
    }

    this.sections = this.filter.getSections();
    this.dates = this.resetDates({});
    this.dateFilterMaximumYears = this.oiqProperties.dateFilterMaximumYears;
    this.filtered = this.filter.isFiltered();
  }

  ngOnInit() {
    this.updateSectionFilterState();

    this.deregister = this.messageBusService.on('event:profile-filtered', (isFiltered) => {
      /* jshint unused: true */
      this.filtered = isFiltered;
      this.updateSectionFilterState();
    });

    this.currentFilterState = { ...this.filter.getFilter() };
    this.makeDispositionToggles(this.currentFilterState);

    this.setFilterDates(this.currentFilterState);
  }

  ngOnDestroy() {
    this.deregister.unsubscribe();
  }

  isEqual(obj1, obj2) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    const keys = Object.keys(obj1);
    for (let i = 0; i < keys.length; i++) {
      if (obj1[keys[i]] !== obj2[keys[i]]) {
        return false;
      }
    }

    return true;
  }

  ngDoCheck() {
    const filter = { ...this.filter.getFilter() };
    if (!this.isEqual(filter, this.currentFilterState)) {
      this.makeDispositionToggles(filter);

      this.setFilterDates(filter);

      this.currentFilterState = filter;
    }
  }
  setFilterDates(currentFilter) {
    if (currentFilter) {
      if (currentFilter.fromDate) {
        this.dates.after = jQuery.datepicker.parseDate(filterDateFormat, currentFilter.fromDate);
      }

      if (currentFilter.toDate) {
        this.dates.before = jQuery.datepicker.parseDate(filterDateFormat, currentFilter.toDate);
      }
    }
  }

  resetDates(dates) {
    const maxDate = new Date();

    dates.after = null;
    dates.before = null;
    dates.options = {
      after: {
        dateFormat: this.datePattern,
        maxDate: maxDate,
      },
      before: {
        dateFormat: this.datePattern,
        maxDate: maxDate,
        minDate: null,
      },
    };

    return dates;
  }

  updateSectionFilterState() {
    this.sections.forEach((section) => {
      if (section.isConfigured) {
        section.metadata.filtered = this.filter.isSectionFiltered(section.name);
      }
    });
  }

  reset() {
    const filter = {
      fromDate: null,
      toDate: null,
      associationBucket: this.associationBuckets.getInitialBucket(),
    };

    this.makeDispositionToggles();
    this.resetDates(this.dates);
    this.filter.clearFilter();
    this.filter.apply(filter);
  }

  makeDispositionToggles(filter: any = {}) {
    const disableNonExclusiveToggles = filter.confirmedOnly || filter.escalatedOnly || filter.adjudicatedOnly;
    this.dispositionToggles = {
      showConfirmedOnly: {
        disabled: !!(filter.escalatedOnly || filter.adjudicatedOnly),
        on: !!filter.confirmedOnly,
        toggled: this.toggleConfirmed.bind(this),
      },
      showStarredOnly: {
        disabled: !!(filter.confirmedOnly || filter.adjudicatedOnly),
        on: !!filter.escalatedOnly,
        toggled: this.toggleStarred.bind(this),
      },
      showAdjudicatedOnly: {
        disabled: !!(filter.confirmedOnly || filter.escalatedOnly),
        on: !!filter.adjudicatedOnly,
        toggled: this.toggleShowAdjudicated.bind(this),
      },
      hideConfirmed: {
        disabled: disableNonExclusiveToggles,
        on: !!filter.hideConfirmed,
        toggled: this.toggleHideConfirmed.bind(this),
      },
      hideStarred: {
        disabled: disableNonExclusiveToggles,
        on: !!filter.hideEscalated,
        toggled: this.toggleHideStarred.bind(this),
      },
      hideAdjudicated: {
        disabled: disableNonExclusiveToggles,
        on: !!filter.excludeAdjudicated,
        toggled: this.toggleHideAdjudicated.bind(this),
      },
    };
  }

  updateExclusions() {
    const filterSpec = {
      confirmedOnly: this.dispositionToggles.showConfirmedOnly.on,
      hideConfirmed: this.dispositionToggles.hideConfirmed.on,
      escalatedOnly: this.dispositionToggles.showStarredOnly.on,
      hideEscalated: this.dispositionToggles.hideStarred.on,
      excludeAdjudicated: this.dispositionToggles.hideAdjudicated.on,
      adjudicatedOnly: this.dispositionToggles.showAdjudicatedOnly.on,
    };

    this.filter.updateExclusions(filterSpec);
  }

  toggleReportSection(section) {
    this.filter.updateSectionMask(section.name);
    this.filter.apply(this.filter.getFilter());
  }

  toggleStarred() {
    this.updateExclusions();
  }

  toggleConfirmed() {
    this.updateExclusions();
  }

  toggleHideConfirmed() {
    this.updateExclusions();
  }

  toggleHideStarred() {
    this.updateExclusions();
  }

  toggleShowAdjudicated() {
    this.updateExclusions();
  }

  toggleHideAdjudicated() {
    this.updateExclusions();
  }

  sectionState(section) {
    return section.metadata.navLinkText + ' is ' + (section.metadata.filtered ? 'hidden' : 'visible');
  }

  isShowConfirmVisible() {
    return this.adjudicationFeatureService.isConfirmActionEnabled();
  }

  setDateRangeFromToday(value, unit) {
    this.resetDates(this.dates);
    const dayUnit = 'day';
    const today = moment().startOf(dayUnit).toDate();
    const before = today;

    let unitOfTime: any = dayUnit;
    if (unit === 'years') {
      unitOfTime = 'year';
    }

    const after = moment(today).add(-value, unitOfTime).toDate();

    this.dates.before = today;
    this.dates.after = after;

    const filter = this.filter.getFilter();
    if (filter) {
      filter.fromDate = jQuery.datepicker.formatDate(filterDateFormat, after);
      filter.toDate = jQuery.datepicker.formatDate(filterDateFormat, today);
      this.filter.apply(filter);
    }
  }

  onAfterDateChange(afterDate) {
    let afterDateObj = afterDate;

    const filter = this.filter.getFilter();

    if (afterDate) {
      this.dates.options.before = {
        minDate: afterDate ? afterDateObj : null,
        maxDate: new Date(),
        dateFormat: this.datePattern,
      };
    }

    if (filter) {
      filter.fromDate = (afterDateObj && jQuery.datepicker.formatDate(filterDateFormat, afterDateObj)) || null;
      this.filter.apply(filter);
    }
  }

  onBeforeDateChange(beforeDate) {
    let beforeDateObj = beforeDate;

    const filter = this.filter.getFilter();

    if (beforeDate) {
      this.dates.options.after = {
        maxDate: beforeDate ? beforeDateObj : new Date(),
        dateFormat: this.datePattern,
      };
    }

    if (filter) {
      filter.toDate = (beforeDateObj && jQuery.datepicker.formatDate(filterDateFormat, beforeDateObj)) || null;
      this.filter.apply(filter);
    }
  }

  setLastDateRange(range, units?) {
    this.setDateRangeFromToday(range, units);
  }
}
