import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { filter } from 'rxjs/operators';

export abstract class NavigationRequest {
  id: string;
  constructor(id: string) {
    this.id = id;
  }
}
export class ByEventId extends NavigationRequest {}
export class ByHitId extends NavigationRequest {}

@Injectable()
export class ProfileNavigationService {
  private _navigationScroll$ = new BehaviorSubject<NavigationRequest | null>(null);

  constructor(private route: ActivatedRoute) {
    route.queryParams.subscribe((params: Params) => {
      const hitId: string = params['navByHitId'];
      const eventId: string = params['navByEventId'];
      let navigationRequest: NavigationRequest;

      if (hitId) {
        navigationRequest = new ByHitId(hitId);
      } else if (eventId) {
        navigationRequest = new ByEventId(eventId);
      }

      this._navigationScroll$.next(navigationRequest);
    });
  }

  get navigationRequested$(): Observable<NavigationRequest> {
    return this._navigationScroll$
      .asObservable()
      .pipe(filter((navigationRequest: NavigationRequest) => navigationRequest instanceof NavigationRequest));
  }
}
