<header>
  <h1>Configuration</h1>
</header>

<section class="fs">
  <div>
    <h2>Features</h2>
    <table class="data-table">
      <tr *ngFor=" let feature of oiqConfigurationInfo.features | orderBy: 'key'">
        <td class="no-pad">{{feature.key}}</td>
        <td class="no-pad">{{feature.value}}</td>
      </tr>
    </table>
    <h2>Raw configuration</h2>
    <table class="data-table">
      <tr *ngFor=" let config of oiqConfigurationInfo.rawConfiguration | orderBy: 'key'">
        <td class="no-pad">{{config.key}}</td>
        <td class="no-pad">{{config.value}}</td>
      </tr>
    </table>
  </div>

  <br class="clr" />
</section>
