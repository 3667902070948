import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';

@Component({
  selector: 'ddiq-crawl-error',
  templateUrl: './ddiq-crawl-error.component.tpl.html',
})
export default class CrawlErrorComponent implements OnInit {
  hasErrors;
  sourcesWithErrors;

  constructor(
    private apiFetchService: ApiFetchService,
    private entity: Entity,
    private oiqProperties: OiqProperties,
    private translatefPipe: TranslatefPipe
  ) {}

  ngOnInit() {
    if (this.oiqProperties.displayAuditErrorsEnabled) {
      this.loadAuditErrorData();
    }
  }

  loadAuditErrorData() {
    this.hasErrors = false;

    const entityType = this.entity.getType();
    const entityId = this.entity.getId();

    this.apiFetchService.audit(entityType, entityId, true).then((result) => {
      if (result.sources && result.sources.errors) {
        this.sourcesWithErrors = this.collateSourcesWithErrors(result.sources.errors);
        this.hasErrors = result.sources.errors.length > 0;
      }
    });
  }

  collateSourcesWithErrors(errors) {
    const results = [];

    (errors || []).forEach((error) => {
      let sourceDesc = error.sourceName;

      if (error.workerCategories && error.workerCategories.length > 0) {
        sourceDesc += ' (';
        for (let i = 0; i < error.workerCategories.length; i++) {
          if (i > 0) {
            sourceDesc += ', ';
          }
          sourceDesc += this.translatefPipe.transform(error.workerCategories[i]);
        }
        sourceDesc += ')';
      }

      if (results.indexOf(sourceDesc) === -1) {
        results.push(sourceDesc);
      }
    });

    return results;
  }
}
