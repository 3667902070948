import { Component, Input, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import $ from 'jquery';
import OiqProperties from '../common-services/oiq-properties.service';

const handleOffset = { top: 25, left: 40 },
  radioButtons = [
    { value: 'DIRECT_OWNER', labelText: 'Show Direct Owners of the main entity only' },
    { value: 'ULTIMATE_OWNER', labelText: 'Show Ultimate Owners of the main entity only' },
    { value: 'ALL', labelText: 'Show All' },
  ];
let defaultSliderValue;

@Component({
  selector: 'corporate-graph-drawer',
  templateUrl: './corporate-graph-drawer.component.tpl.html',
})
export default class CorporateGraphDrawer implements AfterViewInit, OnInit {
  isDrawerOpen: boolean;
  radioButtons: { [key: string]: any };
  filterState: { ownershipType: string; sliderValue: number };
  percentSlider: any;
  percentInput: any;
  handle: any;

  @Input() filterCallback: any;

  constructor(private OiqProperties: OiqProperties, private element: ElementRef) {
    this.isDrawerOpen = true;
    this.radioButtons = radioButtons;
    this.filterState = {
      ownershipType: 'ALL',
      sliderValue: 0,
    };
  }

  ngOnInit() {
    defaultSliderValue = this.OiqProperties.defaultOwnershipFilterPercentage;
  }

  ngAfterViewInit() {
    this.percentSlider = $(this.element.nativeElement).find('.slider-container');
    this.percentInput = $(this.element.nativeElement).find('.percent-input');

    this.percentSlider.slider({
      orientation: 'vertical',
      range: 'max',
      min: 0,
      max: 100,
      disabled: this.isShowAll(),
      value: this.filterState.sliderValue,
      create: this.create(),
      slide: this.updateSliderLabel(),
      change: this.filterGraph(),
    });
  }

  toggleDrawerOpen() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  radioButtonClick() {
    if (this.isShowAll()) {
      this.setSlider(0, 'disable');
    } else {
      this.setSlider(defaultSliderValue, 'enable');
    }
  }

  create() {
    if (this.filterState.sliderValue !== 0) {
      this.filterCallback(this.filterState);
    }
    return this.updateSliderLabel();
  }

  filterGraph() {
    return (event, ui) => {
      this.filterState.sliderValue = ui.value;
      this.filterCallback(this.filterState);
    };
  }

  updateSliderLabel() {
    return (e, ui) => {
      if (typeof ui.value === 'undefined') {
        ui.value = this.filterState.sliderValue;
        this.handle = $(this.element.nativeElement).find('.ui-slider-handle');
      }

      setTimeout(() => {
        let handleCoord = this.handle.position();
        handleCoord.top += handleOffset.top;
        handleCoord.left += handleOffset.left;
        this.percentInput.val(ui.value + ' %').css(handleCoord);
      }, 1);
    };
  }

  setSlider(sliderValue, state) {
    this.percentSlider.slider('value', sliderValue);
    this.updateSliderLabel()('', { value: sliderValue });
    this.percentSlider.slider(state);
  }

  isShowAll() {
    return this.filterState.ownershipType === 'ALL';
  }
}
