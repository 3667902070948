import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

@Component({
  selector: 'version',
  templateUrl: './ddiq-version.component.tpl.html',
})
export default class VersionComponent implements OnInit {
  oiqVersionInfo: any;

  constructor(private apiFetchService: ApiFetchService) {}

  ngOnInit() {
    this.apiFetchService.version().then((data) => {
      this.oiqVersionInfo = data;
    });
  }
}
