import { NgModule } from '@angular/core';
import DdiqRiskSummaryComponent from './ddiq-risk-summary.component';
import DdiqRiskReviewComponent from './ddiq-risk-review.component';
import RiskAssessmentService from './risk-assessment.service';
import RiskInClassificationComponent from './risk-in-classification.component';
import { CommonModule } from '@angular/common';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import TranslateGroupedRiskTitlePipe from './translate-grouped-risk-title.pipe';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';

@NgModule({
  imports: [CommonModule, PipesModule, DdiqDirectivesModule, DdiqUIModule],
  declarations: [
    DdiqRiskSummaryComponent,
    DdiqRiskReviewComponent,
    RiskInClassificationComponent,
    TranslateGroupedRiskTitlePipe,
  ],
  providers: [RiskAssessmentService, TranslatefPipe, TranslateGroupedRiskTitlePipe],
  exports: [DdiqRiskReviewComponent, RiskInClassificationComponent, DdiqRiskSummaryComponent],
})
export class OiqRiskModule {}
