import { Injectable } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import utils from '../../../exiger/utils';

@Injectable({
  providedIn: 'root',
})
export default class IncidentGroupDisplaySettings {
  constructor(private windowRef: WindowRefService) {}

  getShowOtherMentionsOpen() {
    let isOpen = false;
    const location = this.windowRef.nativeWindow.location;
    const printParam = utils.getParameter('print', location.href);
    const excludeParam = utils.getParameter('exclude', location.href);

    if (printParam === 'expanded' && excludeParam !== 'dedupes') {
      isOpen = true;
    }

    return isOpen;
  }

  getShowReferencesOpen() {
    return this.getShowOtherMentionsOpen();
  }
}
