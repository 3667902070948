import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertHtmlSpecificChars',
})
export default class ConvertHtmlSpecificCharsPipe implements PipeTransform {
  constructor() {}

  /**
   * Replaces unicode characters in strings with corresponding HTML characters
   * @param input
   * @returns {String}
   */
  transform(input: any) {
    const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
    };

    return String(input).replace(translate_re, function (match, entity) {
      return translate[entity];
    });
  }
}
