import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import ConfigService from '../../../../../shared/services/http/config.service';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export default class UpgradeVersionInterceptor implements HttpInterceptor {
  constructor(private messageBusService: MessageBusService, private Config: ConfigService) {}

  private HTTP_GONE_STATUS = 410;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const version = this.getVersion();

    if (version) {
      request = request.clone({
        setHeaders: {
          'X-DDIQ-Client-Version': version,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === this.HTTP_GONE_STATUS) {
          this.messageBusService.send({ type: 'event:upgrade-required' });
        }
        return throwError(error);
      })
    );
  }

  private getVersion() {
    return this.Config.settings && this.Config.settings.project && this.Config.settings.project.version;
  }
}
