import { Injectable } from '@angular/core';
import EventGroupsTemplate from '../event-groups/event-groups-template';
import EventGroupSort from '../event-groups/event-group-sort.service';
import EventGroupsBuilderService from './event-groups-builder.service';
import EventGroupBuilderService from './event-group-builder.service';
import TranslateService from '../common-services/translate.service';
import StructuredDataSorts from '../themed-entity/structured-data-sorts.service';

@Injectable({
  providedIn: 'root',
})
export default class WebsiteScorecardEventGroupsService extends EventGroupsTemplate {
  constructor(
    private eventGroupsort: EventGroupSort,
    private eventGroupsBuilderService: EventGroupsBuilderService,
    private eventGroupBuilderService: EventGroupBuilderService,
    private translateService: TranslateService,
    private structuredDataSorts: StructuredDataSorts
  ) {
    super('websiteScorecardsEventGroups', eventGroupBuilderService, eventGroupsBuilderService);
  }

  addEventGroupsToSection() {
    return (section) => {
      const websiteScorecardsByClassification = section.data.websiteScorecards;
      if (!websiteScorecardsByClassification) return;

      const eventGroups = Object.keys(websiteScorecardsByClassification).map((classification) => {
        const websiteScorecards = websiteScorecardsByClassification[classification];

        const translatedClassification = this.translateService.translate(classification);
        return this.eventGroupBuilderService
          .newBuilder()
          .withDiscoveries(websiteScorecards)
          .withSort((discoveries) => this.structuredDataSorts.sortByAdjudication(discoveries))
          .withTitle(translatedClassification)
          .add('classification', classification)
          .add('translatedClassification', translatedClassification)
          .build();
      });

      section.data[this.eventGroupsName] = this.eventGroupsBuilderService
        .newEventGroupsModel(eventGroups)
        .withSort((eventGroups) => this.eventGroupsort.sort(eventGroups))
        .build();
    };
  }
}
