import { Directive, Input, OnInit } from '@angular/core';
import Entity from '../common-services/entity.service';
import OiqProperties from '../common-services/oiq-properties.service';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import Sources from './sources.service';

@Directive({
  selector: '[oiq-source-info-ctrl]',
  exportAs: 'oiqSourceInfoCtrl',
})
export default class OiqSourceInfoCtrlDirective implements OnInit {
  @Input() info: any;

  private toggledOpen: boolean;
  private _sources;

  showSources: boolean;

  constructor(
    private oiqProperties: OiqProperties,
    private entity: Entity,
    private translatePipe: TranslatefPipe,
    private sourcesService: Sources
  ) {}

  ngOnInit() {
    this.toggledOpen = false;
    this.info = this.info || { discovery: { totalSourceCount: 0, sources: [], sourceUrls: [] } };
    this.info.discovery = this.info.discovery || {};
    this._sources = (this.info.discovery.sources || []).map((source) => {
      return this.translatePipe.transform(source);
    });
    this.showHideSources(this.sourcesService.shouldShow());
  }

  toggle() {
    this.toggledOpen = !this.toggledOpen;
    this.showHideSources(this.toggledOpen);
  }

  isOpen() {
    return this.sourcesService.shouldShow() || this.toggledOpen;
  }

  sources() {
    return this._sources;
  }

  articles() {
    return this.info.discovery.sourceUrls;
  }

  total() {
    return this.info.discovery.totalSourceCount || 0;
  }

  shouldShowCachedArticleLinks() {
    return this.oiqProperties.articleCacheEnabled;
  }

  getCurrentEntityId() {
    return this.entity.getId();
  }

  showHideSources(isShow) {
    this.showSources = isShow;
  }

  setTotalSourceCount(tSources, tArticles, discovery) {
    if (discovery) {
      discovery.totalSourceCount = tSources + tArticles;
    }
  }
}
