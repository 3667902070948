import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import OiqProperties from '../common-services/oiq-properties.service';
import UserService from '../user/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ddiq-main-nav',
  templateUrl: 'ddiq-main-nav.component.tpl.html',
})
export default class MainNavComponent implements OnInit {
  canSubmitProfile: boolean;
  monitoringEnabled: boolean;
  shouldRenderMonitorReportsLink: boolean;
  isPersonSubmissionEnabled: boolean;
  userName: string;
  isProfile: boolean;
  monitorHitCount: number;

  constructor(
    private oiqProperties: OiqProperties,
    private user: UserService,
    private apiFetchService: ApiFetchService,
    private router: Router
  ) {}

  ngOnInit() {
    this.oiqProperties.apiPropertiesPromise.then(() => {
      this.canSubmitProfile = this.user.canSubmitProfile();
      this.monitoringEnabled = this.user.canMonitorProfiles() && this.oiqProperties.monitoringEnabled;
      this.shouldRenderMonitorReportsLink =
        this.oiqProperties.isElasticSearchEnabled && this.user.canViewConfiguration();
      this.isPersonSubmissionEnabled = this.oiqProperties.personSubmissionEnabled;
      this.userName = this.user.getUserName();
      this.updateMonitorHitCount();
    });

    this.router.events
      .pipe(filter((e: NavigationEnd): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        const newUrlPieces = this.router.url.split('/');
        this.isProfile = newUrlPieces.length > 1 ? newUrlPieces[1].split('/')[0] === 'overview' : false;
        this.updateMonitorHitCount();
      });
  }

  updateMonitorHitCount() {
    if (this.monitoringEnabled && this.user.isInitialized()) {
      this.apiFetchService.getMonitorCount().then((data) => {
        this.monitorHitCount = data.count;
      });
    }
  }
}
