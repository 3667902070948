<div *ngIf="seedData">
  <div class="input-data">
    <div>
      <p>{{ seedData.userEnteredName }}</p>
    </div>
    <div class="input-data" *ngIf="seedData.website">
      <b>Website:</b>
      <a [href]="seedData.website" target="_blank" ref="noopener noreferrer" class="value">{{seedData.website}}</a>
    </div>
    <div class="input-data" *ngIf="seedData.dbaNames?.length">
      <p>Alternative Names:</p>
      <div *ngFor="let dba of seedData.dbaNames">{{dba}}</div>
    </div>
    <div class="input-data" *ngIf="seedData.userEnteredAddress?.rawAddress">
      <p>Raw Address:</p>
      <span>{{seedData.userEnteredAddress.rawAddress}}<br /></span>
    </div>
    <div *ngIf="!seedData.userEnteredAddress?.rawAddress">
      <span *ngIf="seedData.userEnteredAddress?.streetAddress"
        >{{seedData.userEnteredAddress.streetAddress}}<br
      /></span>
      <span *ngIf="seedData.userEnteredAddress?.buildingName">{{seedData.userEnteredAddress.buildingName}}<br /></span>
      <span *ngIf="seedData.userEnteredAddress?.district">{{seedData.userEnteredAddress.district}}</span>
      <span *ngIf="seedData.userEnteredAddress?.city"
        ><span *ngIf="seedData.userEnteredAddress?.district">, </span>{{seedData.userEnteredAddress.city}}</span
      ><span *ngIf="seedData.userEnteredAddress?.stateProvince"
        ><span *ngIf="seedData.userEnteredAddress?.city">, </span>{{ seedData.userEnteredAddress.stateProvince }}</span
      >
      <span *ngIf="(seedData.userEnteredAddress?.zipPostalCode || seedData.userEnteredAddress?.country)"
        >{{ seedData.userEnteredAddress.zipPostalCode }} {{ seedData.userEnteredAddress.country }}</span
      >
    </div>
  </div>
  <div class="input-data" *ngIf="hasDoingBusinessInLocations()">
    <p>Doing Business In:</p>
    <span *ngFor="let location of (seedData.otherLocations | doingBusinessInLocationSubmission)">
      <p>
        <span>{{ location.address | address }}</span>
      </p>
    </span>
  </div>
  <div class="input-data" *ngIf="(hasIncorporatedInLocation() || seedData.identificationNumbers?.length)">
    <div class="push-down" *ngIf="hasIncorporatedInLocation()">
      <p>Incorporated In:</p>
      <span *ngFor="let location of (seedData.otherLocations | incorporatedInLocationSubmission)">
        <p>
          <span>{{ location.address | address }}</span>
        </p>
      </span>
    </div>
    <div class="input-data" *ngIf="seedData.identificationNumbers?.length">
      <p>Identification Numbers:</p>
      <span *ngFor="let identificationNumber of seedData.identificationNumbers">
        <span *ngIf="identificationNumber.number"
          ><span *ngIf="identificationNumber.identificationType"
            >{{ identificationNumber.identificationType | translatef }}: </span
          >{{ identificationNumber.number }}&nbsp;</span
        >
      </span>
    </div>
  </div>
  <div class="input-data" *ngIf="seedData.companyType">
    <p>Submission Type:</p>
    {{seedData.companyType | translatef }}
  </div>
  <div class="pushdown">
    <span *ngIf="section?.data.submissionId"
      >{{ 'REFERENCE_ID' | translatef }}: {{ section?.data.submissionId }}<br
    /></span>
    <span *ngIf="seedData.oiqCreatedBy"
      ><span>{{'Submitted by' | translatef}}</span><span>: {{ seedData.oiqCreatedBy }}</span><br
    /></span>
    <span *ngIf="seedData.reqOrg"
      ><span>{{'Requested by' | translatef}}</span><span>: {{ seedData.reqOrg }}</span><br
    /></span>
    <span *ngIf="seedData.correlationId"
      >{{ 'CORRELATION_ID' | translatef }}<span>: {{ seedData.correlationId }}</span><br
    /></span>
    <span *ngIf="section?.data.crawlPlanTypeDescription"
      >{{ 'PROFILE_TYPE' | translatef }} : {{section?.data.crawlPlanTypeDescription | translatef}}<br
    /></span>
    <span *ngIf="section?.data.externalReferenceCode" class="no-print"
      >{{ 'EXTERNAL_REFERENCE_CODE' | translatef }} : {{section?.data.externalReferenceCode}}</span
    >
    <span *ngIf="this.importedFrom">Imported From : {{this.importedFrom}}</span>
  </div>
</div>
<div class="input-data" [hidden]="seedData"><p>Input data unavailable.</p></div>
