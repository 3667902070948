import { Injectable } from '@angular/core';
import Filter from '../themed-entity/filter.service';
import OiqProperties from './oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class ProfileDisplayService {
  private isOpenAll: boolean = false;
  private isShowWarning: boolean = false;

  constructor(private oiqProperties: OiqProperties, private filter: Filter) {}

  init() {
    let contentCount = 0;

    this.reset();

    this.filter.getSections().forEach((section) => {
      if (section.isConfigured) {
        for (let i = 0; i < section.labels.length; i++) {
          let label = section.labels[i];
          let eventCount = this.filter.getEventCounts(section.name, label.labelKey, label.kind);
          contentCount += eventCount.totalCount;
        }
      }
    });

    if (contentCount < 200) {
      this.isOpenAll = true;
    } else {
      this.isShowWarning = true;
    }
  }

  reset() {
    this.isOpenAll = false;
    this.isShowWarning = false;
  }

  isExpandAll() {
    if (this.oiqProperties.isDisplayExpandAll) {
      return this.isOpenAll;
    } else {
      return false;
    }
  }

  showWarning() {
    if (this.oiqProperties.isDisplayExpandAll) {
      return this.isShowWarning;
    } else {
      return false;
    }
  }
}
