import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-email-address-form',
  templateUrl: './ddiq-email-address-form.component.tpl.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export default class EmailAddressFormComponent implements OnInit {
  @Input() emailAddresses;
  @Output() emailAddressesChange = new EventEmitter<any>();

  emailLabels: Array<string> = ['work', 'personal', 'other'];
  constructor() {}

  ngOnInit() {
    if (!this.emailAddresses || this.emailAddresses.length === 0) {
      this.emailAddresses = [{ label: 'other' }];
    }
  }
  addEmail() {
    this.emailAddresses.push({ label: 'other' });
  }

  removeEmail(index) {
    this.emailAddresses.splice(index, 1);
  }
}
