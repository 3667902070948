<section *ngIf="isDiff">
  <ddiq-accordion-top label="Baseline Comparison" open>
    <div>
      <strong>Current Profile</strong>
    </div>
    <p>
      <a [href]="diff.currentProfileUrl">{{ diff.current.displayName }}</a>
      , {{ diff.current.oiqCreatedDate | dateFormat }}
    </p>

    <hr class="clr" />

    <div>
      <strong>Baseline Profile</strong>
    </div>
    <p>
      <a [href]="diff.baselineProfileUrl">{{ diff.baseline.displayName }}</a>
      , {{ diff.baseline.oiqCreatedDate | dateFormat }}
    </p>

    <hr class="clr" />
    <p>
      <em>This page consists of information found in Current Profile but not in Baseline Profile</em>
    </p>
  </ddiq-accordion-top>
</section>
