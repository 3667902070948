<article
  [id]="incident.lookupId"
  class="oiq-incident oiq-incidents-more-entry discovery-group"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{escalated: incident.escalated, confirmed: incident.confirmed}"
  data-oiq-collapsible-ctrl>
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="incident"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-incident-summary oiq-grid-cell">
          <ng-container *ngIf="isDisplayClassifications">
            <div
              class="oiq-incident-classifier"
              [ngClass]="{disabled: incident.disabled}"
              *ngFor="let summary of incident.summary">
              <strong>{{summary.label}}</strong>
            </div>
          </ng-container>

          <ddiq-association [level]="incident.summarizedAssociationBucket" class="oiq-incident-association">
          </ddiq-association>

          <div class="text-center">
            <span class="oiq-incident-display-date" *ngIf="displayDate" [ngClass]="{disabled: incident.disabled}">
              <span class="sr-only" *ngIf="displayDate">Incident Date </span>

              <time class="oiq-incident-display-date-time" [dateTime]="displayDate">
                {{displayDate | date: 'mediumDate':'UTC'}}
              </time>
            </span>

            <span
              class="oiq-incident-first-seen"
              *ngIf="displayFirstSeenDate && incident.firstReportedDate && incident.firstReportedDate < displayDate"
              [ngClass]="{disabled: incident.disabled}">
              <span class="oiq-incident-first-seen-label">First seen</span>
              <time class="oiq-incident-first-seen-time" [dateTime]="incident.firstReportedDate">
                {{incident.firstReportedDate | date: 'mediumDate':'UTC'}}
              </time>
            </span>

            <ddiq-adjudication-actions
              [type]="'INCIDENT'"
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [entityType]="entityType"
              [entityId]="entityId"
              [noReassess]="noReassess"
              [classification]="classification"
              [targetId]="incident.id"
              [model]="incident">
            </ddiq-adjudication-actions>

            <protected-article-selector
              *ngIf="isLexisNexisUrl(incident.evidence[0].references[0].url)"
              [articleId]="incident.evidence[0].references[0].url | lexisNexisArticleId">
            </protected-article-selector>
          </div>
        </div>

        <div class="oiq-incident-bd oiq-grid-cell">
          <header class="oiq-incident-header" [ngClass]="{disabled: incident.disabled}">
            <h4 class="oiq-incident-title" title="{{incident.evidence[0].references[0].title}}">
              <a
                class="oiq-incident-title-nav"
                [ngClass]="{inactiveLink: incident.evidence[0].references[0].url | isDarkWeb : classification}"
                href="{{incident.evidence[0].references[0].url}}"
                target="_blank"
                rel="noopener noreferrer">
                {{incident.evidence[0].references[0].title}}
              </a>
            </h4>

            <div class="oiq-incident-byline">
              <a
                class="oiq-incident-toggle-references"
                *ngIf="incident.evidence[0].references.length > 1"
                data-oiq-collapsible-toggle
                data-toggle-for="references"
                [is-toggle-open]="showReferencesOpen"
                title="{{incident.evidence[0].references.length - 1}} article references">
                <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
                <span class="sr-only"> {{incident.evidence[0].references.length -1}} more article reference(s) </span>
              </a>

              <span class="publication-date" *ngIf="incident.evidence[0].references[0].publicationDate">
                Published on
                <time class="oiq-incident-byline-date" [dateTime]="incident.evidence[0].references[0].publicationDate">
                  {{incident.evidence[0].references[0].publicationDate | date:'mediumDate' :'UTC'}}
                </time>
              </span>

              <address class="oiq-incident-byline-address">
                <a
                  class="oiq-incident-byline-address-nav"
                  [ngClass]="{inactiveLink: incident.evidence[0].references[0].url | isDarkWeb : classification}"
                  href="{{incident.evidence[0].references[0].url}}"
                  target="_blank"
                  rel="author noopener noreferrer">
                  {{incident.evidence[0].references[0] | extractSource}}
                </a>

                <span
                  class="cached-article-nav inline"
                  *ngIf="showArticleCacheLinks && incident.evidence[0].references[0].cachedUrl">
                  (
                  <a
                    class="inline oiq-incident-byline-cached-nav"
                    href="{{incident.evidence[0].references[0].cachedUrl}}"
                    [ngClass]="{'disabled': incident.disabled}"
                    target="_blank"
                    rel="author noopener noreferrer"
                    >cached
                  </a>
                  )
                </span>
              </address>

              <span class="sr-only" *ngIf="incident.evidence[0].translated">Language</span>

              <span class="oiq-incident-byline-translated badge badge-alert" *ngIf="incident.evidence[0].translated">
                {{incident.evidence[0].references[0].languageDescription}}
              </span>

              <a
                class="oiq-incident-toggle-other-mentions"
                *ngIf="hasOtherMentions"
                data-oiq-collapsible-toggle
                data-toggle-for="otherMentions"
                [is-toggle-open]="showOtherMentionsOpen">
                <oiq-collapsible-toggle-icon></oiq-collapsible-toggle-icon>
                {{otherMentions.length}} other mentions
              </a>
            </div>
          </header>

          <section
            class="oiq-incident-references"
            [ngClass]="{disabled: incident.disabled}"
            *ngIf="incident.evidence[0].references.length > 1 ">
            <ddiq-collapsible data-for-toggle="references">
              <article class="oiq-incident-reference">
                <header
                  class="oiq-incident-header"
                  [ngClass]="{'disabled': incident.disabled}"
                  *ngFor="let reference of incident.evidence[0].references | slice:1:incident.evidence[0].references.length">
                  <h4 class="oiq-incident-title" title="{{reference.title}}">
                    <a
                      class="oiq-incident-title-nav"
                      [ngClass]="{inactiveLink: reference.url | isDarkWeb : classification}"
                      href="{{reference.url}}"
                      target="_blank"
                      rel="noopener noreferrer">
                      {{reference.title}}
                    </a>
                  </h4>

                  <div class="oiq-incident-byline">
                    <span class="publication-date" *ngIf="reference.publicationDate">
                      Published on
                      <time class="oiq-incident-byline-date" [dateTime]="reference.publicationDate">
                        {{reference.publicationDate | date:'mediumDate' :'UTC'}}
                      </time>
                    </span>

                    <address class="oiq-incident-byline-address">
                      <a
                        class="oiq-incident-byline-address-nav"
                        [ngClass]="{inactiveLink: reference.url | isDarkWeb : classification}"
                        href="{{reference.url}}"
                        target="_blank"
                        rel="author noopener noreferrer">
                        {{reference | extractSource}}
                      </a>

                      <span class="cached-article-nav inline" *ngIf="showArticleCacheLinks && reference.cachedUrl">
                        (
                        <a
                          class="inline oiq-incident-byline-cached-nav"
                          href="{{reference.cachedUrl}}"
                          [ngClass]="{'disabled': incident.disabled}"
                          target="_blank"
                          rel="author noopener noreferrer"
                          >cached</a
                        >
                        )
                      </span>
                    </address>
                  </div>
                </header>
              </article>
            </ddiq-collapsible>
          </section>

          <section class="oiq-incident-body" [ngClass]="{disabled: incident.disabled}">
            <p
              class="oiq-incident-snippet"
              *ngIf="showTranslatedSnippets || !incident.evidence[0].translated"
              [innerHTML]="incident.evidence[0].snippet | stripScriptAndMeta | convertHtmlSpecificChars">
              Snippet Currently Unavailable
            </p>

            <p
              class="oiq-incident-snippet-untranslated"
              *ngIf="incident.evidence[0].translated"
              [innerHTML]="incident.evidence[0].untranslatedSnippet | stripScriptAndMeta | convertHtmlSpecificChars">
              Untranslated Snippet Unavailable
            </p>
          </section>

          <section
            class="oiq-incident-secondary-classifications"
            *ngIf="hasSecondaryClassifications"
            [ngClass]="{disabled: incident.disabled}">
            <div class="oiq-secondary-classifications">Also referenced:</div>

            <ul class="oiq-secondary-classifications">
              <li *ngFor="let classification of incident.secondaryClassifications">
                {{classification.label | translatef}}
              </li>
            </ul>
          </section>

          <section
            class="oiq-incident-other-mentions"
            *ngIf="hasOtherMentions"
            [ngClass]="{disabled: incident.disabled}">
            <ddiq-collapsible data-for-toggle="otherMentions">
              <article
                class="oiq-incident oiq-incident-other-mention discovery-group"
                *ngFor="let evidence of incident.evidence | slice: 1:incident.evidence.length">
                <div
                  class="oiq-incident-other-mention-reference"
                  *ngFor="let reference of evidence.references; let $last = last;">
                  <header class="oiq-incident-header">
                    <h4 class="oiq-incident-title" title="{{reference.title}}">
                      <a
                        class="oiq-incident-title-nav"
                        [ngClass]="{inactiveLink: reference.url | isDarkWeb : classification}"
                        href="{{reference.url}}"
                        target="_blank"
                        rel="noopener noreferrer">
                        {{reference.title}}
                      </a>
                    </h4>

                    <div class="oiq-incident-byline">
                      <span class="publication-date" *ngIf="reference.publicationDate">
                        Published on
                        <time class="oiq-incident-byline-date" [dateTime]="reference.publicationDate">
                          {{reference.publicationDate | date:'mediumDate' :'UTC'}}
                        </time>
                      </span>

                      <address class="oiq-incident-byline-address" *ngIf="reference.url">
                        <a
                          class="oiq-incident-byline-address-nav"
                          [ngClass]="{inactiveLink: reference.url | isDarkWeb : classification}"
                          href="{{reference.url}}"
                          target="_blank"
                          rel="author noopener noreferrer">
                          {{reference | extractSource}}
                        </a>

                        <span class="cached-article-nav inline" *ngIf="showArticleCacheLinks && reference.cachedUrl">
                          (
                          <a
                            class="inline oiq-incident-byline-cached-nav"
                            href="{{reference.cachedUrl}}"
                            [ngClass]="{'disabled': incident.disabled}"
                            target="_blank"
                            rel="author noopener noreferrer"
                            >cached</a
                          >
                          )
                        </span>
                      </address>

                      <span class="sr-only" *ngIf="evidence.translated">Language</span>

                      <span class="oiq-incident-byline-translated badge badge-alert" *ngIf="evidence.translated"
                        >{{reference.languageDescription}}</span
                      >
                    </div>
                  </header>

                  <section class="oiq-incident-body" *ngIf="$last">
                    <p
                      class="oiq-incident-other-mention-snippet oiq-incident-snippet"
                      *ngIf="showTranslatedSnippets || !evidence.translated"
                      [innerHTML]="evidence.snippet | stripScriptAndMeta | convertHtmlSpecificChars">
                      Snippet Currently Unavailable
                    </p>

                    <p
                      class="oiq-incident-other-mention-snippet oiq-incident-snippet-untranslated"
                      *ngIf="evidence.translated"
                      [innerHTML]="evidence.untranslatedSnippet | stripScriptAndMeta | convertHtmlSpecificChars">
                      Untranslated Snippet Unavailable
                    </p>
                  </section>
                </div>
              </article>
            </ddiq-collapsible>
          </section>

          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'INCIDENT'"
            [entityType]="entityType"
            [entityId]="entityId"
            [noReassess]="noReassess"
            [classification]="classification"
            [targetId]="incident.id"
            [model]="incident"
            [label]="'Notes'"
            [active]="incident.comment">
          </ddiq-adjudication-notes>
        </div>
      </div>
    </div>
  </div>
</article>
