import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'cached-article',
  templateUrl: './cached-article.component.tpl.html',
})
export default class CachedArticleComponent implements OnInit {
  article;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.article = data.preLoad;
    });
  }
}
