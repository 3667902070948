<div
  class="alert alert-warning alert-adjudication-unavailable clearfix"
  *ngIf="adjudicationEnabled && entity && entity.getData() && !entity.getData().singleClassificationModel">
  <div class="alert-icon">
    <span class="fa fa-exclamation-circle fa-2x"></span>
  </div>
  <p>
    Adjudication is no longer available for this profile. Please re-submit this profile to enable adjudication.
    <button
      type="button"
      *ngIf="supportEnabled"
      (click)="requestSupport()"
      name="btn-support"
      class="btn btn-primary btn-sm admin-btn right"
      title="Click to choose a profile action">
      Request Adjudication Support
    </button>
  </p>
</div>
