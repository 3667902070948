<div remember-position="true">
  <div class="monitor-hit-items-container" *ngFor="let hit of result.hits | slice:0:5">
    <ng-container [ngSwitch]="true">
      <ddiq-watch-list
        *ngSwitchCase="hit.type ==='company_watchlist' || hit.type === 'person_watchlist'"
        [watchList]="hit.data"
        [entityType]="result.entityType"
        [entityId]="result.oiqEntityId"
        [noReassess]="true"
        [isAdjudicateable]="result.isAdjudicatableByUser"
        [displayCreatedDate]="true"
        [isDisplayClassifications]="true"></ddiq-watch-list>
      <ddiq-regulatory-profile
        *ngSwitchCase="hit.type ==='regulatory_profile'"
        [profile]="hit.data"
        [entityType]="result.entityType"
        [entityId]="result.oiqEntityId"
        [noReassess]="true"
        [isAdjudicateable]="result.isAdjudicatableByUser"
        [displayCreatedDate]="true"
        [isDisplayClassifications]="true"></ddiq-regulatory-profile>
      <ddiq-website-scorecard
        *ngSwitchCase="hit.type ==='adverse_websitescorecard'"
        [scorecard]="hit.data"
        [entityType]="result.entityType"
        [entityId]="result.oiqEntityId"
        [noReassess]="true"
        [isAdjudicateable]="result.isAdjudicatableByUser"
        [displayCreatedDate]="true"
        [isDisplayClassifications]="true"></ddiq-website-scorecard>
      <ddiq-incident
        *ngSwitchDefault
        [incident]="hit.data"
        [classification]="hit.data.summary[0].labelKey"
        [entityType]="result.entityType"
        [entityId]="result.oiqEntityId"
        [noReassess]="true"
        [isAdjudicateable]="hit.data.adjudicable && result.isAdjudicatableByUser"
        [displayCreatedDate]="true"
        [isDisplayClassifications]="true"
        [showTranslatedSnippets]="showTranslatedSnippets"
        [showArticleCacheLinks]="showArticleCacheLinks">
      </ddiq-incident>
    </ng-container>
  </div>
  <button
    *ngIf="result.monitorHitTotal > 5"
    (click)="showDeltaReport(result.profileLink)"
    type="button"
    class="btn btn-primary btn-sm title-button no-print">
    +{{result.monitorHitTotal - 5}} more
  </button>
</div>
