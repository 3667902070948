//@ts-nocheck

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import HttpBufferService from '../../../../../shared/services/http/http-buffer.service';

@Injectable()
export default class NotFoundInterceptor implements HttpInterceptor {
  constructor(private httpBuffer: HttpBufferService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 404) {
          window.location.href = '#/404';
        }
        return throwError(error);
      })
    );
  }
}
