import { NgModule } from '@angular/core';
import WatchList from './ddiq-watch-list.component';
import WatchListHeader from './watch-list-header.component';
import WatchListDetail from './watch-list-detail.component';
import ImageInDbPipe from './image-in-db.pipe';
import WatchListDisplayDate from './watch-list-display-date.component';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { CommonModule } from '@angular/common';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { NgPipesModule } from 'ngx-pipes';
import OiqAdjudicationModule from '../adjudication/oiq-adjudication.module';
import OiqAssociationModule from '../association/oiq-association.module';
import WatchListGroup from './ddiq-watch-list-group.component';
import { OiqRiskModule } from '../risk/oiq-risk.module';
import OiqSourcesModule from '../sources/oiq-sources.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    DdiqUIModule,
    DdiqDirectivesModule,
    NgPipesModule,
    OiqAdjudicationModule,
    OiqAssociationModule,
    OiqRiskModule,
    OiqSourcesModule,
  ],
  declarations: [WatchListDisplayDate, ImageInDbPipe, WatchListHeader, WatchListDetail, WatchList, WatchListGroup],
  providers: [ImageInDbPipe],
  exports: [WatchList, WatchListGroup],
})
export class OiqWatchLists {}
