<section class="additional-item">
  <div *ngFor="let phoneNumber of phoneNumbers; let $index = index;">
    <button type="button" class="fa fa-trash trash" (click)="removePhoneNumber($index)"></button>
    <ddiq-text
      name="phone-numbers-number-{{$index}}"
      label="Phone Number"
      size="half"
      [(model)]="phoneNumber.number"></ddiq-text>
    <div class="input half">
      <label>Label</label>
      <ddiq-actions [prompt]="phoneNumber.label">
        <a *ngFor="let label of phoneLabels | arrayExclude: phoneNumber.label" (click)="phoneNumber.label = label;"
          >{{label}}</a
        >
      </ddiq-actions>
    </div>
    <br class="clr" />
  </div>
  <button type="button" class="btn btn-primary" (click)="addPhoneNumber()">Add Phone Number</button>
</section>
