import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable({
  providedIn: 'root',
})
export default class OwnershipData {
  constructor(private apiFetchService: ApiFetchService) {}

  applyOwnershipDataToCorporateRecords(entityID, corporateRecords = []) {
    const promises = [],
      corporateRecordsById = {};

    corporateRecords.forEach((record) => {
      if (record.ownershipDataNodesCount > 0) {
        corporateRecordsById[record.id] = record;
        promises.push(this.apiFetchService.getCorporateGraphData(entityID, record.id, 'ownership'));
      }
    });

    return Promise.all(promises).then((resolvedOwners) => {
      resolvedOwners.forEach((owner) => {
        corporateRecordsById[owner.id].ownershipData = owner.ownershipData;
      });
    });
  }
}
