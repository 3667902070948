import { NgModule } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import { EmbedDirective } from './embed.directive';
import { EmbedService } from './embed.service';

@NgModule({
  declarations: [EmbedDirective],
  providers: [EmbedService, WindowRefService],
  exports: [EmbedDirective],
})
export class EmbedModule {}
