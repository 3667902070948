import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IdSubmissionInputsComponent } from './id-submission-inputs.component';
import { ThemedEntityModule } from '../themed-entity/oiq-themed-entity.module';

@NgModule({
  imports: [CommonModule, FormsModule, ThemedEntityModule],
  declarations: [IdSubmissionInputsComponent],
  exports: [IdSubmissionInputsComponent],
})
export class IdSubmissionInputsModule {}
