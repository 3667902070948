import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Injectable({
  providedIn: 'root',
})
export default class AssociationBuckets {
  private associationBuckets: any = {
    high: 3,
    medium: 2,
    low: 1,
    unlikely: 0,
  };

  private readonly initialBucket: string;

  constructor(private oiqProperties: OiqProperties) {
    if (this.oiqProperties.unassociatedContentEnabled) {
      Object.keys(this.associationBuckets).forEach((bucket) => {
        this.associationBuckets[bucket]++;
      });

      this.associationBuckets.unassociated = 0;
    }

    Object.freeze(this.associationBuckets);

    this.initialBucket = this.oiqProperties.initialFilterThresholdLevel || 'low';
  }

  getBuckets() {
    return this.associationBuckets;
  }

  getInitialBucket() {
    return this.associationBuckets[this.initialBucket];
  }
}
