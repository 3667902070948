<div class="title">
  Confidence:
  <span class="association-slider-icons">
    <div *ngIf="level != 'high'">
      <ddiq-association-confidence [level]="level"></ddiq-association-confidence>
      <span class="inline fa fa-arrows-h fa-lg"></span>
    </div>
    <ddiq-association-confidence class="static-icon-high"></ddiq-association-confidence>
  </span>
</div>
<div class="slider-container"></div>
