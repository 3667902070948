import { ElementRef, Inject, Input, OnInit, Directive } from '@angular/core';
import $ from 'jquery';
import Theme from './theme.service';

@Directive({
  selector: '[data-theme-logo]',
})
export default class ThemeLogoDirective implements OnInit {
  @Input('data-theme-logo') themeLogo: string;

  constructor(private elementRef: ElementRef, private theme: Theme) {}

  ngOnInit() {
    const nativeElement = this.elementRef.nativeElement;

    let logoUrl = this.theme.getLogoUrl(this.themeLogo || 'small'),
      brandMsg = this.theme.isCustomerBrand() ? this.theme.getBrandMsg() : '';

    $(nativeElement).attr('src', logoUrl);

    if (brandMsg) {
      $(nativeElement).siblings('.brand-sub-text').text(brandMsg);
      $(nativeElement).parents('.brand').addClass('branded');
    }
  }
}
