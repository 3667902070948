import { Component, Input, OnInit } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-monitor-seed-data',
  templateUrl: 'ddiq-monitor-seed-data.component.tpl.html',
})
export default class MonitorSeedData implements OnInit {
  isShowingAll: boolean = false;
  displayShowAll: boolean = false;
  locationListLimit: number = 3;
  nameListLimit: number = 5;
  locations: any;
  currentAddress: { [key: string]: any };
  rawAddress: any;
  isMonitorRevamp: boolean = false;

  @Input() seedData: { [key: string]: any } = {};
  @Input() entityType: string;
  @Input() profileLink: string;
  @Input() submissionId: string;

  constructor(private oiqProperties: OiqProperties) {
    this.isMonitorRevamp = oiqProperties.isElasticSearchEnabled;
  }

  ngOnInit() {
    if (this.seedData) {
      this.locations = getLocationsFromSeedData(this.seedData);

      const names = getNamesFromSeedData(this.seedData);
      this.displayShowAll = this.locations.length > this.locationListLimit || names.length > this.nameListLimit;

      this.currentAddress = this.seedData.userEnteredAddress || this.seedData.location;

      if (this.currentAddress && this.currentAddress.rawAddress) {
        this.rawAddress = this.currentAddress.rawAddress;
      }
    }
  }

  toggleShowAll() {
    this.isShowingAll = !this.isShowingAll;
  }
}

function getLocationsFromSeedData(seedData) {
  const otherLocations = seedData.otherLocations || [];
  const previousAddresses = seedData.previousAddresses || [];
  let locations;

  if (otherLocations.length > 0) {
    locations = otherLocations;
  } else {
    locations = previousAddresses;
  }

  return locations.filter((location) => {
    return location.source !== 'MainAddressSubmission';
  });
}

function getNamesFromSeedData(seedData) {
  const alternativeNames = seedData.alternativeNames || [];
  const dbaNames = seedData.dbaNames || [];

  if (alternativeNames.length > 0) {
    return alternativeNames;
  }

  return dbaNames;
}
