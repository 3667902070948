import { NgModule } from '@angular/core';
import SupportContactPhoneNumberComponent from './support-contact-phone-number.component';
import SupportContactInfoComponent from './ddiq-support-contact-info.component';
import SupportContactInfoService from './support-contact-info.service';
import SupportContactEmailComponent from './support-contact-email.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [SupportContactInfoComponent, SupportContactEmailComponent, SupportContactPhoneNumberComponent],
  providers: [SupportContactInfoService],
  exports: [SupportContactInfoComponent, SupportContactEmailComponent],
})
export default class SupportContactInfoModule {}
