import { Component, OnInit, Input } from '@angular/core';
import './img/loader.gif';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.tpl.html',
})
export default class LoadingIndicator implements OnInit {
  @Input() message: string;

  messageTxt: string;

  ngOnInit() {
    const defaultTxt = 'Loading ...';
    this.messageTxt = this.message ? this.message : defaultTxt;
  }
}
