import { Injectable } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export default class SelectedArticlesDownloaderService {
  constructor(private urlGenerator: UrlGenerator, private windowRef: WindowRefService) {}

  download(entityType, entityId, selectedArticlesId) {
    let pdfUrl = this.urlGenerator.generateSelectedArticlePdf(entityType, entityId, selectedArticlesId);

    this.windowRef.nativeWindow.location.href = pdfUrl;
  }
}
