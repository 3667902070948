import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class StripNgProps {
  strip(json) {
    return JSON.stringify(json, function (key, value) {
      let val = value;
      if (key && key.charAt(0) === '$') {
        val = undefined;
      }
      return val;
    });
  }
}
