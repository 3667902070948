import moment from 'moment';
import { Injectable } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import AssociationBuckets from '../association/association-buckets.service';

@Injectable({
  providedIn: 'root',
})
export default class StructuredDataSorts {
  associationBuckets: AssociationBuckets;
  isAdjudicationEnabled: boolean;
  private readonly rankLhsLower: number = -1;
  private readonly rankSame: number = 0;
  private readonly rankRhsLower: number = 1;

  constructor(private oiqProperties: OiqProperties, private AssociationBuckets: AssociationBuckets) {
    this.associationBuckets = AssociationBuckets.getBuckets();
    this.isAdjudicationEnabled = oiqProperties.adjudicationEnabled || oiqProperties.autoAdjudicationEnabled;
  }

  sortByAdjudication(structuredData) {
    structuredData.sort((lhs, rhs) => {
      let rank = this.isAdjudicationEnabled ? this.rankByEscalated(lhs, rhs) : 0;

      if (rank === this.rankSame) {
        rank = this.rankByAssociationBucket(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankByDate(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankByLabel(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankBySeverity(lhs, rhs);
      }

      return rank;
    });
  }

  sortByAssociation(structuredData) {
    structuredData.sort((lhs, rhs) => {
      let rank = this.rankByAssociationBucket(lhs, rhs);

      if (rank === this.rankSame) {
        rank = this.rankByDate(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankByLabel(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankBySeverity(lhs, rhs);
      }

      return rank;
    });
  }

  sortByDate(structuredData) {
    structuredData.sort((lhs, rhs) => {
      let rank = this.rankByDate(lhs, rhs);

      if (rank === this.rankSame) {
        rank = this.rankByAssociationBucket(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankByLabel(lhs, rhs);
      }

      if (rank === this.rankSame) {
        rank = this.rankBySeverity(lhs, rhs);
      }

      return rank;
    });
  }

  private rankByEscalated(lhs, rhs) {
    const lhsEscalated = StructuredDataSorts.isAdjudicated(lhs, 'escalated'),
      rhsEscalated = StructuredDataSorts.isAdjudicated(rhs, 'escalated');

    if (lhsEscalated && !rhsEscalated) {
      return this.rankLhsLower;
    } else if (rhsEscalated && !lhsEscalated) {
      return this.rankRhsLower;
    } else {
      return this.rankByUserOrAutoAdjudicated(lhs, rhs);
    }
  }

  private rankByUserOrAutoAdjudicated(lhs, rhs) {
    const lhsAdjudicated = StructuredDataSorts.isAdjudicated(lhs, 'adjudicated'),
      rhsAdjudicated = StructuredDataSorts.isAdjudicated(rhs, 'adjudicated');

    if (lhsAdjudicated && !rhsAdjudicated) {
      return this.rankRhsLower;
    } else if (rhsAdjudicated && !lhsAdjudicated) {
      return this.rankLhsLower;
    } else {
      return this.rankSame;
    }
  }

  private static isAdjudicated(event, adjudicateType) {
    return event.adjudication && event.adjudication[adjudicateType];
  }

  private rankByAssociationBucket(lhs, rhs) {
    return this.associationBuckets[rhs.associationBucket] - this.associationBuckets[lhs.associationBucket];
  }

  private rankByDate(lhs, rhs) {
    let defaultDate = {
        year: 1970,
        month: 1,
        day: 1,
      },
      lhsDate,
      rhsDate,
      lhsMoment,
      rhsMoment;

    if (lhs.filterFields.date && typeof lhs.filterFields.date === 'string') {
      lhsMoment = moment(lhs.filterFields.date);
    } else {
      lhsDate = lhs.filterFields.date || defaultDate;
      lhsMoment = moment([lhsDate.year, lhsDate.month - 1 || 0, lhsDate.day || 1]);
    }
    if (rhs.filterFields.date && typeof rhs.filterFields.date === 'string') {
      rhsMoment = moment(rhs.filterFields.date);
    } else {
      rhsDate = rhs.filterFields.date || defaultDate;
      rhsMoment = moment([rhsDate.year, rhsDate.month - 1 || 0, rhsDate.day || 1]);
    }

    if (lhsMoment.isAfter(rhsMoment)) {
      return this.rankLhsLower;
    } else if (lhsMoment.isBefore(rhsMoment)) {
      return this.rankRhsLower;
    }

    return this.rankSame;
  }

  private rankByLabel(lhs, rhs) {
    const lhsLabel = (lhs.filterFields.label || '').toUpperCase(),
      rhsLabel = (rhs.filterFields.label || '').toUpperCase();

    if (lhsLabel === '' && rhsLabel !== '') {
      return this.rankRhsLower;
    }

    if (rhsLabel === '' && lhsLabel !== '') {
      return this.rankLhsLower;
    }

    if (lhsLabel > rhsLabel) {
      return this.rankRhsLower;
    } else if (lhsLabel < rhsLabel) {
      return this.rankLhsLower;
    }

    return this.rankSame;
  }

  private rankBySeverity(lhs, rhs) {
    const lhsSeverity = lhs.severity || 0,
      rhsSeverity = rhs.severity || 0;

    if (lhsSeverity > rhsSeverity) {
      return this.rankLhsLower;
    } else if (lhsSeverity < rhsSeverity) {
      return this.rankRhsLower;
    }

    return this.rankSame;
  }
}
