import { Pipe, PipeTransform } from '@angular/core';
import TranslateService from '../common-services/translate.service';

@Pipe({ name: 'translateGroupedRiskTitle' })
export default class TranslateGroupedRiskTitlePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(input: any): any {
    if ((input.key || '').indexOf('UNS_') === 0) {
      const toTranslate = input.key + '_GROUPED';
      return this.translateService.translate(toTranslate);
    }
    return input.translation;
  }
}
