import { Component, Input, OnInit } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-map',
  templateUrl: './ddiq-map.component.tpl.html',
})
export default class MapComponent implements OnInit {
  @Input() section;
  hasCoordinates;
  constructor(private oiqProperties: OiqProperties) {}

  ngOnInit() {
    this.hasCoordinates = this.oiqProperties.mappingEnabled && !!this.section.data.address.coordinates;
  }
}
