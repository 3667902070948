<div id="vicinity" *ngIf="!section.metadata?.filtered">
  <ddiq-accordion-top label="Map" open [disable]="!hasCoordinates">
    <div [ngSwitch]="hasCoordinates">
      <div *ngSwitchCase="true">
        <ddiq-simple-leaflet id="simpleMapId" [address]="section.data.address"></ddiq-simple-leaflet>
      </div>
      <div *ngSwitchDefault>
        <div>Map Data unavailable.</div>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
