import { Injectable } from '@angular/core';
import AntiCollideClassificationPipe from '../ddiq-filters/anti-collide-classification.pipe';

@Injectable({
  providedIn: 'root',
})
export default class IncidentByClassificationExtractionStrategy {
  constructor(private antiCollideClassification: AntiCollideClassificationPipe) {}

  extract(sections, entity, onSectionExtracted) {
    onSectionExtracted = onSectionExtracted || function noop() {};

    sections.forEach((section) => {
      const incidents = [],
        sectionClassifications = {};

      Object.keys(entity[section] || []).forEach((classification) => {
        const antiCollideClassification = this.antiCollideClassification.transform(classification);

        const extracted = entity[section][classification];

        extracted.forEach(function (incident) {
          (incident.summary || []).forEach(function (summary) {
            summary.classificationLabelKey = antiCollideClassification;
          });
          incident.classification = classification;
          incident.classificationLabelKey = antiCollideClassification;
        });

        sectionClassifications[antiCollideClassification] = extracted;

        Array.prototype.push.apply(incidents, extracted);
      });

      onSectionExtracted(section, incidents, sectionClassifications);
    });
  }
}
