import { Component, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import utils from '../../../exiger/utils';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import UserService from '../user/user.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import { ActivatedRoute, Params } from '@angular/router';
import AddressPipe from '../ddiq-filters/address.pipe';

@Component({
  selector: 'ddiq-integrity',
  templateUrl: './ddiq-integrity.component.tpl.html',
})
export default class IntegrityComponent implements OnInit {
  isLoading: boolean = false;
  forPrint: boolean = false;
  summary;
  entries;
  pdfUrl;
  private window: any;

  constructor(
    private ApiFetchService: ApiFetchService,
    public user: UserService,
    private urlGenerator: UrlGenerator,
    private route: ActivatedRoute,
    private address: AddressPipe,
    private windowRef: WindowRefService
  ) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit() {
    this.isLoading = true;
    this.loadIntegrityAndSummaryData();
  }

  private loadIntegrityAndSummaryData() {
    this.route.params.subscribe((params: Params) => {
      const entityType = params.entityType;
      const entityId = params.entityId;
      const isMonitor = (utils.getParameter('monitor', this.window.location.hash) || '').toLowerCase() === 'true';

      Promise.all([
        this.ApiFetchService.integrity(entityType, entityId, isMonitor),
        this.ApiFetchService.summary(entityType, entityId, false),
      ]).then((result) => {
        this.isLoading = false;

        this.entries = result[0].entries;
        this.summary = result[1];

        this.summary.fullName = this.getName(result[1], entityType);

        this.pdfUrl = this.urlGenerator.generateIntegrityPdf(entityType, entityId, isMonitor);

        setTimeout(() => {
          this.window.status = 'oiqLoadComplete';
        }, 3000);
      });
    });
  }
  getName(summary, entityType) {
    switch (entityType) {
      case 'property':
        return this.address.transform(summary.address);
      case 'person':
        return summary.name;
      case 'company':
        return summary.name;
    }
  }

  getIntegrityPdf() {
    this.window.location.href = this.pdfUrl;
  }

  unwrap(commaSeparated: string) {
    if (commaSeparated) {
      return commaSeparated.split(',');
    }
    return [];
  }
}
