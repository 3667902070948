import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import ApiFetchService from '../../oiq/common-services/api-fetch.service';
import { MonitorSearchModel } from './monitor-search.model';
import { MonitorPaginationModel } from './monitor-pagination.model';
import renameObjProp from '../../../../../shared/utils/rename-object-property';
import MonitorSearchService from './monitor-search.service';
import UserService from '../user/user.service';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';
import IncidentsService from '../themed-entity/incidents.service';
import StructuredData from '../themed-entity/structured-data.service';
import moment from 'moment';
import { MonitorFilterFieldsAPI } from './monitor';

@Component({
  selector: 'ddiq-monitor-hits-container',
  templateUrl: 'ddiq-monitor-hits-container.component.tpl.html',
})
export default class MonitorHitsContainer implements OnChanges, OnDestroy {
  reports: Array<{ [key: string]: any }> = [];
  isLoading: boolean = true;
  hasSearchResults: boolean = true;
  totalHitCount: number;
  pagination: MonitorPaginationModel = { current: 1, total: 0, visible: 7, orderBy: 'date', descending: true };
  isPaginationResetting: boolean = false;
  searchPayload: MonitorSearchModel;

  sortByApi: {
    name: string;
    owner: string;
    date: string;
  } = {
    name: 'nameKeyword',
    owner: 'oiqOwner',
    date: 'hit.oiqCreatedDate',
  };

  @Input() searchQuery: { q: string };

  constructor(
    private apiFetchService: ApiFetchService,
    private user: UserService,
    private urlGenerator: UrlGenerator,
    private incidents: IncidentsService,
    private structuredData: StructuredData,
    private monitorSearchService: MonitorSearchService
  ) {
    this.monitorSearchService.addSetPagination((currentPage, orderBy, descending) => {
      if (this.pagination.current !== currentPage) {
        this.isPaginationResetting = true;
        this.pagination.current = currentPage;
        this.pagination.orderBy = orderBy;
        this.pagination.descending = descending;
      }
    });
  }

  ngOnChanges(changes): void {
    this.search(changes.searchQuery.currentValue);
  }

  ngOnDestroy(): void {
    this.incidents.clear();
    this.structuredData.clear();
  }

  private search(query) {
    this.isLoading = true;
    this.reports.length = 0;

    this.searchPayload = this.createSearchPayload(query);

    this.apiFetchService
      .monitorSearch(this.searchPayload)
      .then((responseData): void => {
        if (responseData.size === 0 || !responseData?.results?.length) {
          this.hasSearchResults = false;
        } else {
          this.normalizeResponseData(responseData);
          this.hasSearchResults = true;
        }
        this.setPagination(responseData);
      })
      .finally(() => {
        this.isPaginationResetting = false;
        this.isLoading = false;
      });
  }

  createSearchPayload(data: { [key: string]: any } = {}): MonitorSearchModel {
    const search: MonitorSearchModel = {
      match: {
        'hit.adjudicationStatus': {
          notIn: ['ADJUDICATED'],
        },
      },
      sort: {
        field: 'hit.oiqCreatedDate',
        order: 'DESC',
      },
      start: 0,
      size: 10,
    };

    if (data.q) {
      search.match.name = { contains: data.q };
      search.match.nameKeyword = { wildcard: data.q };
    }

    if (data.id) {
      search.match.submissionId = { wildcard: data.id };
    }

    if (data.before || data.after) {
      search.match['hit.oiqCreatedDate'] = {
        from: this.clearDateOffset(data.after),
        to: this.clearDateOffset(data.before),
      };
    }

    if (data.govId) {
      search.match['identificationNumbers.number'] = { is: data.govId };
    }

    if (data.owner) {
      search.match.oiqOwner = { in: [data.owner] };
    }

    if (data.hOrder) {
      search.sort.field = this.sortByApi[data.hOrder];
      search.sort.order = data.hDesc ? 'DESC' : 'ASC';
    }

    if (Number.isInteger(data.page)) {
      search.start = data.page;
    }

    if (data.confirmed || data.adjudicated || data.escalated) {
      const adjudicationFilter = search.match['hit.adjudicationStatus'];
      adjudicationFilter.in = [];
      adjudicationFilter.notIn = [];

      this.setAdjudicationFilter(data, adjudicationFilter, 'CONFIRMED');
      this.setAdjudicationFilter(data, adjudicationFilter, 'ESCALATED');
      this.setAdjudicationFilter(data, adjudicationFilter, 'ADJUDICATED');

      if (adjudicationFilter.in.length === 0) {
        delete adjudicationFilter.in;
      }

      if (adjudicationFilter.notIn.length === 0) {
        delete adjudicationFilter.notIn;
      }

      if (!Object.keys(adjudicationFilter).length) {
        delete search.match['hit.adjudicationStatus'];
      }
    }

    if (data.events?.length || data.watchlistEvents?.length) {
      const events = ['adverse', 'legal', 'regulatory', 'regulatoryProfiles', 'noteworthy', 'websiteScorecards'];
      const watchlistEvents = ['watchLists'];
      const allEvents = [
        ...(data.events?.length ? events : []),
        ...(data.watchlistEvents?.length ? watchlistEvents : []),
      ];

      search.match['hit.section'] = { in: [...allEvents] };
      search.match['hit.category'] = { in: [...(data.events || []), ...(data.watchlistEvents || [])] };
    }

    if (data.idType?.length) {
      const idType: MonitorFilterFieldsAPI = 'ID Type';
      search.match[idType] = { in: data.idType };
    }

    if (data.regions?.length) {
      const region: MonitorFilterFieldsAPI = 'Region';
      search.match[region] = { in: data.regions };
    }

    if (data.clientAccount?.length) {
      const clientAccount: MonitorFilterFieldsAPI = 'Client Account';
      search.match[clientAccount] = { in: data.clientAccount };
    }

    return search;
  }

  private setAdjudicationFilter(
    data: { [p: string]: any },
    adjudicationFilter: { in?: Array<string>; notIn?: Array<string> },
    filterType: string
  ): void {
    if (!data[filterType.toLowerCase()]) {
      return;
    }

    if (data[filterType.toLowerCase()] === 'show') {
      adjudicationFilter.in.push(filterType);
    }

    if (data[filterType.toLowerCase()] === 'hide') {
      adjudicationFilter.notIn.push(filterType);
    }
  }

  normalizeResponseData(responseData): void {
    renameObjProp(responseData, 'results', 'reports');

    this.totalHitCount = responseData.size;

    responseData.reports.forEach((report) => {
      const reportUrl = this.urlGenerator.generateReport(report.oiqEntityId, report.entityType, report.oiqEntityId);

      report.oiqOwnerFullName = this.user.getVisibleUser(report.oiqOwner)?.fullName;
      report.profileLink = `${reportUrl}?monitor=true`;

      if (!MonitorHitsContainer.hasHits(report)) {
        this.reports.push(report);
        return;
      }

      this.incidents.init(report, false, 'incident');
      this.structuredData.init(report, false, 'incident');

      report.hits = this.monitorSearchService.interleaveAndSort(report);
      this.reports.push(report);
    });
  }

  onSelectPage(page): void {
    if (this.pagination.current === page) {
      return;
    }
    this.search({ ...this.searchQuery, page: (page - 1) * this.searchPayload.size });
    this.pagination.current = page;
  }

  private static hasHits(report) {
    return report.monitorHitTotal > 0;
  }

  clearDateOffset(date): string {
    return moment(date).utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(true);
  }

  private setPagination(responseData) {
    this.pagination.total = Math.ceil(responseData.size / this.searchPayload.size);
  }
}
