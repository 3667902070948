import { Pipe, PipeTransform } from '@angular/core';
import UrlGenerator from '../../../../../shared/services/common/url-generator.service';

@Pipe({
  name: 'cachedArticleHref',
})
export default class CachedArticleHrefPipe implements PipeTransform {
  constructor(private urlGenerator: UrlGenerator) {}

  transform(url, entityId?) {
    if (!hasUrl(url)) {
      return '';
    }

    if (shouldGenerateCachedArticleUrl(url, entityId)) {
      url = this.urlGenerator.generateCachedArticleUrl(url, entityId);
    }

    return this.urlGenerator.generateCachedArticleRouteUrl(url);
  }
}

function hasUrl(url) {
  return !!(url && typeof url === 'string' && url.trim());
}

function shouldGenerateCachedArticleUrl(url, entityId) {
  return hasUrl(url) && entityId;
}
