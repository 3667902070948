import { Component, Input, OnInit } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';
import OiqProperties from '../common-services/oiq-properties.service';

@Component({
  selector: 'ddiq-monitor-filters',
  templateUrl: './ddiq-monitor-filters.component.tpl.html',
})
export default class MonitorFilters implements OnInit {
  @Input() public query: { [key: string]: any } = {};
  events: Array<{ key: string; name: string }>;
  regions: Array<{ key: string; name: string }>;
  clientAccount: Array<{ key: string; name: string }>;
  watchlistEvents: Array<{ key: string; name: string }>;
  idTypes: Array<{ key: string; name: string }>;
  eventsQueryParams: string =
    'sections=regulatory&sections=adverse&sections=noteworthy&sections=legal&sections=websiteScorecards';
  watchlistEventsQueryParams: string = 'sections=watchLists';
  isIdTypeFilterEnabled: boolean;
  isRegionFilterEnabled: boolean;
  isClientAccountFilterEnabled: boolean;

  constructor(
    private apiFetchService: ApiFetchService,
    private translatef: TranslatefPipe,
    private oiqProperties: OiqProperties
  ) {
    this.isIdTypeFilterEnabled = oiqProperties.monitoringCustomFilters.includes('ID_TYPE');
    this.isRegionFilterEnabled = oiqProperties.monitoringCustomFilters.includes('REGION');
    this.isClientAccountFilterEnabled = oiqProperties.monitoringCustomFilters.includes('CLIENT_ACCOUNT');
  }

  ngOnInit(): void {
    //default filter state
    this.onAdjudicatedClick(false, true);
    this.onConfirmedClick(false, false);
    this.onEscalatedClick(false, false);

    Promise.all([
      this.apiFetchService.getMonitorEvents(this.eventsQueryParams),
      this.apiFetchService.getMonitorEvents(this.watchlistEventsQueryParams),
    ]).then((events) => {
      this.events = events[0].map((eventKey) => {
        return { key: eventKey, name: this.translatef.transform(eventKey) };
      });
      this.watchlistEvents = events[1].map((eventKey) => {
        return { key: eventKey, name: this.translatef.transform(eventKey) };
      });
    });

    if (this.isIdTypeFilterEnabled) {
      this.apiFetchService.getMonitorFilterFields('ID Type').then((idTypes) => {
        this.idTypes = idTypes.map((eventKey) => {
          return { key: eventKey, name: this.translatef.transform(eventKey) };
        });
      });
    }

    if (this.isRegionFilterEnabled) {
      this.apiFetchService.getMonitorFilterFields('Region').then((regions) => {
        this.regions = regions.map((eventKey) => {
          return { key: eventKey, name: this.translatef.transform(eventKey) };
        });
      });
    }

    if (this.isClientAccountFilterEnabled) {
      this.apiFetchService.getMonitorFilterFields('Client Account').then((clientAccount) => {
        this.clientAccount = clientAccount.map((eventKey) => {
          return { key: eventKey, name: this.translatef.transform(eventKey) };
        });
      });
    }
  }

  onConfirmedClick(isShow, isHide): void {
    this.setFilterState(isShow, isHide, 'confirmed');
    this.disable(isShow, isHide, ['escalated', 'adjudicated']);
  }

  onEscalatedClick(isShow, isHide): void {
    this.setFilterState(isShow, isHide, 'escalated');
    this.disable(isShow, isHide, ['adjudicated', 'confirmed']);
  }

  onAdjudicatedClick(isShow, isHide): void {
    this.setFilterState(isShow, isHide, 'adjudicated');
    this.disable(isShow, isHide, ['escalated', 'confirmed']);
  }

  private disable(isShow: boolean, isHide: boolean, disabled: Array<string>): void {
    if (isShow) {
      if (this.query[disabled[0]] === 'hide') {
        this.setFilterState(false, false, disabled[0]);
      }
      if (this.query[disabled[1]] === 'hide') {
        this.setFilterState(false, false, disabled[1]);
      }
    }

    if (isHide) {
      if (this.query[disabled[0]] === 'show') {
        this.setFilterState(false, false, disabled[0]);
      }
      if (this.query[disabled[1]] === 'show') {
        this.setFilterState(false, false, disabled[1]);
      }
    }
  }

  private setFilterState(isShow, isHide, filterType): void {
    if (isShow && !isHide) {
      this.toggle('show', filterType);
    } else if (!isShow && isHide) {
      this.toggle('hide', filterType);
    } else if (!isShow && !isHide) {
      this.toggle('any', filterType);
    }
  }

  toggle(filterState, filterType): void {
    this.query[filterType] = filterState;
  }
}
