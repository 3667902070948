import { Injectable } from '@angular/core';
import UserService from './user.service';
import ApiFetchService from '../common-services/api-fetch.service';

@Injectable({
  providedIn: 'root',
})
export default class LogoutService {
  constructor(private apiFetchService: ApiFetchService, private user: UserService) {}

  logout() {
    this.user.clear();
    return this.apiFetchService.logout();
  }
}
