import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import ExpandAllEventGroupsFeatureService from './expand-all-event-groups-feature.service';

@Component({
  selector: 'ddiq-expand-all-event-groups-toggle',
  templateUrl: './ddiq-expand-all-event-groups-toggle.component.tpl.html',
})
class ExpandAllEventGroupsToggleComponent implements OnInit, OnChanges {
  @Input() displayOpen;
  @Output() toggled = new EventEmitter();

  isEnabled: boolean;
  isOpen: boolean;

  constructor(private expandAllEventGroupsFeatureService: ExpandAllEventGroupsFeatureService) {}

  ngOnInit() {
    this.isEnabled = this.expandAllEventGroupsFeatureService.isEnabled();
    this.isOpen = this.displayOpen;
  }

  toggleClicked() {
    this.isOpen = !this.isOpen;
    this.toggled.emit({ isOpen: this.isOpen });
  }

  ngOnChanges(changes) {
    if (
      changes.displayOpen &&
      typeof changes.displayOpen.currentValue !== 'undefined' &&
      changes.displayOpen.currentValue !== changes.displayOpen.previousValue
    ) {
      this.isOpen = !!changes.displayOpen.currentValue;
    }
  }
}

export default ExpandAllEventGroupsToggleComponent;
