<div class="filter">
  <div class="filter-group" *ngIf="isConfirmEnabled">
    <div>
      <label class="filter-sm disposition-filter-toggle" [ngClass]="{ 'disabled': toggles.showConfirmedOnly.disabled }">
        <input
          type="checkbox"
          name="showConfirmedOnly"
          [(ngModel)]="toggles.showConfirmedOnly.on"
          [disabled]="toggles.showConfirmedOnly.disabled"
          (change)="toggle('showConfirmedOnly')" />
        Show Confirmed Content Only
      </label>
    </div>
    <div>
      <label class="filter-sm disposition-filter-toggle" [ngClass]="{ 'disabled': toggles.hideConfirmed.disabled }">
        <input
          type="checkbox"
          name="hideConfirmed"
          [(ngModel)]="toggles.hideConfirmed.on"
          [disabled]="toggles.hideConfirmed.disabled"
          (change)="toggle('hideConfirmed')" />
        Hide Confirmed Content
      </label>
    </div>
  </div>
  <div class="filter-group" *ngIf="isStarActionEnabled">
    <div>
      <label class="filter-sm disposition-filter-toggle" [ngClass]="{ 'disabled': toggles.showStarredOnly.disabled }">
        <input
          type="checkbox"
          name="showStarredOnly"
          [(ngModel)]="toggles.showStarredOnly.on"
          [disabled]="toggles.showStarredOnly.disabled"
          (change)="toggle('showStarredOnly')" />
        Show Starred Content Only
      </label>
    </div>
    <div>
      <label class="filter-sm disposition-filter-toggle" [ngClass]="{ 'disabled': toggles.hideStarred.disabled }">
        <input
          type="checkbox"
          name="hideStarred"
          [(ngModel)]="toggles.hideStarred.on"
          [disabled]="toggles.hideStarred.disabled"
          (change)="toggle('hideStarred')" />
        Hide Starred Content
      </label>
    </div>
  </div>
  <div class="filter-group">
    <div>
      <label
        class="filter-sm disposition-filter-toggle"
        [ngClass]="{ 'disabled': toggles.showAdjudicatedOnly.disabled }">
        <input
          type="checkbox"
          name="showAdjudicatedOnly"
          [(ngModel)]="toggles.showAdjudicatedOnly.on"
          [disabled]="toggles.showAdjudicatedOnly.disabled"
          (change)="toggle('showAdjudicatedOnly')" />
        Show Adjudicated Content Only
      </label>
    </div>
    <div>
      <label class="filter-sm disposition-filter-toggle" [ngClass]="{ 'disabled': toggles.hideAdjudicated.disabled }">
        <input
          type="checkbox"
          name="hideAdjudicated"
          [(ngModel)]="toggles.hideAdjudicated.on"
          [disabled]="toggles.hideAdjudicated.disabled"
          (change)="toggle('hideAdjudicated')" />
        Hide Adjudicated Content
      </label>
    </div>
  </div>
</div>
