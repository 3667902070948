import { Injectable } from '@angular/core';
import ApiFetchService from '../common-services/api-fetch.service';
import UserService from '../user/user.service';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export default class ConfigurationResolverService implements Resolve<any> {
  constructor(private apiFetchService: ApiFetchService, private user: UserService) {}

  resolve(): Promise<any> {
    return Promise.all([this.preLoadConfigurationData()]);
  }

  preLoadConfigurationData() {
    if (this.user.canViewConfiguration()) {
      return this.apiFetchService.configuration().then(function (config) {
        return config;
      });
    }

    return Promise.resolve();
  }
}
