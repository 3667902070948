import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSubmitted',
})
export default class IsSubmittedPipe implements PipeTransform {
  constructor() {}

  /**
   * Returns TRUE if an item of seedSources has SUBMISSION as the seedSource
   * @param entity
   * {
   *    seedSources: [{
   *      seedSource: STRING
   *    }]
   * }
   * @returns {Array}
   */
  transform(entity: any) {
    return (entity.seedSources || []).some((seedSource) => {
      return seedSource === 'SUBMISSION';
    });
  }
}
