import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import PaginationComponent from './components/pagination/pagination.component';
import DdiqProfileLinkComponent from './components/ddiq-profile-link.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import AuthService from './services/common/auth.service';
import MessageBusService from './services/common/message-bus.service';
import ApiFetchService from './services/http/api-fetch.service';
import HttpBufferService from './services/http/http-buffer.service';
import UrlGenerator from './services/common/url-generator.service';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [OrderByPipe, PaginationComponent, DdiqProfileLinkComponent],
  providers: [ApiFetchService, HttpBufferService, MessageBusService, AuthService, UrlGenerator],
  exports: [OrderByPipe, PaginationComponent, DdiqProfileLinkComponent],
})
export class SharedModule {}
