//@ts-nocheck

var global = window;

global.Exiger = global.Exiger || {};
global.Exiger.Utils = global.Exiger.Utils || {};

global.Exiger.Utils.subStringAfter = subStringAfter;
global.Exiger.Utils.getParameter = getParameter;
global.Exiger.Utils.compact = compact;
global.Exiger.Utils.isPDF = isPDF;

function subStringAfter(str, substr) {
  return str.substring(str.indexOf(substr) + substr.length);
}

// searchObj works if it is a location or querystring
function getParameter(name, searchObj) {
  var regex, results;
  //eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  results = regex.exec(searchObj);

  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function isPDF() {
  var href = global.location.href;
  return href.indexOf('print') > -1 || href.indexOf('cover') > -1;
}

function isObject(obj) {
  return obj === Object(obj);
}

/**
 * JSON object compacting function.
 * - Removes all keys with empty or null values
 * - Removes all empty or null array elements
 * - Recursively removes empty objects and arrays
 */
function compact(obj) {
  var i,
    cleaned,
    newArray = [],
    newObj = {};

  if (Array.isArray(obj)) {
    for (i in obj) {
      cleaned = compact(obj[i]);
      if (cleaned !== null) {
        newArray.push(cleaned);
      }
    }

    if (newArray.length !== 0) {
      return newArray;
    } else {
      return null;
    }
  } else if (isObject(obj)) {
    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    for (i in obj) {
      cleaned = compact(obj[i]);
      if (cleaned !== null) {
        newObj[i] = cleaned;
      }
    }

    if (Object.keys(newObj).length !== 0) {
      return newObj;
    } else {
      return null;
    }
  } else {
    // it's a primitive
    if (obj !== false && !obj) {
      return null;
    } else {
      return obj;
    }
  }
}

var utils = global.Exiger.Utils;

export default utils;
