import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-sortable-column',
  templateUrl: 'ddiq-sortable-column.component.tpl.html',
})
export default class SortableColumn {
  @Input() sort: any;
  @Input() column: string;

  isAscending() {
    return this.column === this.sort.orderBy && !this.sort.descending;
  }

  isDescending() {
    return this.column === this.sort.orderBy && this.sort.descending;
  }
}
