<div id="regulatory" *ngIf="!section.metadata.filtered" #entityAware="oiqEntityAware" oiq-entity-aware>
  <ddiq-accordion-top
    label="Regulatory, Compliance and Government"
    [toggleScope]="section.metadata"
    toggle-function="toggleSection"
    [open]="!previewingEntity || regulatoryHasContent()"
    [disable]="!regulatoryHasContent()"
    [alerts]="alertCount"
    [filteredCount]="contentCounter.filtered()"
    [count]="contentCounter.unfiltered()"
    [filteredEscalatedCount]="contentCounter.filteredEscalated()"
    [escalatedCount]="contentCounter.unfilteredEscalated()">
    <ddiq-alert-list [source]="regulatoryAlerts"></ddiq-alert-list>

    <div
      *ngFor="let subsection of getSubSections(section.metadata.sectionId) | filterRegulatorySubSection: showOshaSafety :showGovProcurement"
      class="sub-section">
      <div [ngSwitch]="subsection">
        <div id="company_watchlists" *ngSwitchCase="'watchlists'">
          <strong class="push-down">Global Compliance / Sanctions / PEP Lists</strong>
          <ddiq-watch-list-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.watchListsEventGroups"
            (sortDiscoveries)="sortDiscoveries('watchLists', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-watch-list-group>
        </div>

        <div id="company_otherRegulatory" *ngSwitchCase="'incidents'">
          <strong class="push-down">Other Regulatory Information</strong>
          <ddiq-incidents-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.contentEventGroups"
            (sortDiscoveries)="sortDiscoveries('content', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-incidents-group>
        </div>

        <div id="company_regulatoryProfiles" *ngSwitchCase="'regulatoryProfiles'">
          <strong class="push-down">Regulatory Databases</strong>
          <ddiq-regulatory-profiles-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.regulatoryProfilesEventGroups"
            (sortDiscoveries)="sortDiscoveries('regulatoryProfiles', $event.sortName, $event.eventGroup)"
            [isDisplayCreatedDate]="isDeltaReport"
            [expandAll]="isExpandAllGroups">
          </ddiq-regulatory-profiles-group>
        </div>

        <div id="company_governmentProcurement" *ngSwitchCase="'governmentProcurement'">
          <strong class="push-down">Government Procurement</strong>
          <p>
            <oiq-government-procurement-link
              *ngIf="hasFoundGovernmentAwards"
              [entityId]="entityId"></oiq-government-procurement-link>
            <span *ngIf="!hasFoundGovernmentAwards">No Awards Found</span>
          </p>
        </div>

        <div id="regulatory_osha" *ngSwitchCase="'oshaSafetyInspections'">
          <strong class="push-down">OSHA Safety Inspections</strong>
          <ddiq-osha-inspections-group
            [idPrefix]="section.metadata.sectionId"
            [groups]="section.data.oshaSafetyInspectionsEventGroups"
            (sortDiscoveries)="sortDiscoveries('oshaSafetyInspections', $event.sortName, $event.eventGroup)"
            [entityType]="entityType"
            [expandAll]="isExpandAllGroups">
          </ddiq-osha-inspections-group>
        </div>
      </div>
    </div>
  </ddiq-accordion-top>
</div>
