import DdiqFilteredMessages from './ddiq-filtered-messages.component';
import DdiqDispositionFilterToggles from './disposition-filter-toggles.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import OiqAssociationModule from '../association/oiq-association.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, OiqAssociationModule, PipesModule, NgPipesModule],
  declarations: [DdiqFilteredMessages, DdiqDispositionFilterToggles],
  exports: [DdiqFilteredMessages, DdiqDispositionFilterToggles],
})
export class ProfileFiltersModule {}
