import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import ArrayDedupePipe from './array-dedupe.pipe';
import $ from 'jquery';
import Filter from '../themed-entity/filter.service';

@Directive({
  selector: '[exg-scroll-adjust]',
})
export default class ExgScrollAdjustDirective implements OnInit, OnDestroy {
  @Input() selector;
  @Input() sections;
  @Input() scrollOffset;
  anchorSection;
  beforeFilterAnchor;
  beforeFilterViewport;
  nextElements;
  nextSection;
  panels;
  sectionsInView;
  sectionsInReport;
  adjust = true;

  constructor(private filter: Filter, private arrayDedupePipe: ArrayDedupePipe) {}

  ngOnInit(): void {
    this.filter.registerBeforeFilterObserver(this.beforeFilter);
    this.filter.registerBeforeFilterObserver(this.afterFilter);
  }

  ngOnDestroy() {
    this.filter.unRegisterFilterObservers(this.beforeFilter, this.afterFilter);
  }

  beforeFilter = () => {
    this.beforeFilterViewport = this.setViewport($(window).scrollTop(), $(window).height(), this.scrollOffset);

    this.sectionsInReport = this.getSectionsInReport(this.getSectionNames(this.sections || []));

    if (this.sectionsInReport.length > 0) {
      this.sectionsInView = this.getSectionInView(this.sectionsInReport, this.beforeFilterViewport);

      if (this.sectionsInView.length > 0) {
        this.anchorSection = this.getNearestToTop(this.beforeFilterViewport.top, this.sectionsInView, undefined);
        this.panels = this.getPanels(this.anchorSection.element, this.selector) || [];
        this.nextSection = this.getNextSection(this.sectionsInReport, this.anchorSection);

        if (this.anchorSection === this.sectionsInReport[0] && !this.anchorSection.filtered) {
          this.adjust = false;
        } else if (this.panels.length > 0) {
          this.beforeFilterAnchor = this.getNearestToTop(
            this.beforeFilterViewport.top,
            this.panels,
            this.sectionsInView
          );
          this.nextElements = this.getNext(
            this.beforeFilterAnchor,
            this.anchorSection.element,
            this.panels,
            this.selector
          );
        } else {
          this.beforeFilterAnchor = this.anchorSection.element;
        }
      }
    }
  };

  afterFilter = () => {
    let scrollTo, afterFilterAnchor, anchorTop, elementOffset;

    if (this.adjust) {
      setTimeout(() => {
        if (typeof this.anchorSection !== 'undefined' && this.anchorSection.filtered) {
          afterFilterAnchor = this.nextSection.element;
        } else if (typeof this.beforeFilterAnchor !== 'undefined') {
          afterFilterAnchor = this.beforeFilterAnchor;
        }

        elementOffset = this.isValidDomElem(afterFilterAnchor) ? $(afterFilterAnchor).offset() : { top: 0, left: 0 };

        if (typeof elementOffset !== 'undefined' && elementOffset.top !== 0) {
          anchorTop = elementOffset.top;
        } else {
          anchorTop = this.getAvailable(this.nextElements);
        }

        scrollTo = Math.max(anchorTop - this.scrollOffset, 0);

        $('html, body').stop().animate(
          {
            scrollTop: scrollTo,
          },
          '200'
        );

        afterFilterAnchor = undefined;
      }, 1);
    }
    this.adjust = true;
  };

  getSectionId(section) {
    return '#' + section.metadata.sectionId;
  }

  getSectionNames(sections) {
    return sections.filter((section) => {
      return section.metadata.showInSectionNav !== false;
    });
  }

  getSectionInView(reportSections, Viewport) {
    return reportSections.filter((section) => {
      return section.element[0] ? this.isInView(section.element, Viewport) : false;
    });
  }

  getNextSection(reportSections, anchorSection) {
    let sectionIndex = reportSections.indexOf(anchorSection),
      nextSectionIndex = sectionIndex + 1,
      prevSectionIndex = sectionIndex - 1;

    while (reportSections[nextSectionIndex] && reportSections[nextSectionIndex].filtered) {
      nextSectionIndex++;
    }
    while (reportSections[prevSectionIndex] && reportSections[prevSectionIndex].filtered) {
      prevSectionIndex--;
    }

    if (typeof reportSections[nextSectionIndex] !== 'undefined') {
      return reportSections[nextSectionIndex];
    } else if (typeof reportSections[prevSectionIndex] !== 'undefined') {
      return reportSections[prevSectionIndex];
    } else {
      return anchorSection;
    }
  }

  getNext(anchorPanel, anchorSection, panels, selector) {
    let elementsQueue,
      nextPanels,
      prevPanels,
      afterFilterAnchor = $(anchorPanel),
      afterFilterNext = afterFilterAnchor.nextAll(selector).toArray(),
      afterFilterPrev = afterFilterAnchor.prevAll(selector).toArray(),
      beforeFilterPanels = panels.toArray(),
      beforeFilterIndex = beforeFilterPanels.indexOf(anchorPanel);

    nextPanels = beforeFilterPanels.slice(beforeFilterIndex + 1).concat(afterFilterNext);

    prevPanels = beforeFilterPanels.slice(0, beforeFilterIndex).reverse().concat(afterFilterPrev);

    elementsQueue = nextPanels.concat(prevPanels);
    elementsQueue.push(anchorSection);
    elementsQueue = this.arrayDedupePipe.transform(elementsQueue);

    return elementsQueue;
  }

  getAvailable(elements) {
    let foundElement;
    (elements || []).some((element) => {
      let elementOffset = this.isValidDomElem(element) ? $(element).offset() : { top: 0, left: 0 };
      if (typeof elementOffset !== 'undefined' && elementOffset.top !== 0) {
        foundElement = elementOffset.top;
        return true;
      }
    });

    return foundElement || 0;
  }

  getPanels(reportSection, selector) {
    return $(reportSection).find(selector);
  }

  getSectionsInReport(reportSections) {
    return reportSections.map((section) => {
      return {
        element: $(this.getSectionId(section)),
        filtered: section.metadata.filtered,
      };
    });
  }

  isInView(reportElement, Viewport) {
    let elemTop = $(reportElement).offset().top,
      elemBottom = elemTop + $(reportElement).outerHeight();

    return elemBottom > Viewport.top && elemTop < Viewport.bottom;
  }

  setViewport(windowScrollTop, windowHeight, scrollOffset) {
    let top = windowScrollTop + scrollOffset;
    let bottom = top + windowHeight - scrollOffset;
    return {
      top: top,
      bottom: bottom,
    };
  }

  getNearestToTop(viewportTop, panelElements, sectionsInView) {
    let anchorScrollY, elementPositions, reportElements;

    reportElements = panelElements.slice();

    if (typeof sectionsInView !== 'undefined') {
      sectionsInView.forEach((section) => {
        reportElements.push(section.element);
      });
    }

    elementPositions = $(reportElements)
      .map(function () {
        let top = $(this.element || this).offset().top;
        return [top, top + $(this.element || []).height()];
      })
      .toArray();
    anchorScrollY = elementPositions.reduce((prev, curr) => {
      return Math.abs(curr - viewportTop) < Math.abs(prev - viewportTop) ? curr : prev;
    });

    return reportElements[Math.floor(elementPositions.indexOf(anchorScrollY) / 2)];
  }

  isValidDomElem(elemObj) {
    let docElem,
      elem = (elemObj || []).length ? elemObj[0] : elemObj;

    if (!elem || !elem.nodeType) {
      return false;
    }

    docElem = (elem.ownerDocument || window.document).documentElement;
    if (!$.contains(docElem, elem)) {
      return false;
    }

    return true;
  }
}
