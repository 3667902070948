import { Component, Input, OnInit } from '@angular/core';
import AdjudicationFeatureService from '../adjudication/adjudication-feature.service';

@Component({
  selector: 'oiq-disposition-filter-toggles',
  templateUrl: './disposition-filter-toggles.component.tpl.html',
})
export default class DispositionFilterToggles implements OnInit {
  @Input() toggles;
  isConfirmEnabled;
  isStarActionEnabled;

  constructor(private adjudicationFeature: AdjudicationFeatureService) {}

  ngOnInit() {
    this.isConfirmEnabled = this.adjudicationFeature.isConfirmActionEnabled();
    this.isStarActionEnabled = this.adjudicationFeature.isStarActionEnabled();
  }

  toggle(srcToggleName) {
    const targetToggleConfig = this.toggles[srcToggleName];

    if (targetToggleConfig.toggled) {
      targetToggleConfig.toggled();
    }
  }
}
