import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ddiq-selected-articles',
  templateUrl: 'ddiq-selected-articles.component.tpl.html',
})
export default class SelectedArticlesComponent implements OnInit {
  selectedArticles;
  entityName;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.entityName = data.preLoad[0];
      this.selectedArticles = data.preLoad[1];
    });
  }
}
