import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddiq-product-recall',
  templateUrl: './ddiq-product-recall.component.tpl.html',
})
export default class DdiqProductRecallComponent {
  @Input() recall;
  @Input() isAdjudicateable;
}
