import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import DdiqDirectivesModule from '../ddiq-directives/ddiq-directives.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import MapComponent from './ddiq-map.component';
import ChainableStructuredDataFilter from './chainable-structured-data-filter.service';
import { DdiqUpgradeModule } from '../ddiq-upgrade/ddiq-upgrade.module';
import AlertSummarizer from './alert-summarizer.service';
import BuildValidationService from './build-validation.service';
import ChainableFilterDateUtils from './chainable-filter-date-utils.service';
import ChainableIncidentFilter from './chainable-incident-filter.service';
import CompanyBuildService from './company-build.service';
import ActionsComponent from './ddiq-actions.component';
import PasswordComponent from './ddiq-password.component';
import AccordionTopComponent from './ddiq-accordion-top.component';
import AccordionComponent from './ddiq-accordion.component';
import DdiqTextComponent from './ddiq-text.component';
import SeedDataService from './seed-data.service';
import NavSearchComponent from './ddiq-nav-search.component';
import FuzzyDateComponent from './ddiq-fuzzy-date.component';
import scrollFixed from './scroll-fixed.directive';
import Incidents from './incidents.service';
import AlertListComponent from './ddiq-alert-list.component';
import BreadcrumbComponent from './ddiq-breadcrumb.component';
import EducationFormComponent from './ddiq-education-form.component';
import EmailAddressFormComponent from './ddiq-email-address-form.component';
import DupComponent from './ddiq-dup.component';
import KnownAssociateFormComponent from './ddiq-known-associate-form.component';
import NavFilterComponent from './ddiq-nav-filter.component';
import PhoneNumberForm from './ddiq-phone-number-form.component';
import PreviousAddressForm from './ddiq-previous-address-form.component';
import ProfileNavComponent from './ddiq-profile-nav.component';
import RecentlyBuiltProfilesComponent from './ddiq-recently-built-profiles.component';
import SimpleLeafletComponent from './ddiq-simple-leaflet.component';
import SortableColumn from './ddiq-sortable-column.component';
import ExtractSourcePipe from './extract-source.pipe';
import FilterCountComponent from './filter-count.component';
import Filter from './filter.service';
import IncidentsUtils from './incidents-utils.service';
import PersonBuildService from './person-build.service';
import RelatedEntityProcessor from './related-entity-processor.service';
import RelationshipFilter from './relationship-filter.service';
import { SharedModule } from '../../../../../shared/shared.module';
import { DdiqUIModule } from '../ui/oiq-ui.module';
import StructuredDataSorts from './structured-data-sorts.service';
import StructuredDataUtils from './structured-data-utils.service';
import StructuredData from './structured-data.service';
import { OiqRiskModule } from '../risk/oiq-risk.module';
import DdiqLocationFormComponent from './ddiq-location-form.component';
import DdiqPeopleFormComponent from './ddiq-people-form.component';
import AssociationSliderComponent from './ddiq-association-slider.component';
import OiqAssociationModule from '../association/oiq-association.module';
import { ProfileFiltersModule } from '../profile-filters/oiq-profile-filters.module';
import { FormsModule } from '@angular/forms';
import ErrorModalComponent from './ddiq-error-modal.component';
import { AlertsModule } from '../alerts/oiq-alerts.module';
import SelectDupComponent from './ddiq-select-dup.component';
import DdiqSelectComponent from './ddiq-select.component';
import DdiqNotifyComponent from './ddiq-notify.component';
import DdiqPdfComponent from './ddiq-pdf.component';
import DdiqAddressComponent from './ddiq-address.component';
import OiqSourcesModule from '../sources/oiq-sources.module';
import DdiqDateComponent from './ddiq-date.component';

import { DdiqTextSubmitComponent } from './ddiq-text-submit.component';
import { ExternalUrlMsgModule } from '../external-url-msg/ddiq-external-url-msg.module';
import { ExternalUrlMsgDirective } from '../external-url-msg/ddiq-external-url-msg.directive';
import { DdiqModalModule } from '../modal/ddiq-modal.module';
import DdiqModalComponent from '../modal/ddiq-modal.component';
import { ProfileHitIdService } from './profile-hit-id.service';
import { CorporateRecordHitIdService } from './corporate-record-hit-id.service';
import { RegulatoryHitIdService } from './regulatory-hit-id.service';
import { WatchListHitIdService } from './watch-list-hit-id.service';
import { IncidentsHitIdService } from './incidents-hit-id.service';

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    DdiqDirectivesModule,
    DdiqUpgradeModule,
    SharedModule,
    DdiqUIModule,
    NgPipesModule,
    OiqRiskModule,
    FormsModule,
    OiqAssociationModule,
    ProfileFiltersModule,
    AlertsModule,
    OiqSourcesModule,
    DdiqModalModule,
    ExternalUrlMsgModule,
  ],
  declarations: [
    ExtractSourcePipe,
    DdiqLocationFormComponent,
    DdiqPeopleFormComponent,
    DdiqNotifyComponent,
    DdiqPdfComponent,
    ExtractSourcePipe,
    DdiqLocationFormComponent,
    DdiqPeopleFormComponent,
    AssociationSliderComponent,
    NavFilterComponent,
    FilterCountComponent,
    SimpleLeafletComponent,
    MapComponent,
    NavSearchComponent,
    EducationFormComponent,
    EmailAddressFormComponent,
    KnownAssociateFormComponent,
    RecentlyBuiltProfilesComponent,
    PreviousAddressForm,
    PhoneNumberForm,
    ErrorModalComponent,
    ActionsComponent,
    AlertListComponent,
    PasswordComponent,
    ProfileNavComponent,
    scrollFixed,
    AccordionTopComponent,
    AccordionComponent,
    DdiqSelectComponent,
    DupComponent,
    DdiqTextComponent,
    FuzzyDateComponent,
    SelectDupComponent,
    SortableColumn,
    BreadcrumbComponent,
    DdiqAddressComponent,
    DdiqDateComponent,
    DdiqTextSubmitComponent,
  ],
  providers: [
    RelatedEntityProcessor,
    RelationshipFilter,
    StructuredDataSorts,
    StructuredDataUtils,
    IncidentsUtils,
    StructuredData,
    BuildValidationService,
    AlertSummarizer,
    Filter,
    ChainableFilterDateUtils,
    ChainableStructuredDataFilter,
    PersonBuildService,
    CompanyBuildService,
    Incidents,
    SeedDataService,
    ChainableIncidentFilter,
    ProfileHitIdService,
    CorporateRecordHitIdService,
    RegulatoryHitIdService,
    WatchListHitIdService,
    IncidentsHitIdService,
  ],
  exports: [
    ExtractSourcePipe,
    DdiqLocationFormComponent,
    DdiqPeopleFormComponent,
    DdiqPdfComponent,
    DdiqNotifyComponent,
    ExtractSourcePipe,
    DdiqLocationFormComponent,
    DdiqPeopleFormComponent,
    FilterCountComponent,
    SimpleLeafletComponent,
    MapComponent,
    NavSearchComponent,
    EducationFormComponent,
    EmailAddressFormComponent,
    KnownAssociateFormComponent,
    RecentlyBuiltProfilesComponent,
    PreviousAddressForm,
    PhoneNumberForm,
    ErrorModalComponent,
    AlertListComponent,
    PasswordComponent,
    ProfileNavComponent,
    scrollFixed,
    AccordionTopComponent,
    AccordionComponent,
    DdiqSelectComponent,
    DupComponent,
    DdiqTextComponent,
    FuzzyDateComponent,
    SelectDupComponent,
    SortableColumn,
    BreadcrumbComponent,
    DdiqAddressComponent,
    DdiqDateComponent,
    DdiqTextSubmitComponent,
    DdiqModalComponent,
    ExternalUrlMsgDirective,
  ],
})
export class ThemedEntityModule {}
