import { Component, OnInit, OnDestroy } from '@angular/core';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import TranslatefPipe from '../ddiq-filters/translatef.pipe';

@Component({
  selector: 'ddiq-footer',
  templateUrl: './ddiq-footer.component.html',
})
export default class DdiqFooterComponent implements OnInit, OnDestroy {
  private deregisterUpdateListener;
  private readonly homePageTranslationKey = 'BRAND_HOME_PAGE_TXT';
  brandHomePageText: string;
  includePageLinks: boolean = true;

  constructor(private messageBusService: MessageBusService, private translatePipe: TranslatefPipe) {}

  ngOnInit() {
    this.deregisterUpdateListener = this.messageBusService.on('translationUpdate', () => {
      this.brandHomePageText = this.translatePipe.transform(this.homePageTranslationKey);
    });
    this.brandHomePageText = this.translatePipe.transform(this.homePageTranslationKey);
  }

  ngOnDestroy(): void {
    this.deregisterUpdateListener.unsubscribe();
  }
}
