<div
  class="oiq-inmate-profile"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{escalated: profile.escalated, confirmed: profile.confirmed}">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="profile"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-inmate-profile-summary oiq-grid-cell">
          <div class="oiq-inmate-profile-cell" role="gridcell">
            <ddiq-association class="oiq-inmate-profile-association" [level]="profile.associationBucket">
            </ddiq-association>

            <ddiq-adjudication-actions
              [type]="'INMATE_PROFILE'"
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [entityType]="entityAware.entityType"
              [entityId]="entityAware.entityId"
              [noReassess]="entityAware.noReassess"
              [targetId]="profile.id"
              [model]="profile">
            </ddiq-adjudication-actions>
          </div>
        </div>
        <div class="oiq-inmate-profile-bd oiq-grid-cell">
          <article class="oiq-inmate-profile-grid" [ngClass]="{disabled: profile.disabled}">
            <div class="oiq-inmate-profile-row" *ngIf="profile.displayName" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Name:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell" [textContent]="profile.displayName"></div>
            </div>

            <div class="oiq-inmate-profile-row" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Inmate ID:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell">
                <a
                  *ngIf="profile.profileUrl"
                  href="{{profile.profileUrl}}"
                  target="_blank"
                  class="inline"
                  rel="noopener noreferrer"
                  [disabled]="profile.disabled"
                  [textContent]="profile.inmateId"></a>

                <span *ngIf="!profile.profileUrl" [textContent]="profile.inmateId"></span>
              </div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.birthdate" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Date of Birth:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell" [textContent]="profile.birthdate | dateFormat"></div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.age && !profile.birthdate" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Age:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell" [textContent]="profile.age"></div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.jurisdiction" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Jurisdiction:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell" [textContent]="profile.jurisdiction"></div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.admissionDate" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Admission Date:</div>
              <div
                class="oiq-inmate-profile-cell"
                role="gridcell"
                [textContent]="profile.admissionDate | dateFormat: 'Unknown'"></div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.releaseDate" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Release Date:</div>
              <div
                class="oiq-inmate-profile-cell"
                role="gridcell"
                [textContent]="profile.releaseDate | dateFormat"></div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.knownAliases.length" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Known Aliases:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell">
                <ul>
                  <li *ngFor="let alias of profile.knownAliases" [textContent]="alias"></li>
                </ul>
              </div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.incarcerationStatus" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Status:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell" [textContent]="profile.incarcerationStatus"></div>
            </div>

            <div class="oiq-inmate-profile-row" *ngIf="profile.comments" role="row">
              <div class="oiq-inmate-profile-hd" role="columnheader">Comments:</div>
              <div class="oiq-inmate-profile-cell" role="gridcell" [textContent]="profile.comments"></div>
            </div>
          </article>
          <ddiq-adjudication-notes
            class="adjudication-notes"
            [type]="'INMATE_PROFILE'"
            [entityType]="entityAware.entityType"
            [entityId]="entityAware.entityId"
            [noReassess]="entityAware.noReassess"
            [targetId]="profile.id"
            [model]="profile"
            [label]="'Notes'"
            [active]="profile.comment">
          </ddiq-adjudication-notes>
        </div>
      </div>
    </div>
  </div>
</div>
