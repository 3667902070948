import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// h stands for hits, r = report, c = closed
// hOrder=owner
// hOrder=date
// hOrder=name
// hOrder=name&hDesc

@Component({
  selector: 'ddiq-monitor-sort-by',
  templateUrl: 'ddiq-monitor-sort-by.component.tpl.html',
})
export default class MonitorSortBy implements OnInit {
  @Output() public initSort = new EventEmitter<any>();
  @Input() public query: { [key: string]: any } = {};

  labelText = {
    name: 'Name',
    owner: 'Owner',
    date: 'Latest Match',
  };

  sortBy: { field: string; order: string } = { field: '', order: '' };

  ngOnInit() {
    this.sortBy.field = this.query.hOrder;
    this.sortBy.order = MonitorSortBy.getOrder(this.query);
  }

  sortByName(query) {
    this.sortBy.field = 'name';
    this.sort(query);
  }

  sortByOwner(query) {
    this.sortBy.field = 'owner';
    this.sort(query);
  }

  sortByDate(query) {
    this.sortBy.field = 'date';
    this.sort(query);
  }

  private sort(query) {
    query.hOrder = this.sortBy.field;
    this.initSort.emit({ query });
  }

  toggleAscDesc(): void {
    this.query.hDesc = !this.query.hDesc;
    this.sortBy.order = MonitorSortBy.getOrder(this.query);
    this.sort(this.query);
  }

  private static getOrder(query) {
    return query.hDesc ? 'desc' : 'asc';
  }
}
