import { Input, Output, Component, OnInit, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'ddiq-password',
  templateUrl: './ddiq-password.component.tpl.html',
  viewProviders: [
    { provide: ControlContainer, useFactory: (ngForm: NgForm) => ngForm, deps: [[new Optional(), NgForm]] },
  ],
})
export default class PasswordComponent implements OnInit {
  @Input() model: any;
  @Input() label: string;
  @Input() pattern: any;
  @Input() oiqTextDisabled: boolean;
  @Input() required: any;
  @Input() size: string;
  @Output() modelChange = new EventEmitter<string>();
  @Input() name: string;
  @Output() passwordChanged = new EventEmitter<string>();
  isRequired;

  constructor() {}

  ngOnInit() {
    this.isRequired = typeof this.required !== 'undefined';
    if (!this.pattern) {
      this.pattern = /^.*$/;
    }
  }

  onInputChange(value: string) {
    this.modelChange.emit(value);
    this.passwordChanged.emit(value);
  }
}
