import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../../../../../shared/services/common/window-ref.service';
import OiqProperties from '../common-services/oiq-properties.service';
import LogoutService from '../user/logout.service';

@Component({
  selector: 'ddiq-logout-link',
  templateUrl: './ddiq-logout-link.component.tpl.html',
})
export default class LogoutLinkComponent implements OnInit {
  isSsoEnabled;
  isSloEnabled;

  constructor(
    private logoutService: LogoutService,
    private oiqProperties: OiqProperties,
    private windowRef: WindowRefService
  ) {}

  ngOnInit() {
    this.oiqProperties.apiPropertiesPromise.then(() => {
      this.isSsoEnabled = this.oiqProperties.isSsoEnabled;
      this.isSloEnabled = this.oiqProperties.isSloEnabled;
    });
  }

  logout() {
    this.logoutService
      .logout()
      .then((redirectToUrl) => {
        if (this.isSloEnabled && redirectToUrl) {
          this.windowRef.nativeWindow.location.href = redirectToUrl;
        }
      })
      .finally(() => {
        if (!this.isSsoEnabled || !this.isSloEnabled) {
          this.windowRef.nativeWindow.location.href = this.windowRef.nativeWindow.location.pathname + '?loggedOut=true';
        }
      });
  }
}
