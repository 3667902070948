import { Component, Inject, OnInit } from '@angular/core';
import { FilterByPipe, OrderByPipe } from 'ngx-pipes';
import MessageBusService from '../../../../../shared/services/common/message-bus.service';
import ApiFetchService from '../common-services/api-fetch.service';
import Entity from '../common-services/entity.service';
import WithRolePipe from '../ddiq-filters/with-role.pipe';
import Monitor from '../monitor/monitor.service';
import UserService from '../user/user.service';
import ModalTrackingService from '../modal/modal-tracking.service';

@Component({
  selector: 'ddiq-notify',
  templateUrl: 'ddiq-notify.component.tpl.html',
})
export default class DdiqNotifyComponent implements OnInit {
  groupUsers;
  selectedUser;
  currentOwner;
  emailNotes;
  userSearch;

  constructor(
    private entity: Entity,
    private modalTrackingService: ModalTrackingService,
    private monitor: Monitor,
    private user: UserService,
    private apiFetchService: ApiFetchService,
    private messageBusService: MessageBusService,
    private withRolePipe: WithRolePipe,
    private orderByPipe: OrderByPipe,
    private filterPipe: FilterByPipe
  ) {}

  ngOnInit(): void {
    this.init();
    this.messageBusService.on('event:notify-modal-opened', () => this.init());
  }

  init() {
    if (this.user.isInGroup()) {
      Promise.all([this.getUsersInGroup(), this.getSummary()]).then((response) => {
        if (response[0].length !== 0) {
          this.groupUsers = this.orderByPipe.transform(
            this.withRolePipe.transform(response[0].users, 'role_limited_user', true),
            'fullName'
          );
          const listOfUsers = this.groupUsers;

          this.selectedUser = listOfUsers[0].username;
        }
        this.currentOwner = response[1].oiqOwner;
      });
    }

    this.emailNotes = '';
  }

  getUsersInGroup(): Promise<any> {
    if (this.user.isInGroup()) {
      return this.apiFetchService.usersInGroup(this.user.getGroup());
    } else {
      return Promise.resolve([]);
    }
  }

  getSummary(): Promise<any> {
    return this.apiFetchService.summary(this.entity.getType(), this.entity.getId(), false);
  }

  filterGroups() {
    if (this.userSearch !== '' && this.userSearch !== undefined) {
      const filteredArray = this.filterPipe.transform(
        this.groupUsers,
        ['fullName', 'username', 'emailAddress'],
        this.userSearch
      );
      this.selectedUser = this.selectedUser ? this.selectedUser : filteredArray[0].username;

      return filteredArray;
    }

    return this.groupUsers;
  }

  notify() {
    const args = {
      from: this.user.getUserName(),
      to: this.selectedUser.username,
      content: this.emailNotes,
    };

    this.apiFetchService.notifyUser(this.entity.getType(), this.entity.getId(), args).then(
      () => {
        this.modalTrackingService.getTriggers().notify.open = false;
      },
      () => {
        this.modalTrackingService.getTriggers().notify.open = false;
        this.modalTrackingService.openError('notifyError');
      }
    );
  }

  cancel() {
    this.modalTrackingService.getTriggers().notify.open = false;
  }

  isMonitored() {
    return this.monitor.isMonitorActive();
  }
}
