import { NgModule } from '@angular/core';
import LoginManagerDirective from './login-manager.directive';
import OiqEntityAwareNgDirective from './oiq-entity-aware.ng.directive';
import ScrollToDirective from './scrollto-ng.directive';
import LoadSourceDirective from './load-source.directive';
import LinkComponent from './ddiq-link.component';
import { CommonModule } from '@angular/common';
import { ProtectedArticlesModule } from '../protected-articles/oiq-protected-articles.module';
import { PipesModule } from '../ddiq-filters/ddiq-filters.module';
import { ExternalUrlMsgModule } from '../external-url-msg/ddiq-external-url-msg.module';

@NgModule({
  imports: [CommonModule, ProtectedArticlesModule, PipesModule, ExternalUrlMsgModule],
  declarations: [
    OiqEntityAwareNgDirective,
    ScrollToDirective,
    LoginManagerDirective,
    LoadSourceDirective,
    LinkComponent,
  ],
  exports: [OiqEntityAwareNgDirective, ScrollToDirective, LoginManagerDirective, LoadSourceDirective, LinkComponent],
})
export default class DdiqDirectivesModule {}
