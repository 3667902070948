<article
  class="oiq-product-recall"
  #entityAware="oiqEntityAware"
  oiq-entity-aware
  [ngClass]="{'escalated': recall.escalated, confirmed: recall.confirmed}">
  <div oiq-adjudication-aware>
    <oiq-dynamic-adjudication-aware [model]="recall"></oiq-dynamic-adjudication-aware>

    <div class="oiq-grid">
      <div class="oiq-grid-row">
        <div class="oiq-product-recall-summary oiq-grid-cell">
          <div class="text-center">
            <span
              class="oiq-product-recall-display-date"
              *ngIf="recall.recallDate"
              [ngClass]="{disabled: recall.disabled}">
              <span class="sr-only">Recall Date</span>
              <time
                class="oiq-recall-date-display-date-time"
                datetime="{{recall.recallDate}}"
                [textContent]="recall.recallDate | dateFormat">
              </time>
            </span>

            <ddiq-adjudication-actions
              *ngIf="isAdjudicateable && !entityAware.isPreview"
              [type]="recall.isUscpsc ? 'USCPSC_PRODUCT_RECALL' : 'CONSUMER_PRODUCT_RECALL'"
              [entityType]="entityAware.entityType"
              [entityId]="entityAware.entityId"
              [noReassess]="entityAware.noReassess"
              [targetId]="recall.id"
              [model]="recall">
            </ddiq-adjudication-actions>
          </div>
        </div>

        <div class="oiq-product-recall-bd oiq-grid-cell">
          <header class="oiq-product-recall-header">
            <h3 class="oiq-product-recall-title">
              <a
                class="oiq-product-recall-title-nav"
                *ngIf="recall.productName"
                target="_blank"
                rel="noopener noreferrer"
                [href]="recall.sourceUrl"
                [textContent]="recall.productName">
              </a>

              <a
                class="oiq-product-recall-title-nav"
                *ngIf="!recall.productName"
                target="_blank"
                rel="noopener noreferrer"
                [href]="recall.sourceUrl"
                [textContent]="recall.sourceUrl">
              </a>
            </h3>

            <div class="oiq-product-recall-byline">
              <address class="oiq-product-recall-byline-address">
                <a
                  class="oiq-product-recall-byline-address-nav"
                  [href]="recall.sourceUrl"
                  [textContent]="recall.sourceUrl | sitename"
                  rel="author">
                </a>
              </address>
            </div>
          </header>

          <section class="oiq-product-recall-body">
            <article class="oiq-product-recall-grid" [ngClass]="{disabled: recall.disabled}">
              <div class="oiq-product-recall-row" *ngIf="recall.title" role="row">
                <div class="oiq-product-recall-hd" role="columnheader">Title:</div>
                <div class="oiq-product-recall-cell" role="gridcell" [textContent]="recall.title"></div>
              </div>

              <div class="oiq-product-recall-row" *ngIf="recall.hazardIdentified" role="row">
                <div class="oiq-product-recall-hd" role="columnheader">Hazard:</div>
                <div class="oiq-product-recall-cell" role="gridcell" [textContent]="recall.hazardIdentified"></div>
              </div>

              <div class="oiq-product-recall-row" *ngIf="recall.description" role="row">
                <div class="oiq-product-recall-hd" role="columnheader">Description:</div>
                <div class="oiq-product-recall-cell" role="gridcell" [textContent]="recall.description"></div>
              </div>
              <div class="oiq-product-recall-row" *ngIf="recall.fileNumber" role="row">
                <div class="oiq-product-recall-hd" role="columnheader">Recall Number:</div>
                <div class="oiq-product-recall-cell" role="gridcell" [textContent]="recall.fileNumber"></div>
              </div>

              <div class="oiq-product-recall-row" *ngIf="recall.soldAt" role="row">
                <div class="oiq-product-recall-hd" role="columnheader">Sold At:</div>
                <div class="oiq-product-recall-cell" role="gridcell" [textContent]="recall.soldAt"></div>
              </div>

              <div class="oiq-product-recall-row" *ngIf="recall.consumerContact" role="row">
                <div class="oiq-product-recall-hd" role="columnheader">Consumer Contact:</div>
                <div class="oiq-product-recall-cell" role="gridcell" [textContent]="recall.consumerContact"></div>
              </div>
            </article>

            <ddiq-adjudication-notes
              class="adjudication-notes"
              [type]="recall.isUscpsc ? 'USCPSC_PRODUCT_RECALL' : 'CONSUMER_PRODUCT_RECALL'"
              [entityType]="entityAware.entityType"
              [entityId]="entityAware.entityId"
              [noReassess]="entityAware.noReassess"
              [targetId]="recall.id"
              [model]="recall"
              [label]="'Notes'"
              [active]="recall.comment">
            </ddiq-adjudication-notes>
          </section>
        </div>
      </div>
    </div>
  </div>
</article>
