import { HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';

export default function (settings) {
  return {
    provide: APP_INITIALIZER,
    useFactory(httpBackend: HttpBackend) {
      return () => {
        return new HttpClient(httpBackend)
          .get(`${settings.apiUrl}rest/admin/version`)
          .toPromise()
          .then((response: any) => {
            settings.version = response.ui;
          });
      };
    },
    deps: [HttpBackend],
    multi: true,
  };
}
