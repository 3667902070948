<header>
  <h1>My Profile</h1>
</header>

<section *ngIf="isLoaded">
  <div class="float-column">
    <strong>User Information</strong>
    <hr />
    <p id="my-profile-user-name" class="search-results">
      User Name:
      <span>{{loggedInUser.username}}</span>
    </p>
    <p id="my-profile-email-address" class="search-results">
      Email Address:
      <span>{{loggedInUser.emailAddress || '(not entered)'}}</span>
    </p>
    <p id="my-profile-full-name" class="search-results">
      Full Name:
      <span>{{loggedInUser.fullName || '(not entered)'}}</span>
    </p>
    <p class="search-results">
      <button
        id="my-profile-change-password-button"
        class="btn btn-primary btn-sm"
        (click)="editUserPassword()"
        [disabled]="editingUser.type">
        Change Password
      </button>
    </p>
    <p id="my-profile-user-administration-button" class="search-results">
      <a class="btn btn-primary btn-sm" *ngIf="canAccessGroupAdmin()" href="/users"> User Administration </a>
    </p>

    <form method="post" novalidate #editUser="ngForm">
      <div *ngIf="editingUser.type">
        <div [ngSwitch]="editingUser.type">
          <hr />
          <div *ngSwitchCase="'reset'">
            <ddiq-password
              size="full"
              name="oldPassword"
              label="Old Password"
              [(model)]="editingUser.oldPassword"
              (passwordChanged)="validPassword()"
              required></ddiq-password>
            <div class="error-message" *ngIf="editingUser.authError && editingUser.oldPassword">
              {{editingUser.authError}}
            </div>

            <ddiq-password
              size="full"
              name="newPassword"
              label="New Password"
              [(model)]="editingUser.newPassword"
              (passwordChanged)="validPassword()"
              required
              [pattern]="passwordValidationPattern"></ddiq-password>

            <ddiq-password
              size="full"
              name="newPasswordConfirm"
              label="Re-enter Password"
              [(model)]="editingUser.newPasswordConfirm"
              (passwordChanged)="validPassword()"
              required
              [pattern]="passwordValidationPattern"></ddiq-password>

            <div class="error-message" *ngIf="editingUser.error">
              <span>{{editingUser.error}}</span>
            </div>
          </div>
        </div>
        <div [ngSwitch]="editingUser.type">
          <div *ngSwitchCase="'reset'">
            <button
              type="submit"
              class="btn btn-primary btn-sm"
              (click)="changeUserPassword()"
              [disabled]="editingUser.error || editUser.form.invalid">
              Change
            </button>
            <button type="cancel" class="btn btn-default btn-sm" (click)="cancelEdit()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="float-column">
    <strong>Profiles Generated</strong>
    <hr />
    <p id="my-profile-last-24hr" class="search-results">
      Last 24h:
      <span *ngIf="loggedInUser.usage.companyCount24Hour != 1">
        {{loggedInUser.usage.companyCount24Hour}} companies
      </span>
      <span *ngIf="loggedInUser.usage.companyCount24Hour == 1">
        {{loggedInUser.usage.companyCount24Hour}} company
      </span>
      <span *ngIf="loggedInUser.usage.personCount24Hour != 1">
        and {{loggedInUser.usage.personCount24Hour}} people
      </span>
      <span *ngIf="loggedInUser.usage.personCount24Hour == 1">
        and {{loggedInUser.usage.personCount24Hour}} person
      </span>
    </p>
    <p id="my-profile-last-7d" class="search-results">
      Last 7d:
      <span *ngIf="loggedInUser.usage.companyCount7Days != 1">
        {{loggedInUser.usage.companyCount7Days}} companies
      </span>
      <span *ngIf="loggedInUser.usage.companyCount7Days == 1"> {{loggedInUser.usage.companyCount7Days}} company </span>
      <span *ngIf="loggedInUser.usage.personCount7Days != 1"> and {{loggedInUser.usage.personCount7Days}} people </span>
      <span *ngIf="loggedInUser.usage.personCount7Days == 1"> and {{loggedInUser.usage.personCount7Days}} person </span>
    </p>
    <p id="my-profile-total" class="search-results">
      Total:
      <span *ngIf="loggedInUser.usage.companyCountTotal != 1">
        {{loggedInUser.usage.companyCountTotal}} companies
      </span>
      <span *ngIf="loggedInUser.usage.companyCountTotal == 1"> {{loggedInUser.usage.companyCountTotal}} company </span>
      <span *ngIf="loggedInUser.usage.personCountTotal != 1"> and {{loggedInUser.usage.personCountTotal}} people </span>
      <span *ngIf="loggedInUser.usage.personCountTotal == 1"> and {{loggedInUser.usage.personCountTotal}} person </span>
    </p>
  </div>

  <div class="float-column">
    <strong>Account Usage</strong>
    <hr />
    <p id="my-profile-last-login" class="search-results">
      Last Login:
      <span>{{loggedInUser.usage.lastLoginDate}} ({{loggedInUser.usage.lastLoginDetails}})</span>
    </p>
    <p id="my-profile-last-profile" class="search-results">
      Last Profile:
      <span>{{loggedInUser.usage.lastProfileCreatedDate}}</span>
    </p>
  </div>

  <div class="float-column" *ngIf="canRenderRefreshTokens">
    <strong>Refresh Token</strong>
    <hr />
    <p>Refresh tokens can be exchanged for an access token which will allow an application to access the DDIQ API</p>
    <br />
    <p>
      <i class="fa fa-exclamation-triangle"></i> Once a new refresh token is requested, all refresh tokens issued
      earlier will be invalidated!
    </p>
    <br />
    <button type="submit" class="btn btn-primary btn-sm" (click)="generateRefreshToken()">
      Generate Refresh Token
    </button>
  </div>

  <ddiq-modal [trigger]="showToken" (closed)="closeTokenWindow()" [closeable]="true">
    <div id="refresh-token">
      <div class="modal-title" id="popup-title">Refresh Token</div>

      <div *ngIf="refreshTokenLoading">
        <br />
        <div class="modal-content center-block center push-down" *ngIf="!refreshTokenError">
          <img src="./img/loader.gif" class="validation-loading-img" />
        </div>
        <div class="modal-content center-block" *ngIf="refreshTokenError">
          <p>An error occurred generating your refresh token. Please try again later.</p>
        </div>
      </div>

      <div *ngIf="!refreshTokenLoading">
        <div class="modal-content center-block">
          <p>
            For security reasons, this will be the only time you see the API token. Please save this token somewhere.
            You will have to regenerate it if you lose it.
          </p>
        </div>
        <div class="center pushdown clr">
          <input class="inputs" type="text" id="token_display" [(ngModel)]="refreshToken" readonly size="50" />
          <button class="btn btn-primary" (click)="copyToken()" title="Copy token">
            <i class="fa fa-clipboard"></i>
          </button>
        </div>
      </div>
    </div>
  </ddiq-modal>
</section>
