import { Component, Inject, Input, OnInit, EventEmitter, Output } from '@angular/core';
import OiqProperties from '../common-services/oiq-properties.service';
import IncidentGroupDisplaySettings from './incident-group-display-settings.service';

@Component({
  selector: 'ddiq-incidents-group',
  templateUrl: './ddiq-incidents-group.component.tpl.html',
})
export default class IncidentsGroupComponent implements OnInit {
  @Input() groups: any;
  @Output() sortDiscoveries: any = new EventEmitter();
  @Input() isDisplayCreatedDate: boolean;
  @Input() idPrefix: string;
  @Input() expandAll: boolean;

  showArticleCacheLinks: boolean;
  showTranslatedSnippets: boolean;
  showOtherMentionsOpen: boolean;
  showReferencesOpen: boolean;

  constructor(
    private oiqProperties: OiqProperties,
    private incidentGroupDisplaySettings: IncidentGroupDisplaySettings
  ) {}

  ngOnInit() {
    this.showArticleCacheLinks = this.oiqProperties.articleCacheEnabled;
    this.showTranslatedSnippets = this.oiqProperties.showTranslatedSnippets;
    this.showOtherMentionsOpen = this.incidentGroupDisplaySettings.getShowOtherMentionsOpen();
    this.showReferencesOpen = this.incidentGroupDisplaySettings.getShowReferencesOpen();
  }

  sort(sortSpec) {
    this.sortDiscoveries.emit(sortSpec);
  }

  classification(index: number, group: any) {
    return group.classification;
  }

  id(index: number, incident: any) {
    return incident.id;
  }
}
