import { Component, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'oiq-drop-down',
  templateUrl: './oiq-drop-down.component.tpl.html',
})
export default class DropDownComponent implements OnInit {
  @Input() toggleName;
  @Input() isDisabled = false;
  escKeyCode = 27;
  escCode = 'ESCAPE';
  isOpen = false;
  button;
  dropDown;

  @HostListener('document:click', ['$event']) onClickOtherElem(e) {
    if (!this.isOpen) {
      return;
    }

    const target = $(e.target);

    if (this.dropDown.has(target).length || target.is(this.dropDown)) {
      this.closeDropDown();
    }

    if (this.button.has(target).length || target.is(this.button)) {
      return;
    }

    this.closeDropDown();
  }

  @HostListener('document:keyup', ['$event']) onEscKeyUpHandler(e: KeyboardEvent) {
    if (e.key !== 'Escape' && e.code !== 'Escape' && e.keyCode !== 27) {
      return;
    }

    if (!this.isOpen) {
      return;
    }

    this.closeDropDown();
  }

  constructor(private elem: ElementRef) {}

  ngOnInit() {
    if (!this.toggleName) {
      this.toggleName = '';
    }
  }

  ngAfterViewInit() {
    this.button = $(this.elem.nativeElement).find('button');
    this.dropDown = $(this.elem.nativeElement).find('div.oiq-drop-down-items');
  }

  onToggle() {
    if (this.isDisabled) {
      this.isOpen = false;
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  closeDropDown() {
    this.isOpen = false;
  }
}
