import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ProgressChecker {
  private progressCheckers = []; // an array of closures -- each responsible for an entity
  private queuesTotal = 0;
  private emptyResponseCount = 1;
  private DELAY_INCREMENT = 20000;
  private MAX_DELAY = 240000;
  private timeout;

  private createProgressChecker(checkerId, ignoreCounts, apiCall, apiCallParams) {
    const progress = {
      data: {},
      results: [],
    };

    function progressFetchSuccess(data) {
      progress.results = data.results;
      progress.data = data;
    }

    function check() {
      if (apiCallParams) {
        apiCall(apiCallParams()).then(progressFetchSuccess);
      } else {
        apiCall().then(progressFetchSuccess);
      }
    }

    function getId() {
      return checkerId;
    }

    function ignoreResultCount() {
      return ignoreCounts;
    }

    return {
      checkNow: function checkNowFn() {
        check();
      },
      progress: progress,
      getId: getId,
      ignoreResultCount: ignoreResultCount,
    };
  }

  private checkAll() {
    let i;
    for (i = 0; i < this.progressCheckers.length; i++) {
      this.progressCheckers[i].checkNow();
    }
    this.timeout = setTimeout(() => this.checkAll(), this.DELAY_INCREMENT * this.emptyResponseCount);
    this.monitorProgressQueues();
  }

  private monitorProgressQueues() {
    let i,
      currentQueuesTotal = 0;

    for (i = 0; i < this.progressCheckers.length; i++) {
      if (!this.progressCheckers[i].ignoreResultCount()) {
        currentQueuesTotal += this.progressCheckers[i].progress.results.length;
      }
    }

    this.incrementEmptyResponseCount(currentQueuesTotal);

    this.queuesTotal = currentQueuesTotal;
  }

  private incrementEmptyResponseCount(queueTotal) {
    if (queueTotal > 0) {
      // found results, reset
      this.emptyResponseCount = 1;
    } else if (this.emptyResponseCount < this.MAX_DELAY / this.DELAY_INCREMENT) {
      this.emptyResponseCount++;
    }
  }

  private stop() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = null;
  }

  addProgressChecker(id, apiCall, ignoreResultCount, apiCallParams?) {
    let i, existingProgressChecker;

    for (i = 0; i < this.progressCheckers.length; i++) {
      if (this.progressCheckers[i].getId() === id) {
        existingProgressChecker = this.progressCheckers[i];
      }
    }

    if (existingProgressChecker) {
      return existingProgressChecker.progress;
    }

    const newProgressChecker = this.createProgressChecker(id, ignoreResultCount, apiCall, apiCallParams);

    this.progressCheckers.push(newProgressChecker);
    newProgressChecker.checkNow();
    return newProgressChecker.progress;
  }
  repeatedProgressCheck() {
    if (!this.timeout) {
      this.checkAll();
    }
  }
  clearAll() {
    this.stop();
    this.progressCheckers = [];
    this.emptyResponseCount = 1;
  }
}
