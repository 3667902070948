<div class="audit-report" oiq-print-rf>
  <div class="alert alert-warning" *ngIf="noAuditInfo">
    <div class="alert-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
    <p><span>{{ warningMessageTxt }}</span></p>
  </div>

  <div *ngIf="!noAuditInfo">
    <div class="audit-title">
      <table>
        <tbody>
          <tr>
            <th>
              <h1>Audit Report</h1>
              :
            </th>
            <td>
              <span class="subject">{{summary.fullName}}</span><br />
              <b>Created:</b> {{summary.oiqCreatedDate | dateFormat}}<br />
              <b>By:</b> {{summary.oiqCreatedBy}}<br />
              <span *ngIf="summary.submissionId"
                ><b>{{ 'REFERENCE_ID' | translatef }}:</b> {{summary.submissionId}}<br
              /></span>
              <span *ngIf="audit.submitted"
                ><b>Profile Submitted:</b> {{audit.submitted | date : 'HH:mm:ss (Z)'}}<br
              /></span>
              <span *ngIf="summary.crawlPlanTypeDescription"
                ><b>{{ 'PROFILE_TYPE' | translatef }}:</b> {{summary.crawlPlanTypeDescription | translatef}}<br
              /></span>
              <span *ngIf="summary.requestedLanguages.length"
                ><b>Languages:</b
                ><span *ngFor="let language of summary.requestedLanguages; last as isLast">
                  {{ language | translatef }}{{isLast ? '' : ',' }}</span
                ><br
              /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="!forPrint">
      <div class="audit-filter inline input">
        <input type="text" [(ngModel)]="filter" />
      </div>
      <button class="btn btn-default" [disabled]="filter == ''" (click)="clearFilter()">Clear Filter</button>
      <div class="right">
        <button id="auditPdf" class="btn btn-primary" (click)="getAuditPdf()">Generate Audit PDF</button>
      </div>
    </div>

    <img *ngIf="isLoading" [src]="'./img/loader.gif'" class="result-loading-img" />

    <div *ngIf="!isLoading">
      <ddiq-accordion-top class="auditRecordsTable" label="Summary" open>
        <table class="summary-table">
          <tr>
            <td class="column">
              <table>
                <tr *ngIf="audit.sectionVisibility.GENERAL">
                  <td>General:</td>
                  <td>{{audit.sectionDetails.GENERAL.totalHits}}</td>
                </tr>
                <tr *ngIf="audit.sectionVisibility.REGULATORY">
                  <td>Regulatory:</td>
                  <td>{{audit.sectionDetails.REGULATORY.totalHits}}</td>
                </tr>
                <tr *ngIf="audit.sectionVisibility.ADVERSE">
                  <td>Adverse:</td>
                  <td>{{audit.sectionDetails.ADVERSE.totalHits}}</td>
                </tr>
                <tr *ngIf="audit.sectionVisibility.LEGAL">
                  <td>Legal:</td>
                  <td>{{audit.sectionDetails.LEGAL.totalHits}}</td>
                </tr>
                <tr *ngIf="audit.sectionVisibility.NOTEWORTHY">
                  <td>Noteworthy:</td>
                  <td>{{audit.sectionDetails.NOTEWORTHY.totalHits}}</td>
                </tr>
                <tr *ngIf="audit.sectionVisibility.OTHER">
                  <td>References:</td>
                  <td>{{audit.sectionDetails.OTHER.totalHits}}</td>
                </tr>
                <tr>
                  <td><b>Total:</b></td>
                  <td>{{audit.sectionTotal}}</td>
                </tr>
                <ng-container *ngFor="let isVisible of audit.sectionVisibility | keyvalue">
                  <tr *ngIf="!isVisible.value">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </ng-container>
              </table>
            </td>
            <td class="column">
              <table>
                <tr>
                  <td>Profile Started:</td>
                  <td>{{(audit.crawlStart | date : 'HH:mm:ss') || "N/A"}}</td>
                </tr>
                <tr>
                  <td>Profile Completed:</td>
                  <td>{{(audit.crawlEnd | date : 'HH:mm:ss') || "N/A"}}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;<!--  DDIQ Sources Analyzed:--></td>
                  <td>&nbsp;<!--  {{audit.totalDDIQSourcesAnalyzed || 'N/A'}}--></td>
                </tr>
                <tr>
                  <td>Web Sources Analyzed:</td>
                  <td>{{audit.totalWebSourcesAnalyzed}}</td>
                </tr>
                <tr>
                  <td><b>Total:</b></td>
                  <td>{{(audit.totalDDIQSourcesAnalyzed || 0) + audit.totalWebSourcesAnalyzed}}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </ddiq-accordion-top>
    </div>

    <div *ngIf="!isLoading">
      <ddiq-accordion-top class="auditRecordsTable" label="Details" open>
        <div *ngIf="audit.sectionVisibility.GENERAL" class="section-detail non-breaking">
          <div class="section-title">General</div>
          <ddiq-audit-section-detail
            [data]="audit.sectionDetails.GENERAL"
            [noWebContent]="true"></ddiq-audit-section-detail>
        </div>

        <div *ngIf="audit.sectionVisibility.REGULATORY" class="section-detail non-breaking">
          <div class="section-title">Regulatory</div>
          <ddiq-audit-section-detail [data]="audit.sectionDetails.REGULATORY"></ddiq-audit-section-detail>
        </div>

        <div class="clr"></div>

        <div *ngIf="audit.sectionVisibility.ADVERSE" class="section-detail non-breaking">
          <div class="section-title">Adverse</div>
          <ddiq-audit-section-detail [data]="audit.sectionDetails.ADVERSE"></ddiq-audit-section-detail>
        </div>

        <div *ngIf="audit.sectionVisibility.LEGAL" class="section-detail non-breaking">
          <div class="section-title">Legal</div>
          <ddiq-audit-section-detail [data]="audit.sectionDetails.LEGAL"></ddiq-audit-section-detail>
        </div>

        <div class="clr"></div>

        <div *ngIf="audit.sectionVisibility.NOTEWORTHY" class="section-detail non-breaking">
          <div class="section-title">Noteworthy</div>
          <ddiq-audit-section-detail [data]="audit.sectionDetails.NOTEWORTHY"></ddiq-audit-section-detail>
        </div>

        <div *ngIf="audit.sectionVisibility.OTHER" class="section-detail non-breaking">
          <div class="section-title">References</div>
          <ddiq-audit-section-detail [data]="audit.sectionDetails.OTHER"></ddiq-audit-section-detail>
        </div>

        <div class="clr"></div>
      </ddiq-accordion-top>
    </div>

    <div class="page-break-before" [ngSwitch]="audit.querieserror">
      <div *ngSwitchCase="true">
        <ddiq-accordion-top class="auditRecordsTable" label="Queries" open>
          <table class="accordion-table">
            <tbody>
              <tr>
                <td>
                  <div class="alert alert-danger">
                    <div class="alert-icon">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </div>
                    <p>An unexpected error has occurred. Query audit information is not available.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ddiq-accordion-top>
      </div>
    </div>

    <div class="page-break-before" [ngSwitch]="audit.queries.length > 0">
      <div *ngSwitchCase="true">
        <ddiq-accordion-top class="auditRecordsTable" label="Queries" [count]="audit.queries.length" open>
          <table class="accordion-table">
            <thead>
              <tr>
                <th class="accordion-icon"></th>
                <th>Query</th>
                <th class="hits">Hits</th>
              </tr>
            </thead>
            <tbody *ngFor="let query of audit.queries; index as queryIdx">
              <tr
                class="accordion-heading"
                (click)="toggleUrls(queryIdx)"
                [ngClass]="{ 'accordion-open': query.isAuditable && isAuditQueryOpened(queryIdx),'accordion-enabled': query.isAuditable }">
                <td class="accordion-icon">
                  <i
                    *ngIf="query.isAuditable"
                    class="fa"
                    [ngClass]="{ 'fa-plus-square-o': !isAuditQueryOpened(queryIdx), 'fa-minus-square-o': isAuditQueryOpened(queryIdx) }"
                    aria-hidden="true"></i>
                </td>
                <td class="accordion-wrap">{{query.query}}</td>
                <td>
                  <span *ngIf="queryHasError(query)">
                    <i class="alert-icon fa fa-lg fa-exclamation-triangle"></i>
                  </span>
                  <ng-container *ngIf="query.isAuditable">
                    <span *ngFor="let result of query.results">
                      <span
                        class="badge"
                        [ngClass]="{'badge-alert': result.totalHits > 0}"
                        *ngIf="wasQuerySearched(result)">
                        {{result.searchsite}} {{result.totalHits}}
                      </span>
                    </span>
                  </ng-container>
                  <p *ngIf="!query.isAuditable">
                    <i class="accordion-alert alert-icon fa fa-exclamation-circle"> </i>
                    <em>
                      We could not extract audit info for this query. This profile may not support this capability.
                    </em>
                  </p>
                </td>
              </tr>
              <tr *ngFor="let result of query.results">
                <ng-container *ngIf="showHideAccordionRow(query, queryIdx, result)">
                  <td colspan="3" class="accordion-content accordion-wrap">
                    <div>
                      <label class="accordion-label">Search Engine:</label>
                      <p>{{result.searchsite}} (Max results {{result.maxResults}})</p>
                    </div>
                    <div>
                      <label class="accordion-label">URIs:</label>
                      <p *ngIf="result.uris.length == 0">Not Available</p>
                      <ul class="no-list">
                        <li *ngFor="let uri of result.uris">{{decodeURI(uri)}}</li>
                      </ul>
                    </div>
                    <div>
                      <label class="accordion-label">Results:</label>
                      <p *ngIf="result.results.length == 0">No Results</p>
                      <ul *ngIf="result.results.length > 0">
                        <li *ngFor="let url of result.results; trackBy: trackByIndex" [ngSwitch]="enableLinks">
                          <a *ngSwitchCase="true" [href]="url | protectedArticleHref" target="_blank">{{url}}</a>
                          <span *ngSwitchCase="false">{{url}}</span>
                          <span *ngIf="audit.warnings.byUri[url]">
                            <i
                              class="alert-icon fa fa-lg fa-exclamation-triangle"
                              title="{{warningMessage(audit.warnings.byUri[url])}}"></i>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="result.errorMessage">
                      <label class="accordion-label">Error:</label>
                      <p>{{result.errorMessage}}</p>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </ddiq-accordion-top>
      </div>
    </div>

    <div [ngSwitch]="audit.sources.errors && audit.sources.errors.length > 0">
      <div *ngSwitchCase="true">
        <ddiq-accordion-top
          class="auditErrorRecordsTable"
          label="Errors"
          [toggleScope]="audit.sources.errorMeta"
          [filteredCount]="(audit.sources.errors | filterBy: ['sourceName']: filter).length"
          [count]="audit.sources.errors.length"
          open>
          <table>
            <tbody>
              <tr>
                <th (click)="changeSorting('error', 'sourceName')">
                  <ddiq-sortable-column column="sourceName" [(sort)]="sort.error">Source</ddiq-sortable-column>
                </th>
                <th (click)="changeSorting('error', 'status')" class="center status">
                  <ddiq-sortable-column column="status" [(sort)]="sort.error">Error</ddiq-sortable-column>
                </th>
              </tr>
              <tr
                class="divided"
                *ngFor="let source of audit.sources.errors | filterBy: ['sourceName'] : filter | orderBy: getOrder('error')">
                <td>
                  {{ source.sourceName | translatef }}<span *ngIf="source.workerCategories.length">
                    ({{workerCategories(source)}})</span
                  >
                </td>
                <td class="center">{{ source.status | translatef }}<span *ngIf="">({{source.workerName}})</span></td>
              </tr>
            </tbody>
          </table>
        </ddiq-accordion-top>
      </div>
    </div>

    <div
      [ngClass]="{'page-break-before': (audit.warnings.array && audit.warnings.array.length > 0)}"
      [ngSwitch]="audit.sources.searched && audit.sources.searched.length > 0">
      <div *ngSwitchCase="true">
        <ddiq-accordion-top
          class="auditRecordsTable"
          label="Sources Searched"
          [toggleScope]="audit.sources.searchedMeta"
          [filteredCount]="(audit.sources.searched | filterBy: ['sourceName'] : filter).length"
          [count]="audit.sources.searched.length"
          open>
          <table>
            <tbody>
              <tr>
                <th (click)="changeSorting('searched', 'sourceName')">
                  <ddiq-sortable-column column="sourceName" [(sort)]="sort.searched">Source</ddiq-sortable-column>
                </th>
                <th (click)="changeSorting('searched', 'status')" class="center status">
                  <ddiq-sortable-column column="status" [(sort)]="sort.searched">Status</ddiq-sortable-column>
                </th>
                <th (click)="changeSorting('searched', 'matchedCount')" class="center matches">
                  <ddiq-sortable-column column="matchedCount" [(sort)]="sort.searched">Matched</ddiq-sortable-column>
                </th>
              </tr>
              <tr
                class="divided"
                *ngFor="let source of audit.sources.searched | filterBy: ['sourceName'] : filter | orderBy: getOrder('searched')">
                <td>
                  {{ source.sourceName }}
                  <span *ngIf="source.workerCategories && source.workerCategories.length">
                    ({{workerCategories(source)}})</span
                  >
                  <span *ngIf="audit.warnings.bySource[source.sourceId]">
                    <i
                      class="alert-icon fa fa-lg fa-exclamation-triangle"
                      title="{{warningMessage(audit.warnings.bySource[source.sourceId])}}"></i>
                  </span>
                </td>
                <td class="center">{{ source.status | translatef }}</td>
                <td class="center">{{ source.matchedCount }} of {{source.matchedCount + source.rejectedCount}}</td>
              </tr>
            </tbody>
          </table>
        </ddiq-accordion-top>
      </div>
    </div>

    <div class="page-break-before" [ngSwitch]="audit.sources.notSearched && audit.sources.notSearched.length > 0">
      <div *ngSwitchCase="true">
        <ddiq-accordion-top
          class="auditRecordsTable"
          label="Sources Not Searched"
          [toggleScope]="audit.sources.notSearchedMeta"
          [filteredCount]="(audit.sources.notSearched | filterBy: ['sourceName'] : filter).length"
          [count]="audit.sources.notSearched.length">
          <table>
            <tbody>
              <tr>
                <th (click)="changeSorting('notSearched', 'sourceName')">
                  <ddiq-sortable-column column="sourceName" [(sort)]="sort.notSearched">Source</ddiq-sortable-column>
                </th>
                <th (click)="changeSorting('notSearched', 'status')" class="center status">
                  <ddiq-sortable-column column="status" [(sort)]="sort.notSearched">Reason</ddiq-sortable-column>
                </th>
              </tr>
              <tr
                class="divided"
                *ngFor="let source of audit.sources.notSearched | filterBy: ['sourceName']: filter | orderBy: getOrder('notSearched')">
                <td>
                  {{ source.sourceName }}
                  <span *ngIf="source.workerCategories.length"> ({{workerCategories(source)}})</span>
                  <span *ngIf="audit.warnings.bySource[source.sourceId]">
                    <i
                      class="fa fa-lg fa-exclamation-triangle"
                      title="{{warningMessage(audit.warnings.bySource[source.sourceId])}}"></i>
                  </span>
                </td>
                <td class="center">{{ source.status | translatef }}</td>
              </tr>
            </tbody>
          </table>
        </ddiq-accordion-top>
      </div>
    </div>
  </div>
</div>
